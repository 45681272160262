import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px",
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    width: "100%",
    flexWrap: 'wrap',
    margin: "0 auto",
  },
  balanceSection: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40
  },
  totalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignSelf: 'stretch',
    alignItems: 'center'

  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "24px",
    gap: "20px",
    width: '100%',
    maxWidth: 500,
    [theme.breakpoints.up('lg')]: {
      '& .MuiButton-root.MuiButton-contained': { // Adjusted selector
        backgroundColor: '#fff',
        color: theme.palette.primary.main
      }
    }
  },
  adSection: {
    borderRadius: 8,
    marginTop: 24,
    background: '#F0EBF8',
    '& .carousel-root .carousel-slider .control-dots .selected': {
      background: theme.palette.primary.main
    },
    '& .carousel-root .carousel-slider': {
      overflow: 'visible'
    },
    '& .carousel-root .carousel-slider .control-dots': {
      margin: '-27px 0px'
    },
    '& .carousel-root .carousel-slider .slider-wrapper .slider .slide img': {
      width: 64,
      [theme.breakpoints.up('md')]: {
        width: 80

      }
    }
  },
  adContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    gap: 12,
    padding: '8px 8px',

  }


}));
