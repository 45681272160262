import api from "../api";
import { SUBSCRIPTIONS_ENDPOINT } from "../apiEndpoints";

const subscriptionsApiClient = {
  create: async (data:any) => {
    try {
       const response = await api.post(
      `${SUBSCRIPTIONS_ENDPOINT}`,data
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
   
  },
 
  updateById: async (id:number,data:any) => {
    try {
        const response = await api.put(
      `${SUBSCRIPTIONS_ENDPOINT}/${id}`,data
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
  
  },
 
  deleteById: async (id:number) => {
    try {
      await api.delete(`${SUBSCRIPTIONS_ENDPOINT}/${id}`);
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
};

export default subscriptionsApiClient;
