import { Alert, Avatar, Box, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useState } from 'react'
import { GoalMember } from '../../types/types';
import { numberWithCommas } from '../../utils/middleware'
import VerifiedIcon from '@mui/icons-material/Verified';
import { Field, Form } from 'react-final-form';
import { Switch, FormControlLabel } from '@mui/material';
import { ExtendedGoalMember, MemberSettings } from '../../types/actionTypes';
import Button from '../Reusables/Input/Button';
import FormInput from '../Reusables/Input/FormInput';
import { useStyles } from '../../components/Reusables/Input/ButtonStyles';
import RadioButtons from '../Reusables/Input/RadioGroup';
import { memberRoleOptions, yesOrNoOptions } from '../Reusables/Input/select/Options';
import Toast from '../Reusables/Feedback/Toast';

interface PromoteAdminProps {
  member: GoalMember
  promoteAdmin: (id: number, data: ExtendedGoalMember) => Promise<void>
  context: string;
}

interface FormValues {
  custom_title?: string;
  role: string;
  add_members_privilege: boolean;
  remove_members_privilege: boolean;
  promote_demote_admins_privilege: boolean;
  edit_goal_info_privilege: boolean;
  withdraw_privilege: boolean;
  distribute_goal_funds_privilege: boolean;
  max_withdraw_limit: number | null;
  is_withdraw_limit_percentage: boolean;
  have_transaction_limit: 'yes' | 'no';
  transaction_limit?: number;
}

const PromoteAdmin: React.FC<PromoteAdminProps> = ({ member, promoteAdmin }) => {
  const [openModal, setOpenModal] = useState(false);
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));
  }


  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleSubmit = (values: FormValues) => {
    try {
      const { have_transaction_limit, ...rest } = values;
      promoteAdmin(member.goal, {
        ...rest,
        memberId: member.id
      })
      setUpdateToast({
        open: true,
        message: "Member updated successfully",
        type: "success",
      });
    } catch (error) {

    }
  };
  const classes = useStyles();
  const initialValues = {
    custom_title: member.custom_title,
    role: member.role,
    add_members_privilege: member.add_members_privilege,
    remove_members_privilege: member.remove_members_privilege,
    promote_demote_admins_privilege: member.promote_demote_admins_privilege,
    edit_goal_info_privilege: member.edit_goal_info_privilege,
    withdraw_privilege: member.withdraw_privilege,
    distribute_goal_funds_privilege: member.distribute_goal_funds_privilege,
    max_withdraw_limit: member.max_withdraw_limit,
    have_transaction_limit: member.transaction_limit ? 'yes' : 'no',
    transaction_limit: member.transaction_limit,
    is_withdraw_limit_percentage: member.is_withdraw_limit_percentage
  };


  return (
    <Box sx={{ p: 2, maxWidth: '500px' }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.4 }}>
        <Avatar alt={'profile image'} src={member.image ?? undefined}
          sx={{ bgcolor: "primary.main", width: 35, height: 35 }}
        >{member.name.charAt(0)}</Avatar>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <Typography variant='body1' fontWeight={600}>{member.name}</Typography>
            {member.role === 'owner' && (<VerifiedIcon fontSize='small' sx={{ color: "#C2B0E2" }} />)}
          </Box>
          <Box sx={{ display: 'flex', color: 'grey.600', gap: 0.3, alignItems: 'baseline' }}>
            <Typography variant='subtitle2'>{`Gave`}</Typography>
            <Typography variant="h6" fontWeight={600}>
              {typeof member.contribution === 'number' ? numberWithCommas(member.contribution) : `${member.contribution}`}
            </Typography>
            <Typography variant='caption'>
              {typeof member.contribution === 'number' ? member.currency : `the min amount`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box >

        <Form onSubmit={handleSubmit}
          initialValues={initialValues}>
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mt: 2 }}>
                <Field
                  label="Does this admin have a custom title. If so, add custom title"
                  name="custom_title"
                  type="text"
                  component={FormInput}
                />
              </Box>
              {
                member.role !== 'owner' && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant='body1' sx={{ fontSize: '1rem', marginBottom: '4px', fontWeight: 600 }}>Are they an accountability partner (observer) or a privileged admin?</Typography>
                    <Field name="role" component={RadioButtons} options={memberRoleOptions} />
                  </Box>
                )
              }

              {values.role === 'member' ? (
                <Alert sx={{ px: 0 }}
                  icon={false}>{`An observer can only view and is not able to withdraw or distribute goal funds, add goal admins. They can download financial transactions`}</Alert>)
                : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 0, mt: 2 }}>
                      <Typography variant='body2' fontWeight={600}>Non-financial privileges</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='body1'>Add members</Typography>
                        <Field name="add_members_privilege" type="checkbox">
                          {({ input }) => (
                            <FormControlLabel
                              control={<Switch checked={input.checked} onChange={input.onChange} />}
                              sx={{ margin: 'unset' }}
                              label=""
                            />
                          )}
                        </Field>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='body1'>Remove members</Typography>
                        <Field name="remove_members_privilege" type="checkbox">
                          {({ input }) => (
                            <FormControlLabel
                              control={<Switch checked={input.checked} onChange={input.onChange} />}
                              sx={{ margin: 'unset' }}
                              label=""
                            />
                          )}
                        </Field>

                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='body1'>Promote/demote admins</Typography>
                        <Field name="promote_demote_admins_privilege" type="checkbox">
                          {({ input }) => (
                            <FormControlLabel
                              control={<Switch checked={input.checked} onChange={input.onChange} />}
                              sx={{ margin: 'unset' }}
                              label=""
                            />
                          )}
                        </Field>

                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='body1'>Edit goal info</Typography>
                        <Field name="edit_goal_info_privilege" type="checkbox">
                          {({ input }) => (
                            <FormControlLabel
                              control={<Switch checked={input.checked} onChange={input.onChange} />}
                              sx={{ margin: 'unset' }}
                              label=""
                            />
                          )}
                        </Field>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 0 }}>
                      <Typography variant='body2' fontWeight={600}>Financial privileges</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='body1'>Withdraw funds. See more</Typography>
                        <Field name="withdraw_privilege" type="checkbox">
                          {({ input }) => (
                            <FormControlLabel
                              control={<Switch checked={input.checked} onChange={input.onChange} />}
                              sx={{ margin: 'unset' }}
                              label=""
                            />
                          )}
                        </Field>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='body1'>Distribute goal funds</Typography>
                        <Field name="distribute_goal_funds_privilege" type="checkbox">
                          {({ input }) => (
                            <FormControlLabel
                              control={<Switch checked={input.checked} onChange={input.onChange} />}
                              sx={{ margin: 'unset' }}
                              label=""
                            />
                          )}
                        </Field>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant='body1'>Max. withdraw/transfer amount</Typography>
                      <Box onClick={handleOpenModal} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
                        <Typography variant="body2" align="right" sx={{ textDecoration: 'underline' }}
                        >
                          {values.max_withdraw_limit != null
                            ? values.is_withdraw_limit_percentage
                              ? `${values.max_withdraw_limit}%`
                              : numberWithCommas(values.max_withdraw_limit)
                            : 'N/A'}
                        </Typography>
                        <Typography variant='overline' sx={{ textDecoration: 'underline' }}>{member.currency}</Typography>
                      </Box>
                      {/* Modal */}
                      <Dialog open={openModal} onClose={handleCloseModal}>
                        <DialogTitle>
                          <Typography sx={{ textAlign: 'center' }}>Edit maximum withdrawable amount</Typography>
                        </DialogTitle>
                        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Field label="" name="max_withdraw_limit" type="number" component={FormInput} />
                          <Field name='is_withdraw_limit_percentage' type="checkbox">
                            {({ input }) => (
                              <Chip
                                label={input.checked ? 'Switch to amount' : 'Switch to percentage'}
                                size='small'
                                onClick={() => input.onChange(!input.checked)}
                                sx={{ cursor: 'pointer', color: '#1F1137', backgroundColor: '#F0EBF8', mt: 1.5 }}
                              />
                            )}
                          </Field>
                          <DialogActions>
                            <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center', gap: 1, mt: 1 }}>
                              <Button title="Cancel" className={classes.mediumButton} onClick={handleCloseModal} />
                              <Button title="Save" variant='contained'
                                className={classes.mediumButton} onClick={handleCloseModal} />
                            </Box>
                          </DialogActions>
                        </DialogContent>
                      </Dialog>
                    </Box>
                    <Box>
                      <Typography variant='body2' sx={{ marginBottom: '4px', fontWeight: 600 }}>Do you want to set a per transaction withdraw limit for them capped to the max withdraw amount above?</Typography>
                      <Field name="have_transaction_limit" component={RadioButtons} options={yesOrNoOptions} />
                    </Box>
                    {values.have_transaction_limit === 'yes' && (
                      <Field
                        label="Set a per transaction withdraw limit"
                        name="transaction_limit"
                        type="number"
                        component={FormInput}
                      />)}
                  </Box>
                )}
              <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center', gap: 1 }}>
                <Button title="Cancel" className={classes.mediumButton} />
                <Button title="Save" type="submit" variant='contained'
                  className={classes.mediumButton} />
              </Box>
            </form>
          )}
        </Form>

      </Box>
      <Toast {...updateToast} handleClose={handleCloseToast} />
    </Box>
  )
}

export default PromoteAdmin