import { useQuery, useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';
import userApiClient from "../apis/apiServices/users";
import { createNextOfKINData, editUserData, updatePasswordData, updatePINData, witnessData } from '../types/actionTypes';
import { KYCDocument, SearchResultUser, SecurityQuestion, Witness } from '../types/types';

export const useUser = () => {
  return useQuery(['user'], userApiClient.getUser);
};

export const useSecurityQuestions = () => {
  return useQuery<SecurityQuestion[],Error>(['security-questions'], userApiClient.getQuestions, {
    placeholderData: [],
  });
};

export const useGetKins = () => {
  return useQuery(['kins'], userApiClient.getKins, {
    placeholderData: [],
  });
};


export const useUpdatePassword = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: updatePasswordData) => userApiClient.updatePassword(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user']);
      },
    }
  );

  const updatePassword = async (data: updatePasswordData) => {
    try {
      const response = await mutation.mutateAsync(data);
      return response.data;
    } catch (error) {
      console.error('Error updating password:', error);
      throw new Error('Error updating password');
    }
  };

  return {
    updatePassword,
    ...mutation,
  };
};

export const useUserDocuments = (data: Partial<KYCDocument>) => {
  return useQuery<KYCDocument[], Error>(['user', 'documents', data], () =>
    userApiClient.getUserDocuments(data), {
    placeholderData: [],
  });
};

export const useSearchUsers = (
  searchFilter: { searchterm: string }
): UseQueryResult<SearchResultUser[]> => {
  return useQuery(['users', searchFilter], () =>
    userApiClient.searchUsers(searchFilter)
    , {
      placeholderData: [],
    });
};


export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation((params: editUserData) => userApiClient.updateUser(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
    },
  })

  const updateUser = async (params: editUserData) => {
    try {
      // Call the mutate function with the necessary data
      const response = await mutation.mutateAsync(params);
      return response.data;
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error updating user:', error);
      throw new Error('Error updating user');
    }
  };

  return {
    ...mutation,
    updateUser,
  };
}



export const useUploadProfileImage = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data: FormData) => userApiClient.uploadProfileImg(data),
    {
      // Optional: onSuccess function to invalidate relevant queries
      onSuccess: () => {
        queryClient.invalidateQueries(['user']); // Replace with the appropriate query key
      },
    });

  const uploadProfileImage = async (data: FormData) => {
    try {
      // Call the mutate function with the necessary data
      const response = await mutation.mutateAsync(data);
      return response?.data;
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error uploading profile image:', error);
      throw new Error('Error uploading profile image');
    }
  };

  return {
    ...mutation,
    uploadProfileImage,
  };
};



export const useDeleteNextOfKin = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (nextOfKinId: number) => userApiClient.deleteNextOfKin(nextOfKinId),
    {

      onSuccess: () => {
        queryClient.invalidateQueries(['kins']);
      },
    }
  );

  const deleteNextOfKin = async (nextOfKinId: number) => {
    try {
      await mutation.mutateAsync(nextOfKinId);

    } catch (error) {
      console.error('Error deleting next of kin:', error);
      throw new Error('Error deleting next of kin');
    }
  };

  return {
    deleteNextOfKin,
    ...mutation
  };
};

export const useCreateNextOfKin = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: createNextOfKINData) => userApiClient.createNextOfKin(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['kins']);
      },
    }
  );

  const createNextOfKin = async (data: createNextOfKINData) => {
    try {
      await mutation.mutateAsync(data);

    } catch (error) {
      console.error('Error creating next of kin:', error);
      throw new Error('Error creating next of kin');
    }
  };

  return {
    createNextOfKin,
    ...mutation
  };
};


export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (credentials: { pin: string; password: string }) =>
      userApiClient.deleteUser(credentials),
    {
      // Optional: onSuccess function to handle query invalidation or other actions
      onSuccess: () => {
        // Perform any necessary actions after successful deletion
        queryClient.invalidateQueries(['user']);
      },
    }
  );

  const deleteUser = async (credentials: { pin: string; password: string }) => {
    try {
      await mutation.mutateAsync(credentials);
    } catch (error) {
      console.error('Error deleting user:', error);
      throw new Error('Error deleting user');
    }
  };

  return {
    deleteUser,
    ...mutation,
  };
};



export const useUpdateSecurityQuestions = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((updatedQuestions: SecurityQuestion[]) => userApiClient.updateSecurityQuestions(updatedQuestions), {
    onSuccess: () => {
      queryClient.invalidateQueries(['security-questions']);
    },
  });

  const updateSecurityQuestions = async (updatedQuestions: SecurityQuestion[]) => {
    try {
      await mutation.mutateAsync(updatedQuestions);
    } catch (error) {
      console.error('Error updating security questions:', error);
      throw new Error('Error updating security questions');
    }
  };

  return {
    updateSecurityQuestions,
    ...mutation,
  };
};



export const useUpdatePin = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: updatePINData) => userApiClient.updatePin(data),
    {
      // Optional: onSuccess function to invalidate relevant queries
      onSuccess: () => {
        queryClient.invalidateQueries(['user']); // Replace with the appropriate query key
      },
    }
  );

  const updatePin = async (data: updatePINData) => {
    try {
      const response = await mutation.mutateAsync(data);
      return response.data;
    } catch (error) {
      console.error('Error updating PIN:', error);
      throw new Error('Error updating PIN');
    }
  };

  return {
    updatePin,
    ...mutation,
  };
};



export const useSocialConnections = () => {
  return useQuery(['socialConnections'], () => userApiClient.getSocialConnections(), {
    placeholderData: [],

  });
};




export const useDeleteSocialAccount = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (accountId: number) => userApiClient.deleteSocialAccount(accountId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['socialConnections']);
      },
    }
  );

  const deleteAccount = async (accountId: number) => {
    try {
      await mutation.mutateAsync(accountId);
    } catch (error) {
      console.error('Error deleting social account:', error);
      throw new Error('Error deleting social account');
    }
  };

  return {
    deleteAccount,
    ...mutation,
  };
};



export const useCreateSocialConnection = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((data: { platform: string, profileName: string }) =>
    userApiClient.addSocialConnection(data)
  );

  const createSocialConnection = async (data: { platform: string, profileName: string }) => {
    try {
      await mutation.mutateAsync(data);

      queryClient.invalidateQueries(['socialConnections']);
    } catch (error) {
      console.error('Error creating social connection:', error);
      throw new Error('Error creating social connection');
    }
  };

  return {
    createSocialConnection,
    ...mutation,
  };
};



export const useCreateKycDocument = () => {
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);

  const mutation = useMutation(
    (newDocument: FormData) => userApiClient.createKycDocument(newDocument, setProgress),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user', 'documents']);
      },
    }
  );

  const createKyc = async (newDocument: FormData) => {
    try {
      const document = await mutation.mutateAsync(newDocument);
      console.log("mutation result: " + document)
      return document;
    } catch (error) {
      console.error('Error creating KYC document:', error);
      throw new Error('Error creating KYC document');
    }
  };
  console.log(progress)

  return {
    createKyc,
    ...mutation,
  };
};

export const useUploadDocument = () => {
  const mutation = useMutation(
    (params: { newDocument: FormData; onUploadProgress: (progressEvent: ProgressEvent) => void }) =>
      userApiClient.uploadDocument(params.newDocument, params.onUploadProgress),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(['user', 'documents']);
      },
    }
  );

  const createDocument = async (newDocument: FormData, onUploadProgress: (progressEvent: ProgressEvent) => void) => {
    try {
      const document = await mutation.mutateAsync({ newDocument, onUploadProgress });
      console.log("mutation result: " + document);
      return document;
    } catch (error) {
      console.error('Error creating KYC document:', error);
      throw new Error('Error creating KYC document');
    }
  };

  return {
    createDocument,
    ...mutation,
  };
};

export const useFetchWitnesses = () => {
  return useQuery<Witness[], Error>(['witnesses'], () => userApiClient.getWitnesses(), {
    placeholderData: [],
  })
};


export const useCreateWitness = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newWitness: witnessData) => userApiClient.createWitness(newWitness),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['witnesses']);
      },
    }
  );

  const createWitness = async (newWitness: witnessData) => {
    try {
      const witness = await mutation.mutateAsync(newWitness);
      return witness
    } catch (error) {
      console.error('Error creating witness:', error);
      throw new Error('Error creating witness');
    }
  };

  return {
    createWitness,
    ...mutation,
  };
};

export const useDeleteWitness = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (id: number) => userApiClient.deleteWitness(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['witnesses']);
      },
    }
  );

  const deleteWitness = async (id: number) => {
    try {
      await mutation.mutateAsync(id);
    } catch (error) {
      console.error('Error creating witness:', error);
      throw new Error('Error creating witness');
    }
  };

  return {
    deleteWitness,
    ...mutation,
  };
};




export const useDeleteDocumentById = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (id: number) => userApiClient.deleteDocument(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user', 'documents']);
      },
    }
  );

  const deleteDocument = async (id: number) => {
    try {
      await mutation.mutateAsync(id);
    } catch (error) {
      console.error('Error deleting document:', error);
      throw new Error('Error deleting document');
    }
  };

  return {
    deleteDocument,
    ...mutation,
  };
};
