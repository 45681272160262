import { useStyles } from "../loginStyles";
import { Alert, Avatar, Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Field, Form } from 'react-final-form';
import RegisterBusiness from './RegisterBusiness';
import BusinessOwners from "./BusinessOwners";
import GovernmentKYC from "./GovernmentKYC";
import InvestmentPlan from "./InvestmentPlan";
import RegisterProfile from "./RegisterProfile";
import { ValidationErrors } from "final-form";
import RegisterEmployer from "./RegisterEmployer";
import { useCreateWaitlist } from '../../../hooks/waitlistHooks'
import dayjs from 'dayjs'
import DoneIcon from '@mui/icons-material/Done';
import Button from "../../../components/Reusables/Input/Button";
import InstitutionKYC from "./InstitutionKYC";


export interface RegisterFormValues {
    firstName: string;
    lastName: string;
    username: string;
    phoneNumber: string;
    dateOfBirth: string;
    address: string;
    email: string;
    businessOwnerType: string;
    type: string;
    business_type: string;
    trade_type: string;
    business_duration: string;
    business_name: string;
    location: string;
    government: any;
    institution: any;
    owners: any[] | string;
    targetAmount: string;
    durationOption: string;
    country: string;
    business_contact: string;
    business_email: string;
    business_mailing_address: string;
    employment_status: string;
    current_job: string;
    former_job: string;
    monthly_salary: number;
    job_duration: string;
    frequency: string;

}
const steps = ['User Profile', 'Business profile', 'Business Owners', 'Government KYC', 'Investment Plan'];

const RegisterStepper = () => {
    const classes = useStyles();
    const history = useHistory();
    const [activeStep, setActiveStep] = useState(0);
    const { createWaitlist, isLoading: loading, error } = useCreateWaitlist()


    const validate = (values: RegisterFormValues) => {
        const errors: ValidationErrors = {};
        if (activeStep === 0) {
            if (!values.firstName) {
                errors.firstName = "Please provide your first name";
            }

            if (!values.lastName) {
                errors.lastName = "Please provide your last name";
            }

            if (!values.phoneNumber && !values.email) {
                errors.phoneNumber = "Please provide either  your phone number or email";

            }
            if (!values.type) {
                errors.type = 'required'
            }

        }

        if (activeStep === 1 && values.type === 'business') {
            if (!values.business_type) {
                errors.business_type = 'required'
            }
            if (values.business_type === 'trading' && !values.trade_type) {
                errors.trade_type = 'required'
            }
            if (!values.business_duration) {
                errors.business_duration = 'required'
            }
            if (!values.location) {
                errors.location = 'required'
            }
            if (!values.business_name) {
                errors.business_name = 'required'
            }
        }

        if (activeStep === 1 && values.type === 'salary') {
            if (!values.business_type) {
                errors.business_type = 'required'
            }
            if (values.business_type === 'trading' && !values.trade_type) {
                errors.trade_type = 'required'
            }
            if (!values.job_duration) {
                errors.job_duration = 'required'
            }
            if (!values.location) {
                errors.location = 'required'
            }
            if (!values.business_name) {
                errors.business_name = 'required'
            }


        };

        if (activeStep === 5) {
            if (!values.targetAmount) {
                errors.targetAmount = 'required'
            }

            if (!values.durationOption) {
                errors.durationOption = 'required'
            }
        }
        return errors;
    };

    const handleNext = async (values: RegisterFormValues, form: any) => {
        if (activeStep === 1 && values.type === 'salary') {
            setActiveStep(3)
        } else if (activeStep === 3 && values.type === 'business') {
            setActiveStep(5)

        } else if (activeStep === 5) {
            try {
                await createWaitlist({
                    first_name: values.firstName,
                    last_name: values.lastName,
                    date_of_birth: dayjs(values.dateOfBirth).toDate(),
                    address: values.address,
                    email: values.email,
                    phone_number: values.phoneNumber,
                    government: values.government.id,
                    institution: values.institution.id,
                    is_employer: values.type === 'business',
                    business_type: values.business_type,
                    trade_type: values.trade_type,
                    business_duration: values.business_duration,
                    business_name: values.business_name,
                    location: values.location,
                    owners: values.owners as any[],
                    target_amount: values.targetAmount,
                    target_duration: `${values.durationOption}`,
                    country: values.country,
                    business_contact: values.business_contact,
                    business_email: values.business_email,
                    business_mailing_address: values.business_mailing_address,
                    employment_status: values.employment_status,
                    current_job: values.current_job,
                    former_job: values.former_job,
                    monthly_salary: values.monthly_salary,
                    job_duration: values.job_duration,
                    frequency: values.frequency,
                });
                setActiveStep(6)
                form.reset()

            } catch (error) {
                console.log(error)
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    };


    const handleBack = (type?: string) => {
        if (activeStep === 3 && type === 'salary') {
            setActiveStep(1)
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };


    return (
        <div className={classes.container}>
            <Box className={classes.logoContainer} onClick={() => history.push("/auth/login/email")}>
                <Avatar alt="Pasbanc" src="/logo.png" />
                <Typography variant='h5'>Pasbanc</Typography>
            </Box>
            <Form
                onSubmit={handleNext}
                validate={validate}
                render={({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit} >
                        {activeStep === 0 && (<RegisterProfile />)}
                        {activeStep === 1 && values.type === 'business' && (<RegisterBusiness values={values} handleBack={handleBack} />)}
                        {activeStep === 1 && values.type === 'salary' && (<RegisterEmployer handleBack={handleBack} />)}
                        {activeStep === 2 && (<Field name="owners">
                            {(props) => (<BusinessOwners   {...props} handleBack={handleBack} loading={false} error={'e dey work'} />)}
                        </Field>)}
                        {activeStep === 3 && (<Field name='government'>
                            {(props) => <GovernmentKYC handleBack={() => handleBack(values.type)} {...props} />}
                        </Field>)}
                        {activeStep === 4 && (<Field name='institution'>
                            {(props) => <InstitutionKYC handleBack={handleBack} {...props} />}
                        </Field>)}

                        {activeStep === 5 && (<InvestmentPlan handleBack={handleBack} loading={loading} />)}
                        {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                        {activeStep === 6 && (<Box>
                            <Success />
                            <Button variant="contained" style={{ width: "80%", margin: "0 auto", marginTop: 24 }} onClick={() => setActiveStep(0)} title='Okay' />
                        </Box>)}

                    </form>
                )}
            />
        </div>
    )
}

export default RegisterStepper


function Success() {

    return (
        <Box sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 3
        }}>
            <Avatar sx={{ bgcolor: '#22C55E', width: '5.6875rem', height: '5.6875rem' }}>
                <DoneIcon fontSize="large" />
            </Avatar>
            <Typography variant='h3' sx={{ mt: 3 }}>Success!</Typography>
            <Typography variant='caption' sx={{ textAlign: 'center', maxWidth: "21.625rem", width: "100%", margin: "0 auto" }}>
                {`Great news! You've successfully joined the investment waitlist. Our team will contact you soon with more information and the next steps. Stay tuned and thanks for your interest.`}

            </Typography>
        </Box>
    );
}