import api from "../api";
import { NOTIFICATIONS_ENDPOINT } from "../apiEndpoints";

const notificationsApiClient = {
  // Retrieve a list of all notifications
  getAll: async () => {
    const response = await api.get(`${NOTIFICATIONS_ENDPOINT}/`);
    return response.data.notifications;
  },
  // Update an existing blog post by ID
  updateById: async (data:{ids:number[]}) => {
    try {
      const response = await api.put(`${NOTIFICATIONS_ENDPOINT}/read/`, data);
      return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
};

export default notificationsApiClient;
