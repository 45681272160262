import { Alert, Avatar, Box, IconButton, Skeleton, Typography } from '@mui/material';
import { request } from 'https';
import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import users from '../../apis/apiServices/users';
import Button from '../../components/Reusables/Input/Button';
import FormInput from '../../components/Reusables/Input/FormInput';
import RadioButtons from '../../components/Reusables/Input/RadioGroup';
import { payOptions } from '../../components/Reusables/Input/select/Options';
import Select from '../../components/Reusables/Input/select/Select';
import { useDeposit } from '../../hooks/paymentHooks';
import { usePaymentMethods } from '../../hooks/paymentMethodHooks';
import { useGetPaymentRequestById } from '../../hooks/paymentRequestHook';
import { PaymentMethod } from '../../types/types';
import { capitalizeWords, numberWithCommas, removeCommas } from '../../utils/middleware';
import { MobilePayValues } from '../../types/actionTypes';


interface DepositFormValues {
 amount:string,
 method:string,
 phoneNumber:string,
 card:any
}

function PaymentRequestPage() {
  const { requestId, } = useParams<{requestId: string}>();
  const { data: paymentMethodsData,  } = usePaymentMethods(); 
  const cardMethods: PaymentMethod[] = paymentMethodsData?.card || []
  const { depositMoney,  error } = useDeposit();
  const history = useHistory();


  const { data: paymentRequest,isLoading,isError} = useGetPaymentRequestById(parseFloat(requestId));


  const validate = (values:DepositFormValues) => {
    const errors: Partial<DepositFormValues> = {};
    
    if(!values.amount){
      errors.amount = "required"
    }
   
    if (!values.method) {
      errors.method = "required";
    }

    if(values.method === 'mobile'){
      if(!values.phoneNumber){
        errors.phoneNumber = 'required'
      }
    }

     if(values.method === 'card'){
      if(!values.card){
        errors.card = 'required'
      }
    }
    return errors;
  };

 

  const handleSubmit = async (values:DepositFormValues) => {
    if (values.method === 'mobile' && paymentRequest) {
      /** Deposits made to groups are to the group wallets */
      const data = {
        amount: parseFloat(values.amount),
        phoneNumber: values.phoneNumber,
        type:  'wallet',
        id: paymentRequest.wallet.id as number,
        currency: paymentRequest.currency,
        url: history.location.pathname,
      };

      try {
        await depositMoney(data as MobilePayValues, values.method);
      } catch (error) {
        console.error('Error:', error);
      }
    } else if (values.method === 'card' && paymentRequest) {
      const data = {
        amount: parseFloat(values.amount),
        card: {
          id: values.card.id,
          token: values.card.card.token,
          country: values.card.card.country,
        },
        type:  'wallet' ,
        id: paymentRequest.wallet.id as number,
        currency: paymentRequest.currency,
        url: '/profile/notifications',
      };

      try {
        await depositMoney(data, values.method);
        
      } catch (error) {
        console.error('Error:', error);
      }
    }
    
  };

  return (
    <Box sx={{p:2,display:'flex',flexDirection:'column'}}>
      <Typography variant='h6' sx={{textAlign:'center'}}>Payment Request</Typography>
      <Box sx={{display:'flex',flexDirection:'column'}}>
      {isLoading || isError ? (
            <Skeleton variant="circular" sx={{mt:4,textAlign:'center'}}>
              <Avatar sx={{ width: 80, height: 80 }}/>
            </Skeleton>
          ) : (
            <Box sx={{m:'0 auto'}}>
            <Avatar sx={{ width: 80, height: 80,mt:4 }} src={paymentRequest.wallet.users?.profile_img} alt={paymentRequest.wallet.users?.first_name}/>
            {paymentRequest.wallet?.users && paymentRequest.wallet?.users.first_name && paymentRequest.wallet?.users.last_name && (<Typography variant="h5" mt={2}>{`${capitalizeWords(paymentRequest.wallet.users?.first_name)} ${capitalizeWords(paymentRequest.wallet.users?.last_name)}`}
            </Typography>)}
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" color="textSecondary">
                {paymentRequest.wallet.users?.username}
              </Typography>
             
            </Box>
            </Box>
          )}
      {paymentRequest && (
        <Box sx={{mt:5}}>
         <Box sx={{display:'flex',justifyContent:'space-between'}}>
          <Typography>Amount</Typography>
          <Typography variant='h5'>{numberWithCommas(paymentRequest.amount)}{" "}
           <Typography component='span' variant='caption'>{paymentRequest.currency}</Typography>
          </Typography>
         </Box>
         <Box mt={2}>
          <Typography variant='body2' color='textSecondary'>Description</Typography>
          <Typography>{paymentRequest.description}</Typography>
         </Box>
         
        </Box>
      )}
      </Box>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          method:'mobile'
        }}
        validate={validate}
        render={({ handleSubmit,values }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{display:'flex',flexDirection:'column',gap:2,mt:2}}>
            <Field name="amount" >
              {(props: any) => <FormInput
                label="Amount"
                type="text"
                name="amount"
                value={numberWithCommas(props.input.value)}
                onChange={(e) => {
                  props.input.onChange(removeCommas(e.target.value));
                 
                }}
                {...props}
              />}
            </Field>
            <Box>
              <Typography fontWeight={600}>Payment Method</Typography>
               <Field name="method" >
                {(props: any) => <RadioButtons
                  options={payOptions}
                  onChange={(e) => {
                    props.input.onChange(e.target.value);
                  }}
                  {...props}
                />}
              </Field>
            </Box>
            {values.method === 'mobile' ?
              <Field
                    label="Enter phone number"
                    name="phoneNumber"
                    component={FormInput}
                    type="string"
                  />
              :values.method === 'card'?
                <Field
                    cards={cardMethods}
                    name="card"
                    type="select"
                    component={Select}
                  />
              :null
            }
              {error && <Alert severity='error'>{(error as Error).message}</Alert>}
              <Box mt={1} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <Button  title='Back'  sx={{mr:1,backgroundColor:'#F0EBF8',width:'120px'}} onClick={()=>history.push(`/profile/notifications`)}/>
              <Button type="submit" title='Make payment' variant='contained'/>
            </Box>
            </Box>
          </form>
        )}
      />
    </Box>
  );
}

export default PaymentRequestPage;
