import React, { useState } from "react";
import Modal from "./Modal";
import Button from "../Reusables/Input/Button";
import FormInput from "../Reusables/Input/FormInput";
import { Form, Field } from "react-final-form";
import Toast from "../Reusables/Feedback/Toast";
import { telephoneCheck } from "../../utils/middleware";
import Alert from '@mui/material/Alert';
import { useCreatePaymentMethod } from "../../hooks/paymentMethodHooks";
import { Box } from "@mui/material";

interface Props {
  open: boolean;
  handleClose: () => void;
}

interface FormValues {
  phoneNumber:string
}

const AddPhoneNumber:React.FC<Props> =({open,handleClose,}) =>{
  const {createPaymentMethod,error,isLoading} = useCreatePaymentMethod();
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

   const handleCloseToast = (toastType:string) => {
     if (toastType === "update") {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    } else if (!telephoneCheck(values.phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phoneNumber";
    }
    return errors;
  };

  const onSubmit = async (values: FormValues) => {
  try {
    await createPaymentMethod({ method_type:'phone',phone_number:values.phoneNumber });
    setUpdateToast({
      open: true,
      message: "Phone number added successfully",
      type: "success",
    });
    handleClose()
  } catch (error) {
    console.log('failed to add phone number')
  }
}

  return <>
    <Modal
      title="Add Phone Number"
      open={open}
      handleClose={handleClose}
      fullWidth={true}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{mt:2,px:2}}>
            <Field name="phoneNumber" component={FormInput} type="number" label="Phone number"  />
            </Box>
            
           
            {error && <Alert severity="error">{(error as Error).message}</Alert>}
            <Box sx={{display:'flex',alignItems:'center',gap:0.5,alignSelf:'center',my:2}}>
              <Button title="Cancel"  onClick={handleClose} sx={{backgroundColor:'#F0EBF8'}}/>
              <Button
                title="Add"
                color="primary"
                variant="contained"
                type="submit"
                disabled={submitting || isLoading}
                loading={isLoading}
              />
            </Box>
          </form>
        )}
      />
    </Modal>
    
    <Toast {...updateToast} handleClose={() => handleCloseToast("update")} />

  </>;
}

export default AddPhoneNumber;
