import GoalItem from "../home/GoalItem";
import FlipMove from "react-flip-move";
import { useStyles } from "./GoalStyles";
import GoalSkeleton from "../Reusables/Feedback/skeletons/GoalSkeleton";
import noSearch from "../../assets/images/nosearch.png";
import { GoalItem as Goal } from "../../types/types";
import { useGoals } from "../../hooks/goalHooks";
import { Key,  } from "react";
import { Typography,Box } from "@mui/material";
import Fuse from "fuse.js";

interface GoalsProps {
  searchTerm: string;
  isTyping: boolean;
  searchFilter:string;
}

function Goals({
  isTyping,
  searchTerm,
  searchFilter
}:GoalsProps) {

  const { data, isLoading, isError } = useGoals();
  const classes = useStyles();
  
  if (isError) {
    return <Typography variant="body1" sx={{fontWeight:600,m:2,color:'#A1A1AA'}}>Error fetching goals</Typography>;
  }
  
  const options = {
    keys: ["name",'privacy','active'], 
    minMatchCharLength:3
  };


  const fuse = new Fuse(data ?? [], options);
  /**take note of the space to account for search filter */
  const filteredGoals = (searchTerm.length === 0 && searchFilter === "all" ) ? data : fuse.search(`${searchTerm} ${searchFilter}`).map((result) => result.item);
  const loading = isTyping || isLoading;
  const goalsToRender = searchTerm || searchFilter  ? filteredGoals : data ?? [];
  const noResults = searchTerm && filteredGoals?.length === 0;

  

  return (
    <div className={classes.goalsContent}>
      <div className={classes.container}>
        {loading ? (
          <div className={classes.myGoals}>
            {Array.from(new Array(2)).map((item: any, index: any) => (
              <GoalSkeleton key={index} />
            ))}
          </div>
        ) : noResults ? (
          <Box sx={{textAlign:'center',color:'#A1A1AA',mt:2}}>
            <img src={noSearch} alt="search icon" />
            <Typography variant='subtitle2'>No goals found, Try another key word</Typography>
          </Box>
        ) : goalsToRender && goalsToRender.length > 0 ? (
          <FlipMove className={classes.myGoals}>
            {
              /**here whenever a user types in the searchbar we display the search*/
              goalsToRender.map((goal: Goal, index: Key ) => (
                <GoalItem goal={goal} key={index} />
              ))
            }
          </FlipMove>
        ) : (
          <Box sx={{textAlign:'center',color:'#A1A1AA',mt:2}}>
            <Typography variant="body1" sx={{fontWeight:600}}>You don’t have any goals yet. Click + icon below to get started</Typography>
          </Box>
        )}
      </div>
    </div>
  );
}


export default Goals;
