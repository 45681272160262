import { Box, IconButton, Typography } from '@mui/material';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { RootState, Transaction } from '../../types/types';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Reusables/Input/Button';
import TransactionItem from '../../components/home/TransactionItem';
import { capitalizeWords, numberWithCommas } from '../../utils/middleware';
import dayjs from 'dayjs';

const logoUrl = `${process.env.PUBLIC_URL}/logo.png`;

// Import fonts if necessary (e.g., download them and place them in a folder)
 
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    padding: 20,
    
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  logo: {
    width: 60,
    height: 60,
    marginRight: 10,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign:'center'
  },
  transactionTable: {
    borderWidth: 1,
    borderColor: 'black',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: 'gray',
    padding: 10,
  },
  tableHeader: {
    flex: 1,
    fontWeight: 'bold',
    fontSize:14,
  },
  tableData: {
    flex: 1,
    fontSize:12
  },
  completedStatus: {
    color: 'green',
  },
  pendingStatus: {
    color: 'orange',
  },
  ongoingStatus: {
    color: 'blue',
  },
  failedStatus: {
    color: 'red',
  },
   fieldValuePair: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
    fontSize:12
  },
  field: {
    fontWeight: 'bold',
    marginRight: 10,
  },
  value: {
    // styles for the value
  },
  footer: {
  position:'absolute',
  textAlign:'center',
  bottom:10
}

  
 
 
  
});

const MyReceipt = ({ transactions }:{transactions:Transaction[]}) => {
    const singleTransaction = transactions.length === 1 ? transactions[0] : null;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Receipt Header */}
        <View style={styles.header}>
          <Image style={styles.logo} src={logoUrl} />
          <Text style={styles.title}>Pasbanc Investment Club</Text>
        </View>
   {transactions.length > 1 && (
       <View style={styles.transactionTable}>
  {/* Header Row */}
  <View style={styles.tableRow}>
    <Text style={styles.tableHeader}>User Name</Text>
    <Text style={styles.tableHeader}>Amount</Text>
    <Text style={styles.tableHeader}>Status</Text>
    <Text style={styles.tableHeader}>Date</Text>
    <Text style={styles.tableHeader}>Type</Text>
    <Text style={styles.tableHeader}>Payment method</Text>

    {/* Add headers for other columns as needed */}
  </View>

  {/* Transaction Rows */}
  {transactions.map((transaction, index) => (
    <View key={index} style={styles.tableRow}>
      <Text style={styles.tableData}>
        {transaction.user_name && capitalizeWords(transaction.user_name)}
      </Text>
      <Text style={styles.tableData}>
        {transaction.amount && numberWithCommas(transaction.amount)}{" "}
        {transaction.currency}
      </Text>
      <Text style={styles.tableData}>
        {transaction.status === 'completed' && (
          <Text style={styles.completedStatus}>Completed</Text>
        )}
        {transaction.status === 'pending' && (
          <Text style={styles.pendingStatus}>Pending</Text>
        )}
        {transaction.status === 'ongoing' && (
          <Text style={styles.ongoingStatus}>Ongoing</Text>
        )}
        {transaction.status === 'failed' && (
          <Text style={styles.failedStatus}>Failed</Text>
        )}
      </Text>
      <Text style={styles.tableData}>
        {dayjs(transaction.updated_at).format('MMM D, YYYY')}
      </Text>

       <Text style={styles.tableData}>
        {transaction.type}
      </Text>
       <Text style={styles.tableData}>
        {transaction.payment_method_name}
      </Text>
      
    </View>
  ))}
     </View>)}
 {/* Details for a Single Transaction */}
            {singleTransaction && (
              <View >
                <Text style={{textAlign:'center',fontSize:13}}>{`Payment #${singleTransaction.id}/ ${singleTransaction.status}`}</Text>
                <Text style={{textAlign:'center',fontSize:13,marginTop:5,marginBottom:15}}>{dayjs(singleTransaction.created_at).format('DD-MM-YYYY HH:mm:ss')}</Text>
               <View style={styles.fieldValuePair}>
                  <Text style={styles.field}>Transaction Type:</Text>
                  <Text style={styles.value}>{singleTransaction.type}</Text>
                </View>
                <View style={styles.fieldValuePair}>
                  <Text style={styles.field}>Amount:</Text>
                  <Text style={styles.value}>{numberWithCommas(singleTransaction.amount)} {singleTransaction.currency}</Text>
                </View>
                {singleTransaction.from && (
                   <View style={styles.fieldValuePair}>
                  <Text style={styles.field}>From:</Text>
                  <Text style={styles.value}> {singleTransaction.from}</Text>
                </View>
                )}
                 {singleTransaction.to && (
                   <View style={styles.fieldValuePair}>
                  <Text style={styles.field}>To:</Text>
                  <Text style={styles.value}> {singleTransaction.to}</Text>
                </View>
                )}
                 <View style={styles.fieldValuePair}>
                  <Text style={styles.field}>Customer Name:</Text>
                  <Text style={styles.value}> {singleTransaction.user_name}</Text>
                </View>
              </View>
            )}

            <Text style={styles.footer}>Thank you for saving with Pasbanc</Text>
      </Page>
    </Document>
  );
};



const PreviewAndDownload = () => {
  const transactions = useSelector((state:RootState) => state.transactions);
  const history = useHistory()

  const handleShareReceipt = async(pdfBlob: Blob | MediaSource) => {
  // Create a URL for the Blob
  const pdfUrl = URL.createObjectURL(pdfBlob);

  // Open a sharing dialog using the Web Share API (if available)
  if (navigator.share) {
    navigator.share({
      title: 'Transaction Receipt',
      text: 'Click the link to view the Pasbanc receipt',
      url: pdfUrl,
    })
      .then(() => console.log('Shared successfully'))
      .catch((error) => console.error('Error sharing:', error));
  } else {
    // Fallback if Web Share API is not available
    console.log('Web Share API not available. Implement custom sharing behavior.');
  }
};


  return (
    <div>
       <Box sx={{display:'flex',alignItems:'center',py:1,}}>
         <IconButton onClick={()=>history.goBack()}>
            <ArrowBackOutlinedIcon />
         </IconButton>
         <Typography variant='h6' fontWeight={600} sx={{textAlign:'center',m:'0 auto'}}>Preview</Typography>
        </Box>
        <Box sx={{px:1,maxWidth:'1000px',m:'0 auto'}}>
          {transactions.map((transaction: Transaction) => (
               <TransactionItem
               key={transaction.id}
               transaction={transaction as Transaction}
               
             />
            ))}
        </Box>
      {/* Download */}
       <PDFDownloadLink
        document={<MyReceipt transactions={transactions} />}
        fileName="Pasbancreceipt.pdf"
      >
        {({ blob, url, loading, error }) =>
        <Box sx={{mt:1,mb:2,display:'flex',justifyContent:'space-around',alignItems:'center'}}>
          <Button title='Print' sx={{backgroundColor:'#F0EBF8'}} loading={loading}/>
          {blob && (<Button title='Share' variant='contained' 
          onClick={()=>handleShareReceipt(blob)}
          sx={{fontSize: { xs: 'small', sm: 'small' }}}
          /> )}
        </Box>
           
        }
      </PDFDownloadLink> 
      
    </div>
  );
};

export default PreviewAndDownload