import { Alert, Avatar, Box, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useState } from 'react';
import Button from '../Reusables/Input/Button';
import DoneIcon from '@mui/icons-material/Done';
import MemberSelection from './MemberSelection';
import { useMemberSelection } from '../../hooks/customHooks';
import ModalWrapper from '../Modals/ModalWrapper';

interface AddMembersStepperProps {
  open: boolean;
  onClose: () => void;
  onAddMembers: (params: { id: number; members: number[]; }) => Promise<any>;
  id: number,
  name: string,
  type: 'goal' | 'group' | 'wallet' | 'pocket'
  error?: any
}


const AddMembersStepper: React.FC<AddMembersStepperProps> = ({ open, onClose, onAddMembers, id, name, type, error }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { selectedMembers, handleAddMember, handleRemoveMember } = useMemberSelection();



  const handleAddMembers = async () => {
    try {
      const selectedUserIds = selectedMembers.map((member) => member.pasbanc_id);
      await onAddMembers({ id, members: selectedUserIds as number[] });
      handleNextStep();
    } catch (error) {
      console.error('Error adding members:', error);
    }
  };


  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };


  const titles = [`Add member`, `Add ${selectedMembers.length} members`, 'Success!'];

  return (
    <ModalWrapper open={open} onClose={onClose} >
      <DialogTitle sx={{ pb: 0.5 }}>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{titles[currentStep]}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        {currentStep === 0 && (
          <Box p={1}>
            <MemberSelection
              selectedMembers={selectedMembers}
              onAddMember={handleAddMember}
              onRemoveMember={handleRemoveMember} />
            <Box sx={{ float: 'right', my: 2 }}>
              <Button title='Close' onClick={onClose} />
              <Button color='primary' title='Next' variant="contained" onClick={handleNextStep} disabled={selectedMembers.length === 0} />
            </Box>
          </Box>
        )}
        {currentStep === 1 && (
          <Box >
            <Typography variant="body1" sx={{ mt: 2 }}>
              {selectedMembers.length > 0 ? (
                `Are you sure you want to add ${selectedMembers.map((member) => member.username).join(', ')
                } to ${type}?`
              ) : (
                `No members selected.`
              )}
            </Typography>
            {error && <Alert severity="error" sx={{ px: 0 }}>{(error as Error).message}</Alert>}
            <Box sx={{ float: 'right', marginTop: 1 }}>
              <Button onClick={handlePrevStep} title='Back' sx={{ mr: 0.4 }} />
              <Button variant="contained" onClick={handleAddMembers} title='Add' />
            </Box>
          </Box>
        )}

        {currentStep === 2 && (
          <Box sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 3
          }}>
            <Avatar sx={{ bgcolor: 'primary.main', width: '80px', height: '80px' }}>
              <DoneIcon />
            </Avatar>
            <Typography variant='body2' sx={{ textAlign: 'center', color: 'grey.700', mt: 2 }}>
              {selectedMembers.length > 0 && (
                `You have successfully added  ${selectedMembers.map((member) => member.username).join(', ')
                } to ${name} ${type}.`
              )}
            </Typography>
            <Button
              title="Okay"
              color="primary"
              variant="contained"
              onClick={onClose}
              disableElevation
              sx={{ mt: 5, width: '100%' }} />
          </Box>
        )}
      </DialogContent>
    </ModalWrapper>
  );
};

export default AddMembersStepper;
