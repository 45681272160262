import { Pocket, PocketItem, PocketMember, RevolvingSettings } from "../../types/types";
import api from "../api";
import { POCKETS_ENDPOINT } from "../apiEndpoints";

const pocketsApiClient = {
  // Retrieve a list of all wallet pockets
  getAll: async (walletId:number) => {
    const response = await api.get(`${POCKETS_ENDPOINT}/wallet/${walletId}`);
    return response.data.pockets;
  },
  getTransactions: async (id:number,params:any) => {
    const response = await api.post(`${POCKETS_ENDPOINT}/transactions/${id}`,params);
    return response.data;
  },
  getRevolvingTransactions: async (id:number,params:any) => {
    const response = await api.post(`${POCKETS_ENDPOINT}/transactions/revolving/${id}`,params);
    return response.data;
  },
  
  getById: async (id:number) => {
    const response = await api.get(`${POCKETS_ENDPOINT}/pocket/${id}`);
    return response.data.pocket;
  },
  updateAdminAccess: async (id:number, data:{ admin_access:boolean}) => {
    try {
       const response = await api.put(`${POCKETS_ENDPOINT}/admin-access/${id}`, data);
      return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
   
  },
    create: async (data:Partial<PocketItem>) => {
      try {
         const response = await api.post(`${POCKETS_ENDPOINT}/create`, data);
        return response.data.pocket;
      } catch (error:any) {
        if (error.response && error.response.data) {
         throw error.response.data
        }
      }
   
  },
  // Update an existing goal  by ID
  updateById: async (id:number, data:Partial<Pocket>) => {
   try {
     const response = await api.put(`${POCKETS_ENDPOINT}/${id}`, data);
    return response.data;
   } catch (error:any) {
    if (error.response && error.response.data) {
     throw error.response.data
    }
   }
  },
  // Delete an existing goal by ID
  deleteById: async (id:number,data:{password:string}) => {
    try {
      await api.post(`${POCKETS_ENDPOINT}/delete/${id}`,data);
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
  },
  updateRevolving: async (id:number, data:Partial<RevolvingSettings>) => {
    try {
      const response = await api.put(`${POCKETS_ENDPOINT}/revolving-settings/${id}`, data);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  getRevolvingSchedule: async (id:number) => {
    const response = await api.get(`${POCKETS_ENDPOINT}/revolving-schedule/${id}`);
    return response.data.schedule;
  },
  getPayoutHistory: async (id:number) => {
    const response = await api.get(`${POCKETS_ENDPOINT}/payout-history/${id}`);
    return response.data.history;
  },
  addPocketMembers: async (id:number,members:number[]) => {
    try {
      const response = await api.post(`${POCKETS_ENDPOINT}/add-members/${id}`,{members});
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  getPocketMembers: async (id:number) => {
    const response = await api.get(`${POCKETS_ENDPOINT}/members/${id}`);
    return response.data.members;
  },
  getPocketMember: async (memberId:number) => {
    const response = await api.get(`${POCKETS_ENDPOINT}/members/member/${memberId}`);
    return response.data.member;
  },
  deletePocketMembers: async (pocketId:number,members:number[]) => {
    try {
      await api.post(`${POCKETS_ENDPOINT}/remove-members/${pocketId}`,{members});
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  promoteMember: async (pocketId:number, data:Partial<PocketMember>) => {
    try {
      const response = await api.put(`${POCKETS_ENDPOINT}/members/${pocketId}`, data);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
       throw error.response.data
      }
    }
    
  },
  joinPocket: async (pocketId:number) => {
    try {
      const response = await api.post(`${POCKETS_ENDPOINT}/join/${pocketId}`);
      return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
  
   },
  leavePocket: async (id:number) => {
    try {
      const response = await api.delete(`${POCKETS_ENDPOINT}/leave/${id}`);
      return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
  
  },

};

export default pocketsApiClient;
