import ReduxThunk from "redux-thunk"; 
import reducer from "./reducers/index";
import { configureStore } from '@reduxjs/toolkit';

/**this is the function that adds idempotency key to the necessary endpoints */
export const middlewares = [ReduxThunk];
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
