import React, { useState } from "react";
import Button from "../Reusables/Input/Button";
import Avatar from "@mui/material/Avatar";
import FormInput from "../Reusables/Input/FormInput";
import { useStyles } from "./ProfileStyles";
import { Form, Field } from "react-final-form";
import Toast from "../Reusables/Feedback/Toast";
import { User } from "../../types/types";
import { Box } from "@mui/material";
import { useUpdateUser, useUploadProfileImage } from "../../hooks/userHooks";

interface PersonalProfileProps {
  user: User;
  open: boolean;
  onClose: () => void;
}

interface FormValues {
  first_name: string;
  last_name: string;
  username: string;
  description: string;
}


const PersonalProfile: React.FC<PersonalProfileProps> = ({ user, open, onClose }) => {
  const { updateUser, isLoading: isUpdating } = useUpdateUser();
  const { uploadProfileImage, isLoading: isUploading } = useUploadProfileImage();


  const [updateToast, setUpdateToast] = useState({
    open: false,
    type: "success",
    message: "",
  });

  const [uploadToast, setUploadToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "upload") {
      setUploadToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    } else if (toastType === "update") {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const {
    profile_img,
    first_name,
    last_name,
    username,
    description,
  } = user;

  const photoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Call the uploadImage function from the useUploadGoalImage hook
        await uploadProfileImage(formData);
        setUploadToast({
          open: true,
          message: "Image uploaded successfully",
          type: "success",
        });
      } catch (error) {
        setUploadToast({
          open: true,
          message: "Failed to upload image",
          type: "error",
        });
      }
    }

  };

  const fileClick = () => {
    const fileInput = document.querySelector("#photoUpload") as HTMLInputElement;;
    fileInput.click();
  };
  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.username) {
      errors.username = "Required";
    }

    if (!values.first_name) {
      errors.first_name = "Required";
    }

    if (!values.last_name) {
      errors.last_name = "Required";
    }

    /* if (registration_method === "local") {
      if (!values.email) {
        errors.email = "Required";
      }
    } else if (registration_method === "phone") {
      if (!values.phone_number) {
        errors.phone_number = "Required";
      }
    }
    if (values.phone_number && !telephoneCheck(values.phone_number)) {
      errors.phone_number = "Please enter a valid phoneNumber";
    } */
    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    try {
      await updateUser(values);
      setUpdateToast({
        open: true,
        message: "User details updated successfully",
        type: "success",
      });
      onClose()
    } catch (error) {
      setUpdateToast({
        open: true,
        message: "Failed to update user details",
        type: "error",
      });
    }
  };

  const classes = useStyles();

  return (
    <Box sx={{ flex: { lg: 0.6 } }}>
      <div>
        <div className={classes.imageContainer}>
          <Avatar sx={{ width: 70, height: 70, mt: 2 }} src={profile_img} />
          <input
            accept="image/*"
            id="photoUpload"
            type="file"
            style={{ display: 'none' }}
            onChange={photoUpload}
          />
          <label htmlFor="photoUpload">
            <Button
              title="Edit picture"
              color="primary"
              onClick={fileClick}
              disabled={isUploading}
              loading={isUploading}
            />
          </label>
        </div>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{
            first_name,
            last_name,
            username,
            description,
          }}
          render={({ handleSubmit, submitting, pristine }) => (
            <form className={classes.formContainer} onSubmit={handleSubmit}>
              <Field
                name="first_name"
                component={FormInput}
                label="First name"
                type="text"
              />

              <Field
                label="Last name"
                type="text"
                name="last_name"
                component={FormInput}
              />

              <Field
                label="Username"
                type="text"
                name="username"
                component={FormInput}
              />

              <Field label="Bio" type="text" name="description" component={FormInput} />


              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.4, my: 2, alignSelf: 'center' }}>
                <Button title="Cancel" onClick={onClose} sx={{ width: '120px' }} />
                <Button
                  title="Save"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitting || pristine || isUpdating}
                  loading={isUpdating}
                  sx={{ width: '120px' }}
                />
              </Box>
            </form>
          )}
        />
      </div>
      <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
      <Toast {...updateToast} handleClose={() => handleCloseToast("update")} />

    </Box>
  );
}

export default PersonalProfile;
