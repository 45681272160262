import React, { useState } from "react";
import { useStyles } from "./SavingStyles";
import Collapsible from 'react-collapsible';
import { ProfileSettings } from "./ProfileSettings";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Alert, AlertTitle,  Box,  FormControlLabel, IconButton,Typography,Switch, MenuItem} from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {  useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Form, Field } from "react-final-form";
import FormInput from "../../components/Reusables/Input/FormInput";
import RadioButtons from "../../components/Reusables/Input/RadioGroup";
import { adminAccessOptions, adminOptions, contributionsType, durationOptions,  } from "../../components/Reusables/Input/select/Options";
import { useGoalById, useGoalMembers, useUpdateGoal } from "../../hooks/goalHooks";
import { calculateEndDate, calculateTotalAmountAccrued, convertMonthsToYears, convertYearsToMonths, numberWithCommas, removeCommas, renderInterestRate, stringToBoolean } from "../../utils/middleware";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Select from "../../components/Reusables/Input/select/Select";
import Button from "../../components/Reusables/Input/Button";
import { Goal } from "../../types/types";
import Toast from "../../components/Reusables/Feedback/Toast";
import Slide from '@mui/material/Slide';
import TransferOwnershipStepper from "../../components/members/TransferOwnership";
import DeleteGoal from "../../components/Modals/DeleteGoal";
import MuiSelect from '@mui/material/Select';
import CustomButton from "../../components/Reusables/Input/Button";
import CreateDeposit from '../../components/Modals/CreateDeposit';

interface RevolvingSettingsFormValues {
  revolving: string;
  
}

const RevolvingSettings = ({goal}:{goal:Goal}) => {
   const { isLoading: isUpdateLoading, updateGoal } = useUpdateGoal();
    const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType:string) => {
     if (toastType === "update") {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  

  const onSubmit = async (values: RevolvingSettingsFormValues) => {
    const data ={
      revolving: stringToBoolean(values.revolving),
    }
  try {
    await updateGoal({ id: goal.id, data:data  });
    setUpdateToast({
      open: true,
      message: "Goal updated successfully",
      type: "success",
    });
  } catch (error) {
    setUpdateToast({
      open: true,
      message: "Failed to update goal",
      type: "error",
    });
  }
}
  return (
    <div>
      <Box sx={{display:'flex',alignItems:'center',gap:0.5}}>
        <Typography variant='body1'>Revolving goal</Typography>
        <InfoOutlinedIcon fontSize='small'/>
      </Box>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          revolving:goal?.revolving
        }}
        render={({ handleSubmit,submitting,pristine}) => (
          <form  onSubmit={handleSubmit} 
          style={{
            display:'flex',
            flexDirection:'column',
            gap:8
          }}>
          <Field name="revolving" component={RadioButtons} options={adminOptions} />
         
         {goal.revolving && (
          <>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <Typography variant='body1'>Automatic revolving order</Typography>
            <Field name="kind" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Switch checked={true}  />}
                  sx={{margin:'unset'}}
                  label=""
                />
              )}
            </Field>
            </Box>
             <Box>
            <Typography >Revolving goal order</Typography>
            <Field name="kind" component={RadioButtons} 
            options={[
              {
                label:`By first come first served (contribution based)`,
                value:'robin'
              }
            ]} />
          <Alert icon={<InfoOutlinedIcon sx={{mr:-1}}/>} sx={{p:0}}>
            <AlertTitle>Note</AlertTitle>
            {`First come first served means that payouts are made out from the first member to the last member until everyone has received their turn of payout. However, contribution frequency of participants highly influences their ranking in payout selections`}
          </Alert>
            </Box>
          </>
         )}
         {!pristine && (
         <Box sx={{textAlign:'center',my:1}} >
              <Button
                title="Save changes"
                color="primary"
                variant="outlined"
                disabled={submitting || isUpdateLoading || pristine}
                type="submit"
                loading={isUpdateLoading}
              />
            </Box>
         )}
              

          </form>
            )}
          />
       <Toast {...updateToast} handleClose={() => handleCloseToast("update")} />
    </div>
  )
}


interface MoneySettingsFormValues {
  duration:string;
  target_amount:string;
  minimum_deposit:string;
  contribution_type:string;
  customDuration: number | null  ;

}

const MoneySettings = ({goal}:{goal:Goal}) => {
 const { isLoading: isUpdateLoading,updateGoal,error } = useUpdateGoal();

   const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType:string) => {
     if (toastType === "update") {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const validate = (values:MoneySettingsFormValues) => {
    const errors:Partial<MoneySettingsFormValues> = {};
    if(!values.duration){
      errors.duration = 'required'
    }
    if(parseFloat(values.duration) < goal.duration){
      errors.duration = 'Cant change to duration lower than what you currently have'
    }
   
    return errors;
  };

  const onSubmit = async (values: MoneySettingsFormValues) => {
    const data ={
      contribution_type:values.contribution_type,
      target_amount:values.target_amount ? removeCommas(values.target_amount) : '',
      duration: values.duration === 'year' ? convertYearsToMonths(values.customDuration ?? 0):parseFloat(values.duration),
      minimum_deposit:parseFloat(values.minimum_deposit)
    }
  try {
    await updateGoal({ id: goal.id, data: data });
    setUpdateToast({
      open: true,
      message: "Goal updated successfully",
      type: "success",
    });
  } catch (error) {
    setUpdateToast({
      open: true,
      message: "Failed to update goal",
      type: "error",
    });
  }
}

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          target_amount: goal?.target_amount,
          duration: goal?.duration > 12 ? 'year': goal.duration,
          customDuration:goal?.duration,
          minimum_deposit:goal?.minimum_deposit ,
          contribution_type:goal.contribution_type ?? 'directly'
        }}
        render={({ handleSubmit, values,submitting,pristine }) => (
          <form onSubmit={handleSubmit} 
          style={{
            display:'flex',
            flexDirection:'column',
            gap:8
          }}>
            <Field name="target_amount" >
              {(props: any) => <FormInput
                label="Target Amount"
                type="text"
                name="targetAmount"
                value={numberWithCommas(props.input.value)}
                onChange={(e) => {
                  props.input.onChange(removeCommas(e.target.value));
                }}
                {...props}
              />}
            </Field>
            
            <Box>
              <Typography fontWeight={600}>How to display contributions</Typography>
             <Field name='contribution_type'>
             {({ input }) => (
           <MuiSelect {...input} sx={{width:'100%',mt:1}}> 
          {contributionsType.map((option) => (
            <MenuItem value={option.value} key={option.value}>
            <div>
              <div style={{fontWeight:600}}>{option.label}</div>
              <div>{option.description}</div>
            </div>
          </MenuItem>
          ))}
        </MuiSelect>
    )}
  </Field>
    </Box> 
            <Field name="minimum_deposit" >
              {(props: any) => <FormInput
                label="Minimum deposit"
                type="text"
                value={numberWithCommas(props.input.value)}
                onChange={(e) => {
                  props.input.onChange(removeCommas(e.target.value));

                }}
                {...props}
              />}
            </Field>
            <Box>
              <Typography fontWeight={600}>Duration</Typography>
              <Field
                options={durationOptions}
                name="duration"
                type="select"
                component={Select}
              />
            </Box> 
             {values.duration === 'year' && (
            <Field name="customDuration">
              {(props: any) => <FormInput
                label="Enter duration(years)"
                value={props.input.value > 12 ? convertMonthsToYears(props.input.value):props.input.value}
                type="number"
                 {...props}
                />}
                </Field> 
              )}  
            <Field
              name="duration"
              subscription={{ dirty: true }}
              render={({ meta: { dirty } }) =>
                dirty && values.target_amount ? (
                   <Slide direction="down" in={dirty}>
                  <Alert severity='success' icon={false} sx={{p:1,color:'#fff',fontWeight:600}} variant="filled" >{`Saving ${
                    goal?.currency
                  } ${numberWithCommas(values.target_amount)} one time at ${
                    renderInterestRate(parseFloat(values.duration)).rate
                  } result in ${goal?.currency} ${numberWithCommas(
                    calculateTotalAmountAccrued({
                      period: values.duration === 'year' ?  convertYearsToMonths(values.customDuration ?? 0): parseFloat(values.duration) ,
                      amount: parseFloat(values.target_amount),
                    })
                  )} on ${calculateEndDate({
                    duration: values.duration === 'year' ?  convertYearsToMonths(values.customDuration ?? 0): parseFloat(values.duration),
                  })}`}</Alert>
                  </Slide>
                ) : null
              }
            />
            {!pristine &&  <Box sx={{textAlign:'center',my:1}} >
              <Button
                title="Save changes"
                color="primary"
                variant="outlined"
                disabled={submitting || isUpdateLoading || pristine}
                type="submit"
                loading={isUpdateLoading}
              />
            </Box>}
            
          </form>
        )}
      />
      <Toast {...updateToast} handleClose={() => handleCloseToast("update")} />

    </div>
  )
}

interface AdminSettingsFormValues {
  admin_access:string
}

const AdminSettings = ({goal}:{goal:Goal}) => {
  const { isLoading: isUpdateLoading,updateGoal,error } = useUpdateGoal();
  const { data: goalMembers } = useGoalMembers(goal.id);


   const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType:string) => {
     if (toastType === "update") {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [openStepper, setOpenStepper] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);


  const handleOpenStepper = () => {
    setOpenStepper(!openStepper);
  };
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  }

   

  const validate = (values:AdminSettingsFormValues) => {
    const errors:Partial<AdminSettingsFormValues> = {};
    if (!values.admin_access) {
      errors.admin_access = "Required";
    }
   
    return errors;
  };
  

  const onSubmit = async (values: AdminSettingsFormValues) => {
    const data ={
      ...values,
      admin_access: stringToBoolean(values.admin_access)
    }
  try {
    await updateGoal({ id: goal.id, data:data  });
    setUpdateToast({
      open: true,
      message: "Goal updated successfully",
      type: "success",
    });
  } catch (error) {
    setUpdateToast({
      open: true,
      message: "Failed to update goal",
      type: "error",
    });
  }
}

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          admin_access: goal?.admin_access,
        }}
        render={({ handleSubmit,submitting,pristine }) => (
            <form onSubmit={handleSubmit}>
            <Box>
              <Typography>Goal Type</Typography>
              <Field
                options={adminAccessOptions}
                name="admin_access"
                component={RadioButtons}
              />
            </Box>  
            <Alert severity="info" sx={{px:1}} icon={false} >
              <AlertTitle sx={{display:'flex',alignItems:'basline',gap:0.4}}>
                <InfoOutlinedIcon fontSize='small' sx={{fontSize:16}}/>
                <Typography variant='subtitle2'sx={{textDecoration:'underline'}}>Note:</Typography>
              </AlertTitle>
              <Typography variant='caption'>
                If everybody is in charge of their own funds(option 1), they will be able to withdraw their own funds as deposited with no limits. If admins are in charge of the funds(option 2), other users can only be able to deposit and view, unless in the case of revolving funds.
              </Typography>
            </Alert>
            <Toast {...updateToast} handleClose={() => handleCloseToast("update")} />
            {!pristine && <Box sx={{textAlign:'center',my:1}}>
              <Button
                title="Save changes"
                color="primary"
                variant="outlined"
                disabled={submitting || isUpdateLoading || pristine}
                type="submit"
                loading={isUpdateLoading}
              />
            </Box> }
            
          </form>
        )}
      />

     <Collapsible
     trigger={
      <Box
      sx={{...triggerBoxStyles }}
    >
        <Typography variant='body1' sx={{ fontWeight: 600 }}>
          {`Types of admin privileges`}
        </Typography>
      {isExpanded ? (
        <KeyboardArrowUp />
      ) : (
        <KeyboardArrowDown />
      )}
    </Box> }
    onOpening={handleToggle}
    onClosing={handleToggle}
    transitionTime={200}>
          <Box sx={{display:'flex',flexDirection:'column',gap:2}}>
            <Box>
              <Typography variant='body2' fontWeight={600}>{`Non-financial admins`}</Typography>
              <Typography variant='subtitle2' color={'grey.600'}>{`Non-financial admins can add and remove members
              and demode admins,verify/witness records,propose votes/proposals,are delegates,edit goal iinfo and any other non-financial rules as appointed`}</Typography>
            </Box>
            <Box>
              <Typography variant='body2' fontWeight={600}>{`Financial admins`}</Typography>
              <Typography variant='subtitle2' color={'grey.600'}>{`Financial admins can withdraw ,deposit and distribute goal funds ,verify/witness recors,and any toher financial rules as appointed`}</Typography>
            </Box>
          </Box>
        </Collapsible>
         <Box sx={{display:'flex',flexDirection:'column',gap:3}}>
          <Box sx={{display:'flex',alignItems:'start',justifyContent:'space-between',mt:2}}>
            <Box >
              <Typography variant='body1' fontWeight={700}>Transfer ownership</Typography>
              <Typography variant='subtitle1' sx={{lineHeight:'1.5'}}>{`Once you make the transfer,there is no going back.Please be certain`}</Typography>
            </Box>
            {openStepper && <TransferOwnershipStepper open={openStepper} onClose={handleOpenStepper} members={goalMembers ?? []} />}
            <Button title ='Transfer' color='error' variant='contained'  onClick={handleOpenStepper} sx={{width:'60%'}}/>
          </Box>
          <Box sx={{display:'flex',alignItems:'start',justifyContent:'space-between'}}>
            <Box>
              <Typography variant='body1' fontWeight={700}>Delete Goal</Typography>
              <Typography variant='subtitle1'>{`Once you delete the goal,there is no going back.Please be certain`}</Typography>
            </Box>
            <Button title ='Delete' color='error' variant='contained' sx={{width:'60%'}} onClick={()=>setOpenDeleteModal(!openDeleteModal)}/>
          </Box>
          {
            openDeleteModal && <DeleteGoal open={openDeleteModal} handleClose={()=>setOpenDeleteModal(false)}/>
          }
         </Box>
    </div>
  )
}

export const triggerBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // background: '#f5f5f5',
  p: 1,
  borderRadius: '4px',
  marginBottom: '8px',
  cursor: 'pointer',
  '&:hover': {
    background: '#e0e0e0',
  },
};




function EditGoal() {
  const { id } = useParams<{ id: string }>();
  const [openSections, setOpenSections] = useState<string[]>([]);
  const { data:goal } = useGoalById(parseFloat(id));
  const history = useHistory()
  const [openDeposit, setOpenDeposit] = useState(false);

  const handleToggle = (sectionId: string) => {
    setOpenSections((prevState: string[]) => {
      if (prevState.includes(sectionId)) {
        return prevState.filter((id) => id !== sectionId);
      } else {
        return [...prevState, sectionId];
      }
    });
  };
  
  const classes = useStyles();
 
  const handleDepositClick = () => {
    setOpenDeposit(true);
  };

  return (
    <div className={classes.editContainer}>
      <div className={classes.editHeader}>
      <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={()=>history.goBack()}
          >
            <ArrowBackIcon/>
          </IconButton>
        <h3>Settings</h3>
      </div>
      <Box sx={{maxWidth:"400px",m:"24px auto"}}>
        {goal && <ProfileSettings  goal={goal}/> }
      
      <Collapsible
      trigger={
        <Box sx={{ ...triggerBoxStyles }} onClick={() => handleToggle('section2')}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AutorenewIcon />
            <Typography variant='body1' sx={{ marginLeft: '8px' }}>
              Revolving Settings
            </Typography>
          </Box>
          {openSections.includes('section2') ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Box>
      }
      open={openSections.includes('section2')}
      transitionTime={200}
    >
      {goal &&   <RevolvingSettings goal={goal} />}
     
    </Collapsible>

  <Collapsible
    trigger={
      <Box sx={{ ...triggerBoxStyles }} onClick={() => handleToggle('section3')}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MonetizationOnIcon />
          <Typography variant='body1' sx={{ marginLeft: '8px' }}>
            Money Settings
          </Typography>
        </Box>
        {openSections.includes('section3') ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Box>
    }
    open={openSections.includes('section3')}
    transitionTime={200}
    >
      {goal && <MoneySettings  goal={goal} /> }
    
  </Collapsible>

    <Collapsible
    onClick={() => handleToggle('section4')}
      trigger={
        <Box sx={{ ...triggerBoxStyles }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AdminPanelSettingsIcon />
            <Typography variant='body1' sx={{ marginLeft: '8px' }}>
              Admin Settings
            </Typography>
          </Box>
          {openSections.includes('section4') ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Box>
      }
      open={openSections.includes('section4')}
      transitionTime={200}
    >
      {goal && <AdminSettings  goal={goal}/> }
      
    </Collapsible>
    </Box>
    <CustomButton title="Deposit" onClick={handleDepositClick} />
    <CreateDeposit
      open={openDeposit}
      onClose={() => setOpenDeposit(false)}
      preselectedGoal={{
        id: goal?.id,
        name: goal?.name,
        type: 'goal',
        currency: goal?.currency
      }}
    />
    </div>
  );
}

export default EditGoal;

