import  Receipt from "../pages/transactions/Receipt";
import { Route, Switch } from "react-router-dom";
import DownloadPage from "../pages/transactions/DownloadPage";
import Transactions from "../pages/transactions/Transactions";
import authGuard from "../utils/auth";


/**end of goal screens */
const TransactionRoutes = () => {
  return (
    <Switch>
      <Route  exact path="/transactions/download" component={authGuard(DownloadPage)} />
      <Route  exact path="/transactions/preview" component={authGuard(Receipt)} />
       <Route exact path="/transactions" component={authGuard(Transactions)} />
    </Switch>
  );
};

export default TransactionRoutes;
