import ProgressBar from "../Reusables/Feedback/ProgressBar";
import { numberWithCommas, renderInterestRate,calculateInterestEarned, calculateTotalAmountAccrued, removeCommas } from "../../utils/middleware";
import { Goal } from "../../types/types";
import { Box, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import IntervalTabs from "./IntervalTabs";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from "react";
import InfoDialog from "../Reusables/Feedback/InfoDialog";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface TargetCardProps {
  goal:Goal
}
function TargetCard({ goal }:TargetCardProps) {
  const [openInfo, setOpenInfo] = useState(false);
  const handleInfoDialog = () => {
  setOpenInfo(!openInfo);
};
  const [isSectionVisible, setIsSectionVisible] = useState(true);



  const { currency, actual_amount, target_amount, interest_earned, maturity_date } = goal;


  const {rate,duration}= renderInterestRate(goal.duration)
  const {interest}= calculateInterestEarned({period:goal.duration,amount:actual_amount})

  return (
    <Box>
      <Box sx={{textAlign:'center'}}>
        { target_amount && parseFloat(target_amount) > 0 && (
          <ProgressBar value={actual_amount} maxValue={parseFloat(target_amount)} />
        )}
      <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',mt:0.5}}>
      <Typography variant="body2">
      {target_amount && parseFloat(target_amount) > 0
        ? `Saved ${((actual_amount >= parseFloat(target_amount)) ? 100 : (actual_amount / parseFloat(target_amount)) * 100).toFixed(1)}%`
        : 'Saved 0% of target'}
    </Typography>
         <Box sx={{display:'flex',alignItems:'baseline',gap:0.1}}>
          <Typography variant='body1'>Target:</Typography>
          <Typography variant="h6" sx={{fontWeight:600}}>{` ${target_amount ? numberWithCommas(target_amount): 0}` }</Typography>
          <Typography variant='overline'>{currency}</Typography>
         </Box>
      </Box>
      <Typography variant="subtitle2" sx={{p:2,borderRadius:2,backgroundColor:'#FAFBFB',display:{lg:'none'},position:'relative'}}>
       {isSectionVisible ? (
      <>
      <Typography variant="subtitle2" sx={{ lineHeight: 'unset' }} 
      component='span'>{`Your est. total interest in`}{" "} <Typography fontWeight={600} variant="subtitle2" component="span" sx={{ lineHeight: 'unset' }}>{duration}</Typography></Typography>
      <Typography fontStyle={'italic'} variant="subtitle2" component="span">{`(${(dayjs(goal.maturity_date).subtract(goal.duration,'months')).format('DD MMM YYYY')} - ${dayjs(maturity_date).format('DD MMM YYYY')})`}</Typography>{"  "}
      <Typography fontWeight={600} variant="subtitle2" component="span">{`${rate} `}
      <Typography component='span' variant='overline'>{`P.A`}</Typography>
      </Typography>
       </>
      ) : (
        <Typography fontWeight={600} component='span' variant="subtitle2">{duration}
        <Typography component='span' variant="subtitle2">{`(${(dayjs(goal.maturity_date).subtract(goal.duration,'months')).format('DD MMM YYYY')} - ${dayjs(maturity_date).format('DD MMM YYYY')})...`}</Typography>
        </Typography>
      )}
       <IconButton sx={{ color:'inherit',position:'absolute',bottom:0,right:0}} size='small' onClick={()=>setIsSectionVisible(!isSectionVisible)}>
       {!isSectionVisible ? <VisibilityOffIcon sx={{ height:'18px',width:'18px'}} /> : <VisibilityIcon sx={{ height:'18px',width:'18px'}}/>}
      </IconButton>
    </Typography>
    <Typography variant="subtitle2" sx={{p:2,borderRadius:2,backgroundColor:'#FAFBFB',display:{xs:'none',lg:'block'},position:'relative'}}>
        {isSectionVisible ? (
      <>
     Your <Typography component='span' fontWeight={600} variant="subtitle2">{duration}</Typography> ({(dayjs(goal.maturity_date).subtract(goal.duration,'months')).format('DD MMM YYYY')} - {dayjs(goal.maturity_date).format('DD MMM YYYY')}) interest rate is <Typography component='span' fontWeight={600} variant="subtitle2">~{rate} P.A.</Typography> , resulting in a total interest amount of <Typography component='span' fontWeight={600} variant="subtitle2">~{numberWithCommas(interest)} <Typography variant='overline' component='span' fontWeight={600}>{currency}</Typography></Typography> and a maturity sum of <Typography component='span' fontWeight={600} variant="subtitle2">~
      {numberWithCommas(calculateTotalAmountAccrued({
      period: goal.duration,
      amount: actual_amount,                  
      })
    )}</Typography> <Typography variant='overline' component='span' fontWeight={600}>{currency}</Typography>
     </>
      ) : (
        <Typography fontWeight={600} component='span' variant="subtitle2">{duration}
        <Typography component='span' variant="subtitle2">{`(${(dayjs(goal.maturity_date).subtract(goal.duration,'months')).format('DD MMM YYYY')} - ${dayjs(maturity_date).format('DD MMM YYYY')})...`}</Typography>
        </Typography>
      )}
      <IconButton sx={{ color:'inherit',ml:2}} size='small' onClick={()=>setIsSectionVisible(!isSectionVisible)}>
       {!isSectionVisible ? <VisibilityOffIcon sx={{ height:'18px',width:'18px'}} /> : <VisibilityIcon sx={{ height:'18px',width:'18px'}}/>}
      </IconButton>
    </Typography>
 
      </Box>
      <Box>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <Typography variant="body2">Interest so far</Typography>
        <Typography variant='h6' sx={{fontWeight:700,color:'#006703'}}>{`+ ${numberWithCommas(interest_earned)} `}
        <Typography variant="overline" component='span'>{currency}</Typography>
        </Typography>
        </Box>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <Typography variant="body2">Est. total interest</Typography>
        <Typography variant='h6' sx={{fontWeight:700}}>{`${numberWithCommas(interest)}`}
        <Typography variant="overline" component='span'>{currency}</Typography>
        </Typography>
        
        </Box>
      </Box>
      <Box sx={{backgroundColor:'#F0EBF8',borderRadius:'6px',mt:1,p:1,
    display:'flex',flexDirection:'column',gap:0.5}}>
        <Box sx={{
          display:'flex',alignItems:'center',justifyContent:'space-between',
          }}>
          <Typography variant="subtitle2" sx={{fontWeight:600}}>Interest Overview</Typography>
          <InfoOutlinedIcon fontSize="small" onClick={handleInfoDialog}/>
        </Box>
        <Box>
        <IntervalTabs amount={actual_amount} currency={currency} duration={goal.duration}/>
        </Box>
      </Box>
      <InfoDialog
            title="Interest overview"
            content="This breaks down the total interest you are to earn over time."
            open={openInfo}
            handleClose={handleInfoDialog}
            width="auto"
            padding="0 0 24px 0"
          />
    </Box>
  );
}

export default TargetCard;
