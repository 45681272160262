import { PaymentDetailsStepProps } from "./PaymentDetailsStep";
import { Box, Typography, Avatar } from "@mui/material";
import FormInput from "../../Reusables/Input/FormInput";
import { Field } from "react-final-form";
import { numberWithCommas, removeCommas } from "../../../utils/middleware";

const RequestDetailsStep: React.FC<PaymentDetailsStepProps> = ({ selectedMember, balance }) => {
    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                {selectedMember && <Avatar alt={selectedMember.username} src={selectedMember.users?.profile_img} sx={{ bgcolor: 'primary.main', width: 60, height: 60, marginRight: 1 }}>{selectedMember.name.charAt(0)}</Avatar>}

                <Typography fontWeight={600}>{selectedMember.name}</Typography>
                <Typography variant='body2' sx={{ color: 'grey.600' }}>{(selectedMember.username ? selectedMember.username : selectedMember.users?.username)}</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Field name="amount" data-testid="amount">
                    {(props: any) => <FormInput
                        label="Amount"
                        type="text"
                        name="amount"
                        variant='standard' InputProps={{
                            endAdornment: `${balance.currency}`
                        }}
                        value={numberWithCommas(props.input.value)}
                        onChange={(e) => {
                            props.input.onChange(removeCommas(e.target.value));

                        }}
                        {...props}
                    />}
                </Field>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography fontWeight={600}>Reason</Typography>
                <Field name='description' component={FormInput} />
            </Box>
        </Box>
    )
}

export default RequestDetailsStep