import { Box, Typography } from "@mui/material";
import { useStyles } from "../loginStyles";
import { useHistory } from "react-router-dom";
import FormInput from "../../../components/Reusables/Input/FormInput";
import Button from "../../../components/Reusables/Input/Button";
import { Field } from 'react-final-form';
import DateInput from "../../../components/Reusables/Input/DateInput";
import RadioButtons from "../../../components/Reusables/Input/RadioGroup";

const RegisterProfile = () => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <Box className={classes.formContainer}>
            <Box maxWidth={"450px"} width="100%" sx={{ textAlign: 'center' }}>
                <Typography variant="h4">Tell us about yourself</Typography>
            </Box>

            <Box className={classes.nameContainer}>
                <Field name="firstName">
                    {(props: any) => (
                        <FormInput label="First Name" type="text" {...props} name="firstName" />
                    )}
                </Field>

                <Field name="lastName">
                    {(props: any) => (
                        <FormInput label="Last Name" type="text" {...props} />
                    )}
                </Field>
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field name="email">
                    {(props: any) => (
                        <FormInput label="Email" type="email" {...props} />
                    )}
                </Field>
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field name="phoneNumber">
                    {(props: any) => (
                        <FormInput label="Phone number" type="number" {...props} />
                    )}
                </Field>
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field name="address">
                    {(props: any) => (
                        <FormInput label="Address" type="text" {...props} />
                    )}
                </Field>
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Typography variant='body1'
                    sx={{ marginBottom: '4px', fontWeight: 600 }}>Birthday</Typography>
                <Field name="dateOfBirth" component={DateInput} label="" />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Typography variant='body1'
                    sx={{ marginBottom: '4px', fontWeight: 600 }}>Are you a business owner/salary contract employee?</Typography>
                <Field name="type">
                    {(props: any) => (
                        <RadioButtons
                            options={[{ value: 'business', label: 'Business Owner' }, { value: 'salary', label: 'Salary Contract Employee' }]}
                            {...props}
                        />
                    )}
                </Field>
            </Box>


            <Box className={classes.formBtnContainer}>
                <Button title='Back' variant='outlined' style={{ width: "49%" }} onClick={() => history.goBack()} />
                <Button title='Next' type='submit' variant='contained' style={{ width: "49%" }} />
            </Box>
        </Box>

    );
}

export default RegisterProfile;
