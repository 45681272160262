import React from "react";
import Checkbox from "@mui/material/Checkbox";
import {  FormControl, FormControlLabel, FormGroup, FormHelperText, Box } from "@mui/material";

import { FieldRenderProps } from 'react-final-form';



type CheckboxListSecondaryProps = FieldRenderProps<any, HTMLElement, any> & {
  options: { label: string; value: string }[];
  
};


function CheckboxList({
  options,
  input: { onChange, value },
  meta,
}:CheckboxListSecondaryProps) {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    const selectedValue = event.target.value;

    const updatedValue = newValue
      ? [...value, selectedValue]
      : value.filter((val: string) => val !== selectedValue);

    onChange(updatedValue);
  };
  

  return (
    <Box>
    <FormControl
      variant="standard"
      error={!!(meta?.error || meta?.submitError) && meta?.touched}
    >
      <FormGroup>
        {options.map((option,i) => (
              <FormControlLabel
              key={i}
              control={
                <Checkbox
                  checked={value.includes(option.value)}
                  onChange={handleChange}
                  value={option.value}
                />
              }
                label={option.label}
                
              />
            ))}
          
      </FormGroup>
      <FormHelperText>
        {meta?.touched ? meta?.error || meta?.submitError : ""}
      </FormHelperText>
    </FormControl>
    </Box>
  );
}

export default CheckboxList;
