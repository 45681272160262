import React, { useState } from 'react';
import { List, ListItem, IconButton, Typography, Box, Skeleton, useMediaQuery, Theme, Drawer } from '@mui/material';
import { NextOfKin } from '../../types/types';
import { useDeleteNextOfKin, useGetKins } from '../../hooks/userHooks';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Reusables/Input/Button';
import { capitalizeWords } from '../../utils/middleware';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreateNextOfKin from './CreateNextOfKin';
import ModalWrapper from '../../components/Modals/ModalWrapper';


const NextOfKinList = () => {
  // Fetch next of kin data
  const { data: nextOfKinData, isLoading, isError } = useGetKins();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [open, openModal] = useState(false)
  // Delete next of kin data
  const { deleteNextOfKin } = useDeleteNextOfKin();
  const history = useHistory();


  // Function to handle deleting next of kin data
  const handleDeleteNextOfKin = async (id: number) => {
    try {
      await deleteNextOfKin(id);

    } catch (error) {
      // Handle error or display an error message
      console.error('Error deleting next of kin:', error);
    }
  };



  return (
    <Box sx={{ flex: { lg: 0.7 } }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: 'space-between' }}>
        {!isDesktop && (
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
        )}

        <Typography fontWeight={600} sx={{ margin: '0 auto' }}>Next of kins</Typography>
        <Button title='Add new' onClick={() => openModal(true)} />
      </Box>
      {isError || isLoading ? <Box sx={{ pt: 0.5 }}>
        <Skeleton />
        <Skeleton width="60%" />
      </Box> :
        nextOfKinData.length === 0 ? (
          <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
            You have no next of kin. You can add one by clicking on the "Add new" button above.
          </Typography>
        ) : (
          <List sx={{ maxWidth: '500px', margin: '16px auto' }}>
            {nextOfKinData.map((kin: NextOfKin) => (
              <Box key={kin.id} sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2.5, px: 2 }}>
                <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                  <Typography fontWeight={600}>Name</Typography>
                  <Typography>{`${capitalizeWords(kin.first_name)}${kin.last_name ? ' ' + capitalizeWords(kin.last_name) : ''}`}</Typography>
                </ListItem>
                {kin.phone_number && (
                  <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                    <Typography fontWeight={600}>Phone number</Typography>
                    <Typography>{`${kin.phone_number}`}</Typography>
                  </ListItem>
                )}
                {kin.email && (
                  <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                    <Typography fontWeight={600}>Email address</Typography>
                    <Typography>{`${kin.email}`}</Typography>
                  </ListItem>
                )}
                <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                  <Typography fontWeight={600}>Relationship</Typography>
                  <Typography>{`${kin.relationship}`}</Typography>
                </ListItem>
                {kin.gender && (
                  <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                    <Typography fontWeight={600}>Gender</Typography>
                    <Typography>{`${kin.gender}`}</Typography>
                  </ListItem>
                )}
                {kin.pasbanc_username && (
                  <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                    <Typography fontWeight={600}>Pasbanc username</Typography>
                    <Typography>{`${kin.pasbanc_username}`}</Typography>
                  </ListItem>
                )}
                {kin.country && (
                  <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                    <Typography fontWeight={600}>Country of work</Typography>
                    <Typography>{`${kin.country}`}</Typography>
                  </ListItem>
                )}
                {kin.company_address && (
                  <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                    <Typography fontWeight={600}>Office Location</Typography>
                    <Typography>{`${kin.company_address}`}</Typography>
                  </ListItem>
                )}
                {kin.mailing_address && (
                  <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                    <Typography fontWeight={600}>P.O. Box mailing address</Typography>
                    <Typography>{`${kin.mailing_address}`}</Typography>
                  </ListItem>
                )}
                {kin.beneficiary_role && (
                  <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                    <Typography fontWeight={600}>Beneficiary role</Typography>
                    <Typography>{`${kin.beneficiary_role}`}</Typography>
                  </ListItem>
                )}

                <Button title='Delete' color='error' startIcon={<DeleteOutlineOutlinedIcon />} sx={{ backgroundColor: '#FFF7F8', mt: 2, alignSelf: 'end' }} onClick={() => handleDeleteNextOfKin(kin.id)} />
              </Box>
            ))}
          </List>
        )}
      <ModalWrapper open={open && !isDesktop} onClose={() => openModal(!open)} >
        <CreateNextOfKin open={open && !isDesktop} onClose={() => openModal(!open)} />
      </ModalWrapper>

      <Drawer anchor="right" open={open && isDesktop} onClose={() => openModal(!open)} sx={{
        '& .MuiPaper-root.MuiDrawer-paper': {
          width: '400px'
        }
      }}>
        <CreateNextOfKin open={open && !isDesktop} onClose={() => openModal(!open)} />
      </Drawer>
    </Box>
  );
};

export default NextOfKinList;
