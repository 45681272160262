import React from "react";
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';

function MemberSkeleton() {
  return (
     <Box sx={{ maxWidth: 345, my: 1 }}>
      <Box>
      <Skeleton animation="wave" variant="circular" width={40} height={40} />
       <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
      <Skeleton animation="wave" height={10} width="40%" />
      </Box>
    </Box>
    
  );
}

export default MemberSkeleton;
