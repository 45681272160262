import React from "react";
import Modal from "./Modal";
import Button from "../Reusables/Input/Button";
import { Avatar, Box, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';


interface MembershipPopupProps {
    onClose: () => void;
    open: boolean;
}

const MembershipPopup: React.FC<MembershipPopupProps> = ({ open, onClose }) => {
    return (
        <>
            <Modal open={open} handleClose={onClose} >
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 1.5 }}>
                    <Avatar sx={{ background: `linear-gradient(90deg, #FDFC47 0%, #24FE41 100%)`, width: 90, height: 90 }}>
                        <DoneIcon />
                    </Avatar>

                    <Typography sx={{ textAlign: 'center' }} variant='body2'>
                        {`Hello Pasbanc members! We've introduced a ONE-TIME lifetime membership fee of UGX 10,000 to support our services. This fee will help us keep providing you with the best experience. Thank you for your continued support!`}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 2 }}>
                        <Button title='OK' variant="contained" onClick={onClose} />
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default MembershipPopup;
