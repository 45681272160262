import { useInfiniteQuery ,useMutation,useQuery, useQueryClient} from '@tanstack/react-query';
import transactionsApiClient from "../apis/apiServices/transactions";



export const useInfiniteTransactions = (filter: any, hasSearchTerm: boolean) => {
  return useInfiniteQuery<any,Error>(['transactions', JSON.stringify(filter)], ({ pageParam = 0 }) => {
    const params = {
      ...filter,
      skip: pageParam,
    };
    return transactionsApiClient.getAll(params);
  }, {
    
    getNextPageParam: (lastPage, pages) => {
        if (lastPage.transactions && lastPage.transactions.length === 10) {
          // Calculate the next page based on the current pageParam
          const nextPage = pages.length * 10;
          return nextPage;
        }
        return null;
      },
    
   
  });
};
  
export const useTransactionById = (transactionId: number) => {
  return useQuery(['transactions', transactionId], () => transactionsApiClient.getById(transactionId));
};

export const useTransactionAnalytics = () => {
  return useQuery(['transactions-analytics',], transactionsApiClient.getAnalytics,{
    placeholderData:[],
  });
};

export const useUpdateTransaction = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (params: {id:number; data: any }) => transactionsApiClient.updateById(params.id,params.data),
    {
      // Optional: onSuccess function to invalidate the notifications query
      onSuccess: () => {
        queryClient.invalidateQueries(['transactions']);
      },
    }
  );

  const updateTransaction = async (params:{id:number, data:any}) => {
    try {
      // Call the mutate function with the necessary data
      const response = await mutation.mutateAsync(params);
      return response.data;
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error updating transaction', error);
      throw new Error('Error updating transaction');
    }
  };

  return {
    updateTransaction,
    ...mutation,
  };
};

