import { useMutation,useQueryClient } from '@tanstack/react-query';
import transfersApiClient from "../apis/apiServices/transfers";
import { GoalToGoalTransferData, GoalToPocketTransferData, PocketToPocketTransferData, SendMoneyToNonPasbancUser, SendMoneyToPasbancUser, WalletToGoalTransferData, WalletToPocketTransferData, WalletToWalletTransferData } from '../types/actionTypes';
import { Transaction } from '../types/types';


export const useGoalWalletTransfer = () => {
  const queryClient = useQueryClient();
  const walletToGoalMutation = useMutation(transfersApiClient.walletToGoal);
  const goalToWalletMutation = useMutation(transfersApiClient.goalToWallet);
  

  const transfer = async (transferData:WalletToGoalTransferData,type:'walletToGoal' | 'goalToWallet') => {
    try {
      if(type === 'walletToGoal'){
      await walletToGoalMutation.mutateAsync(transferData);
      }else if(type === 'goalToWallet'){
        await goalToWalletMutation.mutateAsync(transferData)
      }
       /**update wallet*/
       queryClient.invalidateQueries(['wallet']);
       queryClient.invalidateQueries(['user', 'wallet','total'])
       queryClient.invalidateQueries(['user','wallet','transactions'])
       queryClient.invalidateQueries(['group','wallet','transactions', transferData.walletId])
      
       /**update goal */
       queryClient.invalidateQueries(['goal',transferData.goalId])
       queryClient.invalidateQueries([['goal-transactions',transferData.goalId]])

      /**update transactions */
       queryClient.invalidateQueries(['transactions']); 
    } catch (error) {
      console.error('Transfer error:', error);
      throw new Error('Transfer error');
    }
  };
  
  return {
    goalWalletTransfer:transfer,
    loading: walletToGoalMutation.isLoading || goalToWalletMutation.isLoading,
    isError: walletToGoalMutation.isError || goalToWalletMutation.isError,
    error: walletToGoalMutation.error || goalToWalletMutation.error,
  };
};

export const useWalletPocketTransfer = () => {
  const queryClient = useQueryClient();
  const walletToPocketMutation = useMutation(transfersApiClient.walletToPocket);
  const pocketToWalletMutation = useMutation(transfersApiClient.pocketToWallet);

  const transfer = async (transferData:WalletToPocketTransferData, type:'walletToPocket' | 'pocketToWallet') => {
    try {
      if (type === 'walletToPocket') {
        await walletToPocketMutation.mutateAsync(transferData);
      } else if (type === 'pocketToWallet') {
        await pocketToWalletMutation.mutateAsync(transferData);
      }
        /**update wallet*/
       queryClient.invalidateQueries(['wallet']);
       queryClient.invalidateQueries(['user', 'wallet','total'])
       queryClient.invalidateQueries(['user','wallet','transactions'])
       queryClient.invalidateQueries(['group','wallet','transactions', transferData.walletId])
      
       /**update pocket */
       queryClient.invalidateQueries(['pocket',transferData.pocketId])
       queryClient.invalidateQueries([['pocket-transactions',transferData.pocketId]])

      /**update transactions */
       queryClient.invalidateQueries(['transactions']); 
    
    } catch (error) {
      console.error('Transfer error:', error);
      throw new Error('Transfer error');
    }
  };

  return {
    walletPocketTransfer: transfer,
    loading: walletToPocketMutation.isLoading || pocketToWalletMutation.isLoading,
    isError: walletToPocketMutation.isError || pocketToWalletMutation.isError,
    error: walletToPocketMutation.error || pocketToWalletMutation.error,
  };
};


export const usePocketGoalTransfer = () => {
  const queryClient = useQueryClient();
  const pocketToGoalMutation = useMutation(transfersApiClient.pocketToGoal);
  const goalToPocketMutation = useMutation(transfersApiClient.goalToPocket);

  const transfer = async (transferData:GoalToPocketTransferData, type:'pocketToGoal'| 'goalToPocket') => {
    try {
      if (type === 'pocketToGoal') {
        await pocketToGoalMutation.mutateAsync(transferData);
      } else if (type === 'goalToPocket') {
        await goalToPocketMutation.mutateAsync(transferData);
      }

      /**update pocket*/
       queryClient.invalidateQueries(['pocket',transferData.pocketId]);
       queryClient.invalidateQueries(['pocket-transactions',transferData.pocketId ])
      
      
       /**update goal */
       queryClient.invalidateQueries(['goal',transferData.goalId])
       queryClient.invalidateQueries([['goal-transactions',transferData.goalId]])

      /**update transactions */
       queryClient.invalidateQueries(['transactions']); 
      
    } catch (error) {
      console.error('Transfer error:', error);
      throw new Error('Transfer error');
    }
  };

  return {
    pocketGoalTransfer: transfer,
    loading: pocketToGoalMutation.isLoading || goalToPocketMutation.isLoading,
    isError: pocketToGoalMutation.isError || goalToPocketMutation.isError,
    error: pocketToGoalMutation.error || goalToPocketMutation.error,
  };
};

export const useGoalToGoalTransfer = () => {
    const queryClient = useQueryClient();
  
    const transferMutation = useMutation(transfersApiClient.goalToGoal, {
      onSuccess: (_,variables) => {
        // Invalidate relevant queries after a successful transfer
       queryClient.invalidateQueries(['goal',variables.senderGoalId])
       queryClient.invalidateQueries([['goal-transactions',variables.senderGoalId]])
       queryClient.invalidateQueries(['goal',variables.recipientGoalId])
       queryClient.invalidateQueries([['goal-transactions',variables.recipientGoalId]])
      },
    });
  
    const goalToGoal = async (transferData:GoalToGoalTransferData) => {
      try {
        await transferMutation.mutateAsync(transferData);
      } catch (error) {
        console.error('Goal to goal transfer error:', error);
        throw new Error('Goal to goal transfer error');
      }
    };
  
    return {
      goalToGoal,
      ...transferMutation
    };
  };



export const useWalletToWalletTransfer = () => {
    const queryClient = useQueryClient();
  
    const transferMutation = useMutation(transfersApiClient.walletToWallet, {
      onSuccess: (_,variables) => {
        // Invalidate relevant queries after a successful transfer
        /**update sender  wallet*/
       queryClient.invalidateQueries(['wallet']);
       queryClient.invalidateQueries(['user', 'wallet','total'])
       queryClient.invalidateQueries(['user','wallet','transactions'])
       queryClient.invalidateQueries(['group','wallet','transactions',variables.senderWalletId])
      
        /**update recipient  wallet*/
       queryClient.invalidateQueries(['group','wallet','transactions',variables.recipientWalletId])

      /**update transactions */
       queryClient.invalidateQueries(['transactions']); 
      },
    });
  
    const walletToWallet = async (transferData:WalletToWalletTransferData) => {
      try {
        await transferMutation.mutateAsync(transferData);
      } catch (error) {
        console.error('Wallet to wallet transfer error:', error);
        throw new Error('Wallet to wallet transfer error');
      }
    };
  
    return {
      walletToWallet,
     ...transferMutation
    };
  };


  export const usePocketToPocketTransfer = () => {
    const queryClient = useQueryClient();
  
    const transferMutation = useMutation(transfersApiClient.pocketToPocket, {
      onSuccess: (_,variables) => {
        // Invalidate relevant queries after a successful transfer
        queryClient.invalidateQueries(['pocket',variables.senderPocketId])
       queryClient.invalidateQueries([['pocket-transactions',variables.senderPocketId]])
       queryClient.invalidateQueries(['pocket',variables.recipientPocketId])
       queryClient.invalidateQueries([['pocket-transactions',variables.recipientPocketId]])
      },
    });
  
    const pocketToPocket = async (transferData:PocketToPocketTransferData) => {
      try {
        await transferMutation.mutateAsync(transferData);
      } catch (error) {
        console.error('Wallet to wallet transfer error:', error);
        throw new Error('Wallet to wallet transfer error');
      }
    };
  
    return {
      pocketToPocket,
     ...transferMutation
    };
  };



 