import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { numberWithCommas } from "../../utils/middleware";

interface WithdrawTabContentProps {
  total: number;
  currency: string;
}

export const WithdrawTabContent: React.FC<WithdrawTabContentProps> = ({ total, currency }) => {
  return <Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Total withdraws</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`- ${numberWithCommas(total)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>

  </Box>
};

interface DepositTabContentProps {
  total: number;
  currency: string
}

export const DepositTabContent: React.FC<DepositTabContentProps> = ({ total, currency }) => {
  return <Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Total deposits</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`+ ${numberWithCommas(total)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>

  </Box>
};

interface HomeTabContentProps {
  depositTotal: number;
  withdrawTotal: number;
  currency: string;
}

export const HomeTabContent: React.FC<HomeTabContentProps> = ({ depositTotal, withdrawTotal, currency }) => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return <Box display={"flex"} flexDirection={"column"} gap="0.62rem">
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Total deposits</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`+ ${numberWithCommas(depositTotal)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Total withdraws</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`- ${numberWithCommas(withdrawTotal)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Current balance</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`${numberWithCommas(depositTotal - withdrawTotal)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>
  </Box>
};
