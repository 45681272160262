
import React from 'react';
import { useParams } from 'react-router-dom';
import MemberProfile from '../../components/members/MemberProfile';
import { useGoalMemberById } from '../../hooks/goalHooks';

const GoalMember: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading, isError } = useGoalMemberById(parseFloat(id));

  return (
    <MemberProfile memberData={data} memberType="goal" isLoading={isLoading} isError={isError} />
  );
};

export default GoalMember;
