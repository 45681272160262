import React, { useState } from 'react';
import { Typography, Box, ListItem, ListItemText, List, ListItemIcon, IconButton,useMediaQuery,Theme } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useStyles } from "./ProfileStyles";
import { useHistory } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import InfoDialog from '../../components/Reusables/Feedback/InfoDialog';
const exchangeRatesData = [
  { currency: 'Unites States Dollar', code: 'USD', rate: '1 USD ≈ 50,000 UGX', flag: '🇺🇸' },
  { currency: 'Kenya Shilling', code: 'KSH', rate: '1 KSH ≈ 10,000 UGX', flag: '🇰🇪' },
  { currency: 'South African Rand', code: 'RND', rate: '1 RND ≈ 40,000 UGX', flag: '🇿🇦' },
  { currency: 'British Pound', code: 'GBP', rate: '1 GBP ≈ 80,000 UGX', flag: '🇬🇧' },
  { currency: 'Euro', code: 'EUR', rate: '1 EUR ≈ 80,000 UGX', flag: '🇪🇺' },
  { currency: 'Chinese Yuan', code: 'CNY', rate: '1 CNY ≈ 80,000 UGX', flag: '🇨🇳' },
  { currency: 'Japanese Yen', code: 'JPY', rate: '1 JPY ≈ 80,000 UGX', flag: '🇯🇵' },
];

const ExchangeRates = () => {
    const history = useHistory()
    const classes = useStyles();
    const [showInfo,setShowInfo]= useState(false)
    const isDesktop = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));
  return (
    <Box m={2}>
      <Box sx={{display:'flex',alignItems:'center',gap:1,mt:2}}>
        {!isDesktop && (
         <IconButton onClick={()=>history.goBack()} sx={{p:0}}>
            <ArrowBackOutlinedIcon  color='inherit' fontSize='small'/>
         </IconButton>
        )}
        
     <Typography variant="h5" fontWeight={600} sx={{m:'0 auto'}}>
        Exchange rates
      </Typography>
      <IconButton onClick={()=>setShowInfo(!showInfo)}>
      <InfoIcon  color='primary'/>
      </IconButton>
    </Box>
     <InfoDialog
            title="Exchange rate fees"
            content="Exchange fees ≈ 1%"
            open={showInfo}
            handleClose={()=>setShowInfo(false)}
            width="auto"
            padding="0 0 24px 0"
          />
      
        <List>
          {exchangeRatesData.map((item) => (
            <ListItem key={item.currency}>
              <ListItemIcon>
                {item.flag}
              </ListItemIcon>
              <ListItemText primary={item.currency} secondary={item.rate} />
            </ListItem>
          ))}
        </List>
    </Box>
  );
};

export default ExchangeRates;
