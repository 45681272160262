import React, { useState } from "react";
import Button from "../../components/Reusables/Input/Button";
import { Avatar, Box, Chip, IconButton, Typography } from "@mui/material";
import FormInput from "../../components/Reusables/Input/FormInput";
import { useStyles } from "./loginStyles";
import { Form, Field } from "react-final-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { telephoneCheck } from "../../utils/middleware";
import Alert from '@mui/material/Alert';
import { useLogin } from '../../hooks/authHooks';
import { Link, useHistory } from "react-router-dom";
import CustomLink from "../../components/Reusables/Input/CustomLink";
import { useStyles as useButtonStyles } from '../../components/Reusables/Input/ButtonStyles';
import CountryInput from "../../components/Reusables/Input/CountryInput";
import { ValidationErrors } from "final-form";


function MobileLogin() {

  const { loginByPhoneNumber, loading, error } = useLogin();
  const [loginMethod, setLoginMethod] = useState('password');

  const history = useHistory()
  const [showPassword, setPassword] = useState(false);


  const validate = (values: { phoneNumber: string; password?: string, pin?: string }) => {
    const errors: ValidationErrors = {};
    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    }
    if (!telephoneCheck(values.phoneNumber)) {
      errors.phoneNumber = "Please enter a valid UG phonenumber";
    }
    if (loginMethod === 'password' && !values.password) {
      errors.password = "Required";
    }
    if (loginMethod === 'pin' && !values.pin) {
      errors.pin = "Required";
    }

    return errors;
  };
  /**submit form data */
  const onSubmit = async (values: { phoneNumber: string; password?: string; pin?: string }) => {

    /**clear error before each new submission */
    const data = {
      [loginMethod]: loginMethod === 'password' ? values.password : values.pin,
      phonenumber: values.phoneNumber,
    };
    try {
      await loginByPhoneNumber(data);
      history.push("/", { fromLogin: true });
    } catch (error) {
      console.log(error);
    }
  };

  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  return (
    <div className={classes.container}>
      <Box className={classes.logoContainer}>
        <Avatar alt="Pasbanc" src="/logo.png" />
        <Typography variant='h5'>Pasbanc</Typography>
      </Box>
      <Box className={classes.formContainer}>
        <Box>
          <Typography variant="h2"> Log in</Typography>
          <Typography variant="body1">Start your Pasbanc journey here</Typography>
        </Box>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form className={classes.customForm} onSubmit={handleSubmit} >
              <Box className={classes.textFieldContainer}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <Typography variant="body1" fontWeight={600} textAlign="left">Phone number</Typography>
                  <Chip label={<Typography variant="button" component='span'>Use <Typography variant="body2" fontWeight={700} component='span'>email</Typography> instead</Typography>} component={Link} to={`/auth/login/email`} clickable />
                </Box>

                <Field name="phoneNumber" component={FormInput} type="number" label={''} />
              </Box>
              {loginMethod === 'pin' ?
                (
                  <div className={classes.textFieldContainer}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                      <Typography variant="body1" fontWeight={600}>PIN</Typography>
                      <Chip label={<Typography variant="button" component='span'>Use <Typography variant="body2" fontWeight={700} component='span'>password</Typography> instead</Typography>} onClick={() => setLoginMethod('password')} />
                    </Box>
                    <Field name="pin">
                      {(props: any) => <FormInput
                        type="text"
                        sx={{
                          // width: '18.75rem',
                          borderRadius: '0.5rem'
                        }}
                        {...props}
                      />}
                    </Field>

                    <CustomLink
                      variant="body2"
                      title="Forgot PIN?"
                      href="/auth/reset/pin/mobile"
                      sx={{ width: "auto", textAlign: 'left', mt: "0.5rem" }}
                    />
                  </div>
                )
                : (
                  <div className={classes.textFieldContainer}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography fontWeight={600}>Password</Typography>
                      <Chip label={<Typography variant="button" component='span'>Use <Typography variant="body2" fontWeight={700} component='span'>PIN</Typography> instead</Typography>} onClick={() => setLoginMethod('pin')} />
                    </Box>
                    <Field name="password">
                      {(props: any) => <FormInput
                        type={showPassword ? "text" : "password"}
                        Icon={
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setPassword(!showPassword)}
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        }
                        sx={{
                          // width: '18.75rem',
                          borderRadius: '0.5rem'
                        }}
                        {...props}
                      />}
                    </Field>
                    <div style={{ marginTop: "0.5rem" }}>
                      <CustomLink
                        variant="body2"
                        title="Forgot Password?"
                        href="/auth/reset/password/mobile"
                        sx={{ width: "auto", textAlign: 'left', mt: "0.5rem" }}
                      />
                    </div>
                  </div>
                )}

              {error && <Alert severity='error'>{(error as Error).message}</Alert>}

              <Box mt={"1.12rem"}>
                <Button
                  title="Log in"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  className={buttonClasses.largeButton}
                  disabled={submitting || loading}
                />
              </Box>

            </form>
          )}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
          <Typography variant='body2'>Don't have an account yet?</Typography>
          <Button
            variant="text"
            title="Create account"
            href="/auth/register/mobile"
            sx={{ mt: "0.56rem", backgroundColor: "#F0EBF8" }}
          />
        </Box>

      </Box>
    </div>
  );
}



export default MobileLogin;
