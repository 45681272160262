import React from 'react';
import {
    Box,
    DialogContent,
    Typography,
} from '@mui/material';
import { DialogContainer } from '../Reusables/Feedback/InfoDialog';
import Button from '../Reusables/Input/Button';

interface IncreaseLimitPopupProps {
    open: boolean;
    handleClose: () => void;
}

const IncreaseLimitPopup: React.FC<IncreaseLimitPopupProps> = ({
    children,
    open,
    handleClose,
}) => {

    const handleCloseLimit = () => {
        handleClose();
    };

    return (
        <DialogContainer open={open} onClose={handleCloseLimit}>
            <DialogContent>
                {children}
                <Typography variant="body1" fontWeight={600} sx={{ mb: "0.38rem" }}>
                Increase limits
                </Typography>
                <Box>
                    <Typography variant="body1" sx={{ mb: "0.5rem" }}>
                        To get higher loan limits, a longer payment duration and lower
                        interest rate;
                    </Typography>
                </Box>
                <Box sx={{ mb: "2.12rem", gap:"0.75rem" }}>
                    <Typography variant="body1">1. Pay your loans in a timely manner.</Typography>
                    <Typography variant="body1">2. Add business records regularly.</Typography>
                </Box>
                <Button
                    title="Okay"
                    variant="contained"
                    onClick={handleCloseLimit}
                    sx={{ width: '100%', height:"2.75rem" }}
                />
            </DialogContent>
        </DialogContainer>
    );
};

export default IncreaseLimitPopup;