import api from "../api";
import { BALANCES_ENDPOINT } from "../apiEndpoints";

const balancesApiClient = {
  // Retrieve a list of all balances
  getTotal: async () => {
    const response = await api.get(`${BALANCES_ENDPOINT}/balance`);
    return response.data.totalBalance;
  },
  getGoalsTotal: async () => {
    const response = await api.get(`${BALANCES_ENDPOINT}/balance/goals`, );
    return response.data.totalBalance;
  },
  
  getUserWalletTotal: async () => {
    const response = await api.get(`${BALANCES_ENDPOINT}/balance/user-wallet`, );
    return response.data.totalBalance;
  },
  
};

export default balancesApiClient;
