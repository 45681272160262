import React, { Component, ErrorInfo } from "react";


interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State>{
  constructor(props:Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error:Error, errorInfo:ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    console.error('Error:', error, 'ErrorInfo:', errorInfo);

    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      // Error path
      return (
        <div>
          <p>Something went wrong.</p>
          <details style={{ whiteSpace: "pre-wrap" }}>
            refresh to clear error
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
