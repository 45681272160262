import { Box, Typography, AvatarGroup, Badge, IconButton, Avatar } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useContacts } from '../../hooks/contactHooks'
import { ContactList } from '../contacts/ContactList'
import MemberSkeleton from '../Reusables/Feedback/skeletons/MemberSkeleton'
import FormInput from '../Reusables/Input/FormInput'
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Fuse from 'fuse.js'
import { Contact } from '../../types/types'



interface MemberSelectionProps {
  selectedMembers: Contact[];
  onAddMember: (data: any) => void;
  onRemoveMember: (memberId: number) => void;
}

const MemberSelection: React.FC<MemberSelectionProps> = ({ selectedMembers, onAddMember, onRemoveMember }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Contact[]>();
  const { data: contactsData, isLoading: contactsLoading, isError: contactsError } = useContacts();

  // Create a Fuse instance for searching
  const fuse = useMemo(
    () =>
      new Fuse(contactsData, {
        keys: ['name', 'username', 'phone_number'],
      }),
    [contactsData]
  );

  // Perform search based on the current search term and update the search results
  const updateSearchResults = (): void => {
    if (searchTerm) {
      const searchResults = fuse.search(searchTerm);
      setSearchResults(searchResults.map((result) => result.item as Contact));
    }
  };


  return (
    <div>
      <FormInput
        placeholder="Find members"
        type="text"
        Icon={<SearchIcon />}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          updateSearchResults();
        }}
      />
      {selectedMembers.length > 0 && (
        <Box sx={{ mt: 1 }}>
          <Typography>Selected Members</Typography>
          <AvatarGroup max={10} sx={{ flexDirection: 'row' }}>
            {selectedMembers.map((member) => (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={<IconButton   >  <CancelOutlinedIcon fontSize="small" /> </IconButton>} >
                <Avatar alt={'profile image'} src={member.profile_img ?? undefined}
                  sx={{ bgcolor: "primary.main", width: 30, height: 30 }}
                >{member.username.charAt(1)}</Avatar>
              </Badge>
            ))}
          </AvatarGroup>
        </Box>
      )
      }
      <Box sx={{ mt: 1.5 }}>
        <Typography sx={{ fontWeight: 600 }}>{`Contacts on Pasbanc (${contactsData && contactsData.length})`}</Typography>
        {contactsLoading ? (
          <div >
            {Array.from(new Array(1)).map((item, index) => (
              <MemberSkeleton key={index} />
            ))}
          </div>
        ) : contactsError ? (
          <div>
            <Typography variant="caption">Error occurred while fetching contacts.</Typography>
          </div>
        ) : (
          searchResults && searchResults.length > 0 ? (
            <ContactList contacts={searchResults} handleContactSelect={onAddMember} />
          ) : (
            <ContactList contacts={contactsData} handleContactSelect={onAddMember} />
          )
        )}
      </Box>
    </div>
  )
}

export default MemberSelection