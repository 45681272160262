import React, { forwardRef, useState } from "react";
import { capitalizeWords, generateTransactionMessage, numberWithCommas, truncateText } from "../../utils/middleware";
import { useStyles } from "./transactionStyles";
import dayjs from "dayjs";
import 'dayjs/locale/en';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { KYCDocument, Transaction as TransactionType } from "../../types/types";
import { Alert, Box, DialogActions, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem, ImageListItemBar, Radio, Theme, Typography, useMediaQuery } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import DownloadIcon from '@mui/icons-material/Download';
import Button from "../Reusables/Input/Button";
import { setTransactions } from "../../redux/reducers/transactionReducer";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import HourglassEmpty from "@mui/icons-material/HourglassEmpty";
import EditIcon from '@mui/icons-material/Edit';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FormInput from "../Reusables/Input/FormInput";
import { useUpdateTransaction } from "../../hooks/transactionHooks";
import { useCreateKycDocument, useDeleteDocumentById } from "../../hooks/userHooks";
import { Form, Field } from "react-final-form";
import { document_types } from "../Reusables/Input/select/Options";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Toast from "../Reusables/Feedback/Toast";
import CloseIcon from '@mui/icons-material/Close';
import ModalWrapper from "../Modals/ModalWrapper";
import DotSeparator from "../Reusables/DotSeparator";

dayjs.extend(localizedFormat);

interface TransactionProps {
  transaction: TransactionType,
  selectMode?: boolean;
  selected?: boolean;
  onSelect?: (transaction: TransactionType) => void;
}
const TransactionItem = forwardRef<HTMLDivElement, TransactionProps>(({ transaction, selectMode = false, selected, onSelect }, ref) => {
  const [edit, setEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [uploadDocument, setUploadDocument] = useState(false)
  const { updateTransaction, error } = useUpdateTransaction()
  const { deleteDocument, } = useDeleteDocumentById();

  const dispatch = useDispatch();
  const history = useHistory()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };

  const handleDeleteDocument = async (documentId: number) => {
    try {
      // Call the deleteDocument function from the hook
      await deleteDocument(documentId);
      setUpdateToast({
        open: true,
        message: "Document deleted",
        type: "success",
      });
    } catch (error: any) {
      setUpdateToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  const handleTransactionUpdate = async () => {
    try {
      await updateTransaction({ id: transaction.id, data: { description: note } })
      setEdit(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleTransactionCancel = async () => {
    try {
      await updateTransaction({ id: transaction.id, data: { status: 'canceled' } })
      setEdit(false)
    } catch (error) {
      console.log(error)
    }
  }


  const { amount, currency, status, source, destination, type, updated_at, user_name, created_at, delay, from, to, description, documents, fee_details, fee } = transaction;

  // Define the color based on the transaction status
  let color, bgColor;
  if (status === 'completed') {
    color = '#006703';
    bgColor = '#E4F7E8'
  } else if (status === 'pending') {
    color = '#EF6C00';
    bgColor = '#FFF3E0'
  } else if (status === 'ongoing') {
    color = '#021AEE';
    bgColor = '#F0F2FB'
  } else if (status === 'failed') {
    color = '#B7000C';
    bgColor = '#FFEAED'
  } else {
    color = '#F32E26';
  }

  const remainingTime = dayjs(created_at).add(delay ?? 0, 'hour').diff(dayjs(), 'minute');


  let remainingTimeDisplay = '';
  if (remainingTime >= 0) {
    if (remainingTime >= 60) {
      const remainingHours = Math.floor(remainingTime / 60);
      remainingTimeDisplay = `${remainingHours} ${remainingHours === 1 ? 'hour' : 'hours'}`;
    } else {
      remainingTimeDisplay = `${remainingTime} ${remainingTime === 1 ? 'minute' : 'minutes'}`;
    }
  }

  const [open, setOpen] = useState(false);
  const [note, setNote] = useState(description);
  const handleClose = () => {

    if (selectMode && onSelect) {
      onSelect(transaction);
    } else {
      setOpen(!open);
    }
  };



  const handleDownload = () => {
    const transactionData = [
      transaction
    ];

    // Dispatch the setTransactions action with the transaction data
    dispatch(setTransactions(transactionData));
    history.push(`/transactions/preview`)
  };

  const renderTransactionIcon = (transactionType: string) => {
    switch (transactionType) {
      case 'deposit':
        return <DoneIcon fontSize="large" />;
      case 'withdrawal':
        return <SyncAltIcon fontSize="large" />;
      case 'transfer':
        return <SyncAltIcon fontSize="large" />;
      default:
        return <SyncAltIcon fontSize="large" />;
    }
  };

  let message = generateTransactionMessage(transaction)

  const classes = useStyles();
  return (
    <Box
      ref={ref}
      className={`${classes.transactionContainer}`}
    >
      {selectMode && <Radio checked={selected} onChange={handleClose} name="radio-buttons" sx={{ alignSelf: 'end' }} />}
      <Box onClick={handleClose}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='body1'>
            {user_name && truncateText(capitalizeWords(user_name), 15)}
          </Typography>
          <Typography variant='body1' sx={{ fontWeight: status === "completed" ? 700 : 400 }} textAlign={"right"}>
            {amount && numberWithCommas(amount)}
            <Typography variant='overline' component={'span'}>
              {" "}{currency}
            </Typography>
          </Typography>

        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='body1' sx={{ fontWeight: 600 }}>
            {type && capitalizeWords(type)}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            {
              status === 'pending' && delay && remainingTime > 0 && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <HourglassEmpty fontSize="small" />
                <Typography variant="caption"> {`${remainingTimeDisplay}to go`}</Typography>
              </Box>
            }
            <div style={{ height: "1.0625rem", width: "auto", padding: "0.0.625rem", borderRadius: "1.5rem", backgroundColor: bgColor, display: "flex", justifyContent: "center", alignItems: "center", gap: "0.625rem", flexShrink: 0 }}>
              <Typography variant='overline' textTransform={"capitalize"} sx={{ color, fontWeight: 600 }}>
                {status}
              </Typography>
            </div>
          </Box>


        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

        }}>
          {source && (
            <Typography variant='caption' textAlign={"left"}>{`From ${from}`}</Typography>
          )}
          {destination && (
            <Typography variant='caption' textAlign={"left"}>{`To ${to}`}</Typography>
          )}
          <Typography variant='overline' textTransform={"initial"} textAlign={"right"}>
            {dayjs(updated_at).format("MMM D, YYYY - h:mmA")}
          </Typography>
        </Box>
      </Box>

      <ModalWrapper open={open} onClose={handleClose}>
        <Box sx={{ maxWidth: { md: '85%' }, width: "100%", margin: "auto" }}>
          {/* Render transaction type icon based on the 'type' */}
          <Box sx={{ backgroundColor: '#22C55E', width: "5.625rem", height: "5.625rem", borderRadius: '60%', display: 'flex', alignItems: 'center', justifyContent: 'center', m: '0 auto', mb: "2.06rem" }}>
            {renderTransactionIcon(type)}
          </Box>
          <Typography variant="body1" sx={{ textAlign: 'center', mb: "1.19rem" }}>{message}</Typography>
          <Box>
            <Typography variant="button" fontWeight={700} sx={{ mb: "0.38rem" }}>{`Transaction details`}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.2, mb: "0.38rem" }}>

              {
                source && (
                  <Typography variant="body1">{`From: ${from}`}</Typography>
                )
              }
              {
                destination && (
                  <Typography variant="body1">{`To: ${to}`}</Typography>
                )
              }

              <Typography variant="body1" textTransform={"capitalize"}>
                Status: {status}
              </Typography>
              {fee && Number(fee) > 0 && (
                <Typography variant="body1" textTransform={"capitalize"}>
                  Fee: {`${numberWithCommas(fee)}`}{" "}
                  <Typography component='span' variant='caption'>{currency}</Typography>
                </Typography>

              )}

              <Box display="flex" alignItems="center" gap="0.4375rem">
                <Typography variant="body1">
                  {`Initiated at: ${dayjs(created_at).format('h:mm A')}`}</Typography><DotSeparator />
                <Typography variant="body1">{`${dayjs(created_at).format('ddd D MMM YY')}`}
                </Typography>
              </Box>
            </Box>

            <Box>
              {description && <Typography variant="button" fontWeight={700} sx={{ mb: "0.38rem" }}>{`Transaction note`}
                <Typography component='span' sx={{ display: 'block', mb: "0.38rem" }} variant='body2'>{description}</Typography></Typography>

              }
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="button" fontWeight={700} sx={{ mb: "0.38rem" }}>Attached file(s)</Typography>
                {openDelete ? <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography sx={{ textDecoration: 'underline' }} onClick={() => setOpenDelete(false)}>Save</Typography>
                </Box> :
                  <Typography sx={{ textDecoration: 'underline' }} onClick={() => setOpenDelete(true)}>Edit</Typography>}
              </Box>
              {documents && (
                <ImageList sx={{ width: 500, height: 'auto' }} cols={4} gap={0}>
                  {documents.map((document: KYCDocument, i: React.Key) => (
                    <ImageListItem key={i} sx={{ width: 91, height: 80, borderRadius: 8, objectFit: 'cover', border: `0.5px solid  #F6F6F7` }}>
                      <ImageListItemBar
                        sx={{ background: 'transparent' }}
                        position="top"
                        actionIcon={
                          <IconButton
                            sx={{ padding: '0px', display: openDelete ? 'block' : 'none', color: 'red' }}
                            aria-label={`close ${document.document_name}`}
                            onClick={() => handleDeleteDocument(document.id)}
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                        actionPosition="left"
                      />
                      <img
                        srcSet={`${document.document_url[0].url}`}
                        src={`${document.document_url[0].url}`}
                        alt={`transaction attached document`}
                        style={{ objectFit: 'cover' }}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              )}
            </Box>
          </Box>
          <Box sx={{ justifyContent: 'stretch', mb: 1, display: "flex", gap: 1 }}>
            {fee_details && fee_details.description === 'pb' && status === 'pending' && (
              <Button onClick={handleTransactionCancel} color="primary" title='Cancel' variant='outlined' />
            )}
            {isDesktop ? <Button onClick={handleDownload} color="primary" title='Receipt' variant='outlined' sx={{ flexGrow: 0.7 }} /> : <IconButton onClick={handleDownload} >
              <DownloadIcon color='primary' />
            </IconButton>}
            <Button onClick={handleClose} color="primary" title='Close' variant='contained' sx={{ flexGrow: 1 }} />
          </Box>
          <Box sx={{ justifyContent: 'stretch', display: "flex", gap: 1 }}>

            <Button onClick={() => setEdit(!edit)} color="primary" title='Edit note' sx={{ flexGrow: 0.7, backgroundColor: '#F0EBF8' }} startIcon={isDesktop && <EditIcon />} />
            <Button onClick={() => setUploadDocument(!uploadDocument)} color="primary" title='Attach file' sx={{ flexGrow: 1, backgroundColor: '#F0EBF8' }} startIcon={isDesktop && <AttachFileIcon />} />
          </Box>
        </Box>
      </ModalWrapper>
      <ModalWrapper open={edit} onClose={() => setEdit(!edit)}>
        <DialogTitle>Edit Transaction Note</DialogTitle>
        <DialogContent>
          <FormInput onChange={(e) => setNote(e.target.value)} value={note} />
        </DialogContent>
        {error && <Alert severity='error'>{(error as Error).message}</Alert>}
        <Button title="Edit Note" onClick={handleTransactionUpdate} sx={{ m: '16px auto', width: '120px' }} variant='contained' />
      </ModalWrapper>
      <AddTransactionDocument open={uploadDocument} onClose={() => setUploadDocument(!uploadDocument)} transaction={transaction} />
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />
    </Box>
  );
});



export default TransactionItem;



interface FormValues {
  document_name: string;
  frontImage: File | string;
}

const AddTransactionDocument: React.FC<{ open: boolean; onClose: any, transaction: TransactionType }> = ({ open, onClose, transaction }) => {
  const { createKyc, error, isLoading } = useCreateKycDocument();


  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };


  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.document_name) {
      errors.document_name = 'Document name is required';
    }
    if (!values.frontImage) {
      errors.frontImage = 'Image is required';
    }
    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const formData = new FormData();
      formData.append('name', values.document_name);
      formData.append('type', document_types[4]);
      formData.append('transaction', transaction.id.toString());
      formData.append('documents', values.frontImage);
      await createKyc(formData);
      onClose()
      // Document created successfully, you can perform any further actions here
    } catch (error) {
      // Handle error
    }

  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Add transaction document`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        {/* Add form fields for adding a new governmental ID */}
        <Form onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Enter document name</Typography>
                  <Field name="document_name" component={FormInput} fullWidth />
                </Box>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach file</Typography>
                  <Field name="frontImage">
                    {({ input, meta }) => (
                      <>
                        <Box sx={{ borderRadius: 1.5, border: `1px solid  #BABFC3`, py: 2 }}
                          style={{
                            backgroundImage: `url(${values.frontImage ? URL.createObjectURL(values.frontImage as File) : null})`,
                            backgroundSize: 'cover',
                            backgroundPosition: "center"
                          }}>
                          <input
                            type="file"
                            accept="image/*"
                            id="frontPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);

                            }}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="frontPhotoUpload" >
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, opacity: values.frontImage ? 0.3 : 1 }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("front")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>
                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Button color="primary" title='Cancel' sx={{ mr: 2 }} onClick={onClose} />
                  <Button variant="contained" color="primary" type="submit" title='Save' loading={isLoading} />
                </Box>

              </Box>
            </form>
          )}
        </Form>
      </DialogContent>
    </ModalWrapper>
  );
}



