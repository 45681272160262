import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { motion } from "framer-motion";
import Skeleton from '@mui/material/Skeleton';
import { Avatar, Box,  IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery,Theme } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useUser } from "../../hooks/userHooks";
import Button from "../../components/Reusables/Input/Button";
import { Link, useHistory } from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { capitalizeWords } from "../../utils/middleware";
import PersonalProfile from "../../components/profile/PersonalProfile";
import { useState } from "react";
import { useStyles } from "./ProfileStyles";
import Modal from "../../components/Modals/Modal";
import  { ContactDetails } from "./Contact";
import  { SocialDetails } from "./SocialMedia";
import { KYCDetails } from "./KYC";
import NextOfKinList from "./NextOfKinList";

function Profile() {
    const [openProfile, setEditProfile] = useState(false);
    const isDesktop = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));
    const [activeTab, setActiveTab] = useState(0);

    const history = useHistory()

   const { data: user, isLoading,isError } = useUser();
   const handleCopyUsername = async() => {
     try {
    // Use the Clipboard API to copy the username to the clipboard
    await navigator.clipboard.writeText(user.username);
  } catch (error) {
    console.error('Error copying username to clipboard:', error);
  }
  };

  const handleContact=()=>{
    if(isDesktop){
     setActiveTab(1)
    }else{
     history.push(`/profile/contact`)
    }
  }

  const handleMedia=()=>{
     if(isDesktop){
     setActiveTab(2)
    }else{
     history.push(`/profile/social-media`)
    }
  }

   const handleKyc=()=>{
     if(isDesktop){
     setActiveTab(3)
    }else{
     history.push(`/profile/kyc`)
    }
  }

   const handleKin=()=>{
    if(isDesktop){
      setActiveTab(4)
    }else{
     history.push("/profile/contact/kins")
    }
   }

  
  
const classes = useStyles()
  return (
    <motion.div initial={{ opacity: 0.8 }} animate={{ opacity: 1 }}>
        <Box sx={{display:'flex',alignItems:'center',background:{lg:'#673AB7'},color:{lg:'#fff'},p:{xs:1,lg:2},height:{lg:'70px'},justifyContent:{lg:'space-between'}}}>
          <Typography fontWeight={600} sx={{margin:{xs:'0 auto',lg:'unset'}}} variant='h6'>Profile</Typography>
          <IconButton component={Link} to={`/`} sx={{color:'inherit'}}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.profileSection}>
        {!isDesktop ?(  
        <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',flex:{lg:0.8}}}>
        {
        isLoading || isError ? (
        <Skeleton variant="circular">
          <Avatar sx={{width:60,height:60,}} />
        </Skeleton>
      ) : (
        <Avatar src={user.profile_img} sx={{width:60,height:60,backgroundColor:'primary.main'}}>
          {user.first_name.charAt(0)}
            </Avatar>
      )}

       {
        isLoading || isError ? (
       <>
       <Skeleton variant="text" width={210} height={20} />
       <Skeleton variant="text" width={210} height={20} />

       </>
      ) : (
       <Box sx={{mt:2}}>
        <Typography variant='h6' sx={{textAlign:'center',lineHeight:'unset',fontWeight:600}}>{`${capitalizeWords(user?.first_name)} ${capitalizeWords(user?.last_name)}`}</Typography>
        <Box sx={{display:'flex',alignItems:'center',gap:0.4,justifyContent:'center'}}>
          <Typography>{`${user?.username}`}</Typography>
          <IconButton onClick={handleCopyUsername} sx={{p:0}}><ContentCopyIcon fontSize="small"/></IconButton>
        </Box>
        <Typography variant="body2" sx={{mt:1,textAlign:'center'}}>{user?.description}</Typography>
       </Box>
      )}
       
        <Button title='Edit Profile' startIcon={<EditIcon />} sx={{backgroundColor:'#FAFBFB',mt:2}} onClick={()=>setEditProfile(!openProfile)}/>
        </Box>
        ):activeTab === 0 ?(
         <PersonalProfile open={openProfile} user={user} onClose={()=>setEditProfile(false)}/>
        ):activeTab === 1 ?(<ContactDetails handleClick={handleKin} />):activeTab === 2 ? (<SocialDetails />): activeTab === 3 ? (<KYCDetails />):activeTab === 4 ? (<NextOfKinList/>):null}
        {openProfile && user && (
           <Modal open={openProfile} handleClose={()=>setEditProfile(false)} title='Edit Profile' fullWidth={true}>
             <PersonalProfile open={openProfile} user={user} onClose={()=>setEditProfile(false)}/>
           </Modal>
        )}

        <List sx={{flex:{lg:0.5}}}>
           <ListItem onClick={handleContact}
           sx={{ flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
            <ListItemIcon sx={{ minWidth: 'unset' }}>
            <ArrowForwardIosIcon  fontSize='small'/>
          </ListItemIcon>
          <ListItemText primary={`Contact information`} secondary={`Email addresses, phone number, location, next of kin`} />
          </ListItem>
           <ListItem onClick={handleMedia}
           sx={{ flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
            <ListItemIcon sx={{ minWidth: 'unset' }}>
            <ArrowForwardIosIcon  fontSize='small'/>
          </ListItemIcon>
          <ListItemText primary={`Social media accounts`} secondary={`Connect your social media accounts for verification and social payments`} />
          </ListItem>
          <ListItem onClick={handleKyc}
          sx={{ flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
            <ListItemIcon sx={{ minWidth: 'unset' }}>
            <ArrowForwardIosIcon  fontSize='small'/>
          </ListItemIcon>
          <ListItemText primary={`KYC`} secondary={`Your government, and institutionally issued documents, etc`} />
          </ListItem>
        </List>
        </Box>
      
    </motion.div>
  );
}

export default Profile;
