import React from 'react';
import { useHistory } from 'react-router-dom'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { numberWithCommas } from '../../utils/middleware';
import { useEffect, useState } from 'react';
import Button from '../../components/Reusables/Input/Button';
import { KeyboardArrowDown, KeyboardArrowUp, } from '@mui/icons-material';
import Collapsible from 'react-collapsible';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RepayLoan from '../../components/loans/RepayLoan';
import { useLoanDetails, useLoans } from '../../hooks/loanHooks';
import LoanHistory from './LoanHistory';
import { Loan } from '../../types/types';
import VerifiedIcon from '@mui/icons-material/Verified';
import Modal from '../../components/Modals/Modal';
import { Box, DialogContent, IconButton, Typography, useMediaQuery, Theme, Drawer, SvgIcon } from '@mui/material';
import IncreaseLimitPopup from '../../components/Modals/IncreaseLimitPopup';
import ApplyforLoanCards from '../../components/loans/ApplyforLoanCards';
import LoanOverviewCard from '../../components/loans/LoanOverviewCard';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ContentBox from '../../components/layout/ContentBox';
import LoanProfile from './LoanProfile'
import ModalWrapper from '../../components/Modals/ModalWrapper';
import HistoryIcon from "@mui/icons-material/History";
import { ReactComponent as RepayIcon } from "../../assets/svgs/Repay.svg";

export const loanCardStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  width: '45%',
  height: '190px',
  padding: '24px 16px',
  borderRadius: '8px',
  boxShadow: `-5px 2px 28px 0px rgba(0, 0, 0, 0.10)`,
  background: '#fff',
  gap: "0.5625rem"
};

const LoanHub = () => {
  const history = useHistory()
  const [openLoan, setOpenLoan] = useState(false);
  const [hasLoan, setHasLoan] = useState(true);
  const [hideBalance, setHideBalance] = useState(false);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [isTransactionsOpen, setIsTransactionsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const { data, isLoading, isError } = useLoanDetails();
  const { data: loans } = useLoans();
  const [openLimit, setOpenLimit] = useState(false);
  const [openRepay, setOpenRepay] = useState(false);

  const handleToggle = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  }

  const ongoingLoans = (loans ?? []).filter(loan => loan.status !== 'cleared')

  useEffect(() => {
    if (data?.hide_balance !== undefined) {
      setHideBalance(data.hide_balance);
    }
    if (data?.hide_balance !== undefined) {
      setHasLoan(data?.has_saver_loan)
    }

  }, [data?.hide_balance, data?.has_saver_loan]);





  return (
    <div>
      <Box sx={{ background: { xs: 'transparent', md: hasLoan ? '#F0EBF8' : '#673AB7' }, pb: { md: 2 }, mb: "1.25rem" }}>
        <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, pt: 2, px: 2 }}>
          <IconButton onClick={() => history.goBack()} sx={{ p: 0 }}>
            <ArrowBackOutlinedIcon />
          </IconButton>
          <Typography variant="body1" fontWeight={700} sx={{ m: '0 auto' }}>
            Lending
          </Typography>
        </Box>
        <ContentBox>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: `12px 0`, backgroundColor: hasLoan ? 'transparent' : (!hasLoan && !isDesktop) ? '#764EBE' : 'none', color: hasLoan ? '#000' : '#fff', borderRadius: 1, mt: 2, px: 2 }}>
            {hasLoan ?
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                      <Typography variant="body2" fontWeight={600}>Current loans</Typography>
                      <IconButton sx={{ color: '#000' }} size='small' onClick={() => setHideBalance(!hideBalance)}>
                        {hideBalance ? <VisibilityOffIcon sx={{ height: '18px', width: '18px' }} /> : <VisibilityIcon sx={{ height: '18px', width: '18px' }} />}
                      </IconButton>
                    </Box>
                    {!hideBalance ? (
                      <Typography variant="h3" >
                        {numberWithCommas(isLoading || isError ? 0 : data.totalUncleared)}
                      </Typography>
                    ) : (<Typography variant="h5">••••••</Typography>)}
                  </Box>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                      <Typography variant="body2" fontWeight={600}>Available bal.</Typography>
                      <IconButton sx={{ color: '#000' }} size='small' onClick={() => setHideBalance(!hideBalance)}>
                        {hideBalance ? <VisibilityOffIcon sx={{ height: '18px', width: '18px' }} /> : <VisibilityIcon sx={{ height: '18px', width: '18px' }} />}</IconButton>
                    </Box>
                    {!hideBalance ? (<Typography variant="h3" > {0}</Typography>) : (<Typography variant="h5">••••••</Typography>)}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1.5, width: "100%" }}>
                  <Button title='Repay' variant='contained' sx={{ height: "2.75rem", width: "100%" }} onClick={() => setOpenRepay(!openRepay)} />
                  <Button title='Withdraw' variant='contained' sx={{ height: "2.75rem", width: "100%" }} onClick={() => setOpenLoan(!openLoan)} disabled={true} />
                </Box>

                <Collapsible
                  trigger={
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                      <Typography fontWeight={600} variant='body1'>Loan Limits</Typography>
                      {isExpanded ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </Box>}
                  onOpening={handleToggle}
                  onClosing={handleToggle}
                  transitionTime={200} open={true}>

                  <LoanLimits loanLimit={isLoading || isError ? 0 : data.loanLimit} clearedAmounts={isLoading || isError ? 0 : data.totalCleared} />
                </Collapsible>
              </Box>
              :
              <>
                <Box>
                  <Typography variant="body1">Saver loan limit</Typography>
                  <Typography fontWeight={600} variant='h3'>{numberWithCommas(isLoading || isError ? 0 : data.loanLimit)}</Typography>
                </Box>
                <Typography variant="overline" textTransform={"initial"}>{`You're eligible for that estimated loan amount`}</Typography>
                <Button title='Increase limits' variant='contained' color="info" sx={{ borderRadius: '1rem', height: '2.125rem', width: '10.4375rem', padding: '0.625rem' }} onClick={() => setOpenLimit(!openLimit)} />
              </>}
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'center' }}>
            <Box onClick={() => setOpenRepay(!openRepay)} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
              <SvgIcon component={RepayIcon} sx={{ fill: '#000' }} />
              <Typography variant='subtitle2' fontWeight={600}>Repay Loan</Typography>
            </Box>
            <Box onClick={isDesktop ? () => setIsTransactionsOpen((prevState) => !prevState) : () => history.push(`/loans/history`)} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
              <HistoryIcon />
              <Typography variant='subtitle2' fontWeight={600}>History</Typography>
            </Box>
            <Box onClick={isDesktop ? () => setIsProfileOpen((prevState) => !prevState) : () => history.push(`/loans/profile`)} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
              <TextSnippetIcon />
              <Typography variant='subtitle2' fontWeight={600}>Loan profiles</Typography>
            </Box>
          </Box>
        </ContentBox>
      </Box>
      <ContentBox>
        {data && <ApplyforLoanCards data={data} loanCardStyles={loanCardStyles} />}

      </ContentBox>

      <Box>

        <ContentBox>
          <Box sx={{ m: { xs: `16px 0px`, md: '16px auto' } }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>Loan history</Typography>
                <Button variant="text" title="View all" onClick={isDesktop ? () => setIsTransactionsOpen((prevState) => !prevState) : () => history.push(`/loans/history`)} sx={{ color: 'black' }} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {ongoingLoans.length === 0 ? <Typography sx={{ color: 'grey.600', p: 2, textAlign: 'center' }}>No loans yet</Typography> : ongoingLoans.map(loan => (
                  <LoanOverviewCard loan={loan} key={loan.id} />
                ))}
              </Box>
            </Box>
          </Box>
        </ContentBox>
      </Box>
      <ModalWrapper open={isTransactionsOpen} onClose={() => setIsTransactionsOpen((prevState) => !prevState)}>
        <LoanHistory />
      </ModalWrapper>
      <ModalWrapper open={isProfileOpen} onClose={() => setIsProfileOpen((prevState) => !prevState)}>
        <LoanProfile />
      </ModalWrapper>
      <IncreaseLimitPopup open={openLimit} handleClose={() => setOpenLimit(false)} />
      <ModalWrapper open={openRepay && !isDesktop} onClose={() => setOpenRepay(false)}>
        <RepayLoan open={openRepay} onClose={() => setOpenRepay(false)} />
      </ModalWrapper>
      <Drawer anchor="right" open={openRepay && isDesktop} onClose={() => setOpenRepay(false)} sx={{
        '& .MuiPaper-root.MuiDrawer-paper': {
          width: '400px'
        }
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <RepayLoan open={openRepay} onClose={() => setOpenRepay(false)} />
        </Box>
      </Drawer>
    </div>
  )
}

export default LoanHub

const LoanLimits: React.FC<{ loanLimit: number; clearedAmounts: number }> = ({ loanLimit }) => {
  const [openLimit, setOpenLimit] = useState(false);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1, flexWrap: "wrap", gap: "13px" }}>
          <Box>
            <Typography variant="body2" fontWeight={600}>Saver loan limit</Typography>
            <Typography variant='h4' fontWeight={600}>{numberWithCommas(loanLimit)}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" fontWeight={600}>Business loan limit</Typography>
            <Typography variant='h4' fontWeight={600}>{numberWithCommas(400000)}</Typography>
          </Box>
          <Button title='Increase limits' variant='outlined' sx={{ borderRadius: '50px', height: '1.6875rem' }} onClick={() => setOpenLimit(!openLimit)} />
        </Box>

        <IncreaseLimitPopup open={openLimit} handleClose={() => setOpenLimit(false)} />

      </Box>
    </Box>
  )
}

export const LoanInfo: React.FC<{ open: boolean; onClose: any }> = ({ open, onClose }) => {
  return (
    <Modal open={open} handleClose={onClose}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
        <VerifiedIcon color='success' sx={{ height: '80px', width: '80px' }} />
        <Typography variant='body1' sx={{ textAlign: 'center' }}>
          {`You have exhausted your loan limit, Please repay your loan to become eligible for more loans`}
        </Typography>
        <Button title='Okay' onClick={onClose} />
      </DialogContent>
    </Modal>
  );
}