import React, { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

interface PageWrapperProps {
    children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    root: {
        position:"relative",
        width:"100vw",
        overflowX:"hidden",
    },
}));

const PageWrapper = ({ children }: PageWrapperProps) => {
    const classes = useStyles();
    return (
        <Grid className={classes.root} columnGap={0} rowGap={0}>
            {children}
        </Grid>
    );
}

export default PageWrapper;