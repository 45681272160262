import React, { useState } from "react";
import { Alert, Avatar, Box, Container, DialogContent, DialogTitle, FormControl, IconButton, Typography } from "@mui/material";
import { Field, Form } from "react-final-form";
import Button from "../Reusables/Input/Button";
import RadioButtons from "../Reusables/Input/RadioGroup";
import CloseIcon from '@mui/icons-material/Close';
import { calculateTransferFee, numberWithCommas, removeCommas, telephoneCheck } from "../../utils/middleware";
import { sendOptions } from "../Reusables/Input/select/Options";
import { Balance, Contact, SearchResultUser, Transaction } from "../../types/types";
import DoneIcon from '@mui/icons-material/Done';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import { useMemberSelection } from "../../hooks/customHooks";
import { FormApi, ValidationErrors } from "final-form";
import theme from "../../theme";
import ModalWrapper from "./ModalWrapper";
import PasbancUserStep from "../SendRequestMoney/steps/PasbancUserStep";
import PaymentDetailsStep from "../SendRequestMoney/steps/PaymentDetailsStep";
import NonPasbancUserStep from "../SendRequestMoney/steps/NonPasbancUserStep";
import RequestStepper from "../SendRequestMoney/RequestStepper";
import { LightStyledTab, LightStyledTabs } from "../Reusables/LightStyledTabs";

interface SendMoneyStepperProps {
  open: boolean;
  onClose: () => void;
  balance: Balance
}

const SendMoneyStepper: React.FC<SendMoneyStepperProps> = ({ open, onClose, balance }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <Box sx={{
        [theme.breakpoints.down('md')]: {
          maxWidth: "600px",
          minWidth: "500px",
          width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: "none",
          minWidth: "auto",
          width: 'auto'
        },
      }}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '0px' }}>
          <LightStyledTabs value={activeTab} onChange={handleTabChange}>
            <LightStyledTab label="Send to" />
            <LightStyledTab label="Request" />
          </LightStyledTabs>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 0.8, mt: 0 }}>

          {activeTab === 0 && <SendStepper balance={balance} onClose={onClose} />}
          {activeTab === 1 && <RequestStepper steps={steps} balance={balance} onClose={onClose} />}
        </DialogContent>
      </Box>
    </ModalWrapper>
  );
}

export default SendMoneyStepper;

export interface SendFormValues {
  user: SearchResultUser;
  userType: string;
  amount: string;
  description: string;
  paymentMethod: string;
  phoneNumber: string;
  bank: any;
  accountNumber?: string;
}

interface SendStepperProps {
  balance: Balance
  onClose: () => void;
}

const steps = ['User type', 'Select recipient', 'Payment Details', 'Confirm', 'Success!'];

const SendStepper: React.FC<SendStepperProps> = ({ balance, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { selectedMembers, handleAddMember } = useMemberSelection();
 
  const [transaction, setTransaction] = useState<Transaction | null>(null);

  const validate = (values: SendFormValues) => {
    const errors: ValidationErrors = {};

    if (activeStep === 0) {
      if (!values.userType) {
        errors.userType = 'required'
      }
    }

    if (activeStep === 1) {
      if (!values.user) {
        errors.user = "Required";
      }

      if (values.user) {
        const names = values.user.name.split(' ');
        if (names.length !== 2) {
          errors.user = 'Please enter two names separated by a space';
        }
      }

      if (values.userType === 'non-pasbanc') {
        if (!values.paymentMethod) {
          errors.paymentMethod = 'required'
        }
        if (values.paymentMethod === 'mobile') {
          if (!values.phoneNumber) {
            errors.phoneNumber = 'required'
          }
          if (!telephoneCheck(values.phoneNumber)) {
            errors.phoneNumber = "Please enter a valid phonenumber";
          }
        } else if (values.paymentMethod === 'bank') {
          if (!values.bank) {
            errors.bank = 'required'
          }
          if (!values.accountNumber) {
            errors.accountNumber = 'required'
          }
        }
      }

    }
    if (activeStep === 2) {
      if (!values.amount) {
        errors.amount = "Required";
      }
      if (values.amount && parseFloat(removeCommas(values.amount)) > balance.amount) {
        errors.amount = 'Insufficient funds'
      }
    }
    return errors;
  };

  const handleNext = async (values: SendFormValues) => {
    const { amount, userType, user, bank, accountNumber, description } = values
    

    if (activeStep === steps.length - 2) {
      try {
        const fee = calculateTransferFee(balance.currency, Number(amount))
       
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

      } catch (error) {
        console.log('errror')
      }


    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = (form: FormApi<SendFormValues, Partial<SendFormValues>>) => {
    setActiveStep(0);
    form.reset();
    onClose()
  };

  const handleShareClick = () => {
    // Use the Web Share API to trigger the native sharing dialog
    if (navigator.share && transaction) {
      const shareableLink = `${process.env.REACT_APP_API_URL}/transaction/${transaction.id}`;
      navigator.share({
        title: 'Pasbanc Transaction',
        text: `I just sent you money via Pasbanc! Check it out: ${shareableLink}`,
        url: shareableLink,
      })
        .then(() => console.log('Share successful'))
        .catch((error) => console.error('Error sharing:', error));
    }
  }

  return (
    <Container maxWidth="sm">
      <Form
        initialValues={{ userType: 'pasbanc' }}
        onSubmit={handleNext}
        validate={validate}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            {activeStep === 0 && (
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <Field name="userType" type="radio">
                  {(props: any) => (
                    <RadioButtons
                      options={sendOptions}
                      onChange={(e) => {
                        props.input.onChange(e.target.value);
                      }}
                      {...props}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "0.5rem"
                      }}
                      labelPlacement={'start'}
                      labelStyles={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                      }}
                    />

                  )}
                </Field>

              </FormControl>
            )}
            {activeStep === 1 && values.userType === 'pasbanc' &&
              <Field name="user" value={selectedMembers[0]} >
                {({ input, meta }) => (
                  <>
                    <PasbancUserStep
                      recipient={values.user}
                      onAddMember={(selectedContact) => {
                        input.onChange(selectedContact);
                        handleAddMember(selectedContact);
                        handleNext(values)
                      }}
                    />
                    <Typography color='error' variant='caption'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                  </>
                )}
              </Field>
            }
            {activeStep === 1 && values.userType === 'non-pasbanc' && <NonPasbancUserStep values={values} />}
            {activeStep === 2 && <PaymentDetailsStep selectedMember={values.user as Contact} balance={balance} />}
            {activeStep === 3 && <Box>
              <Typography sx={{ mt: 2 }}>
                You are about to send  <Typography component='span' fontWeight={600}> {numberWithCommas(values.amount)}</Typography> <Typography component='span' variant='overline'> {balance.currency}</Typography> to  <Typography component='span' fontWeight={600}>{values.user.name}</Typography>
                <Typography>Fee: {`${balance.currency} ${calculateTransferFee(balance.currency, Number(values.amount))}`}</Typography>
              </Typography>
            </Box>}
            {activeStep === 4 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar sx={{ bgcolor: '#22C55E', mt: 2, width: 60, height: 60 }}>
                <DoneIcon fontSize='large' />
              </Avatar>
              <Typography variant='h6' sx={{ mt: 1, fontWeight: 600 }}>Success!</Typography>
              <Typography textAlign={'center'}>Money has been sent successfully.User has been notified  </Typography>
            </Box>}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 3,
              }}
            >
              {activeStep === steps.length - 1 ? (
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                  <Button title='Copy Link' sx={{ width: '100%', mr: 1, backgroundColor: '#F0EBF8' }} onClick={handleShareClick} startIcon={<ContentPasteOutlinedIcon />} />
                  <Button title='Great' variant='contained' sx={{ width: '100%' }} onClick={() => handleReset(form)} />
                </Box>
              ) : (
                <>
                  {activeStep !== 0 && (
                    <Button color="primary" onClick={handleBack} title='Back' sx={{ backgroundColor: '#F0EBF8', mr: 1.5, width: '120px' }} />
                  )}
                  <Button variant="contained" type="submit"
                    title={activeStep === steps.length - 2 ? 'Send' : 'Next'}
                    sx={{ width: '120px' }}
                  />
                </>
              )}
            </Box>
          </form>
        )}
      />
    </Container>
  );
};
