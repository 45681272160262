import { useQuery,useMutation, useInfiniteQuery,useQueryClient } from '@tanstack/react-query';
import goalsApiClient from "../apis/apiServices/goals";
import { createGoalData, editUserGoalData, ExtendedGoalMember, ExtendedRevolvingSettings } from '../types/actionTypes';
import { GoalItem, PastRevolvingPayout, RevolvingSettings, TransactionData, UpcomingRevolvingPayout } from '../types/types';


export const useGoals = () => {
  return useQuery<GoalItem[],Error>(['goals'], goalsApiClient.getAll,{
    placeholderData: [],

  });
};

export const useGoalById = (goalId: number) => {
    return useQuery(['goal', goalId], () => goalsApiClient.getById(goalId));
 };


export const useGoalTransactions = (filter: any, hasSearchTerm: boolean,goalId:number) => {
    return useInfiniteQuery<TransactionData, Error>(['goal-transactions', JSON.stringify(filter),goalId], ({ pageParam = 0 }) => {
     
      const params = {
        ...filter,
        skip: pageParam
      };
      return  goalsApiClient.getTransactions(goalId,params);
    }, {
      refetchInterval: 2 * 60 * 1000, // 2 minutes in milliseconds
      placeholderData:{ pageParams: [undefined, 1], pages: []},
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || !lastPage.transactions || lastPage.transactions.length < 10) {
          return null;
        }
        return pages.length * 10;
      },
    });
  };

  

  export const useGoalNotifications = (goalId: number) => {
    return useQuery(['goal-notifications', goalId], () => goalsApiClient.getNotifications(goalId),{
      placeholderData:[]
    });
  };

  

export const useUploadGoalImage = () => {
    const queryClient = useQueryClient();

  const mutation = useMutation((params:{id:number, data:FormData}) => goalsApiClient.uploadImage(params.id, params.data),{
      onSuccess:(_,variables)=>{
        queryClient.invalidateQueries(['goal', variables.id])
      }
    });

  const uploadImage = async (params:{id:number, data:FormData}) => {
    try {
      // Call the mutate function with the necessary data
      const response = await mutation.mutateAsync(params);
      return response.data;
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error uploading the image:', error);
      throw new Error('Error uploading the image');
    }
  };

  return {
    // Return the mutation function and loading/error states
    ...mutation,
    uploadImage,
  };
}

  
  export const useUpdateGoal = () => {
   const queryClient = useQueryClient();
  const mutation = useMutation((params: { id: number,data:editUserGoalData }) =>goalsApiClient.updateById(params.id, params.data),{
      onSuccess:(_,variables)=>{
        queryClient.invalidateQueries(['goal', variables.id])
      }
    });

  const updateGoal = async (params: { id: number,data:editUserGoalData }) => {
    try {
      const response = await mutation.mutateAsync(params);
      return response.data;
    } catch (error) {
      console.error('Error updating the goal:', error);
      throw new Error('Error updating the goal');
    }
  };

  return {
    
    ...mutation,
    updateGoal,
  };
}
  
  export const useUpdateGoalAdminAccess = () => {
  const mutation = useMutation<void, Error, { id: number; data: { admin_access: boolean } }>(
    ({ id, data }) => goalsApiClient.updateAdminAccess(id, data)
  );

  const updateAdminAccess = async (id: number, data: { admin_access: boolean }) => {
    try {
      // Call the mutate function with the necessary data
      await mutation.mutateAsync({ id, data });
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error updating goal admin access:', error);
      throw new Error('Error updating goal admin access');
    }
  };

  return {
    ...mutation,
    updateAdminAccess,
  };
};


  

 export const useDeleteGoal = () => {
     const queryClient = useQueryClient();
  const mutation = useMutation(
    (params: { id: number; data: { password: string } }) =>
      goalsApiClient.deleteById(params.id, params.data),{
        onSuccess:()=>{
          queryClient.invalidateQueries(['goals'])
        }
      }
  );

  const deleteGoal = async (goalId: number, password: string) => {
    try {
      // Call the mutate function with the necessary data
      await mutation.mutateAsync({ id: goalId, data: { password } });
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error deleting the goal:', error);
      throw new Error('Error deleting the goal');
    }
  };

  return {
    ...mutation,
    deleteGoal,
  };
};


  export const useCreateGoal = () => {
    const queryClient = useQueryClient();
    const mutation = useMutation((goalData: createGoalData) => goalsApiClient.create(goalData),{
      onSuccess:()=>{
        queryClient.invalidateQueries(['goals'])
      }
    });

  const handleCreateGoal = async (goalData: createGoalData) => {
    try {
      const goal =  await mutation.mutateAsync(goalData);
      return goal
    } catch (error) {
      console.error('Error creating goal:', error);
      throw new Error('error creating goal')
    }
  };

  return {
    ...mutation,
    handleCreateGoal,
  };
};

 

export const useUpdateGoalRevolvingSettings = () => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation((params:{id:number; data:Partial<RevolvingSettings>}) =>
    goalsApiClient.updateRevolving(params.id, params.data),
  {
    onSuccess: (_,variables) => {
     queryClient.invalidateQueries(['goal', variables.id])
    },
  });

  const updateRevolvingSettings = async (params:{id:number; data:ExtendedRevolvingSettings}) => {
    try {
      await updateMutation.mutateAsync(params);
    } catch (error) {
      console.error('Error updating revolving settings:', error);
    }
  };

  return {
    updateRevolvingSettings,
    ...updateMutation,
  };
};


  export const useGoalRevolvingSchedule = (goalId: number) => {
    return useQuery<UpcomingRevolvingPayout[],Error>(['goals', goalId, 'revolving-schedule'], () => goalsApiClient.getRevolvingSchedule(goalId),{
      placeholderData:[],
    });
  };

   export const useGoalRevolvingHistory = (goalId: number) => {
    return useQuery<PastRevolvingPayout[],Error>(['goals', goalId, 'revolving-history'], () => goalsApiClient.getPayoutHistory(goalId),{
      placeholderData:[],
    });
  };

 
  

 

  export const useRevolvingPayoutTransactions = (goalId: number) => {
    return useInfiniteQuery(
      ['revolving-transactions','goal' ,goalId],
      ({ pageParam = 0 }) => {
        const params = {
          skip: pageParam
        };
        return goalsApiClient.getRevolvingTransactions(goalId, params); 
      },
      {
        refetchInterval: 2 * 60 * 1000, // 2 minutes in milliseconds
        placeholderData: { pages: [], pageParams: [undefined, 1] },
        getNextPageParam: (lastPage) => {
          const nextPage = lastPage?.nextPage; 
          return nextPage || undefined; 
        },
      }
    );
  };

  export const useAddGoalMembers = () => {
      const queryClient = useQueryClient();

    const mutation = useMutation((params:{id: number, members: number[]}) => goalsApiClient.addGoalMembers(params.id, params.members),{
      onSuccess:(_,variables)=>{
        queryClient.invalidateQueries(['goal-members',variables.id])
      }
    });

    // Function to add members
  const addMembers = async (params: {id: number, members: number[]}) => {
    try {
      // Call the mutate function with the necessary data
      const response =  await mutation.mutateAsync(params);
      return response.data
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error adding member:', error);
      throw new Error('error adding member')
      // Handle the error state, display an error message, etc.
    }
  };
  
    return {
      ...mutation,
      addMembers
    }
  };

  export const useGoalMembers = (goalId: number) => {
    return useQuery(['goal-members', goalId], () => goalsApiClient.getGoalMembers(goalId),{
      placeholderData:[],
    })
  };

  export const useGoalMemberById = (memberId?: number) => {
   if (memberId === null || memberId === undefined ) {
    // Return some default data or an empty object as a placeholder
    return {
      data: null,
      isLoading: true,
      isError: true,
    };
  }
    return useQuery(['goal-members', 'goal', memberId], () => goalsApiClient.getGoalMember(memberId));
  };

  interface UpdateGoalMemberParams {
    goalId: number;
    data: ExtendedGoalMember;
  }
  
  export const useUpdateGoalMember = () => {
    const mutation = useMutation(({ goalId, data }: UpdateGoalMemberParams) =>
      goalsApiClient.promoteMember(goalId, data)
    );
  
    // Function to handle updating the goal member
    const handleUpdateMember = async (goalId: number, data: ExtendedGoalMember) => {
      try {
        // Call the mutate function with the necessary data
        await mutation.mutateAsync({ goalId, data });
        // Mutation successful, handle success or update state as needed
      } catch (error) {
        throw new Error('error')
        // Mutation failed, handle error or show error message
      }
    };
  
    return {
      ...mutation,
      handleUpdateMember,
    };
  };


export const useRemoveGoalMembers = () => {
  const queryClient = useQueryClient();

  const removeMembersMutation = useMutation((params: { goalId: number, members: number[] }) =>
    goalsApiClient.deleteGoalMembers(params.goalId, params.members),
  {
    onSuccess: (_,variables) => {
      queryClient.invalidateQueries(['goal-members',variables.goalId]);
    },
  });

  const removeGoalMembers = async (params: { goalId: number, members: number[] }) => {
    try {
      await removeMembersMutation.mutateAsync(params);
    } catch (error) {
      console.error('Error removing goal members:', error);
      throw new Error('Error removing goal members:')
    }
  };

  return {
    removeGoalMembers,
    ...removeMembersMutation,
  };
};

  
  export const useMaturedGoals = () => {
    return useQuery<GoalItem[],Error>(['matured-goals'], goalsApiClient.getMatured,{
      placeholderData:[],
    });
  };


export const usePublicByMember = (userId:number) => {
  return useQuery<GoalItem[],Error>(['public-goals', userId], () => goalsApiClient.getPublicByMember(userId),{
    placeholderData:[],
  });
};

export const usePublicGoals = () => {
  return useQuery<GoalItem[],Error>(['public-goals'], () => goalsApiClient.getAllPublic(),{
    placeholderData:[],
  });
};


export const useJoinGoal = () => {
  const mutation = useMutation((params: { goalId: number }) => goalsApiClient.joinGoalById(params.goalId));

  const joinGoal = async (params: { goalId: number }) => {
    try {
      // Call the mutate function with the necessary data
      const response = await mutation.mutateAsync(params);
      return response.data;
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error joining goal:', error);
      throw new Error('Error joining goal'); 
    }
  };

  return {
   ...mutation,
    joinGoal,
  };
}

export const useLeaveGoal = () => {
  const mutation = useMutation((params: { goalId: number }) => goalsApiClient.leaveGoalById(params.goalId));

  const leaveGoal = async (params: { goalId: number }) => {
    try {
      // Call the mutate function with the necessary data
      const response = await mutation.mutateAsync(params);
      return response.data;
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error leaving the goal:', error);
      throw new Error('Error leaving the goal');
    }
  };

  return {
    ...mutation,
    leaveGoal,
  };
}

export const useTransferGoalOwnership = () => {
    const mutation = useMutation((params:{id:number, data:{memberId:number}}) => goalsApiClient.transferGoalOwnership(params.id, params.data));

    const transferOwnership = async (params: {id:number, data:{memberId:number}}) => {
    try {
      // Call the mutate function with the necessary data
      const response = await mutation.mutateAsync(params);
      return response.data;
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error transferring goal ownership:', error);
      throw new Error('Error transferring goal ownership');
    }
  };

  return {
    ...mutation,
    transferOwnership,
  };
  };


  export const useGoalsInCommon = (userId:number) => {
  return useQuery(['goals-in-common', userId], () => goalsApiClient.fetchAccountsInCommon(userId),{
    placeholderData:[],
    // Provide a default value for the query data
    select: (data) => data || [],
  });
};

