import { useQuery,useMutation,useQueryClient } from '@tanstack/react-query';
import contactsApiClient from "../apis/apiServices/contacts";


export const useContacts = () => {
    return useQuery(['contacts'], contactsApiClient.getAll, {
      placeholderData:[]
    });
  };

  export const useContactById = (contactId: number) => {
    return useQuery(['contacts', contactId], () => contactsApiClient.getById(contactId));
  };



export const useCreateContact = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (contactData: { name: string; phonenumber: string; username?: string }) => {
      return contactsApiClient.create(contactData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contacts']);
      },
    }
  );

  const createContact = async (contactData: { name: string; phonenumber: string; username?: string }) => {
    try {
      await createMutation.mutateAsync(contactData);
    } catch (error) {
      console.error('Error creating contact:', error);
      throw new Error('Error creating contact:')
    }
  };

  return {
    createContact,
    ...createMutation,
  };
};


  

export const useDeleteContact = () => {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation((contactId: number) => contactsApiClient.deleteById(contactId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['contacts']);
    },
  });

  const deleteContact = async (contactId: number) => {
    try {
      await deleteMutation.mutateAsync(contactId);
    } catch (error) {
      console.error('Error deleting contact:', error);
      throw new Error('Error deleting contact:')
    }
  };

  return {
    deleteContact,
    ...deleteMutation,
  };
};


export const useUpdateContact = () => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation((params: { id: number, data: { name: string, phonenumber: string } }) =>
    contactsApiClient.updateById(params.id, params.data),
  {
    onSuccess: () => {
      queryClient.invalidateQueries(['contacts']); 
    },
  });

  const updateContact = async (params: { id: number, data: { name: string, phonenumber: string } }) => {
    try {
      await updateMutation.mutateAsync(params);
    } catch (error) {
      console.error('Error updating contact:', error);
      throw new Error('Error updating contact:')
    }
  };

  return {
    updateContact,
    ...updateMutation,
  };
};
