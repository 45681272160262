import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme:Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 16px",
    alignItems: "center",
    width:'100%',
  },
 
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "232px",
    height: "128px",
    borderRadius: "4px",
    backgroundColor: "#C4CFD4",
    marginBottom: "24px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    alignSelf: "center",
  },
  formContainer: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "column",
    width:'100%',
  },
  header: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    maxWidth: "400px",
    marginTop:"16px"
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
  },
  previewCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "1.5rem",
    padding: 16,
    background: '#E4F7E8',
    gap:'8px',
    width:'100%',
  },
  myGoals: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    justifyContent: "center",
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      width: "400px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      flexDirection: "row",
      flexWrap: "wrap",
      width: "unset",
    },
  },
   
  
}));
