import React, { useState } from "react";
import Button from "../../components/Reusables/Input/Button";
import FormInput from "../../components/Reusables/Input/FormInput";
import { useStyles } from "./loginStyles";
import { Form, Field } from "react-final-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, Typography, Box, Chip, InputAdornment, Avatar } from "@mui/material";
import Toast from "../../components/Reusables/Feedback/Toast";
import Alert from '@mui/material/Alert';
import { useForgetPassword, useResetPassword } from '../../hooks/authHooks';
import { useStyles as useButtonStyles } from '../../components/Reusables/Input/ButtonStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CountryInput from "../../components/Reusables/Input/CountryInput";
import { useHistory } from "react-router-dom";


interface FormValues {
  email: string;
  otp: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string
}

function ResetPassword() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Forget Pasword', 'Reset Password'];
  const history = useHistory()
  const { resetPasswordByEmail, resetPasswordByPhoneNumber, loading, error } = useResetPassword();


  const [open, setOpen] = useState(false);



  const handleNext = async (values: FormValues) => {
    if (activeStep === 1) {
      try {
        if (values.email) {
          await resetPasswordByEmail({ password: values.password, otp: values.otp, email: values.email })
          setOpen(true)
          history.push('/auth/login/email')
        } else if (values.phoneNumber) {
          await resetPasswordByPhoneNumber({
            password: values.password,
            phonenumber: values.phoneNumber,
            reference: localStorage.getItem('ref') as string,
            otp: values.otp
          })
          setOpen(true)
          history.push('/auth/login/mobile')

        }


      } catch (error) {

      }

    } else if (activeStep < steps.length) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };




  const validate = (values: FormValues) => {

    const errors: Partial<FormValues> = {};
    if (activeStep === 0) {
      if (!values.email && !values.otp) {
        errors.email = "Required";
        errors.otp = 'Required'
      }

    }

    if (activeStep === 1) {
      if (!values.password) {
        errors.password = "Required";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Required";
      }
      if (values.password?.length < 8) {
        return { password: "Password must be atleast 8 chars" };
      }
      if (values.password !== values.confirmPassword) {
        return { confirmPassword: "Passwords must match" };
      }
    }
    return errors;
  };


  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  return (
    <div className={classes.container}>
      <Box className={classes.logoContainer}>
        <Avatar alt="Pasbanc" src="/logo.png" />
        <Typography variant='h5'>Pasbanc</Typography>
      </Box>
      <Form
        onSubmit={handleNext}
        validate={validate}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            {activeStep === 0 && <ForgetPassword values={values} />}
            {activeStep === 1 && <Reset handleBack={handleBack} />}

            {activeStep === 1 && error && <Alert severity="error">{(error as Error).message}</Alert>}
            <Box mt={3} sx={{ textAlign: 'center' }}>
              <Button
                title="Next"
                color="primary"
                variant="contained"
                type="submit"
                disabled={submitting || loading}
                className={buttonClasses.largeButton}
              />
            </Box>

          </form>
        )}
      />
      <Toast
        type="success"
        message="Password reset Successfully"
        open={open}
        handleClose={() => setOpen(!open)}
      />
    </div>
  );
}



export default ResetPassword;


export const ForgetPassword = ({ values }: { values: { email?: string, phoneNumber?: string } }) => {
  const { forgetPassword, error } = useForgetPassword();
  const history = useHistory();
  const [resetMethod, setResetMethod] = useState('email');
  const [open, setOpen] = useState(false);


  const handleForgetPassword = async () => {
    try {
      if (resetMethod === 'email') {
        await forgetPassword({ email: values.email });
      } else if (resetMethod === 'phone') {
        await forgetPassword({ phonenumber: values.phoneNumber });
      }
      setOpen(true)
    } catch (error) {
      console.log('error')
    }
  }
  const classes = useStyles();


  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'left' }}>
      <Box sx={{ alignSelf: 'baseline' }} >
        <IconButton onClick={() => history.goBack()}><ArrowBackIcon /></IconButton>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h2" >Reset Login</Typography>
        <Typography variant="body1" >
          Start your Pasbanc journey here
        </Typography>
      </Box>


      <Box className={classes.customForm}>
        {
          resetMethod === 'email' ?
            (
              <Box mt={2} className={classes.textFieldContainer}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1" fontWeight={600}>Email</Typography>
                  <Chip label={<Typography variant="button" component='span'>Use <Typography variant="body2" fontWeight={700} component='span'>phone</Typography> instead</Typography>} onClick={() => setResetMethod('phone')} />
                </Box>
                <Field name="email">
                  {(props: any) => <FormInput
                    label=""
                    type="email"
                    name="email"

                    {...props}
                  />}
                </Field>

              </Box>
            )
            : (
              <Box mt={2} className={classes.textFieldContainer}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1" fontWeight={600}>Phone number</Typography>
                  <Chip label={<Typography variant="button" component='span'>Use <Typography variant="body2" fontWeight={700} component='span'>email</Typography> instead</Typography>} onClick={() => setResetMethod('email')} />
                </Box>
                <CountryInput name="country" />
              </Box>
            )
        }

        <Field name="otp">
          {(props: any) => <FormInput
            label=""
            type="text"
            InputProps={{
              endAdornment: <InputAdornment position="end" sx={{ cursor: 'pointer', }} onClick={handleForgetPassword}><Typography variant="button" sx={{color:"#000"}}>Send me a code</Typography></InputAdornment>,
            }}
            sx={{
              mt: 2,
            }}
            {...props}
          />}
        </Field>
      </Box>
      {error && <Alert severity="error">{(error as Error).message}</Alert>}
      <Toast open={open} type={'success'} message={'Code sent'} handleClose={() => setOpen(false)} />
    </Box>
  );
}

const Reset = ({ handleBack }: { handleBack: any }) => {
  const [showPassword, setPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const classes = useStyles();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'left', gap: '20px' }}>
      <Box sx={{ alignSelf: 'baseline' }}>
        <IconButton onClick={handleBack} ><ArrowBackIcon /></IconButton>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h5">Set your Password</Typography>
        <Typography variant='body1' sx={{ maxWidth: '414px' }}>Enter a password you will easily remember to access your Pasbanc account</Typography>
      </Box>
      <Box className={classes.customForm}>
        <Field
          label="New Password"
          name="password"
          component={FormInput}
          type={showPassword ? "text" : "password"}
          position="end"
          Icon={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setPassword(!showPassword)}
              size="large">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
        />
        <Field
          label="Confirm New Password"
          name="confirmPassword"
          component={FormInput}
          type={showConfirmPassword ? "text" : "password"}
          position="end"
          Icon={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setConfirmPassword(!showConfirmPassword)}
              size="large">
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
        />
      </Box>
    </Box>
  )
}