import { TransactionApiData } from "../../types/actionTypes";
import api from "../api";
import { TRANSACTIONS_ENDPOINT } from "../apiEndpoints";

const transactionsApiClient = {
  // Retrieve a list of all transactions
  getAll: async (data:TransactionApiData) => {
    const response = await api.post(`${TRANSACTIONS_ENDPOINT}/`, data);
    return response.data;
  },
  getAnalytics: async () => {
    const response = await api.post(
      `${TRANSACTIONS_ENDPOINT}/analytics`
    );
    return response.data.analytics;
  },
  updateById: async (id:number,data:any) => {
    try {
       const response = await api.put(
      `${TRANSACTIONS_ENDPOINT}/${id}`,data
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
   
  },
  getById: async (id:number) => {
    const response = await api.get(`${TRANSACTIONS_ENDPOINT}/${id}`);
    return response.data;
  },
};

export default transactionsApiClient;
