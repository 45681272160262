import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme:Theme) => ({
  container: {
    margin: "16px",
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginTop: "16px",
  },
  /**still used */
  imageContainer: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    justifyContent: "center",
  },
 

  sectionTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "16px 0px",
    backgroundColor: "#DBEAFE",
    padding: "0px 8px",
  },
  
  
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  
  
  expiryContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  
  
  btnContainer: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    gap: "24px",
    marginTop: "8px",
  },
  
  notificationItem: {
    marginTop: "8px",
    padding: "8px 16px",
    borderRadius: "8px",
    cursor: "pointer",
  },
 

 
  
  notificationsContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "8px",
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      marginTop: "8px",
    },
    
  },
  notificationBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    padding: "4px 0px",
  },
  notificationSection: {
    marginTop: "16px",
  },

  
 
}));
