import { useMutation ,useQuery, useQueryClient} from '@tanstack/react-query';
import depositsApiClient from '../apis/apiServices/deposits';
import payoutsApiClient from '../apis/apiServices/payouts';
import { BankTransferData, CardPay, MobilePayValues } from '../types/actionTypes';




export const useDeposit = () => {
  const queryClient = useQueryClient()
  const depositMobileMoneyMutation = useMutation((data:MobilePayValues) => depositsApiClient.depositViaMobile(data));
  const depositCardMutation = useMutation((data:CardPay) => depositsApiClient.depositViaCard(data));

  const depositMoney = async (data: MobilePayValues | CardPay | any, method: string) => {
    try {
      if (method === 'mobile' || method === 'mtn' || method === 'airtel') {
        await depositMobileMoneyMutation.mutateAsync(data as MobilePayValues);
      } else if (method === 'card') {
        await depositCardMutation.mutateAsync(data as CardPay);
      }

      if (data.type === 'goal') {
        queryClient.invalidateQueries(['goal-transactions', null, data.id]);
      } else if (data.type === 'pocket') {
        queryClient.invalidateQueries(['pocket-transactions', null]);
      } else if (data.type === 'wallet') {
        queryClient.invalidateQueries(['user', 'wallet', 'transactions', null]);
        queryClient.invalidateQueries(['group', 'wallet', 'transactions', null, data.id]);
      }
      queryClient.invalidateQueries(['transactions']);

      // Handle success or do something after deposit
    } catch (error) {
      // Handle error
      console.error('Deposit error:', error);
      throw new Error('Deposit error');
    }
  };

  return {
    depositMoney,
    loading: depositMobileMoneyMutation.isLoading || depositCardMutation.isLoading,
    isError: depositMobileMoneyMutation.isError || depositCardMutation.isError,
    error: depositMobileMoneyMutation.error || depositCardMutation.error,
  };
};





export const useMobilePayouts = () => {
  const queryClient = useQueryClient()
  const mobilePayoutFromWalletMutation = useMutation((data:MobilePayValues) => payoutsApiClient.mobileWalletPayout(data));
  const mobilePayoutFromGoalMutation = useMutation((data:MobilePayValues) => payoutsApiClient.mobileGoalPayout(data));

  const makeMobilePayoutFromWallet = async (data: MobilePayValues) => {
    try {
      await mobilePayoutFromWalletMutation.mutateAsync(data);
      queryClient.invalidateQueries(['transactions']); 
      // Handle success or do something after the payout
    } catch (error) {
      // Handle error
      console.error('Mobile payout from wallet error:', error);
      throw new Error('Mobile payout from wallet error');
    }
  };

  const makeMobilePayoutFromGoal = async (data: MobilePayValues) => {
    try {
      await mobilePayoutFromGoalMutation.mutateAsync(data);
      queryClient.invalidateQueries(['transactions']); 
      // Handle success or do something after the payout
    } catch (error) {
      // Handle error
      console.error('Mobile payout from goal error:', error);
      throw new Error('Mobile payout from goal error');

    }
  };

  return {
    makeMobilePayoutFromWallet,
    makeMobilePayoutFromGoal,
    isLoading: mobilePayoutFromWalletMutation.isLoading || mobilePayoutFromGoalMutation.isLoading,
    isError:
      mobilePayoutFromWalletMutation.isError || mobilePayoutFromGoalMutation.isError,
    error:
      mobilePayoutFromWalletMutation.error || mobilePayoutFromGoalMutation.error,
  };
};



export const useBankPayouts = () => {
  const queryClient = useQueryClient()
  const bankPayoutFromWalletMutation = useMutation((data:BankTransferData) => payoutsApiClient.bankWalletPayout(data));
  const bankPayoutFromGoalMutation = useMutation((data:BankTransferData) => payoutsApiClient.bankGoalPayout(data));

  const makeBankPayoutFromWallet = async (data: BankTransferData) => {
    try {
      await bankPayoutFromWalletMutation.mutateAsync(data);
      queryClient.invalidateQueries(['transactions']); 
      // Handle success or do something after the payout
    } catch (error) {
      // Handle error
      console.error('Bank payout from wallet error:', error);
      throw new Error('Bank payout from wallet error');
    }
  };

  const makeBankPayoutFromGoal = async (data: BankTransferData) => {
    try {
      await bankPayoutFromGoalMutation.mutateAsync(data);
      queryClient.invalidateQueries(['transactions']); 
      // Handle success or do something after the payout
    } catch (error) {
      // Handle error
      console.error('Bank payout from goal error:', error);
      throw new Error('Bank payout from goal error');
    }
  };

  return {
    makeBankPayoutFromWallet,
    makeBankPayoutFromGoal,
    isLoading: bankPayoutFromWalletMutation.isLoading || bankPayoutFromGoalMutation.isLoading,
    isError:
      bankPayoutFromWalletMutation.isError || bankPayoutFromGoalMutation.isError,
    error:
      bankPayoutFromWalletMutation.error || bankPayoutFromGoalMutation.error,
  };
};


interface DepositOptions {
  wallet: {  id: number;name: string; currency:string;};
  goals: { id: number; name: string; type: string;currency:string;}[];
  groups: {id: number;name: string;type: string;currency:string;}[];
  pockets: {id: number;name: string;type: string;currency:string;}[];
}

export const useDepositOptions = () => {
  return useQuery<DepositOptions, Error>(['depositOptions'], depositsApiClient.getDepositOptions)
};

interface PayoutOptions {
  wallet: {  id: number;name: string; currency:string; amount:number;};
  goals: { id: number; name: string; type: string;currency:string; amount:number;}[];
  groups: {id: number;name: string;type: string;currency:string; amount:number;}[];
  pockets: {id: number;name: string;type: string;currency:string; amount:number;}[];
}

export const usePayoutOptions = () => {
  return useQuery<PayoutOptions, Error>(['payoutOptions'], payoutsApiClient.getPayoutOptions);
};





