import { FormControl, Select, MenuItem, TextField } from "@mui/material";
import { Field } from "react-final-form";
import { makeStyles } from "@mui/styles";
import FormInput, { CustomizedTextField } from "./FormInput";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme:Theme) => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius:'4px',
    overflow:'hidden',
    marginTop:theme.spacing(1),
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
      borderWidth:'2px'
    },

  },
  select: {
    minWidth:'60px',
    '& .MuiInputBase-root:before':{
        borderBottom:'none'
    },
    '& .MuiInputBase-root:after': {
      borderBottom: 'none', 
    },
    '& .MuiSelect-select':{
        paddingLeft:'6px',
        minWidth:'40px'
    }
  },
  input: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
     borderWidth: 0,  
    },
  },
 
}));

interface Country {
  code: string;
  name: string;
  flag: string;
  phoneCode: string;
}

const countries: Country[] = [
  { code: 'UG', name: 'Uganda', flag: '🇺🇬', phoneCode: '+256' },
  { code: 'USA', name: 'United States', flag: '🇺🇸', phoneCode: '+1' },
  { code: 'KE', name: 'Kenya', flag: '🇰🇪', phoneCode: '+254' },
  { code: 'SA', name: 'Saudi Arabia', flag: '🇸🇦', phoneCode: '+966' },
  { code: 'UK', name: 'United Kingdom', flag: '🇬🇧', phoneCode: '+44' },
  { code: 'CN', name: 'China', flag: '🇨🇳', phoneCode: '+86' },
  { code: 'JP', name: 'Japan', flag: '🇯🇵', phoneCode: '+81' },
];

interface CountryCodesProps {
  name: string;
  width?:string;
}

const CountryInput: React.FC<CountryCodesProps> = ({ name, width = '100%' }) => {
  const classes = useStyles();

  return (
    <Field name={name} initialValue='UG'>
      {({ input, meta }) => (
        <div className={classes.inputContainer} style={{ width }}>
          <FormControl variant="standard" className={classes.select}>
            <Select {...input} label="Country">
              {countries.map((item) => (
                <MenuItem key={item.code} value={item.code}>
                  {item.flag} {item.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Field name={`phoneNumber`}>
            {(props) => (
              <CustomizedTextField
                placeholder="Phone number"
                className={classes.input}
                error={!!(meta?.error || meta?.submitError) && meta?.touched}
                helperText={meta?.touched ? meta?.error || meta?.submitError : ""}
                {...props.input}
              />
            )}
          </Field>
        </div>
      )}
    </Field>
  );
};


export default CountryInput;
