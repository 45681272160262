import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme:Theme) => ({
  smallButton: {
    fontSize: '0.8rem',
    padding: theme.spacing(1),
  },
  mediumButton: {
    padding: theme.spacing(2),
    height:44,
    width:'-webkit-fill-available'
  },
  largeButton: {
    padding: theme.spacing(2),
    width:254,
    height:44,
    [theme.breakpoints.up("lg")]: {
      width:328
    }
  },
}));
