import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import loansApiClient from '../apis/apiServices/loans';
import { BusinessData, CreateLoanData, RepayLoanData, SalaryData } from '../types/actionTypes';
import { Business, EmployeeInfo, Loan, TransactionData } from '../types/types';

export interface LoanDetails {
  loanLimit: number;
  totalBorrowed: number;
  amountLeftToBorrow: number;
  totalCleared: number;
  totalUncleared: number;
  hide_balance: boolean;
  has_saver_loan: boolean;
  has_business_apply: boolean;
  has_salary_apply: boolean;

}



export const useLoans = () => {
  return useQuery<Loan[], Error>(['loans'], loansApiClient.getAll, {
    placeholderData: []
  });
};

export const useLoanById = (loanId: number) => {
  return useQuery(['loan', loanId], () => loansApiClient.getById(loanId));
};

export const useLoanTransactions = (filter: any, hasSearchTerm: boolean, loanId: number) => {
  return useInfiniteQuery<TransactionData, Error>(['loan-transactions', JSON.stringify(filter), loanId], ({ pageParam = 0 }) => {

    const params = {
      ...filter,
      skip: pageParam
    };
    return loansApiClient.getTransactions(loanId, params);
  }, {
    refetchInterval: 2 * 60 * 1000, // 2 minutes in milliseconds
    placeholderData: { pageParams: [undefined, 1], pages: [] },
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || !lastPage.transactions || lastPage.transactions.length < 10) {
        return null;
      }
      return pages.length * 10;
    },
  });
};

export const useLoanDetails = () => {
  return useQuery<LoanDetails, Error>(['loan-details'], loansApiClient.getDetails, {
    placeholderData: { loanLimit: 0, totalBorrowed: 0, amountLeftToBorrow: 0, totalCleared: 0, hide_balance: false, has_saver_loan: false, totalUncleared: 0, has_business_apply: false, has_salary_apply: false },
  });
};



export const useCreateLoan = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (data: CreateLoanData) => {
      return loansApiClient.create(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['loans']);
        queryClient.invalidateQueries(['loan-details']);
      },
    }
  );

  const createLoan = async (data: CreateLoanData) => {
    try {
      await createMutation.mutateAsync(data);
    } catch (error) {
      console.error('Error creating loan:', error);
      throw new Error('Error creating loan:')
    }
  };

  return {
    createLoan,
    ...createMutation,
  };
};







export const useRepayLoan = () => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation((params: RepayLoanData) =>
    loansApiClient.repay(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['loans']);
      },
    });

  const repayLoan = async (params: RepayLoanData) => {
    try {
      const url = await updateMutation.mutateAsync(params);
      if (url) {
        window.location.href = url
      }
    } catch (error) {
      console.error('Error repaying loan:', error);
      throw new Error('Error repaying loan:')
    }
  };

  return {
    repayLoan,
    ...updateMutation,
  };
};

export const useLoanApply = () => {
  const queryClient = useQueryClient();

  const salaryMutation = useMutation((data: SalaryData) => loansApiClient.applySalary(data));
  const businessMutation = useMutation((data: BusinessData) => loansApiClient.applyBusiness(data));

  const loanApply = async (data: BusinessData | SalaryData, type: string) => {
    try {
      if (type === 'salary') {
        await salaryMutation.mutateAsync(data as SalaryData);

      } else if (type === 'business') {
        await businessMutation.mutateAsync(data as BusinessData);
      }

      queryClient.invalidateQueries(['loans']);


      // Handle success or do something after deposit
    } catch (error) {
      // Handle error
      console.error('Loan apply:', error);
      throw new Error('Loan apply');
    }
  };

  return {
    loanApply,
    loading: salaryMutation.isLoading || businessMutation.isLoading,
    isError: salaryMutation.isError || businessMutation.isError,
    error: salaryMutation.error || businessMutation.error,
  };
};

export const useBusinessInfo = () => {
  return useQuery<Business[], Error>(['business-info'], loansApiClient.getBusinessInfo);
};

export const useEmployeeInfo = () => {
  return useQuery<EmployeeInfo, Error>(['employee-info'], loansApiClient.getEmployeeInfo);
};


interface BusinessRevenueInput {
  businessId: number;
  this_week_revenue: number;
  last_week_revenue: number;
  last_month_revenue: number;
}

export const useCreateRevenue = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (data: BusinessRevenueInput) => {
      return loansApiClient.addBusinessRevenue(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['business-info']);
      },
    }
  );

  const createRevenue = async (data: BusinessRevenueInput) => {
    try {
      await createMutation.mutateAsync(data);
    } catch (error) {
      console.error('Error creating revenue:', error);
      throw new Error('Error creating revenue:')
    }
  };

  return {
    createRevenue,
    ...createMutation,
  };
};

interface LegalOwnerInput {
  businessId: number;
  name: string;
  ownership: string;
  phoneNumber: string;
}

export const useCreateLegalOwner = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (data: LegalOwnerInput) => {
      return loansApiClient.addLegalOwner(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['business-info']);
      },
    }
  );

  const createLegalOwner = async (data: LegalOwnerInput) => {
    try {
      await createMutation.mutateAsync(data);
    } catch (error) {
      console.error('Error creating owner:', error);
      throw new Error('Error creating owner:')
    }
  };

  return {
    createLegalOwner,
    ...createMutation,
  };
};

export const useDeleteLegalOwner = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (data: { businessId: number; name: string }) => {
      return loansApiClient.removeLegalOwner(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['business-info']);
      },
    }
  );

  const deleteLegalOwner = async (data: { businessId: number; name: string }) => {
    try {
      await createMutation.mutateAsync(data);
    } catch (error) {
      console.error('Error creating owner:', error);
      throw new Error('Error creating owner:')
    }
  };

  return {
    deleteLegalOwner,
    ...createMutation,
  };
};

export const useUpdateBusinessLoanAmount = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (data: { id: number; loan_amount: number }) => {
      return loansApiClient.updateBusinessLoanAmount(data);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['business-info'] });
    },
  });

  const updateAmount = async (data: { id: number; loan_amount: number }) => {
    try {
      await createMutation.mutateAsync(data);
    } catch (error) {
      console.error('Error updating amount:', error);
      throw new Error('Error updatiing amount:');
    }
  };

  return {
    updateAmount,
    ...createMutation,
  };
};
