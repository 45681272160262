import React, { useEffect, useState } from "react";
import { Alert, Avatar, Box, DialogContent, DialogTitle, Drawer, FormControlLabel, FormHelperText, Popover, Radio, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import { Field, Form } from "react-final-form";
import FormInput from "../Reusables/Input/FormInput";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import Fuse from "fuse.js";
import { useDeposit, useDepositOptions } from "../../hooks/paymentHooks";
import Button from "../Reusables/Input/Button";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CustomLink from "../Reusables/Input/CustomLink";
import RadioButtons from "../Reusables/Input/RadioGroup";
import CloseIcon from '@mui/icons-material/Close';
import { calculateTransferFee, numberWithCommas, removeCommas } from "../../utils/middleware";
import { usePaymentMethods } from "../../hooks/paymentMethodHooks";
import { PaymentMethod } from "../../types/types";
import Select from "../Reusables/Input/select/Select";
import { useHistory } from "react-router-dom";
import DoneIcon from '@mui/icons-material/Done';
import { FormApi, ValidationErrors } from "final-form";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useGoalToGoalTransfer, useWalletToWalletTransfer, useGoalWalletTransfer, usePocketGoalTransfer, useWalletPocketTransfer, usePocketToPocketTransfer } from "../../hooks/transferHooks";
import { useUserWalletTotal } from "../../hooks/balanceHooks";
import ModalWrapper from "./ModalWrapper";
import theme from "../../theme";
import { LightStyledTab, LightStyledTabs } from "../Reusables/LightStyledTabs";
import InfoIcon from '@mui/icons-material/Info';
import { MobilePayValues } from "../../types/actionTypes";


interface DepositStepperProps {
  open: boolean;
  onClose: () => void;
  preselectedGoal?: {
    id: number;
    name: string;
    type: string;
    currency: string;
  };
}

const steps = ['Deposit Options', 'Payment Details', 'Confirm', 'Success!'];


export interface DepositFormValues {
  type: string,
  amount: string,
  method: string,
  source: string | number;
  id: string | number,
  phoneNumber: string,
  card: any
}

export interface FormValues {
  type: string;
  currency: string;
  name: string;
}

const CreateDeposit: React.FC<DepositStepperProps> = ({ open, onClose, preselectedGoal }) => {
  const [activeStep, setActiveStep] = useState(preselectedGoal ? 1 : 0);
  const [formValues, setFormValues] = useState(preselectedGoal 
    ? { type: preselectedGoal.type, currency: preselectedGoal.currency, name: preselectedGoal.name }
    : { type: '', currency: '', name: '' });
  const [sourceFormValues, setSourceFormValues] = useState({ type: '', currency: '', name: '' });
  const { depositMoney, error, loading } = useDeposit();
  const { goalWalletTransfer, loading: walletGoalLoading, error: walletGoalError } = useGoalWalletTransfer();
  const { walletPocketTransfer, loading: walletPocketLoading, error: walletPocketError } = useWalletPocketTransfer();
  const { pocketGoalTransfer, loading: pocketGoalLoading, error: pocketGoalError } = usePocketGoalTransfer();
  const { goalToGoal, isLoading: goalLoading, error: goalError } = useGoalToGoalTransfer();
  const { pocketToPocket, isLoading: pocketLoading, error: pocketError } = usePocketToPocketTransfer();
  const { walletToWallet, isLoading: walletLoading, error: walletError } = useWalletToWalletTransfer();
  const history = useHistory()

  const handleReset = (form: FormApi<DepositFormValues, Partial<DepositFormValues>>) => {
    setActiveStep(preselectedGoal ? 1 : 0);
    form.reset();
    onClose()
    setFormValues(preselectedGoal 
      ? { type: preselectedGoal.type, currency: preselectedGoal.currency, name: preselectedGoal.name }
      : { type: '', currency: '', name: '' });
    setSourceFormValues({ type: '', currency: '', name: '', });

  };


  const validate = (values: DepositFormValues) => {
    const errors: ValidationErrors = {};
    if (activeStep === 0) {
      if (!values.id) {
        errors.id = "required";
      }

    }

    if (activeStep === 1) {
      if (!values.amount) {
        errors.amount = "required"
      }

      if (!values.method) {
        errors.method = "required";
      }

      if (values.method === 'mobile') {
        if (!values.phoneNumber) {
          errors.phoneNumber = 'required'
        }
      }

      if (values.method === 'card') {
        if (!values.card) {
          errors.card = 'required'
        }
      }

      if (values.method === 'transfer') {
        if (!values.source) {
          errors.source = 'required'
        }
      }

      if (values.source && values.id) {
        if ((values.source === values.id) && (formValues.type === sourceFormValues.type)) {
          errors.source = 'Cant transfer to the same account'
        }
      }
    }
    return errors;
  };

  const handleNext = async (values: DepositFormValues) => {
    if (activeStep === 2 && values) {
      try {
        // Open phone dialer with USSD code
        const ussdCode = values.method === 'mtn' ? '*165*3*586046' : '*185*9*4364904';
        const fullUssdCode = `${ussdCode}*${removeCommas(values.amount)}*1#`;
        window.location.href = `tel:${encodeURIComponent(fullUssdCode)}`;

        // Submit form to API
        const data = {
          amount: parseFloat(removeCommas(values.amount)),
          phoneNumber: values.phoneNumber,
          type: formValues.type === 'group' ? 'wallet' : formValues.type,
          id: values.id as number,
          currency: formValues.currency,
          url: history.location.pathname,
        };

        if (values.method) {
          await depositMoney(data as MobilePayValues, values.method);
        } else {
          throw new Error('Invalid payment method');
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error('Error during deposit:', error);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex' }}>
        <Typography variant="body1" sx={{ m: '0 auto', fontWeight: 700 }}>{steps[activeStep]}</Typography>
        <CloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent sx={{ padding: { lg: '28px 55px' } }}>
        <Form
          onSubmit={handleNext}
          validate={validate}
          initialValues={{
            method: 'mtn',
            amount: 0,
            id: preselectedGoal?.id || ''
          }}
          render={({ handleSubmit, values, form, submitting }) => (
            <form onSubmit={handleSubmit}>
              {activeStep === 0 && !preselectedGoal &&
                <DepositOptionsStep formValues={formValues}
                  setFormValues={setFormValues} name='id'
                  handleNext={handleNext} />}
              {activeStep === 1 && <PaymentMethodStep values={values} setFormValues={setSourceFormValues} formValues={formValues} handleNext={handleNext} form={form} />}
              {activeStep === 2 && (
                <Box>
                  <Typography>
                    You are depositing {" "}
                    <Typography component='span' fontWeight={600}>{numberWithCommas(values.amount)}</Typography> 
                    <Typography component='span' variant='overline'>{formValues.currency}</Typography> to {formValues.name}.
                  </Typography>
                </Box>
              )}
              {activeStep === 3 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#22C55E', mt: 2, width: 60, height: 60 }}>
                  <DoneIcon fontSize='large' />
                </Avatar>
                <Typography variant='h6' sx={{ mt: 1, fontWeight: 600 }}>Payment Queued!</Typography>
                <Typography>Your transaction is being processed. Please wait.</Typography>
              </Box>}

              {activeStep === steps.length - 2 && error && <Alert severity='error'>{(error as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletGoalError && <Alert severity="error">{(walletGoalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletPocketError && <Alert severity="error">{(walletPocketError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && goalError && <Alert severity="error">{(goalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && pocketGoalError && <Alert severity="error">{(pocketGoalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletError && <Alert severity="error">{(walletError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && pocketError && <Alert severity="error">{(pocketError as Error).message}</Alert>}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 3,
                }}
              >
                {activeStep === steps.length - 1 ? (
                  <Button title='Great' variant='contained' sx={{ width: '100%', mt: 2 }} onClick={() => handleReset(form)} />
                ) : (
                  activeStep !== 1 ? <>
                    {activeStep !== 0 && (
                      <Button color="primary" onClick={handleBack} title='Back' sx={{ backgroundColor: '#F0EBF8', mr: 1.5, width: '120px' }} />
                    )}
                    <Button variant="contained" type="submit"
                      title={activeStep === steps.length - 2 ? 'Deposit' : 'Next'}
                      loading={loading || submitting || walletGoalLoading || goalLoading || walletPocketLoading || walletLoading || pocketGoalLoading || pocketLoading}
                      sx={{ width: '120px' }}
                    />
                  </> : null
                )}
              </Box>
            </form>
          )}
        />
      </DialogContent>
    </ModalWrapper>
  );
}


export default CreateDeposit;

interface DepositStepProps {
  formValues: FormValues,
  setFormValues: any,
  name: string,
  handleNext?: any
}

export const DepositOptionsStep: React.FC<DepositStepProps> = ({ formValues, setFormValues, name, handleNext }) => {
  const history = useHistory();
  const { data: depositOptions, isLoading, isError } = useDepositOptions();
  const [activeTab, setActiveTab] = useState(1);
  const [searchWord, setSearchWord] = useState("");
  const options = {
    keys: ["name", "type"],
    minMatchCharLength: 3
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);

    // Use the ID to scroll to the selected section
    const sectionId = newValue === 0 ? 'pockets' : newValue === 1 ? 'goals' : 'groups';

    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }

  };


  if (isLoading) {
    return <Skeleton variant="rectangular" width={210} height={20} />;
  }

  if (isError) {
    return <Skeleton variant="rectangular" width={210} height={20} />
  }
  const searchableOptions = [
    ...depositOptions.goals.map((option: any) => option),
    

  ];

  const fuse = new Fuse(searchableOptions, options);


  const handleSearch = (query: string) => {
    setSearchWord(query)
  };

  const goals = depositOptions.goals.map((goal) => {
    return {
      ...goal
    }
  })



 

  const filteredOptions = fuse.search(`${searchWord}`).map((result) => result.item);

  return (
    <Box>
      <FormInput
        name='search deposit options'
        value={searchWord}
        placeholder="Search here"
        onChange={(e) => handleSearch(e.target.value)}
        InputProps={{
          startAdornment: <SearchOutlined />
        }}
      />
     {/*  <Box sx={{ width: "100%", margin: "0 auto", display: "flex" }}>
        <Field
          name={name}
          component={({ input, meta }) => (
            <>
              <Button
                title="Choose Wallet"
                value={depositOptions.wallet.id}
                variant="contained"
                onClick={() => {
                  input.onChange(depositOptions.wallet.id)
                  setFormValues({ type: 'wallet', name: 'wallet', currency: depositOptions.wallet.currency })
                  if (handleNext) {
                    handleNext()
                  }
                }}
                startIcon={<AccountBalanceWalletIcon />}
                sx={{ width: '20.625rem', height: "2.75rem", mt: 2, mb: "1rem", backgroundColor: formValues.type === 'wallet' ? `#341D5C` : null, mx: "auto" }}
              />
              <FormHelperText sx={{ color: 'red' }}>
                {meta?.touched ? meta?.error || meta?.submitError : ""}
              </FormHelperText>
            </>
          )}
        />

      </Box> */}

      {filteredOptions.length > 0 ? (
        <Field name={name}>
          {({ input }) => (
            <div>
              {filteredOptions.map((result) => (
                <div key={result.id}
                  onClick={() => {
                    input.onChange(result.id);
                    setFormValues({ type: result.type, currency: result.currency, name: result.name });
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={input.value === result.id}
                        onChange={() => { }}
                        value={result.id}
                        name={input.name}
                        sx={{
                          '&.Mui-checked': {
                            color: '#000000',
                          }
                        }}
                      />
                    }
                    label={result.name}
                  />
                </div>
              ))}
            </div>
          )}
        </Field>
      ) : (
        <>
         {/*  <LightStyledTabs value={activeTab} onChange={handleTabChange} sx={{
            mx: 'auto', marginBottom: '1.25rem', backgroundColor: '#F0EBF8', borderRadius: "0.5rem", minWidth: "auto", width: "70%",
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
          }}>
            <LightStyledTab label="Pockets" />
            <LightStyledTab label="Goals" />
          </LightStyledTabs>
          {activeTab === 0 && <Box id="pockets">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "0.5rem" }}>
              <Typography variant="button" sx={{ color: '#3F3F46' }}>POCKETS</Typography>
              <Button variant="text" title='Create a pocket' onClick={() => history.push(`/pockets/create`)} sx={{ color: 'black' }} />
            </Box>
            <Field name={name}>
              {({ input }) => (
                <div>
                  {pockets.map((result) => (
                    <div key={result.id}
                      onClick={() => {
                        input.onChange(result.id);
                        setFormValues({ type: result.type, currency: result.currency, name: result.name });
                        if (handleNext) {
                          handleNext()
                        }
                      }}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={input.value === result.id && formValues.type === 'pocket'}
                            onChange={() => { }}
                            value={result.id}
                            name={input.name}
                            sx={{
                              '&.Mui-checked': {
                                color: '#000000',
                              }
                            }}
                          />
                        }
                        label={result.name}
                      />
                      {/* <RadioButtons
                  options={DEPOSIT_OPTIONS.pockets}
                  onChange={(e) => {
                    console.log(props);
                    props.input.onChange(e.target.value);
                  }}
                  {...props}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "0.5rem"
                  }}
                  labelPlacement={'start'}
                  labelStyles={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                /> 
                    </div>
                  ))}
                </div>
              )}
            </Field>
          </Box>
          } */}
          <Box id="goals">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "0.5rem" }}>
              <Typography variant="button" sx={{ color: '#3F3F46' }}>Select goal</Typography>
              <Button variant="text" title='Create a goal' onClick={() => history.push(`/goals/create`)} sx={{ color: 'black' }} />
            </Box>
            <Field name={name}>
              {({ input }) => (
                <div>
                  {goals.map((result) => (
                    <div key={result.id}
                      onClick={() => {
                        input.onChange(result.id);
                        setFormValues({ type: result.type, currency: result.currency, name: result.name });
                        if (handleNext) {
                          handleNext()
                        }
                      }}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={input.value === result.id && formValues.type === 'goal'}
                            onChange={() => { }}
                            value={result.id}
                            name={input.name}
                            sx={{
                              '&.Mui-checked': {
                                color: '#000000',
                              }
                            }}
                          />
                        }
                        label={result.name}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Field>
          </Box>

        </>
      )}

    </Box>
  );
}

interface PaymentMethodStepProps {
  values: Partial<DepositFormValues>,
  setFormValues: any,
  formValues: FormValues,
  handleNext: any,
  form: any

}

const payOptions = [
  { label: 'MTN Momo', value: 'mtn' },
  { label: 'Airtel Momo', value: 'airtel' },
];

export const PaymentMethodStep: React.FC<PaymentMethodStepProps> = ({ values, setFormValues, formValues, handleNext, form }) => {
  const [showFeeInfo, setShowFeeInfo] = useState(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = removeCommas(e.target.value);
    form.change('amount', amount);
    setShowFeeInfo(!!amount);
  };

  const isNextDisabled = !values.amount || !values.phoneNumber;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.6 }}>
      <Field name="amount" data-testid="amount">
        {(props: any) => (
          <FormInput
            label="Amount"
            type="text"
            name="amount"
            value={numberWithCommas(props.input.value)}
            onChange={handleAmountChange}
            required
            {...props}
          />
        )}
      </Field>
      {showFeeInfo && (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F0F4F8',
          borderRadius: '4px',
          padding: '8px',
          marginTop: '8px',
        }}>
          <InfoIcon sx={{ color: '#3182CE', marginRight: '8px' }} />
          <Typography sx={{ color: '#2C5282', fontSize: '0.875rem' }}>
            Please note: A 1% fee will be applied by the telecom provider for this transaction.
          </Typography>
        </Box>
      )}
      <Box>
        <Typography variant="body1" fontWeight={600} sx={{ marginBottom: "0.5rem" }}>Payment Method</Typography>
        <Field name="method">
          {(props: any) => (
            <RadioButtons
              options={payOptions}
              onChange={(e) => {
                props.input.onChange(e.target.value);
              }}
              {...props}
            />
          )}
        </Field>
      </Box>
      <Field
        name="phoneNumber"
        component={FormInput}
        type="string"
        label="Enter phone number"
        required
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 3,
        }}
      >
        <Button color="primary" onClick={handleNext} title='Back' sx={{ backgroundColor: '#F0EBF8', mr: 1.5, width: '120px' }} />
        <Button
          variant="contained"
          type="submit"
          title='Next'
          disabled={isNextDisabled}
          sx={{ width: '120px' }}
        />
      </Box>
    </Box>
  );
};
