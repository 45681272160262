import React, { useEffect, useState } from "react";
import { Alert, Avatar, Box, DialogContent, DialogTitle, Drawer, FormControlLabel, FormHelperText, Popover, Radio, Skeleton, Typography, } from "@mui/material";
import { Field, Form } from "react-final-form";
import FormInput from "../Reusables/Input/FormInput";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import Fuse from "fuse.js";
import Button from "../Reusables/Input/Button";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CustomLink from "../Reusables/Input/CustomLink";
import RadioButtons from "../Reusables/Input/RadioGroup";
import CloseIcon from '@mui/icons-material/Close';
import { calculateWithdrawalFee, numberWithCommas, removeCommas } from "../../utils/middleware";
import { usePaymentMethods } from "../../hooks/paymentMethodHooks";
import { PaymentMethod } from "../../types/types";
import Select from "../Reusables/Input/select/Select";
import { useHistory } from "react-router-dom";
import DoneIcon from '@mui/icons-material/Done';
import { useMobilePayouts, useBankPayouts, usePayoutOptions } from "../../hooks/paymentHooks";
import { withdrawOptions } from "../Reusables/Input/select/Options";
import { FormApi } from "final-form";
import ModalWrapper from "./ModalWrapper";
import { LightStyledTab, LightStyledTabs } from "../Reusables/LightStyledTabs";
import { useUserWalletTotal } from "../../hooks/balanceHooks";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DepositOptionsStep } from "./CreateDeposit";
import { MobilePayValues } from "../../types/actionTypes";

interface WithdrawStepperProps {
  open: boolean;
  onClose: () => void;
  step?: number
}

const steps = ['Withdraw Options', 'Payment Details', 'Fee details', 'Confirm', 'Success!'];


interface WithdrawFormValues {
  type: string,
  amount: string,
  method: string,
  id: string | number,
  phoneNumber: string,
  bank: PaymentMethod,


}

interface FormValues {
  type: string;
  currency: string;
  name: string;
  amount: number;
}

const CreateWithdrawal: React.FC<WithdrawStepperProps> = ({ open, onClose, step = 0 }) => {
  const [activeStep, setActiveStep] = useState(step);
  const [formValues, setFormValues] = useState({ type: '', currency: '', name: '', amount: 0 });
  const { makeMobilePayoutFromWallet, makeMobilePayoutFromGoal, isLoading: mobileLoading, error: mobileError } = useMobilePayouts();
  const { makeBankPayoutFromWallet, makeBankPayoutFromGoal, isLoading: bankLoading, error: bankError } = useBankPayouts();
  const history = useHistory()


  const handleReset = (form: FormApi<WithdrawFormValues, Partial<WithdrawFormValues>>) => {
    setActiveStep(0);
    form.reset();
    onClose()
    setFormValues({ type: '', currency: '', name: '', amount: 0 });
  };


  const validate = (values: WithdrawFormValues) => {
    const errors: Partial<WithdrawFormValues> = {};
    const withdrawLimit = 500000
    if (activeStep === 0) {
      if (!values.id) {
        errors.id = "required";
      }

    }

    if (activeStep === 1) {
      if (!values.amount) {
        errors.amount = "required"
      }
      if (values.amount && parseFloat(removeCommas(values.amount)) > formValues.amount && formValues.currency === 'UGX') {
        errors.amount = 'Insufficient funds'
      }

      if (values.amount && parseFloat(removeCommas(values.amount)) > withdrawLimit) {
        errors.amount = `Transaction limit is ${numberWithCommas(withdrawLimit)}`
      }


      if (!values.method) {
        errors.method = "required";
      }

      if (values.method === 'mobile') {
        if (!values.phoneNumber) {
          errors.phoneNumber = 'required'
        }
      }

    }

    return errors;
  };

  const handleNext = async (values: WithdrawFormValues) => {
    if (activeStep === steps.length - 2) {
      try {
        if (values.method === 'mobile') {
          let data = {
            amount: parseFloat(removeCommas(values.amount)),
            phoneNumber: values.phoneNumber,
            type: formValues.type === 'group' ? 'wallet' : formValues.type,
            id: values.id as number,
            currency: formValues.currency,
            url: history.location.pathname,
            fee: calculateWithdrawalFee(Number(values.amount), formValues.currency)
          }

          if (data.type === 'wallet') {
            await makeMobilePayoutFromWallet(data as MobilePayValues)

          } else if (data.type === 'goal') {
            await makeMobilePayoutFromGoal(data as MobilePayValues)
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);




        } else if (values.method === 'bank') {
          let data = {
            amount: parseFloat(removeCommas(values.amount)),
            bankAccount: {
              id: values.bank.id,
              code: values.bank.bank_acc.code,
              accountNumber: values.bank.acc_no as string,
            },
            type: formValues.type === 'group' ? 'wallet' : formValues.type,
            id: values.id as number,
            currency: formValues.currency,
            url: history.location.pathname,
            fee: calculateWithdrawalFee(Number(values.amount), formValues.currency)
          }

          if (data.type === 'wallet') {
            console.log('wallet', data)
            await makeBankPayoutFromWallet(data)
          } else if (data.type === 'goal') {
            await makeBankPayoutFromGoal(data)
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } catch (error) {
        console.log(`error`)
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex' }}>
        <Typography sx={{ m: '0 auto', fontWeight: 600 }}>{steps[activeStep]}</Typography>
        <CloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent sx={{ padding: { lg: '28px 55px' } }}>
        <Form
          onSubmit={handleNext}
          validate={validate}
          initialValues={{
            method: 'mobile',
            amount: 0
          }}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              {activeStep === 0 && <WithdrawOptionsStep
                formValues={formValues}
                handleNext={handleNext}
                setFormValues={setFormValues} name={'id'} />}
              {activeStep === 1 && <WithdrawDetailsStep
                values={values} formValues={formValues} setFormValues={setFormValues} form={form} handleNext={handleNext} />}
              {activeStep === 2 && <Box>
                <Typography fontWeight={600}>Transaction details</Typography>
                <Typography>Amount: {formValues.currency}{numberWithCommas(values.amount)} </Typography>
                <Typography>Fee: {formValues.currency} {numberWithCommas(calculateWithdrawalFee(Number(values.amount), formValues.currency))} </Typography>
              </Box>}
              {activeStep === 3 && <Box>
                <Typography>
                  You are withdrawing {" "}
                  <Typography component='span' fontWeight={600}>{numberWithCommas(values.amount)}</Typography> <Typography component='span' variant='overline'>{formValues.currency}</Typography> to <Typography component={'span'} fontWeight={600}>
                    {values.method === 'mobile' ? values.phoneNumber : values.method === 'bank' ? `${values.bank.bank_acc.name} -- ${values.bank.acc_no}` : values.method === 'wallet' ? 'wallet' : values.method === 'transfer' ? formValues.name : null}</Typography> from {formValues.name}.
                </Typography>
              </Box>}
              {activeStep === 4 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#22C55E', mt: 2, width: 60, height: 60 }}>
                  <DoneIcon fontSize='large' />
                </Avatar>
                <Typography variant='h6' sx={{ mt: 1, fontWeight: 600 }}>Payment Queued!</Typography>
                <Typography>Your transaction is being processed.Please wait.</Typography>
              </Box>}

              {activeStep === steps.length - 2 && mobileError && <Alert severity='error'>{(mobileError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && bankError && <Alert severity='error'>{(bankError as Error).message}</Alert>}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 3,
                }}
              >
                {activeStep === steps.length - 1 ? (
                  <Button title='Great' variant='contained' sx={{ width: '100%', mt: 2 }} onClick={() => handleReset(form)} />
                ) : (
                  <>
                    {activeStep !== 0 && (
                      <Button color="primary" onClick={handleBack} title='Back' sx={{ backgroundColor: '#F0EBF8', mr: 1.5, width: '120px' }} />
                    )}
                    <Button variant="contained" type="submit"
                      loading={mobileLoading || bankLoading}
                      disabled={mobileLoading || bankLoading}
                      title={activeStep === steps.length - 2 ? 'Withdraw' : 'Next'}
                      sx={{ width: '120px' }}
                    />
                  </>
                )}
              </Box>
            </form>
          )}
        />
      </DialogContent>
    </ModalWrapper>
  );
}


export default CreateWithdrawal;

interface WithdrawStepProps {
  formValues: FormValues,
  setFormValues: any;
  name: string;
  handleNext?: any
}

export const WithdrawOptionsStep: React.FC<WithdrawStepProps> = ({ formValues, setFormValues, name, handleNext }) => {
  const { data: payoutOptions, isLoading, isError } = usePayoutOptions();
  const [activeTab, setActiveTab] = useState(0);

  const [searchWord, setSearchWord] = useState("");
  const options = {
    keys: ["name", "type"],
    minMatchCharLength: 3
  };

  if (isLoading) {
    return <Skeleton variant="rectangular" width={210} height={20} />;
  }

  if (isError) {
    return <Skeleton variant="rectangular" width={210} height={20} />
  }
  const searchableOptions = [
    ...payoutOptions.goals.map((option) => option),
  
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);

    // Use the ID to scroll to the selected section
    const sectionId = newValue === 0 ? 'pockets' : newValue === 1 ? 'goals' : 'groups';

    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }

  };

  const fuse = new Fuse(searchableOptions, options);


  const handleSearch = (query: string) => {
    setSearchWord(query)
  };

  const goals = payoutOptions.goals.map((goal) => {
    return {
      ...goal
    }
  })




  const filteredOptions = fuse.search(`${searchWord}`).map((result) => result.item);

  return (
    <Box>
      <FormInput
        name='search deposit options'
        value={searchWord}
        placeholder="Search here"
        onChange={(e) => handleSearch(e.target.value)}
        InputProps={{
          startAdornment: <SearchOutlined />
        }}
      />
   {/*    <Box>
        <Field
          name={name}
          component={({ input, meta }) => (
            <>
              <Button
                title="Choose Wallet"
                value={payoutOptions.wallet.id}
                variant="contained"
                onClick={() => {
                  input.onChange(payoutOptions.wallet.id)
                  setFormValues({ type: 'wallet', name: 'wallet', currency: payoutOptions.wallet.currency, amount: payoutOptions.wallet.amount })
                  if (handleNext) {
                    handleNext()
                  }
                }}
                startIcon={<AccountBalanceWalletIcon />}
                sx={{ width: '100%', mt: 2, backgroundColor: formValues.type === 'wallet' ? `#341D5C` : null }}
              />
              <FormHelperText sx={{ color: 'red' }}>
                {meta?.touched ? meta?.error || meta?.submitError : ""}
              </FormHelperText>
            </>
          )}
        />

      </Box> */}
      <Typography sx={{ textAlign: 'center', mt: 1 ,mb:2}}>Select goal</Typography>

      {filteredOptions.length > 0 ? (
        <Field name={name}>
          {({ input }) => (
            <div>
              {filteredOptions.map((result) => (
                <div key={result.id} onClick={() => {
                  input.onChange(result.id);
                  setFormValues({ type: result.type, currency: result.currency, name: result.name, amount: result.amount });

                }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={input.value === result.id}
                        onChange={() => { }}
                        value={result.id}
                        name={input.name}
                      />
                    }
                    label={result.name}
                  />
                </div>
              ))}
            </div>
          )}
        </Field>
      ) : (
        <>
        {/*   <LightStyledTabs value={activeTab} onChange={handleTabChange} sx={{ flexGrow: 1, m: '0 auto', marginTop: '10px', backgroundColor: '#F0EBF8' }}>
            <LightStyledTab label="Pockets" />
            <LightStyledTab label="Goals" />
          </LightStyledTabs>
          {activeTab === 0 && <Box sx={{ mt: 1 }} id="pockets">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="body2" sx={{ color: '#3F3F46' }}>POCKETS</Typography>

            </Box>
            <Field name={name}>
              {({ input }) => (
                <div>
                  {pockets.map((result) => (
                    <div key={result.id}
                      onClick={() => {
                        input.onChange(result.id);
                        setFormValues({ type: result.type, currency: result.currency, name: result.name, amount: result.amount });
                        if (handleNext) {
                          handleNext()
                        }
                      }}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={input.value === result.id && formValues.type === 'pocket'}
                            onChange={() => { }}
                            value={result.id}
                            name={input.name}
                          />
                        }
                        label={result.name}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Field>
          </Box>} */}
          <Box sx={{ mt: 1 }} id="goals">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="body2" sx={{ color: '#3F3F46' }}>GOALS</Typography>

            </Box>
            <Field name={name}>
              {({ input }) => (
                <div>
                  {goals.map((result) => (
                    <div key={result.id}
                      onClick={() => {
                        input.onChange(result.id);
                        setFormValues({ type: result.type, currency: result.currency, name: result.name, amount: result.amount });
                        if (handleNext) {
                          handleNext()
                        }
                      }}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={input.value === result.id && formValues.type === 'goal'}
                            onChange={() => { }}
                            value={result.id}
                            name={input.name}
                          />
                        }
                        label={result.name}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Field>
          </Box>
        </>
      )}
    </Box>
  );
}

interface WithdrawDetailsFormValues {
  values: Partial<WithdrawFormValues>,
  formValues: Partial<FormValues>,
  setFormValues: any,
  handleNext: any,
  form: any
}


export const WithdrawDetailsStep: React.FC<WithdrawDetailsFormValues> = ({ values, formValues, form, handleNext, setFormValues }) => {
  const { data: paymentMethodsData, } = usePaymentMethods();
  const phoneMethods: PaymentMethod[] = paymentMethodsData?.phone || []
  const bankMethods: PaymentMethod[] = paymentMethodsData?.bank || []
  const { data: wallet } = useUserWalletTotal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);


  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget as HTMLDivElement);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [displayInput, setDisplayInput] = useState(phoneMethods.length === 0)

  useEffect(() => {
    if (phoneMethods.length !== 0) {
      setDisplayInput(!displayInput);
    }
  }, [paymentMethodsData]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.6 }}>
      <Box>
        <Field name="amount" data-testid="amount">
          {(props: any) => <FormInput
            label="Amount"
            type="text"
            name="amount"
            value={numberWithCommas(props.input.value)}
            onChange={(e) => {
              props.input.onChange(removeCommas(e.target.value));

            }}
            {...props}
          />}
        </Field>
        {formValues.amount && (
          <Typography variant='caption' color='textSecondary'>{`Max amount:${numberWithCommas(formValues.amount)} ${formValues.currency}`}</Typography>
        )}
      </Box>

      <Box>
        <Typography fontWeight={600}>Payment Method</Typography>
        <Field name="method" >
          {(props: any) => <RadioButtons
            options={formValues.currency === 'USD' ? withdrawOptions.filter(option => option.value !== 'mobile') : withdrawOptions}
            onChange={(e) => {
              props.input.onChange(e.target.value);
              if (e.target.value === 'wallet' && !props.meta.submitError && wallet && values.amount && parseFloat(removeCommas(values.amount)) > 0) {
                form.change('destination', wallet.id);
                setFormValues({ type: 'wallet', name: 'wallet', currency: 'UGX' })
                handleNext()
              }
            }}
            {...props}
          />}
        </Field>
      </Box>

      {values.method === "mobile" ? (
        <div >
          {displayInput ? (
            <Field
              label="Enter phone number"
              name="phoneNumber"
              component={FormInput}
              type="string"
            />
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight={600}>Choose PhoneNumber</Typography>
                <Typography fontWeight={600} onClick={() => setDisplayInput(!displayInput)} sx={{ color: '#006703' }}>Add </Typography>
              </Box>
              <Field
                phoneNumbers={phoneMethods}
                name="phoneNumber"
                type="select"
                component={Select}
              />
            </>
          )}
        </div>
      ) : values.method === "bank" ? (
        <div >
          <Typography fontWeight={600}>Choose Account</Typography>
          {bankMethods.length === 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography fontWeight={600}>Add bank account to continue transaction</Typography>
              <CustomLink href={`/profile/settings/payment-methods`} title='Add card' />
            </Box>
          ) : (
            <Field
              banks={bankMethods}
              name="bank"
              type="select"
              component={Select}
            />
          )}
        </div>
      ) : values.method === 'transfer' ?
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleClick}>
            <Typography fontWeight={600} sx={{ width: '51px' }}>To</Typography>
            <Field
              name='destination'
              component={(props) => (
                <FormInput
                  value={formValues.name}
                  InputProps={{
                    readOnly: true,
                    endAdornment: <ArrowDropDownIcon />
                  }} {...props} />
              )}
            />
          </Box>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box sx={{ p: 1 }}>
              <DepositOptionsStep formValues={values as unknown as FormValues} setFormValues={setFormValues} name='destination' handleNext={handleClose} />
            </Box>
          </Popover>
        </>
        : null}
    </Box>
  );
};





