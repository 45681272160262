import { Stack, Chip } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';

interface SavingsFilter {
  value: string;
  label: string;
}

interface GoalFilterProps {
  selected: string
  filters: SavingsFilter[];
  handleFilterChange: (filter: string) => void;
}

const CustomizedChip = styled(Chip)(({ theme }) => ({
  '&.MuiChip-root': {
    background: "transparent",
    fontSize: "0.875rem",
    color: '#000',
    borderRadius: '0.5rem',
    padding: "0.1875rem",
    '&:hover': {
      background: '#F0EBF8'
    },
    '&.MuiChip-filledPrimary': {
      background: '#F0EBF8',
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  }
}))


export const SavingFilter: React.FC<GoalFilterProps> = ({ filters, handleFilterChange, selected }) => {
  //this was done to make the boolean value searchbale by fuse.js
  const handleChipClick = (filter: string) => {
    if (filter === 'Active') {
      handleFilterChange('true');
    } else if (filter === 'Inactive') {
      handleFilterChange('false');

    } else {
      handleFilterChange(filter);
    }
  };

  return (
    <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }} >
      {filters.map((filter, index) => (
        <CustomizedChip
          color={filter.value === selected ? 'primary' : 'default'}
          key={index}
          label={filter.label}
          onClick={() => handleChipClick(filter.value)}
        />
      ))}
    </Stack>
  );
}
