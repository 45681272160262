import { Route, Switch } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Dashboard from "../pages/home/Home";
import authGuard from "../utils/auth";
import LabelBottomNavigation from "../components/Reusables/Navigation/BottomNavigation";
import { useStyles } from "../components/Reusables/Navigation/Sidebar/SidebarStyles";
import AuthRoutes from "./authRoutes";
import ProfileRoutes from "./profileRoutes";
import GoalRoutes from "./goalRoutes";
import NoMatch from "../components/Reusables/NoMatch";
import TransactionRoutes from "./transactionRoutes";
//import WalletRoutes from "./walletRoutes";
/* import PocketRoutes from "./pocketRoutes"; */
import PaymentRequestPage from "../pages/wallet/PaymentRequestPage";
import LoanRoutes from "./loanRoutes";
import { routes } from "../components/Reusables/Navigation/Sidebar/WithSidebar";

//The PageRoutes component is then conditionally rendered based on the device screen size.
const PageRoutes = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
   // Check if the current location is /auth
   const isAuthRoute = location.pathname.startsWith('/auth');
   const isNoMatchRoute = !routes.includes(location.pathname);
  return isMobile ? (
    <div className={classes.containerRoot}>
      <div className={classes.contentBottom}>
        <Switch>
          <Route path="/transactions" component={authGuard(TransactionRoutes)}/>
         {/*  <Route  path="/wallet" component={authGuard(WalletRoutes)}/> */}
          <Route  path="/profile" component={authGuard(ProfileRoutes)} />
          <Route  path="/goals" component={authGuard(GoalRoutes)} />
           <Route  path="/loans" component={authGuard(LoanRoutes)} />
         {/*  <Route  path="/pockets" component={authGuard(PocketRoutes)} /> */}
          <Route  path="/auth" component={AuthRoutes} />
          <Route exact path="/" component={authGuard(Dashboard)} />
          <Route exact path="/payment-request/:requestId" component={authGuard(PaymentRequestPage)} />
          <Route path="*" component={NoMatch} />
        </Switch>
      </div>
      {/* Conditionally render the LabelBottomNavigation component */}
      {!isAuthRoute && !isNoMatchRoute &&<LabelBottomNavigation />}
    </div>
  ) : <Switch>
       <Route path="/transactions" component={authGuard(TransactionRoutes)} />
     {/*  <Route path="/wallet" component={authGuard(WalletRoutes)} /> */}
       <Route path="/profile" component={authGuard(ProfileRoutes)} />
       <Route path="/goals" component={authGuard(GoalRoutes)} />
      {/* <Route  path="/pockets" component={authGuard(PocketRoutes)} /> */}
       <Route path="/auth" component={AuthRoutes} />
       <Route  path="/loans" component={authGuard(LoanRoutes)} />
       <Route exact path="/" component={(authGuard(Dashboard))} />
       <Route exact path="/payment-request/:requestId" component={authGuard(PaymentRequestPage)} />
       <Route path="*" component={NoMatch} />
     </Switch>

;
};

export default PageRoutes;
