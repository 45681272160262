import React, { useEffect, useState, } from "react";
import Total from "../../components/home/HomeHeader";
import { motion } from "framer-motion";
import MaturedGoalsPopup from "../../components/Modals/MaturedGoalsPopup";
import { Typography, Box, CircularProgress } from "@mui/material";
import { HomeOptions } from "../../components/home/HomeOptions";
import TransactionList from "../../components/home/TransactionList";
import { useInfiniteTransactions } from "../../hooks/transactionHooks";
import { useMaturedGoals } from "../../hooks/goalHooks";
import { GoalItem, } from "../../types/types";
import { useHistory, useLocation } from 'react-router-dom';
import AdSection from "../../components/home/AdSection";
import Button from '../../components/Reusables/Input/Button';
import ContentBox from "../../components/layout/ContentBox";
import MembershipPopup from "../../components/Modals/MembershipInfo";

const Home = () => {
  const history = useHistory();
  const transactions = useInfiniteTransactions({ limit: 3 }, false);
  const { data: maturedGoals, isLoading, isError } = useMaturedGoals();
  const location = useLocation<{ fromLogin?: boolean; fromSignup?: boolean }>();

  const [showMaturedGoalsPopup, setShowMaturedGoalsPopup] = useState(false);
  const [showMembershipPopup, setMembershipPopup] = useState(false);
  const [maturedGoal, setMaturedGoal] = useState<GoalItem | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isComingFromLoginPage =
      location.state && location.state.fromLogin === true;

    if (isComingFromLoginPage && !isLoading && !isError && maturedGoals) {
      if (maturedGoals.length > 0) {
        setMaturedGoal(maturedGoals[0]);
        setShowMaturedGoalsPopup(true);
      }
    }
  }, [location.state, isLoading, isError, maturedGoals]);

  useEffect(() => {
    const isComingFromSignupPage =
      location.state && location.state.fromSignup === true;

    if (isComingFromSignupPage) {
      setMembershipPopup(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (transactions) {
      setLoading(true);
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, []);

  const handleClosePopup = () => {
    setShowMaturedGoalsPopup(false);
    setMaturedGoal(null);
  };



  return (
    <motion.div
      initial={{ opacity: 0.8 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.9, ease: [0.43, 0.13, 0.23, 0.96] }}

    >
      <Total />
      <ContentBox>
        <HomeOptions />
        <AdSection />
        <Box mt={3}>
          <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: "8px" }}>
            <Typography variant="body1" sx={{ fontWeight: 700 }}>Activity</Typography>
            <Button variant="text" title="View all" onClick={() => history.push(`/profile/notifications`)} sx={{ color: 'black' }} />
          </Box>
          {loading ? <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><CircularProgress /></Box> : <TransactionList transactions={transactions} />}
        </Box>
      </ContentBox>

      {/* Display the popup when a matured goal is found */}
      {showMaturedGoalsPopup && maturedGoal && (
        <MaturedGoalsPopup maturedGoal={maturedGoal} onClose={handleClosePopup} open={showMaturedGoalsPopup} />
      )}
      <MembershipPopup open={showMembershipPopup} onClose={() => setShowMaturedGoalsPopup(true)} />
    </motion.div>
  );
};

export default Home
