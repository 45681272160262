import axios from "axios";

import Cookies from "js-cookie";

const token = Cookies.get("access_token");
const refreshToken = Cookies.get("refreshToken");




// Set the default headers for all requests
axios.defaults.headers.common["Authorization"] = token;
axios.defaults.headers.common["x-refresh-token"] = refreshToken;
axios.defaults.withCredentials = true;

// Create an instance of axios with custom settings
const api = axios.create({
  baseURL: "/v1/api",
});
// Add a request interceptor to set token headers
api.interceptors.request.use((config) => {
  const token = Cookies.get("access_token");
  const refreshToken = Cookies.get("refreshToken");
  config.headers["Authorization"] = token;
  config.headers["x-refresh-token"] = refreshToken;
  return config;
});


export default api;
