import { Stepper, Step, Box, Typography, InputAdornment, Alert, IconButton, ListItemText, DialogContent, DialogTitle, FormHelperText, ImageList, ImageListItem, ImageListItemBar, useMediaQuery, Theme, Drawer, CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form';
import { QontoConnector } from '../profile/DeleteAccount';
import FormInput from '../../components/Reusables/Input/FormInput';
import RadioButtons from '../../components/Reusables/Input/RadioGroup';
import { businessCategoryOptions, businessDurationOptions, document_types, employmentStatusOptions, loanTypeOptions, yesOrNoOptions } from '../../components/Reusables/Input/select/Options';
import Select from '../../components/Reusables/Input/select/Select';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/Reusables/Input/Button';
import { useHistory } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ApplyBusinessLoan, { LoanSuccess, LoanWitnesses } from './ApplyBusinessLoan';
import { useCreateKycDocument, useDeleteDocumentById, useFetchWitnesses, useUserDocuments } from '../../hooks/userHooks';
import AddIcon from '@mui/icons-material/Add';
import { FormApi } from 'final-form';
import Toast from '../../components/Reusables/Feedback/Toast';
import { numberWithCommas, removeCommas } from '../../utils/middleware';
import { useEmployeeInfo, useLoanApply } from '../../hooks/loanHooks';
import { Document, Page, pdfjs } from 'react-pdf';
import { KYCDocument } from '../../types/types';
import ModalWrapper from '../../components/Modals/ModalWrapper';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

//Step one: about employee
//step two:legal details
//step three:proofs documents
//step four :loan witnesses
interface SalaryFormValues {
  type: string;
  business_type: string;
  employer_name: string;
  employment_status: string;
  current_job: string;
  former_job: string;
  monthly_revenue: string;
  job_duration: string;
  dependants: string;
  home_owner: string;
  home_duration: string;
  extra_salary: string;
  have_national_id: boolean | string;
  have_phonenumber: boolean | string;
  phoneNumber: string;
  nationalFront: File | string;
  nationalBack: File | string;
  profileImage: File | string;
  witnesses: number[] | string;



}
const steps = ['', 'Employee info', 'Legal details', 'Proof documents', 'Loan witnesses'];
const ApplySalaryLoan = () => {
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { loanApply, loading, error } = useLoanApply();
  const { data: governmentalID } = useUserDocuments({ document_name: 'National ID', document_type: 'government' })
  const { data: profileID } = useUserDocuments({ document_name: 'Facial Profile' })



  const validate = (values: SalaryFormValues) => {
    const errors: Partial<SalaryFormValues> = {};
    if (activeStep === 0) {
      if (!values.type) {
        errors.type = "Required";
      }
    }
    if (activeStep === 1) {
      if (!values.business_type) {
        errors.business_type = 'required'
      }
      if (!values.employer_name) {
        errors.employer_name = 'required'
      }
      if (!values.employment_status) {
        errors.employment_status = 'required'
      }
      if (!values.current_job) {
        errors.current_job = 'required'
      }
      if (!values.former_job) {
        errors.former_job = 'required'
      }
      if (!values.monthly_revenue) {
        errors.monthly_revenue = 'required'
      }
      if (!values.job_duration) {
        errors.job_duration = 'required'
      }
      if (!values.dependants) {
        errors.dependants = 'required'
      }
      if (!values.home_owner) {
        errors.home_owner = 'required'
      }
      if (!values.home_duration) {
        errors.home_duration = 'required'
      }
      if (!values.extra_salary) {
        errors.extra_salary = 'required'
      }
    }



    if (activeStep === 2) {
      if (!values.have_national_id) {
        errors.have_national_id = 'required'
      }
      if (values.have_national_id === 'yes' && !values.have_phonenumber) {
        errors.have_phonenumber = 'required'
      }
      if (values.have_phonenumber === 'yes' && !values.phoneNumber) {
        errors.phoneNumber = 'required'
      }
      if (values.have_national_id === 'yes' && !values.nationalFront) {
        errors.nationalFront = 'required'
      }
      if (values.have_national_id === 'yes' && !values.nationalBack) {
        errors.nationalBack = 'required'
      }
      if ((values.nationalFront && typeof values.nationalFront) !== (typeof values.nationalBack) && values.nationalBack) {
        errors.nationalBack = 'Please reupload both images'
      }

      if (!values.profileImage) {
        errors.profileImage = 'required'
      }

    }

    if (activeStep === 4) {
      if (!values.witnesses || values.witnesses.length < 2) {
        errors.witnesses = 'Minimum of 2 people'
      }
    }
    return errors;
  };

  const data = localStorage.getItem('salaryValues') ? JSON.parse(localStorage.getItem('salaryValues') as string) : undefined;

  const handleNext = async (values: SalaryFormValues, form: any) => {
    localStorage.setItem('salaryValues', JSON.stringify(values));
    if (activeStep === 4) {
      try {
        await loanApply({
          business_type: values.business_type,
          employer: { name: values.employer_name },
          employment_status: values.employment_status,
          current_job: values.current_job,
          former_job: values.former_job,
          monthly_revenue: parseFloat(values.monthly_revenue),
          job_duration: values.job_duration,
          dependants: values.dependants,
          home_owner: values.home_owner,
          home_duration: values.home_duration,
          extra_salary: values.extra_salary,
          phone_number: values.phoneNumber,
          witnesses: values.witnesses as number[]
        }, 'salary');

        setOpenSuccess(true)
        form.restart();
        localStorage.removeItem('salaryValues')

      } catch (error) {
        console.log(error)
      }

    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ px: 2 }}>
      <Form
        onSubmit={handleNext}
        validate={validate}
        initialValues={{
          type: data?.type,
          business_type: data?.business_type,
          employer_name: data?.employer_name,
          employment_status: data?.employment_status,
          current_job: data?.current_job,
          former_job: data?.former_job,
          monthly_revenue: data?.monthly_revenue,
          job_duration: data?.job_duration,
          dependants: data?.dependants,
          home_owner: data?.home_owner,
          home_duration: data?.home_duration,
          extra_salary: data?.extra_salary,
          have_national_id: data?.have_national_id,
          have_phonenumber: data?.have_phonenumber,
          phoneNumber: data?.phone_number,
          nationalFront: governmentalID && governmentalID[0] ? governmentalID[0].document_url[0].url : undefined,
          nationalBack: governmentalID && governmentalID[0] && governmentalID[0].document_url[1] ? governmentalID[0].document_url[1].url : undefined,
          profileImage: profileID && profileID[0] && profileID[0].document_url[0].url ? profileID[0].document_url[0].url : undefined,
          witnesses: data?.witnesses ?? []
        }}
        render={({ handleSubmit, values, errors }) => (
          <form onSubmit={handleSubmit} >
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<QontoConnector />}
              sx={{ minHeight: '32px' }}
            >
              {steps.map((label) => (
                <Step key={label}>
                </Step>
              ))}

            </Stepper>
            {activeStep === 0 && (
              <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
                <Box sx={{ width: '100%', height: '373px', backgroundColor: '#D9D9D9', display: 'none' }}>
                </Box>
                <Box>
                  <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}>Are you a business owner/salary contract employee? </Typography>
                  <Field name="type">
                    {(props: any) => <Select
                      onChange={(e: any) => {
                        props.input.onChange(e.target.value)
                        if (e.target.value === 'business') {
                          isDesktop ? setIsBusinessOpen(true) : history.push(`/loans/apply/business`)
                        }
                      }}
                      options={loanTypeOptions}
                      {...props}
                    />}
                  </Field>

                </Box>
                <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Button title='Continue' type='submit' variant='contained' endIcon={<ArrowForwardIcon />} />
                  <Button title='Back' onClick={() => history.goBack()} sx={{ textDecoration: 'underline' }} />
                </Box>

              </Box>
            )}
            {activeStep === 1 && (<EmployeeInfo goBack={handleBack} errors={errors} handleSubmit={handleSubmit} />)}
            {activeStep === 2 && (<LegalDetails goBack={handleBack} values={values} errors={errors} handleSubmit={handleSubmit} />)}
            {activeStep === 3 && (<LegalDocuments values={values} handleBack={handleBack} />)}
            {activeStep === 4 && (<Field name="witnesses">
              {(props) => (<LoanWitnesses   {...props} handleBack={handleBack} loading={loading} error={error} />)}
            </Field>)}
          </form>
        )}
      />
      <LoanSuccess open={openSuccess} onClose={() => setOpenSuccess(!openSuccess)} />
      <Drawer anchor="right" open={isBusinessOpen && isDesktop} onClose={() => setIsBusinessOpen(false)} sx={{
        '& .MuiPaper-root.MuiDrawer-paper': {
          width: '400px'
        }
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ApplyBusinessLoan />
        </Box>
      </Drawer>
    </Box>
  )
}

export default ApplySalaryLoan

const dependantOptions = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4 or more', value: 'more than 4' }
]

const homeOptions = [
  { label: 'Myself', value: 'myself' },
  { label: 'Parents', value: 'parents' },
  { label: 'Landlord', value: 'landlord' },
  { label: 'Rented', value: 'rented' },
]

const EmployeeInfo: React.FC<{ goBack: any; errors: any; handleSubmit: any }> = ({ goBack, errors, handleSubmit }) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    if (activeStep === 0) {
      if (errors.business_type || errors.employer_name || errors.employment_status || errors.current_job || errors.former_job || errors.monthly_revenue || errors.job_duration) {
        handleSubmit()
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (errors.dependants || errors.home_owner || errors.home_duration || errors.extra_salary) {
        handleSubmit()
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
      {activeStep === 0 &&
        <>
          <Typography fontWeight={600} textAlign={'center'}>Tell us about your employer</Typography>
          <Box>
            <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}>What is your industry?</Typography>
            <Field
              type="select"
              name="business_type"
              component={Select}
              options={businessCategoryOptions}
            />
          </Box>
          <Field
            label="What is the name of your employer?"
            name="employer_name"
            type="text"
            component={FormInput}
          />
          <Box>
            <Typography fontWeight={600} >What is your employment status?</Typography>
            <Field
              type="select"
              name="employment_status"
              component={Select}
              options={employmentStatusOptions}
            />
          </Box>

          <Field
            label="What is your current job title?"
            name="current_job"
            type="text"
            component={FormInput}
          />
          <Field
            label="What is your former job title?"
            name="former_job"
            type="text"
            component={FormInput}
          />
          <Field name="monthly_revenue">
            {(props: any) => <FormInput
              label="How much do you earn every month?"
              name="monthly_revenue"
              type='text'
              value={numberWithCommas(props.input.value)}
              onChange={(e) => {
                props.input.onChange(removeCommas(e.target.value));
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">UGX</InputAdornment>,
              }}
              {...props}
            />}
          </Field>
          <Box>
            <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}> For how long have you worked for your current employer? </Typography>
            <Field
              name="job_duration"
              row={false}
              component={RadioButtons}
              options={businessDurationOptions}
            />
          </Box>
          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title='Continue' onClick={handleNext} variant='contained' endIcon={<ArrowForwardIcon />} />
            <Button title='Back' onClick={goBack} sx={{ textDecoration: 'underline' }} />
          </Box>

        </>}
      {activeStep === 1 && <>
        <Typography fontWeight={600} textAlign={'center'}>Tell us a bit about yourself</Typography>
        <Box>
          <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}>How many people are you financially supporting? </Typography>
          <Field
            name="dependants"
            row={false}
            component={RadioButtons}
            options={dependantOptions}
          />

        </Box>
        <Box>
          <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}>Who owns the house or building that you live in? </Typography>
          <Field
            type="select"
            name="home_owner"
            component={Select}
            options={homeOptions}
          />
        </Box>

        <Box>
          <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}>How long have you lived in your current residence? </Typography>
          <Field
            name="home_duration"
            row={false}
            component={RadioButtons}
            options={businessDurationOptions}
          />
        </Box>
        <Field name="extra_salary">
          {(props: any) => <FormInput
            label="What percentage of your salary do you always remain with at the end of the month?"
            name="extra_salary"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            {...props}
          />}
        </Field>
        <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Button title='Continue' type='submit' variant='contained' endIcon={<ArrowForwardIcon />} />
          <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
        </Box>

      </>}
    </Box>


  );
}

const LegalDetails: React.FC<{ goBack: any; values: SalaryFormValues; errors: any; handleSubmit: any }> = ({ values, goBack, errors, handleSubmit }) => {

  const [activeStep, setActiveStep] = useState(0);
  const { createKyc, isLoading, error } = useCreateKycDocument();


  const handleNext = async () => {
    if (activeStep === 0) {
      if (errors.have_national_id || errors.have_phonenumber || errors.phoneNumber) {
        handleSubmit()
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (errors.nationalFront || errors.nationalBack) {
        handleSubmit()
      } else {
        /**if the user already has their national id  */
        if (typeof values.nationalFront === 'string' && typeof values.nationalBack === 'string') {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (values.nationalFront && values.nationalBack) {
          /**if they dont then upload it */
          const formData = new FormData();
          formData.append('name', 'National ID');
          formData.append('type', document_types[0]);
          formData.append('documents', values.nationalFront);
          formData.append('documents', values.nationalBack);
          try {
            await createKyc(formData);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } catch (error) {
            console.log(error)
          }
        }

      }
    } else if (activeStep === 2) {
      if (errors.profileImage) {
        handleSubmit()
      } else {
        if (typeof values.profileImage === 'string') {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (values.profileImage) {
          /**if they dont then upload it */
          const formData = new FormData();
          formData.append('name', 'Facial Profile');
          formData.append('type', document_types[7]);
          formData.append('documents', values.profileImage);

          try {
            await createKyc(formData);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };

  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
      <Typography fontWeight={600} textAlign={'center'}>We need to verify your legal details</Typography>
      {activeStep === 0 &&
        <>
          <Box>
            <Typography>Do you have a National ID?</Typography>
            <Field
              type="select"
              name="have_national_id"
              component={Select}
              options={yesOrNoOptions}
            />
          </Box>
          {values.have_national_id === 'yes' && (
            <Box>
              <Typography>Do you have a phone number registered in those names?</Typography>
              <Field
                type="select"
                name="have_phonenumber"
                component={Select}
                options={yesOrNoOptions}
              />
            </Box>
          )}
          {values.have_phonenumber === 'yes' && (
            <Field
              label="Please enter correct phone number"
              name="phoneNumber"
              type="text"
              component={FormInput}
            />
          )}
          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title='Continue' onClick={handleNext} variant='contained' endIcon={<ArrowForwardIcon />} />
            <Button title='Back' onClick={goBack} sx={{ textDecoration: 'underline' }} />
          </Box>
        </>}
      {activeStep === 1 &&
        <>
          <Box>
            <Typography fontWeight={600} >Take a photo of the front of your National ID.</Typography>
            <Field name="nationalFront">
              {({ input, meta }) => (
                <>
                  <Box>
                    <input
                      type="file"
                      accept="image/*,.pdf"
                      id="frontPhotoUpload"
                      onChange={(e) => {
                        input.onChange(e.target.files && e.target.files[0]);

                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="frontPhotoUpload" >
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>

                        <Button
                          title={"Capture Image"}
                          onClick={() => imageUpload("front")}
                          sx={{ padding: '4px 32px', background: '#F0EBF8' }}
                        />
                      </Box>
                    </label>
                  </Box>
                  <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                </>
              )}
            </Field>
          </Box>
          <Box>
            <Typography fontWeight={600} >Take a photo of the back of your National ID.</Typography>
            <Field name="nationalBack">
              {({ input, meta }) => (
                <>
                  <Box>
                    <input
                      type="file"
                      accept="image/*,.pdf"
                      id="backPhotoUpload"
                      onChange={(e) => {
                        input.onChange(e.target.files && e.target.files[0]);
                      }}

                      style={{ display: 'none' }}
                    />
                    <label htmlFor="backPhotoUpload">
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>

                        <Button
                          title={"Capture Image"}
                          onClick={() => imageUpload("back")}
                          sx={{ padding: '4px 32px', background: '#F0EBF8' }}
                        />
                      </Box>
                    </label>
                  </Box>
                  <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                </>
              )}
            </Field>
          </Box>
          <ImageList sx={{ width: '100%', alignItems: 'start', overFlowY: 'hidden' }} cols={2}>

            {values.nationalFront && (
              <ImageListItem
                sx={{ borderRadius: 8, objectFit: 'cover', border: '1px solid #F6F6F7' }}
              >
                {(values.nationalFront instanceof File && values.nationalFront.type.includes('pdf') || (typeof values.nationalFront === 'string' && values.nationalFront.endsWith('.pdf'))) ? (
                  // Render PDF preview using iframe
                  <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                    <Document
                      file={values.nationalFront instanceof File ? URL.createObjectURL(values.nationalFront) : values.nationalFront}

                    >
                      <Page pageNumber={1} width={200} height={1} />
                    </Document>
                  </div>
                ) : (
                  // Render image
                  <img
                    src={values.nationalFront instanceof File ? URL.createObjectURL(values.nationalFront) : values.nationalFront}
                    alt="National ID Front"
                    style={{ objectFit: 'contain' }}
                  />
                )}
              </ImageListItem>
            )}
            {values.nationalBack && (
              <ImageListItem
                sx={{ borderRadius: 8, border: '1px solid #F6F6F7' }}
              >
                {(values.nationalBack instanceof File && values.nationalBack.type.includes('pdf')) || (typeof values.nationalBack === 'string' && values.nationalBack.endsWith('.pdf')) ? (
                  <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                    <Document
                      file={values.nationalBack instanceof File ? URL.createObjectURL(values.nationalBack) : values.nationalBack}

                    >
                      <Page pageNumber={1} width={200} height={1} />
                    </Document>
                  </div>
                ) : (
                  // Render image
                  <img
                    src={values.nationalBack instanceof File ? URL.createObjectURL(values.nationalBack) : values.nationalBack}
                    alt="National ID Back"
                    style={{ objectFit: 'contain' }}
                  />
                )}
              </ImageListItem>
            )}
          </ImageList>
          {isLoading && (<CircularProgress color="success" sx={{ m: '0 auto' }} />)}
          {error && <Alert severity='error'>{(error as Error).message}</Alert>}
          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title='Continue' onClick={handleNext} disabled={isLoading} loading={isLoading} variant='contained' endIcon={<ArrowForwardIcon />} />
            <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
          </Box>
        </>}
      {activeStep === 2 && (
        <>
          <Box>
            <Typography fontWeight={600} >Take a clear photo of your face(a selfie).</Typography>
            <Box>
              <Field name="profileImage">
                {({ input, meta }) => (
                  <div>
                    <input
                      type="file"
                      accept="image/*,.pdf"
                      id="profilePhotoUpload"
                      onChange={(e) => {
                        input.onChange(e.target.files && e.target.files[0]);

                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="profilePhotoUpload">
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, mt: 2 }}>

                        <Button
                          title={"Upload Image"}
                          sx={{ padding: '4px 32px', background: '#F0EBF8' }}
                          onClick={() => imageUpload("profile")}
                        />
                      </Box>
                    </label>
                    <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                  </div>
                )}
              </Field>
              {values.profileImage && (

                <ImageListItem
                  sx={{ borderRadius: 8, objectFit: 'cover', border: '2px dashed #F6F6F7' }}
                >
                  {(values.profileImage instanceof File && values.profileImage.type.includes('pdf')) || (typeof values.profileImage === 'string' && values.profileImage.endsWith('.pdf')) ? (
                    <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                      <Document
                        file={values.profileImage instanceof File ? URL.createObjectURL(values.profileImage) : values.profileImage}

                      >
                        <Page pageNumber={1} width={200} height={1} />
                      </Document>
                    </div>
                  ) : (
                    // Render image
                    <img
                      src={values.profileImage instanceof File ? URL.createObjectURL(values.profileImage) : values.profileImage}
                      alt="Profile Image"
                      style={{ objectFit: 'contain' }}
                    />
                  )}
                </ImageListItem>
              )}
              {isLoading && (<CircularProgress color="success" sx={{ m: '0 auto' }} />)}
            </Box>
          </Box>
          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title='Continue' type='submit' variant='contained' endIcon={<ArrowForwardIcon />} loading={isLoading} disabled={isLoading} />
            <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
          </Box>
        </>
      )}
    </Box>
  );
}

const LegalDocuments: React.FC<{ handleBack: any; values: SalaryFormValues }> = ({ handleBack }) => {
  const { data: documents, refetch } = useUserDocuments({ document_type: document_types[5] })
  const { deleteDocument } = useDeleteDocumentById();
  const { createKyc, isLoading, error } = useCreateKycDocument();

  const [openDocument, setOpenDocument] = useState(false)
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };
  const handleDeleteDocument = async (documentId: number) => {
    try {
      // Call the deleteDocument function from the hook
      await deleteDocument(documentId);
      setUpdateToast({
        open: true,
        message: "Document deleted",
        type: "success",
      });
    } catch (error: any) {
      setUpdateToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
      <Typography fontWeight={600} textAlign={'center'}>We need to verify your legal details</Typography>
      <Typography>Kindly upload all the required legal documents(3 documents i.e current employer contract, current bank statement, recent utility bill)</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography fontWeight={600}>Upload document</Typography>
        <Button title='Upload' variant='outlined' startIcon={<AddIcon />} onClick={() => setOpenDocument(!openDocument)} />
      </Box>
      {documents && (
        <ImageList sx={{ width: 500, height: 'auto' }} cols={4} gap={0}>
          {documents.map((document: KYCDocument, i: React.Key) => (
            <ImageListItem key={i} sx={{ width: 91, height: 80, borderRadius: 8, objectFit: 'cover', border: `0.5px solid  #F6F6F7` }}>
              <ImageListItemBar
                sx={{ background: 'transparent' }}
                position="top"
                actionIcon={
                  <IconButton
                    sx={{ padding: '0px', display: 'block', color: 'red' }}
                    aria-label={`close ${document.document_name}`}
                    onClick={() => handleDeleteDocument(document.id)}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                actionPosition="left"
              />
              <img
                srcSet={`${document.document_url[0].url}`}
                src={`${document.document_url[0].url}`}
                alt={`transaction attached document`}
                style={{ objectFit: 'cover' }}
                loading="lazy"
              />
            </ImageListItem>
          ))}
          {isLoading && <CircularProgress color="success" sx={{ m: '0 auto' }} />}
        </ImageList>
      )}

      {documents && documents?.length < 3 && <Typography variant='caption' color='error'>Please upload all documents</Typography>}
      <Box sx={{ mt: 1.5 }}>
        <Typography variant='body2' sx={{ fontWeight: 600, textDecoration: 'underline' }}>Uploaded documents</Typography>
        <Box>
          {documents?.map((document) => document.document_name).join(', ')}
        </Box>
      </Box>

      <AddSalaryDocuments open={openDocument} onClose={() => {
        setOpenDocument(!openDocument);
        refetch()
      }} createKyc={createKyc} error={error} isLoading={isLoading} />
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />
      <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Button title='Continue' type='submit' variant='contained' endIcon={<ArrowForwardIcon />} disabled={documents && documents?.length < 3} />
        <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
      </Box>
    </Box>
  );
}



export interface DocumentFormValues {
  type: string;
  document_name: string;
  frontImage: File | string;
}

export const salaryDocumentTypes = [
  { label: 'Employer contract', value: 'employer contract' },
  { label: 'Bank statement', value: 'bank statement' },
  { label: 'Utility bill', value: 'utility bill' }


]
const AddSalaryDocuments: React.FC<{ open: boolean; onClose: any; createKyc: any; error: any; isLoading: any; }> = ({ open, onClose, createKyc, error, isLoading }) => {
  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };
  const [uploadToast, setUploadToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "upload") {
      setUploadToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const validate = (values: DocumentFormValues) => {
    const errors: Partial<DocumentFormValues> = {};
    if (!values.document_name) {
      errors.document_name = 'Document name is required';
    }
    if (!values.frontImage) {
      errors.frontImage = 'Front Image is required';
    }


    return errors;
  };

  const onSubmit = async (values: DocumentFormValues, form: FormApi<DocumentFormValues, Partial<DocumentFormValues>>) => {
    try {
      const formData = new FormData();
      formData.append('name', values.document_name);
      formData.append('type', document_types[5]);
      formData.append('documents', values.frontImage);
      const additionalDetails = [
        { field: 'name', value: values.type },
      ];
      const additionalDetailsString = JSON.stringify(additionalDetails);
      formData.append('additional_details', additionalDetailsString);

      await createKyc(formData);
      setUploadToast({
        open: true,
        message: "Document uploaded successfully",
        type: "success",
      });
      form.reset()
      onClose()
      // Document created successfully, you can perform any further actions here
    } catch (error) {
      // Handle error
      setUploadToast({
        open: true,
        message: "Failed to upload document",
        type: "error",
      });
    }

  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Add documents`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <Form onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Which of these do you have?</Typography>
                  <Field name="document_name" component={Select} fullWidth options={salaryDocumentTypes} />
                </Box>
                <Field
                  name="type"
                  label="Type the name of the document you have"
                  fullWidth
                  component={FormInput}
                />
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach a pdf or clear scanned photo of the document</Typography>
                  <Field name="frontImage">
                    {({ input, meta }) => (
                      <>
                        <Box>
                          <input
                            type="file"
                            accept="image/*,.pdf"
                            id="frontPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);

                            }}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="frontPhotoUpload">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("front")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                          {values.frontImage && (

                            <ImageListItem
                              sx={{ borderRadius: 8, objectFit: 'cover', border: '2px dashed #F6F6F7' }}
                            >
                              {(values.frontImage instanceof File && values.frontImage.type.includes('pdf')) || (typeof values.frontImage === 'string' && values.frontImage.endsWith('.pdf')) ? (
                                <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                                  <Document
                                    file={values.frontImage instanceof File ? URL.createObjectURL(values.frontImage) : values.frontImage}

                                  >
                                    <Page pageNumber={1} width={200} height={1} />
                                  </Document>
                                </div>
                              ) : (
                                // Render image
                                <img
                                  src={values.frontImage instanceof File ? URL.createObjectURL(values.frontImage) : values.frontImage}
                                  alt="Profile Image"
                                  style={{ objectFit: 'contain' }}
                                />
                              )}
                            </ImageListItem>
                          )}
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>

                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Button color="primary" title='Cancel' sx={{ mr: 2, width: '120px' }} onClick={onClose} />
                  <Button variant="contained" color="primary" type="submit" title='Save' loading={isLoading || submitting} sx={{ width: '120px' }} />
                </Box>
              </Box>
            </form>
          )}
        </Form>
        <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
      </DialogContent>
    </ModalWrapper>
  );
}
