import { Alert, Avatar, Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import React, { useState } from 'react';
import { GoalMember } from '../../types/types';
import { numberWithCommas, capitalizeWords } from '../../utils/middleware';
import Modal from '../Modals/Modal';
import Button from '../Reusables/Input/Button';
import FormInput from '../Reusables/Input/FormInput';
import Checkbox from '@mui/material/Checkbox';
import { useTransferGoalOwnership } from '../../hooks/goalHooks';
import { useParams } from 'react-router-dom';

interface TransferOwnershipStepperProps {
  open: boolean;
  onClose: () => void;
  members: GoalMember[]
}



const TransferOwnershipStepper: React.FC<TransferOwnershipStepperProps> = ({ open, onClose, members }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { transferOwnership, error, isLoading } = useTransferGoalOwnership();
  const [selectedMember, setSelectedMember] = useState<GoalMember | null>(null);
  const { id } = useParams<{ id: string }>();


  const sortedMembers = [...members].sort((a, b) => (a.role === 'admin' ? -1 : 1));

  const handleSelectMember = (member: GoalMember) => {
    setSelectedMember(member);
  };

  const handleTransferOwnership = async () => {
    try {
      if (selectedMember) {
        await transferOwnership({ id: parseFloat(id), data: { memberId: selectedMember.id } });
      }
      // Ownership transferred successfully
      handleNextStep()
    } catch (error) {
      // Failed to transfer ownership

    }
  };



  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };


  const titles = ['Are you sure you want to transfer ownership of this goal?', 'Please enter account password to continue', 'Select new owner', 'Transfer ownership', 'Success!'];

  return (
    <Modal open={open} handleClose={onClose} title={titles[currentStep]}>
      {currentStep === 0 && (
        <Box p={2}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, textAlign: 'center', justifyContent: 'center' }}>
            <Button title='Cancel' onClick={onClose} sx={{ mr: 0.4, width: '120px' }} />
            <Button title='Next' variant="contained" onClick={handleNextStep} sx={{ width: '120px' }} />
          </Box>
        </Box>
      )}
      {currentStep === 1 && (
        <Box p={2}>
          <FormInput />
          <Box sx={{ textAlign: 'center', marginTop: 1 }}>
            <Button onClick={handlePrevStep} title='Back' sx={{ mr: 0.4, width: '120px' }} />
            <Button variant="contained" onClick={handleNextStep} title='Next' sx={{ width: '120px' }} />
          </Box>
        </Box>
      )}
      {currentStep === 2 && (
        <Box p={2}>
          <Typography variant='caption'>Members</Typography>
          <List>
            {sortedMembers.map((member: GoalMember) => (
              <ListItem key={member.id}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleSelectMember(member)}
                    checked={selectedMember?.id === member.id}
                  />
                }
                disablePadding
                sx={{
                  borderBottom: `0.5px solid  #F1F2F3`, py: 1
                }}>
                <ListItemButton onClick={() => handleSelectMember(member)} dense>
                  <ListItemAvatar>
                    <Avatar alt={'profile image'} src={member.image ?? undefined}
                      sx={{ bgcolor: "primary.main", width: 35, height: 35 }}
                    >{member.name.charAt(0)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={member.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box sx={{ mt: 1, textAlign: 'center' }}>
            <Button onClick={handlePrevStep} title='Back'
              sx={{ mr: 0.4, width: '120px' }} />
            <Button variant="contained" onClick={handleNextStep} title='Next' disabled={!selectedMember} sx={{ width: '120px' }} />
          </Box>
        </Box>
      )}

      {currentStep === 3 && (
        <Box sx={{ px: 1, py: 2 }}>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>{`You are about to transfer ownership of this account to ${capitalizeWords(`${selectedMember?.users?.first_name} ${selectedMember?.users?.last_name}`)}. This action is irreversible. You will lose control over the goal and its funds but you will remain a participant`}</Typography>
          {error && <Alert severity='error'>{(error as Error).message}</Alert>}
          <Box sx={{ mt: 1, textAlign: 'center' }}>
            <Button onClick={handlePrevStep} title='Back' color='inherit' />
            <Button variant="contained" color='error' onClick={handleTransferOwnership} title='Proceed' loading={isLoading} disabled={isLoading} />
          </Box>
        </Box>
      )}

      {currentStep === 4 && (
        <Box p={2}>
          <Typography variant="body1">{`You will be notified when the new owner accepts or declines the invitation`}</Typography>
          <Button variant="contained" onClick={onClose} title='Close' sx={{ width: '100%', mt: 1 }} />
        </Box>
      )}

    </Modal>
  );
};

export default TransferOwnershipStepper;
