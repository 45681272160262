import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useStyles } from "../Styles";
import { Box, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { styled } from '@mui/material/styles';



export const StyledTabs = styled(Tabs)({
  borderBottom:'0.8px solid #A1A1AA',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
});


export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(18),
  color: 'grey.600',
  '&.Mui-selected': {
    borderRadius:'3px 3px 0px 0px',
    backgroundColor:'#F0EBF8'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

export const ColoredTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(18),
  color:'#fff',
    maxWidth:'180px',
    '&.Mui-selected': {
      color:theme.palette.secondary.main,
      backgroundColor:'#482980'
   },
}));


type Label = {
  label: string;
  icon?: React.ReactElement | undefined;
}

interface SimpleTabsProps {
  labels: Label[];
  handleTab: (newValue: number) => void;
  children: React.ReactNode;
  orientation?: "horizontal" | "vertical";
  value: number;
  tabClasses?: string;
}

function TabPanel(props:{ children?: React.ReactNode; value: number; index: number; style?: string; }) {
  const { children, value, index, style, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={style}
    >
      {value === index && <>{children}</>}
    </div>
  );
}





export default function SimpleTabs({
  labels,
  handleTab,
  children,
  orientation,
  value,
  tabClasses,
}:SimpleTabsProps) {

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    handleTab(newValue);
  };

  const classes = useStyles();

 
  return (
    <Box
      className={orientation === "vertical" ? classes.sideTabs : classes.tabs}
    >
      <StyledTabs
        value={value}
        onChange={handleChange}
        centered
        orientation={orientation}
        indicatorColor={"primary"}
      >
        {labels.map((label, index) => (
          <StyledTab
            disableRipple
            label={label.label}
            icon={label.icon}
            className={label.icon ? classes.tab : ""}
            key={index}
          />
        ))}
      </StyledTabs>

      <TabPanel
        value={value}
        index={value}
        style={orientation === "vertical" ? classes.sidebarContent : tabClasses}
      >
        {children}
      </TabPanel>

    </Box>
  );
}
