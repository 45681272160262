import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTransactions } from '../redux/reducers/transactionReducer';
import { Contact, Transaction } from '../types/types';



export const useMemberSelection = () => {
  const [selectedMembers, setSelectedMembers] = useState<Contact[]>([]);

  const handleAddMember = (member: Contact) => {
    // Check if the member is already selected based on their ID
    const isAlreadySelected = selectedMembers.some((selectedMember) => selectedMember.id === member.pasbanc_id);

    if (!isAlreadySelected && member.id !== null) {
      setSelectedMembers((prevSelectedMembers) => [...prevSelectedMembers, member]);
    }
  };

  const handleRemoveMember = (memberId: number) => {
    setSelectedMembers((prevSelectedMembers) => prevSelectedMembers.filter((member) => member.id !== memberId));
  };

  return {
    selectedMembers,
    handleAddMember,
    handleRemoveMember,
  };
};




export const useTransactionSelection = () => {
  const dispatch = useDispatch();
  const [selectedTransactions, setSelectedTransactions] = useState<Transaction[]>([]);

  const handleTransactionSelect = (transaction: Transaction) => {
    // Check if the transaction is already in the selectedTransactions array
    const isSelected = selectedTransactions.some((t) => t.id === transaction.id);

    if (isSelected) {
      // If the transaction is already selected, remove it from the selectedTransactions array
      const updatedTransactions = selectedTransactions.filter((t) => t.id !== transaction.id);
      setSelectedTransactions(updatedTransactions);
      dispatch(setTransactions(updatedTransactions));
    } else {
      // If the transaction is not selected, add it to the selectedTransactions array
      const updatedTransactions = [...selectedTransactions, transaction];
      setSelectedTransactions(updatedTransactions);
      dispatch(setTransactions(updatedTransactions));
    }
  };

  const selectTransactions=(transactions:Transaction[])=>{
    setSelectedTransactions(transactions)
    dispatch(setTransactions(transactions));

  }

  return {
    selectedTransactions,
    handleTransactionSelect,
    selectTransactions
  };
};
