import { Alert, Avatar, Box, CircularProgress, Drawer, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { capitalizeWords, numberWithCommas } from '../../utils/middleware';
import { useHistory, useParams } from 'react-router-dom';
import { useGoalById } from '../../hooks/goalHooks';
import TargetCard from '../../components/goals/TargetCard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '../../components/Reusables/Input/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState, useEffect } from 'react';
import { DepositStepper, WithdrawalStepper } from '../../components/pockets/PocketHeader';
import InfoDialog from '../../components/Reusables/Feedback/InfoDialog';
import { makeStyles } from '@mui/styles';
import { Theme } from "@mui/material";
import EditGoal from './EditGoal';
import ModalWrapper from '../../components/Modals/ModalWrapper';
import ContentBox from '../../components/layout/ContentBox';
import CreateDeposit from '../../components/Modals/CreateDeposit';

interface RenderButtonsProps {
  admin_access: boolean;
  isAdmin: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex', flexDirection: 'column',
    maxWidth: '717px',
    width: '100%',
    margin: '0 auto',
    borderRadius: '16px',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      backgroundColor: '#FAFBFB',
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
    }
  },
  root: {
    maxWidth: '35.75rem',
    width: '100%',
    margin: '0 auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'center'
    }
  },
  depositButton: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      flexGrow: 'unset',
      width: '182px',

    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginTop: 0
    }
  },
  button: {
    backgroundColor: '#F0EBF8',
    flexGrow: 0.5,
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up('lg')]: {
      width: '182px',
      marginLeft: theme.spacing(1)

    }
  },
}))


// Goal header component after scrolling (condensed version)
/* const GoalHeaderCondensed= () => {
  const { id } = useParams<{id:string}>();
  const history = useHistory();
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);

  const { data:goal,isLoading,isError } = useGoalById(parseFloat(id));

  if (isLoading || isError) {
    return <Typography sx={{px:2}}>Loading...</Typography>;
  }

  const renderButtons = ({admin_access,isAdmin}: RenderButtonsProps):JSX.Element => {
    if (admin_access) {
      if (isAdmin) {
        return (
          <Box sx={{mt:1,mb:2,display:'flex',justifyContent:'space-around',alignItems:'center' }}>
           
           <Button title='Deposit' variant='contained'/>
           <Button title='Withdraw' sx={{backgroundColor:'#F0EBF8'}}/>
           <Button title='Reinvest' sx={{backgroundColor:'#F0EBF8'}}/>
          </Box>
        );
      } else {
        return (
          <Box sx={{mt:1,mb:2,display:'flex',alignItems:'center'}}>
            <Button title='Deposit' variant='contained' sx={{flexGrow:0.6}}/>
          </Box>
        );
      }
    } else {
      return (
        <Box sx={{mt:1,mb:2,display:'flex',justifyContent:'space-around',alignItems:'center'}}>
         
          <Button title='Deposit' variant='contained' 
          sx={{fontSize: { xs: 'small', sm: 'small' }}}
          />
           <Button title='Withdraw' sx={{backgroundColor:'#F0EBF8'}}/>
           <Button title='Reinvest' sx={{backgroundColor:'#F0EBF8'}}/>
        </Box>
      );
    }
  };

 const {name,actual_amount,available_amount,isAdmin,admin_access}= goal
  return (
    <Toolbar sx={{display:'none',flexDirection:'column',alignItems:'stretch',backgroundColor:'primary.main',color:'#fff',position:"absolute",top:0}}>
       <Box sx={{ 
        display:'flex',
        justifyContent:'space-between',
        width:'100%',
        mt:1
        }}>
      <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={()=>history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box sx={{ display:'flex',alignItems:'center', justifyContent:'center' }}>
         <Typography variant="h6" sx={{fontWeight:600}}>
            {name && capitalizeWords(name)}
          </Typography>
          <LockOutlinedIcon fontSize='small' />
         </Box>
         {
            isAdmin && (
                <IconButton color="primary" aria-label="goal settings" onClick={()=>history.push(`/goals/edit/${id}`)}>
                <SettingsIcon />
              </IconButton>
            )
         }
      </Box>
        <Box sx={{display:'flex'}}>
            <Box sx={{flexGrow: 0.5}}>
                <Typography variant='body2' >Saved</Typography>
                <Typography variant='h5'> {numberWithCommas(actual_amount)}</Typography>
            </Box>
            <Box>
            <Typography variant='body2'>Available</Typography>
                <Typography variant='h5' sx={{fontWeight:600}}>{numberWithCommas(available_amount)}</Typography>
            </Box>
        </Box>
      {renderButtons({ admin_access, isAdmin })}
    </Toolbar>
  );
}; */

// Goal header component after scrolling (detailed version)
const GoalHeaderDetailed = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory()
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const { data: goal, isLoading, isError } = useGoalById(parseFloat(id));
  const [hideBalance, setHideBalance] = useState(goal?.hide_balance || false);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (goal?.hide_balance !== undefined) {
      setHideBalance(goal.hide_balance);
    }
  }, [goal?.hide_balance]);

  if (isError || isLoading) {
    return <Box display="flex" height="70vh" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  }


  const { name, minimum_deposit, isAdmin, currency, admin_access, image_url } = goal

  const handleDepositClick = () => {
    setOpenDeposit(true);
  };

  const renderButtons = ({ admin_access, isAdmin }: RenderButtonsProps): JSX.Element => {
    if (admin_access) {
      if (isAdmin) {
        return (
          <ContentBox>
            <Box sx={{ mt: "1.25rem", display: "flex", justifyContent: { lg: "center" }, flexDirection: { xs: "column", lg: "row" } }}>
              <Button title='Deposit' variant='contained' onClick={handleDepositClick} className={classes.depositButton} />
              <Box className={classes.buttonContainer}>
                <Button title='Withdraw' className={classes.button} onClick={() => setOpenWithdraw(!openWithdraw)} disabled={goal?.available_amount < 1} />
                <Button title='Reinvest' className={classes.button} onClick={() => history.push(`/goals/reinvest`)} disabled={goal?.available_amount < 1} />
              </Box>
            </Box>
          </ContentBox>
        );
      } else {
        return (
          <ContentBox>
            <Box sx={{ mt: 1, mb: 2, display: 'flex', alignItems: 'center' }}>
              {/* Render deposit and share buttons */}
              <Button title='Deposit' variant='contained' sx={{ flexGrow: 1 }} onClick={handleDepositClick}
                disabled={!goal.isMember} />
            </Box>
          </ContentBox>
        );
      }
    } else {
      return (
        <ContentBox>
          <Box sx={{ mt: "1.25rem", display: "flex", justifyContent: { lg: "center" }, flexDirection: { xs: "column", lg: "row" } }}>
            <Button title='Deposit' variant='contained' onClick={handleDepositClick} className={classes.depositButton} />
            <Box className={classes.buttonContainer}>
              <Button title='Withdraw' className={classes.button} onClick={() => setOpenWithdraw(!openWithdraw)} disabled={goal?.available_amount < 1} />
              <Button title='Reinvest' className={classes.button} onClick={() => history.push(`/goals/reinvest`)} disabled={goal?.available_amount < 1} />
            </Box>
          </Box>
        </ContentBox>
      );
    }
  };
  // Render the detailed version of the goal header
  return (
    <Toolbar sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', px: { md: 0 } }}>
      {!isError && !goal.isMember && (
        <Alert severity='info' sx={{ p: 1, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }} >
          Join goal to start transacting.
        </Alert>
      )}
      <Box sx={{ backgroundColor: { md: '#FAFBFB' }, width: "100%" }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          pb: { xs: "16px", lg: "2.44rem" },
          pt: 1,
          px: { md: "24px" }
        }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: 'inherit' }}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, gap: 0.5 }}>
            {image_url && <Avatar src={image_url} alt={'goal image'} sx={{ display: { xs: 'none', lg: 'block' } }} />}
            <Typography variant="body1" sx={{ fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical' }}>
              {name && capitalizeWords(name)}
            </Typography>
            <IconButton onClick={() => setOpenInfo(!openInfo)}>
              <LockOutlinedIcon fontSize='small' color='primary' />
            </IconButton>
            <InfoDialog title='Goals' content='Main goals are locked goals that are fixed till maturity date regardless of the saving target amount.' open={openInfo} handleClose={() => setOpenInfo(!openInfo)} width="auto"
              padding="0 0 24px 0" />

          </Box>
          {
            isAdmin && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton aria-label="goal settings" color='inherit' sx={{ display: "flex", alignItems: "center", gap: "0.56rem" }} onClick={isDesktop ? () => setIsSettingsOpen((prevState) => !prevState) : () => history.push(`/goals/edit/${id}`)} disableRipple>
                  <Typography variant='body1'>Settings</Typography>
                  <SettingsIcon />
                </IconButton>
              </Box>
            )
          }
        </Box>
        <Box className={classes.header} >
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Box sx={{ flexGrow: 0.5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Typography variant='body2' sx={{ color: 'grey.600' }}>Saved</Typography>
                <IconButton sx={{ color: '#000' }} size='small' onClick={() => setHideBalance(!hideBalance)}>
                  {hideBalance ? <VisibilityOffIcon sx={{ height: '18px', width: '18px' }} /> : <VisibilityIcon sx={{ height: '18px', width: '18px' }} />}
                </IconButton>
              </Box>
              {!hideBalance ? (
                <Typography variant="h3" fontWeight={600}>
                  {goal.actual_amount && numberWithCommas(goal.actual_amount)}
                </Typography>
              ) : (
                <Typography variant="h4">••••••</Typography>
              )}
            </Box>
            <Box sx={{ ml: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Typography variant='body2' sx={{ color: 'grey.600' }}>Available</Typography>
                <IconButton sx={{ color: '#000' }} size='small' onClick={() => setHideBalance(!hideBalance)}>
                  {hideBalance ? <VisibilityOffIcon sx={{ height: '18px', width: '18px' }} /> : <VisibilityIcon sx={{ height: '18px', width: '18px' }} />}
                </IconButton>
              </Box>
              {!hideBalance ? (
                <Typography variant="h3" fontWeight={600}>
                  {goal.available_amount && numberWithCommas(goal.available_amount)}
                </Typography>
              ) : (
                <Typography variant="h4">••••••</Typography>
              )}
            </Box>
          </Box>
          {minimum_deposit && minimum_deposit > 0 && (
            <Alert sx={{ px: 0 }}
              icon={<InfoOutlinedIcon fontSize='small' sx={{ color: '#000', mr: 0 }} />}>
              <Typography>Minimum deposit is <Typography component='span' fontWeight={600}>{minimum_deposit}</Typography> {currency}</Typography></Alert>)}
        </Box>
      </Box>
      {renderButtons({ admin_access, isAdmin })}
      <ContentBox>
        <TargetCard goal={goal} />
      </ContentBox>
      <CreateDeposit
        open={openDeposit}
        onClose={() => setOpenDeposit(false)}
        preselectedGoal={{
          id: goal.id,
          name: goal.name,
          type: 'goal',
          currency: goal.currency
        }}
      />
      <WithdrawalStepper
        data={{ id: parseFloat(id), type: 'goal', currency, name: name, amount: goal.available_amount }}
        open={openWithdraw} onClose={() => setOpenWithdraw(!openWithdraw)} />

      <ModalWrapper open={isSettingsOpen} onClose={() => setIsSettingsOpen((prevState) => !prevState)}>
        <EditGoal />
      </ModalWrapper>
    </Toolbar>
  );
};

export { GoalHeaderDetailed };
