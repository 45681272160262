import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import { Instagram, Facebook, Twitter } from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { useStyles } from "./ProfileStyles";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';

const ContactUs = () => {
  const contactInfo = [
    {
      label: 'Website',
      link: 'https://pasbanc.com/',
      icon: <LanguageIcon />,
    },
    {
      label: 'Email',
      link: 'mailto:contact@pasbanc.com',
      icon: <EmailIcon />,
    },
    {
      label: 'Whatsapp',
      link: '#',
      icon: <WhatsAppIcon />,
    },
    {
      label: 'Telegram',
      link: 'https://t.me/PasbancChat',
      icon: <TelegramIcon />,
    },
    {
      label: 'Instagram',
      link: 'https://www.instagram.com/pasbanc/',
      icon: <Instagram />,
    },
    {
      label: 'Twitter',
      link: 'https://twitter.com/Pasbanc_',
      icon: <Twitter />,
    },
    {
      label: 'Facebook',
      link: 'https://www.facebook.com/pasbanc',
      icon: <Facebook />,
    },
  ];
  const infoStyles ={
    display: `flex`,
    width: `167px`,
    height: `122px`,
    padding: `24px 16px`,
   flexDirection: `column`,
   justifyContent: `center`,
   alignItems: `center`,
   gap: `4px`,
   flexShrink: `0px`,
   borderRadius: `8px`,
   boxShadow: `-5px 2px 28px 0px rgba(0, 0, 0, 0.10)`

  }
    const classes = useStyles();
    const history =useHistory()


  return (
    <Box m={2}>
        <Box sx={{display:'flex',alignItems:'start',gap:1,mt:2}}>
         <IconButton onClick={()=>history.goBack()} sx={{p:0}}>
            <ArrowBackOutlinedIcon />
         </IconButton>
          <Typography variant="h6" fontWeight={600} mb={2}>
         Contact us
        </Typography>
         </Box>
      <Box display="flex" flexWrap="wrap" gap={1} sx={{mt:2}}>
      {contactInfo.map(({ label, link, icon }) => (
        <Box key={label} display="flex" alignItems="center" mb={1} sx={infoStyles}>
          {icon && <Box mr={1}>{icon}</Box>}
          <Link href={link} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
            {label}
          </Link>
        </Box>
      ))}
      </Box>

      <Typography variant="body1"  mt={3} sx={{textAlign:'center'}}>
         <LocationOnIcon /> Located at Naguru, Kampala Uganda
      </Typography>

      <Typography variant="body1" mt={1} sx={{textAlign:'center'}}>
        <PhoneIcon /> 0778940049
      </Typography>
    </Box>
  );
};

export default ContactUs;
