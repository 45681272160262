import { AuthState } from "../../types/reduxTypes";
import {
  SIGN_UP,
  AUTH_ERROR,
  SIGN_IN,
  SIGN_OUT,
  USER_LOADED,
  CLEAR_ERRORS,
  LOADING,
} from "../actions/types";

// Define a type for the slice state


const initialState:AuthState = {
  isAuth: false,
  loading: false,
  error: "",
};

//the reducer has the inital state and alters it based on the action
export default (state = initialState, action:{
  type: string;
  payload: any;
}) => {
  switch (action.type) {
    case SIGN_UP:
    case SIGN_IN:
      return {
        ...state,
        isAuth: true,
        loading: false,
      };
    case AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_LOADED:
      return {
        ...state,
        loading: false,
      };
    case SIGN_OUT:
      return {
        ...initialState,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: "",
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
