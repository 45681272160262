import { Alert, Avatar, Box, DialogContent, DialogTitle, IconButton, Toolbar, Typography, useMediaQuery, Theme, Drawer } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import { calculateTransferFee, calculateWithdrawalFee, capitalizeWords, numberWithCommas, removeCommas } from '../../utils/middleware';
import { useHistory, useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '../../components/Reusables/Input/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { usePocket } from '../../hooks/pocketHooks';
import ProgressBar from '../Reusables/Feedback/ProgressBar';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import { DialogContainer } from '../Reusables/Feedback/InfoDialog';
import { useBankPayouts, useDeposit, useMobilePayouts } from '../../hooks/paymentHooks';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';
import { PaymentMethodStep } from '../Modals/CreateDeposit';
import DoneIcon from '@mui/icons-material/Done';
import { PaymentMethod } from '../../types/types';
import { WithdrawDetailsStep } from '../Modals/CreateWithdrawal';
import PocketSettings from '../../pages/pockets/PocketSetting';
import { useGoalWalletTransfer, useWalletPocketTransfer, usePocketGoalTransfer, useGoalToGoalTransfer, usePocketToPocketTransfer, useWalletToWalletTransfer } from '../../hooks/transferHooks';
import ContentBox from '../layout/ContentBox';
import ModalWrapper from '../Modals/ModalWrapper';
import UserTransfer from '../Modals/UserTransfer';
import { CardPay, MobilePayValues } from '../../types/actionTypes';

interface RenderButtonsProps {
  admin_access: boolean;
  isAdmin: boolean;
}


// Pocket header component after scrolling (detailed version)
const PocketHeader = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory()
  const [openInfo, setOpenInfo] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { data: pocket, isLoading, isError } = usePocket(parseFloat(id));
  const [hideBalance, setHideBalance] = useState(pocket?.hide_balance || false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  useEffect(() => {
    if (pocket?.hide_balance !== undefined) {
      setHideBalance(pocket.hide_balance);
    }
  }, [pocket?.hide_balance]);


  if (isError || isLoading) {
    return <Box sx={{ px: 2 }}>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', width: '100%',
        mt: 1
      }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, color: 'inherit' }}
          onClick={() => history.goBack()}
        >
          <ArrowBackIcon />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {`Pocket...`}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
        <Typography variant='body2' sx={{ color: 'grey.600' }}>Available</Typography>
        <Typography variant='h3'>{0}</Typography>
      </Box>
    </Box>
  }
  const renderButtons = ({ admin_access, isAdmin }: RenderButtonsProps): JSX.Element => {
    if (admin_access) {
      if (isAdmin) {
        return (
          <Box sx={{ mt: 1, mb: 2, display: 'flex', justifyContent: 'space-around', maxWidth: '600px', m: '8px auto', width: '100%' }}>
            <Button title='Deposit' variant='contained' onClick={() => setOpenDeposit(!openDeposit)} sx={{ flexGrow: 0.5 }} />
            <Button title='Withdraw' sx={{ backgroundColor: '#F0EBF8', flexGrow: 0.5, ml: 0.5 }} onClick={() => setOpenWithdraw(!openWithdraw)} disabled={pocket?.amount_saved < 1} />
            <Button title='Transfer' sx={{ backgroundColor: '#F0EBF8', flexGrow: 0.5, ml: 0.5 }} onClick={() => setOpenTransfer(!openTransfer)} disabled={pocket?.amount_saved < 1} />
          </Box>
        );
      } else {
        return (
          <Box sx={{ mt: 1, mb: 2, display: 'flex', alignItems: 'center' }}>
            <Button title='Deposit' variant='contained' sx={{ flexGrow: 1 }} onClick={() => setOpenDeposit(!openDeposit)} />
          </Box>
        );
      }
    } else {
      return (
        <Box sx={{ mt: 1, mb: 2, display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          {/* Render all three buttons: deposit, withdraw, transfer */}
          <Button title='Deposit' variant='contained'
            sx={{ flexGrow: 0.5 }}
            onClick={() => setOpenDeposit(!openDeposit)} />
          <Button title='Withdraw' sx={{ backgroundColor: '#F0EBF8', flexGrow: 0.5, ml: 0.5 }} onClick={() => setOpenWithdraw(!openWithdraw)} />
        </Box>
      );
    }
  };

  const { name, minimum_deposit, amount_saved, isAdmin, currency, admin_access, target_amount, delay, bonus_earned } = pocket
  // Render the detailed version of the goal header
  return (
    <Toolbar sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        mt: 1
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: { lg: 1 } }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: 'inherit' }}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical' }}>
              {`${capitalizeWords(name)}`}
            </Typography>
          </Box>
        </Box>
        {
          isAdmin && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='body2'>Settings</Typography>
              <IconButton aria-label="pocket settings" sx={{ color: 'inherit' }} onClick={isDesktop ? () => setIsSettingsOpen((prevState) => !prevState) : () => history.push(`/pockets/edit/${id}`)}>
                <SettingsIcon />
              </IconButton>
            </Box>
          )
        }
      </Box>
      <ContentBox>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Typography variant='body2' sx={{ color: 'grey.600' }}>Available</Typography>
            <IconButton sx={{ color: '#000' }} size='small' onClick={() => setHideBalance(!hideBalance)}>
              {hideBalance ? <VisibilityOffIcon sx={{ height: '18px', width: '18px' }} /> : <VisibilityIcon sx={{ height: '18px', width: '18px' }} />}
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
            {!hideBalance ? (
              <Typography variant="h2" >
                {numberWithCommas(amount_saved)}
              </Typography>
            ) : (
              <Typography variant="h5">••••••</Typography>
            )}
            <Typography variant="subtitle1">
              {` ${currency}`}
            </Typography>
          </Box>

          {minimum_deposit && minimum_deposit > 0 && (
            <Alert sx={{ px: 0 }}
              icon={<InfoOutlinedIcon fontSize='small' sx={{ color: '#000', mr: 0 }} />}>{`Minimum deposit is ${minimum_deposit} ${currency}`}</Alert>)}
        </Box>
        {renderButtons({ admin_access, isAdmin })}
        <Box>
          {target_amount && parseFloat(target_amount) > 0 && (
            <ProgressBar value={amount_saved} maxValue={parseFloat(target_amount)} />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 0.5 }}>
            <Typography variant="body2">
              {target_amount && parseFloat(target_amount) > 0
                ? `Saved ${((amount_saved >= parseFloat(target_amount) ? 100 : (amount_saved / parseFloat(target_amount)) * 100)).toFixed(1)}%`
                : 'Saved 0% of target'}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.1 }}>
              <Typography variant='body2'>Target:</Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>{`${target_amount ? numberWithCommas(target_amount) : 0}`}</Typography>
              <Typography variant='overline'>{currency}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>Withdraw delay</Typography>
            <Typography fontWeight={600}>{`${delay} hours`}</Typography>
          </Box>
          <Box sx={{ float: 'right' }} onClick={() => setOpenInfo(!openInfo)}>
            <Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }}>View bonuses</Typography>
          </Box>
        </Box>
      </ContentBox>

      <DialogContainer open={openInfo} onClose={() => setOpenInfo(!openInfo)} width="auto">
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600, textAlign: 'center', m: '0 auto' }}>Interest bonuses</Typography>
          <IconButton onClick={() => setOpenInfo(!openInfo)}><CloseIcon /></IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 1 }}>
          <Typography>Est. total bonuses so far</Typography>
          <Typography fontWeight={600} sx={{ color: 'success.main' }}>{`+ ${numberWithCommas(bonus_earned)}`}
            <Typography component='span'>{` ${currency}`}</Typography>
          </Typography>
        </DialogContent>
      </DialogContainer>

      <DepositStepper
        data={{ id: parseFloat(id), type: 'pocket', currency, name: name }}
        open={openDeposit} onClose={() => setOpenDeposit(!openDeposit)} />

      <WithdrawalStepper
        data={{ id: parseFloat(id), type: 'pocket', currency, name: name, amount: pocket.amount_saved }}
        open={openWithdraw} onClose={() => setOpenWithdraw(!openWithdraw)} />

      <ModalWrapper open={isSettingsOpen} onClose={() => setIsSettingsOpen((prevState) => !prevState)}>
        <PocketSettings />
      </ModalWrapper>
      <UserTransfer open={openTransfer} onClose={() => setOpenTransfer(!openTransfer)} />
    </Toolbar>
  );
};

export default PocketHeader

interface FormValues {
  id: number;
  type: string;
  currency: string;
  name: string;
  amount?: number
}

interface DepositStepperProps {
  open: boolean;
  onClose: () => void;
  data: FormValues
}

const steps = ['Payment Details', 'Confirm', 'Success!'];

interface DepositFormValues {
  amount: string,
  method: string,
  phoneNumber: string,
  source: string | number;
  card: any
}



export const DepositStepper: React.FC<DepositStepperProps> = ({ open, onClose, data }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { depositMoney, error, loading } = useDeposit();
  const history = useHistory()
  const [formValues, setFormValues] = useState({ type: '', currency: '', name: '' });

  const { goalWalletTransfer, loading: walletGoalLoading, error: walletGoalError } = useGoalWalletTransfer();
  const { walletPocketTransfer, loading: walletPocketLoading, error: walletPocketError } = useWalletPocketTransfer();
  const { pocketGoalTransfer, loading: pocketGoalLoading, error: pocketGoalError } = usePocketGoalTransfer();
  const { goalToGoal, isLoading: goalLoading, error: goalError } = useGoalToGoalTransfer();
  const { pocketToPocket, isLoading: pocketLoading, error: pocketError } = usePocketToPocketTransfer();
  const { walletToWallet, isLoading: walletLoading, error: walletError } = useWalletToWalletTransfer();

  const handleReset = (form: FormApi<DepositFormValues, Partial<DepositFormValues>>) => {
    setActiveStep(0);
    form.reset();
    onClose()
  };


  const validate = (values: DepositFormValues) => {
    const errors: Partial<DepositFormValues> = {};
    if (activeStep === 0) {
      if (!values.amount) {
        errors.amount = "required"
      }

      if (!values.method) {
        errors.method = "required";
      }

      if (values.method === 'mobile') {
        if (!values.phoneNumber) {
          errors.phoneNumber = 'required'
        }
      }

      if (values.method === 'card') {
        if (!values.card) {
          errors.card = 'required'
        }
      }

      if (values.method === 'transfer') {
        if (!values.source) {
          errors.source = 'required'
        }
      }

      if (values.source && data.id) {
        if ((values.source === data.id) && (data.type === formValues.type)) {
          errors.source = 'Cant transfer to the same account'
        }
      }
    }

    return errors;
  };

  const handleNext = async (values: DepositFormValues) => {

    if (activeStep === steps.length - 2) {
      try {
        const fee = calculateTransferFee(data.currency, Number(values.amount))
        if (values.method === 'mobile') {
          /** Deposits made to groups are to the group wallets */
          const mobilePay = {
            amount: parseFloat(removeCommas(values.amount)),
            phoneNumber: values.phoneNumber,
            type: data.type === 'group' ? 'wallet' : data.type,
            id: data.id,
            currency: data.currency,
            url: history.location.pathname,
          };
          await depositMoney(mobilePay as MobilePayValues, values.method);
        } else if (values.method === 'card') {
          const cardPay = {
            amount: parseFloat(removeCommas(values.amount)),
            card: {
              id: values.card.id,
              token: values.card.card.token,
              country: values.card.card.country,
            },
            type: data.type === 'group' ? 'wallet' : data.type,
            id: data.id,
            currency: data.currency,
            url: history.location.pathname,
          };


          await depositMoney(cardPay as CardPay, values.method);


        } else if ((formValues.type === 'wallet' || formValues.type === 'group') && data.type === 'goal' && values.source) {

          // Handle wallet/group to goal transfer
          await goalWalletTransfer({ goalId: data.id as number, walletId: values.source as number, amount: parseFloat(removeCommas(values.amount)), currency: formValues.currency, fee }, 'walletToGoal');
        } else if ((formValues.type === 'wallet' || formValues.type === 'group') && data.type === 'wallet' && values.source) {
          // Handle wallet/group to wallet transfer
          await walletToWallet({ senderWalletId: values.source as number, recipientWalletId: data.id as number, amount: parseFloat(removeCommas(values.amount)), currency: formValues.currency, fee });
        } else if (formValues.type === 'goal' && (data.type === 'wallet' || data.type === 'group') && values.source) {
          // Handle goal to wallet/group transfer
          await goalWalletTransfer({ goalId: values.source as number, walletId: data.id as number, amount: parseFloat(removeCommas(values.amount)), currency: formValues.currency, fee }, 'goalToWallet');
        } else if (formValues.type === 'goal' && data.type === 'goal' && values.source) {
          // Handle goal to goal transfer
          await goalToGoal({ senderGoalId: values.source as number, recipientGoalId: data.id as number, amount: parseFloat(removeCommas(values.amount)), currency: formValues.currency, fee });
        } else if (formValues.type === 'pocket' && data.type === 'goal' && values.source) {
          // Handle pocket to goal transfer
          await pocketGoalTransfer({ pocketId: values.source as number, goalId: data.id as number, amount: parseFloat(removeCommas(values.amount)), currency: formValues.currency, fee }, 'pocketToGoal');
        } else if (formValues.type === 'goal' && data.type === 'pocket' && values.source) {
          // Handle goal to pocket transfer
          await pocketGoalTransfer({ pocketId: data.id as number, goalId: values.source as number, amount: parseFloat(removeCommas(values.amount)), currency: formValues.currency, fee }, 'goalToPocket');
        } else if ((formValues.type === 'wallet' || formValues.type === 'group') && data.type === 'pocket' && values.source) {
          // Handle wallet/group to pocket transfer
          await walletPocketTransfer({ pocketId: data.id as number, walletId: values.source as number, amount: parseFloat(removeCommas(values.amount)), currency: formValues.currency, fee }, 'walletToPocket');
        } else if (formValues.type === 'pocket' && (data.type === 'wallet' || data.type === 'group') && values.source) {
          // Handle pocket to wallet/group transfer
          await walletPocketTransfer({ pocketId: values.source as number, walletId: data.id as number, amount: parseFloat(removeCommas(values.amount)), currency: formValues.currency, fee }, 'pocketToWallet');
        } else if (formValues.type === 'pocket' && data.type === 'pocket' && values.source) {
          // Handle pocket to pocket transfer
          await pocketToPocket({ senderPocketId: values.source as number, recipientPocketId: data.id as number, amount: parseFloat(removeCommas(values.amount)), currency: formValues.currency, fee });
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.log(error)
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex' }}>
        <Typography sx={{ m: '0 auto', fontWeight: 600 }}>{steps[activeStep]}</Typography>
        <CloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent sx={{ padding: { lg: '28px 55px' } }}>
        <Form
          onSubmit={handleNext}
          validate={validate}
          initialValues={{
            method: 'mobile',
            amount: 0
          }}
          render={({ handleSubmit, values, form, submitting }) => (
            <form onSubmit={handleSubmit}>
              {activeStep === 0 && <PaymentMethodStep values={values} setFormValues={setFormValues} formValues={formValues} handleNext={handleNext} form={form} />}
              {activeStep === 1 && <Box>
                <Typography>
                  You are depositing {" "}
                  <Typography component='span' fontWeight={600}>{numberWithCommas(values.amount)}</Typography> <Typography component='span' variant='overline'>{data.currency}</Typography> from <Typography component={'span'} fontWeight={600}>
                    {values.method === 'mobile' ? values.phoneNumber : values.method === 'card' ? `**** **** **** ${values.card.card.last_4digits}` : values.method === 'wallet' ? 'wallet' : values.method === 'transfer' ? formValues.name : null}</Typography> to {data.name}.
                </Typography>
              </Box>}
              {activeStep === 2 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#22C55E', mt: 2, width: 60, height: 60 }}>
                  <DoneIcon fontSize='large' />
                </Avatar>
                <Typography variant='h6' sx={{ mt: 1, fontWeight: 600 }}>Payment Queued!</Typography>
                <Typography>Your transaction is being processed.Please wait</Typography>
              </Box>}

              {activeStep === steps.length - 2 && error && <Alert severity='error'>{(error as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletGoalError && <Alert severity="error">{(walletGoalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletPocketError && <Alert severity="error">{(walletPocketError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && goalError && <Alert severity="error">{(goalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && pocketGoalError && <Alert severity="error">{(pocketGoalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletError && <Alert severity="error">{(walletError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && pocketError && <Alert severity="error">{(pocketError as Error).message}</Alert>}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 3,
                }}
              >
                {activeStep === steps.length - 1 ? (
                  <Button title='Great' variant='contained' sx={{ width: '100%', mt: 2 }} onClick={() => handleReset(form)} />
                ) : (
                  <>
                    {activeStep !== 0 && (
                      <Button color="primary" onClick={handleBack} title='Back' sx={{ backgroundColor: '#F0EBF8', mr: 1.5, width: '120px' }} />
                    )}
                    <Button variant="contained" type="submit"
                      title={activeStep === steps.length - 2 ? 'Pay' : 'Next'}
                      loading={loading || submitting || walletGoalLoading || goalLoading || walletPocketLoading || walletLoading || pocketGoalLoading || pocketLoading}
                      sx={{ width: '120px' }}
                    />
                  </>
                )}
              </Box>
            </form>
          )}
        />
      </DialogContent>
    </ModalWrapper>
  );
}

interface WithdrawStepperProps {
  open: boolean;
  onClose: () => void;
  step?: number;
  data: FormValues

}

interface WithdrawFormValues {
  amount: string,
  method: string,
  phoneNumber: string,
  bank: PaymentMethod,
  destination: string | number;
  id: string | number,

}
export const WithdrawalStepper: React.FC<WithdrawStepperProps> = ({ open, onClose, step = 0, data }) => {
  const [activeStep, setActiveStep] = useState(step);
  const [destinationFormValues, setDestinationFormValues] = useState({ type: '', currency: data.currency, name: '', amount: data.amount });


  const { makeMobilePayoutFromWallet, makeMobilePayoutFromGoal, isLoading: mobileLoading, error: mobileError } = useMobilePayouts();
  const { makeBankPayoutFromWallet, makeBankPayoutFromGoal, isLoading: bankLoading, error: bankError } = useBankPayouts();
  const { goalWalletTransfer, loading: walletGoalLoading, error: walletGoalError } = useGoalWalletTransfer();
  const { walletPocketTransfer, loading: walletPocketLoading, error: walletPocketError } = useWalletPocketTransfer();
  const { pocketGoalTransfer, loading: pocketGoalLoading, error: pocketGoalError } = usePocketGoalTransfer();
  const { goalToGoal, isLoading: goalLoading, error: goalError } = useGoalToGoalTransfer();
  const { pocketToPocket, isLoading: pocketLoading, error: pocketError } = usePocketToPocketTransfer();
  const { walletToWallet, isLoading: walletLoading, error: walletError } = useWalletToWalletTransfer();


  const history = useHistory()

  const handleReset = (form: FormApi<WithdrawFormValues, Partial<WithdrawFormValues>>) => {
    setActiveStep(0);
    form.reset();
    onClose()

  };


  const validate = (values: WithdrawFormValues) => {
    const errors: Partial<WithdrawFormValues> = {};
    const withdrawLimit = 500000
    if (activeStep === 0) {
      if (!values.amount) {
        errors.amount = "required"
      }
      if (values.amount && parseFloat(removeCommas(values.amount)) > withdrawLimit && data.currency === 'UGX') {
        errors.amount = `Transaction limit is ${numberWithCommas(withdrawLimit)}`
      }

      if (!values.method) {
        errors.method = "required";
      }

      if (values.method === 'mobile') {
        if (!values.phoneNumber) {
          errors.phoneNumber = 'required'
        }
      }

      if (values.method === 'wallet') {
        if (!values.destination) {
          errors.destination = 'required'
        }
      }

      if (values.method === 'transfer') {
        if (!values.destination) {
          errors.destination = 'required'
        }
      }

      if (values.destination && data.id) {
        if ((values.destination === data.id) && (data.type === destinationFormValues.type)) {
          errors.destination = 'Cant transfer to the same account'
        }
      }

    }

    return errors;
  };

  const handleNext = async (values: WithdrawFormValues) => {
    const fee = calculateTransferFee(data.currency, Number(values.amount))
    const withdrawFee = calculateWithdrawalFee(Number(values.amount), data.currency)
    if (activeStep === steps.length - 2) {
      try {
        if (values.method === 'mobile') {
          let mobileWithdraw = {
            amount: Number((values.amount)),
            phoneNumber: values.phoneNumber,
            type: data.type === 'group' ? 'wallet' : data.type,
            id: data.id,
            currency: data.currency,
            url: history.location.pathname,
            fee: withdrawFee
          }

          if (data.type === 'wallet' || data.type === 'pocket') {
            await makeMobilePayoutFromWallet(mobileWithdraw as MobilePayValues)

          } else if (data.type === 'goal') {
            await makeMobilePayoutFromGoal(mobileWithdraw as MobilePayValues)
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);



        } else if (values.method === 'bank') {
          let bankWithdraw = {
            amount: parseFloat(removeCommas(values.amount)),
            bankAccount: {
              id: values.bank.id,
              code: values.bank.bank_acc.code,
              accountNumber: values.bank.acc_no as string,
            },
            type: data.type === 'group' ? 'wallet' : data.type,
            id: data.id,
            currency: data.currency,
            url: history.location.pathname,
            fee: withdrawFee
          }

          if (data.type === 'wallet') {
            await makeBankPayoutFromWallet(bankWithdraw)
          } else if (data.type === 'goal') {
            await makeBankPayoutFromGoal(bankWithdraw)
          }

        } else if (data.type === 'wallet' && destinationFormValues.type === 'goal' && values.destination) {
          // Handle wallet to goal transfer
          await goalWalletTransfer({ goalId: values.destination as number, walletId: data.id as number, amount: parseFloat(removeCommas(values.amount)), currency: data.currency, fee }, 'walletToGoal');
        } else if (destinationFormValues.type === 'wallet' && data.type === 'wallet' && values.destination) {
          // Handle  to wallet transfer
          await walletToWallet({ senderWalletId: data.id as number, recipientWalletId: values.destination as number, amount: parseFloat(removeCommas(values.amount)), currency: data.currency, fee });
        } else if (data.type === 'goal' && destinationFormValues.type === 'wallet' && values.destination) {
          // Handle goal to wallet/group transfer
          await goalWalletTransfer({ goalId: data.id as number, walletId: values.destination as number, amount: parseFloat(removeCommas(values.amount)), currency: data.currency, fee }, 'goalToWallet');
        } else if (destinationFormValues.type === 'goal' && data.type === 'goal' && values.destination) {
          // Handle goal to goal transfer
          await goalToGoal({ senderGoalId: data.id as number, recipientGoalId: values.destination as number, amount: parseFloat(removeCommas(values.amount)), currency: data.currency, fee });
        } else if (destinationFormValues.type === 'goal' && data.type === 'pocket' && values.destination) {
          // Handle pocket to goal transfer
          await pocketGoalTransfer({ pocketId: data.id as number, goalId: values.destination as number, amount: parseFloat(removeCommas(values.amount)), currency: data.currency, fee }, 'pocketToGoal');
        } else if (destinationFormValues.type === 'pocket' && data.type === 'goal' && values.destination) {
          // Handle goal to pocket transfer
          await pocketGoalTransfer({ pocketId: values.destination as number, goalId: data.id as number, amount: parseFloat(removeCommas(values.amount)), currency: data.currency, fee }, 'goalToPocket');
        } else if (destinationFormValues.type === 'pocket' && data.type === 'wallet' && values.destination) {
          // Handle wallet/group to pocket transfer
          await walletPocketTransfer({ pocketId: values.destination as number, walletId: data.id as number, amount: parseFloat(removeCommas(values.amount)), currency: data.currency, fee }, 'walletToPocket');
        } else if (data.type === 'pocket' && destinationFormValues.type === 'wallet' && values.destination) {
          // Handle pocket to wallet/group transfer
          await walletPocketTransfer({ pocketId: data.id as number, walletId: values.destination as number, amount: parseFloat(removeCommas(values.amount)), currency: data.currency, fee }, 'pocketToWallet');
        } else if (data.type === 'pocket' && destinationFormValues.type === 'pocket' && values.destination) {
          // Handle pocket to pocket transfer
          await pocketToPocket({ senderPocketId: data.id as number, recipientPocketId: values.destination as number, amount: parseFloat(removeCommas(values.amount)), currency: data.currency, fee });
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      } catch (error) {
        console.log(error)
      };

    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex' }}>
        <Typography sx={{ m: '0 auto', fontWeight: 600 }}>{steps[activeStep]}</Typography>
        <CloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent sx={{ padding: { lg: '28px 55px' } }}>
        <Form
          onSubmit={handleNext}
          validate={validate}
          initialValues={{
            method: 'mobile',
            amount: 0
          }}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              {activeStep === 0 && <WithdrawDetailsStep
                values={values} formValues={destinationFormValues} setFormValues={setDestinationFormValues} handleNext={handleNext} form={form} />}
              {activeStep === 1 && <Box>
                <Box sx={{ my: 2 }}>
                  <Typography fontWeight={600}>Transaction details</Typography>
                  <Typography>Amount: {data.currency}{numberWithCommas(values.amount)} </Typography>
                  <Typography>Fee: {data.currency} {numberWithCommas(calculateWithdrawalFee(Number(values.amount), data.currency))} </Typography>
                </Box>

                <Typography>
                  You are withdrawing {" "}
                  <Typography component='span' fontWeight={600}>{numberWithCommas(values.amount)}</Typography> <Typography component='span' variant='overline'>{data.currency}</Typography> to <Typography component={'span'} fontWeight={600}>
                    {values.method === 'mobile' ? values.phoneNumber : values.method === 'bank' ? `${values.bank.bank_acc.name} -- ${values.bank.acc_no}` : values.method === 'wallet' ? 'wallet' : values.method === 'transfer' ? destinationFormValues.name : null}</Typography> from {data.name}.
                </Typography>
              </Box>}
              {activeStep === 2 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#22C55E', mt: 2, width: 60, height: 60 }}>
                  <DoneIcon fontSize='large' />
                </Avatar>
                <Typography variant='h6' sx={{ mt: 1, fontWeight: 600 }}>Payment Queued!</Typography>
                <Typography>Your transaction is being processed.Please wait.</Typography>
              </Box>}

              {activeStep === steps.length - 2 && mobileError && <Alert severity='error'>{(mobileError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && bankError && <Alert severity='error'>{(bankError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletGoalError && <Alert severity="error">{(walletGoalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletPocketError && <Alert severity="error">{(walletPocketError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && goalError && <Alert severity="error">{(goalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && pocketGoalError && <Alert severity="error">{(pocketGoalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletError && <Alert severity="error">{(walletError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && pocketError && <Alert severity="error">{(pocketError as Error).message}</Alert>}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 3,
                }}
              >
                {activeStep === steps.length - 1 ? (
                  <Button title='Great' variant='contained' sx={{ width: '100%', mt: 2 }} onClick={() => handleReset(form)} />
                ) : (
                  <>
                    {activeStep !== 0 && (
                      <Button color="primary" onClick={handleBack} title='Back' sx={{ backgroundColor: '#F0EBF8', mr: 1.5, width: '120px' }} />
                    )}
                    <Button variant="contained" type="submit"
                      loading={mobileLoading || bankLoading || walletGoalLoading || goalLoading || walletPocketLoading || walletLoading || pocketGoalLoading || pocketLoading}
                      disabled={mobileLoading || bankLoading}
                      title={activeStep === steps.length - 2 ? 'Withdraw' : 'Next'}
                      sx={{ width: '120px' }}
                    />
                  </>
                )}
              </Box>
            </form>
          )}
        />
      </DialogContent>
    </ModalWrapper>
  );
}



