import { createSlice } from '@reduxjs/toolkit';

export interface GoalState {
  name: string;
  currency: string;
  targetAmount: string;
  image: File | null;
  type: string;
  visibility: string;
  durationOption: number | string;
  customDuration: number | null;
}

const initialState: GoalState = {
  name: '',
  currency: 'UGX',
  targetAmount: '',
  image: null,
  type: 'main',
  visibility:'private',
  durationOption: 3,
  customDuration:  null
};


const goalSlice = createSlice({
  name: 'goal',
  initialState,
  reducers: {
    setGoalData: (state, action) => {
     Object.assign(state, action.payload);
    },
    resetGoal: (state) => {
     Object.assign(state, initialState);
    },
  },
});

export const { setGoalData,resetGoal } = goalSlice.actions;
export default goalSlice.reducer;

