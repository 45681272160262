import { Theme } from "@mui/material";
import { orange } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme:Theme) => ({
  container: {
    margin: "32px 16px 16px 16px",
    minHeight: "150px",
  },
  transactionList: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  transactionGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  transactionContainer: {
    gap:"0.25rem", 
    padding:"0.1875rem 0rem",
    display: "flex",
    flexDirection:'column',
    borderBottom: `0.5px solid  #F6F6F7`,
    cursor: "pointer",
    background: "#fff",
    marginBottom: "0.5rem",
  },
  transactionListSection: {
    marginTop: "16px",
    "& h6": {
      color: theme.palette.grey[900],
    },
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      "& h6": {
        fontSize: 16,
      },
    },
  },
  success: {
    color: theme.palette.success.main,
  },
  failure: {
    color: theme.palette.error.main,
  },
  pending: {
    color: orange[800],
  },
  statusChip: {
    display: "none",
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      display: "block",
    },
  },
  arrowIcon: {
    display: "block",
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      display: "none",
    },
  },
  successChip: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    padding: "0px 8px",
    borderRadius: "16px",
  },
  errorChip: {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.light,
    padding: "0px 8px",
    borderRadius: "16px",
  },
  pendingChip: {
    color: orange[800],
    backgroundColor: "#ffe4c7",
    padding: "0px 8px",
    borderRadius: "16px",
  },
  transactionName: {
    fontSize: "16px",
    color: theme.palette.grey[500],
  },
  transactionMsg: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
    fontSize: "16px",
    "& p": {
      fontSize: "16px",
      fontWeight: 700,
    },
  },
 
 
}));
