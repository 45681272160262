import React, { useState, useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import { Box, capitalize, Chip, IconButton, List, ListItem, Slide, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import TransactionList from "../../components/home/TransactionList";
import Filter from "../../components/transactions/Filter";
import { useInfiniteTransactions, useTransactionAnalytics } from "../../hooks/transactionHooks";
import BarChartIcon from '@mui/icons-material/BarChart';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { statusOptions, transactionCategoryTypes } from "../../components/Reusables/Input/select/Options";
import { StyledTab, StyledTabs } from "../goals/GoalTabs";
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from "react-router-dom";
import PieChartComponent from "../../components/transactions/PieChartComponent";
import { useTransactionSelection } from "../../hooks/customHooks";
import { numberWithCommas } from "../../utils/middleware";
import Button from "../../components/Reusables/Input/Button";
import { useStyles } from "./TransactionStyles";
import FormInput from "../../components/Reusables/Input/FormInput";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SearchIcon from '@mui/icons-material/Search';
import Modal from "../../components/Modals/Modal";
import NotificationList from "../../components/profile/NotificationList";
import { useNotifications } from "../../hooks/notificationHooks";
import { Transaction, TransactionData } from "../../types/types";
import Fuse from 'fuse.js'
import { UseInfiniteQueryResult } from "@tanstack/react-query";


const Transactions = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openPie, setOpenPie] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const { data: notificationsData } = useNotifications();


  const [value, setValue] = useState(0);
  const [selectMode, setSelectMode] = useState(false)
  const history = useHistory()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { selectedTransactions, handleTransactionSelect, selectTransactions } = useTransactionSelection();
  const [searchResults, setSearchResults] = useState<UseInfiniteQueryResult<TransactionData, Error>>();




  /**handle filter terms */
  const [filter, setFilter] = useState({
    category: 'all',
    status: 'all',
  });

  const transactions = useInfiniteTransactions(filter, searchTerm.length > 0);




  const transactionsData: readonly any[] = transactions.data ? transactions.data?.pages.flatMap(page => page.transactions) : [];

  const fuse = useMemo(
    () =>
      new Fuse(transactionsData, {
        keys: ['name', 'from', 'to', 'payment_method_name', 'currency', 'type'],
      }),
    [transactionsData]
  );

  const updateSearchResults = (): void => {
    if (searchTerm) {
      const searchResults = fuse.search(searchTerm);
      const pages = [{ transactions: searchResults.map((result: any) => result.item as Transaction) }] as unknown as TransactionData;

      const updatedSearchResults = {
        data: { pages },
        error: null, // Set this based on your error handling logic
        hasNextPage: false, // Set this based on whether there are more pages
      };

      setSearchResults(updatedSearchResults as unknown as UseInfiniteQueryResult<TransactionData, Error>);
    }
  };





  const handleSelectAll = () => {
    if (transactions.data) {
      selectTransactions(transactions.data?.pages.flatMap(page => page.transactions))
      history.push(`/transactions/preview`)
    }
  }

  const handleDownload = () => {
    if (transactions.data && transactions.data.pages[0].transactions.length > 0) { // Check if transactions.data is defined
      const firstTransactions = transactions.data.pages[0].transactions
      selectTransactions(firstTransactions.slice(0, 5));
    }
    setSelectMode(true)
  }

  const handlePreview = () => {
    if (selectedTransactions.length > 0) {
      history.push(`/transactions/preview`)
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {

    setValue(newValue);
    setFilter({ ...filter, category: transactionCategoryTypes[newValue].value })


  };

  const classes = useStyles()

  return (
    <motion.div
      initial={{ opacity: 0.8 }}
      animate={{ opacity: 1 }}
      style={{
        minHeight: "100vh",
      }}
    >
      <Box >
        <Box className={classes.header}>
          {selectMode === true && !isDesktop ?
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Chip icon={<CloseIcon fontSize="small" />} label={selectedTransactions.length} sx={{ backgroundColor: '#C2B0E2', fontSize: 16 }} onClick={() => setSelectMode(false)} />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography fontWeight={600} onClick={handlePreview} sx={{ pointer: 'cursor' }}>Download</Typography>
                  <Typography fontWeight={600} color='primary' onClick={handleSelectAll} sx={{ pointer: 'cursor' }}>Select all</Typography>
                </Box>
              </Box>
            </Box>
            : (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant='h6' fontWeight={600}>Transactions</Typography>
                <Box sx={{ display: { sx: 'flex', lg: 'none' }, alignItems: 'center', gap: 1.2 }}>
                  <BarChartIcon onClick={() => setOpenPie(!openPie)} />
                  <FilterAltIcon onClick={() => setOpenDialog(!openDialog)} />
                  <FileDownloadIcon onClick={handleDownload} />
                </Box>
                {isDesktop && (
                  <>
                    <FormInput sx={{ backgroundColor: '#fff', borderRadius: 2 }} Icon={<SearchIcon />} placeholder='Search transactions' value={searchTerm} onChange={(e) => {
                      setSearchTerm(e.target.value);
                      updateSearchResults();
                    }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <IconButton color="inherit" onClick={() => setOpenNotifications(!openNotifications)} >
                        <NotificationsActiveIcon />
                      </IconButton>
                      <Typography variant='subtitle2' sx={{ color: '#F0EBF8', fontWeight: 600 }}>Notifications</Typography>
                    </Box>
                  </>
                )}

              </Box>
            )}

          <Box sx={{ borderBottom: 1, borderColor: 'transparent', mt: 2 }}>
            <StyledTabs value={value} onChange={handleChange} aria-label="transactions tabs" className={classes.transactionTabs} >
              {transactionCategoryTypes.map((filter, index) => (
                <StyledTab label={filter.label} key={index} className={classes.transactionTab} />
              ))}
            </StyledTabs>
          </Box>
        </Box>
        <Box className={classes.transactionSection}>
          <Box sx={{ p: { lg: 2 } }}>
            <TransactionAnalyticsComponent direction={isDesktop ? 'left' : 'down'} isVisible={openPie} onClose={() => setOpenPie(false)} />
          </Box>
          <Box sx={{ flexGrow: 0.9, m: { lg: '0 auto' } }}>
            {selectMode && isDesktop &&
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'flex-end', mt: 1 }}>
                <Chip icon={<CloseIcon fontSize="small" />} label={selectedTransactions.length} sx={{ backgroundColor: '#C2B0E2', fontSize: 16 }} onClick={() => setSelectMode(false)} />
                <Typography fontWeight={600} onClick={handlePreview} sx={{ pointer: 'cursor' }}>Download</Typography>
                <Typography fontWeight={600} color='primary' onClick={handleSelectAll} sx={{ pointer: 'cursor' }}>Select all</Typography>
              </Box>
            }
            <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end', mt: 2, mr: 1, display: { xs: 'none', lg: 'flex' } }} >
              <Chip icon={<BarChartIcon />} label="Metrics" variant="outlined" onClick={() => setOpenPie(!openPie)} />
              <Chip icon={<FilterAltIcon />} label="Filter" variant="outlined" onClick={() => setOpenDialog(!openDialog)} />
              <Chip icon={<FileDownloadIcon />} label="Download" variant="outlined" onClick={handleDownload} />
            </Stack>
            <Stack direction="row" spacing={1} className={classes.statusSection}>
              {statusOptions.map((status, index) => (
                <Chip
                  key={index}
                  label={status.label}
                  onClick={() => setFilter({ ...filter, status: status.value })}
                  sx={{
                    borderRadius: 1,
                    backgroundColor: status.value === filter.status ? '#C2B0E2' : 'inherit'
                  }}
                />
              ))}
            </Stack>

            <Box className={classes.transactionContent}>
              <TransactionList
                transactions={searchResults && searchTerm.length > 0 ? searchResults : transactions}
                selectMode={selectMode}
                selectedTransactions={selectedTransactions}
                onSelect={handleTransactionSelect}
              />
              {transactions.hasNextPage && !searchResults && searchTerm.length === 0 && (
                <Button
                  onClick={() => transactions.fetchNextPage()}
                  title={transactions.isFetchingNextPage ? 'Loading more...' : 'Load more'}
                  disabled={transactions.isFetchingNextPage}
                />)}
            </Box>
          </Box>
        </Box>
        <Filter open={openDialog} handleClose={() => setOpenDialog(false)} initialValues={filter} onUpdateValues={setFilter} />
        <Modal open={openNotifications} handleClose={() => setOpenNotifications(!openNotifications)} title='Notifications'>
          <NotificationList notifications={notificationsData} />
        </Modal>
      </Box>
    </motion.div>
  );
};



export default Transactions;


interface TransactionAnalyticsProps {
  isVisible: boolean;
  onClose: () => void;
  direction: "left" | "right" | "up" | "down" | undefined;
}
const TransactionAnalyticsComponent: React.FC<TransactionAnalyticsProps> = ({ isVisible, onClose, direction }) => {
  const { data, isLoading, isError } = useTransactionAnalytics();


  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography>Error loading analytics data</Typography>;
  }

  // Calculate the total sum of amounts for all items
  const totalSum = data.reduce((sum: number, item: { amount: number; }) => sum + item.amount, 0);

  // Calculate the percentage for each item
  const analyticsWithPercentage = data.map((item: { amount: number; }) => ({
    ...item,
    percentage: Math.floor((item.amount / totalSum) * 100),
  }));


  return (
    <Slide direction={direction} in={isVisible} mountOnEnter unmountOnExit>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="body1">Overview</Typography>
          <CloseIcon onClick={onClose} />
        </Box>
        {data.length === 0 ?
          <Typography variant='subtitle2' color='textSecondary' sx={{ p: 1 }}>No data to display</Typography>
          : <PieChartComponent data={data} />
        }

        <List>
          {
            analyticsWithPercentage.map((item: { name: string; percentage: number; currency: string, amount: number }) => (
              <ListItem sx={{ gap: 1, alignItems: 'baseline' }} key={item.name}>
                <Typography>{capitalize(item.name)}</Typography>
                <Typography>{item.percentage}%</Typography>
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
                  <Typography fontWeight={600}>{numberWithCommas(item.amount)}</Typography>
                  <Typography variant='overline'>{item.currency}</Typography>
                </Box>
              </ListItem>
            ))
          }
        </List>
      </Box>
    </Slide>
  );
}

