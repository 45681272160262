import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FieldRenderProps } from 'react-final-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface DateInputProps extends FieldRenderProps<Date, HTMLElement> {
  label: string;
  fullWidth?: boolean;
}

const DateInput: React.FC<DateInputProps> = ({ label, fullWidth = true,input, ...rest }) => {
  return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            value={input.value}
            onChange={input.onChange}
           {...rest}
           slotProps={{
            textField: {
              sx: {
                width:"100%",
                padding: 0,
                margin: 0,
                borderRadius: "8px",
              }
            }
          }} 
          />
        </LocalizationProvider>
  );
};

export default DateInput;
