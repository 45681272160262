import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import GoalMembers from './GoalMembers';
import { GoalInfo } from './GoalInfo';
import { styled } from '@mui/material/styles';
import { Box, useMediaQuery, Theme } from '@mui/material';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TransactionList from '../../components/home/TransactionList';
import Button from '../../components/Reusables/Input/Button';
import { useGoalTransactions } from '../../hooks/goalHooks';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SavingFilter } from '../../components/goals/SavingFilter';
import { transactionFilters } from '../../components/Reusables/Input/select/Options';
import GoalTransactionsPage from './GoalTransactions';
import ModalWrapper from '../../components/Modals/ModalWrapper';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  className?: any;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    variant="scrollable"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  minHeight: 'unset',
  padding: '4px 0px',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 0,
  },
  '& .MuiTabs-scroller .MuiTabs-flexContainer': {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'between',
      gap: "1rem"
    }

  }
}));

interface StyledTabProps {
  label: string;
  className?: any;
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(16),
  marginRight: theme.spacing(1),
  padding: theme.spacing(0.5),
  height: theme.typography.pxToRem(32),
  borderRadius: theme.spacing(0.5),
  minHeight: 'unset',
  minWidth: '60px',
  color: theme.palette.grey[600],
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));


export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', justifyContent: { lg: 'center' } }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="goal tabs">
          <StyledTab label="Activity" {...a11yProps(0)} />
          <StyledTab label="Members" {...a11yProps(1)} />
          <StyledTab label="Info"  {...a11yProps(2)} />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <GoalTransactions />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <GoalMembers />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <GoalInfo />
      </CustomTabPanel>
    </Box>
  );
}

const GoalTransactions = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [isTransactionsOpen, setIsTransactionsOpen] = useState(false);

  const [filter, setFilter] = useState('all')
  const transactions = useGoalTransactions({ type: filter, limit: 3 }, false, parseFloat(id));

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <Box sx={{ my: 1 }}>
          <SavingFilter filters={transactionFilters} handleFilterChange={(filter: string) => setFilter(filter)} selected={filter} />
        </Box>
        <Button variant="text" title="See all" onClick={() => {
          if(isDesktop) {
            setIsTransactionsOpen((prevState) => !prevState) 
          } else {
            history.push(`/goals/transactions/${id}`)
          }
        }} sx={{ color: 'black', fontSize: "0.875rem" }}>
          <ArrowForwardIcon fontSize='small' />
        </Button>

      </Box>
      <TransactionList transactions={transactions} />
      <Box sx={{ textAlign: 'center' }}>
        {transactions.hasNextPage && (
          <Button
            onClick={() => transactions.fetchNextPage()}
            title={transactions.isFetchingNextPage ? 'Loading more...' : 'Load more'}
            disabled={transactions.isFetchingNextPage}
          />
        )}
      </Box>
      <ModalWrapper open={isTransactionsOpen} onClose={() => setIsTransactionsOpen((prevState) => !prevState)} >
        <GoalTransactionsPage />
      </ModalWrapper>
    </Box>
  );
};