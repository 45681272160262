import React, { useState } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Box, Chip, Typography, DialogTitle, DialogContent, DialogActions, Alert, useMediaQuery, Theme } from '@mui/material';
import { GoalItem as Goal,Subscription } from '../../types/types';
import Button from '../../components/Reusables/Input/Button';
import { Field, Form } from 'react-final-form';
import { autoTransferCustomOptions, autoTransferfrequencyOptions, autoTransferOptions, sourceOptions } from '../../components/Reusables/Input/select/Options';
import RadioButtons from '../../components/Reusables/Input/RadioGroup';
import { DialogContainer } from '../../components/Reusables/Feedback/InfoDialog';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FormInput from '../../components/Reusables/Input/FormInput';
import Select from '../../components/Reusables/Input/select/Select';
import { useGoals } from '../../hooks/goalHooks';
import { numberWithCommas, removeCommas } from '../../utils/middleware';
import { FormApi } from 'final-form';
import { useDeleteSubscription, useUpdateSubscription } from '../../hooks/subscriptionHooks';
import {useGetUserWallet } from '../../hooks/walletHooks';
import { Edit } from '@mui/icons-material';
import Toast from '../../components/Reusables/Feedback/Toast';
import ModalWrapper from '../../components/Modals/ModalWrapper';

interface SubscriptionListProps {
  subscriptions: Subscription[];
  createSubscription: (data: any) => void;
}



const SubscriptionList: React.FC<SubscriptionListProps> = ({ subscriptions, createSubscription }) => {
  const [open, setOpen] = useState(false);
  const [editSubscription, setEditSubscription] = useState(false);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const { deleteSubscription } = useDeleteSubscription();

  const handleDelete = async (subscriptionId: number) => {
    try {
      await deleteSubscription(subscriptionId);
      // Handle success, e.g., redirect or show success message
    } catch (error) {
      // Handle error, e.g., show error message
      console.log(error)
    }
  };

  const addSubscription = (data: any) => {
    createSubscription(data)
  }

  return (
    <>
      <List sx={{ pt: 0 }}>
        <ListItem disableGutters sx={{ p: 0 }}>
          <ListItemText primary={<Typography variant="body2" fontWeight={600}>Auto-transfer details</Typography>} />

        </ListItem>
        {subscriptions.map((subscription) => (
          <ListItem key={subscription.id} disableGutters sx={{ py: 0 }}>
            <ListItemText primary={<Typography variant="body2" fontWeight={600}>{`Frequency`}</Typography>}
              secondary={<Typography variant="body2" fontWeight={600}>{`${subscription.frequency}`}</Typography>} />
            {!subscription.percentage ? (
              <ListItemText primary={<Typography variant="body2" fontWeight={600}>{`Amount`}</Typography>}
                secondary={<Typography variant="body2" fontWeight={600}>{`${numberWithCommas(subscription.amount)}  ${subscription.currency}`}</Typography>} />
            ) : (
              <ListItemText primary={<Typography variant="body2" fontWeight={600}>{`Amount`}</Typography>}
                secondary={<Typography variant="body2" fontWeight={600}>{`${subscription.amount} %`}</Typography>} />
            )}

            {subscription.type === 'deposit' ? (
              <ListItemText primary={<Typography variant="body2" fontWeight={600}>{`Source`}</Typography>}
                secondary={<Typography variant="body2" fontWeight={600}>{`${subscription.source_type}`}</Typography>} />
            ) : (
              <ListItemText primary={<Typography variant="body2" fontWeight={600}>{`Destination`}</Typography>}
                secondary={<Typography variant="body2" fontWeight={600}>{`${subscription.destination_type}`}</Typography>} />
            )}
            <SubscriptionEditDialog open={editSubscription} onClose={() => setEditSubscription(false)} subscription={subscription} />

            <ListItemSecondaryAction sx={{ ml: 2 }}>
              <IconButton edge="end" aria-label="edit" onClick={() => setEditSubscription(!editSubscription)} >
                <Edit />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(subscription.id)}>
                <DeleteOutlineOutlinedIcon color='error' />
              </IconButton>
            </ListItemSecondaryAction>

          </ListItem>
        ))}
      </List>
      <Box sx={{ textAlign: 'center' }}>
        <Button title='Add new auto transfer' onClick={() => setOpen(!open)}
          sx={{ backgroundColor: '#F0EBF8' }} />
      </Box>
      <DialogContainer open={open && !isDesktop} onClose={() => setOpen(false)} >
        <NewAutoTransferStepper open={open} onClose={() => setOpen(false)} onAddNewTransfer={addSubscription} />
      </DialogContainer>
      <ModalWrapper open={open && isDesktop} onClose={() => setOpen(false)} >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <NewAutoTransferStepper open={open} onClose={() => setOpen(false)} onAddNewTransfer={addSubscription} />
        </Box>
      </ModalWrapper>


    </>
  );
};

export default SubscriptionList;




const steps = ['Add auto-transfer (can select more than one)', 'Select Frequency', 'Enter auto-transfer-amount', 'Payment details', 'Payment details', 'Confirm'];

export interface FormValues {
  type: string;
  frequency: string;
  customFrequency_number: number | string;
  customFrequency_string: string;
  amount: string;
  percentage: boolean;
  source: string;
  source_type: string;
  destination_type: string;
  destination: string;
  goal: number | null;
  group: number | null;
  group_category: string;
  account: number | null;
  category: string;
  id: number;
}

interface NewAutoTransferStepperProps {
  onAddNewTransfer: (data: FormValues) => void;
  open: boolean,
  onClose: () => void;
}



const NewAutoTransferStepper: React.FC<NewAutoTransferStepperProps> = ({ onAddNewTransfer, open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { data: wallet } = useGetUserWallet()
  const { data: goals, isLoading, isError } = useGoals();


  const goalOptions = isLoading || isError ? [] : goals.map((goal: Goal) => ({
    value: goal.id,
    label: goal.name,
  }));


  const handleNext = async (values: FormValues, form: FormApi<FormValues, Partial<FormValues>>) => {
    try {
      if (activeStep === 5) {
        let id: number | undefined;
        let type: string | undefined;

        if (values.source === 'goal' || values.destination === 'goal') {
          id = values.goal as number;
          type = 'goal';
        } else if ((values.source === 'wallet' || values.destination === 'wallet') && wallet) {
          id = wallet.id;
          type = 'wallet';
        }

        if (type && id) {
          onAddNewTransfer({
            ...values,
            amount: removeCommas(values.amount),
            category: type,
            id: id,
          });
          onClose()
          form.reset();
        }
      } else if (activeStep === 3) {
        if (values.source === 'wallet' || values.destination === 'wallet') {
          // Proceed to the next step (step 5) if the transfer involves a wallet
          setActiveStep(5);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else if (activeStep < steps.length) {
        console.log(`proceed to ${activeStep + 1}`)
        // Proceed to the next step
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };




  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (activeStep === 0) {
      if (!values.type) {
        errors.type = "Required";
      }

    }
    if (activeStep === 1) {
      if (!values.frequency) {
        errors.frequency = 'Required'
      }
      if (values.frequency === 'custom') {
        if (!values.customFrequency_number) {
          errors.customFrequency_number = 'Required'
        }
        if (!values.customFrequency_string) {
          errors.customFrequency_string = 'Required'
        }
      }
    }

    if (activeStep === 2) {
      if (!values.amount) {
        errors.amount = "Required";
      }
      if (values.percentage && values.percentage === true) {
        if (parseFloat(values.amount) > 100) {
          errors.amount = 'Invalid input'
        }
      }
    }

    if (activeStep === 3) {
      if (!values.source || !values.destination) {
        values.source = 'required'
        values.destination = 'required'
      }
    }
    return errors;
  };

  return (
    <Box>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{steps[activeStep]}</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: { lg: '28px 55px' } }}>
        <Form
          onSubmit={handleNext}
          validate={validate}
          initialValues={{ group: null, percentage: false }}>
          {({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              {activeStep === 0 &&
                <Box>
                  <Field name="type" >
                    {(props) => (
                      <RadioButtons
                        options={autoTransferOptions}
                        {...props}
                        row={false}
                      />)}
                  </Field>
                </Box>}
              {activeStep === 1 && (
                <Box>
                  <Field name="frequency">
                    {(props) => (
                      <RadioButtons options={autoTransferfrequencyOptions}
                        {...props} row={false} />
                    )}
                  </Field>

                  {values.frequency === 'custom' && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.4 }}>
                      <Field name="customFrequency_number">
                        {(props) => (
                          <FormInput
                            {...props}
                            type="number"
                            placeholder="Custom frequency"
                            fullWidth
                            InputProps={{
                              startAdornment: 'Every',
                            }}
                            sx={{ flex: 0.6, mb: 0.5 }}
                          />
                        )}
                      </Field>
                      <Field name="customFrequency_string">
                        {(props) => (
                          <Select
                            options={autoTransferCustomOptions}
                            {...props}
                          />
                        )}
                      </Field>
                    </Box>
                  )}
                </Box>)}
              {activeStep === 2 && <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Field name="amount" type="text" >
                  {(props) => (
                    <FormInput
                      value={values.percentage === true ? props.input.value : numberWithCommas(props.input.value)}
                      fullWidth
                      {...props}

                    />
                  )}
                </Field>
                <Field name="percentage" >
                  {({ input }) => (
                    <Chip
                      {...input}
                      color="default"
                      label={input.value === false ? 'Switch to percentage' : 'Switch to amount'}
                      onClick={() => {
                        input.onChange(!input.value)
                      }}
                      sx={{ mt: 1, m: '0 auto' }}
                    />
                  )}
                </Field>
              </Box>}


              {activeStep === 3 && (
                <Box>
                  {values.type === 'deposit' ? <>
                    <Typography>{`Choose source FROM which the auto-deposit of ${numberWithCommas(values.amount)} ${values.frequency} will be taken`}</Typography>
                    <Field name='source'>
                      {(props) => (
                        <RadioButtons options={sourceOptions} {...props} row={false} />
                      )}</Field>
                  </> : values.type === 'withdraw' ?
                    <>
                      <Typography>{`Choose destination TO which the auto-deposit of ${numberWithCommas(values.amount)} ${values.frequency} will be taken`}</Typography>
                      <Field name='destination'>
                        {(props) => (
                          <RadioButtons options={sourceOptions} {...props} row={false} />
                        )}</Field>
                    </> : null
                  }

                </Box>
              )}
              {activeStep === 4 && (
                <Box>
                  {values.source === 'goal' || values.destination === 'goal' ? (
                    <Box>
                      {/* Render the step for choosing the goal */}
                      <Typography fontWeight={600} sx={{ mb: 1, textAlign: 'center' }}>Select goal destination:</Typography>

                      <Field name="goal" component={Select} options={goalOptions} />
                    </Box>
                  ) : null}
                </Box>
              )}
              {activeStep === 5 && (
                <Box>
                  <Alert>
                    {`You are setting an automatic ${values.type} of ${numberWithCommas(values.amount)} ${values.type === 'deposit' ? 'from' : 'to'} ${values.source === 'goal' || values.destination === 'goal'
                        ? 'goal'
                        : values.source === 'wallet' || values.destination === 'wallet'
                          ? 'wallet'
                          : values.source === 'pocket' || values.destination === 'pocket'
                            ? 'pocket'
                            : 'group'
                      } which will occur ${values.frequency === 'custom'
                        ? `every ${values.customFrequency_number} ${values.customFrequency_string}`
                        : values.frequency
                      }`}
                  </Alert>
                </Box>
              )}

              <Box>
                {activeStep === steps.length - 1 ? (
                  <Box sx={{ float: 'right' }}>
                    <Button disabled={activeStep === 0} onClick={handleBack} title='Back' sx={{ width: '120px' }} />
                    <Button variant="contained" color="primary" title='Create' type='submit' sx={{ width: '120px' }} />
                  </Box>
                ) : activeStep < steps.length - 1 ? (
                  <Box sx={{ float: 'right' }}>
                    <Button disabled={activeStep === 0} onClick={handleBack} title='Back' sx={{ width: '120px' }} />
                    <Button variant="contained" color="primary" type='submit' title='Next' onClick={handleSubmit} sx={{ width: '120px' }} />
                  </Box>
                ) : null}
              </Box>
            </form>
          )}
        </Form>
      </DialogContent>
    </Box>
  );
}




interface SubscriptionEditDialogProps {
  open: boolean;
  onClose: () => void;
  subscription: Subscription;
}

export interface EditFormValues {
  frequency: string;
  customFrequency_number: number | string;
  customFrequency_string: string;
  amount: string;
  percentage: boolean;

}

const SubscriptionEditDialog: React.FC<SubscriptionEditDialogProps> = ({
  open,
  onClose,
  subscription,
}) => {
  const { isLoading, error, updateSubscription } = useUpdateSubscription();
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.frequency) {
      errors.frequency = 'Required'
    }
    if (values.frequency === 'custom') {
      if (!values.customFrequency_number) {
        errors.customFrequency_number = 'Required'
      }
      if (!values.customFrequency_string) {
        errors.customFrequency_string = 'Required'
      }
    }


    if (!values.amount) {
      errors.amount = "Required";
    }

    if (values.percentage) {
      if (parseFloat(values.amount) > 100) {
        errors.amount = 'Invalid input'
      }
    }

    return errors;
  };
  const onSubmit = async (values: EditFormValues) => {
    try {
      await updateSubscription({
        id: subscription.id,
        data: {
          amount: values.percentage ? values.amount : parseFloat(removeCommas(values.amount)),
          frequency: values.frequency,
          custom_frequency: `${values.customFrequency_number} ${values.customFrequency_string}`,
          percentage: values.percentage
        },
      });
      setUpdateToast({
        open: true,
        message: "Subscription updated successfully",
        type: "success",
      });

    } catch (error) {

    }
  }

  return (
    <DialogContainer open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 600 }}>Edit Subscription</DialogTitle>
      <DialogContent>
        <Form
          initialValues={{
            amount: subscription.amount,
            percentage: subscription.percentage,
            frequency: subscription.frequency.split(" ").length === 1 ? subscription.frequency : 'custom',
            customFrequency_number: subscription.frequency.split(" ")[0],
            customFrequency_string: subscription.frequency.split(" ")[1]
          }}
          validate={validate}
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                  <Field name="amount" type="text" >
                    {(props) => (
                      <FormInput
                        value={values.percentage === true ? props.input.value : numberWithCommas(props.input.value)}
                        fullWidth
                        {...props}

                      />
                    )}
                  </Field>
                  <Field name="percentage" >
                    {({ input }) => (
                      <Chip
                        {...input}
                        color="default"
                        label={input.value === false ? 'Switch to percentage' : 'Switch to amount'}
                        onClick={() => {
                          input.onChange(!input.value)
                        }}
                        sx={{ mt: 1, m: '0 auto' }}
                      />
                    )}
                  </Field>
                </Box>

                <Box>
                  <Typography fontWeight={600} sx={{ my: 1 }}>Frequency</Typography>
                  <Field name="frequency">
                    {(props) => (
                      <Select options={autoTransferfrequencyOptions}
                        {...props} />
                    )}
                  </Field>
                </Box>

                {values.frequency === 'custom' && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.4 }}>
                    <Field name="customFrequency_number">
                      {(props) => (
                        <FormInput
                          {...props}
                          type="number"
                          placeholder="Custom frequency"
                          fullWidth
                          InputProps={{
                            startAdornment: 'Every',
                          }}
                          sx={{ flex: 0.6, mb: 0.5 }}
                        />
                      )}
                    </Field>
                    <Field name="customFrequency_string">
                      {(props) => (
                        <Select
                          options={autoTransferCustomOptions}
                          {...props}
                        />
                      )}
                    </Field>
                  </Box>
                )}
                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Toast {...updateToast} handleClose={handleCloseToast} />

                <DialogActions>
                  <Button onClick={onClose} title='Cancel' sx={{ width: '120px' }} />
                  <Button type="submit" title='Save' variant='contained' disabled={isLoading} loading={isLoading} sx={{ width: '120px' }} />
                </DialogActions>
              </Box>
            </form>
          )}
        />
      </DialogContent>
    </DialogContainer>
  );
};

