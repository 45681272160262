import { CreateLoanData, RepayLoanData } from "../../types/actionTypes";
import { createIdempotenceKey } from "../../utils/middleware";
import api from "../api";
import { LOANS_ENDPOINT } from "../apiEndpoints";

const timeframeInMinutes = 10;

const loansApiClient = {
  // Retrieve a list of all loans
  getAll: async () => {
    const response = await api.get(`${LOANS_ENDPOINT}`);
    return response.data.loans;
  },
  getById: async (id: number) => {
    const response = await api.get(`${LOANS_ENDPOINT}/loan/${id}`);
    return response.data;
  },
  getTransactions: async (id: number, params: any) => {
    const response = await api.post(`${LOANS_ENDPOINT}/transactions/${id}`, params);
    return response.data;
  },
  create: async (data: CreateLoanData) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
      const config = {
        headers: {
          "x-idempotence-key": idempotenceKey,
        },
      };

      const response = await api.post(`${LOANS_ENDPOINT}/apply-loan`, data, config);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data
      }
    }

  },
  getDetails: async () => {
    const response = await api.get(`${LOANS_ENDPOINT}/loan-details`);
    return response.data;
  },
  repay: async (data: RepayLoanData) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);

      const config = {
        headers: {
          "x-idempotence-key": idempotenceKey,
        },
      };

      const response = await api.put(`${LOANS_ENDPOINT}/repay-loan`, data, config);
      return response?.data?.url;;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data
      }
    }

  },
  applySalary: async (data: any) => {
    const response = await api.post(`${LOANS_ENDPOINT}/apply/salary`, data);
    return response.data;
  },
  applyBusiness: async (data: any) => {
    const response = await api.post(`${LOANS_ENDPOINT}/apply/business`, data);
    return response.data;
  },
  getBusinessInfo: async (data: any) => {
    const response = await api.get(`${LOANS_ENDPOINT}/business-info`, data);
    return response.data;
  },
  getEmployeeInfo: async (data: any) => {
    const response = await api.get(`${LOANS_ENDPOINT}/employee-info`, data);
    return response.data;
  },
  addBusinessRevenue: async (data: any) => {
    const response = await api.post(`${LOANS_ENDPOINT}/business-revenue`, data);
    return response.data;
  },
  addLegalOwner: async (data: any) => {
    const response = await api.post(`${LOANS_ENDPOINT}/legal-owner`, data);
    return response.data;
  },
  removeLegalOwner: async (data: any) => {
    const response = await api.put(`${LOANS_ENDPOINT}/legal-owner`, data);
    return response.data;
  },
  updateBusinessLoanAmount: async (data: { id: number; loan_amount: number }) => {
    const response = await api.put(`${LOANS_ENDPOINT}/business/${data.id}`, data);
    return response.data;
  },

};

export default loansApiClient;
