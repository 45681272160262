import { Box, Grid, Typography } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles
import Ad1 from "../../assets/svgs/ad1.svg";
import Ad2 from "../../assets/svgs/ad2.svg";
import Ad3 from "../../assets/svgs/ad3.svg";
import Ad4 from "../../assets/svgs/ad4.svg";
import DurationOptionsList from '../Reusables/Display/DurationOptionList';
import CustomLink from '../Reusables/Input/CustomLink';
import { durationOptions } from '../Reusables/Input/select/Options';

import { useStyles } from './homeStyles'

const AdSection = () => {
  // Sample ad data (you can replace this with your own data)
  const ads = [
    {
      id: 1,
      image: Ad1,
      title: 'Send pocket money',
      description: "Send pocket money to your children at school. It’s digital, safe and convenient.",
    },
    {
      id: 2,
      image: Ad2,
      title: 'Get an instant saver loan',
      description: "Loan limit of up to 40% of your total saved up amount in goals.",
    },
    {
      id: 3,
      image: Ad3,
      title: 'Payday comes early with a salary loan',
      description: 'Loan limit of up to 20% of your monthly salary.',
    },
    {
      id: 4,
      image: Ad4,
      title: 'Do more with a business loan',
      description: 'Flexible loan limits. No collateral. Faster approvals.',
    },


  ];
  const classes = useStyles()
  return (
    <Box>
      <Box className={classes.adSection}>
        <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={false} showStatus={false}>
          {ads.map((ad) => (
            <Box className={classes.adContainer} key={ad.id}>
              <img src={ad.image} alt={`Ad ${ad.id}`} />
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left' }}>
                <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>{ad.title}</Typography>
                <Typography variant='body2'>{ad.description}</Typography>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Box>
      <Box sx={{ marginTop: 5, '& .css-ht41ky': { columnGap: 0.5 } }}>
        <Typography fontWeight={600} mb={1}>Save and earn with Pasbanc goals</Typography>
        <DurationOptionsList
          options={durationOptions}
          value={durationOptions[0].value}
          onChange={() => console.log('hi')}
        />
      </Box>
      <Grid container spacing={0} sx={{ background: '#F0EBF8', borderRadius: '8px', p: "0.5rem", mt: 4 }} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item xs={6} sm={8} md={9}>
              <Typography variant='body2' sx={{ maxWidth: { xs: "13.0625rem", sm: "13.0625", md: "70%" } }}>
                Create a goal account for your SACCO, club, association, union, family, friends, etc.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Box justifySelf={'flex-end'} width='auto'>
                <CustomLink
                  title='Create goal'
                  sx={{
                    color: '#fff',
                    background: '#673AB7',
                    borderRadius: '0.5rem',
                    padding: '0.625rem',
                    width: { xs: '100%', sm: '100%' },
                    height: { xs: 'auto', sm: '2.0625rem' },
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: { xs: '85%', sm: '75%', md: '90%' },
                    marginLeft:"auto"
                  }}
                  href='/goals/create'
                />
              </Box>
            </Grid>
          </Grid>

    </Box>
  );
};

export default AdSection;
