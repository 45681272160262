import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useGoalsTotal } from '../../hooks/balanceHooks';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { numberWithCommas } from '../../utils/middleware';
import { useStyles } from './SavingStyles'
import { Avatar, InputAdornment, Tabs, useMediaQuery, Theme, capitalize } from '@mui/material';
import { useUser } from '../../hooks/userHooks';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useHistory } from 'react-router-dom';
import CreateDeposit from '../../components/Modals/CreateDeposit';
import Button from '../../components/Reusables/Input/Button';
import { useState, useEffect } from 'react';
import { StyledTab } from '../../components/Reusables/Navigation/Tabs';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FormInput from '../../components/Reusables/Input/FormInput';
import { useNotifications, useUpdateNotifications } from '../../hooks/notificationHooks';
import NotificationsModal from '../../components/Modals/NotificationsModal';
import { Notification as NotificationType } from "../../types/types";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 0,
  paddingRight: 0,
  [theme.breakpoints.up('sm')]: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  background: 'white',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: -80,
  },
}));



export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '40ch',
      },
    },
  },
}));

export const WhiteAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: 'none',
  color: theme.palette.text.primary,
}));

interface SearchAppBarProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;
  searchValue: string;
  handleTabChange: any,
  activeTab: number

}

export default function SearchAppBar({ handleChange, clearSearch, searchValue, handleTabChange, activeTab }: SearchAppBarProps) {
  const { data, isError } = useGoalsTotal();
  const notificationsData = useNotifications();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [openNotifications, setOpenNotifications] = useState(false);
  const { updateNotifications } = useUpdateNotifications();

  const handleUpdateNotifications = () => {
    const ids = notificationsData.data.map((notification: NotificationType) => notification.id)
    updateNotifications(ids);
  };

  const { data: userData } = useUser();
  const history = useHistory();
  const [openDeposit, setOpenDeposit] = useState(false)
  const [hideBalance, setHideBalance] = useState(data?.hide_balance || false);
  const section = activeTab === 0 ? 'goals' :  'explore';

  useEffect(() => {
    if (data?.hide_balance !== undefined) {
      setHideBalance(data.hide_balance);
    }
  }, [data?.hide_balance]);

  const [showSearch, setShowSearch] = useState(false);

  const handleSearchIconClick = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch);
  };

  const classes = useStyles();

  const handleDepositClick = () => {
    setOpenDeposit(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }} >
      <WhiteAppBar position="static" >
        <StyledToolbar>
          <Box className={classes.header}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', mt: 1.5, mb: "1.19rem" }}>
              <Box sx={{ display: { xs: 'block', md: "none", } }}>
                <Link to="/profile">
                  <Avatar alt={'profile image'} src={userData?.profile_img ?? undefined} sx={{ bgcolor: "primary.main", width: 30, height: 30 }}
                  >{userData?.first_name.charAt(0)}</Avatar>
                </Link>
              </Box>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: { xs: 1, lg: 0 }, fontWeight: 600, mx: 1, display: { sm: 'block' }, justifyContent: { xs: 'center', sm: 'initial' }, textAlign: { xs: 'center', sm: "initial" } }}>
                Savings {isDesktop && <Typography component='span'>{`> ${capitalize(section)}`}</Typography>}
              </Typography>
              {!showSearch && !isDesktop ? (
                <IconButton onClick={handleSearchIconClick} >
                  <SearchIcon sx={{ fill: 'inherit' }} />
                </IconButton>
              ) : !isDesktop ? (
                <Search>
                  <IconButton onClick={handleSearchIconClick}>
                    <SearchIcon sx={{ fill: 'inherit' }} />
                  </IconButton>
                  <StyledInputBase
                    placeholder={`Search ${section}`}
                    value={searchValue}
                    endAdornment={
                      <InputAdornment position="end">
                        <CloseIcon onClick={() => {
                          clearSearch()
                          handleSearchIconClick()
                        }} />
                      </InputAdornment>
                    }
                    onChange={handleChange}
                  />
                </Search>
              ) : null}
              {isDesktop && (
                <>
                  <FormInput sx={{ backgroundColor: '#fff', borderRadius: 2, width: '417px' }} Icon={<SearchIcon />} placeholder={`Search ${section}`} value={searchValue} onChange={handleChange} />
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton color="inherit" onClick={() => setOpenNotifications(!openNotifications)} >
                      <NotificationsActiveIcon />
                    </IconButton>
                    <Typography variant='subtitle2' sx={{ color: '#F0EBF8', fontWeight: 600 }}>Notifications</Typography>
                  </Box>
                </>
              )}
            </Box>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="savings tabs" centered variant="fullWidth" className={classes.goalTabs}>
              <StyledTab label="Goals" className={classes.goalTab} />
              <StyledTab label="Explore" className={classes.goalTab} />
            </Tabs>
          </Box>
          {activeTab === 0 && <Box sx={{ width: '100%', px: 1 }}>
            <Box sx={{ alignSelf: 'center' }}>
              <div className={classes.balanceSection}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Typography variant="button">My Goal Balance</Typography>
                  <IconButton sx={{ color: '#000' }} size='small' onClick={() => setHideBalance(!hideBalance)}>
                    {hideBalance ? <VisibilityOffIcon sx={{ height: '18px', width: '18px' }} /> : <VisibilityIcon sx={{ height: '18px', width: '18px' }} />}
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: "4px" }}>
                  {!hideBalance ? (
                    <Typography variant="h2" fontSize="2.125rem" letterSpacing={"0.00531rem"}>
                      {!isError && data && numberWithCommas(data.available.amount + data.pending.amount)}
                    </Typography>
                  ) : (
                    <Typography variant="h4">••••••</Typography>
                  )}
                  <Typography variant="overline">
                    {!isError && data?.available.currency}
                  </Typography>
                </Box>
              </div>
            </Box>
            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center', width: '100%', maxWidth: { md: '23.0625rem' }, mx: "auto" }}>
              <Box sx={{ flexGrow: 0.5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Typography variant='button' sx={{ color: 'grey.700' }}>Locked Balance</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: "0.375rem" }}>
                  <Typography variant='body1' sx={{ fontWeight: 700 }}> {!isError && data && numberWithCommas(data.pending.amount)}</Typography>
                  <Typography variant='overline'>{!isError && data?.pending.currency}</Typography>
                </Box>
              </Box>
              <Box sx={{ ml: 2 }}>
                <Typography variant='button' sx={{ color: 'grey.700' }}>Available Balance</Typography>
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: "0.375rem" }}>
                  <Typography variant='body1' sx={{ fontWeight: 700 }}>{!isError && data && numberWithCommas(data.available.amount)}</Typography>
                  <Typography variant='overline'>{data?.available.currency}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '500px', m: '0 auto' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, }}>
                <Button variant="contained" onClick={handleDepositClick} sx={{ mt: "1rem", width: '100%' }} title='Deposit' />
                <Button variant="outlined" onClick={() => history.push(`/goals/create`)} sx={{ mt: "1rem", width: '100%', backgroundColor: `#F0EBF8` }} title='Create goal' />
              </Box>
              {!isError && data && data.available.amount > 0 && (
                <Button variant="contained" onClick={() => history.push(`/goals/reinvest`)} sx={{ mt: "1rem", width: '100%' }} title='Reinvest' />
              )}
            </Box>
          </Box>}
         
          <CreateDeposit open={openDeposit} onClose={() => setOpenDeposit(false)} />
          <NotificationsModal
            open={openNotifications}
            onClose={() => setOpenNotifications(!openNotifications)}
            notifications={notificationsData}
            handleUpdateNotifications={handleUpdateNotifications}
          />
        </StyledToolbar>
      </WhiteAppBar>
    </Box>
  );
}