// UserDrawer.js
import React from 'react';
import { Avatar, capitalize, Divider, Drawer, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { User } from '../../types/types';
import LinkIcon from '@mui/icons-material/Link';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useHistory } from 'react-router-dom';
import { useSignOut } from '../../hooks/authHooks';
interface UserDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  userData: User; 
}

const UserDrawer: React.FC<UserDrawerProps>  = ({ isOpen, onClose, userData }) => {
  const history = useHistory()
      const { signOut } = useSignOut();
      const handleSignout=()=>{
        signOut();
        history.push(`/auth/login/email`)
      }

  // Add your drawer content and logic here
  const {username,profile_img,first_name,last_name}=userData

  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose}>
      <List>
        <ListItem>
            <ListItemAvatar>
             <Avatar alt={first_name} src={profile_img}>
                {first_name.charAt(0)}
            </Avatar>
            </ListItemAvatar>
        </ListItem>

        <ListItem>
          <ListItemText primary={<Typography variant='h5' fontWeight={600}>{`${capitalize(first_name)} ${capitalize(last_name)}`}</Typography>} 
          secondary={<Typography variant='body2'>{username}</Typography>}/>
        </ListItem>
        <Divider />

        <ListItem component={Link} to={`/profile`}>
            <ListItemIcon>
                <AccountCircleOutlinedIcon />
            </ListItemIcon>
          <ListItemText primary="Profile" secondary='Personal information' />
        </ListItem>

         <ListItem >
            <ListItemIcon>
                <LinkIcon />
            </ListItemIcon>
          <ListItemText primary="Payment links" secondary='Send and receive money from anyone with just a link' sx={{whiteSpace: 'normal', wordWrap: 'break-word', width: '200px',fontWeight:600}} />
        </ListItem>
        <ListItem component={Link} to={`/profile/settings`}>
            <ListItemIcon>
                <SettingsOutlinedIcon />
            </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
      {/* Empty space to push the bottom list to the bottom */}
      <div style={{ flex: 1 }}></div>

      <List>
        <Divider/>
        <ListItem  button onClick={handleSignout}>
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItem>
         <ListItem component='a' href='https://t.me/PasbancChat'>
            <ListItemIcon>
                <QuestionAnswerIcon />
            </ListItemIcon>
          <ListItemText primary="Chat with us" />
        </ListItem>
         <ListItem component={Link} to={`/profile/help`}>
            <ListItemIcon>
                <HelpIcon />
            </ListItemIcon>
          <ListItemText primary="Help center & FAQ" />
        </ListItem>

      </List>
    </Drawer>
  );
};

export default UserDrawer;
