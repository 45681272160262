import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGoalMemberById, useGoalTransactions, useRevolvingPayoutTransactions } from "../../hooks/goalHooks";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import theme from "../../theme";
import TransactionsWrapper from "../transactions/TransactionsWrapper";
import { transactionTypes } from "../../components/Reusables/Input/select/Options";
import { DepositTabContent, WithdrawTabContent } from "../../components/transactions/TransactionSummaryDetails";
import { numberWithCommas } from "../../utils/middleware";

interface HomeTabContentProps {
  depositTotal: number;
  withdrawTotal: number;
  currency: string;
}

const HomeTabContent: React.FC<HomeTabContentProps> = ({ depositTotal, withdrawTotal, currency }) => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return <Box display={"flex"} flexDirection={"column"} gap="0.62rem">
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Total deposits</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`+ ${numberWithCommas(depositTotal)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Total withdraws</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`- ${numberWithCommas(withdrawTotal)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>
  </Box>
};

const MembersTransactionsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: memberQueryData } = useGoalMemberById(parseFloat(id));

  const memberId = memberQueryData?.user;
  const goalId = memberQueryData?.goal;


  const [filterValues, setFilterValues] = useState({
    startDate: '',
    endDate: '',
    type: 'all',
    category: 'all',
    paymentMethod: 'all',
    status: 'all',
    user: null,
  });


  const memberTransactions = useGoalTransactions({ ...filterValues, user: memberId }, false, goalId);

  const revolvingTransactions = useRevolvingPayoutTransactions(parseFloat(goalId));
  const totalDeposits = memberTransactions?.data?.pages?.[0]?.totalDeposits || 0;
  const totalWithdrawals = memberTransactions?.data?.pages[0]?.totalWithdrawals || 0;
  const currency = 'UGX';

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (memberTransactions?.data?.pages[0]?.transactions?.length === 0) return;
    setIsLoading(true);
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Box sx={{
      [theme.breakpoints.down('sm')]: {
        padding: "16px",
      },
    }}>
      <TransactionsWrapper
        transactions={memberTransactions}
        onUpdateValues={setFilterValues}
        member={memberQueryData}
        initialValues={filterValues}
        revolvingPayouts={revolvingTransactions}
        transactionPageTitle="Contribution details"
        transactionTypes={transactionTypes}
        customTabPanels={[
          <HomeTabContent depositTotal={totalDeposits} withdrawTotal={totalWithdrawals} currency={currency} />
          ,
          <DepositTabContent total={totalDeposits} currency={currency} />
          ,
          <WithdrawTabContent total={totalWithdrawals} currency={currency} />
        ]}
        isLoading={isLoading} />
    </Box>
  );
};

export default MembersTransactionsPage