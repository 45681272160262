import { GoalToGoalTransferData, GoalToPocketTransferData, PocketToPocketTransferData, WalletToGoalTransferData, WalletToPocketTransferData, WalletToWalletTransferData } from "../../types/actionTypes";
import { createIdempotenceKey } from "../../utils/middleware";
import api from "../api";
import { TRANSFERS_ENDPOINT } from "../apiEndpoints";

const timeframeInMinutes = 10;
const transfersApiClient = {
  // Retrieve a list of all transfers
  walletToGoal: async (data:WalletToGoalTransferData) => {
    const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    try {
      const response = await api.post(`${TRANSFERS_ENDPOINT}/wallet-to-goal`, data,config);
      return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  walletToWallet: async (data:WalletToWalletTransferData) => {
    const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    try {
      const response = await api.post(
      `${TRANSFERS_ENDPOINT}/wallet-to-wallet`,data,config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  pocketToPocket: async (data:PocketToPocketTransferData) => {
    const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    try {
      const response = await api.post(
      `${TRANSFERS_ENDPOINT}/pocket-to-pocket`,data,config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  walletToPocket: async (data:WalletToPocketTransferData) => {
    const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    try {
      const response = await api.post(
      `${TRANSFERS_ENDPOINT}/wallet-to-pocket`,data,config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  pocketToWallet: async (data:WalletToPocketTransferData) => {
    const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    try {
      const response = await api.post(
      `${TRANSFERS_ENDPOINT}/pocket-to-wallet`,data,config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  goalToWallet: async (data:WalletToGoalTransferData) => {
     const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    try {
      const response = await api.post(
      `${TRANSFERS_ENDPOINT}/goal-to-wallet`,data,config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  goalToPocket: async (data:GoalToPocketTransferData) => {
     const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    try {
      const response = await api.post(
      `${TRANSFERS_ENDPOINT}/goal-to-pocket`,data,config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  pocketToGoal: async (data:GoalToPocketTransferData) => {
     const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    try {
      const response = await api.post(
      `${TRANSFERS_ENDPOINT}/pocket-to-goal`,data,config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  goalToGoal: async (data:GoalToGoalTransferData) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
      const response = await api.post(`${TRANSFERS_ENDPOINT}/goal-to-goal`,data,config);
     return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
 
};

export default transfersApiClient;
