import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import wallpaper from "../../assets/images/wallpaper.png";

export const useStyles = makeStyles((theme:Theme) => ({
  profileSection:{
    [theme.breakpoints.up('lg')]:{
        display:'flex',
        flexDirection:'row-reverse',
        marginTop:'16px',
        paddingRight:'8px',
        justifyContent:'space-between',
        gap:'75px',
        marginRight:'60px'
    }
  },
   settingSection:{
    padding:'16px',
    [theme.breakpoints.up('lg')]:{
        display:'flex',
        marginTop:'16px',
        paddingRight:'8px',
        justifyContent:'space-between',
        gap:'75px',
        marginRight:'60px'
    },
   
  }, header:{
      display:'flex',alignItems:'center',
      [theme.breakpoints.up('lg')]:{
      backgroundColor:theme.palette.primary.main,
      color:'#fff',
      height:'60px'
      }
    }
 
  
  

  

 

  
}));
