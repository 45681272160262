import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "48px",
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      maxWidth: "1080px",
      width: "100%",
      margin: "0 auto",
      paddingLeft: "16px",
      paddingRight: "16px",
      marginBottom: "48px",
    },
  },
  formContainer: {
    marginTop: "47px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    [theme.breakpoints.up("lg")]: {
      marginTop: 0
    }

  },
  customForm: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    marginTop: 24,
    maxWidth: "28.125rem",
    [theme.breakpoints.up("lg")]: {
      width: "28.125rem",
      alignItems: 'unset',
      gap: 20
    },
  },

  nameContainer: {
    display: "flex",
    flexDirection: 'column',
    gap: 16,
    width: '100%',
    textAlign: 'left',
    [theme.breakpoints.up("lg")]: {
      flexDirection: 'row',
      justifyContent: "space-between",
      gap: "8px",
      alignItems: "center",
    }
  },

  textFieldContainer: {
    display: "flex",
    flexDirection: 'column',
    width: '100%',
    textAlign: 'left',
  },

  btnContainer: {
    marginTop: "16px",
    display: "flex",
    columnGap: "8px",
    fontSize: "16px",

  },
  pinContainer: {
    "& input": {
      boxShadow: "unset",
      width: 45,
      height: 45,
      border: `1px solid ${theme.palette.grey[400]}`,
      marginRight: 8,
      padding: 16,
      fontWeight: "bold",
      borderRadius: 5,
    },
  },
  logoContainer: {
    display: 'none',
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: "0.75rem",
      alignSelf: 'baseline',
      marginTop: 24,
    },

  },
  resetContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "16px",

  },
  formBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems:"center",
    margin:"0 auto",
    marginTop: "24px",
    gap: "20px",
    width: '100%',
    maxWidth: 300,
  },
}));
