import React, { useState } from 'react';
import {  Typography, Box, Alert } from '@mui/material';
import { useSecurityQuestions } from '../../hooks/userHooks';
import FormInput from '../Reusables/Input/FormInput';
import Button from '../Reusables/Input/Button';
import { useHistory } from 'react-router-dom';


interface SecurityQuestionsFormProps {
  onNext: () => void;
}

const SecurityQuestions:React.FC<SecurityQuestionsFormProps> =({ onNext }) =>{
    const [answer, setAnswer] = useState('');
    const history = useHistory()
    const [questionIndex, setQuestionIndex] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const { data:securityQuestions,isLoading,isError } = useSecurityQuestions();

    if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography>Error loading security questions</Typography>;
  }


  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(event.target.value);
  };


  const handleAnswerSubmit = () => {
  const currentQuestion = securityQuestions[questionIndex];

  if (answer === currentQuestion.answer) {
      // Redirect to the next step (e.g., PIN and password setup)
      onNext(); 
    
  } else {
    if (questionIndex < securityQuestions.length - 1) {
      setQuestionIndex(questionIndex + 1);
      setAnswer('');
      setErrorMessage('Incorrect answer. Please try the next question.');
    } else {
      // Inform the user that they have failed all questions
      setErrorMessage('You have failed all security questions. You cannot proceed to the next step.');
      // Optionally, you can show a different message or take a different action
    }
  }
};


  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Security Question {questionIndex + 1}
      </Typography>
      <Typography variant="body2" gutterBottom>
      {securityQuestions && securityQuestions[questionIndex] && (
  <Typography>{securityQuestions[questionIndex].question}</Typography>
)}

      </Typography>
      <FormInput
        fullWidth
        label="Your Answer"
        value={answer}
        onChange={handleAnswerChange}
      />
      {errorMessage && (
          <Alert severity="error" >
            {errorMessage}
          </Alert>
        )}
      <Box mt={2} sx={{textAlign:'center'}}>
        <Button title='Cancel' onClick={()=> history.push(`/profile/settings/security-privacy`)}/>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAnswerSubmit}
          title= {'Next' }
       />
      </Box>
    </div>
  );
}

export default SecurityQuestions