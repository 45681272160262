import { Alert, Avatar, Box, Chip, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Switch, SwitchProps, Typography, useMediaQuery, Theme, Drawer } from '@mui/material';
import Button from '../../components/Reusables/Input/Button';
import { styled } from '@mui/material/styles';
import { useStyles } from '../../components/Reusables/Input/ButtonStyles';
import ShareIcon from '@mui/icons-material/Share';
import { useHistory, useParams } from 'react-router-dom';
import { useGoalById, useLeaveGoal, useUpdateGoal, useUpdateGoalRevolvingSettings } from '../../hooks/goalHooks';
import { numberWithCommas, removeCommas } from '../../utils/middleware';
import dayjs from 'dayjs';
import SubscriptionList from './SubscriptionList';
import { useCreateSubscription } from '../../hooks/subscriptionHooks';
import { GoalMember, RevolvingSettings } from '../../types/types';
import { FormValues as SubscriptionFormValues } from './SubscriptionList'
import { Form, Field } from 'react-final-form';
import { useState } from 'react';
import { DialogContainer } from '../../components/Reusables/Feedback/InfoDialog';
import { autoTransferCustomOptions, autoTransferfrequencyOptions, privacyOptions } from '../../components/Reusables/Input/select/Options';
import Select from '../../components/Reusables/Input/select/Select';
import FormInput from '../../components/Reusables/Input/FormInput';
import { Edit } from '@mui/icons-material';
import { ValidationErrors } from 'final-form';
import Toast from '../../components/Reusables/Feedback/Toast';
import CloseIcon from '@mui/icons-material/Close';
import PayoutHistory from "../goals/PayoutHistory";
import GoalTransactionsPage from './GoalTransactions';
import ModalWrapper from '../../components/Modals/ModalWrapper';

interface FormValues {
  revolving: boolean;
  show_notifications: boolean;
  give_interest: boolean;
  minimum_deposit: number;
  privacy: string;
}

export const StyledFormInput = styled(FormInput)(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline .Mui-disabled:before': {
    borderBottomStyle: 'hidden',
  },
}));

export const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: '#000',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export const GoalInfo = () => {
  const classes = useStyles();
  const [openPayout, setOpenPayout] = useState(false)
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "update") {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const [exitGoal, setExitGoal] = useState(false)

  // Retrieve the goal data from the query cache
  const { id } = useParams<{ id: string }>();
  const history = useHistory()

  const { data: goal, isLoading, isError } = useGoalById(parseFloat(id));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const [isTransactionsOpen, setIsTransactionsOpen] = useState(false);

  const { leaveGoal, error: leaveError } = useLeaveGoal();
  const { createSubscription } = useCreateSubscription();
  const { updateGoal, } = useUpdateGoal();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { updateRevolvingSettings } = useUpdateGoalRevolvingSettings();

  const handleLeaveGoal = async () => {
    try {
      // Call the leaveGoal function with the goalId to leave the group.
      await leaveGoal({ goalId: parseFloat(id) });
      history.push(`/goals`)
      // The mutation was successful, handle success state here.
    } catch (error: any) {
      // The mutation failed, handle error state here.
      setUpdateToast({
        open: true,
        message: (leaveError as Error).message,
        type: 'error',
      });
    }
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      await updateGoal({ id: goal.id, data: values });
      setUpdateToast({
        open: true,
        message: "Goal updated successfully",
        type: "success",
      });
    } catch (error) {
      setUpdateToast({
        open: true,
        message: "Failed to update goal",
        type: "error",
      });
    }
  }

  const handleCreateSubscription = async (data: SubscriptionFormValues) => {
    try {
      if (data.type === 'withdraw') {
        await createSubscription({
          source_type: 'goal',
          source: goal.id,
          type: data.type,
          percentage: data.percentage,
          currency: goal.currency,
          amount: data.amount,
          destination_type: data.category,
          destination: data.id,
          frequency: data.frequency,
          custom_frequency: `${data.customFrequency_number} ${data.customFrequency_string}`
        });


      } else if (data.type === 'deposit') {
        await createSubscription({
          destination_type: 'goal',
          destination: goal.id,
          type: data.type,
          currency: goal.currency,
          amount: data.amount,
          source: data.id,
          source_type: data.category,
          percentage: data.percentage,
          frequency: data.frequency,
          custom_frequency: `${data.customFrequency_number} ${data.customFrequency_string}`
        });
      }
      setUpdateToast({
        open: true,
        message: "Subscription created successfully",
        type: "success",
      });

    } catch (error) {
      // The mutation failed, handle error state here.
      console.error('Error creating subscription:', error);
      setUpdateToast({
        open: true,
        message: "Failed to create subscription",
        type: "error",
      });
    }
  };


  // Handle loading state
  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  // Handle error state
  if (isError) {
    return <Typography>Error occurred while fetching goal data.</Typography>;
  }


  const { description, invite_link, privacy, minimum_deposit, currency, show_notifications, give_interest, users, created_at, isAdmin, subscriptions, revolving, revolving_settings } = goal





  const handleRevolvingSwitchChange = async (goalId: number, isRevolving: boolean) => {
    if (isRevolving) {

      const revolvingSettingsExist = !!goal && !!revolving_settings

      if (!revolvingSettingsExist) {
        setIsEditDialogOpen(true);
      } else {
        await updateRevolvingSettings({
          id: goalId,
          data: {
            revolving: isRevolving,
          },
        });
      }
    } else {
      await updateRevolvingSettings({
        id: goalId,
        data: {
          revolving: isRevolving,
        },
      });
    }
  }



  const handleCopyLink = async () => {
    try {
      const shareableLink = `${process.env.REACT_APP_API_URL}/goals/join/${goal.id}`;
      if (navigator.share) {
        navigator.share({
          title: 'Pasbanc',
          text: `Click this link to join the goal: ${goal.name}`,
          url: shareableLink,
        });
      } else {
        // Fallback for browsers that do not support Web Share API
        // You can implement your own custom sharing logic here
        console.log(`Share this link: ${shareableLink}`);
      }


    } catch (error) {

    }
  };

  const handleClick = () => {
    if (isDesktop) {
      setOpenPayout(true)
    } else {
      history.push(`/goals/payouts/${id}`)
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      <Box sx={{ my: 1 }}>
        <Typography variant='body1' fontWeight={600}>Description</Typography>
        <Typography variant='body2'>{description}</Typography>
        <Typography variant='caption'>{`Created by ${users?.first_name} ${users?.last_name}.${dayjs(created_at).format('ddd D MMM YY')} . ${dayjs(created_at).format('h:mm A')}.`}</Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='body1' fontWeight={600}>Invite link</Typography>
          <Chip label="Share" size="small" onClick={handleCopyLink} icon={<ShareIcon />} />
        </Box>
        <Typography color='primary' fontWeight={600}>{`${process.env.REACT_APP_API_URL}${invite_link}`}</Typography>
      </Box>
      <Form initialValues={{
        revolving: revolving,
        show_notifications: show_notifications,
        give_interest: give_interest,
        privacy: privacy,
        minimum_deposit: minimum_deposit
      }}
        onSubmit={handleSubmit}>
        {({ handleSubmit, values, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant='body1' fontWeight={600}>Visibility</Typography>
              <Field
                type="select"
                name="privacy"
                component={Select}
                options={privacyOptions}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant='body1' fontWeight={600}>Minimum deposit</Typography>
              <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.2 }}>
                <Field name="minimum_deposit" type="text">
                  {(props) => (
                    <StyledFormInput
                      name="minimum_deposit"
                      type="text"
                      label=""
                      variant='standard'
                      sx={{ width: '60px' }}
                      {...props}
                    />
                  )}
                </Field>
                <Typography variant='overline'>{currency}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant='body1' fontWeight={600}>Auto-transfer</Typography>
              <FormControlLabel sx={{ m: 0 }}
                control={<Android12Switch defaultChecked={subscriptions.length > 0} />}
                label="" />
            </Box>
            <SubscriptionList subscriptions={subscriptions} createSubscription={handleCreateSubscription} />
            {isAdmin && (
              <Box>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='body1' fontWeight={600}>Revolving fund</Typography>
                    <Field name="revolving" type="checkbox">
                      {({ input }) => (
                        <FormControlLabel sx={{ m: 0 }}
                          control={<Android12Switch checked={input.checked}
                            onChange={(event) => {
                              input.onChange(!input.checked);
                              handleRevolvingSwitchChange(goal.id, event.target.checked)
                            }
                            } />}
                          label="" />
                      )}
                    </Field>

                  </Box>
                </Box>
                {isEditDialogOpen && <EditRevolvingSettingsDialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} settings={goal.revolving_settings} />}

                {
                  values.revolving && (
                    <List sx={{ pt: 0 }}>
                      <ListItem disableGutters sx={{ py: 0, flexWrap: 'wrap' }}>
                        <ListItemText primary={<Typography variant="body2" fontWeight={600} >{`Frequency`}</Typography>} secondary={<Typography>{revolving_settings?.payout_frequency ?? 'N/A'}</Typography>} />
                        <ListItemText primary={<Typography variant="body2" fontWeight={600}>{`Total amount`}</Typography>}
                          secondary={
                            <Typography>
                              {revolving_settings?.payout_amount
                                ? numberWithCommas(revolving_settings.payout_amount * (revolving_settings.revolving_order?.length ?? 1))
                                : 'N/A'}
                            </Typography>
                          } />
                        <ListItemText primary={<Typography variant="body2" fontWeight={600}>{`Recipients`}</Typography>}
                          secondary={<Typography>{revolving_settings?.revolving_order?.length ?? 1} </Typography>} />
                        <ListItemText primary={<Typography variant="body2">
                          {revolving_settings?.next_payout_date
                            ? dayjs(revolving_settings.next_payout_date).format('ddd D MMM')
                            : 'N/A'}
                        </Typography>}
                          sx={{ mt: 0 }}
                        />
                        <ListItemText primary={<Typography variant="body2">
                          {revolving_settings?.payout_amount ? `${numberWithCommas(revolving_settings.payout_amount)} each` : 'N/A'}</Typography>}
                          sx={{ mt: 0 }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="edit" onClick={() => setIsEditDialogOpen(!isEditDialogOpen)}>
                            <Edit />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem sx={{ pl: 0 }}>
                        <ListItemText primary={<Typography variant="body2" sx={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '200px', fontWeight: 600 }}>{`Payout history/manual selection`}</Typography>} />
                        <ListItemSecondaryAction sx={{ right: 0 }}>
                          <Chip label="View here" onClick={handleClick} sx={{ color: '#021AEE', fontWeight: 600 }} />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  )
                }
              </Box>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant='body1' fontWeight={600}>Turn on notifications</Typography>
              <Field name="show_notifications" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    sx={{ m: 0 }}
                    control={<Android12Switch  {...input} />}
                    label=""
                  />
                )}
              </Field>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant='body1' fontWeight={600}>Turn off interest rates</Typography>
              <Field name="give_interest" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    sx={{ m: 0 }}
                    control={<Android12Switch  {...input} />}
                    label=""
                  />)}
              </Field>

            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant='body1' fontWeight={600}>Deposit frequency</Typography>
              <Typography>Anytime</Typography>
            </Box>
            {!pristine && <Button type="submit" title='Save' variant='outlined' sx={{ mt: 2, width: '100%', maxWidth: '250px', m: '16px auto' }} />}
          </form>
        )}
      </Form>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2, alignItems: 'center' }}>
        <Button title='View transaction history' variant='outlined' className={classes.largeButton} sx={{ width: '100%' }} onClick={isDesktop ? () => setIsTransactionsOpen((prevState) => !prevState) : () => history.push(`/goals/transactions/${id}`)} />
        <Button title='Exit goal' variant='outlined' className={classes.largeButton} sx={{ width: '100%' }} onClick={() => setExitGoal(!exitGoal)} />
      </Box>
      <DialogContainer open={exitGoal} onClose={() => setExitGoal(false)}>
        <DialogContent sx={{ textAlign: 'center', fontWeight: 600 }}>
          Are u sure u want to leave goal ?
          {leaveError && <Alert severity='error'>{(leaveError as Error).message}</Alert>}
          <DialogActions>
            <Button title='Cancel' sx={{ width: '120px', mr: 1 }} onClick={() => setExitGoal(false)} />
            <Button title='Confirm' color='error' variant='contained' sx={{ width: '120px' }} onClick={handleLeaveGoal} />
          </DialogActions>
        </DialogContent>
      </DialogContainer>
      <Toast {...updateToast} handleClose={() => handleCloseToast("update")} />
      <Drawer anchor="right" open={openPayout} onClose={() => setOpenPayout((prevState) => !prevState)}>
        <PayoutHistory />
      </Drawer>
      <Drawer anchor="right" open={isTransactionsOpen} onClose={() => setIsTransactionsOpen((prevState) => !prevState)} >
        <GoalTransactionsPage />
      </Drawer>
    </Box>
  )
}


interface EditRevolvingSettingsProps {
  open: boolean;
  onClose: () => void;
  settings?: RevolvingSettings;
}

interface EditRevolvingSettingsFormValues {
  payout_frequency: string;
  payout_amount: string;
  customFrequency_number: number;
  customFrequency_string: string;
}


const EditRevolvingSettingsDialog: React.FC<EditRevolvingSettingsProps> = ({ open, onClose, settings }) => {
  const { updateRevolvingSettings, error } = useUpdateGoalRevolvingSettings();
  const { id } = useParams<{ id: string }>();
  const [members, setMembers] = useState(settings?.revolving_order as GoalMember[] || []);

  const removeMember = (id: number) => {
    setMembers((prevMembers) =>
      prevMembers.filter((member: GoalMember) => member.id !== id)
    );
  };



  const validate = (values: EditRevolvingSettingsFormValues) => {
    const errors: ValidationErrors = {};

    if (!values.payout_frequency) {
      errors.payout_frequency = 'Required'
    }

    if (values.payout_frequency === 'custom') {
      if (!values.customFrequency_number) {
        errors.customFrequency_number = 'Required'
      }
      if (!values.customFrequency_string) {
        errors.customFrequency_string = 'Required'
      }
    }


    if (!values.payout_amount) {
      errors.payout_amount = "Required";

    }


    return errors;
  };

  const handleSubmit = async (values: EditRevolvingSettingsFormValues) => {
    try {

      // Filter out the members to remove from the existing revolving order
      const updatedRevolvingOrder = settings && settings.revolving_order
        ? (settings?.revolving_order as GoalMember[] || []).filter((member: GoalMember) => !members.some((m) => m.id === member.id)).map((member) => member.id)
        : [];



      await updateRevolvingSettings({
        id: parseFloat(id),
        data: {
          revolving: true,
          payout_amount: parseFloat(removeCommas(values.payout_amount)),
          payout_frequency: values.payout_frequency,
          custom_frequency: `${values.customFrequency_number} ${values.customFrequency_string}`,
          revolving_order: updatedRevolvingOrder as unknown as GoalMember[]
        },
      });
      onClose();
    } catch (error) {
      console.log(error)
    }

  };

  return (
    <>
      <ModalWrapper open={open} onClose={onClose}>
        <DialogTitle sx={{ textAlign: 'center' }}>Edit Revolving Settings</DialogTitle>
        <DialogContent>
          <Form
            initialValues={{
              payout_amount: settings?.payout_amount ?? 0,
              payout_frequency: settings && settings.payout_frequency ? settings?.payout_frequency.split(" ").length === 1 ? settings?.payout_frequency : 'custom' : '',
              customFrequency_number: settings?.payout_frequency && settings?.payout_frequency.split(" ")[0],
              customFrequency_string: settings?.payout_frequency && settings?.payout_frequency.split(" ")[1]
            }}
            validate={validate}
            onSubmit={handleSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <Box >
                    <Typography variant="body1" fontWeight={600}> Add payout frequency</Typography>
                    <Field name="payout_frequency">
                      {(props) => (<Select options={autoTransferfrequencyOptions}
                        {...props} />
                      )}
                    </Field>
                  </Box>
                  <Box>
                    {values.payout_frequency === 'custom' && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.4 }}>
                        <Field name="customFrequency_number">
                          {(props) => (
                            <FormInput
                              {...props}
                              type="number"
                              placeholder="Custom frequency"
                              fullWidth
                              InputProps={{ startAdornment: 'Every' }}
                              sx={{ flex: 0.6, mb: 0.5 }}
                            />
                          )}
                        </Field>
                        <Field name="customFrequency_string">
                          {(props) => (
                            <Select options={autoTransferCustomOptions}
                              {...props}
                            />
                          )}
                        </Field>
                      </Box>
                    )}
                  </Box>
                  <Box mt={2}>
                    <Field name="payout_amount">
                      {(props: any) => <FormInput
                        label=" How much should each person get in this revolving payout?"
                        type="text"
                        value={numberWithCommas(props.input.value)}
                        onChange={(e) => {
                          props.input.onChange(removeCommas(e.target.value));

                        }}
                        {...props}
                      />}
                    </Field>
                  </Box>
                </Box>
                <Typography fontWeight={600} mt={2}>Selected members for payout</Typography>
                {members && members.length > 0 && (
                  <List dense>
                    {members.map((member) => (
                      <ListItemButton
                        key={member.id}
                      >
                        <ListItemIcon>
                          <Avatar
                            alt={'profile image'}
                            src={member?.users?.profile_img ?? undefined}
                            sx={{ bgcolor: "primary.main", width: 30, height: 30 }} />

                        </ListItemIcon>
                        <ListItemText primary={`${member.users?.first_name} ${member.users?.last_name} (${member.users?.username})`} secondary={member.users?.phone_number} />
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => removeMember(member.id)}>
                            <CloseIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    ))}
                  </List>
                )}
                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <DialogActions>
                  <Button onClick={onClose} title='Cancel' sx={{ width: '120px' }} />
                  <Button type="submit" title='Save' variant='contained' sx={{ width: '120px' }} />
                </DialogActions>
              </form>
            )}
          />
        </DialogContent>
      </ModalWrapper>
    </>
  );
};

