import api from "../api";
import { PAYOUTS_ENDPOINT } from "../apiEndpoints";
import { BankTransferData, MobilePayValues} from "../../types/actionTypes";
import { createIdempotenceKey } from "../../utils/middleware";
const timeframeInMinutes = 60; // Set the timeframe to an hour

const payoutsApiClient = {
  mobileWalletPayout: async (data:MobilePayValues) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);

    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    const response = await api.post(
      `${PAYOUTS_ENDPOINT}/mobile/wallet`,
      data,
      config
    );
    return response.data;
    } catch (error:any) {
      console.log(error)
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  mobileGoalPayout: async (data:MobilePayValues) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);

    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
     await api.post(
      `${PAYOUTS_ENDPOINT}/mobile/goal`,
      data,
      config
    );
    ;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  bankWalletPayout: async (data:BankTransferData) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);

    const config = {
      headers: {
        "x-idempotence-key":idempotenceKey,
      },
    };
    const response = await api.post(
      `${PAYOUTS_ENDPOINT}/bank/wallet`,
      data,
      config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  bankGoalPayout: async (data:BankTransferData) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);

    const config = {
      headers: {
        "x-idempotence-key":idempotenceKey,
      },
    };
    const response = await api.post(
      `${PAYOUTS_ENDPOINT}/bank/goal`,
      data,
      config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  
   getPayoutOptions:async()=>{
    const response = await api.post(`${PAYOUTS_ENDPOINT}/options`);
    return response.data;
  }
  
};

export default payoutsApiClient;
