import React,{ReactNode} from "react";
import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import {  styled } from '@mui/material/styles';
import { Box,Typography } from "@mui/material";

export const CustomizedTextField = styled(TextField)(({theme})=>({
 minWidth:'100%',
 "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
  padding: "8px 12px",
  fontFamily: "Source Serif Pro, serif",  
},
'&.MuiFormControl-root .MuiFormHelperText-root':{
  fontFamily: "Source Serif Pro, serif", 
  textAlign:'left',
  marginLeft:0,
  marginTop:4,
},'&.MuiFormControl-root .MuiIconButton-root':{
  padding:'0px'

},
"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
  borderColor:theme.palette.divider
},
}))

interface CustomInputProps extends StandardTextFieldProps {
  Icon?: ReactNode;
  label?: string;
  placeholder?: string;
  input?:any;
  meta?:any
}

const CustomInput: React.FC<CustomInputProps> = ({
  input,
  meta,
  Icon,
  label,
  placeholder,
  ...rest
}) => {

  return (
    <Box>
        <Typography 
        variant ='body1' 
        sx={{marginBottom:'4px',fontWeight:600}}>
          {label}
        </Typography>
      <CustomizedTextField
        variant="outlined"
        placeholder={placeholder}
        error={!!(meta?.error || meta?.submitError) && meta?.touched}
        helperText={meta?.touched ? meta?.error || meta?.submitError : ""}
        autoComplete="off"
        {...input}
        InputProps={{
          endAdornment: Icon ? <React.Fragment>{Icon}</React.Fragment> : null,
        }}
         {...rest}
      />
      
    </Box>
  );
};

export default CustomInput;
