import React, { useState } from "react";
import Button from "../Reusables/Input/Button";
import FormInput from "../Reusables/Input/FormInput";
import { useStyles } from "./ProfileStyles";
import { Form, Field, FormSpy } from "react-final-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, Typography } from "@mui/material";
import Alert from '@mui/material/Alert';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useUpdatePin } from "../../hooks/userHooks";
import { useHistory } from "react-router-dom";
import Toast from "../Reusables/Feedback/Toast";

interface FormValues {
  pin: string;
  newPin: string;
  confirmPin: string;
}



function PINForm() {
  const history = useHistory()
  const [showPIN, setPIN] = useState(false);
  const [showOldPIN, setOldPIN] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
   const { updatePin, isLoading,error } = useUpdatePin();
   const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });
   const handleCloseToast = () => {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    
  };


  const validate = (values:FormValues) => {
    const errors:Partial<FormValues> = {};
    if (!values.pin) {
      errors.pin = "Required";
    }
    if (!values.newPin) {
      errors.newPin = "Required";
    }
    if (!values.confirmPin) {
      errors.confirmPin = "Required";
    }
    if (values.newPin?.length !== 5) {
      return { newPassword: "Pin must be 5 chars" };
    }
    if (values.newPin !== values.confirmPin) {
      return { confirmPassword: "Passwords must match" };
    }
    return errors;
  };
  
  const onSubmit = async(values:FormValues, form:any) => {
    try {
      await updatePin({ pin: values.pin,newPin:values.newPin }); // Replace with the new PIN
       setUpdateToast({
      open: true,
      message: "PIN updated successfully",
      type: "success",
    });
      Object.keys(values).forEach((key) => {
          form.change(key, undefined);
          form.resetFieldState(key);
        });
    } catch (error) {
      console.error('Failed to update PIN:', error);
    }
    
  
  };
  return (
    <Box sx={{m:2}}>
       <Box sx={{display:'flex',alignItems:'start',gap:1,mt:2}}>
         <IconButton onClick={()=>history.goBack()} sx={{p:0}}>
            <ArrowBackOutlinedIcon />
         </IconButton>
          <Typography variant="h6" fontWeight={600} sx={{m:'0 auto'}}>
          PIN setup
        </Typography>
         </Box> 
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting,form }) => (
          <form  onSubmit={handleSubmit}>
            <Box sx={{mt:2,maxWidth:'400px',margin:'16px auto',display:'flex',flexDirection:'column',gap:2}}>
            <Field
              search={true}
              label="Enter current PIN"
              name="pin"
              component={FormInput}
              type={showOldPIN ? "text" : "password"}
              position="end"
              Icon={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setOldPIN(!showOldPIN)}
                  size="large">
                  {showPIN ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />
            <Field
              search={true}
              label="Set a new PIN"
              name="newPin"
              component={FormInput}
              type={showPIN ? "text" : "password"}
              position="end"
              Icon={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPIN(!showPIN)}
                  size="large">
                  {showPIN ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />
            <Field
              search={true}
              label="Confirm New PIN"
              name="confirmPin"
              component={FormInput}
              type={showConfirmPassword ? "text" : "password"}
              position="end"
              Icon={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setConfirmPassword(!showConfirmPassword)}
                  size="large">
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
            />
            {/**display error on submission if it exits and remove it as soon as the user starts typing again
             * Since initially there are no changes but this is when wer show the message that is why we negate it with !
             */}
            <FormSpy subscription={{ dirtySinceLastSubmit: true }}>
              {({
                dirtySinceLastSubmit
              }: any) => {
                if (error && !dirtySinceLastSubmit) {
                  return <Alert severity="error">{error}</Alert>;
                }else if(!error && !dirtySinceLastSubmit){
                   <Alert severity="success">{`PIN updated successfully`}</Alert>;
                }
                return null;
              }}
            </FormSpy>
            <Box sx={{textAlign:'center',mt:4}}>
              <Button title='Cancel' sx={{backgroundColor:'#F0EBF8',mr:1.4,width:'120px'}}
              onClick={()=>form.reset()} />
              <Button
                title="Save"
                color="primary"
                variant="contained"
                type="submit"
                disabled={submitting || isLoading}
                sx={{width:'120px'}}
              />
            </Box>
            </Box>
          </form>
        )}
      />
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />

    </Box>
  );
}

export default PINForm;

