import { memo, useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeSharpIcon from "@mui/icons-material/HomeSharp";
import { ReactComponent as SavingIcon } from '../../../assets/svgs/Money.svg';
import { NavLink, useHistory } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import Paper from '@mui/material/Paper';
import { ReactComponent as WalletIcon } from "../../../assets/svgs/Wallet.svg";
import SvgIcon from "@mui/material/SvgIcon";
import { Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as LendIcon } from "../../../assets/svgs/Lending.svg";
import { ReactComponent as LendColoredIcon } from "../../../assets/svgs/Profit_colored.svg";

const styles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  bar: {
    padding: '0.75rem 2.09375rem 0.5rem 2.09375rem'
  },
  barItem: {
    minWidth: 'none',
    maxWidth: 'none',
    width: 'auto',
  }
}));

function LabelBottomNavigation() {
  const classes = styles();
  const history = useHistory();
  const navigationItems = [
    {
      label: "Home",
      icon: <HomeSharpIcon />,
      to: "/",
      exact: true,
    },
    /* {
      label: "Wallet",
      icon: <SvgIcon component={WalletIcon} />,
      to: "/wallet",
    }, */
    {
      label: "Savings",
      icon: <SvgIcon component={SavingIcon} />,
      to: "/goals",
    },
    {
      label: "Lending",
      icon: <SvgIcon component={LendIcon} />,
      coloredIcon: <SvgIcon component={LendColoredIcon} />,
      to: "/loans",
    },
    {
      label: "History",
      icon: <HistoryIcon />,
      to: "/transactions",
    },
  ];
  const activeLendRoute = history.location.pathname.startsWith('/loans');
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down(315));
  const activeRoute = history.location.pathname;

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: "999" }} elevation={3}>
      <BottomNavigation showLabels={!isSmallDevice} sx={{padding:'0.75rem 2.09375rem 0.5rem 2.09375rem'}}>
        {navigationItems.map((item, index) => (
          <BottomNavigationAction
            key={index}
            label={!isSmallDevice && item.label}
            icon={(item.label === "Lending" && activeLendRoute) ? item.coloredIcon : item.icon}
            component={NavLink}
            to={item.to}
            exact={item.exact}
            classes={{ label: classes.label, root: classes.barItem }}
            sx={{
              color: "#000000",
              gap: "0.25rem",
              padding:"0",
                minWidth: isSmallDevice ? "40px" : "65px",
              width:"auto",
              "&.active": {
                color: "#673AB7",
              },
              justifyContent:"center",
              alignItems:"center",
              mx: isSmallDevice ? "0.5rem" : "0.5px",
            }}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}

export default memo(LabelBottomNavigation);
