import React from 'react'
import { Box, CircularProgress, Divider, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import ContentBox from '../../components/layout/ContentBox';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory, useParams } from 'react-router-dom';
import { useLoanById, useLoanTransactions,  } from '../../hooks/loanHooks';
import { Loan, Transaction } from '../../types/types';
import dayjs from 'dayjs';
import {  convertMonthsToDays, numberWithCommas, returnLoanInterest } from '../../utils/middleware';
import FlipMove from "react-flip-move";
import Button from '../../components/Reusables/Input/Button';





const LoanPage = () => {
     const history = useHistory();
     const { id } = useParams<{ id: string }>();
     const { data: loan,isLoading,isError } = useLoanById(parseFloat(id));
    
     const isDesktop = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));
  return (
    <Box sx={{px:1}}>
      <Box sx={{display:'flex',alignItems:'start',gap:1,mt:2}}>
        {!isDesktop && (
          <IconButton onClick={()=>history.goBack()} sx={{p:0}}>
            <ArrowBackOutlinedIcon />
         </IconButton>
        )}
        
          <Typography variant="h6" fontWeight={600} sx={{m:'0 auto'}}>
          Loan details
        </Typography>
         </Box> 

      <ContentBox>
        <Box sx={{ mt: 2 }}>
            {isLoading || isError ?
            ( <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><CircularProgress /></Box>)
            :( <LoanContent loan={loan}/>)}
         
         
        </Box>
      </ContentBox>
    </Box>
  )
}

export default LoanPage

export const calculateDaysToDueDate=(dueDate: Date)=> {
  const daysDifference = dayjs(dueDate).diff(dayjs(), 'days');

  if (daysDifference < 0) {
    return 'overdue';
  } else {
    return `${daysDifference} days`;
  }
}

export const LoanContent = ({ loan }: { loan: Loan & { amountLeft?: number } }) => {
   const interestOnly = returnLoanInterest(loan.loan_amount, loan.rate, loan.duration);

  const {data,isLoading,isError,hasNextPage,isFetchingNextPage,fetchNextPage} = useLoanTransactions({}, false, loan.id);
  return (
    <div>
      <Box sx={{
                display: 'flex',
                padding: '0.5rem',
                flexDirection: 'column',
                borderRadius: '0.375rem',
                background: '#F5F5F5',
                width: '100%',
                gap: "0.5rem"
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body2'>Amount to pay</Typography>
            <Typography variant='h5' color='error'>{numberWithCommas(loan.loan_amount - loan.amount_paid)}
            <Typography variant='overline' component='span'>{` ${loan.currency}`}</Typography></Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body2'>Cleared so far</Typography>
            <Typography>
            {`${((loan.amount_paid / loan.loan_amount) * 100).toFixed(0)}%`}
            </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
             <Typography variant='body2'>{`Loan ID PB00${loan.id}G`}</Typography>
            <Typography>{numberWithCommas(`${loan.loan_amount}`)}
            <Typography variant='overline' component='span'>{`${loan.currency}`}</Typography></Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body2'>Granted amount</Typography>
            <Typography>{numberWithCommas(`${loan.loan_amount -interestOnly}`)}
            <Typography variant='overline' component='span'>{`${loan.currency}`}</Typography></Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body2'>Interest</Typography>
            <Typography>{numberWithCommas(interestOnly)}
            <Typography variant='overline' component='span'>{`${loan.currency}`}</Typography></Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body2'>Loan duration</Typography>
            <Typography>{`${convertMonthsToDays(loan.duration)}
             days`}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body2'>From</Typography>
            <Typography>{dayjs(loan.created_at).format("ddd, DD MMM YY")}
            </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body2'>To</Typography>
            <Typography>{dayjs(loan.due_date).format("ddd, DD MMM YY")}
            </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body2'>Due</Typography>
            <Typography>{calculateDaysToDueDate(loan.due_date)}
            </Typography>
            </Box>
            <Divider variant='middle'/>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body2'>Withdrawable loan balance</Typography>
            <Typography>{numberWithCommas(`${loan.amountLeft ?? 0} `)}
            <Typography variant='overline' component='span'>{` ${loan.currency}`}</Typography></Typography>
            </Box>
     </Box>
         <Box mt={3}>
          {isLoading || isError ? <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><CircularProgress /></Box> :
          <FlipMove>
        <Typography>Transactions</Typography>
        {data.pages.map((page, pageIndex) => (
          <React.Fragment key={pageIndex}>
            {page.transactions.length === 0 ? (
              <Box display="flex" justifyContent="center" alignItems="center"><Typography variant="body1" color="grey.400" textAlign="center">No transactions yet</Typography></Box>
            ) : page.transactions.map((transaction: Transaction) => (
             <Box sx={{mt:0.5}}>
            <Typography variant='overline' textTransform={"initial"} textAlign={"right"}>
            {dayjs(transaction.updated_at).format("MMM D, YYYY - h:mmA")}
          </Typography>
           <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body2'>{transaction.type === 'withdrawal' ? `Withdraw` : `Repaid`}  </Typography>
            <Typography variant='body1' color={transaction.type === 'withdrawal' ? 'inherit':'success.dark'} sx={{fontWeight:600}}>
             {transaction.type === 'withdrawal' ? `-` : `+`}   {numberWithCommas(transaction.amount)}
            <Typography variant='overline' component='span'>{` ${loan.currency}`}</Typography></Typography>
           
            </Box>
            <Divider sx={{mt:1,borderColor: "#E2E8F0"}}/>
             </Box>
            ))}
          </React.Fragment>
        ))}
       
      </FlipMove>}

        </Box>       
       {hasNextPage  && (
        <Button onClick={() => fetchNextPage()}
       title={isFetchingNextPage ? 'Loading more...' : 'Load more'}  disabled={isFetchingNextPage} sx={{m:'0 auto'}}/>) }
    </div>
  )
}


