import { Tab, Tabs } from "@mui/material";
import { styled } from '@mui/material/styles';
import { StyledTabProps, StyledTabsProps } from "../../pages/transactions/TransactionListTabs";
import theme from "../../theme";

export const LightStyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        centered
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    minHeight: 'unset',
    padding: '4px 4px',
    margin: '0 auto', backgroundColor: '#F0EBF8', minWidth: "auto", width: "70%",
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
    borderRadius: "0.5rem",
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 0,
    },
});

export const LightStyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: "1rem",
    padding: "0.625rem",
    height: "2rem",
    borderRadius: "0.5rem",
    minHeight: 'unset',
    flexGrow: 0.5,
    textAlign: "center",
    color: "#000000",
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));