import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Alert, AlertTitle, Box, DialogActions, DialogContent, DialogTitle, duration, FormHelperText, IconButton, Typography } from "@mui/material";
import Button from "../../components/Reusables/Input/Button";
import { useStyles } from "./GoalStyles";
import { useStyles as useButtonStyles } from '../../components/Reusables/Input/ButtonStyles'
import { numberWithCommas, removeCommas, renderInterestRate } from '../../utils/middleware';
import { useHistory } from 'react-router-dom';
import { useWalletOptions } from '../../hooks/walletHooks';
import FormInput from '../../components/Reusables/Input/FormInput';
import { adminOptions, delayOptions, yesOrNoOptions } from '../../components/Reusables/Input/select/Options';
import { useCreatePocket } from '../../hooks/pocketHooks';
import Select from '../../components/Reusables/Input/select/Select';
import RadioButtons from '../../components/Reusables/Input/RadioGroup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Divider from '../../components/Reusables/Display/Divider';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ModalWrapper from '../../components/Modals/ModalWrapper';

export interface FormValues {
  name: string;
  targetAmount: string;
  add_delay: boolean;
  delay: string;
  wallet: number | string;
}

export const CreatePocket = () => {
  const history = useHistory()
  const { data, isLoading, isError } = useWalletOptions();
  const { createPocket, error } = useCreatePocket()
  const [open, setOpenDialog] = useState(false)
  const buttonClasses = useButtonStyles()
  const [showDelayForm, setShowDelayForm] = useState(false);

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.wallet) {
      errors.wallet = "Required";
    }

    return errors;
  };

  const handleCreatePocket = async (values: FormValues) => {
    try {
      let data = {
        ...values,
        targetAmount: values.targetAmount ? removeCommas(values.targetAmount) : null,
        delay: values.add_delay == true ? parseFloat(values.delay) : 0,
        wallet: values.wallet as number
      }
      const pocket = await createPocket(data)
      history.push(`/pockets/pocket/${pocket.id}`)

    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (values: FormValues) => {
    setOpenDialog(true)
  };

  const walletOptions = isLoading || isError ? [] : data.map((wallet) => ({
    value: wallet?.id,
    label: wallet?.name,
  }));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        add_delay: false,
        targetAmount: 0
      }}
      validate={validate}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ px: 2, }}>
            <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 2 }}>
              <IconButton onClick={() => history.goBack()} sx={{ p: 0 }}>
                <ArrowBackOutlinedIcon />
              </IconButton>
              <Typography variant="body1" fontWeight={700} sx={{ m: '0 auto' }}>
                Create Pocket
              </Typography>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2, mt: 2, maxWidth: 400, margin: '32px auto' }}>
              <Field
                label="Pocket Name"
                name="name"
                type="text"
                component={FormInput}
              />
              <Box >
                <Typography variant='body1' sx={{ marginBottom: '4px', fontWeight: 600 }}>Select holding wallet</Typography>
                <Field
                  type="select"
                  name="wallet"
                  component={Select}
                  options={walletOptions}
                />
              </Box>
              <Field name="targetAmount">
                {(props: any) => <FormInput
                  label="Any target amounts ?(optional)"
                  type="text"
                  value={numberWithCommas(props.input.value)}
                  onChange={(e) => {
                    props.input.onChange(removeCommas(e.target.value));

                  }}
                  {...props}
                />}
              </Field>
              <Box>
                <Typography variant="body1" fontWeight={600}>Do you want to add a delay/gap to the time taken to withdraw funds from this pocket?</Typography>
                <Field name="add_delay" type="radio">
                  {(props: any) => <RadioButtons
                    options={adminOptions}
                    value={props.input.value}
                    onChange={(e) => {
                      props.input.onChange(e.target.value);
                      setShowDelayForm(e.target.value === "true");
                    }}
                    {...props}
                  />}
                </Field>
              </Box>

              {showDelayForm && (
                <>
                  <Box>
                    <Typography variant='body1' fontWeight={600}>Time taken to withdraw funds upon request (hrs)</Typography>
                    <Field name="delay">
                      {(props) => (
                        <FormInput
                          {...props}
                          type="number"
                          placeholder="Custom delay"
                          fullWidth
                          InputProps={{
                            endAdornment: 'hrs',
                          }}
                          sx={{ flex: 0.6, mb: 0.5 }}
                        />
                      )}
                    </Field>
                  </Box>
                  <Divider>or selecting existing duration</Divider>
                  <Field name="delay">
                    {({ input, meta }) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {delayOptions.map((option, i) => (
                          <Box sx={{ p: 2, cursor: 'pointer', border: option.value === input.value ? '1px solid #673AB7' : '1px solid #C4CFD4', borderRadius: '8px', mr: 2 }} onClick={() => input.onChange(option.value)} key={i}>
                            <Typography fontWeight={600}>{option.label}</Typography>
                          </Box>
                        ))}
                        <FormHelperText sx={{ color: 'error.main' }}>
                          {meta?.touched ? meta?.error || meta?.submitError : ""}
                        </FormHelperText>
                      </Box>
                    )}
                  </Field>
                </>
              )}

              <Alert severity="info" sx={{ px: 1 }} icon={false} >
                <AlertTitle sx={{ display: 'flex', alignItems: 'basline', gap: 0.4 }}>
                  <InfoOutlinedIcon fontSize='small' sx={{ fontSize: 16 }} />
                  <Typography variant='body2' sx={{ textDecoration: 'underline' }}>Note:</Typography>
                </AlertTitle>
                <Typography variant='caption'>
                  The higher the time delay/gap on funds withdraw, the more likely it is for you to earn some interest bonus (0-10%) on your pocket funds
                </Typography>
              </Alert>
              <ModalWrapper open={open} onClose={() => setOpenDialog(!open)}>
                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant='body1' fontWeight={600} sx={{ m: '0 auto', textAlign: 'center' }}>Pocket Preview</Typography>
                  <IconButton onClick={() => setOpenDialog(!open)}><CloseIcon /></IconButton>
                </DialogTitle>
                <DialogContent sx={{ px: 1 }}>
                  <Box sx={{ px: 1, backgroundColor: '#E4F7E8', padding: `20px 14px`, borderRadius: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant='body1'>Pocket name</Typography>
                      <Typography sx={{ fontWeight: 600 }}>{values.name}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant='body1'>Target Amount</Typography>
                      <Typography sx={{ fontWeight: 600 }}>  {values.targetAmount ? `${numberWithCommas(values.targetAmount)} UGX` : 'N/A'}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant='body1'>Interest rate</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {`0-10%`}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant='body1'>Saving Frequency</Typography>
                      <Typography sx={{ fontWeight: 600 }}>Anytime </Typography>
                    </Box>
                    {values.add_delay && (
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='body1'>Withdraw delay</Typography>
                        <Typography sx={{ fontWeight: 600 }}>{`${values.add_delay == true ? values.delay :0} hrs`} </Typography>
                      </Box>
                    )}
                  </Box>
                  {error && <Alert severity="error">{(error as Error).message}</Alert>}
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDialog(false)}
                      variant="outlined"
                      title={'Cancel'}
                    />
                    <Button
                      variant="contained"
                      title={'Submit'}
                      onClick={() => handleCreatePocket(values)}
                    />

                  </DialogActions>
                </DialogContent>
              </ModalWrapper>

              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                pt: 2,
                justifyContent: 'center'
              }}>

                <Button
                  type='submit'
                  variant="contained"
                  title={'Next'}
                  className={buttonClasses.mediumButton}
                  sx={{ maxWidth: '250px' }}
                />
              </Box>


            </Box>
          </Box>
        </form>
      )}
    />
  )
}
