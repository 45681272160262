import React, { Key, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '../../components/Reusables/Input/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useGoalsInCommon, usePublicByMember } from '../../hooks/goalHooks';
import { useHistory, } from 'react-router-dom';
import { capitalizeWords, numberWithCommas } from '../../utils/middleware';
import { GoalItem as Goal, GoalMember, WalletMember, PocketMember } from "../../types/types";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AddToGoal from '../../components/members/AddToGoal';
import { StyledTabs, StyledTab, CustomTabPanel } from '../../pages/goals/GoalTabs';
import SendMoneyStepper from '../Modals/SendMoney';
import { useUserWalletTotal } from '../../hooks/balanceHooks';
import theme from "../../theme";

interface MemberProfileProps {
  memberData: GoalMember | WalletMember | PocketMember ;
  memberType: string;
  isLoading: boolean;
  isError: boolean
}

const Container = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      margin: "0 auto",
      width: "100%",
      [theme.breakpoints.down('md')]: {
        maxWidth: "600px",
        minWidth: "500px",
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: "none",
        minWidth: "auto",
        width: 'auto',
      }
    }}>
    {children}
  </Box>
);

const SavingListItem = (
  { item, onClick }: { item: Goal , onClick: (e: React.MouseEvent<HTMLLIElement>) => void }
) => (
  <ListItem alignItems="flex-start" sx={{ px: 0 }} onClick={onClick}>
    <ListItemAvatar>
      <Avatar alt={'profile image'} src={item.image_url ?? undefined} sx={{ bgcolor: "primary.main" }}>{item.name.charAt(0)}</Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, width: "100%" }}>
          <Typography variant='body2' fontWeight={600}>{item.name}</Typography>
          {
            item.revolving && (<AutorenewIcon color='primary' fontSize='small' />)
          }
        </Box>
      }
      secondary={
        <Box sx={{
          display: 'flex', alignItems: {
            xs: "flex-start",
            sm: "center",
            md: "center",
            lg: "center",
            xl: "center"
          }, gap: 0.5, flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row"
          }
        }} component="span">
          <Typography
            sx={{ display: 'inline' }}
            variant="body2"
            component="span"
            color="text.primary"
          >
            {`${item.members} members`}
          </Typography>

          <Box height="0.25rem" width="0.25rem" borderRadius="60%" sx={{
            backgroundColor: "#D9D9D9", display: {
              xs: "none",
              sm: "inline",
            }
          }} />

          <Box display="flex" alignItems={"baseline"} gap={"4px"}>
            <Typography fontWeight={600} variant='body2' color='text.primary' component="span">{`${numberWithCommas(item.amount_saved)}`}</Typography>
            <Typography variant='overline' color='text.primary' component="span">{item.currency}</Typography>
            <Typography variant='body2' color='text.primary' component="span">{`saved`}</Typography>
          </Box>
        </Box>
      }
    />
  </ListItem>
);


const GroupsInCommonTab = ({ userId }: { userId: number }) => {
  const [goalsListData, setGoalsListData] = useState([]);
  const { isLoading, isError, data } = useGoalsInCommon(userId);
  const history = useHistory();

  useEffect(() => {
    if (data && goalsListData.length === 0) {
      setGoalsListData(data);
    }
  }, [data]);

  return (
    <Box>
      <List>
        {goalsListData.length === 0 ?
          <Typography variant="body1" color="text.secondary" textAlign={"center"} sx={{ py: 1, display: "flex", justifyContent: "center" }}>No channels in common</Typography>
          : goalsListData.map((item: Goal, index: Key) => (
            <SavingListItem
              item={item}
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
               
                  history.push(`/goals/goal/${item.id}`);
                
              }}
            />
          ))}
      </List>

      {isError || isLoading && (
        <Skeleton width="100%">
          <Typography>.</Typography>
        </Skeleton>
      )}
    </Box>
  );
}

const PublicGoalsTab = ({ userId }: { userId: number }) => {
  const [goalsListData, setGoalsListData] = useState<Goal[]>([]);;
  const { isError, isLoading, data } = usePublicByMember(userId);
  const history = useHistory();

  useEffect(() => {
    if (data && goalsListData.length === 0) {
      setGoalsListData(data);
    }
  }, [data]);

  return (
    <Box>
      <List>
        {goalsListData.length === 0 ?
          <Typography variant="body1" color="text.secondary" sx={{ py: 1, display: "flex", justifyContent: "center" }}>No public goals</Typography>
          : goalsListData.map((item: Goal, index: Key) => (
            <SavingListItem
              item={item}
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
               
                  history.push(`/goals/goal/${item.id}`);
                
              }}
            />
          ))}
      </List>

      {isError || isLoading && (
        <Skeleton width="100%">
          <Typography>.</Typography>
        </Skeleton>
      )}
    </Box>
  );
};



const MemberProfile: React.FC<MemberProfileProps> = ({ memberData, memberType, isError, isLoading }) => {
  const [member, setMember] = useState<GoalMember | WalletMember | PocketMember >(memberData || {});
  const history = useHistory()
  const [openSend, setOpenSend] = useState(false)
  const { data } = useUserWalletTotal();
  const [value, setValue] = useState(0);
  const [openAddGoal, setAddGoal] = useState(false);
 

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (memberData && memberData !== member) {
      setMember({
        ...memberData,
        users: {
          first_name: memberData.users?.first_name || "",
          last_name: memberData.users?.last_name || "",
          username: memberData.users?.username || "",
          profile_img: memberData.users?.profile_img || "",
          description: memberData.users?.description || "",
          id: memberData.users?.id
        }
      });
    }
  }, [memberData]);

  const handleCopyUsername = async () => {
    try {
      if (member.users?.username) {
        // Use the Clipboard API to copy the username to the clipboard
        await navigator.clipboard.writeText(member.users?.username);
      }
    } catch (error) {
      console.error('Error copying username to clipboard:', error);
    }
  };

 

  const handleAddToGoal = () => {
    setAddGoal(true)
  };

  return (
    <Box>
      <Container>
        <IconButton onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </IconButton>

        <Box sx={{
          margin: "0 auto",
          width: "100%",
          maxWidth: "29.8125rem",
          px: "2.58rem",
          [theme.breakpoints.down('sm')]: {
            px: "16px"
          }
        }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={3}
          >
            {isLoading || isError ? (
              <Skeleton variant="circular">
                <Avatar sx={{ width: 80, height: 80 }} />
              </Skeleton>
            ) : (
              <>
                <Avatar sx={{ width: 80, height: 80 }} src={member.users?.profile_img} alt={member.users?.first_name} />
                {member?.users && member.users?.first_name && member.users?.last_name && (<Typography variant="h4" textAlign="center" mt={2}>{`${capitalizeWords(member.users?.first_name)} ${capitalizeWords(member.users?.last_name)}`}
                </Typography>)}

                <Box display="flex" alignItems="center">
                  <Typography variant="body2">
                    {member.users?.username}
                  </Typography>
                  <IconButton onClick={handleCopyUsername}>
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Container>

      <Box width="100%" height="0.375rem" sx={{ backgroundColor: "#eee" }} />

      <Container>
        <Box sx={{
          margin: "0.75rem auto",
          width: "100%",
          maxWidth: "29.8125rem",
          px: "2.58rem",
          [theme.breakpoints.down('sm')]: {
            px: "16px"
          }
        }}>
          <Box>
            <Typography variant="body1" fontWeight={600} gutterBottom>
              Bio
            </Typography>
            <Typography variant="body1" gutterBottom>
              {member.users?.description ? member.users?.description : (isLoading || isError) ? <Skeleton width="100%">
                <Typography>.</Typography>
              </Skeleton> : "No bio yet"}
            </Typography>
          </Box>

        </Box>
      </Container>

      <Box width="100%" height="0.375rem" sx={{ backgroundColor: "#eee" }} />

      

      <Box width="100%" height="0.375rem" sx={{ backgroundColor: "#eee" }} />

      <Container>
        <Box sx={{
          margin: "0.75rem auto",
          width: "100%",
          maxWidth: "29.8125rem",
          px: "2.58rem",
          [theme.breakpoints.down('sm')]: {
            px: "16px"
          }
        }}>
          <List dense>
            <ListItem button onClick={handleAddToGoal} sx={{ pl: 0 }}>
              <ListItemIcon>
                <LockOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Add to Goal" />

            </ListItem>
          
          </List>

        </Box>
      </Container>

      <Box width="100%" height="0.375rem" sx={{ backgroundColor: "#eee" }} />

      <Container>
        {!isError && !isLoading && (
          <Box marginTop="0.69rem" sx={{
            margin: "0.75rem auto",
            width: "100%",
            maxWidth: "29.8125rem",
            px: "2.58rem",
            [theme.breakpoints.down('sm')]: {
              px: "16px"
            }
          }}>
            <Typography mb={1} variant='button' display={"flex"} justifyContent={"center"} alignItems={"center"}>{`Other saving channels* that ${member.users?.first_name} is part of`}</Typography>
            {
              member.user && (
                <>
                  <Box display="flex" flexDirection={"column"} alignItems={"center"} width="100%">
                    <StyledTabs
                      value={value} onChange={handleChange} aria-label="goal member tabs"
                    >
                      <StyledTab label="In Common" />
                      <StyledTab label="Public Goals" />
                     
                    </StyledTabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <GroupsInCommonTab userId={member.user} />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <PublicGoalsTab userId={member.user} />
                  </CustomTabPanel>
                 
                </>
              )}
          </Box>
        )}
      </Container>

      {openAddGoal && (<AddToGoal member={member} open={openAddGoal} onClose={() => setAddGoal(false)} />)}
     
      
    </Box>
  );
};

export default MemberProfile;


