import React from 'react';
import { Dialog, DialogTitle, DialogContent,  Box, Typography, useMediaQuery, useTheme,  IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogContainerProps {
  width?: string;
  padding?: string;
}

export const DialogContainer = styled(Dialog)<DialogContainerProps>(({ theme, width, padding }) => ({
  '& .MuiDialog-container .MuiDialog-paperScrollPaper' :{
    position: 'absolute',
    margin: "auto",
    bottom: 0,
    left: 0,
    width: width ? width : '100%',
    borderRadius:'24px 24px 0px 0px',
    minHeight: width ? "none" : '60vh',
    padding: padding ? padding : "24px",
    [theme.breakpoints.up('sm')]: {
      position: 'static',
      width: 'auto',
      borderRadius:theme.spacing(1),
      padding: padding ? padding : "24px 16px 0px 16px",
      marginBottom: "12px"
    },
    [theme.breakpoints.up('md')] :{
      width: width ? width : '600px',
      padding: padding ? padding : "24px 24px 48px 24px"
    },
    [theme.breakpoints.up('lg')]:{
      width: width ? width : '560px',
    }
  },
}));

export const DialogHeader = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(0)} ${theme.spacing(2)}`,

}));

interface InfoDialogProps {
  title?: string;
  content: any;
  open: boolean;
  handleClose: () => void;
  width?: string;
  padding?: string;
}

const InfoDialog: React.FC<InfoDialogProps> = ({ title, content, open, handleClose, width, padding }) => {

  return (
      <DialogContainer open={open} onClose={handleClose} TransitionComponent={Transition} width={width} padding={padding}>
       <DialogHeader>
        <InfoOutlinedIcon color='inherit'/>
        {title && <Typography fontWeight={600}>{title}</Typography>}
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogHeader>
        <DialogContent>
          {content}
        </DialogContent>
      </DialogContainer>
  );
};

export default InfoDialog;
