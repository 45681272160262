import React, { useState } from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';
import { Box, IconButton, styled, Tab, Tabs, Typography, useMediaQuery, Theme, Drawer } from '@mui/material';
import { CustomTabPanel } from '../goals/GoalTabs';
import { numberWithCommas } from '../../utils/middleware';
import Button from '../../components/Reusables/Input/Button';
import dayjs from 'dayjs';
import RepayLoan from '../../components/loans/RepayLoan';
import { useLoanDetails, useLoans } from '../../hooks/loanHooks';
import { Loan } from '../../types/types';
import TransactionsSkeleton from '../../components/Reusables/Feedback/skeletons/TransactionsSkeleton';
import IncreaseLimitPopup from '../../components/Modals/IncreaseLimitPopup';
import ModalWrapper from '../../components/Modals/ModalWrapper';
import { LoanContent } from './LoanPage';

const LOAN_REPAY_STATUS_DOTS = {
  green_dot: '#006703',
  blue_dot: '#021AEE',
  orange_dot: '#EF6C00'
}

const StyledTab = styled((props: any) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(16),
  marginRight: theme.spacing(1),
  padding: theme.spacing(0.5),
  height: theme.typography.pxToRem(32),
  borderRadius: theme.spacing(0.5),
  minHeight: 'unset',
  minWidth: '60px',
  color: theme.palette.grey[600],
  '&.Mui-selected': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '24px'

  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const LoanHistory = () => {
  const history = useHistory()
  const [value, setValue] = useState(0);
  const { data, isLoading } = useLoans();
  //this is such that we can also display pending goals for the meantime
  const ongoingLoans = (data ?? []).filter(loan => loan.status !== 'cleared').sort((loanA, loanB) => dayjs(loanB.created_at).diff(dayjs(loanA.created_at)));
  const clearedLoans = (data ?? []).filter(loan => loan.status === 'cleared').sort((loanA, loanB) => dayjs(loanB.cleared_at).diff(dayjs(loanA.cleared_at)));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 2, px: 2 }}>
        <IconButton onClick={() => history.push('/loans')} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="body1" fontWeight={700} sx={{ m: '0 auto' }}>
          Loan History
        </Typography>
      </Box>
      <Box sx={{ px: 2 }}>
        <Tabs value={value} onChange={handleChange} aria-label="loan tabs" sx={{ '& .MuiTabs-indicator': { height: 0 }, mt: 2 }}>
          <StyledTab label="Ongoing loans" />
          <StyledTab label="Cleared loans" />
        </Tabs>

        {isLoading ?
          <TransactionsSkeleton />
          :
          <>
            <CustomTabPanel value={value} index={0}>
              <PendingLoans loans={ongoingLoans} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ClearedLoans loans={clearedLoans} />
            </CustomTabPanel>
          </>
        }

      </Box>
    </div>
  )
}

export default LoanHistory

export const loanCardStyles = {
  display: 'flex',
  padding: '0.375rem',
  flexDirection: 'column',
  gap: '8px',
  borderRadius: '0.375rem',
  background: '#F5F5F5',
  minWidth: '300px',
  marginBottom: "1.25rem"
};

const PendingLoans: React.FC<{ loans: Loan[] }> = ({ loans }) => {
  const [openRepay, setOpenRepay] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState<Loan>()
  const history = useHistory()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [openLimit, setOpenLimit] = useState(false);
  const { data, isLoading, isError } = useLoanDetails();

  const handleRepay = (loan: Loan) => {
    setOpenRepay(!openRepay)
    setSelectedLoan(loan)
  }

  const handleLoan = (loan: Loan) => {
    setIsSettingsOpen(!isSettingsOpen)
    setSelectedLoan(loan)
    if(!isDesktop){
    history.push(`/loans/${loan.id}`)
    }
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: "0.75rem" }} border="1px solid #F1F2F3" borderRadius={"0.75rem"} padding="0.5rem 0.75rem">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: "0.3125rem 0rem" }}>
          <Typography variant="body1">Saver loan limit</Typography>
          <Typography variant="body1">{numberWithCommas(isLoading || isError ? 0 : data.loanLimit)}
            <Typography variant='overline' component='span'>{` UGX`}</Typography></Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="body1" fontWeight={600}>Total current loans</Typography>

          <Box sx={{ display: "flex", gap: "4px", alignItems: "baseline" }}>
            <Typography variant="body1" fontWeight={700}>-{numberWithCommas(isLoading || isError ? 0 : data.totalBorrowed)}</Typography>
            <Typography variant="overline">UGX</Typography>
          </Box>
        </Box>
      </Box>
      <Button title='Increase limits' variant='outlined' sx={{ borderRadius: '50px', height: '2.125rem', mx: "auto", my: "1.0625rem", display: "flex", backgroundColor: "#F0EBF8", border: "0" }} onClick={() => setOpenLimit(!openLimit)} />
      <IncreaseLimitPopup open={openLimit} handleClose={() => setOpenLimit(false)}>
        <Box marginBottom={"1.88rem"}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: "1" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="body1" fontWeight={600} style={{ marginBottom: "0.5rem" }}>Saver loan limits</Typography>
            </Box>
            <Box display="flex" flexDirection={"column"} sx={{ gap: "0.9375rem" }}>
              <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.1875rem" }}>
                <Typography variant="body2" fontWeight={600}>Limit amount</Typography>
                <Box sx={{ display: "flex", gap: "4px", alignItems: "baseline" }}>
                  <Typography variant="h4" fontWeight={600}>{numberWithCommas(isError || isLoading ? 0 : data?.loanLimit)}</Typography>
                  <Typography variant="overline">UGX</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.1875rem" }}>
                <Typography variant="body2" fontWeight={600}>Limit percentage</Typography>
                <Typography variant="body1">~<strong>40%</strong> of goal savings</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.1875rem" }}>
                <Typography variant="body2" fontWeight={600}>Goal savings (TLV)</Typography>
                <Box sx={{ display: "flex", gap: "4px", alignItems: "baseline" }}>
                  <Typography variant="h4">{numberWithCommas(isError || isLoading ? 0 : ((data?.loanLimit) / 0.4).toFixed(0))}</Typography>
                  <Typography variant="overline">UGX</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </IncreaseLimitPopup>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center' }}>
        {loans.length === 0 && <Box display="flex" justifyContent="center" alignItems="center" height="40vh" marginX="auto"><Typography variant="body1" color="grey.400" textAlign="center">No ongoing loans yet</Typography></Box>}
        {loans.map(loan => (
          <Box key={loan.id} onClick={()=>handleLoan(loan)}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: "0.5rem", marginBottom: "0.44rem" }}>
              <Typography variant="body2">{dayjs(loan.created_at).format("ddd, DD MMM YY")}</Typography>
              <Box width="3px" height="3px" borderRadius={"999px"} sx={{
                background: "#B7B7B7"
              }} />
              <Typography variant="body2" textTransform={"uppercase"}>{dayjs(loan.created_at).format("h:mma")}</Typography>
            </Box>
            <Box sx={{ ...loanCardStyles }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>{`Loan ID PB00${loan.id}G`}</Typography>
                <Typography>{numberWithCommas(`${loan.loan_amount}`)}
                  <Typography variant='overline' component='span'>{`${loan.currency}`}</Typography></Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>Cleared</Typography>
                <Typography>
                  {`${((loan.amount_paid / loan.loan_amount) * 100).toFixed(0)}%`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>Amount to pay</Typography>
                <Typography variant='h6' color='error' sx={{ fontWeight: 600 }}>{numberWithCommas(loan.loan_amount - loan.amount_paid)}
                  <Typography variant='caption' component='span'>{` ${loan.currency}`}</Typography></Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>Due</Typography>
                <Typography>{dayjs(loan.due_date).diff(dayjs(), 'days')} days</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>Available Balance</Typography>
                <Typography>{numberWithCommas(isError || isLoading ? 0 : data?.amountLeftToBorrow)}
                  <Typography variant='overline' component='span'>{` ${loan.currency}`}</Typography></Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button title='Repay' variant='contained' sx={{ minWidth: '120px' }} onClick={() => handleRepay(loan)} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <ModalWrapper open={openRepay && !isDesktop} onClose={() => setOpenRepay(false)}>
        <RepayLoan open={openRepay} onClose={() => setOpenRepay(false)} loanId={selectedLoan?.id as number} maxAmount={(selectedLoan?.loan_amount || 0) - (selectedLoan?.amount_paid || 0)} />
      </ModalWrapper>
      <Drawer anchor="right" open={openRepay && isDesktop} onClose={() => setOpenRepay(false)} sx={{
        '& .MuiPaper-root.MuiDrawer-paper': {
          width: '400px'
        }
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <RepayLoan open={openRepay} onClose={() => setOpenRepay(false)} loanId={selectedLoan?.id as number} maxAmount={(selectedLoan?.loan_amount || 0) - (selectedLoan?.amount_paid || 0)} />
        </Box>
      </Drawer>
      <Drawer anchor="right" open={isSettingsOpen && isDesktop } onClose={() => setIsSettingsOpen(false)} sx={{
        '& .MuiPaper-root.MuiDrawer-paper': {
          width: '400px',
          padding:'16px'
        }
      }}>
        {selectedLoan &&   <LoanContent loan={selectedLoan}/>}
      </Drawer>

    </Box>
  )
}

export const getLoanStatusDot=(loan: Loan)=> {
    // loan duration
    const loanDuration = Math.abs(dayjs(loan.due_date).diff(dayjs(loan.created_at), 'day'));

    // if loan cleared at is greater than due date, then it was cleared past due date
    if (dayjs(loan.cleared_at).isAfter(dayjs(loan.due_date))) {
      return <Box display="flex" alignItems={"center"} gap="0.31rem" border={`1px solid ${LOAN_REPAY_STATUS_DOTS.green_dot}`} borderRadius={"1.5rem"} height="1.3125rem" padding="0.625rem" fontSize="16px" fontWeight={700}><Box width="0.625rem" height="0.625rem" borderRadius={"999px"} sx={{ background: `${LOAN_REPAY_STATUS_DOTS.orange_dot}` }} /> {'100%'}</Box>;
    }

    // if loan cleared at is before due date and difference between cleared at and loan was not cleared in the last 20% of the loan duration
    if (dayjs(loan.cleared_at).isBefore(dayjs(loan.due_date)) && (dayjs(loan.cleared_at).diff(dayjs(loan.due_date)) < 0.2 * loanDuration)) {
      return <Box display="flex" alignItems={"center"} gap="0.31rem" border={`1px solid ${LOAN_REPAY_STATUS_DOTS.green_dot}`} borderRadius={"1.5rem"} height="1.3125rem" padding="0.625rem" fontSize="16px" fontWeight={700}><Box width="0.625rem" height="0.625rem" borderRadius={"999px"} sx={{ background: `${LOAN_REPAY_STATUS_DOTS.green_dot}` }} /> {'100%'}</Box>;
    }

    // if loan cleared at is before due date and loan was cleared in the last 20% of the loan duration
    if (dayjs(loan.cleared_at).isBefore(dayjs(loan.due_date)) && (dayjs(loan.cleared_at).diff(dayjs(loan.due_date)) > 0.2 * loanDuration)) {
      return <Box display="flex" alignItems={"center"} gap="0.31rem" border={`1px solid ${LOAN_REPAY_STATUS_DOTS.green_dot}`} borderRadius={"1.5rem"} height="1.3125rem" padding="0.625rem" fontSize="16px" fontWeight={700}><Box width="0.625rem" height="0.625rem" borderRadius={"999px"} sx={{ background: `${LOAN_REPAY_STATUS_DOTS.blue_dot}` }} /> {'100%'}</Box>;
    }

    return ''
  }

const ClearedLoans: React.FC<{ loans: Loan[] }> = ({ loans }) => {
  const totalClearedLoanAmount = loans.reduce((acc, loan) => acc + Number(loan.loan_amount), 0);
  const history = useHistory()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [selectedLoan, setSelectedLoan] = useState<Loan>()
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

   const handleLoan = (loan: Loan) => {
    setIsSettingsOpen(!isSettingsOpen)
    setSelectedLoan(loan)
    if(!isDesktop){
    history.push(`/loans/${loan.id}`)
    }
  }
  

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: "0.75rem" }} border="1px solid #F1F2F3" borderRadius={"0.75rem"} padding="0.5rem 0.75rem">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="body1" fontWeight={600}>Total cleared</Typography>
          <Box sx={{ display: "flex", gap: "4px", alignItems: "baseline" }}>
            <Typography variant="body1" fontWeight={700}>{numberWithCommas(!loans ? 0 : totalClearedLoanAmount)}</Typography>
            <Typography variant="overline">UGX</Typography>
          </Box>
        </Box>
      </Box>

      {loans.length === 0 ? <Box display="flex" justifyContent="center" alignItems="center" height="40vh" marginX="auto"><Typography variant="body1" color="grey.400" textAlign="center">No cleared loans</Typography></Box> :
        loans.map(loan => (
          <React.Fragment key={loan.id}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: "0.5rem", marginBottom: "0.44rem" }}>
              <Typography variant="body2">{dayjs(loan.cleared_at).format("ddd, DD MMM YY")}</Typography>
              <Box width="3px" height="3px" borderRadius={"999px"} sx={{
                background: "#B7B7B7"
              }} />
              <Typography variant="body2" textTransform={"uppercase"}>{dayjs(loan.cleared_at).format("h:mma")}</Typography>
            </Box>
            <Box sx={{ ...loanCardStyles }} onClick={()=>handleLoan(loan)}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body1">{`Loan ID PB00${loan.id}G`}</Typography>
                <Typography variant="body1" fontWeight={600}>{numberWithCommas(loan.loan_amount)}
                  <Typography variant='overline' component='span'>{` ${loan.currency}`}</Typography></Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body1">Cleared</Typography>
                {getLoanStatusDot(loan)}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body1">Cleared in</Typography>
                <Typography variant="body1">{loan.cleared_at ? (
                  `${Math.abs(dayjs(loan.cleared_at).diff(dayjs(loan.created_at), 'day'))}/${Math.abs(dayjs(loan.due_date).diff(dayjs(loan.created_at), 'day'))} days`
                ) : (
                  'Cleared date not available'
                )}
                </Typography>

              </Box>
            </Box>
          </React.Fragment>
        ))}
          <Drawer anchor="right" open={isSettingsOpen && isDesktop } onClose={() => setIsSettingsOpen(false)} sx={{
        '& .MuiPaper-root.MuiDrawer-paper': {
          width: '400px',
          padding:'16px'
        }
      }}>
        {selectedLoan &&   <LoanContent loan={selectedLoan}/>}
       
      </Drawer>
    </Box>
  )
}