import React, { useState } from 'react';
import { Stepper, Step, StepLabel, StepConnector, Typography, Box, DialogTitle, Avatar, Alert, DialogActions } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import SecurityQuestions from '../../components/profile/SecurityQuestions';
import FormInput from '../../components/Reusables/Input/FormInput';
import Button from '../../components/Reusables/Input/Button';
import { useDeleteUser } from '../../hooks/userHooks';
import { DialogContainer } from '../../components/Reusables/Feedback/InfoDialog';
import DoneIcon from '@mui/icons-material/Done';
import { Link, useHistory } from 'react-router-dom';

export const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
    
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor: `#D9D9D9`,
   
    
  },
}))(StepConnector);

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding:'8px 16px'
  },
});


export function SecurityQuestionsStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [pin, setPin] = useState('');
  const [password, setPassword] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const history = useHistory()


  const { deleteUser, isLoading, error } = useDeleteUser();

  const handleDelete = async () => {
    try {
      await deleteUser({ pin, password });
      // Handle successful deletion, e.g., show a success message
      setIsSuccessModalOpen(true);

    } catch (error) {
      // Handle error, e.g., show an error message
    } finally {
    }
  };

  const handlePinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPin(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };



 const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ['Security Questions', 'PIN','Password',''];

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
        sx={{minHeight:'32px'}}
      >
        {steps.map((label) => (
          <Step key={label}>
          </Step>
        ))}
       
      </Stepper>
      {activeStep === 0 && (
        <SecurityQuestions
          onNext={handleNext}
        />
      )}
      {activeStep === 1 && (
        <Box>
        <Typography fontWeight={600} >Confirm PIN to continue</Typography>
        <FormInput
        fullWidth
        label=""
        value={pin}
        onChange={handlePinChange}
        variant='standard'
      />
      <Box mt={3} sx={{textAlign:'center'}}>
        <Button title='Cancel' onClick={handleBack} sx={{backgroundColor:'#F0EBF8',mr:1.5}}/>
        <Button title='Next' onClick={handleNext} variant='contained'/>

      </Box>
        </Box>
      )}
      {activeStep === 2 && (
        <Box>
        <Typography fontWeight={600}>Confirm Password to continue</Typography>
        <FormInput
        fullWidth
        label=""
        value={password}
        onChange={handlePasswordChange}
        variant='standard'
      />
      {error && <Alert severity='error'>{(error as Error).message}</Alert>}
       <Box mt={3} sx={{textAlign:'center'}}>
        <Button title='Cancel' onClick={handleBack} sx={{backgroundColor:'#F0EBF8',mr:1.5}}/>
        <Button title='Confirm' onClick={handleDelete} variant='contained' disabled={isLoading} loading={isLoading}/>

      </Box>
        </Box>
      )}
      <DialogContainer open={isSuccessModalOpen} onClose={()=>setIsSuccessModalOpen(false)}>
        <Box>
          <DialogTitle>
            <Avatar sx={{ bgcolor: '#22C55E' }}><DoneIcon/></Avatar>
          </DialogTitle>
          <Typography variant="h5" mt={2} sx={{textAlign:'center'}}>{`Bye-bye for now!`}</Typography>
          <Typography sx={{textAlign:'center'}}>{`We have deleted all your application history. To rejoin the application, you will need to signup a fresh.`}</Typography>
          <DialogActions>
          <Button title='Close' sx={{width:'260px',mt:4}} onClick={()=>history.push(`/auth/register/email`)} variant='contained'/>
          </DialogActions>
        </Box>
      </DialogContainer>
    </div>
  );
}
