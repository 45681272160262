import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react'
import Collapsible from 'react-collapsible';
import { Payouts, Transaction } from '../../types/types';
import { capitalizeWords, numberWithCommas } from '../../utils/middleware';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import dayjs from 'dayjs';
import { UseInfiniteQueryResult } from '@tanstack/react-query';


interface PayoutTransactionsProps {
  revolvingPayouts?: UseInfiniteQueryResult<any, unknown>,

}

const PayoutTransactions: React.FC<PayoutTransactionsProps> = ({ revolvingPayouts }) => {


  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  }

  if (revolvingPayouts?.isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (revolvingPayouts?.isError) {
    return <Typography>Error fetching payout history</Typography>;
  }
  // Access the total amount directly
  const totalAmount = revolvingPayouts?.data?.pages[0]?.total || 0;

  return (
    <Box>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='body1'>Total paid out</Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
            <Typography variant='body1' fontWeight={700}>{`- ${numberWithCommas(totalAmount)}`}</Typography>
            <Typography variant='overline'>{`UGX`}</Typography>
          </Box>
        </Box>

      </Box>
      <Typography variant="body1" fontWeight={600} marginBottom={"0.62rem"} marginTop="1.56rem">Transactions</Typography>
      {!revolvingPayouts ?
        <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><Typography variant="body1" color="grey.400" textAlign="center">No revolving transactions</Typography></Box>
        : revolvingPayouts?.data.pages.map((page, pageIndex) => (
          <React.Fragment key={pageIndex}>
            {page.payouts.length === 0 ?
              <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><Typography variant="body1" color="grey.400" textAlign="center">No transactions yet</Typography></Box>
              : page.payouts.map((payout: Payouts, index: number) => (
                <Box key={payout.id}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography fontWeight={600}>{`Round ${index + 1}`}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
                      <Typography fontWeight={600}>{`-${numberWithCommas(payout.amount)}`}</Typography>
                      <Typography variant='overline'>{payout.currency}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Collapsible trigger={
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                          <Typography variant='caption' sx={{ color: 'grey.700' }}>
                            {`${dayjs(payout.arrival_date)?.locale('en').format('ddd, DD MMM YY')}`}
                          </Typography>
                          {isExpanded ? (<KeyboardArrowUp />) : (<KeyboardArrowDown />)}
                        </Box>
                        <Typography variant='caption' sx={{ color: 'grey.700' }}>{`${payout.transactions.length} recipients`}</Typography>
                      </Box>
                    } onOpening={handleToggle} onClosing={handleToggle} transitionTime={200}>
                      <Box sx={{ my: 1 }}>
                        {payout.transactions.map((transaction) => (
                          <PayoutTransaction transaction={transaction} />
                        ))}</Box>
                    </Collapsible>
                  </Box>
                </Box>
              ))}
          </React.Fragment>
        ))}

      {revolvingPayouts?.hasNextPage && (
        <Button
          onClick={() => revolvingPayouts?.fetchNextPage()}
          title={revolvingPayouts?.isFetchingNextPage ? 'Loading more...' : 'Load more'}
          disabled={revolvingPayouts?.isFetchingNextPage}
        />
      )}
    </Box>
  )
}

interface PayoutTransactionProps {
  transaction: Transaction;
}

const PayoutTransaction: React.FC<PayoutTransactionProps> = ({ transaction }) => {
  const { amount, currency, status, type, updated_at, name, user_name } = transaction;
  let color, bgColor;
  if (status === 'completed') {
    color = '#006703';
    bgColor = '#E4F7E8'
  } else if (status === 'pending') {
    color = '#EF6C00';
    bgColor = '#FFF3E0'
  } else if (status === 'ongoing') {
    color = '#021AEE';
    bgColor = '#F0F2FB'
  } else if (status === 'failed') {
    color = '#B7000C';
    bgColor = '#FFEAED'
  } else {
    color = '#F32E26';
  }
  return (
    <Box key={transaction.id}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
        <Typography variant='body1' >
          {user_name && capitalizeWords(user_name)}
        </Typography>
        <Box sx={{ display: '-webkit-flex', alignItems: 'baseline', gap: 0.4 }}>
          <Typography variant='h5' sx={{ fontWeight: 600 }}>
            {transaction.amount && numberWithCommas(amount)}
          </Typography>
          <Typography variant='overline'>{currency}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <Typography fontWeight={600} variant='body1'>Payout withdraw</Typography>
          <Typography variant='body2'>{dayjs(updated_at).format('h:mma')}</Typography>
        </Box>
        <Typography variant='caption' sx={{ color, backgroundColor: bgColor, p: 0.5, borderRadius: 3, fontWeight: 600 }}>
          {status}
        </Typography>
      </Box>
    </Box>

  );
};

export default PayoutTransactions