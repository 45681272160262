import api from "../api";
import { DEPOSITS_ENDPOINT } from "../apiEndpoints";
import { CardPay, MobilePayValues } from "../../types/actionTypes";
import { createIdempotenceKey } from "../../utils/middleware";

const timeframeInMinutes = 10; 

const depositsApiClient = {
  depositViaMobile: async (data:MobilePayValues) => {
    try {
    const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    const response = await api.post(
      `${DEPOSITS_ENDPOINT}/mobile`,
      data,
      config
    );
    console.log(response)
    return response?.data?.url;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response?.data
      }
    }
    
  },
  depositViaCard: async (data:CardPay) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);

    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    const response = await api.post(
      `${DEPOSITS_ENDPOINT}/card`,
      data,
      config
    );
    return response?.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response?.data
      }
    }
    
  },

  getDepositOptions:async()=>{
    const response = await api.get(`${DEPOSITS_ENDPOINT}/options`);
    return response.data;
  }
  
};

export default depositsApiClient;
