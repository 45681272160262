import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { downloadOptions } from '../../components/Reusables/Input/select/Options';
import { useState } from 'react';
import { Chip, IconButton, Stack } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useGoalTransactions } from '../../hooks/goalHooks';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useParams } from 'react-router-dom';
import Button from '../../components/Reusables/Input/Button';
import { Transaction } from '../../types/types';
import TransactionList from '../../components/home/TransactionList';
import { StyledTab, StyledTabs } from '../goals/GoalTabs';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
        {children}
        </Box>
      )}
    </div>
  );
}






const WithdrawTabContent = () => {
    return <Box>
       
    
    </Box>
  };

  const DepositTabContent = () => {
    return <Box>
        
    
    </Box>
  };

  const HomeTabContent = () => {
    return <Box>
       
    
    </Box>
  };


export default function BasicTabs() {
  const { id } = useParams<{id:string}>();
  const [value, setValue] = React.useState(0);
  const [type,setType]=useState('')
  const [selectedTransactions, setSelectedTransactions] = useState<Transaction[]>([]);

  const handleTransactionSelect = (transaction: Transaction) => {
  // Check if the transaction is already in the selectedTransactions array
  const isSelected = selectedTransactions.some((t) => t.id === transaction.id);

  if (isSelected) {
    // If the transaction is already selected, remove it from the selectedTransactions array
    setSelectedTransactions((prevSelected) =>
      prevSelected.filter((t) => t.id !== transaction.id)
    );
  } else {
    // If the transaction is not selected, add it to the selectedTransactions array
    setSelectedTransactions((prevSelected) => [...prevSelected, transaction]);
  }
};

  const transactions= useGoalTransactions({type},false,parseFloat(id));
    
  const handleClick = () => {
    console.info('You clicked the Chip');
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setType(downloadOptions[newValue].value)
  };

  return (
    <Box sx={{ width: '100%'}}>
         <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
         <IconButton>
            <ArrowBackOutlinedIcon />
         </IconButton>
         <Typography>Activity</Typography>
         <IconButton>
           <SearchOutlinedIcon/>
         </IconButton>
        </Box>
        <Stack direction="row" spacing={1}>
      <Chip label="Filter" variant="outlined" onClick={handleClick} icon={<FilterAltIcon />} />
      <Chip label="Download" variant="outlined" onClick={handleClick} icon={<FileDownloadIcon />}/>
    </Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'transparent' }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="goal transactions tabs">
        {downloadOptions.map((filter,index) => (
        <StyledTab label={filter.label}  />
      ))}
        </StyledTabs>
       
      </Box>
      <CustomTabPanel value={value} index={0}>
       <HomeTabContent/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
       <WithdrawTabContent />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <DepositTabContent/>
      </CustomTabPanel>
     
     <TransactionList transactions={transactions}
     selectMode={true}
          selectedTransactions={selectedTransactions}
          onSelect={handleTransactionSelect}/>
    
       <Box sx={{textAlign:'center'}}>
     {transactions.hasNextPage && (
       <Button 
       onClick={() => transactions.fetchNextPage()}
       title= {transactions.isFetchingNextPage ? 'Loading more...' : 'Load more'}
       disabled={transactions.isFetchingNextPage}
       />
      )}
      </Box>
    </Box>
  );
}