import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography,useMediaQuery,Theme } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Link, useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';
import { useStyles } from "./ProfileStyles";
import BalancesVisibility from './BalancesVisibility';
import PaymentMethods from './PaymentMethods';
import NotificationSettings from '../../components/profile/NotificationSettings';
import Security from './Security';
import ExchangeRates from './ExchangeRates';
import TransactionLimits from './TransactionLimits';

const UserSettings = () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0);
  const isDesktop = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles()
   
    const settingsList = [
  {
    primary: 'Balances visibility',
    secondary: 'Hide or unhide various account balances',
    link: '/profile/settings/balances',
  },
  {
    primary: 'Payment methods',
    secondary: 'Choose how you make and receive payments',
    link: '/profile/settings/payment-methods',
  },
  {
    primary: 'Security & Privacy',
    secondary: 'Setup your PINs and passwords here',
    link: '/profile/settings/security-privacy',
  },
  {
    primary: 'Notification settings',
    secondary: 'Choose how you make and receive payments',
    link: '/profile/settings/notification',
  },
  {
    primary: 'Exchange rates',
    secondary: 'Up to date conversion rates',
    link: '/profile/settings/exchange-rates',
  },
  {
    primary: 'Transaction limits',
    secondary: 'How much you can transact and the associated charges',
    link: '/profile/settings/transaction-limits',
  },
]

interface Setting {
   primary: string;
    secondary: string;
    link: string;
}

const handleClick = ({ item, index }: { item: Setting; index: number }) => {
  if (isDesktop) {
    setActiveTab(index);
  } else {
    history.push(item.link);
  }
};


  return (
    <Box >
      <Box className={classes.header} >
         <IconButton onClick={()=>history.push(`/profile`)} sx={{p:0,mr:2,color:'inherit',ml:0.5}}>
            <ArrowBackOutlinedIcon  fontSize='small'/>
         </IconButton>
          <Typography variant="h5" fontWeight={600} >
          Settings
        </Typography>
        </Box>
      <Box className={classes.settingSection}>
      <List >
      {settingsList.map((item, index) => (
          <ListItem
            key={index}
            sx={{ px: 0 }}
           onClick={()=>handleClick({item,index})}
          >
            <ListItemText primary={item.primary} secondary={item.secondary} />
            <ListItemIcon sx={{ minWidth: 'unset', color: '#000' }}>
              <ArrowForwardIosIcon fontSize="small" />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
       {isDesktop &&<Box sx={{width:'70%'}}>{activeTab === 0 ? <BalancesVisibility/> : activeTab === 1 ? <PaymentMethods/> : activeTab === 2 ? <Security /> : activeTab === 3 ? <NotificationSettings/> : activeTab === 4 ? <ExchangeRates/> :activeTab === 5 ? <TransactionLimits/> :null}</Box>}
      </Box>
    </Box>
  );
};

export default UserSettings;
