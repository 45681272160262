import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    display: "none",
    flexShrink: 1,
    [theme.breakpoints.up('sm')]: {
      display: "block",
      width: 240,
    },
  },
  /**the drawer paper is not the same size as the drawer width, adding width:100% doesnt work either so we have to unset the position from fixed and remove the gap ,we add the position and gap back on large device viewport */
  drawerPaper: {
    backgroundColor: 'red'
  },
  list: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    color: theme.palette.grey[500],
    marginTop: "36px",
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      justifyContent: "flex-start",
      gap: "5.12rem"
    },
  },
  navList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.75rem",
    width: '100%',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      gap: "0.75rem",
    },
    "& .active": {
      color: '#673AB7',
      background: '#F0EBF8',
      borderRadius: '8px',
      margin: '0px 8px'
    },
    "& :hover": {
      color: theme.palette.primary.light,

    },
  },
  /**list item with name and icon */
  listItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    color: '#000',
    borderRadius: '0.5rem',
    padding: '0.25rem 0rem',
    width: '4.9375rem',
    gap: '0.25rem',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      gap: '0.25rem',
      padding: '0.375rem 0.25rem',
      width: '10.3125rem'
    },
  },
  /**content on the side of navbar */
  content: {
    flexGrow: 1,
    backgroundColor: "#fff",
    overflowX: "hidden",
    marginLeft: -15
  },
  /**container for sidebar and content */
  root: {
    display: "flex",
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      gap: "4px",
    },
  },
  /**for bottom bar and content */
  containerRoot: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  contentBottom: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    paddingBottom: "56px",
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      paddingBottom: "0px",
    },
  },
}));
