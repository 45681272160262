
import { forwardRef, Key, useState} from "react";
import { useStyles } from "../goals/GoalStyles";
import FlipMove from "react-flip-move";
import GoalSkeleton from "../Reusables/Feedback/skeletons/GoalSkeleton";
import noSearch from "../../assets/images/nosearch.png";
import { GoalItem as Goal} from "../../types/types";
import { usePublicGoals } from "../../hooks/goalHooks";
import { Box, Typography } from "@mui/material";
import Fuse from "fuse.js";
import GoalItem from "../home/GoalItem";


type ExploreProps = {
  isTyping?: boolean;
  searchTerm: string;

};

const Explore = forwardRef<HTMLDivElement, ExploreProps>(
  ({  isTyping, searchTerm }, ref) => {
  const { data:goals, isLoading, isError } = usePublicGoals();
  

    const options = {
      keys: ["name"], 
      minMatchCharLength:3
    };
  
    const fuse = new Fuse(goals ?? [], options);
     /**take note of the space to account for search filter */
    const filteredGoals = searchTerm 
    ? fuse.search(searchTerm).map(result => result.item)
    :goals;
   
    const loading = isTyping || isLoading;
    const goalsToRender =filteredGoals;
    const noResults = searchTerm && filteredGoals?.length === 0;
    const classes = useStyles();
     if (isError) {
      return <Typography variant="body1" sx={{fontWeight:600,m:2,color:'#A1A1AA'}}>Error fetching public goals</Typography>;
    }
    return (
      <div ref={ref} className={classes.goalsContent}>
       
        <div className={classes.container}>
          {loading ? (
            <div className={classes.myGoals}>
              {Array.from(new Array(2)).map((_: any, index: any) => (
                <GoalSkeleton key={index} />
              ))}
            </div>
          ) : noResults ? (
            <Box sx={{textAlign:'center',color:'#A1A1AA',mt:2}}>
              <img src={noSearch} alt="search icon" />
              <Typography variant='subtitle2'>No public groups found, Try another key word</Typography>
            </Box>
          ) : goalsToRender && goalsToRender.length > 0 ? (
            <FlipMove className={classes.myGoals}>
            {goalsToRender.map((item: Goal, index: Key ) => (
              <GoalItem goal={item as Goal} key={index} isExplore />))}
            </FlipMove>
          ) : (
            <Box sx={{textAlign:'center',color:'#A1A1AA',mt:2}}>
            <Typography variant="body1" sx={{fontWeight:600}}>{`No public goals. Click + icon below to get started`}</Typography>
          </Box>
          )}
        </div>
      </div>
    );
  }
);




export default Explore;
