
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { ReactComponent as LendIcon } from "../../assets/svgs/Lending.svg";
import { Box, SvgIcon, Typography } from '@mui/material';
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import UserTransfer from '../Modals/UserTransfer';
import SendIcon from '@mui/icons-material/Send';
import { useUserWalletTotal } from '../../hooks/balanceHooks';
import SendMoneyStepper from '../Modals/SendMoney';

interface HomeOptionItemProps {
  IconComponent: React.ComponentType;
  caption: string;
  link?: string;
  onClick?: () => void
}

export const HomeOptionItem: React.FC<HomeOptionItemProps> = ({ IconComponent, caption, link, onClick }) => {
  const history = useHistory()
  const handleClick = () => {
    if (link) {
      history.push(link)
    } else if (onClick) {
      onClick()
    }
  };
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, border: "1px solid #F6F6F7", padding: "0.5rem", borderRadius: "1rem", width: { xs: "4.9375rem", sm: "4.9375rem", md: '8.75rem', lg: "9.875rem" } }}
      onClick={handleClick}
    >
      <Box sx={{
        backgroundColor: '#F0EBF8',
        border: '100%',
        width: '2.25rem',
        height: '2.25rem', borderRadius: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'
      }}>
        <SvgIcon component={IconComponent} fontSize='small' />
      </Box>
      <Typography variant='subtitle2' fontWeight={600}>{caption}</Typography>
    </Box>
  );
};

export const HomeOptions = () => {
  const [openTransfer, setOpenTransfer] = useState(false)
  const [openSend, setOpenSend] = useState(false)
  const { data } = useUserWalletTotal();

  return (
    <Box sx={{
      display: 'flex', alignItems: 'center', justifyContent: 'space-evenly',
      maxWidth: '44.8125rem', width: "100%", rowGap: { lg: "0.875rem" }, columnGap: { md: "14px" }, gap: { xs: "0.875rem" }, mx:"auto"
    }}>
     {/*  <HomeOptionItem IconComponent={SendIcon} caption="Send" onClick={() => setOpenSend(!openSend)} /> */}

      <HomeOptionItem IconComponent={SyncAltIcon} caption="Transfer" onClick={() => setOpenTransfer(!openTransfer)} />

      <HomeOptionItem IconComponent={LendIcon} caption="Lending" link='/loans' />
      <UserTransfer open={openTransfer} onClose={() => setOpenTransfer(!openTransfer)} />
      {data &&
        <SendMoneyStepper open={openSend} onClose={() => setOpenSend(!openSend)} balance={data.available} />
      }
    </Box>
  )
}


