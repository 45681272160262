import { ReactNode } from "react";
import { ButtonProps } from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from "@mui/material";

const CustomizedButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: "inherit",
  fontFamily: "Source Serif Pro",
  borderRadius: '0.5rem',
  height: "2.25rem",
  '&:hover': {
    transform: "none"
  }
}))

interface CustomButtonProps extends ButtonProps {
  title: string;
  loading?: boolean;
  children?: ReactNode;
}

const CustomButton = ({
  title,
  loading = false,
  children,
  onClick,
  ...rest
}: CustomButtonProps) => {
  return (

    <CustomizedButton
      disableElevation
      onClick={onClick}
      loading={loading}
      {...rest}
    >
      <Typography variant="button">
        <span>
          {title}</span>
      </Typography>
      {children}
    </CustomizedButton>


  );
};


export default CustomButton;
