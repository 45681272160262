import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps,AlertColor } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ToastProps  {
  open: boolean;
  type: string;
  message: string;
  handleClose: () => void;
}

function Toast({ open, type, message, handleClose }:ToastProps) {
  return (
    <div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity={type as AlertColor}>{message}</Alert>
      </Snackbar>
    </div>
  );
}


export default Toast;
