import React, { useState } from "react";
import Button from "../Reusables/Input/Button";
import FormInput from "../Reusables/Input/FormInput";
import { useStyles } from "./ProfileStyles";
import { Form, Field, FormSpy } from "react-final-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, Typography } from "@mui/material";
import Alert from '@mui/material/Alert';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useUpdatePassword } from "../../hooks/userHooks";
import { useHistory } from "react-router-dom";
import Toast from "../Reusables/Feedback/Toast";

interface FormValues {
  password: string;
  newPassword: string;
  confirmPassword: string;
}



function PasswordForm() {
  const classes = useStyles();
  const { updatePassword, isLoading, error } = useUpdatePassword();
  const history = useHistory()

  const [showPassword, setPassword] = useState(false);
  const [showOldPassword, setOldPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };


  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.password) {
      errors.password = "Required";
    }
    if (!values.newPassword) {
      errors.newPassword = "Required";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    }
    if (values.newPassword?.length < 8) {
      return { newPassword: "Password must be atleast 8 chars" };
    }
    if (values.newPassword !== values.confirmPassword) {
      return { confirmPassword: "Passwords must match" };
    }
    return errors;
  };

  const onSubmit = async (values: FormValues, form: any) => {
    try {
      await updatePassword({ password: values.password, newPassword: values.newPassword });
      setUpdateToast({
        open: true,
        message: "Password updated successfully",
        type: "success",
      });
      Object.keys(values).forEach((key) => {
        form.change(key, undefined);
        form.resetFieldState(key);
      });
    } catch (error) {
      console.error('Failed to update PIN:', error);
    }

  };
  return (
    <Box sx={{ m: 2 }} >
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 2 }}>
        <IconButton onClick={() => history.goBack()} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
          Password setup
        </Typography>
      </Box>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, form }) => (
          <form className={classes.formContainer} onSubmit={handleSubmit}>
            <Box sx={{ mt: 2, maxWidth: '400px', margin: '16px auto', display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Field
                search={true}
                label="Enter current password"
                name="password"
                component={FormInput}
                type={showOldPassword ? "text" : "password"}
                position="end"
                Icon={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setOldPassword(!showOldPassword)}
                    size="large">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
              <Field
                search={true}
                label="Set new password"
                name="newPassword"
                component={FormInput}
                type={showPassword ? "text" : "password"}
                position="end"
                Icon={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPassword(!showPassword)}
                    size="large">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
              <Field
                search={true}
                label="Confirm new Password"
                name="confirmPassword"
                component={FormInput}
                type={showConfirmPassword ? "text" : "password"}
                position="end"
                Icon={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setConfirmPassword(!showConfirmPassword)}
                    size="large">
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
              {/**display error on submission if it exits and remove it as soon as the user starts typing again
             * Since initially there are no changes but this is when wer show the message that is why we negate it with !
             */}
              <FormSpy subscription={{ dirtySinceLastSubmit: true }}>
                {({
                  dirtySinceLastSubmit
                }: any) => {
                  if (error && !dirtySinceLastSubmit) {
                    return <Alert severity="error">{error}</Alert>;
                  }
                  return null;
                }}
              </FormSpy>
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Button title='Cancel' sx={{ backgroundColor: '#F0EBF8', mr: 1.4, width: '120px' }}
                  onClick={() => form.reset()} />
                <Button
                  title="Save"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitting || isLoading}
                  sx={{ width: '120px' }}
                />
              </Box>
            </Box>
          </form>
        )}
      />
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />

    </Box>
  );
}

export default PasswordForm;
