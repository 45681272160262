
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { useLocation } from "react-router-dom";
import { SortItem, Transaction } from "../types/types";
import { SHA256 } from 'crypto-js';
import { durationOptions } from "../components/Reusables/Input/select/Options";
import Tesseract from 'tesseract.js';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d mins",
    h: "an hr",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d mths",
    y: "a yr",
    yy: "%d yrs",
  },
});

export const numberWithCommas = (x: number | string): string => {
  // Remove any characters that aren't numbers, periods, or hyphens
  let number = x.toString().replace(/[^0-9.-]/g, "");

  // Parse the number as a float or integer
  let parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
    parsedNumber = 0;
  }

  // Format the number with commas
  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(parsedNumber);
};

export const removeCommas = (value: string): string => {
  return value.replace(/,/g, '');
};


export const capitalizeWords = (str: string): string => {
  const arr = str.split(" ");
  let newArr = arr.map((element) => {
    return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
  });
  return newArr.join(" ");
};

interface EndDateProps {
  duration: number;
  startDate?: Date;
}

export const calculateEndDate = ({ duration, startDate }: EndDateProps): string => {
  let end: string;
  if (startDate) {
    end = dayjs(startDate).add(duration, "months").format("MMM DD, YYYY");
  } else {
    end = dayjs().add(duration, "months").format("MMM DD, YYYY");
  }
  return end;
};



export const calculateInterest = (rate: number, time: number, amount: number) => {
  return (rate / 100) * time * amount;
};

interface CalculateInterestEarnedParams {
  period: number;
  amount: number;
}

export const calculateTotalAmountAccrued = ({ period, amount }: CalculateInterestEarnedParams) => {
  let total = 0;
  let interest: number;
  let timeInMonths: number;


  // Main options, convert months to years for calculation
  timeInMonths = period; // Assuming period is a string representing months
  const yearlyRate = durationOptions.find((option) => option.value === period)?.rate || 21;



  // Calculate the new amount
  interest = calculateInterest(yearlyRate, timeInMonths / 12, amount);
  total = Number(amount) + Number(interest);

  return total.toFixed(2);
};




export const calculateInterestEarned = ({ period, amount }: CalculateInterestEarnedParams) => {
  //first convert the interest rate percentage to  a decimal
  let interest: number;
  let time: number;
  let rate: number;
  if (period === 3) {
    time = 3 / 12;
    rate = 12;
  } else if (period === 6) {
    time = 6 / 12;
    rate = 16;
  } else if (period === 9) {

    time = 9 / 12;
    rate = 18;
  } else if (period === 12) {
    time = 1;
    rate = 21;
  } else if (period >= 24) {
    time = period / 12;
    rate = 21;
  } else {
    time = period / 12;
    rate = 21
  }
  //calculate the new amount
  interest = calculateInterest(rate, time, amount);

  return {
    interest: parseFloat(interest.toFixed(0)),
    rate: rate,
  };
};



interface InterestRate {
  rate: string;
  duration: string;
}

export const renderInterestRate = (period: number): InterestRate => {
  let rate: string, duration: string;
  const option = durationOptions.find((opt) => opt.value === period);

  if (option) {
    rate = `${option.rate}% APY`;
    duration = option.label;
  } else {
    rate = '21% APY';
    duration = '2years+'
  }

  return {
    rate,
    duration,
  };
};



export const convertMonthsToYears = (months: number) => {
  return Math.floor(months / 12);
};

export const convertMonthsToDays = (months: number) => {
  return Math.floor(months * 30.5);
};

export const convertDaysToMonths = (days: number) => {
  return Math.floor(days / 30.5);
};

export const convertWeeksToMonths = (weeks: number) => {
  return Math.floor(weeks / 4.5);
};

export const convertYearsToMonths = (years: number) => {
  return years * 12;
};

export const groupBy = (array: SortItem[], key: string): { [key: string]: SortItem[] } => {
  const transactions = array.map((item) => {
    // Create a new object with the formatted date and all the properties of the original object
    return {
      ...item,
      created_at: dayjs(item.created_at).format("DD MMM YYYY"),
    };
  });

  // Use Array.reduce() to group the transactions by the specified key
  return transactions.reduce((result: { [key: string]: SortItem[] }, currentValue: SortItem) => {
    const groupByKey = currentValue[key];

    // If the key doesn't exist in the result object, create a new array for it
    if (!result[groupByKey]) {
      result[groupByKey] = [];
    }

    // Push the current transaction to the array for the corresponding key
    result[groupByKey].push(currentValue);

    return result;
  }, {});
};


export const truncateText = (str: string | undefined, num: number): string | undefined => {
  if (str && str.length > num) {
    let subStr = str.substring(0, num);
    return subStr + "...";
  } else {
    return str;
  }
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type Order = "ascending" | "descending" | "pinned";



export const sortByDate = (array: SortItem[], order: Order = "ascending", key: string = "created_at"): SortItem[] => {
  return array.sort((a, b) => {
    const dateA = dayjs(a[key]);
    const dateB = dayjs(b[key]);
    let result = 0;

    if (order === "ascending") {
      result = dateA.isAfter(dateB) ? 1 : dateA.isBefore(dateB) ? -1 : 0;
    } else if (order === "descending") {
      result = dateA.isAfter(dateB) ? -1 : dateA.isBefore(dateB) ? 1 : 0;
    } else if (order === "pinned") {
      result = a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1;
    }

    return result;
  });
};


export function telephoneCheck(str: string) {
  let regexPatterns = [
    //256775542410
    /^256\d{9}$/,
    //0775542410
    /^0\d{9}$/g,
  ];
  return regexPatterns.some((pattern) => pattern.test(`${str}`));
}



type Func = (...args: any[]) => any;

interface DebouncedFunction<T extends Func> {
  (): void;
  cancel: () => void;
}

export const debounce = <T extends Func>(func: T, delay: number = 500): DebouncedFunction<T> => {
  let timer: NodeJS.Timeout | null;

  const debounced = function (this: any, ...args: Parameters<T>) {
    const context = this;

    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  } as DebouncedFunction<T>;

  debounced.cancel = function () {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  };

  return debounced;
};



export const returnMinimumDeposit = (duration: number, currency: string): number => {
  if (duration < 24) {
    return currency === "UGX" ? 1000 : 20;
  } else {
    /**mimimum deposit for two years is 100000 ,for every subsequent year,the minimum amount increases by 50000 */
    const years = Math.floor(duration / 12);
    const extraMonths = duration % 12;
    let min = currency === "UGX" ? 100000 : 20;
    for (var i = 0; i < years; i++) {
      if (currency === "USD") {
        min = min + 10;
      } else if (currency === "UGX") {
        min = min + 50000;
      }
    }
    if (extraMonths > 0) {
      // If there are extra months, add an additional increment to the minimum deposit
      if (currency === "USD") {
        min += extraMonths * (10 / 12);
      } else {
        min += extraMonths * (50000 / 12);
      }
    }
    return min;
  }
}


/**create a custom idempotence key depending on the data sent and timeframe in which the api is made */
export const createIdempotenceKey = (data: any, timeframeInMinutes: number): string => {
  const timestamp = Math.floor(Date.now() / (1000 * 60 * timeframeInMinutes));
  const dataString = JSON.stringify(data);
  console.log(data)
  const inputData = `${dataString}-${timestamp}`;

  return SHA256(inputData).toString();
};


export const getStartDateAndEndDate = (duration: string) => {
  const today = dayjs();
  switch (duration) {
    case '1day':
      return { startDate: today, endDate: today };
    case '7days':
      const sevenDaysAgo = today.subtract(7, 'day');
      return { startDate: sevenDaysAgo, endDate: today };
    case '14days':
      const fourteenDaysAgo = today.subtract(14, 'day');
      return { startDate: fourteenDaysAgo, endDate: today };
    case '1month':
      const oneMonthAgo = today.subtract(1, 'month');
      return { startDate: oneMonthAgo, endDate: today };
    case '1year':
      const oneYearAgo = today.subtract(1, 'year');
      return { startDate: oneYearAgo, endDate: today };
    default:
      // For 'all' or any other invalid input, return null for both start and end date.
      return { startDate: null, endDate: null };
  }
};

export const extractTextFromImage = async (imageFile: File) => {
  const { data: { text } } = await Tesseract.recognize(imageFile);
  console.log(text)
  return text;
}

export function stringToBoolean(str: string) {
  return str === "true";
}


export const generateTransactionMessage = (transaction: Transaction) => {
  const { type, amount, status, from, to, currency } = transaction;

  // Determine the verb and additional information based on the transaction type and status
  let verb = "";
  let additionalInfo = "";
  let name = "";

  switch (type) {
    case "withdrawal":
      verb = "withdrawn";
      additionalInfo = `from ${from}`;
      name = `${transaction.user_name}`
      break;
    case "maturity":
      verb = `${to} has matured with`;
      name = `${transaction.name}`
      break;
    case "transfer":
      verb = 'transferred'
      additionalInfo = `from ${from} to ${to}`;
      name = `${transaction.user_name}`
      break;
    case "auto":
      verb = 'transferred'
      additionalInfo = `from ${from} to ${to}`;
      name = `${transaction.user_name}`
      break;
    case "rollover":
      verb = "reinvested";
      additionalInfo = `from ${from}  into ${to}`;
      name = `${transaction.user_name}`
      break;
    case "payout":
      verb = "received a revolving payout";
      additionalInfo = `from ${from}`;
      name = `${transaction.user_name}`
      break;
    case "deposit":
      verb = "deposited";
      additionalInfo = `in ${to}`;
      name = `${transaction.user_name}`
      break;
    default:
      return "Unknown transaction type";
  }

  // Construct the final message with status information
  const message =
    status === "pending"
      ? `${type} transaction of  ${numberWithCommas(amount)} ${currency} is pending.`
      : status === "failed"
        ? `${type} transaction of  ${numberWithCommas(amount)} ${currency} failed.`
        : status === "ongoing"
          ? `Ongoing ${type} settlement of ${amount} ${currency} ${additionalInfo}.`
          : `${name} ${(verb)} ${numberWithCommas(amount)} ${currency} ${additionalInfo}.`;

  return message;
}

export const calculateLoanInterest = (principal: number, days: number) => {
  //Convert 10% monthly interest rate to daily rate
  const dailyInterestRate = (Math.pow(1 + 10 / 100, 1 / 30) - 1).toFixed(4);

  const interest: number = principal * (parseFloat(dailyInterestRate) * days);

  return Math.ceil(interest);
}

export function returnLoanInterest(loanAmount: number, interestRatePerMonth: number, durationInMonths: number): number {
  // Calculate interest by multiplying principal, rate, and duration
  const interest = loanAmount * (interestRatePerMonth / 100) * durationInMonths;

  return interest;
}


export function calculateWithdrawalFee(amount: number, currency: string): number {
  if (currency === 'USD') {
    // Calculate 1% of the amount for USD currency
    return amount * 0.01;
  } else if (currency === 'UGX') {
    const flatFees = [
      { range: [0, 5000], fee: 350 },
      { range: [5001, 15000], fee: 500 },
      { range: [15001, 50000], fee: 1000 },
      { range: [50001, 80000], fee: 1500 },
      { range: [80001, 120000], fee: 2000 },
      { range: [120001, 180000], fee: 2500 },
      { range: [180001, 220000], fee: 3000 },
      { range: [220001, 280000], fee: 3500 },
      { range: [280001, 500000], fee: 5000 }
    ];

    // Find the appropriate flat fee based on the amount range
    const flatFee = flatFees.find(({ range }) => amount >= range[0] && amount <= range[1])?.fee || 0;

    // Calculate 1% of the amount
    const onePercentFee = amount * 0.01;

    // Use the higher fee between flat fee and 1% fee
    return Math.max(flatFee, onePercentFee);
  } else {
    throw new Error("Unsupported currency");
  }
}



interface TransferFee {
  currency: string;
  fee: number;
}

export const calculateTransferFee = (currency: string, amount: number): number => {
  // Define transfer fees for different currencies
  const transferFees: TransferFee[] = [
    { currency: 'UGX', fee: 500 },
    { currency: 'USD', fee: 500 / 3785 } // Convert 500 UGX to USD based on conversion rate
    // Add more currencies and corresponding fees as needed
  ];

  // Find the transfer fee for the given currency
  const feeObject = transferFees.find(fee => fee.currency === currency);

  if (feeObject) {
    let fee = feeObject.fee;

    // Check if the currency is UGX and the amount is greater than 280,000
    if (currency === 'UGX' && amount > 280000) {
      fee = 600; // Update fee for amounts greater than 280,000 UGX
    }

    return fee;
  } else {
    // If no matching currency is found, return a default fee
    return 0; // Or throw an error, depending on your use case
  }
};






