import React, { useEffect, useState } from 'react'
import { Alert, Avatar, Box, DialogContent, DialogTitle, FormControlLabel, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { FormApi, ValidationErrors } from 'final-form';
import { Field, Form } from 'react-final-form';
import Select from '../Reusables/Input/select/Select';
import CustomLink from '../Reusables/Input/CustomLink';
import { numberWithCommas, removeCommas } from '../../utils/middleware';
import FormInput from '../Reusables/Input/FormInput';
import RadioButtons from '../Reusables/Input/RadioGroup';
import { repayOptions } from '../Reusables/Input/select/Options';
import { PaymentMethod } from '../../types/types';
import { usePaymentMethods } from '../../hooks/paymentMethodHooks';
import DoneIcon from '@mui/icons-material/Done';
import Button from '../Reusables/Input/Button';
import dayjs from 'dayjs';
import { WithdrawOptionsStep } from '../Modals/CreateWithdrawal';
import { useLoanDetails, useLoans, useRepayLoan } from '../../hooks/loanHooks';
import { useHistory } from 'react-router-dom';


interface LoanStepperProps {
  open: boolean;
  onClose: () => void;
  loanId?: number;
  maxAmount?: number
}

interface FormValues {
  type: string;
  currency: string;
  name: string;
  amount: number;
}


interface LoanFormValues {
  type: string,
  amount: string,
  method: string,
  id: string | number,
  phoneNumber: string,
  card: any,
  loanId: number,

}

const steps = ['Repay Loan', 'Transfer options', 'Confirm', 'Success!'];

const RepayLoan: React.FC<LoanStepperProps> = ({ open, onClose, loanId, maxAmount }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { repayLoan, isLoading, error } = useRepayLoan();
  const history = useHistory();

  useEffect(() => {
    if (loanId && maxAmount) {
      setFormValues({ ...formValues, amount: maxAmount });
    }
  }, [maxAmount, loanId]);


  const validate = (values: LoanFormValues) => {
    const errors: ValidationErrors = {};

    if (activeStep === 0) {
      if (!loanId && !values.loanId) {
        errors.id = `Choose loan to repay`
      }

      if (!values.amount) {
        errors.amount = "required"
      }

      if (values.amount && parseFloat(removeCommas(values.amount)) < 1000) {
        errors.amount = 'Please enter a valid amount'
      }

      if (values.amount && !loanId && !values.loanId && parseFloat((values.amount)) > formValues.amount) {
        errors.amount = `Max. amount is ${numberWithCommas(formValues.amount)}`
      }




      if (values.amount && loanId && maxAmount && parseFloat(values.amount) > maxAmount) {
        errors.amount = `Max. amount is ${numberWithCommas(maxAmount)}`
      }

      if (!values.method) {
        errors.method = "required";
      }

      if (values.method === 'mobile') {
        if (!values.phoneNumber) {
          errors.phoneNumber = 'required'
        }
      }
    }
    if (activeStep === 1) {
      if (values.method === 'transfer') {
        if (!values.id) {
          errors.id = 'required'
        }
      }
    }

    return errors;
  };

  const [formValues, setFormValues] = useState({ type: '', currency: 'UGX', name: '', amount: 0 });



  const handleReset = (form: FormApi<LoanFormValues, Partial<LoanFormValues>>) => {
    setActiveStep(0);
    form.reset();
    onClose()
    setFormValues({ type: '', currency: 'UGX', name: '', amount: 0 });
  };

  const handleNext = async (values: LoanFormValues) => {
    if (activeStep === steps.length - 2) {
      try {
        if (values.method === 'card') {
          await repayLoan({
            amount: parseFloat(values.amount),
            card: {
              id: values.card.id,
              token: values.card.card.token,
              country: values.card.card.country,
            },
            type: formValues.type === 'group' ? 'wallet' : formValues.type,
            id: values.id as number,
            method: values.method,
            currency: "UGX",
            loanId: loanId ?? values.loanId
          });

        } else {
          await repayLoan({
            amount: parseFloat(values.amount),
            phoneNumber: values.phoneNumber,
            type: formValues.type === 'group' ? 'wallet' : formValues.type,
            id: values.id as number,
            method: values.method,
            currency: "UGX",
            url: history.location.pathname,
            loanId: loanId ?? values.loanId
          });
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

      } catch (error) {
        console.log(`error`, error)
      }
    } else if (activeStep === 0 && (values.method === 'mobile' || values.method === 'card')) {
      setActiveStep(2)
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleStep = (step: string) => {
    if (step === 'transfer') {
      setActiveStep(0)
    } else {
      console.log(activeStep, step)
    }
  }

  const handleBack = (values: LoanFormValues) => {
    if (activeStep === 2 && (values.method === 'mobile' || values.method === 'card')) {
      setActiveStep(0)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <Box>
      <Box>
        <DialogTitle sx={{ display: 'flex' }}>
          <Typography sx={{ m: '0 auto', fontWeight: 600 }}>{steps[activeStep]}</Typography>
          <CloseIcon onClick={onClose} />
        </DialogTitle>
        <DialogContent sx={{ padding: { lg: '28px 55px' } }}>
          <Form
            onSubmit={handleNext}
            validate={validate}
            initialValues={{
              method: 'mobile',
              amount: 0,
              loanId: loanId

            }}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit}>

                {activeStep === 0 && <LoanDetailsStep values={values} formValues={formValues} setFormValues={setFormValues} handleStep={handleStep} />}
                {activeStep === 1 && <WithdrawOptionsStep
                  formValues={formValues}
                  setFormValues={setFormValues} name={'id'} handleNext={handleNext} />}
                {activeStep === 2 && <Box>
                  <Typography>
                    You are about to withdraw {" "}
                    <Typography component='span' fontWeight={600}>{numberWithCommas(values.amount)}</Typography> <Typography component='span' variant='overline'>UGX</Typography> from <Typography component={'span'} fontWeight={600}>
                      {values.method === 'mobile' ? values.phoneNumber : values.method === 'card' ? `**** **** **** ${values.card.card.last_4digits}` : `${formValues.name}`}</Typography> to repay loan
                  </Typography>
                </Box>}

                {activeStep === 3 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: '#22C55E', mt: 2, width: 60, height: 60 }}>
                    <DoneIcon fontSize='large' />
                  </Avatar>
                  <Typography sx={{ mt: 1, textAlign: 'center' }}>{`You have successfully repaid  ${numberWithCommas(parseFloat(removeCommas(values.amount)))} UGX for loan Loan ID PB00${loanId ?? values.loanId}G `}</Typography>
                  <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography variant='subtitle2' fontWeight={600}>Transaction details</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography>From</Typography>
                      <Typography component={'span'}>
                        {values.method === 'mobile' ? values.phoneNumber : values.method === 'card' ? `**** **** **** ${values.card.card.last_4digits}` : `${formValues.name}`}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography>For</Typography>
                      <Typography>Loan</Typography>
                    </Box>
                    <Typography gutterBottom>
                      {`At ${dayjs().format('h:mm A')}. ${dayjs().format('ddd D MMM YY')}`}
                    </Typography>
                  </Box>

                </Box>}
                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 3,
                  }}
                >
                  {activeStep === steps.length - 1 ? (
                    <Button title='Great' variant='contained' sx={{ width: '100%' }} onClick={() => handleReset(form)} />
                  ) : (
                    <>
                      {activeStep !== 0 && (
                        <Button color="primary" onClick={() => handleBack(values)} title='Back' sx={{ backgroundColor: '#F0EBF8', mr: 1.5, width: '120px' }} />
                      )}
                      <Button variant="contained" type="submit"
                        title={activeStep === steps.length - 2 ? 'Proceed' : 'Next'}
                        sx={{ width: '120px' }}
                        disabled={isLoading}
                        loading={isLoading}
                      />
                    </>
                  )}
                </Box>
              </form>
            )}
          />
        </DialogContent>
      </Box>
    </Box>
  )
}

export default RepayLoan

interface LoanDetailsFormValues {
  values: Partial<LoanFormValues>,
  formValues: FormValues,
  setFormValues: any,
  handleStep: (step: string) => void
}


const LoanDetailsStep: React.FC<LoanDetailsFormValues> = ({ values, formValues, handleStep, setFormValues }) => {
  const { data: paymentMethodsData } = usePaymentMethods();
  const { data: loans } = useLoans();
  const loanOptions = (loans ?? []).filter(loan => loan.status === 'ongoing').map((loan) => {
    return {
      label: `PB00${loan.id}G - UGX ${numberWithCommas(Number(loan.loan_amount) - Number(loan.amount_paid))}`,
      amount: Number(loan.loan_amount) - Number(loan.amount_paid),
      value: loan.id
    }
  })
  const phoneMethods: PaymentMethod[] = paymentMethodsData?.phone || []
  const cardMethods: PaymentMethod[] = paymentMethodsData?.card || []

  const [displayInput, setDisplayInput] = useState(phoneMethods.length === 0)

  useEffect(() => {
    if (phoneMethods.length !== 0) {
      setDisplayInput(!displayInput);
    }
  }, [paymentMethodsData]);



  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.6 }}>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.6rem", marginBottom: "0.5rem", minWidth: { lg: '300px' } }}>
          <Typography fontWeight={600}>Select loan ID to repay</Typography>
          <Field name="loanId">
            {(props: any) => <Select
              options={loanOptions}
              onChange={(e) => {
                const selectedLoanId = e.target.value;
                props.input.onChange(selectedLoanId);
                const selectedLoan = loanOptions.find((loan) => loan.value === parseFloat(selectedLoanId));
                setFormValues({ ...formValues, amount: selectedLoan?.amount ?? 0 })
              }}
              {...props}
            />}
          </Field>


        </Box>
        <Box sx={{ mt: 2 }}>
          <Field name="amount">
            {(props: any) => <FormInput
              label="How much you want to repay?"
              type="text"
              name="amount"
              value={numberWithCommas(props.input.value)}
              onChange={(e) => {
                props.input.onChange(removeCommas(e.target.value));

              }}
              InputProps={{
                endAdornment: <Typography sx={{ color: '#006703', fontWeight: 600 }}
                  onClick={() => {
                    props.input.onChange(formValues.amount.toString());
                  }}
                >Max</Typography>
              }}
              {...props}
            />}
          </Field>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='caption' sx={{ color: '#6F6F6F' }}>Max. Loan Amount</Typography>
            <Typography variant='caption' sx={{ color: '#6F6F6F' }}>{`${numberWithCommas(formValues.amount)} ${formValues.currency}`}</Typography>
          </Box>
        </Box>

      </Box>

      <Box>
        <Typography fontWeight={600}>Payment Method</Typography>
        <Field name="method" >
          {(props: any) => <RadioButtons
            options={repayOptions}
            onChange={(e) => {
              props.input.onChange(e.target.value);
              handleStep(e.target.value)
            }}
            {...props}
          />}
        </Field>
      </Box>

      {values.method === "mobile" ? (
        <div >
          {displayInput ? (
            <Field
              label="Enter phone number"
              name="phoneNumber"
              component={FormInput}
              type="string"
            />
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight={600}>Choose PhoneNumber</Typography>
                <Typography fontWeight={600} onClick={() => setDisplayInput(!displayInput)} sx={{ color: '#006703' }}>Add </Typography>
              </Box>
              <Field
                phoneNumbers={phoneMethods}
                name="phoneNumber"
                type="select"
                component={Select}
              />
            </>
          )}
        </div>
      ) : values.method === "card" ? (
        <div >
          <Typography fontWeight={600}>Choose Card</Typography>
          {cardMethods.length === 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography fontWeight={600}>Add card to continue transaction</Typography>
              <CustomLink href={`/profile/settings/payment-methods`} title='Add card' />
            </Box>
          ) : (
            <Field
              cards={cardMethods}
              name="card"
              type="select"
              component={Select}
            />
          )}
        </div>
      ) : null}
    </Box>
  );
};