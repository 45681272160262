import React, { useState } from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useHistory } from 'react-router-dom';

interface DurationOption {
  label: string;
  rate: number;
  value: number | string;
}

interface DurationOptionsListProps {
  options: DurationOption[];
  onChange: (value: number | string) => void;
  value: number | string;
  meta?: {
    error?: string;
    submitError?: string;
    touched?: boolean;
  };
  customStyles?: any;
}

const DurationOptionsList: React.FC<DurationOptionsListProps> = ({ options, onChange, value, meta, customStyles }) => {
  const [selectedValue, setSelectedValue] = useState<string | number>(value);
  const history = useHistory();

  const handleOptionClick = (option: DurationOption) => {
    if (option.label === "View goals") {
      history.push('/goals');
    } else {
      setSelectedValue(option.value);
      onChange(option.value);
    }
  };

  return (
    <Box sx={customStyles || { display: 'flex', flexWrap: 'wrap', gap: "16px", width: "100%", justifyContent: "flex-start" }}>
      {options.map((option) => (
        <Box
          key={option.label}
          onClick={() => handleOptionClick(option)}
          sx={{
            cursor: 'pointer',
            backgroundColor: option.value === selectedValue ? '#F0EBF8' : 'transparent',
            border: option.value === selectedValue ? '1px solid #673AB7' : '1px solid #C4CFD4',
            width: { xs: '85px', lg: '91px' },
            height: { xs: '70px', lg: '80px' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            flexDirection: 'column',
            p: "0.5rem",
            textAlign: 'center',
            gap: "0.25rem"
          }}
        >
          <Typography
            variant='body2'
            sx={{ fontWeight: 700 }}>{option.label}</Typography>
          {option.label === "View goals" ?
            (
              <ArrowForwardIcon fontSize='small' />
            ) :
            (<Typography variant='body1'
              sx={{ color: 'grey.700', fontWeight: 600 }}
            >{`${option.rate}%`} {" "}
              <Typography component='span' variant='overline'>{`P.A`}</Typography>
            </Typography>)
          }
        </Box>
      ))}
      <FormHelperText sx={{ color: 'error.main' }}>
        {meta?.touched ? meta?.error || meta?.submitError : ""}
      </FormHelperText>
    </Box>
  );
};

export default DurationOptionsList;
