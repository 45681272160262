import { PaymentRequestData } from "../../types/actionTypes";
import { Wallet, WalletMember } from "../../types/types";
import api from "../api";
import { WALLETS_ENDPOINT } from "../apiEndpoints";

const walletsApiClient = {
  // Retrieve a list of all payment requests
 
  getUserWallet: async () => {
    const response = await api.get(`${WALLETS_ENDPOINT}/user`);
    return response.data.wallet;
  },
  getGroupWallet: async (groupId:number) => {
    const response = await api.get(`${WALLETS_ENDPOINT}/group/${groupId}`);
    return response.data.wallet;
  },
  updateById: async (id:number,data:Partial<Wallet>) => {
    try {
      const response = await api.put(`${WALLETS_ENDPOINT}/${id}`,data);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  createWalletMember:async(id:number,members:number[])=>{
    try {
      const response = await api.post(`${WALLETS_ENDPOINT}/add-members/${id}`,{members});
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  editWalletMember: async (id:number,data:Partial<WalletMember>) => {
    try {
       const response = await api.put(`${WALLETS_ENDPOINT}/member/${id}`,data);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
       throw error.response.data
      }
    }
   
  },
  getWalletMember: async (memberId:number) => {
    const response = await api.get(`${WALLETS_ENDPOINT}/members/member/${memberId}`);
    return response.data.member;
  },
  getWalletPockets: async () => {
    const response = await api.get(`${WALLETS_ENDPOINT}/pockets`);
    return response.data.pockets;
  },
   getGroupWalletPockets: async (groupId:number) => {
    const response = await api.get(`${WALLETS_ENDPOINT}/pockets/${groupId}`);
    return response.data.pockets;
  },
  getWalletOptions: async () => {
    const response = await api.get(`${WALLETS_ENDPOINT}/wallet-options`);
    return response.data.options;
  },
  getTransactions: async (id:number,params:any) => {
    const response = await api.post(`${WALLETS_ENDPOINT}/transactions/${id}`,params);
    return response.data;
  },
  getUserTransactions: async (params:any) => {
    const response = await api.post(`${WALLETS_ENDPOINT}/transactions`,params);
    return response.data;
  },
};

export default walletsApiClient;
