import { Box, Drawer, Theme, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoanContent } from '../../pages/loans/LoanPage';
import { Loan } from '../../types/types';
import { numberWithCommas } from '../../utils/middleware';
import ModalWrapper from '../Modals/ModalWrapper';
import Button from '../Reusables/Input/Button';
import RepayLoan from './RepayLoan';

interface LoanOverviewCardProps {
    loan: any;
}

const LoanOverviewCard: React.FC<LoanOverviewCardProps> = ({ loan }) => {
    const [openRepay, setOpenRepay] = useState(false);
    const history  = useHistory()
    const [selectedLoan, setSelectedLoan] = useState<Loan>()
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const handleRepay = (loan: Loan) => {
    setOpenRepay(!openRepay)
    setSelectedLoan(loan)
  }

   const handleMore = (loan: Loan) => {
    setIsSettingsOpen(!isSettingsOpen)
    setSelectedLoan(loan)
    if(!isDesktop){
    history.push(`/loans/${loan.id}`)
    }
  }
    return (
        <Box key={loan.id}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: "0.5rem", marginBottom: "0.44rem" }}>
                <Typography variant="body2">{dayjs(loan.created_at).format("ddd, DD MMM YY")}</Typography>
                <Box width="3px" height="3px" borderRadius={"999px"} sx={{
                    background: "#B7B7B7"
                }} />
                <Typography variant="body2" textTransform={"uppercase"}>{dayjs(loan.created_at).format("h:mma")}</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                padding: '0.375rem',
                flexDirection: 'column',
                borderRadius: '0.375rem',
                background: '#F5F5F5',
              
                width: '100%',
                gap: "0.5rem"
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='body1'>Amount to pay</Typography>
                    <Typography variant='h5' color='error'>{numberWithCommas(loan.loan_amount - loan.amount_paid)}
                        <Typography variant='overline' component='span'>{` ${loan.currency}`}</Typography></Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='body1'>{`Loan ID PB00${loan.id}G`}</Typography>
                    <Typography component='span' variant='body2'> Due {dayjs(loan.due_date).format("DD MMM, YY")} </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button title='Repay' variant='text' sx={{ height: "2.75rem", width: "100%" }} onClick={() => handleRepay(loan)}/>
                <Button title='See more' variant='text' sx={{ height: "2.75rem", width: "100%" }} onClick={() => handleMore(loan)} />
                </Box>
            </Box>
            <ModalWrapper open={openRepay && !isDesktop} onClose={() => setOpenRepay(false)}>
        <RepayLoan open={openRepay} onClose={() => setOpenRepay(false)} loanId={selectedLoan?.id as number} maxAmount={(selectedLoan?.loan_amount || 0) - (selectedLoan?.amount_paid || 0)} />
      </ModalWrapper>
      <Drawer anchor="right" open={openRepay && isDesktop} onClose={() => setOpenRepay(false)} sx={{
        '& .MuiPaper-root.MuiDrawer-paper': {
          width: '400px'
        }
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <RepayLoan open={openRepay} onClose={() => setOpenRepay(false)} loanId={selectedLoan?.id as number} maxAmount={(selectedLoan?.loan_amount || 0) - (selectedLoan?.amount_paid || 0)} />
        </Box>
      </Drawer>
       <Drawer anchor="right" open={isSettingsOpen && isDesktop } onClose={() => setIsSettingsOpen(false)} sx={{
        '& .MuiPaper-root.MuiDrawer-paper': {
          width: '400px',
          padding:'16px'
        }
      }}>
        {selectedLoan &&   <LoanContent loan={selectedLoan}/>}
       
      </Drawer>
        </Box>
    )
}

export default LoanOverviewCard;
