import { Box, Typography } from "@mui/material";
import { useStyles } from "../loginStyles";
import FormInput from "../../../components/Reusables/Input/FormInput";
import Button from "../../../components/Reusables/Input/Button";
import { Field } from 'react-final-form';
import RadioButtons from "../../../components/Reusables/Input/RadioGroup";
import Select from "../../../components/Reusables/Input/select/Select";
import { businessCategoryOptions, businessDurationOptions } from "../../../components/Reusables/Input/select/Options";
import { RegisterFormValues } from "./RegisterStepper";




const RegisterBusiness: React.FC<{ values: RegisterFormValues; handleBack: any }> = ({ values, handleBack }) => {
    const classes = useStyles();
    return (
        <Box className={classes.formContainer}>
            <Box maxWidth={"450px"} width="100%">
                <Typography variant="h4">Tell us about your business</Typography>
            </Box>
            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}>What type of business do you run?</Typography>
                <Field
                    type="select"
                    name="business_type"
                    component={Select}
                    options={businessCategoryOptions}
                />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field
                    label="What is the registered name of your business?"
                    name="business_name"
                    type="text"
                    component={FormInput}
                />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                {values.business_type === 'trading' && (
                    <Field
                        label="What do you trade?"
                        name="trade_type"
                        type="text"
                        component={FormInput}
                    />

                )}
            </Box>
            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}> How long has the business been in operation? </Typography>
                <Field
                    name="business_duration"
                    row={false}
                    component={RadioButtons}
                    options={businessDurationOptions}
                />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field
                    label="Where is your business located?"
                    name="location"
                    type="text"
                    component={FormInput}
                />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Typography variant='body1'
                    sx={{ marginBottom: '4px', fontWeight: 600 }}>Are you the legal business owner?</Typography>
                <Field name="businessOwnerType">
                    {(props: any) => (
                        <RadioButtons
                            options={[{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }]}
                            {...props.input}
                        />
                    )}
                </Field>
            </Box>

            <Box mt={3} className={classes.formBtnContainer}>
                <Button title='Back' variant='outlined' style={{ width: "49%" }} onClick={handleBack} />
                <Button title='Continue' type='submit' variant='contained' style={{ width: "49%" }} />
            </Box>
        </Box>

    );
};

export default RegisterBusiness;