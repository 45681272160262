import React from "react";
import Sidebar from "./Sidebar";
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Box, Grid } from "@mui/material";

import { useLocation } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";


export const routes = [
  "/transactions",
  "/profile",
  "/goals",
  "/auth",
  "/loans",
  "/",
  "/payment-request/:requestId",
  "*",
];


import theme from "../../../../theme";
import PageWrapper from "../../../layout/PageWrapper";
import { makeStyles } from "@mui/styles";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(12)} + 1px)`,
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(${theme.spacing(27)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const mainContentStyles = makeStyles((theme: Theme) => ({
  boxContent: {
    flexGrow: 1,
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      marginLeft: `calc(${theme.spacing(12)} + 1px)`,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: `calc(${theme.spacing(27)} + 1px)`,
    },
  },
}));

const withSidebar = (Component: React.ComponentType) => {
  const matches = useMediaQuery(`@media (min-width: ${theme.breakpoints.values.sm}px)`);
  const location = useLocation();
  // Check if the current location is /auth
  const authRoute = location.pathname.startsWith('/auth');
  const isNoMatchRoute = !routes.includes(location.pathname);

  
  const classes = mainContentStyles();

  return () => (
    (matches && !authRoute && !isNoMatchRoute) ? (
      <PageWrapper>
        <Grid item>
          <Drawer variant="permanent" open={false}>
            <Sidebar />
          </Drawer>
        </Grid>
        <Grid item className={classes.boxContent}>
          <Component />
        </Grid>
      </PageWrapper>
    ) : <Box>
      <Component />
    </Box>
  );
};

export default withSidebar;
