import { PaymentRequestData } from "../../types/actionTypes";
import api from "../api";
import { PAYMENT_REQUESTS_ENDPOINT } from "../apiEndpoints";

const paymentrequestsApiClient = {
  // Retrieve a list of all payment requests
  getById: async (id:number) => {
    const response = await api.get(`${PAYMENT_REQUESTS_ENDPOINT}/${id}`);
    return response.data;
  },
  create: async (data:PaymentRequestData) => {
    try {
      const response = await api.post(`${PAYMENT_REQUESTS_ENDPOINT}/pasbanc`, data);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
};

export default paymentrequestsApiClient;
