import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme: Theme) =>
  ({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      backgroundColor: "#f8f8f8",
      padding: theme.spacing(2),
    },
    content: {
      maxWidth: "400px",
      textAlign: "center",
      padding: theme.spacing(4),
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    },
    title: {
      fontSize: "32px",
      fontWeight: "bold",
      marginBottom: theme.spacing(2),
    },
    message: {
      fontSize: "18px",
      color: "#666",
      marginBottom: theme.spacing(3),
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  })
);

function NoMatch() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.content}>
        <Typography variant="h1" className={classes.title}>
          Oops! Page not found.
        </Typography>
        <Typography variant="body1" className={classes.message}>
          The page you are looking for doesn't exist or has been moved.
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
}

export default NoMatch;