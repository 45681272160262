import React, { useState } from 'react';
import {
    Box, Typography, Drawer, Theme, useMediaQuery, Chip, Alert, DialogContent
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ApplyBusinessLoan from '../../pages/loans/ApplyBusinessLoan';
import ApplySalaryLoan from '../../pages/loans/ApplySalaryLoan';
import { LoanInfo } from '../../pages/loans/LoanHub';
import CreateLoan from './CreateLoan';
import { LoanDetails, useBusinessInfo, useLoanApply, useUpdateBusinessLoanAmount } from '../../hooks/loanHooks';
import { Field, Form } from 'react-final-form';
import { ValidationErrors } from 'final-form';
import { numberWithCommas, removeCommas } from '../../utils/middleware';
import FormInput from '../Reusables/Input/FormInput';
import Select from '../Reusables/Input/select/Select';
import Button from '../Reusables/Input/Button';
import ModalWrapper from '../Modals/ModalWrapper';
import ProfessionalIcon from '../../assets/svgs/Lawyer.svg';
import Toast from '../Reusables/Feedback/Toast';

interface Props {
    loanCardStyles: object;
    data: LoanDetails;
}

const ApplyforLoanCards: React.FC<Props> = ({ loanCardStyles, data }) => {
    const history = useHistory();
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const [isSalaryOpen, setIsSalaryOpen] = useState(false);
    const [isBusinessOpen, setIsBusinessOpen] = useState(false);
    const [openLoan, setOpenLoan] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [applyLoan, setApplyLoan] = useState(false)

    const handleLoan = () => {
        if (data && data?.amountLeftToBorrow) {
            setOpenLoan(true);
        } /* else if (data && data?.amountLeftToBorrow === 0) {
            setOpenInfo(false);
        } */
    };
    ``
    const handleBusinessLoan = () => {
        if (data && data.has_business_apply) {
            setApplyLoan((prevState) => !prevState)
        } else if (isDesktop) {
            setIsBusinessOpen((prevState) => !prevState)
        } else {
            history.push('/loans/apply/business')
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1.5,
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Box sx={{ ...loanCardStyles }} >
                <RocketLaunchIcon fontSize="large" />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.5625rem" }}>
                    <Typography variant="body1">
                        Get instant <b>saver</b> loan
                    </Typography>
                    {data.has_saver_loan && <Chip label='active' sx={{ color: '#22C55E', borderColor: '#22C55E', borderWidth: "1px", fontWeight: 700, fontSize: "0.75rem", height: "1.1875rem" }} variant='outlined' />}
                </Box>
            </Box>
            <Box
                sx={{ ...loanCardStyles }}
                onClick={handleBusinessLoan}>
                <img src={ProfessionalIcon} alt={`business loan`} width="35px" height="35px" />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.5625rem" }}>
                    <Typography variant="body1">
                        Apply for <b>business</b> loan
                    </Typography>
                    {data.has_business_apply && <Chip label='pending' sx={{ color: '#EF6C00', borderColor: '#EF6C00', borderWidth: "1px", fontWeight: 700, fontSize: "0.75rem", height: "1.1875rem" }} variant='outlined' />}

                </Box>

            </Box>
            <Box
                sx={{ ...loanCardStyles }}
                onClick={
                    isDesktop
                        ? () => setIsSalaryOpen((prevState) => !prevState)
                        : () => history.push('/loans/apply/salary')
                }
            >
                <BusinessCenterIcon fontSize="large" />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.5625rem" }}>
                    <Typography variant="body1" >
                        Apply for <b>salary</b> loan
                    </Typography>
                    {data.has_salary_apply && <Chip label='pending' sx={{ color: '#EF6C00', borderColor: '#EF6C00', borderWidth: "1px", fontWeight: 700, fontSize: "0.75rem", height: "1.1875rem" }} variant='outlined' />}
                </Box>

            </Box>
            <ModalWrapper open={isSalaryOpen && isDesktop} onClose={() => setIsSalaryOpen(false)}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ApplySalaryLoan />
                </Box>
            </ModalWrapper>
            <ModalWrapper open={isBusinessOpen && isDesktop} onClose={() => setIsBusinessOpen(false)}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ApplyBusinessLoan />
                </Box>
            </ModalWrapper>
            <ModalWrapper open={openLoan} onClose={() => setOpenLoan(false)}>
                <CreateLoan open={openLoan} onClose={() => setOpenLoan(false)} />
            </ModalWrapper>
            <LoanInfo open={openInfo} onClose={() => setOpenInfo(false)} />
            <ModalWrapper open={applyLoan && !isDesktop} onClose={() => setApplyLoan(false)}>
                <LoanAmount open={applyLoan} onClose={() => setApplyLoan(false)} />
            </ModalWrapper>
            <ModalWrapper open={applyLoan && isDesktop} onClose={() => setApplyLoan(false)}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <LoanAmount open={applyLoan} onClose={() => setApplyLoan(false)} />
                </Box>
            </ModalWrapper>

        </Box>
    );
};

export default ApplyforLoanCards;


const LoanAmount: React.FC<{ open: boolean; onClose: any }> = ({ open, onClose }) => {
    const { data } = useBusinessInfo();
    const { updateAmount, isLoading: loading, error } = useUpdateBusinessLoanAmount();
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const history = useHistory()
    const [isBusinessOpen, setIsBusinessOpen] = useState(false);

    const [updateToast, setUpdateToast] = useState({
        open: false,
        message: '',
        type: 'success',
    });

    const handleCloseToast = () => {
        setUpdateToast((prevToast) => ({
            ...prevToast,
            open: false,
        }));
    };

    const businessOptions = (data ?? []).map((business) => {
        return {
            label: business.business_name,
            value: business.id,
        };
    });


    const validate = (values: ValidationErrors) => {
        const errors: Partial<ValidationErrors> = {};

        if (!values?.loan_amount) {
            errors.loan_amount = 'required'
        }
        if (!values?.business_name) {
            errors.business_name = 'required'
        }

        return errors
    }

    const onSubmit = async (values: { business: number; loan_amount: string }, form: any) => {
        try {
            await updateAmount({
                id: values.business,
                loan_amount: parseFloat(removeCommas(values.loan_amount)),
            });
            setUpdateToast({
                open: true,
                message: 'Loan updated successfully',
                type: 'success',
            });
            form.reset()

        } catch (error) {
            console.log(error)
            setUpdateToast({
                open: true,
                message: (error as Error).message,
                type: 'error',
            });
        }
    }

    return (
        <DialogContent>
            <Typography sx={{ fontWeight: 600, textAlign: 'center', mb: 2 }}>Apply for loan</Typography>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.6 }}>
                            <Box>
                                <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}> Are you applying as an owner of the selected business below </Typography>
                                <Field
                                    type="select"
                                    name="business"
                                    component={Select}
                                    options={businessOptions}
                                />

                                <Button title='Add new business' onClick={() => isDesktop ? setIsBusinessOpen(true) : history.push(`/loans/apply/business`)} sx={{ fontSize: 'small' }} />
                            </Box>

                            <Field name="loan_amount" >
                                {(props: any) => <FormInput
                                    label="Loan amount"
                                    type="text"
                                    name="amount"
                                    value={numberWithCommas(props.input.value)}
                                    onChange={(e) => {
                                        props.input.onChange(removeCommas(e.target.value));

                                    }}
                                    {...props}
                                />}
                            </Field>

                            {error && <Alert severity="error" sx={{ textAlign: 'center' }}>{(error as Error).message}</Alert>}
                            <Toast {...updateToast} handleClose={() => handleCloseToast()} />
                            <Button title='Submit' type='submit' loading={loading} disabled={loading} variant='contained' />

                        </Box>
                    </form>
                )}
            />
            <Drawer anchor="right" open={isBusinessOpen && isDesktop} onClose={() => setIsBusinessOpen(false)} sx={{ '& .MuiPaper-root.MuiDrawer-paper': { width: '400px' } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ApplyBusinessLoan />
                </Box>
            </Drawer>
        </DialogContent>
    )
}