export const currencyOptionsArray = [
  { label: "--select currency--", value: "" },
  { label: "UGX", value: "UGX" },
  { label: "USD", value: "USD" },
];

export const memberRoleOptionsArray = [
  { label: 'Admin', value: "admin" },
  { label: "Member", value: "member" },
  { label: "Manager", value: "manager" },
];

export const transactionTypes = [
  { value: 'all', label: 'All' },
  { value: 'deposit', label: 'Deposit' },
  { value: 'withdrawal', label: 'Withdraw' },
  { value: 'revolving_payouts', label: 'Revolving Payouts' },
];

export const transactionCategoryTypes = [
  { value: 'all', label: 'All' },
  { value: 'wallet', label: 'Wallet' },
  { value: 'goal', label: 'Saving' },
  { value: 'request', label: 'Requests' },
  { value: 'transfer', label: 'Transfers' },

];

export const transactionFilters = [
  { value: 'all', label: 'All' },
  { value: 'deposit', label: 'Deposit' },
  { value: 'withdrawal', label: 'Withdraw' },
];

export const downloadOptions = [
  { value: 'all', label: 'All' },
  { value: 'deposit', label: 'Deposit' },
  { value: 'withdrawal', label: 'Withdraw' },
];

export const categoryOptions = [
  { value: 'all', label: 'All' },
  { value: 'deposit', label: 'Deposit' },
  { value: 'withdrawal', label: 'Withdraw' },
  { value: 'transfer', label: 'Transfer' },
  { value: 'pay', label: 'Pay' },
  { value: 'revolving', label: 'Revolving payout' },

];

export const durationFilters = [
  { value: '1day', label: '1D' },
  { value: '7days', label: '7D' },
  { value: '14days', label: '14D' },
  { value: '1month', label: '1M' },
  { value: '1year', label: '1Y' },
  { value: 'all', label: 'All' },
];

export const savingsFilters = [
  { value: 'all', label: 'All' },
  { value: 'private', label: 'Private' },
  { value: 'public', label: 'Public' },
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
];


export const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'completed', label: 'Completed' },
  { value: 'pending', label: 'Pending' },
  { value: 'failed', label: 'Failed' },
  { value: 'ongoing', label: 'Ongoing' },
  { value: 'cleared', label: 'Cleared' },
];


export const paymentOptions = [
  {
    label: "Show All",
    value: "all",
  },
  {
    label: "mobile money",
    value: "mobile money",
  },
  {
    label: "card",
    value: "card",
  },
  /*  {
     label: "bank",
     value: "bank",
   }, */
];



export const genderOptions = [
  { label: "male", value: "male" },
  { label: "female", value: "female" },
];
export const relationShipOptions = [
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Sibling", value: "Sibling" },
  { label: "Child", value: "Child" },
  { label: "Uncle", value: "Uncle" },
  { label: "Aunt", value: "Aunt" },
  { label: "Husband", value: "Husband" },
  { label: "Wife", value: "Wife" },
  { label: "Cousin", value: "Cousin" },
  { label: "Friend", value: "Friend" },
  { label: "Other", value: "Other" },
];

export const goalOptions = [
  { label: "Yes, i want to save in a group", value: "group" },
  { label: "No,i am going personal", value: "goal" },
];
export const groupOptions = [
  { label: "I want to create my own group", value: "new" },
  { label: "I want to save  in an existing group", value: "existing" },
];

export const privacyOptions = [
  { label: "private", value: "private" },
  { label: "public", value: "public" },
];

export const groupType = [
  { label: "Formal", value: true },
  { label: "Informal/casual", value: false },
];

export const transferTypes = [
  { label: "I just want to add a new owner", value: 'new-owner' },
  { label: "Yes, please proceed", value: 'member' },
];

export const legalStatus = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const adminOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const yesOrNoOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const adminAccessOptions = [
  {
    label: "Owner and/or admins",
    value: true,
  },
  { label: "Everybody to their own", value: false },
];

export const paymentMethodOptions = [
  { label: "Mobile Money", value: "Mobile Money" },

];

export const payOptions = [
  { label: "Mobile Money", value: "mobile" },
  /* { label: "Wallet", value: "wallet" }, */
  { label: "Transfer", value: "transfer" },
  { label: "Card", value: "card" },

];

export const withdrawOptions = [
  { label: "Mobile Money", value: "mobile" },
  /* { label: "Wallet", value: "wallet" }, */
  { label: "Transfer", value: "transfer" },
];

export const loanOptions = [
  { label: "Mobile Money", value: "mobile" },

  /* { label: "Wallet", value: "wallet" }, */
];

export const repayOptions = [
  { label: "Mobile Money", value: "mobile" },
  { label: "Card", value: "card" },
  { label: "Transfer", value: "transfer" },
];

export const memberRoleOptions = [
  { label: "Observer", value: "member" },
  { label: "Priveleged admin", value: "admin" },
];

export const legalTypes = [
  { label: "Associations(SLAs)", value: "association" },
  { label: "SACCOs/MPCs", value: "sacco/mpc" },
  { label: "Private companies", value: "private company" },
  { label: "Public companies", value: "public company" },
  { label: "Investment clubs", value: "investment club" },

];

export const informalOptions = [
  { value: "Sports clubs & teams", label: "Sports clubs & teams" },
  { value: "College clubs, groups & associations", label: "College clubs, groups & associations" },
  { value: "Friends & family", label: "Friends & family" },
  { value: "OBs & OGs (alums)", label: "OBs & OGs (alums)" },
  { value: "Colleagues / workmates", label: "Colleagues / workmates" },
  { value: "Boy & Girl groups", label: "Boy & Girl groups" },
];


export const legalDocuments = [
  { label: "Constitution/bylaws", value: "Constitution" },
  { label: "Certification of incorporation", value: "Certification of incorporation" },
  { label: "Trading License", value: "Trading License" },
  { label: "Business name", value: "Business name" },
];

export const registrationReasons = [
  { value: "Registration is in progress", label: "Registration is in progress" },
  { value: "I don’t know how to register", label: "I don’t know how to register" },
  { value: "I don’t have all requirements", label: "I don’t have all requirements" },
];

export const progressDocuments = [
  { value: "Constitution / bylaws*", label: "Constitution / bylaws*" },
  { value: "Business name", label: "Business name" },
  { value: "Certificate of incorporation", label: "Certificate of incorporation" },
  { value: "Trading License", label: "Trading License" },
  { value: "None of these", label: "None of these" },
];

export const availabilityDocuments = [
  { value: "Constitution / bylaws*", label: "Constitution / bylaws*" },
  { value: "Certificate of incorporation", label: "Certificate of incorporation" },
  { value: "Business name", label: "Business name" },
  { value: "Trading License", label: "Trading License" },
  { value: "None of these", label: "None of these" },
];

export const serviceDocuments = [
  { value: "Constitution / bylaws", label: "Constitution / bylaws(150,000 UGX)" },
  { value: "Certificate of incorporation", label: "Certificate of incorporation" },
  { value: "Trading License", label: "Trading License" },
];

export const document_types = ['government', 'institution', 'proof_of_address', 'financial_records', 'transaction', 'salary_loan', 'business_loan', 'profile'];


export const durationOptions = [

  {
    label: "91 days",
    rate: 12,
    value: 3,

  },
  {
    label: "182 days",
    rate: 16,
    value: 6,
  },
  {
    label: "273 days",
    rate: 18,
    value: 9,
  },
  {
    label: "365 days",
    rate: 21,
    value: 12,

  },
  {
    label: "2 years+",
    rate: 21,
    value: 'year',
  },
  {
    label: "View goals",
    rate: 0,
    value: ""
  }
];

export const intervalOptions = [
  {
    label: "Daily ",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Every 6 months",
    value: "Every 6 months",
  },
];

export const frequencyOptions = [
  {
    label: "Daily ",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Custom frequency",
    value: "custom",
  },
];



export const topUpOptions = [
  {
    label: "Make a one-time payment",
    value: "/amount",
  },
  {
    label: "Set up automatic payments",
    value: "/interval",
  },
];
export const contributionsType = [
  {
    label: "Directly",
    value: "directly",
    description: 'Shows exact contributions in figures'
  },
  {
    label: "Indirectly",
    value: "indirectly",
    description: 'Shows contributions in terms of X multiples of the minimum deposit amount'
  },
];
export const transferOptions = [
  {
    label: "Account Transfer",
    value: "/account",
  },
  {
    label: "Mobile Money",
    value: "/mobile",
  },
  /*  {
     label: "Bank Transfer",
     value: "/bank",
   }, */
];

export const autoTransferOptions = [
  { value: 'deposit', label: 'Deposit transfer' },
  { value: 'withdraw', label: 'Withdraw transfer' },
];

export const autoTransferfrequencyOptions = [
  { label: "Instant", value: "instant", },
  { label: "Hourly", value: "hourly", },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'custom', label: 'Custom frequency' },
];

export const sourceOptions = [
  { value: 'wallet', label: 'Wallet' },
  { value: 'goal', label: 'Goal' },
  { value: 'group', label: 'Group' },
];

export const autoTransferCustomOptions = [
  { value: 'day', label: 'day' },
  { value: 'week', label: 'week' },
  { value: 'month', label: 'month' },
  { value: 'year', label: 'year' },
]



export const socialPlatformOptions = [
  { value: 'Email', label: 'Email' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Twitter', label: 'Twitter' },
];

export const countries = [
  { value: 'Uganda', label: 'Uganda' },
  { value: 'United States', label: 'United States' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  { value: 'France', label: 'France' },
  { value: 'China', label: 'China' },
  { value: 'Japan', label: 'Japan' },
  { value: 'India', label: 'India' },
  { value: 'Australia', label: 'Australia' },
  // Add more countries as needed
];

export const beneficiaryOptions = [
  { value: 'primary', label: 'Primary beneficiary' },
  { value: 'secondary', label: 'Secondary beneficiary' },
  { value: 'optional', label: 'Optional beneficiary' },
];

export const governmentTypeOptions = [
  { label: 'National ID', value: 'National ID' },
  { label: 'Passport', value: 'Passport' },
  { label: "Driver's License", value: "Driver's License" },
];

export const institutionalTypeOptions = [
  { label: 'Work ID', value: 'Work ID' },
  { label: 'School ID', value: 'School ID' },
  { label: 'Other', value: 'other' },
];

export const proofOfAddressOptions = [
  { label: 'Bank statement', value: 'Bank statement' },
  { label: 'Utility bill', value: 'Utility Bill' },
];

export const businessTypeOptions = [
  { value: 'Business Name Registration', label: 'Business Name Registration' },
  { value: 'Trading License', label: 'Trading License' },
  { value: 'Certificate of Incorporation', label: 'Certificate of Incorporation' },
  { value: 'Investment License', label: 'Investment License' },
  { value: 'Industry-Specific or Professional License', label: 'Industry-Specific or Professional License' },
  { value: 'Tax Identification Number', label: 'Tax Identification Number' },
  { value: 'Employer Contract', label: 'Employer Contract' },
];

export const financialRecordsOptions = [
  { value: 'Receipts', label: 'Receipts' },
  { value: 'Trial balance', label: 'Trial balance' },
  { value: 'General ledger', label: 'General ledger' },
  { value: 'Balance sheets', label: 'Balance sheets' },
  { value: 'Income statement', label: 'Income statement' },
  { value: 'Other statements (P&L, FP, etc)', label: 'Other statements (P&L, FP, etc)' },
  { value: 'Audited books', label: 'Audited books' },
  { value: 'Employer contract*', label: 'Employer contract*' },
];

export const legalRecordsOptions = [
  { value: 'Constitutions/bylaws', label: 'Constitutions/bylaws' },
  { value: 'Resolutions', label: 'Resolutions' },
  { value: 'Agreements', label: 'Agreements' },
  { value: 'Notices', label: 'Notices' },
  { value: 'Other', label: 'Other' },
];




export const delayOptions = [
  { label: '12hr', value: 12 },
  { label: '24hr', value: 24 },
  { label: '48hr', value: 48 },
]

export const viewPermissions = [
  { value: 'view_goals', label: 'Can view goals' },
  { value: 'view_assets', label: 'Can view assets' },
  { value: 'view_wallet_transactions', label: 'Can view wallet transactions' },
  { value: 'view_member_profiles', label: 'Can view member profiles' },
  { value: 'view_voting_activity', label: 'Can view voting activity' },
];

/**loans options */
export const loanTypeOptions = [
  { label: 'Business owner', value: 'business' },
  { label: 'Salary contract employee', value: 'salary' },
]

export const businessDurationOptions = [
  { label: '6months to 1year', value: '6months to 1year' },
  { label: '1 to 2years', value: '1 to 2years' },
  { label: '3 to 5 years', value: '3 to 5 years' },
  { label: '5 years+', value: '5 years+' },

]

export const businessCategoryOptions = [
  { label: 'Services', value: 'services' },
  { label: 'Manufacturing', value: 'manufacturing' },
  { label: 'Trade (Buying & Selling goods)', value: 'trading' },
  { label: 'Agriculture', value: 'agriculture' },
  { label: 'Groups (SACCOs etc)', value: 'groups' },
  { label: 'Employed / Not business owner', value: 'employee' },
]

export const businessLoanTypeOptions = [
  { value: 'Business Name Registration', label: 'Business Name Registration' },
  { value: 'Trading License', label: 'Trading License' },
  { value: 'Certificate of Incorporation', label: 'Certificate of Incorporation' },
  { value: 'Investment License', label: 'Investment License' },
  { value: 'Industry-Specific or Professional License', label: 'Industry-Specific or Professional License' },
  { value: 'Tax Identification Number', label: 'Tax Identification Number' },
  { label: 'Other', value: 'other' },
];

export const employmentStatusOptions = [
  { label: 'Employed full-time', value: "full-time" },
  { label: 'Employed part-time', value: "part-time" },
  { label: 'National service', value: "national service" },
  { label: 'Civil servant', value: "civil servant" }
]

export const sendOptions = [
  { label: "Pasbanc", value: "pasbanc", subtitle: "" },
  { label: "Non-pasbanc user", value: "non-pasbanc", subtitle: "bank, mobile money" },
];

export const savingFrequencyOptions = [
  { label: "Anytime", value: "anytime", subtitle: "Manually deposit any amount at any time" },
  { label: "Onetime lump sum", value: "onetime", subtitle: "Deposit all the money at once only" },
  { label: "Automatic transfers", value: "auto-transfer", subtitle: "Deposit at any time according to your rules" },
];

export const KYCOptions = [
  { label: 'Personal', options: ['Personal Profile'] },
  { label: 'Institutional IDs', options: ['Work ID', 'School ID'] },
  { label: 'Government IDs', options: ['National ID', "Driver's license", 'Passport'] },
];


export const AccountKYCOptions = [
  { label: 'Institutional IDs', options: ['Work ID', 'School ID'] },
  { label: 'Government IDs', options: ['National ID', "Driver's license", 'Passport'] },
  { label: 'Proof of address', options: ['Bank statement', "Utility bills"] },
  { label: 'Others', options: ['Financial documents', "Legal documents"] },
];