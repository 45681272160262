import React, { useState, useEffect, useRef } from "react";
import Button from "../../components/Reusables/Input/Button";
import { useStyles } from "./ProfileStyles";
import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Skeleton, Typography,useMediaQuery,Theme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useQuery } from "../../utils/middleware";
import Toast from "../../components/Reusables/Feedback/Toast";
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { PaymentMethod } from "../../types/types";
import { useAddCardPaymentMethod, useDeletePaymentMethod, usePaymentMethods } from "../../hooks/paymentMethodHooks";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from "react-router-dom";
import AddPhoneNumber from "../../components/Modals/AddPhoneNumber";
import CreatePaymentMethod from "../../components/profile/CreatePaymentMethod";
import { addCardData } from "../../types/actionTypes";

const PaymentMethods =()=> {
  const { data: paymentMethodsData,isLoading,isError  } = usePaymentMethods(); 
  const [isAddPhoneNumberModalOpen, setIsAddPhoneNumberModalOpen] = useState(false);
  const [openModal, setModalOpen] = useState(false);
  const { addCardPaymentMethod } = useAddCardPaymentMethod();
   const isDesktop = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));

  const {deletePaymentMethod} = useDeletePaymentMethod();
  const history = useHistory()

  const [deleteToast, setDeleteToast] = useState({
    open: false,
    message: '',
    type: 'error', 
  });

  const handleCloseToast = (toastType:string) => {
     if (toastType === "delete") {
      setDeleteToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const handleAddCard = async (formData: addCardData) => {
    try {
      // Call the addCardPaymentMethod function from the hook
      await addCardPaymentMethod(formData);
       setDeleteToast({
        open: true,
        message: 'Card added successfully',
        type: 'success',
      });
      // Handle any additional logic after successfully adding the card
    } catch (error:any) {
      // Handle errors here, if needed
      setDeleteToast({
        open: true,
        message: 'An error occurred while deleting the payment method.',
        type: error.message,
      });
    }
  };
  

  
  

  let query = useQuery();

 

  useEffect(() => {
    const resp = query.get("transaction_id")
     // If there is no 'resp' query parameter, return without doing anything
     if (!resp) {
      return;
    }

    let response: number = JSON.parse(resp);

    handleAddCard({ id:response });

    
  }, []);

  const openAddPhoneNumberModal = () => {
    setIsAddPhoneNumberModalOpen(true);
  };

  const closeAddPhoneNumberModal = () => {
    setIsAddPhoneNumberModalOpen(false);
  };

  const handleDeletePaymentMethod = async (id:number) => {
    try {
      await deletePaymentMethod(id);
      setDeleteToast({
        open: true,
        message: 'Payment method deleted successfully.',
        type: 'success',
      });
    } catch (error) {
      // Handle error here (show a toast, display an error message, etc.)
      console.error('Error deleting payment method:', error);
      setDeleteToast({
        open: true,
        message: 'An error occurred while deleting the payment method.',
        type: 'error',
      });
    }
  };

  
  
  const phoneMethods: PaymentMethod[] = paymentMethodsData?.phone || []
  const cardMethods: PaymentMethod[] = paymentMethodsData?.card || []
  const bankMethods: PaymentMethod[] = paymentMethodsData?.bank || []
  const classes = useStyles();
  return (
    <Box m={2}>
      <Box sx={{display:'flex',alignItems:'center',gap:1,mt:2}}>
        {!isDesktop && (
         <IconButton onClick={()=>history.push(`/profile/settings`)} sx={{p:0}}>
            <ArrowBackOutlinedIcon  color='inherit' fontSize='small'/>
         </IconButton> 
        )}
        <Typography variant="h5" fontWeight={600} sx={{m:'0 auto'}}>
        Payment methods
      </Typography>
    </Box>
    <Box>
      <List sx={{maxWidth:'500px',margin:'16px auto'}}>
      {isError || isLoading ?  <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>:phoneMethods && (
              <>
                <ListItem disablePadding>
                  <ListItemText primary="Phone numbers" primaryTypographyProps={{fontWeight:600}} />
                  <ListItemSecondaryAction sx={{right:0}}>
                   <Button title='Add new' onClick={openAddPhoneNumberModal}/>
                  </ListItemSecondaryAction>
                </ListItem>
                {phoneMethods.map((method:PaymentMethod) => (
                  <ListItem key={method.id} disablePadding>
                    <ListItemText primary={method.phone_number} />
                    <ListItemSecondaryAction sx={{right:0}}>
                   <IconButton onClick={()=>handleDeletePaymentMethod(method.id)}>
                    <CloseIcon />
                   </IconButton>
                  </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </>
            )}
          </List>

          <List sx={{maxWidth:'500px',margin:'16px auto'}}>
        {isError || isLoading ?  <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>:bankMethods && (
              <>
                <ListItem disablePadding>
                  <ListItemText primary="Accounts & cards" primaryTypographyProps={{fontWeight:600}}/>
                  <ListItemSecondaryAction sx={{right:0}}>
                    <Button title="Add new" onClick={()=>setModalOpen(!openModal)}/>
                  </ListItemSecondaryAction>
                </ListItem>
                {bankMethods.map((method:PaymentMethod) => (
                  <ListItem key={method.id} disablePadding>
                    <ListItemText
                      primary={<Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <Typography fontWeight={600}>{method.bank_acc.name ?? ''}</Typography>
                     <IconButton edge="end" aria-label="close" onClick={()=>handleDeletePaymentMethod(method.id)}>
                      <CloseIcon fontSize='small' />
                    </IconButton>
                      </Box>}
                      secondary={<Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                        <Typography>{`Account number:${method.acc_no}`}</Typography>
                     <IconButton edge="end" aria-label="copy" sx={{py:0}}>
                      <FileCopyIcon fontSize='small' />
                    </IconButton>
                      </Box>}
                    
                    />
                  
                  </ListItem>
                ))}
        {isError || isLoading ?  <Box sx={{ pt: 0.5 }}>
        <Skeleton />
          <Skeleton width="60%" />
        </Box>:cardMethods.map((method:PaymentMethod) => (
                  <ListItem key={method.id} disablePadding>
                    <ListItemText
                      primary={<Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <Typography fontWeight={600}>{method.card.name ?? 'Bank Card'}</Typography>
                     <IconButton edge="end" aria-label="close" onClick={()=>handleDeletePaymentMethod(method.id)}>
                      <CloseIcon fontSize='small' />
                    </IconButton>
                      </Box>}
                      secondary={<Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                        <Typography>{`Card number: *****${method.card.last_4digits}`}</Typography>
                     <IconButton edge="end" aria-label="copy" sx={{py:0}}>
                      <FileCopyIcon fontSize='small' />
                    </IconButton>
                      </Box>}
                    
                    />
                  
                  </ListItem>
                ))}
              </>
            )}
          </List>

      </Box>
      {
        isAddPhoneNumberModalOpen && <AddPhoneNumber open={isAddPhoneNumberModalOpen} handleClose={closeAddPhoneNumberModal}/>
      }
      {
        openModal && (<CreatePaymentMethod open={openModal} onClose={()=>setModalOpen(false)}/>)
      }
      <Toast {...deleteToast} handleClose={() => handleCloseToast("delete")} />
    </Box>
  );
}




export default PaymentMethods;
