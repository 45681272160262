import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import subscriptionsApiClient from '../apis/apiServices/subscriptions';
import { CreateSubscriptionData } from '../types/actionTypes';




export const useCreateSubscription = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation((params:CreateSubscriptionData)=>subscriptionsApiClient.create(params), {
    onSuccess: (_,variables) => {
      if(variables.type === 'deposit'){
      queryClient.invalidateQueries(['goal',variables.destination]);
      queryClient.invalidateQueries(['pocket',variables.destination]);
      queryClient.invalidateQueries(['user','wallet']);
      queryClient.invalidateQueries(['group','wallet',variables.destination]);


      }else{
      queryClient.invalidateQueries(['goal',variables.source]);
      queryClient.invalidateQueries(['pocket',variables.source]);
      queryClient.invalidateQueries(['user','wallet']);
      queryClient.invalidateQueries(['group','wallet',variables.source]);

      }
      // Invalidate the 'subscriptions' query after a successful creation
    },
  });

  const createSubscription = async (params:CreateSubscriptionData) => {
    try {
      const response = await createMutation.mutateAsync(params);
      return response.data
    } catch (error) {
      console.error('Error creating subscription by mobile:', error);
      throw new Error('Error creating subscription by mobile:');
    }
  };

  return {
    createSubscription,
    ...createMutation
  };
}



export const useUpdateSubscription = () => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation(
  (params: { id: number; data: any }) =>
    subscriptionsApiClient.updateById(params.id, params.data),
  {
    onSuccess: (_,variables) => {
      // Invalidate the relevant subscription query after a successful update
      if(variables.data.type === 'deposit'){
      queryClient.invalidateQueries(['goal',variables.data.destination]);
      queryClient.invalidateQueries(['pocket',variables.data.destination]);
      queryClient.invalidateQueries(['user','wallet']);
      queryClient.invalidateQueries(['group','wallet',variables.data.destination]);


      }else{
      queryClient.invalidateQueries(['goal',variables.data.source]);
      queryClient.invalidateQueries(['pocket',variables.data.source]);
      queryClient.invalidateQueries(['user','wallet']);
      queryClient.invalidateQueries(['group','wallet',variables.data.source]);

      }
    },
  }
);

  const updateSubscription = async (params:{id:number, data:any}) => {
    try {
      await updateMutation.mutateAsync(params);
    } catch (error) {
      console.error('Error updating subscription:', error);
      throw new Error('Error updating subscription:');
    }
  };

  return {
    updateSubscription,
    ...updateMutation
  };
};





export const useDeleteSubscription = () => {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation((id:number)=>subscriptionsApiClient.deleteById(id), {
    onSuccess: () => {
      // Invalidate the 'subscriptions' query after a successful deletion
      queryClient.invalidateQueries(['subscriptions']);
    },
  });

  const deleteSubscription = async (subscriptionId: number) => {
    try {
      await deleteMutation.mutateAsync(subscriptionId);
    } catch (error) {
      console.error('Error deleting subscription:', error);
      throw new Error('Error deleting subscription:');
    }
  };

  return {
    deleteSubscription,
   ...deleteMutation
  };
};
