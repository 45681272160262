import { useStyles } from "./ProfileStyles";
import { useHistory } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import NotificationList from "../../components/profile/NotificationList";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNotifications, useUpdateNotifications } from "../../hooks/notificationHooks";
import { Notification as NotificationType } from "../../types/types";


function Notifications() {
  const history = useHistory();
  const notifications = useNotifications();

  const { updateNotifications } = useUpdateNotifications();

  const handleUpdateNotifications = () => {
    const ids = notifications.data.map((notification:NotificationType)=>notification.id)
    updateNotifications(ids);
  };


 
  return (
    <Box m={2}>
      <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <Box sx={{display:'flex',alignItems:'center',gap:1}}>
        <IconButton onClick={()=>history.goBack()} sx={{p:0}}>
            <ArrowBackOutlinedIcon />
         </IconButton>
        <Typography fontWeight={600}>Notifications</Typography>
        </Box>
        {notifications.data?.length > 0 && (
        <Box sx={{cursor:'pointer'}} onClick={handleUpdateNotifications}>
          <Typography>Mark as read</Typography>
        </Box>
        )}
      </Box>
        <NotificationList notifications={notifications} />
    </Box>
  );
}


export default Notifications;
