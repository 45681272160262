import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  useMediaQuery,Theme
} from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useUpdateUser, useUser } from '../../hooks/userHooks';


const balanceSettings = [
  {
    title: 'Goals',
    description: 'Turn your personal goals balance visibility on or off',
    type: 'goals'
  },
  {
    title: 'Groups',
    description: 'Turn your personal group balances visibility on or off',
    type: 'groups'
  },
 /*  {
    title: 'Wallets',
    description: 'Turn your wallet balances visibility on or off',
    type: 'wallets',
  }, */
  {
    title: 'Loans',
    description: 'Turn your loans balances visibility on or off',
    type: 'loans',
  },
  {
    title: 'Home page',
    description: 'Turn your home page balances visibility on or off',
    type: 'home'
  }
]

type BalanceType = 'goals' | 'groups' | 'loans' | 'wallets' | 'home';


const BalancesVisibility = () => {
  const history = useHistory();
   const isDesktop = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));

  const { data: userData} = useUser();
  const { updateUser } = useUpdateUser();


  type BalancesVisibility = {
  goals: boolean;
  groups: boolean;
  loans: boolean;
  wallets: boolean;
  home: boolean;
};



  const [balancesVisibility, setBalancesVisibility] = useState<BalancesVisibility>({
    goals: userData?.goals_visibility || false,
    groups: userData?.groups_visibility || false,
    loans: userData?.loans_visibility || false,
    wallets: userData?.wallets_visibility || false,
    home: userData?.home_visibility || false,
  });


  const handleVisibilityChange = (balanceType:BalanceType) => (event: React.ChangeEvent<HTMLInputElement>) => {
   const updatedVisibility = {
      ...balancesVisibility,
      [balanceType]: event.target.checked,
    };
    setBalancesVisibility(updatedVisibility);
    try {
     updateUser({
        goals_visibility: updatedVisibility.goals,
        groups_visibility: updatedVisibility.groups,
        loans_visibility: updatedVisibility.loans,
        wallets_visibility: updatedVisibility.wallets,
        home_visibility: updatedVisibility.home,
      });
  } catch (error) {
    console.error('Error updating user settings:', error);
  }
  };

  return (
    <Box m={2}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
        {!isDesktop && (
          <IconButton onClick={() => history.goBack()} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon color="inherit" fontSize="small" />
        </IconButton>
        )}
        <Typography variant="body1" fontWeight={600} sx={{m:'0 auto'}}>
          Balances visibility
        </Typography>
      </Box>
      <List>
        {balanceSettings.map((setting) => (
        <ListItem sx={{pl:0}} key={setting.type}>
          <ListItemText primary={`${setting.title}`} secondary={`${setting.description}`} />
          <ListItemSecondaryAction sx={{right:'-3px'}}>
            <Switch
            checked={balancesVisibility[setting.type as BalanceType]}
            onChange={handleVisibilityChange(setting.type as BalanceType)}
            />
          </ListItemSecondaryAction>
        </ListItem>
       ))}
      </List>
    </Box>
  );
};

export default BalancesVisibility;
