import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';
import {numberWithCommas } from '../../utils/middleware';
import { durationOptions } from '../Reusables/Input/select/Options';

interface Props {
  amount: number;
  currency:string;
  duration:number
}


interface EarningsResult {
  rate: number;
  earnings: number;
}

const intervals = [
  {name:'Yearly',value:'yearly'},
  {name:'Monthly',value:'monthly'},
  {name:'Weekly',value:'weekly'},
  {name:'Daily',value:'daily'}
]

function calculateEarnings(
  principal: number,
  selectedDuration: number ,
  period: string = 'yearly'
): EarningsResult {
  // Find the interest rate based on selected duration
  const selectedOption = durationOptions.find(option => option.value === selectedDuration);
  const yearlyRate = selectedOption ? selectedOption.rate : 26;

  // Convert the annual interest rate to a decimal
  const annualInterestRate = yearlyRate / 100;

  let earnings = 0;
  let rate = yearlyRate

  // Calculate earnings based on the selected period using the simple interest formula
  switch (period) {
    case 'monthly':
      // Calculate monthly earnings
      earnings = (principal * annualInterestRate / 12) ; // Monthly rate * 12 months
      rate = yearlyRate /12;
      break;
    case 'weekly':
      // Calculate weekly earnings
      earnings = (principal * annualInterestRate / 52) ; // Weekly rate * 52 weeks
      rate = yearlyRate /52;

      break;
    case 'daily':
      // Calculate daily earnings
      earnings = (principal * annualInterestRate / 365) ; // Daily rate * 365 days
      rate = yearlyRate /365;
      break;
    case 'yearly':
    default:
      // Calculate yearly earnings (default)
      earnings = principal * annualInterestRate;
      break;
  }

  return {
    rate: rate,
    earnings: earnings,
  };
}



const IntervalTabs:FC<Props> = ({amount,currency,duration}) => {
  const [activeTab, setActiveTab] = useState(0);
  const {earnings,rate} = calculateEarnings(amount,duration, intervals[activeTab].value);


  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Box sx={{display:'flex',flexDirection:'column',gap:0.5}}>
      <Box sx={{display:'flex',border:'0.7px solid  #C2B0E2',borderRadius:'8px',justifyContent:{lg:'space-around'}}}>
        {intervals.map((interval, index) => (
          <Typography variant='caption' 
          key={index}
          sx={{
            m:0.5,
            px:1,
            cursor:'pointer',
            borderRadius:'0.5rem',
            color: index === activeTab ? '#fff' : 'text.primary',
            fontWeight: index === activeTab ? 'bold' : 'regular',
            backgroundColor:index === activeTab ? '#A489D4':'transparent'
          }} 
          onClick={() => handleTabClick(index)}>
            {interval.name}
          </Typography>
        ))}
      </Box>
      <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <Typography variant='body2' sx={{fontWeight:600}}>{`${numberWithCommas(earnings.toFixed(2))} ${currency}`}</Typography>
        <Typography variant='body2' sx={{fontWeight:600}}>{`${(rate).toFixed(2)} %`}</Typography>
      </Box>
    </Box>
  );
};

export default IntervalTabs;
