import { Field } from "react-final-form";
import { Box, Typography } from "@mui/material";
import FormInput from "../../Reusables/Input/FormInput";
import { SendFormValues } from "../../Modals/SendMoney";
import { useBanks } from "../../../hooks/paymentMethodHooks";
import RadioButtons from "../../Reusables/Input/RadioGroup";
import { withdrawOptions } from "../../Reusables/Input/select/Options";
import Select from "../../Reusables/Input/select/Select";

export interface NonPasbancUserStepProps {
    values: SendFormValues;
}

const NonPasbancUserStep: React.FC<NonPasbancUserStepProps> = ({ values }) => {
    const { data: banks, } = useBanks();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.6, mt: 2 }}>
            <Field name="contact" label='Full name' >
                {({ input, meta }) => (
                    <>
                        <FormInput
                            label='Full name'
                            value={input.value.name}
                            onChange={(e) => {
                                input.onChange({
                                    name: e.target.value,
                                    username: ''
                                });
                            }}
                        />
                        <Typography color='error' variant='caption'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                    </>
                )}
            </Field>
            <Box>
                <Typography fontWeight={600}>Payment Method</Typography>
                <Field name="paymentMethod" >
                    {(props: any) => <RadioButtons
                        options={withdrawOptions}
                        onChange={(e) => {
                            props.input.onChange(e.target.value);
                        }}
                        {...props}
                    />}
                </Field>
            </Box>

            {values.paymentMethod === "mobile" ? (
                <div >
                    <Field
                        label="Enter phone number"
                        name="phoneNumber"
                        component={FormInput}
                        type="string"
                    />

                </div>
            ) : values.paymentMethod === "bank" ? (
                <div >
                    <Box>
                        <Typography fontWeight={600}>Select Bank</Typography>
                        <Field name="bank" flutterwaveBanks={banks ?? []} component={Select}
                            type="select" />
                    </Box>
                    <Field label="Account number" name="accountNumber" component={FormInput}
                        type="number" sx={{ mt: 2 }} />
                </div>
            ) : null}
        </Box>
    )
}

export default NonPasbancUserStep;