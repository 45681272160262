import { useState, useEffect, useRef } from "react";
import { Avatar, Box, Chip, IconButton, SvgIcon, Typography } from "@mui/material";
import Button from "../../components/Reusables/Input/Button";
import FormInput from "../../components/Reusables/Input/FormInput";
import Divider from "../../components/Reusables/Display/Divider";
import { useStyles } from "./loginStyles";
import { Form, Field, FormSpy } from "react-final-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";
import { useLogin } from '../../hooks/authHooks';
import { Link, useHistory } from "react-router-dom";
import CustomLink from "../../components/Reusables/Input/CustomLink";
import { useStyles as useButtonStyles } from '../../components/Reusables/Input/ButtonStyles';


function EmailLogin() {
  const [isComponentMounted, setComponentMounted] = useState(true);


  // Cleanup function to set isMounted to false when the component unmounts
  useEffect(() => {
    return () => {
      // The component is unmounting, set the mounted state to false
      setComponentMounted(false);
    };
  }, []);

  const { loginByEmail, loading, error } = useLogin();
  const history = useHistory()
  const [loginMethod, setLoginMethod] = useState('password');

  const [showPassword, setPassword] = useState(false);
  const validate = (values: any) => {
    const errors: { email?: string, password?: string, pin?: string } = {};
    if (!values.email) {
      errors.email = "Required";
    }
    if (loginMethod === 'password' && !values.password) {
      errors.password = "Required";
    }
    if (loginMethod === 'pin' && !values.pin) {
      errors.pin = "Required";
    }
    return errors;
  };
  /**submit form data */
  const onSubmit = async (values: { email: string, password: string, pin: string }) => {
    const data = {
      [loginMethod]: loginMethod === 'password' ? values.password : values.pin,
      email: values.email,
    };

    try {
      await loginByEmail(data);
      if (!isComponentMounted) {
        return; // If the component has unmounted, don't update state
      }
      history.push("/", { fromLogin: true });

    } catch (error) {
      if (!isComponentMounted) {
        return; // If the component has unmounted, don't update state
      }

      console.log(error);
    }
  };

  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  return (
    <div className={classes.container}>
      <Box className={classes.logoContainer}>
        <Avatar alt="Pasbanc" src="/logo.png" />
        <Typography variant='h5'>Pasbanc</Typography>
      </Box>
      <Box className={classes.formContainer}>
        <Box>
          <Typography variant="h2"> Log in</Typography>
          <Typography variant="body1">Start your Pasbanc journey here</Typography>
        </Box>

        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form className={classes.customForm} onSubmit={handleSubmit}>
              <Box className={classes.textFieldContainer}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography fontWeight={600}>Email</Typography>
                  <Chip label={<Typography variant="button" component='span'>Use <Typography variant="body2" fontWeight={700} component='span'>phone</Typography> instead</Typography>} component={Link} to={`/auth/login/mobile`} clickable />
                </Box>
                <Field name="email">
                  {(props: any) => <FormInput
                    label=""
                    type="email"
                    name="email"
                    {...props}
                  />}
                </Field>

              </Box>
              {
                loginMethod === 'pin' ?
                  (
                    <div className={classes.textFieldContainer}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography fontWeight={600}>PIN</Typography>
                        <Chip label={<Typography variant="button" component='span'>Use <Typography variant="body2" fontWeight={700} component='span'>password</Typography> instead</Typography>} onClick={() => setLoginMethod('password')} />
                      </Box>
                      <Field name="pin">
                        {(props: any) => <FormInput
                          label=""
                          type="text"
                          {...props}
                        />}
                      </Field>


                      <div style={{ marginTop: "0.5rem" }}>
                        <CustomLink
                          variant="body2"
                          title="Forgot PIN?"
                          href="/auth/reset/pin/email"
                          sx={{ width: "auto", textAlign: 'left', mt: "0.5rem" }}
                        />
                      </div>
                    </div>
                  )
                  : (
                    <div className={classes.textFieldContainer}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography fontWeight={600}>Password</Typography>
                        <Chip label={<Typography variant="button" component='span'>Use <Typography variant="body2" fontWeight={700} component='span'>PIN</Typography> instead</Typography>} onClick={() => setLoginMethod('pin')} />
                      </Box>
                      <Field name="password">
                        {(props: any) => <FormInput
                          label=""
                          type={showPassword ? "text" : "password"}
                          Icon={
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setPassword(!showPassword)}
                              size="large"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          }
                          {...props}
                        />}
                      </Field>


                      <div style={{ marginTop: "0.5rem" }}>
                        <CustomLink
                          variant="body2"
                          title="Forgot Password?"
                          href="/auth/reset/password/email"
                          sx={{ width: "auto", textAlign: 'left', mt: "0.5rem" }}
                        />
                      </div>
                    </div>
                  )}
              {error && <Alert severity='error'>{(error as Error).message}</Alert>}
              <Box mt={"1.12rem"}>
                <Button
                  title="Log in"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  className={buttonClasses.largeButton}
                  disabled={submitting || loading}
                />
              </Box>
            </form>
          )}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
          <Typography variant='body2'>Don't have an account yet?</Typography>
          <Button
            variant="text"
            title="Create account"
            href="/auth/register/email"
            sx={{ mt: "0.56rem", backgroundColor: "#F0EBF8" }}
          />
        </Box>
      </Box>
    </div>
  );
}

export default EmailLogin
