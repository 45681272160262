import { Route, Switch } from "react-router-dom";
import authGuard from "../utils/auth";
import LoanHub from "../pages/loans/LoanHub";
import LoanHistory from "../pages/loans/LoanHistory";
import ApplyBusinessLoan from "../pages/loans/ApplyBusinessLoan";
import ApplySalaryLoan from "../pages/loans/ApplySalaryLoan";
import LoanProfile, { ReplaceEmployer } from "../pages/loans/LoanProfile";
import LoanPage from "../pages/loans/LoanPage";


const LoanRoutes = () => {
  return (
    <Switch>
       <Route  exact path="/loans/history" component={authGuard(LoanHistory)} />
        <Route exact path="/loans/apply/business" component={authGuard(ApplyBusinessLoan)} />
        <Route exact path="/loans/apply/salary" component={authGuard(ApplySalaryLoan)} />
        <Route exact path="/loans/profile" component={authGuard(LoanProfile)} />
          <Route  exact path="/loans/:id" component={authGuard(LoanPage)} />
         <Route exact path="/loans/new-employer" component={authGuard(ReplaceEmployer)} />
      <Route  path="/" component={authGuard(LoanHub)} />
    </Switch>
  );
};

export default LoanRoutes;
