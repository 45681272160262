import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme:Theme) => ({
  container: {
    margin: "32px 16px 0px 16px",
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      maxWidth: "1000px",
      margin: "32px auto 0px auto",
      padding: "0px 16px",
    },
  },
  commonContainer: {
    display: "flex",
    flexDirection: "column",
  },
  
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  /**image for goal item */
  imageAvatar: {
    height: "50px",
    width: "50px",
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
 
  goalContainer: {
    minWidth: "300px",
    maxWidth:"400px",
    backgroundColor: "white",
    border: "0.4px solid #E2E8F0",
    boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
    padding: "13px 14px",
    marginTop: "16px",
    width: "100%",
    cursor: "pointer",

    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      /**leave some room for spacing so we dont go to 50% */
      flexBasis: "40%",
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
      /**leave some room for spacing so we dont go to 50% */
      flexBasis: "35%",
    },
  },
  goalContent: {
    marginTop: "12px",
    display: "block",
  },
  progressContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "8px",
  },
  /**list of goals */
  goals: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "scroll",
    gap: "8px",
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      justifyContent: "flex-start",
    },
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      gap: "4px",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      justifyContent: "flex-start",
    },
  },
 
}));
