import { useMutation,useQuery,useQueryClient } from '@tanstack/react-query';
import paymentMethodsApiClient from '../apis/apiServices/paymentmethods';
import { addCardData, VerifyCardData ,PaymentMethodData} from '../types/actionTypes';
import { PaymentMethodsGroupedByType } from '../types/types';



const payment_methods: PaymentMethodsGroupedByType = {
  card: [],
  phone: [],
  bank: [],
};




interface Bank {
  id:number;
  code:string;
  name:string
}



export const usePaymentMethods = () => {
    return useQuery<PaymentMethodsGroupedByType,Error>(['payment-methods'], paymentMethodsApiClient.getAll,{
      placeholderData:payment_methods,
    });
  };

 export const useBanks = () => {
    return useQuery<Bank[],Error>(['banks'], paymentMethodsApiClient.getBanks,{
      placeholderData:[],
    });
  };
  
  
export const useCreatePaymentMethod = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(paymentMethodsApiClient.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['payment-methods']);
    },
  });

  const createPaymentMethod = async (data:PaymentMethodData) => {
    try {
      const response = await mutation.mutateAsync(data);
      return response.data;
    } catch (error) {
      console.error('Error creating payment method:', error);
      throw new Error('Error creating payment method');
    }
  };

  return {
    ...mutation,
    createPaymentMethod,
  };
};

  
  
  export const useDeletePaymentMethod = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(paymentMethodsApiClient.deleteById, {
    onSuccess: () => {
      queryClient.invalidateQueries(['payment-methods']);
    },
  });
  
    const deletePaymentMethod = async (id: number) => {
      try {
        await mutation.mutateAsync(id);
      } catch (error) {
        console.error('Failed to delete payment method:', error);
      }
    };
  
    return {
      deletePaymentMethod,
      ...mutation
    };
  };
  

 export const useVerifyCard = () => {
    const mutation = useMutation(paymentMethodsApiClient.verifyCard,{
      onSuccess:(data)=>{
       console.log(data)
    }
    });
  
    const verifyCard = async (data:VerifyCardData) => {
      try {
       const res = await mutation.mutateAsync(data);
       if(res.url){
        window.location.href = res.url
       }
      } catch (error) {
        console.error('Failed to verify card:', error);
      }
    };
  
    return {
      verifyCard,
     ...mutation
    };
  };
  
 export const useAddCardPaymentMethod = () => {
        const queryClient = useQueryClient();

    const mutation = useMutation(paymentMethodsApiClient.addCard, {
    onSuccess: () => {
      queryClient.invalidateQueries(['payment-methods']);
    },
  });
  
    const addCardPaymentMethod = async (data: addCardData) => {
      try {
        await mutation.mutateAsync(data);
      } catch (error) {
        console.error('Failed to add card as payment method:', error);
      }
    };
  
    return {
      addCardPaymentMethod,
     ...mutation,
    };
  };
  