import { Box } from "@mui/material";

interface DotSeparatorProps {
    height?: string;
    width?: string;
    color?: string;
}

const DotSeparator = ({ height='0.25rem', width='0.25rem', color='#000000' }: DotSeparatorProps) => {
    return <Box height={height} width={width} borderRadius="60%" sx={{ backgroundColor: color }} />;
}

export default DotSeparator;