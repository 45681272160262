import React from "react";
import Skeleton from '@mui/material/Skeleton';
function TransactionsSkeleton() {
  return (
    <div>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave"></Skeleton>
    </div>
  );
}

export default TransactionsSkeleton;
