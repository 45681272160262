import React, { useState } from 'react';
import { Typography, List, ListItem, ListItemText, IconButton, ListItemSecondaryAction, Box, DialogContent, DialogTitle, Alert, Skeleton, useMediaQuery, Theme, Drawer } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { capitalizeWords, extractTextFromImage, telephoneCheck } from '../../utils/middleware';
import { useCreateKycDocument, useCreateWitness, useDeleteDocumentById, useDeleteWitness, useFetchWitnesses, useUserDocuments } from '../../hooks/userHooks';
import CloseIcon from '@mui/icons-material/Close';
import { KYCDocument, Witness } from '../../types/types';
import Button from '../../components/Reusables/Input/Button';
import { Form, Field } from 'react-final-form';
import { countries, document_types, financialRecordsOptions, governmentTypeOptions, institutionalTypeOptions, relationShipOptions } from '../../components/Reusables/Input/select/Options';
import Select from '../../components/Reusables/Input/select/Select';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import FormInput from '../../components/Reusables/Input/FormInput';
import Toast from '../../components/Reusables/Feedback/Toast';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CountryInput from '../../components/Reusables/Input/CountryInput';
import { FormApi } from 'final-form';
import ModalWrapper from '../../components/Modals/ModalWrapper';


export const KYC: React.FC = () => {
  const history = useHistory();
  return (
    <Box m={2}>
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 2 }}>
        <IconButton onClick={() => history.push(`/profile`)} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
          KYC
        </Typography>
      </Box>
      <KYCDetails />
    </Box>
  );
};

export const KYCDetails = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [openKYC, setOpenKYC] = useState(false);
  const history = useHistory()
  const kycItems = [
    { title: 'Governmental', description: 'Your government issued identity documents (IDs)', link: '/profile/kyc/governmental' },
    { title: 'Institutional', description: 'Formally and/or socially recognized institutional identity documents (IDs)', link: '/profile/kyc/institutional' },
    { title: 'Proof of Address', description: 'Institutionally issued documents that prove your residence', link: '/profile/kyc/proof-of-address' },
    { title: 'Witnesses', description: 'People who can verify your information and/or are accountability partners', link: '/profile/kyc/witnesses' },
    { title: 'Financial Records', description: 'Personal financial documents', link: '/profile/kyc/financial-records' },
  ];

  const handleClick = (type: string) => {
    if (type === 'Governmental') {
      if (isDesktop) {
        setActiveTab(0)
        setOpenKYC(true)
      } else {
        history.push('/profile/kyc/governmental')
      }
    } else if (type === 'Institutional') {
      if (isDesktop) {
        setActiveTab(1)
        setOpenKYC(true)
      } else {
        history.push('/profile/kyc/institutional')
      }
    } else if (type === 'Proof of Address') {
      if (isDesktop) {
        setActiveTab(2)
        setOpenKYC(true)
      } else {
        history.push('/profile/kyc/proof-of-address')
      }
    } else if (type === 'Witnesses') {
      if (isDesktop) {
        setActiveTab(3)
        setOpenKYC(true)
      } else {
        history.push('/profile/kyc/witnesses')
      }
    } else if (type === 'Financial Records') {
      if (isDesktop) {
        setActiveTab(4)
        setOpenKYC(true)
      } else {
        history.push('/profile/kyc/financial-records')
      }
    }
  }

  return (
    <List sx={{ flex: { lg: 0.7 } }}>
      <ListItem>
        {/* <ListItemText primary="Legal Names" secondary="Belinda Marion Kobusingye" /> */}
      </ListItem>
      {kycItems.map((item, index) => (
        <ListItem key={index} onClick={() => handleClick(item.title)}>
          <ListItemText primary={item.title} secondary={item.description} />
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={() => handleClick(item.title)} color='inherit'>
              <ArrowForwardIosOutlinedIcon fontSize='small' />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      <Drawer open={openKYC} onClose={() => setOpenKYC(false)} anchor='right' sx={{
        '& .MuiPaper-root.MuiDrawer-paper': {
          width: '400px'
        }
      }}>
        {activeTab === 0 ? <GovernmentalKYC /> : activeTab === 1 ? <InstitutionalKyc /> : activeTab === 2 ? <ProofOfAddress /> : activeTab === 3 ? <Witnesses /> : activeTab === 4 ? <FinancialRecords /> : null}
      </Drawer>
    </List>
  )
}


export const GovernmentalKYC: React.FC = () => {
  const history = useHistory();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };

  const { data: governmentalIDs, isLoading, isError } = useUserDocuments({ document_type: document_types[0] })
  const { deleteDocument, } = useDeleteDocumentById();
  const handleDeleteDocument = async (documentId: number) => {
    try {
      // Call the deleteDocument function from the hook
      await deleteDocument(documentId);
      setUpdateToast({
        open: true,
        message: "Document deleted",
        type: "success",
      });
    } catch (error: any) {
      setUpdateToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };




  return (
    <Box m={2}>
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 2 }}>
        <IconButton onClick={() => history.push(`/profile/kyc`)} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
          KYC
        </Typography>
      </Box>
      {isLoading || isError ?
        (<Typography>Loading...</Typography>)
        : (
          <List sx={{ maxWidth: '500px', margin: '16px auto' }}>
            <ListItem>
              <ListItemText primary={`Government`} primaryTypographyProps={{ fontWeight: 600 }} />
              <ListItemSecondaryAction sx={{ right: 0 }}>
                <Button title="Add new" onClick={() => setOpenDialog(!openDialog)} />
              </ListItemSecondaryAction>
            </ListItem>
            {openDialog && <AddGovernmentalDialog open={openDialog} onClose={() => setOpenDialog(!openDialog)} />}
            {governmentalIDs.length === 0 ? (
              <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                You have no governmental records. You can add one by clicking on the "Add new" button above.
              </Typography>
            ) : governmentalIDs.map((document: KYCDocument, index: number) => (
              <React.Fragment key={index}>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText primary={document.document_name} sx={{ mt: 2 }} primaryTypographyProps={{ fontWeight: 600 }} />
                  <ListItemSecondaryAction sx={{ right: 0 }}>
                    <IconButton edge="end" onClick={() => handleDeleteDocument(document.id)}>
                      <CloseIcon color='error' />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Box>
                  {document.additional_details && document?.additional_details?.map((detail, index) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={index}>
                      <Typography>{detail.field}</Typography>
                      <Typography>{detail.value}</Typography>
                    </Box>
                  ))}
                </Box>
              </React.Fragment>
            ))}
          </List>
        )}
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />
    </Box>
  );
}

interface AddGovernmentalDialogProps {
  open: boolean;
  onClose: () => void;
}

interface GovernmentFormValues {
  country: string;
  document_name: string;
  frontImage: File | string;
  backImage: File | string;
}

const AddGovernmentalDialog: React.FC<AddGovernmentalDialogProps> = ({ open, onClose }) => {
  const [extractedText, setExtractedText] = useState('');
  const { createKyc, error, isLoading } = useCreateKycDocument();


  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };

  const [uploadToast, setUploadToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "upload") {
      setUploadToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const imageFile: File | null = event.target.files ? event.target.files[0] : null;
      if (imageFile) {

        // Extract text from the image using Tesseract
        const extractedText = await extractTextFromImage(imageFile);
        setExtractedText(extractedText);
        const ninRegex = /([A-Z]+\d+)/;
        const cardNumberRegex = /([A-Z]+\d+)/;
        const ninMatch = extractedText.match(ninRegex);
        const cardNumberMatch = extractedText.match(cardNumberRegex);

        const extractedNIN = ninMatch ? ninMatch[1] : 'N/A';
        const extractedCardNumber = cardNumberMatch ? cardNumberMatch[1] : 'N/A';
        const fullNameRegex = /(\w+<<\w+)/i;

        const fullNameMatch = extractedText.match(fullNameRegex);

        const extractedFullName = fullNameMatch ? fullNameMatch[1] : 'N/A';

        console.log('NIN Number:', extractedNIN);
        console.log('Card Number:', extractedCardNumber);
        console.log('Legal Full Name:', extractedFullName);
      }
    } catch (error) {
      console.log('error uploading image')
    }
  };
  const validate = (values: GovernmentFormValues) => {
    const errors: Partial<GovernmentFormValues> = {};

    if (!values.country) {
      errors.country = 'Country is required';
    }
    if (!values.document_name) {
      errors.document_name = 'Document name is required';
    }
    if (!values.frontImage) {
      errors.frontImage = 'Front Image is required';
    }
    if (!values.backImage) {
      errors.backImage = 'Back Image is required';
    }


    return errors;
  };

  const onSubmit = async (values: GovernmentFormValues, form: FormApi<GovernmentFormValues, Partial<GovernmentFormValues>>) => {
    try {
      const formData = new FormData();
      formData.append('name', values.document_name);
      formData.append('type', document_types[0]);
      formData.append('documents', values.frontImage);
      formData.append('documents', values.backImage);
      const additionalDetails = [
        { field: 'country', value: values.country },
      ];
      const additionalDetailsString = JSON.stringify(additionalDetails);
      formData.append('additional_details', additionalDetailsString);
      await createKyc(formData);
      setUploadToast({
        open: true,
        message: "Document uploaded successfully",
        type: "success",
      });
      form.reset()
      onClose()
      // Document created successfully, you can perform any further actions here
    } catch (error) {
      // Handle error
      setUploadToast({
        open: true,
        message: "Failed to upload document",
        type: "error",
      });
    }

  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Add government document`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        {/* Add form fields for adding a new governmental ID */}
        <Form onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Box>
                  <Typography fontWeight={600}>Which country’s IDs do you have?</Typography>
                  <Field name="country" component={Select} fullWidth options={countries} />
                </Box>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Select the government IDs issued to you by that country</Typography>
                  <Field name="document_name" component={Select} fullWidth options={governmentTypeOptions} />
                </Box>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach a clear photo of the front of the selected ID</Typography>
                  <Field name="frontImage">
                    {({ input, meta }) => (
                      <>
                        <Box sx={{ borderRadius: 1.5, border: `1px solid  #BABFC3`, py: 2 }}
                          style={{
                            backgroundImage: `url(${values.frontImage ? URL.createObjectURL(values.frontImage as File) : null})`,
                            backgroundSize: 'cover',
                            backgroundPosition: "center"
                          }}>
                          <input
                            type="file"
                            accept="image/*"
                            id="frontPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);
                              handleImageUpload(e);
                            }}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="frontPhotoUpload" >
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, opacity: values.frontImage ? 0.3 : 1 }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("front")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach a clear photo of the back of the selected ID</Typography>
                  <Field name="backImage">
                    {({ input, meta }) => (
                      <>
                        <Box sx={{ borderRadius: 1.5, border: `1px solid  #BABFC3`, py: 2 }}
                          style={{
                            backgroundImage: `url(${values.backImage ? URL.createObjectURL(values.backImage as File) : null})`,
                            backgroundSize: 'cover',
                            backgroundPosition: "center"
                          }}>
                          <input
                            type="file"
                            accept="image/*"
                            id="backPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);
                              handleImageUpload(e);
                            }}

                            style={{ display: 'none' }}
                          />
                          <label htmlFor="backPhotoUpload">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, opacity: values.backImage ? 0.3 : 1 }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("back")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>
                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Button color="primary" title='Cancel' sx={{ mr: 2, width: '120px' }} onClick={onClose} />
                  <Button variant="contained" color="primary" type="submit" title='Save' sx={{ width: '120px' }} loading={isLoading || submitting} />
                </Box>

              </Box>
            </form>
          )}
        </Form>
      </DialogContent>
      <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
    </ModalWrapper>
  );
}
const AddInstitutionDialog: React.FC<AddGovernmentalDialogProps> = ({ open, onClose }) => {
  const [extractedText, setExtractedText] = useState('');
  const { createKyc, error, isLoading } = useCreateKycDocument();


  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };

  const [uploadToast, setUploadToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "upload") {
      setUploadToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {


      const imageFile: File | null = event.target.files ? event.target.files[0] : null;
      if (imageFile) {

        // Extract text from the image using Tesseract
        const extractedText = await extractTextFromImage(imageFile);
        setExtractedText(extractedText);
        const ninRegex = /([A-Z]+\d+)/;
        const ninMatch = extractedText.match(ninRegex);

        const extractedNIN = ninMatch ? ninMatch[1] : 'N/A';


        console.log('NIN Number:', extractedNIN);
      }
    } catch (error) {
      console.log('error uploading image')
    }
  };
  const validate = (values: GovernmentFormValues) => {
    const errors: Partial<GovernmentFormValues> = {};
    if (!values.document_name) {
      errors.document_name = 'Document name is required';
    }
    if (!values.frontImage) {
      errors.frontImage = 'Front Image is required';
    }
    if (!values.backImage) {
      errors.backImage = 'Back Image is required';
    }

    return errors;
  };

  const onSubmit = async (values: GovernmentFormValues, form: FormApi<GovernmentFormValues, Partial<GovernmentFormValues>>) => {
    try {
      const formData = new FormData();
      formData.append('name', values.document_name);
      formData.append('type', document_types[1]);
      formData.append('documents', values.frontImage);
      formData.append('documents', values.backImage);

      await createKyc(formData);

      setUploadToast({
        open: true,
        message: "Document uploaded successfully",
        type: "success",
      });
      form.reset()
      onClose()
      // Document created successfully, you can perform any further actions here
    } catch (error) {
      // Handle error
      setUploadToast({
        open: true,
        message: "Failed to upload document",
        type: "error",
      });
    }

  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Add institution document`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        {/* Add form fields for adding a new governmental ID */}
        <Form onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Which institutional IDs do you have?</Typography>
                  <Field name="document_name" component={Select} fullWidth options={institutionalTypeOptions} />
                </Box>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach a clear photo of the front of the selected ID</Typography>
                  <Field name="frontImage">
                    {({ input, meta }) => (
                      <>
                        <Box sx={{ borderRadius: 1.5, border: `1px solid  #BABFC3`, py: 2 }} style={{
                          backgroundImage: `url(${values.frontImage ? URL.createObjectURL(values.frontImage as File) : null})`,
                          backgroundSize: 'cover',
                          backgroundPosition: "center"
                        }}>
                          <input
                            type="file"
                            accept="image/*"
                            id="frontPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);
                              handleImageUpload(e);
                            }}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="frontPhotoUpload">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, opacity: values.frontImage ? 0.3 : 1 }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("front")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach a clear photo of the back of the selected ID</Typography>
                  <Field name="backImage">
                    {({ input, meta }) => (
                      <>
                        <Box sx={{ borderRadius: 1.5, border: `1px solid  #BABFC3`, py: 2 }} style={{
                          backgroundImage: `url(${values.backImage ? URL.createObjectURL(values.backImage as File) : null})`,
                          backgroundSize: 'cover',
                          backgroundPosition: "center"
                        }}>
                          <input
                            type="file"
                            accept="image/*"
                            id="backPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);
                              handleImageUpload(e);
                            }}

                            style={{ display: 'none' }}
                          />
                          <label htmlFor="backPhotoUpload">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, opacity: values.backImage ? 0.3 : 1 }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("back")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>
                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Button color="primary" title='Cancel' sx={{ mr: 2, width: '120px' }} onClick={onClose} />
                  <Button variant="contained" color="primary" type="submit" title='Save' sx={{ width: '120px' }} loading={isLoading || submitting} />
                </Box>

              </Box>
            </form>
          )}
        </Form>

      </DialogContent>
      <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
    </ModalWrapper>
  );
}

export const InstitutionalKyc: React.FC = () => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };
  const { data: governmentalIDs, isLoading, isError } = useUserDocuments({ document_type: document_types[1] })
  const { deleteDocument, } = useDeleteDocumentById();
  const handleDeleteDocument = async (documentId: number) => {
    try {
      // Call the deleteDocument function from the hook
      await deleteDocument(documentId);
      setUpdateToast({
        open: true,
        message: "Document deleted",
        type: "success",
      });
    } catch (error: any) {
      setUpdateToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };



  return (
    <Box m={2}>
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 2 }}>
        <IconButton onClick={() => history.push(`/profile/kyc`)} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
          KYC
        </Typography>
      </Box>
      {isLoading || isError ?
        (<Typography>Loading...</Typography>)
        : (<List sx={{ maxWidth: '500px', margin: '16px auto' }}>
          <ListItem>
            <ListItemText primary={`Institutional`} primaryTypographyProps={{ fontWeight: 600 }} />
            <ListItemSecondaryAction sx={{ right: 0 }}>
              <Button title="Add new" onClick={() => setOpenDialog(!openDialog)} />
            </ListItemSecondaryAction>
          </ListItem>
          {openDialog && <AddInstitutionDialog open={openDialog} onClose={() => setOpenDialog(!openDialog)} />}
          {governmentalIDs.length === 0 ? (
            <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
              You have no instituitional records. You can add one by clicking on the "Add new" button above.
            </Typography>
          ) : governmentalIDs.map((document: KYCDocument, index: number) => (
            <React.Fragment key={index}>
              <ListItem sx={{ p: 0 }}>
                <ListItemText primary={document.document_name} sx={{ mt: 2 }} primaryTypographyProps={{ fontWeight: 600 }} />
                <ListItemSecondaryAction sx={{ right: 0 }}>
                  <IconButton edge="end" onClick={() => handleDeleteDocument(document.id)}>
                    <CloseIcon color='error' />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Box>
                {document.additional_details && document.additional_details.map((detail, index) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={index}>
                    <Typography>{detail.field}</Typography>
                    <Typography>{detail.value}</Typography>
                  </Box>
                ))}
              </Box>
            </React.Fragment>
          ))}
        </List>
        )}
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />

    </Box>
  );
}

interface ProofAddFormValues {
  type: string;
  document_name: string;
  frontImage: File | string;
}

const AddProofOfAddress: React.FC<AddGovernmentalDialogProps> = ({ open, onClose }) => {
  const [extractedText, setExtractedText] = useState('');
  const { createKyc, error, isLoading } = useCreateKycDocument();


  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };
  const [uploadToast, setUploadToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "upload") {
      setUploadToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {


      const imageFile: File | null = event.target.files ? event.target.files[0] : null;
      if (imageFile) {

        // Extract text from the image using Tesseract
        const extractedText = await extractTextFromImage(imageFile);
        setExtractedText(extractedText);
        const ninRegex = /([A-Z]+\d+)/;
        const ninMatch = extractedText.match(ninRegex);

        const extractedNIN = ninMatch ? ninMatch[1] : 'N/A';


        console.log('NIN Number:', extractedNIN);
      }
    } catch (error) {
      console.log('error uploading image')
    }
  };
  const validate = (values: ProofAddFormValues) => {
    const errors: Partial<ProofAddFormValues> = {};
    if (!values.document_name) {
      errors.document_name = 'Document name is required';
    }
    if (!values.frontImage) {
      errors.frontImage = 'Front Image is required';
    }


    return errors;
  };

  const onSubmit = async (values: ProofAddFormValues, form: FormApi<ProofAddFormValues, Partial<ProofAddFormValues>>) => {
    try {
      const formData = new FormData();
      formData.append('name', values.document_name);
      formData.append('type', document_types[2]);
      formData.append('documents', values.frontImage);

      const additionalDetails = [
        { field: 'name', value: values.type },
      ];
      const additionalDetailsString = JSON.stringify(additionalDetails);
      formData.append('additional_details', additionalDetailsString);
      await createKyc(formData);
      setUploadToast({
        open: true,
        message: "Document uploaded successfully",
        type: "success",
      });
      form.reset()
      onClose()
      // Document created successfully, you can perform any further actions here
    } catch (error) {
      // Handle error
      setUploadToast({
        open: true,
        message: "Failed to upload document",
        type: "error",
      });
    }

  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Add proof of address`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        {/* Add form fields for adding a new governmental ID */}
        <Form onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Which of these do you have?</Typography>
                  <Field name="document_name" component={Select} fullWidth options={institutionalTypeOptions} />
                </Box>
                <Field
                  name="type"
                  label="Type the name of the utility bill you have"
                  fullWidth
                  component={FormInput}
                />
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach a pdf or clear scanned photo of the proof of address</Typography>
                  <Field name="frontImage">
                    {({ input, meta }) => (
                      <>
                        <Box sx={{ borderRadius: 1.5, border: `1px solid  #BABFC3`, py: 2 }} style={{
                          backgroundImage: `url(${values.frontImage ? URL.createObjectURL(values.frontImage as File) : null})`,
                          backgroundSize: 'cover',
                          backgroundPosition: "center"
                        }}>
                          <input
                            type="file"
                            accept="image/*"
                            id="frontPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);
                              handleImageUpload(e);
                            }}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="frontPhotoUpload">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, opacity: values.frontImage ? 0.3 : 1 }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("front")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>

                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Button color="primary" title='Cancel' sx={{ mr: 2, width: '120px' }} onClick={onClose} />
                  <Button variant="contained" color="primary" type="submit" title='Save' loading={isLoading || submitting} />
                </Box>
              </Box>
            </form>
          )}
        </Form>
        <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
      </DialogContent>
    </ModalWrapper>
  );
}

export const ProofOfAddress: React.FC = () => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };

  const { data: governmentalIDs, isLoading, isError } = useUserDocuments({ document_type: document_types[2] })
  const { deleteDocument, } = useDeleteDocumentById();
  const handleDeleteDocument = async (documentId: number) => {
    try {
      // Call the deleteDocument function from the hook
      await deleteDocument(documentId);
      setUpdateToast({
        open: true,
        message: "Document deleted",
        type: "success",
      });
    } catch (error: any) {
      setUpdateToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  return (
    <Box m={2}>
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 2 }}>
        <IconButton onClick={() => history.push(`/profile`)} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
          KYC
        </Typography>
      </Box>
      {
        isLoading || isError ?
          (
            <Typography>Loading...</Typography>
          )
          : (
            <List sx={{ maxWidth: '500px', margin: '16px auto' }}>
              <ListItem>
                <ListItemText primary={`Proof of address`} primaryTypographyProps={{ fontWeight: 600 }} />
                <ListItemSecondaryAction sx={{ right: 0 }}>
                  <Button title="Add new" onClick={() => setOpenDialog(!openDialog)} />
                </ListItemSecondaryAction>
              </ListItem>
              {openDialog && <AddProofOfAddress open={openDialog} onClose={() => setOpenDialog(!openDialog)} />}
              {governmentalIDs.length === 0 ? (
                <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                  You have no proof of address records. You can add one by clicking on the "Add new" button above.
                </Typography>
              ) : governmentalIDs.map((document: KYCDocument, index: number) => (
                <React.Fragment key={index}>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText primary={document.document_name} sx={{ mt: 2 }} primaryTypographyProps={{ fontWeight: 600 }} />
                    <ListItemSecondaryAction sx={{ right: 0 }}>
                      <IconButton edge="end" onClick={() => handleDeleteDocument(document.id)}>
                        <CloseIcon color='error' />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Box>
                    {document.additional_details && document.additional_details.map((detail, index) => (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={index}>
                        <Typography>{detail.field}</Typography>
                        <Typography>{detail.value}</Typography>
                      </Box>
                    ))}
                  </Box>
                </React.Fragment>
              ))}
            </List>
          )}
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />

    </Box>

  );
};


const AddFinancialRecord: React.FC<AddGovernmentalDialogProps> = ({ open, onClose }) => {
  const [extractedText, setExtractedText] = useState('');
  const { createKyc, error, isLoading } = useCreateKycDocument();


  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };

  const [uploadToast, setUploadToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "upload") {
      setUploadToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {


      const imageFile: File | null = event.target.files ? event.target.files[0] : null;
      if (imageFile) {

        // Extract text from the image using Tesseract
        const extractedText = await extractTextFromImage(imageFile);
        setExtractedText(extractedText);
        const ninRegex = /([A-Z]+\d+)/;
        const ninMatch = extractedText.match(ninRegex);

        const extractedNIN = ninMatch ? ninMatch[1] : 'N/A';


        console.log('NIN Number:', extractedNIN);
      }
    } catch (error) {
      console.log('error uploading image')
    }
  };
  const validate = (values: GovernmentFormValues) => {
    const errors: Partial<GovernmentFormValues> = {};
    if (!values.document_name) {
      errors.document_name = 'Document name is required';
    }
    if (!values.frontImage) {
      errors.frontImage = 'Front Image is required';
    }


    return errors;
  };

  const onSubmit = async (values: GovernmentFormValues, form: FormApi<GovernmentFormValues, Partial<GovernmentFormValues>>) => {
    try {
      const formData = new FormData();
      formData.append('name', values.document_name);
      formData.append('type', document_types[3]);
      formData.append('documents', (values.frontImage));

      await createKyc(formData);
      setUploadToast({
        open: true,
        message: "Document uploaded successfully",
        type: "success",
      });
      form.reset()
      onClose()
      // Document created successfully, you can perform any further actions here
    } catch (error) {
      // Handle error
      setUploadToast({
        open: true,
        message: "Failed to upload document",
        type: "error",
      });
    }

  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Add financial records`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        {/* Add form fields for adding a new governmental ID */}
        <Form onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>

                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Which financial record do you have?</Typography>
                  <Field name="document_name" component={Select} fullWidth options={financialRecordsOptions} />
                </Box>

                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach a pdf or clear scanned photo of the selected record</Typography>
                  <Field name="frontImage">
                    {({ input, meta }) => (
                      <>
                        <Box sx={{ borderRadius: 1.5, border: `1px solid  #BABFC3`, py: 2 }} style={{
                          backgroundImage: `url(${values.frontImage ? URL.createObjectURL(values.frontImage as File) : null})`,
                          backgroundSize: 'cover',
                          backgroundPosition: "center"
                        }}>
                          <input
                            type="file"
                            accept="image/*"
                            id="frontPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);
                              handleImageUpload(e);
                            }}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="frontPhotoUpload">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, opacity: values.frontImage ? 0.3 : 1 }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("front")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>

                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Button color="primary" title='Cancel' sx={{ mr: 2, width: '120px' }} onClick={onClose} />
                  <Button variant="contained" color="primary" type="submit" title='Save' sx={{ width: '120px' }} loading={isLoading || submitting} />
                </Box>
              </Box>
            </form>
          )}
        </Form>

      </DialogContent>
      <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
    </ModalWrapper>
  );
}




export const FinancialRecords: React.FC = () => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };

  const { data: governmentalIDs, isLoading, isError } = useUserDocuments({ document_type: document_types[3] })
  const { deleteDocument, } = useDeleteDocumentById();
  const handleDeleteDocument = async (documentId: number) => {
    try {
      // Call the deleteDocument function from the hook
      await deleteDocument(documentId);
      setUpdateToast({
        open: true,
        message: "Document deleted",
        type: "success",
      });
    } catch (error: any) {
      setUpdateToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  return (
    <Box m={2}>
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 2 }}>
        <IconButton onClick={() => history.push(`/profile`)} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
          KYC
        </Typography>
      </Box>
      {
        isLoading || isError ?
          (
            <Typography>Loading...</Typography>
          )
          : (
            <List sx={{ maxWidth: '500px', margin: '16px auto' }}>
              <ListItem>
                <ListItemText primary={`Financial Records`} primaryTypographyProps={{ fontWeight: 600 }} />
                <ListItemSecondaryAction sx={{ right: 0 }}>
                  <Button title="Add new" onClick={() => setOpenDialog(!openDialog)} />
                </ListItemSecondaryAction>
              </ListItem>
              {openDialog && <AddFinancialRecord open={openDialog} onClose={() => setOpenDialog(!openDialog)} />}
              {governmentalIDs.length === 0 ? (
                <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                  You have no financial records. You can add one by clicking on the "Add new" button above.
                </Typography>
              ) : governmentalIDs.map((document: KYCDocument, index: number) => (
                <React.Fragment key={index}>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText primary={document.document_name} sx={{ mt: 2 }} primaryTypographyProps={{ fontWeight: 600 }} />
                    <ListItemSecondaryAction sx={{ right: 0 }}>
                      <IconButton edge="end" onClick={() => handleDeleteDocument(document.id)}>
                        <CloseIcon color='error' />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Box>
                    {document.additional_details && document.additional_details.map((detail, index) => (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={index}>
                        <Typography>{detail.field}</Typography>
                        <Typography>{detail.value}</Typography>
                      </Box>
                    ))}
                  </Box>
                </React.Fragment>
              ))}
            </List>
          )}
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />

    </Box>

  );
};



export const Witnesses: React.FC = () => {
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };
  const { data: witnessesData, isLoading, isError } = useFetchWitnesses();
  const { deleteWitness } = useDeleteWitness();
  const [open, openModal] = useState(false)

  const handleDeleteWitness = async (witnessId: number) => {
    try {
      await deleteWitness(witnessId);
      setUpdateToast({
        open: true,
        message: "Witness deleted",
        type: "success",
      });
    } catch (error: any) {
      setUpdateToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  const history = useHistory()

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', px: 2, mt: 2, justifyContent: 'space-between' }}>
        <IconButton onClick={() => history.goBack()} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
          Witnesses
        </Typography>
        <Button title='Add new' onClick={() => openModal(true)} />

      </Box>
      {isLoading || isError ? (
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      ) :
        witnessesData.length === 0 ? (
          <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
            You have no witnesses. You can add one by clicking on the "Add new" button above.
          </Typography>
        ) : (
          <List sx={{ maxWidth: '500px', margin: '16px auto' }}>
            {witnessesData.map((witness: Witness, index: number) => (
              <Box key={witness.id} sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2.5, px: 2 }}>
                <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                  <Typography fontWeight={600}>Name</Typography>
                  <Typography>{`${capitalizeWords(witness.name)}`}</Typography>
                </ListItem>
                <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                  <Typography fontWeight={600}>Phone number</Typography>
                  <Typography>{`${witness.phone_number}`}</Typography>
                </ListItem>
                <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
                  <Typography fontWeight={600}>Relationship</Typography>
                  <Typography>{`${witness.relationship}`}</Typography>
                </ListItem>
                <Button title='Delete' color='error' startIcon={<DeleteOutlineOutlinedIcon />} sx={{ backgroundColor: '#FFF7F8', mt: 2, alignSelf: 'end' }} onClick={() => handleDeleteWitness(witness.id)} />
              </Box>
            ))}
          </List>)}
      {open && <CreateWitness open={open} onClose={() => openModal(!open)} />}
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />
    </div>
  );
};

export interface WitnessFormValues {
  name: string;
  phoneNumber: string;
  relationship: string
}


export const CreateWitness: React.FC<AddGovernmentalDialogProps> = ({ open, onClose }) => {
  const { createWitness, error } = useCreateWitness();;


  const validate = (values: WitnessFormValues) => {
    const errors: Partial<WitnessFormValues> = {};
    if (!values.name) {
      errors.name = 'Name is required';
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    }

    if (!telephoneCheck(values.phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phonenumber";
    }

    if (!values.relationship) {
      errors.relationship = 'Relationship is required';
    }


    return errors;
  };

  const onSubmit = async (values: WitnessFormValues) => {
    try {
      await createWitness({
        name: values.name,
        phonenumber: values.phoneNumber,
        relationship: values.relationship
      });
      onClose()

    } catch (error) {
      // Handle error
    }

  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Add witnesses`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <Form onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Field
                  name="name"
                  label="Full legal name"
                  fullWidth
                  component={FormInput}
                />
                <CountryInput name="country" />

                <Box>
                  <Typography fontWeight={600}>Type of relationship</Typography>
                  <Field name="relationship" component={Select} fullWidth options={relationShipOptions} />
                </Box>

                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Button color="primary" title='Cancel' sx={{ mr: 2 }} onClick={onClose} />
                  <Button variant="contained" color="primary" type="submit" title='Save' />
                </Box>
              </Box>
            </form>
          )}
        </Form>

      </DialogContent>
    </ModalWrapper>
  );
}