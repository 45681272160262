import React, { useState } from "react";
import Collapsible from 'react-collapsible';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Alert, AlertTitle, Box, FormControlLabel, IconButton, Typography, Switch, InputAdornment } from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Form, Field } from "react-final-form";
import FormInput from "../../components/Reusables/Input/FormInput";
import RadioButtons from "../../components/Reusables/Input/RadioGroup";
import { adminAccessOptions, adminOptions, contributionsType, delayOptions, durationOptions, } from "../../components/Reusables/Input/select/Options";
import { convertMonthsToYears, convertYearsToMonths, numberWithCommas, removeCommas, stringToBoolean, } from "../../utils/middleware";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Select from "../../components/Reusables/Input/select/Select";
import Button from "../../components/Reusables/Input/Button";
import { Pocket } from "../../types/types";
import Toast from "../../components/Reusables/Feedback/Toast";
import { usePocket, useUpdatePocket, useUpdatePocketAdminAccess } from "../../hooks/pocketHooks";
import { useStyles } from '../goals/SavingStyles'
import DeletePocket from "../../components/Modals/DeletePocket";
import Divider from "../../components/Reusables/Display/Divider";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import dayjs from "dayjs";
import theme from "../../theme";
import DotSeparator from "../../components/Reusables/DotSeparator";

interface RevolvingSettingsFormValues {
  revolving: string;

}

const RevolvingSettings = ({ pocket }: { pocket: Pocket }) => {
  const { isLoading: isUpdateLoading, updatePocket, error } = useUpdatePocket();
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "update") {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const validate = (values: RevolvingSettingsFormValues) => {
    const errors: Partial<RevolvingSettingsFormValues> = {};
    if (!values.revolving) {
      errors.revolving = "Required";
    }
    return errors;
  };

  const onSubmit = async (values: RevolvingSettingsFormValues) => {
    const data = {
     
      revolving: values.revolving === "true"
    }
    try {
      await updatePocket({ id: pocket.id, data: data });
      setUpdateToast({
        open: true,
        message: "Pocket updated successfully",
        type: "success",
      });
    } catch (error) {
      setUpdateToast({
        open: true,
        message: "Failed to update pocket",
        type: "error",
      });
    }
  }
  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, marginBottom: "0.5rem" }}>
        <Typography variant='body1'>Revolving pocket</Typography>
        <InfoOutlinedIcon fontSize='small' />
      </Box>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          revolving: pocket?.revolving,
          kind: 'robin'
        }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8
            }}>
            <Field name="revolving" component={RadioButtons} options={adminOptions} />
            {pocket.revolving && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant='body1'>Automatic revolving order</Typography>
                  <Field name="kind" type="checkbox">
                    {({ input }) => (
                      <FormControlLabel
                        control={<Switch checked={true} />}
                        sx={{ margin: 'unset' }}
                        label=""
                      />
                    )}
                  </Field>
                </Box>
                <Box>
                  <Typography variant="body1">Revolving pocket order</Typography>
                  <Field name="kind" component={RadioButtons}
                    options={[
                      {
                        label: `By first come first served (contribution based)`,
                        value: 'robin'
                      }
                    ]} />
                  <Alert icon={<InfoOutlinedIcon sx={{ mr: -1 }} />} sx={{ p: 0 }}>
                    <AlertTitle>Note</AlertTitle>
                    {`First come first served means that payouts are made out from the first member to the last member until everyone has received their turn of payout. However, contribution frequency of participants highly influences their ranking in payout selections`}
                  </Alert>
                </Box>
              </>
            )}
            {!pristine && (
              <Box sx={{ textAlign: 'center', my: 1 }} >
                <Button
                  title="Save changes"
                  color="primary"
                  variant="outlined"
                  disabled={submitting || isUpdateLoading || pristine}
                  type="submit"
                  loading={isUpdateLoading}
                />
              </Box>
            )}

          </form>
        )}
      />
      <Toast {...updateToast} handleClose={() => handleCloseToast("update")} />
    </div>
  )
}


interface MoneySettingsFormValues {
  duration: string;
  target_amount: string;
  minimum_deposit: string;
  customDuration: number | null;
  delay: string;

}

const MoneySettings = ({ pocket }: { pocket: Pocket }) => {
  const { isLoading: isUpdateLoading, updatePocket, error } = useUpdatePocket();

  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "update") {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const validate = (values: MoneySettingsFormValues) => {
    const errors: Partial<MoneySettingsFormValues> = {};
    if (!values.delay) {
      errors.delay === 'required'
    }


    return errors;
  };

  const onSubmit = async (values: MoneySettingsFormValues) => {
    const data = {
      ...values,
      target_amount: values.target_amount ? removeCommas(values.target_amount) : undefined,
      delay: parseFloat(values.delay),
      duration: values.duration === 'year' ? convertYearsToMonths(values.customDuration ?? 0) : parseFloat(values.duration),
      minimum_deposit: parseFloat(values.minimum_deposit)
    }
    try {
      await updatePocket({ id: pocket.id, data: data });
      setUpdateToast({
        open: true,
        message: "Pocket updated successfully",
        type: "success",
      });
    } catch (error) {
      setUpdateToast({
        open: true,
        message: (error as Error).message,
        type: "error",
      });
    }
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          target_amount: pocket?.target_amount,
          duration: pocket.duration && pocket?.duration > 12 ? 'year' : pocket.duration,
          minimum_deposit: pocket?.minimum_deposit,
          delay: pocket?.delay
        }}
        render={({ handleSubmit, values, submitting, pristine }) => (
          <form onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8
            }}>
            <Field name="target_amount">
              {(props: any) => <FormInput
                label="Target Amount"
                type="text"
                name="targetAmount"
                value={numberWithCommas(props.input.value)}
                onChange={(e) => {
                  props.input.onChange(removeCommas(e.target.value));
                }}
                sx={{
                  '& .MuiInputLabel-root': {
                    fontWeight: 400,
                  },
                }}
                {...props}
              />}
            </Field>
            <Field name="minimum_deposit" >
              {(props: any) => <FormInput
                label="Minimum deposit"
                type="text"
                value={numberWithCommas(props.input.value)}
                onChange={(e) => {
                  props.input.onChange(removeCommas(e.target.value));

                }}
                {...props}
              />}
            </Field>
            <Box>
              <Typography variant="body1">Desired duration to achieve target amount (optional)</Typography>
              <Field
                options={durationOptions}
                name="duration"
                type="select"
                component={Select}
              />
            </Box>
            {values.duration === 'year' && (
              <Field name="customDuration">
                {(props: any) => <FormInput
                  label="Enter duration(years)"
                  value={props.input.value > 12 ? convertMonthsToYears(props.input.value) : props.input.value}
                  type="number"
                  {...props}
                />}
              </Field>
            )}

            <>
              <Box>
                <Typography variant="body1">Time taken to withdraw funds upon request (hrs)</Typography>
                <Field name="delay">
                  {(props) => (
                    <FormInput
                      {...props}
                      type="number"
                      placeholder="Custom delay"
                      fullWidth
                      InputProps={{
                        endAdornment: 'hrs',
                      }}
                      sx={{ flex: 0.6, mb: 0.5 }}
                    />
                  )}
                </Field>
              </Box>
              <Divider>or selecting existing duration</Divider>
              <Field name="delay">
                {({ input, meta }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {delayOptions.map((option, i) => (
                      <Box sx={{ p: 2, cursor: 'pointer', border: option.value === input.value ? '1px solid #673AB7' : '1px solid #C4CFD4', borderRadius: '8px', mr: 2 }} onClick={() => input.onChange(option.value)} key={i}>
                        <Typography fontWeight={600}>{option.label}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Field>
              <Alert severity="info" sx={{ px: 1 }} icon={false} >
                <AlertTitle sx={{ display: 'flex', alignItems: 'basline', gap: 0.4 }}>
                  <InfoOutlinedIcon fontSize='small' sx={{ fontSize: 16 }} />
                  <Typography variant='subtitle2' sx={{ textDecoration: 'underline' }}>Note:</Typography>
                </AlertTitle>
                <Typography variant='caption'>
                  The higher the time delay/gap on funds withdraw, the more likely it is for you to earn some interest bonus (0-10%) on your pocket funds
                </Typography>
              </Alert>
            </>
            {!pristine && (
              <Box sx={{ textAlign: 'center', my: 1 }} >
                <Button
                  title="Save changes"
                  color="primary"
                  variant="outlined"
                  disabled={submitting || isUpdateLoading}
                  type="submit"
                  loading={isUpdateLoading}
                />
              </Box>
            )}

          </form>
        )}
      />
      <Toast {...updateToast} handleClose={() => handleCloseToast("update")} />

    </div>
  )
}

interface AdminSettingsFormValues {
  admin_access: string
}

const AdminSettings = ({ pocket }: { pocket: Pocket }) => {
  const { isLoading: isUpdateLoading, updatePocketAdminAccess, error } = useUpdatePocketAdminAccess();


  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "update") {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const validate = (values: AdminSettingsFormValues) => {
    const errors: Partial<AdminSettingsFormValues> = {};
    if (!values.admin_access) {
      errors.admin_access = "Required";
    }

    return errors;
  };


  const onSubmit = async (values: AdminSettingsFormValues) => {
    const data = {
      ...values,
      admin_access: stringToBoolean(values.admin_access)
    }
    try {
      await updatePocketAdminAccess(pocket.id, data);
      setUpdateToast({
        open: true,
        message: "Pocket updated successfully",
        type: "success",
      });
    } catch (error) {
      setUpdateToast({
        open: true,
        message: "Failed to update pocket",
        type: "error",
      });
    }
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          admin_access: pocket?.admin_access,
        }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Typography>Pocket Type</Typography>
              <Field
                options={adminAccessOptions}
                name="admin_access"
                component={RadioButtons}
              />
            </Box>
            <Alert severity="info" sx={{ px: 1 }} icon={false} >
              <AlertTitle sx={{ display: 'flex', alignItems: 'basline', gap: 0.4 }}>
                <InfoOutlinedIcon fontSize='small' sx={{ fontSize: 16 }} />
                <Typography variant='subtitle2' sx={{ textDecoration: 'underline' }}>Note:</Typography>
              </AlertTitle>
              <Typography variant='caption'>
                If everybody is in charge of their own funds(option 1), they will be able to withdraw their own funds as deposited with no limits. If admins are in charge of the funds(option 2), other users can only be able to deposit and view, unless in the case of revolving funds.
              </Typography>
            </Alert>
            <Toast {...updateToast} handleClose={() => handleCloseToast("update")} />
            {!pristine && (
              <Box sx={{ textAlign: 'center', my: 1 }}>
                <Button
                  title="Save changes"
                  color="primary"
                  variant="outlined"
                  disabled={submitting || isUpdateLoading}
                  type="submit"
                  loading={isUpdateLoading}
                />
              </Box>
            )}

          </form>
        )}
      />

      <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', mt: 4 }}>
        <Box>
          <Typography variant='body1' fontWeight={700}>Delete Pocket</Typography>
          <Typography variant='subtitle1'>{`Once you delete the pocket,there is no going back.Please be certain`}</Typography>
        </Box>
        <Button title='Delete' color='error' variant='contained' sx={{ width: '60%' }} onClick={() => setOpenDeleteModal(!openDeleteModal)} />
      </Box>
      {openDeleteModal && <DeletePocket open={openDeleteModal} handleClose={() => setOpenDeleteModal(false)} />}

    </div>
  )
}

export const triggerBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#f5f5f5',
  p: 1,
  borderRadius: '4px',
  marginBottom: '8px',
  cursor: 'pointer',
  '&:hover': {
    background: '#e0e0e0',
  },
};


interface FormValues {
  name: string;

}

function PocketSettings() {
  const { id } = useParams<{ id: string }>();
  const [openSections, setOpenSections] = useState<string[]>([]);
  const { data: pocket } = usePocket(parseFloat(id));
  const { updatePocket } = useUpdatePocket();

  const history = useHistory()
  const [editMode, setEditMode] = useState(false);


  const handleToggle = (sectionId: string) => {
    setOpenSections((prevState: string[]) => {
      if (prevState.includes(sectionId)) {
        return prevState.filter((id) => id !== sectionId);
      } else {
        return [...prevState, sectionId];
      }
    });
  };

  const classes = useStyles();

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.name) {
      errors.name = "Required";
    }

    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    try {
      if (pocket) {
        await updatePocket({ id: pocket.id, data: values });
      }


    } catch (error) {

    }
    setEditMode(false);
  }

  return (
    <Box sx={{
      [theme.breakpoints.down('md')]: {
        maxWidth: "600px",
        minWidth: "500px",
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: "none",
        minWidth: "auto",
        width: 'auto'
      },
      padding: "1rem",
      margin: "0 auto"
    }}>
      <div className={classes.editHeader} style={{ gap: "0" }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          sx={{ mr: 2 }}
          onClick={() => history.goBack()}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body1" fontWeight={700} textAlign={"center"} sx={{ width: "100%" }}>Pocket settings</Typography>
      </div>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          name: pocket?.name,
        }}
        render={({ handleSubmit, }) => (
          <form >
            <Field name="name" type="text">
              {(props) => (
                <FormInput
                  name="name"
                  type="text"
                  label=""
                  variant='standard'
                  sx={{
                    mt: 2,
                    '& .MuiInput-underline:before': {
                      borderBottom: 'none',
                    },
                    '& .MuiInput-underline .Mui-disabled:before': {
                      borderBottomStyle: 'hidden',
                    },
                  }}
                  InputProps={{
                    readOnly: !editMode,
                    endAdornment: (
                      <InputAdornment position="end">
                        {editMode ? (
                          <IconButton onClick={handleSubmit}>
                            <SaveIcon fontSize='small' />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => setEditMode(!editMode)}>
                            <ModeEditIcon fontSize='small' />
                          </IconButton>
                        )
                        }

                      </InputAdornment>
                    ),
                  }}
                  {...props}
                />
              )}
            </Field>
            <Typography variant='caption' sx={{ my: 2, fontWeight: 500, display: "flex", gap: "0.4375rem", alignItems: "center", flexWrap:"wrap" }}>{`Created by ${pocket?.users.first_name} ${pocket?.users.last_name}`}<DotSeparator />{`${dayjs(pocket?.created_at).format('ddd D MMM YY')}`}<DotSeparator />{`${dayjs(pocket?.created_at).format('h:mm A')}`}</Typography>
          </form>
        )}
      />
      <Collapsible
        trigger={
          <Box sx={{ ...triggerBoxStyles, mt: 3 }} onClick={() => handleToggle('section2')}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AutorenewIcon />
              <Typography variant='body1' sx={{ marginLeft: '8px' }}>
                Revolving Settings
              </Typography>
            </Box>
            {openSections.includes('section2') ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Box>
        }
        open={openSections.includes('section2')}
        transitionTime={200}
      >
        {pocket && <RevolvingSettings pocket={pocket} />}

      </Collapsible>

      <Collapsible
        trigger={
          <Box sx={{ ...triggerBoxStyles }} onClick={() => handleToggle('section3')}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <MonetizationOnIcon />
              <Typography variant='body1' sx={{ marginLeft: '8px' }}>
                Money Settings
              </Typography>
            </Box>
            {openSections.includes('section3') ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Box>
        }
        open={openSections.includes('section3')}
        transitionTime={200}
      >
        {pocket && <MoneySettings pocket={pocket} />}

      </Collapsible>

      <Collapsible
        onClick={() => handleToggle('section4')}
        trigger={
          <Box sx={{ ...triggerBoxStyles }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AdminPanelSettingsIcon />
              <Typography variant='body1' sx={{ marginLeft: '8px' }}>
                Admin Settings
              </Typography>
            </Box>
            {openSections.includes('section4') ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Box>
        }
        open={openSections.includes('section4')}
        transitionTime={200}
      >
        {pocket && <AdminSettings pocket={pocket} />}

      </Collapsible>
    </Box>
  );
}

export default PocketSettings;

