import React, { useEffect, useState } from 'react';
import { Form, Field, FieldInputProps } from 'react-final-form';
import { TextField, Typography, Box, Chip, IconButton, Avatar, Checkbox, FormControlLabel, FormGroup, FormHelperText, RadioGroup, FormControl, Alert, DialogActions, DialogContent } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { GoalItem } from '../../types/types';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';
import Button from '../Reusables/Input/Button';
import { useGoals, useMaturedGoals } from '../../hooks/goalHooks';
import GoalSkeleton from '../Reusables/Feedback/skeletons/GoalSkeleton';
import { numberWithCommas } from '../../utils/middleware';
import Radio from '@mui/material/Radio';
import FormInput from '../Reusables/Input/FormInput';
import { useReinvestMultipleGoals, useReinvestSameGoals, useReinvestSingleGoal } from '../../hooks/reinvestHooks';
import { DialogContainer } from '../Reusables/Feedback/InfoDialog';
import { ValidationErrors } from 'final-form';
import DoneIcon from '@mui/icons-material/Done';
import Toast from "../Reusables/Feedback/Toast";


interface ReinvestmentStepperFormValues {
  sourceGoals: Array<{ id: number; amount: number; name: string; currency: string }>,
  reinvestmentOption: string,
  paymentDetails: string,
  targetGoal: { id: number; amount: number; name: string; },
  targetGoals: Array<{ id: number; amount: number; name: string; currency: string }>
}


const ReinvestmentStepper = () => {
  const history = useHistory()
  const [currentStep, setCurrentStep] = useState(0);
  const { data: goals, isLoading, isError } = useMaturedGoals();
  const [formValues, setFormValues] = useState<ReinvestmentStepperFormValues>({
    sourceGoals: [],
    reinvestmentOption: '',
    paymentDetails: '',
    targetGoal: { id: 0, name: '', amount: 0 },
    targetGoals: []
  });
  const [singleGoalDialogOpen, setSingleGoalDialogOpen] = useState(false);
  const [multipleGoalsDialogOpen, setMultipleGoalsDialogOpen] = useState(false);
  const [sameGoalsDialogOpen, setSameGoalsDialogOpen] = useState(false);

  const handleNext = (values: ReinvestmentStepperFormValues, form: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      ...values,
    }));
    if (currentStep === 1 && values.reinvestmentOption === 'sameGoal') {
      setSameGoalsDialogOpen(true)

    } else if (currentStep === 2 && values.reinvestmentOption === 'combineGoal') {
      setSingleGoalDialogOpen(true)
    } else if (currentStep === 2 && values.reinvestmentOption === 'otherGoal') {
      setMultipleGoalsDialogOpen(true)
    } else if (currentStep === 3) {
      form.reset()
      history.goBack()
    }
    else {
      setCurrentStep(currentStep + 1);
    }

  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const validate = (values: ReinvestmentStepperFormValues) => {
    const errors: ValidationErrors = {};

    // Add validation rules based on your needs
    if (currentStep === 0) {
      // Validate sourceGoals
      if (!values.sourceGoals || values.sourceGoals.length === 0) {
        errors.sourceGoals = 'Please select at least one source goal';
      } else {
        // Validate amount for each selected goal
        values.sourceGoals.forEach((goal) => {
          if (!goal.amount || isNaN(goal.amount) || goal.amount <= 0) {
            errors.sourceGoals = 'Invalid amount';
          }
        });
      }
    }
    if (currentStep === 1) {
      if (!values.reinvestmentOption) {
        errors.reinvestmentOption = 'required'
      }
    }

    if (currentStep === 2) {
      if (values.reinvestmentOption) {
        if (values.reinvestmentOption === 'combineGoal' && !values.targetGoal) {
          errors.targetGoal = 'Please select at least one target goal'
        } else if (values.reinvestmentOption === 'otherGoal' && values.targetGoals.length < 1) {
          errors.targetGoals = 'Please select at least 2 target goals'
        }
      }
    }
    return errors
  }


  return (
    <Box sx={{ px: 2, maxWidth: '500px', m: '0 auto' }}>
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 2 }}>
        <IconButton onClick={() => history.goBack()} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
          Reinvest goal funds
        </Typography>
      </Box>
      <Form
        onSubmit={handleNext}
        validate={validate}
        initialValues={formValues}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            {isLoading || isError ? <GoalSkeleton /> : currentStep === 0 && (
              <Field name="sourceGoals" value={values.sourceGoals} >
                {({ input, meta }) => (
                  <>
                    <StepOne
                      input={input}
                      goals={goals}
                    />
                    <Typography color='error' variant='caption'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                  </>
                )}
              </Field>
            )}
            {currentStep === 1 && (<ReinvestmentOptions values={values} />)}
            {currentStep === 2 && values.reinvestmentOption === 'combineGoal' && (<Field name="targetGoal" value={values.targetGoal} >
              {({ input, meta }) => (
                <>
                  <SingleGoal
                    input={input}
                  />
                  <Typography color='error' variant='caption'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                </>
              )}
            </Field>)}
            {currentStep === 2 && values.reinvestmentOption === 'otherGoal' && (<Field name="targetGoals" value={values.targetGoals} >
              {({ input, meta }) => (
                <>
                  <MutipleGoals
                    input={input as unknown as FieldInputProps<{
                      id: number;
                      amount: number;
                    }[], HTMLElement>}
                    goals={values.sourceGoals}
                  />
                  <Typography color='error' variant='caption'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                </>
              )}
            </Field>)}
            {currentStep === 3 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar sx={{ bgcolor: '#22C55E', mt: 2, width: 60, height: 60 }}>
                <DoneIcon fontSize='large' />
              </Avatar>
              <Typography variant='h6' sx={{ mt: 1, fontWeight: 600 }}>Success!</Typography>
              <Typography>Your reinvestment is successful.</Typography>
            </Box>}
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Button title='Back' disabled={currentStep === 0} onClick={handleBack} sx={{ width: '120px', mr: 1 }} />
              <Button title='Next' type='submit' variant='contained' sx={{ width: '120px' }} />
            </Box>
            <ReinvestSingleGoalDialog
              open={singleGoalDialogOpen}
              onClose={() => setSingleGoalDialogOpen(false)}
              sourceGoals={values.sourceGoals}
              targetGoal={values.targetGoal}
              handleStep={() => setCurrentStep(3)}

            />

            <ReinvestMultipleGoalsDialog
              open={multipleGoalsDialogOpen}
              onClose={() => setMultipleGoalsDialogOpen(false)}
              sourceGoals={values.sourceGoals}
              targetGoals={values.targetGoals}
              handleStep={() => setCurrentStep(3)}

            />

            <ReinvestSameGoalsDialog
              open={sameGoalsDialogOpen}
              onClose={() => setSameGoalsDialogOpen(false)}
              sourceGoals={values.sourceGoals}
              handleStep={() => setCurrentStep(3)}


            />
          </form>
        )}
      />

    </Box>

  );
};

export default ReinvestmentStepper;


interface StepOneProps {
  input: FieldInputProps<{
    id: number;
    amount: number;
    currency: string;
  }[], HTMLElement>;
  goals: GoalItem[]
}

const StepOne: React.FC<StepOneProps> = ({ input, goals }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [goalAmounts, setGoalAmounts] = useState<{ [key: number]: number }>({});

  useEffect(() => {
    const initialValues: Record<number, number> = {};
    input.value.forEach((selectedGoal: { id: number; amount: number, currency: string; }) => {
      initialValues[selectedGoal.id] = selectedGoal.amount;
    });
    setGoalAmounts(initialValues);
  }, [input.value]);

  const handleGoalSelect = (goal: { id: number; amount: number, name: string; currency: string }) => {
    const isSelected = input.value.some((selectedGoal: { id: number }) => selectedGoal.id === goal.id);
    if (isSelected) {
      input.onChange(input.value.filter((selectedGoal: { id: number }) => selectedGoal.id !== goal.id));
    } else {
      const selectedGoalAmount = goalAmounts[goal.id] || 0;
      input.onChange([...input.value, { id: goal.id, amount: selectedGoalAmount, currency: goal.currency }]);
    }
  };

  const handleAmountChange = (goalId: number, amount: number, name: string) => {
    setGoalAmounts((prevAmounts) => ({ ...prevAmounts, [goalId]: amount }));
    const updatedSelectedGoals = input.value.map((selectedGoal: { id: number; amount: number; }) =>
      selectedGoal.id === goalId ? { ...selectedGoal, amount, name } : selectedGoal
    );
    input.onChange(updatedSelectedGoals);
  };

  const filteredGoals = goals.filter(goal => goal.amount_saved > 0);
  const handleSelectAll = () => {
    if (selectAll) {
      input.onChange([]);
      setGoalAmounts({});
    } else {
      const selectedGoals = filteredGoals.map((goal) => ({ id: goal.id, amount: 0, name: goal.name }));
      input.onChange(selectedGoals);
    }
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  return (
    <div>
      <Typography sx={{ mt: 2 }}>Select which goals’ available balances to reinvest</Typography>
      <Chip icon={<CheckCircleOutlineIcon />} label={selectAll ? 'Deselect' : 'Select all'} sx={{ backgroundColor: '#F0EBF8' }} onClick={handleSelectAll} />
      {
        filteredGoals.map(goal => (
          <Box key={goal.id}
            sx={{ border: '1px solid #E2E8F0', mt: 2, padding: `8px 16px`, borderRadius: 1 }}

          >
            <Box onClick={() => handleGoalSelect({ id: goal.id, amount: 0, name: goal.name, currency: goal.currency })}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '16px' }}>{goal.name}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={input.value.some((selectedGoal: { id: number; }) => selectedGoal.id === goal.id)}
                      onChange={() => handleGoalSelect({ id: goal.id, amount: goal.amount_saved, name: goal.name, currency: goal.currency })}
                      size="small" sx={{ p: 0 }}
                    />
                  }
                  label=""
                  sx={{ mr: 'unset' }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 0.5 }}>
                <Typography variant='body2'>
                  Available amount
                </Typography>
                <Typography variant='h6'>
                  {numberWithCommas(goal.amount_saved)}{" "}
                  <Typography component='span' variant='overline'>{goal.currency}</Typography>
                </Typography>
              </Box>
            </Box>
            {input.value.some((selectedGoal: { id: number }) => selectedGoal.id === goal.id) && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body2" sx={{ mr: 1 }}>How much do you want to reinvest?:</Typography>
                <FormInput
                  type="number"
                  value={goalAmounts[goal.id] || ''}
                  onChange={(event) => handleAmountChange(goal.id, parseFloat(event.target.value), goal.name)}
                  error={(goal.amount_saved < Number(goalAmounts[goal.id]) || isNaN(goalAmounts[goal.id]))}
                  helperText={goal.amount_saved < Number(goalAmounts[goal.id]) ? 'Amount exceeds available balance'
                    : isNaN(goalAmounts[goal.id]) ? 'Invalid amount' : ''
                  }
                  sx={{ width: '60%' }}
                />
              </Box>
            )}
          </Box>
        ))
      }
    </div>
  )
}

interface MutipleGoalsProps {
  input: FieldInputProps<{
    id: number;
    amount: number;
  }[], HTMLElement>;
  goals: Array<{ id: number; amount: number; name: string; currency: string }>
}

const calculateTotalByCurrency = (goals: Array<{ id: number; amount: number; name: string; currency: string }>) => {
  const totalByCurrency: Record<string, number> = {};
  goals.forEach((goal) => {
    const { currency, amount } = goal;

    if (!totalByCurrency[currency]) {
      totalByCurrency[currency] = 0;
    }

    totalByCurrency[currency] += amount;
  });

  return totalByCurrency;
};

const MutipleGoals: React.FC<MutipleGoalsProps> = ({ input, goals }) => {
  const { data, isLoading, isError } = useGoals();

  const [goalAmounts, setGoalAmounts] = useState<{ [key: number]: number }>({});

  useEffect(() => {
    const initialValues: Record<number, number> = {};
    input.value.forEach((selectedGoal: { id: number; amount: number }) => {
      initialValues[selectedGoal.id] = selectedGoal.amount;
    });
    setGoalAmounts(initialValues);
  }, [input.value]);

  const handleGoalSelect = (goal: { id: number; amount: number, name: string; currency: string }) => {
    const isSelected = input.value.some((selectedGoal: { id: number }) => selectedGoal.id === goal.id);
    if (isSelected) {
      input.onChange(input.value.filter((selectedGoal: { id: number }) => selectedGoal.id !== goal.id));
    } else {
      const selectedGoalAmount = goalAmounts[goal.id] || 0;
      input.onChange([...input.value, { id: goal.id, amount: selectedGoalAmount, name: goal.name, currency: goal.currency }]);
    }
  };

  const handleAmountChange = (goalId: number, amount: number) => {
    setGoalAmounts((prevAmounts) => ({ ...prevAmounts, [goalId]: amount }));
    const updatedSelectedGoals = input.value.map((selectedGoal: { id: number; amount: number }) =>
      selectedGoal.id === goalId ? { ...selectedGoal, amount } : selectedGoal
    );
    input.onChange(updatedSelectedGoals);
  };

  // Create a comma-separated string for each currency and total
  const totalsString = Object.entries(calculateTotalByCurrency(goals))
    .map(([currency, total]) => `${currency}: ${numberWithCommas(total)}`)
    .join(', ');



  return (
    <div>
      <Typography sx={{ mt: 1, textAlign: 'center' }}>
        {`Select goals where to reinvest the available amount ${totalsString} `}</Typography>

      {isLoading || isError ? <GoalSkeleton /> :
        data.map(goal => (
          <Box key={goal.id}
            sx={{ border: '1px solid #E2E8F0', mt: 2, padding: `8px 16px`, borderRadius: 1 }}

          >
            <Box onClick={() => handleGoalSelect({ id: goal.id, amount: 0, name: goal.name, currency: goal.currency })}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '16px' }}>{goal.name}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={input.value.some((selectedGoal: { id: number; }) => selectedGoal.id === goal.id)}
                      onChange={() => handleGoalSelect({ id: goal.id, amount: 0, name: goal.name, currency: goal.currency })}
                      size="small" sx={{ p: 0 }}
                    />
                  }
                  label=""
                  sx={{ mr: 'unset' }}
                />

              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 0.5 }}>
                <Typography variant='body2'>
                  Available amount
                </Typography>
                <Typography variant='h6'>
                  {numberWithCommas(goal.amount_saved)}{" "}
                  <Typography component='span' variant='overline'>{goal.currency}</Typography>
                </Typography>
              </Box>
            </Box>
            {input.value.some((selectedGoal: { id: number }) => selectedGoal.id === goal.id) && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body2" sx={{ mr: 1 }}>How much do you want to reinvest?:</Typography>
                <FormInput
                  type="number"
                  value={goalAmounts[goal.id] || ''}
                  onChange={(event) => handleAmountChange(goal.id, parseFloat(event.target.value))}
                  /*  error={(goal.amount_saved < Number(goalAmounts[goal.id])||isNaN(goalAmounts[goal.id]))}
                  helperText={goal.amount_saved < Number(goalAmounts[goal.id]) ? 'Amount exceeds available balance'
                   : isNaN(goalAmounts[goal.id]) ? 'Invalid amount' :''
                  } */
                  sx={{ width: '60%' }}
                />
              </Box>
            )}
          </Box>
        ))
      }
    </div>
  )
}

interface StepTwoProps {
  values: ReinvestmentStepperFormValues
}

const ReinvestmentOptions: React.FC<StepTwoProps> = ({ values }) => {

  return (
    <div>
      <Typography sx={{ textAlign: 'center', mt: 2, fontWeight: 200 }}>Where would you like to reinvest?</Typography>
      <FormControl component="fieldset" sx={{ mt: 2 }} >
        <Field name="reinvestmentOption" type="radio">
          {({ input, meta }) => (
            <>
              <RadioGroup {...input}>
                <FormControlLabel
                  value="sameGoal"
                  control={<Radio />}
                  label="Back into the same goal(s)"
                />
                <FormControlLabel
                  value="combineGoal"
                  control={<Radio />}
                  disabled={values.sourceGoals.length === 1}
                  label="Combine into one goal"
                />
                <FormControlLabel
                  value="otherGoal"
                  control={<Radio />}
                  label="Into other goal(s)"
                />
              </RadioGroup>
              <FormHelperText color='error'>
                {meta?.touched ? meta?.error || meta?.submitError : ""}
              </FormHelperText>
            </>
          )}
        </Field>
      </FormControl>


    </div>

  )
};



interface SingleGoalProps {
  input: any;
}

const SingleGoal: React.FC<SingleGoalProps> = ({ input }) => {
  const { data: goals, isLoading, isError } = useGoals();

  return (
    <div>
      <Typography sx={{ textAlign: 'center', fontWeight: 600, mt: 2 }}>Select goal where to reinvest the combined available balance</Typography>
      {isLoading || isError ? <GoalSkeleton /> :
        goals.map(goal => (
          <Box key={goal.id}  >
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={input.value.id}
              onChange={() => input.onChange({ id: goal.id, name: goal.name, amount: 0 })}
              sx={{ border: '1px solid #E2E8F0', mt: 2, padding: `8px 16px`, borderRadius: 1 }}
            >
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontSize: '16px' }}>{goal.name}</Typography>
                  <FormControlLabel value={goal.id} control={<Radio sx={{ p: 0 }} size='small' />} label="" sx={{ mr: 'unset' }} />

                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 0.5 }}>
                  <Typography variant='body2'>
                    Saved amount
                  </Typography>
                  <Typography variant='h6'>
                    {numberWithCommas(goal.amount_saved)}{" "}
                    <Typography component='span' variant='overline'>{goal.currency}</Typography>
                  </Typography>
                </Box>

              </Box>
            </RadioGroup>
          </Box>
        ))
      }
    </div>
  )
};

interface ReinvestSameGoalsDialogProps {
  open: boolean;
  onClose: () => void;
  sourceGoals: Array<{ id: number; amount: number; name: string; }>
  handleStep: () => void;
}

const ReinvestSameGoalsDialog: React.FC<ReinvestSameGoalsDialogProps> = ({ open, onClose, sourceGoals, handleStep }) => {
  const { reinvestSameGoals, isLoading, error } = useReinvestSameGoals();

  const handleReinvest = async () => {
    try {
      const result = await reinvestSameGoals({ goals: sourceGoals });
      handleStep()
      onClose()
      console.log('Reinvestment successful:', result);




    } catch (error) {
      console.error('Error reinvesting same goals:', error);
    }
  };

  return (
    <DialogContainer open={open} onClose={onClose}>
      <DialogContent>
        {`You are reinvesting ${sourceGoals.length} available balances back into their former respective goals.`}
      </DialogContent>
      {error && <Alert severity='error'>{(error as Error).message}</Alert>}

      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} title='Cancel' />
        <Button onClick={handleReinvest} disabled={isLoading} loading={isLoading} title='Reinvest' variant='contained' />
      </DialogActions>
    </DialogContainer>
  );
};




interface ReinvestSingleGoalDialogProps {
  open: boolean;
  onClose: () => void;
  sourceGoals: Array<{ id: number; amount: number; name: string; }>
  targetGoal: { id: number; amount: number; name: string; };
  handleStep: () => void;


}

const ReinvestSingleGoalDialog: React.FC<ReinvestSingleGoalDialogProps> = ({ open, onClose, sourceGoals, targetGoal, handleStep }) => {
  const { reinvestSingleGoal, isLoading, error } = useReinvestSingleGoal();

  const handleReinvest = async () => {
    try {
      const result = await reinvestSingleGoal({ sourceGoals, targetGoal });
      handleStep()
      onClose()
      console.log('Reinvestment successful:', result);

    } catch (error) {
      console.error('Error reinvesting single goal:', error);
    }
  };

  const formattedSourceGoals = sourceGoals.map((goal) => `${goal.name}`).join(', ');


  return (
    <DialogContainer open={open} onClose={onClose}>
      <DialogContent>
        {`You are reinvesting ${numberWithCommas(sourceGoals.reduce((total, goal) => total + goal.amount, 0))}  from ${formattedSourceGoals} into ${targetGoal.name} goal.`}
      </DialogContent>
      {error && <Alert severity='error'>{(error as Error).message}</Alert>}

      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} title='Cancel' />
        <Button onClick={handleReinvest} disabled={isLoading} loading={isLoading} title='Reinvest' variant='contained' />
      </DialogActions>
    </DialogContainer>
  );
};



interface ReinvestMultipleGoalsDialogProps {
  open: boolean;
  onClose: () => void;
  sourceGoals: Array<{ id: number; amount: number; name: string; currency: string }>
  targetGoals: Array<{ id: number; amount: number; name: string; currency: string; }>
  handleStep: () => void;


}

const ReinvestMultipleGoalsDialog: React.FC<ReinvestMultipleGoalsDialogProps> = ({ open, onClose, sourceGoals, targetGoals, handleStep }) => {
  const { reinvestMultipleGoals, isLoading, error } = useReinvestMultipleGoals();


  const handleReinvest = async () => {
    try {

      const result = await reinvestMultipleGoals({ sourceGoals, targetGoals });
      handleStep()
      onClose()




    } catch (error) {
      console.error('Error reinvesting multiple goals:', error);
    }
  };
  const formattedSourceGoals = sourceGoals.map((goal) => `${goal.name}`).join(', ');

  const formattedTargetGoals = targetGoals.map((goal) => `${goal.name}`).join(', ');

  return (
    <DialogContainer open={open} onClose={onClose}>
      <DialogContent>
        {`You are reinvesting ${numberWithCommas(targetGoals.reduce((total, goal) => total + goal.amount, 0))}  from ${formattedSourceGoals} into ${formattedTargetGoals}.`}
      </DialogContent>
      {error && <Alert severity='error'>{(error as Error).message}</Alert>}

      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} title='Cancel' />
        <Button onClick={handleReinvest} disabled={isLoading} loading={isLoading} title='Reinvest' variant='contained' />
      </DialogActions>
    </DialogContainer>
  );
};

