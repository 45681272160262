import { Alert, Avatar, Box, InputAdornment, Typography } from "@mui/material";
import { useStyles } from "../loginStyles";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import FormInput from "../../../components/Reusables/Input/FormInput";
import Button from "../../../components/Reusables/Input/Button";
import { Field, Form } from 'react-final-form';
import RadioButtons from "../../../components/Reusables/Input/RadioGroup";
import Select from "../../../components/Reusables/Input/select/Select";
import { businessCategoryOptions, businessDurationOptions, countries, employmentStatusOptions } from "../../../components/Reusables/Input/select/Options";
import { numberWithCommas, removeCommas } from "../../../utils/middleware";

const RegisterEmployer: React.FC<{ handleBack: any }> = ({ handleBack }) => {
    const classes = useStyles();
    return (

        <Box className={classes.formContainer}>
            <Box maxWidth={"450px"} width="100%">
                <Typography variant="h4">Tell us about your employer</Typography>
            </Box>
            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}>What is your industry?</Typography>
                <Field
                    type="select"
                    name="business_type"
                    component={Select}
                    options={businessCategoryOptions}
                />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field
                    label="What is the name of your employer?"
                    name="business_name"
                    type="text"
                    component={FormInput}
                />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Typography fontWeight={600}>Select the Country of Work</Typography>
                <Field name="country" component={Select} options={[countries[0]]} />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field name="location" component={FormInput} label="Add office location or business address" fullWidth />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field name="business_contact" component={FormInput} label="Their phone number" fullWidth />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field name="business_email" component={FormInput} label="Email Address" fullWidth type='email' />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field name="business_mailing_address" component={FormInput} label="P.O. Box mailing address (optional)" fullWidth />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Typography fontWeight={600} >What is your employment status?</Typography>
                <Field
                    type="select"
                    name="employment_status"
                    component={Select}
                    options={employmentStatusOptions}
                />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field
                    label="What is your current job title?"
                    name="current_job"
                    type="text"
                    component={FormInput}
                />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field
                    label="What is your former job title?"
                    name="former_job"
                    type="text"
                    component={FormInput}
                />
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Field name="monthly_salary">
                    {(props: any) => <FormInput
                        label="How much do you earn every month?"
                        name="monthly_salary"
                        type='text'
                        value={numberWithCommas(props.input.value)}
                        onChange={(e) => {
                            props.input.onChange(removeCommas(e.target.value));
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">UGX</InputAdornment>,
                        }}
                        {...props}
                    />}
                </Field>
            </Box>

            <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}> For how long have you worked for your current employer? </Typography>
                <Field
                    name="job_duration"
                    row={false}
                    component={RadioButtons}
                    options={businessDurationOptions}
                />
            </Box>

            <Box mt={3} className={classes.formBtnContainer}>
                <Button title='Back' variant='outlined' style={{ width: "49%" }} onClick={() => handleBack('salary')} />
                <Button title='Continue' type='submit' variant='contained' style={{ width: "49%" }} />
            </Box>

        </Box>

    );
};

export default RegisterEmployer;