import React, { useEffect } from "react";
import Cookies from "js-cookie";
import decode from "jwt-decode";
import { Redirect } from "react-router-dom";

// Define a Higher-Order Component (HOC) that takes a component as an argument
const authGuard = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  // Define a functional component that returns the mixed component
  const MixedComponent = (props:P) => {
    // Use the useSelector hook to get the auth state from the Redux store

    const checkAuth = () => {
      const token = Cookies.get("access_token");
      const refreshToken = Cookies.get("refreshToken");
      /**check if the user has a token or refresh token */
      // If there is no access token or refresh token, return false

      if (!token || !refreshToken) {
        return false;
      }

      try {
        /**check if the refresh token is expired */
        // Decode the refresh token to get its expiration time
        // { exp: 12903819203 }
        const decoded = decode(refreshToken) ;

        if (typeof decoded === 'object' && decoded !== null ) {
          const {exp} = decoded as { exp: number };
          if (exp < new Date().getTime() / 1000) {
            return false;
          }
        } else {
          console.log('Invalid refresh token');
          return false

        }
        // If the refresh token is expired, return false
       
      } catch (e) {
        // If there is any error decoding the refresh token, return false
        return false;

      }
      /**otherwise the user is authenticated */
      return true;
    };
    // Use the useEffect hook to check the user's authentication status on component mount
    useEffect(() => {
      checkAuth();
    }, [checkAuth]);
    // Render the wrapped component if the user is authenticated, otherwise redirect to the sign in page
    return checkAuth() ? (
      <WrappedComponent {...props} />
    ) : (
      <Redirect to={{ pathname: "/auth/login/email" }} />
    );
  };
  return MixedComponent;
};

export default authGuard;
