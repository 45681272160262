import { Box, Drawer, Theme, useMediaQuery } from "@mui/material";
import { DialogContainer } from "../Reusables/Feedback/InfoDialog";

interface ModalWrapperProps {
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
    centered?: boolean;
}

const ModalWrapper = ({ children, centered=false, ...props }: ModalWrapperProps) => {
    const { open, onClose } = props;
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    return (
        <Box>
            <Drawer anchor="right" open={open && isDesktop} onClose={onClose} sx={{
                '& .MuiPaper-root.MuiDrawer-paper': {
                    width: '45vw'
                }
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column', margin: centered ? "auto" : "10px 0", width: "100%"
                }}>
                    {children}
                </Box>
            </Drawer>
            <DialogContainer open={open && !isDesktop} onClose={onClose}>
                {children}
            </DialogContainer>
        </Box>
    )

}

export default ModalWrapper;