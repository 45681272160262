import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {LinkProps} from '@mui/material/Link';

interface CustomLinkProps extends LinkProps {
  title: string;
  href: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({ title, href,...rest }) => {
  return (
    <Link to={href} >
      <Typography variant ='button' {...rest}>
        {title}
      </Typography>
    </Link>
  );
};

export default CustomLink;
