import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import FormInput from '../../components/Reusables/Input/FormInput'
import { useUpdateGoal, useUploadGoalImage } from '../../hooks/goalHooks'
import Button from '../../components/Reusables/Input/Button';
import { Box, FormControlLabel, Typography,Switch, IconButton, InputAdornment } from '@mui/material';
import dayjs from 'dayjs';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import Toast from '../../components/Reusables/Feedback/Toast';
import { Goal } from '../../types/types';

interface FormValues {
  name:string;
  show_notifications:boolean

}


export const ProfileSettings = ({goal}:{goal:Goal}) => {

  const [editMode, setEditMode] = useState(false);
  const [uploadToast, setUploadToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType:string) => {
    if (toastType === "upload") {
      setUploadToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    } else if (toastType === "update") {
      setUpdateToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };


  
  const { updateGoal } = useUpdateGoal();
  const { isLoading: isUploadLoading,  uploadImage } = useUploadGoalImage();

  const validate = (values:FormValues) => {
    const errors:Partial<FormValues> = {};
    if (!values.name) {
      errors.name = "Required";
    }
   
    return errors;
  };

  const fileUpload = () => {
    const fileInput = document.querySelector("#photoUpload") as HTMLInputElement;
    fileInput.click();
  };

  const photoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Call the uploadImage function from the useUploadGoalImage hook
        await uploadImage({ id: goal.id, data: formData });
        setUploadToast({
          open: true,
          message: "Image uploaded successfully",
          type: "success",
        });
      } catch (error) {
        setUploadToast({
          open: true,
          message: "Failed to upload image",
          type: "error",
        });
      }
    }
  };

  const onSubmit = async (values: FormValues) => {
  try {
    await updateGoal({ id: goal.id, data: values });
    setUpdateToast({
      open: true,
      message: "Goal updated successfully",
      type: "success",
    });
  } catch (error) {
    setUpdateToast({
      open: true,
      message: "Failed to update goal",
      type: "error",
    });
  }
  setEditMode(false);
}

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          name: goal?.name,
          show_notifications:goal?.show_notifications
        }}
        render={({ handleSubmit,pristine }) => (
          <form  onSubmit={handleSubmit}>
              <Box
               sx={{
                width:'234px',
                height:'114px',
                border:`1px dashed #8C9196`,
                display:'flex',
                flexDirection: 'column',
                alignItems:'center',
                justifyContent:'center',
                alignSelf:'center',
                m:'auto'
              }}
                style={{
                  backgroundImage: `url(${
                    goal?.image_url ? goal.image_url : null
                  })`,
                  backgroundSize: 'cover',
                  backgroundPosition: "center"
                }}
              >
                 <input
                accept="image/*"
                id="photoUpload"
                type="file"
                style={{display:'none'}}
                onChange={photoUpload}
              />
              <label htmlFor="contained-button-file">
                <Button
                  title="Upload Image"
                  color="primary"
                  onClick={fileUpload}
                  size='small'
                  variant='contained'
                  sx={{opacity:goal.image_url ? 0.5 :1}}
                  disabled={isUploadLoading}
                  loading={isUploadLoading}
                />
              </label>
              </Box>
            <Field name="name" type="text">
              {(props) => (
                <FormInput
                  name="name"
                  type="text"
                  label=""
                  variant='standard'
                  sx={{
                    mt:2,
                    '& .MuiInput-underline:before': {
                    borderBottom: 'none', 
                  },
                  '& .MuiInput-underline .Mui-disabled:before': {
                    borderBottomStyle: 'hidden', 
                  },
                  }}
                  InputProps={{
                  readOnly:!editMode,
                  endAdornment: (
                    <InputAdornment position="end">
                      {editMode ? (
                        <IconButton >
                       <SaveIcon fontSize='small' />
                      </IconButton>
                        ):(
                          <IconButton onClick={()=>setEditMode(!editMode)}>
                        <ModeEditIcon fontSize='small'/>
                      </IconButton>
                        )
                      }
                      
                    </InputAdornment>
                  ),
                }}
                  {...props}
                />
              )}
            </Field>
            <Typography variant='caption' sx={{my:2,fontWeight:500}}>{`Created by ${goal?.users.first_name} ${goal?.users.last_name} . ${dayjs(goal?.created_at).format('ddd D MMM YY .  h:mm A')}`}</Typography>
            
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mt:2}}>
            <Typography variant='body2'>Turn on notifications</Typography>
            <Field name="show_notifications" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Switch checked={input.checked} onChange={input.onChange} />}
                  sx={{margin:'unset'}}
                  label=""
                />
              )}
            </Field>
            </Box>
            {!pristine && <Button type="submit" title='Save changes' variant='outlined' sx={{my:1}}/> }
          </form>
        )}
      />
       <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
      <Toast {...updateToast} handleClose={() => handleCloseToast("update")} />
    </div>
  )
}
