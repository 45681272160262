import React, { forwardRef, memo, Ref } from "react";
import { capitalizeWords, numberWithCommas, renderInterestRate } from "../../utils/middleware";
import { useStyles } from "./GoalStyles";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { Avatar, Typography, Box, Stack, Chip } from "@mui/material";
import dayjs from "dayjs";
import { GoalItem as Goal } from "../../types/types";
import Progress from "../Reusables/Feedback/ProgressBar";
import { useHistory } from "react-router-dom";
import Button from "../Reusables/Input/Button";
import { useJoinGoal } from "../../hooks/goalHooks";

interface GoalItemProps {
  goal: Goal;
  isExplore?: boolean
}
const GoalItem = forwardRef<HTMLDivElement, GoalItemProps>(({ goal, isExplore }, ref: Ref<HTMLDivElement>) => {
  const history = useHistory();
  const { joinGoal, isLoading } = useJoinGoal();
  const daysToMaturity = dayjs(goal.maturity_date).diff(dayjs(), 'day');
  const { rate, duration } = renderInterestRate(goal.duration)

  let durationText = '';
  if (daysToMaturity >= 365) {
    const years = Math.floor(daysToMaturity / 365);
    durationText = years === 1 ? '1yr' : `${years}yr`;
  } else if (daysToMaturity >= 30) {
    const months = Math.floor(daysToMaturity / 30);
    durationText = months === 1 ? '1m' : `${months}m`;
  } else {
    durationText = daysToMaturity === 1 ? '1d' : `${daysToMaturity}d`;
  }

  const handleGoal = () => {
    if (!isExplore) {
      history.push(`/goals/goal/${goal.id}`)
    }

  }
  const handleJoinGoal = async () => {
    try {
      // Call the joinGoal function with the goalId
      const data = await joinGoal({ goalId: goal.id });
      history.push(`/goals/goal/${goal.id}`)
      // Handle success, e.g., show a success message
      console.log('Joined goal successfully:', data);
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error('Error joining goal:', error);
    }
  };

  const classes = useStyles();
  return (
    <div ref={ref} className={classes.goalContainer} onClick={handleGoal}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 0.8 }}>
        <Avatar
          alt={goal?.name}
          src={goal?.image_url ?? undefined}
          sx={{ bgcolor: "primary.main" }}
        >
          {goal.name.charAt(0)}
        </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: -0.2 }}>
            <Typography variant='body2' sx={{ fontWeight: 600 }}>{capitalizeWords(goal?.name)}</Typography>
            <Box>
              {goal.revolving && (
                <CachedOutlinedIcon color='primary' fontSize="small" />
              )}
              {goal.active ? (
                <LockOutlinedIcon color='primary' fontSize="small" />
              ) : (
                <LockOpenOutlinedIcon color='primary' />
              )}
            </Box>
          </Box>
          <Stack direction="row" spacing={1} alignItems='baseline'>
            <Chip
              label={goal.active ? 'Active' : 'Matured'}
              size="small"
              sx={{
                '&.MuiChip-root': {
                  color: goal.active ? 'success.main' : 'warning.main',
                  backgroundColor: goal.active ? '#F0EBFE' : '#FFF3E0',
                  fontWeight: 600
                }
              }} />
            <Chip label={capitalizeWords(goal?.privacy)} size="small" />
            <Typography variant="caption" sx={{ color: 'grey.600' }}>{`${goal.members} members`}</Typography>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='caption' sx={{ mb: -0.6, fontWeight: 600 }}>Saved</Typography>
          <Typography variant="caption" sx={{ mb: -0.6, fontWeight: 600 }}>{`${duration} interest`}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='h3' fontSize={{ md: "1.5rem" }}>
            {numberWithCommas((Number(goal.amount_saved)).toFixed(0))}{" "}
            <Typography variant='overline' component='span'> {goal.currency}</Typography>
          </Typography>
          <Typography variant='body1' sx={{ color: '#00871B', fontWeight: 600 }}>{rate} </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'baseline',
            color: 'success.main',
            borderRadius: '4px',
            background: `#F0EBF8`,
            border: `0.3px solid #C2B0E2`,
            px: `0.1rem`,
            width: `fit-content`,
            gap: 0.2,
            height: `1.5625rem`,

            }}>
              <Typography variant='body2' sx={{ fontWeight: 700 }}>
                {`+ ${numberWithCommas((Number(goal.interest_earned)).toFixed(0))}`}
              </Typography>
              <Typography variant='overline'> {goal.currency}</Typography>
            </Box>
          <Typography variant='caption' sx={{ marginTop: 1 }}> {`${durationText} ${goal.active ? 'to mature' : 'to reinvest'}`}</Typography>
        </Box>

      </Box>
      {goal.target_amount && parseFloat(goal.target_amount) >= 0 && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.7 }}>
          <Typography variant="caption">
            {goal.target_amount !== undefined && parseFloat(goal.target_amount) >= 0
              ? `${((goal.amount_saved >= parseFloat(goal.target_amount)) ? 100 : (goal.amount_saved / parseFloat(goal.target_amount)) * 100).toFixed(1)}%`
              : null}
          </Typography>
          <Progress value={goal.amount_saved} maxValue={Number(goal.target_amount)} />
        </Box>
      )}
      {
        isExplore && (
          <Button
            title='Join'
            variant="contained"
            disableElevation
            onClick={handleJoinGoal}
            loading={isLoading}
            sx={{ mt: 2, width: '100%', height: '35px' }} />
        )
      }
    </div>
  );
});


export default memo(GoalItem);
