import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps, useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { FieldRenderProps } from 'react-final-form';
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

interface Option {
  value: any;
  label: string;
  subtitle?: string;
}

interface RadioButtonsProps extends RadioGroupProps, FieldRenderProps<any, HTMLElement, any> {
  options: Option[];
  defaultValue?: any;
}

const StyledFormControlLabel = styled((props: FormControlLabelProps & { subtitle?: string }) => (
  <Box sx={{ ...(props.checked ? { border: '2px solid #673AB7', borderRadius: "4px", backgroundColor: "#F6F6F7" } : {}), padding: "0rem 0.25rem", display: "inline-flex", alignItems: "center", width: "100%" }}>
    <FormControlLabel {...props} label={
      <Box gap="0">
        <Typography variant="body1">{props.label}</Typography>
        <Typography variant="caption" sx={{ color: "text.secondary" }}>{props.subtitle}</Typography>
      </Box>
    } control={<Radio />} sx={{ ...props.sx, mx: "0" }} />
  </Box>
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": {
    // add styles here
  },
}));

function MyFormControlLabel(props: FormControlLabelProps & { subtitle?: string }) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

function RadioButtons({ options, input, meta, sx, labelPlacement = 'end', labelStyles, defaultValue = options[0].value, ...rest }: RadioButtonsProps) {
  return (
    <FormControl
      variant="standard"
      error={!!(meta?.error || meta?.submitError) && meta?.touched}
    >
      <RadioGroup {...input} row {...rest} sx={sx} defaultValue={defaultValue}>
        {options.map((option, i) => (
          <MyFormControlLabel
            value={option.value.toString()}
            label={option.label}
            subtitle={option.subtitle}
            key={i}
            sx={{ ...labelStyles }}
            labelPlacement={labelPlacement}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
      <FormHelperText>
        {meta?.touched ? meta?.error || meta?.submitError : ""}
      </FormHelperText>
    </FormControl>
  );
}

export default RadioButtons;
