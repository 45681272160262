import { Route, Switch } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import './styles/index.css'

import PageRoutes from "./routes/pageRoutes";
import withSidebar from "./components/Reusables/Navigation/Sidebar/WithSidebar";

const queryClient = new QueryClient();

function App():JSX.Element {
  const ComponentWithSidebar = withSidebar(PageRoutes);

  return (
      <QueryClientProvider client={queryClient}>
        <div>
          <Switch>
          <Route component={withSidebar(PageRoutes)} />
          </Switch>
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
  );
}

export default App;
