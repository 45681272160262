import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import HomeSharpIcon from "@mui/icons-material/HomeSharp";
import HistoryIcon from "@mui/icons-material/History";
import { NavLink, useHistory } from "react-router-dom";
import SvgIcon from "@mui/material/SvgIcon";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { Avatar, ListItemAvatar, Typography, useMediaQuery, Theme } from "@mui/material";
import { useStyles } from "./SidebarStyles";
import { ReactComponent as SavingIcon } from '../../../../assets/svgs/Money.svg';
import { ReactComponent as WalletIcon } from "../../../../assets/svgs/Wallet.svg";
import { ReactComponent as LendColoredIcon } from "../../../../assets/svgs/Profit_colored.svg";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useSignOut } from "../../../../hooks/authHooks";
import { useUser } from "../../../../hooks/userHooks";
import UserDrawer from "../../../profile/UserDrawer";
import HelpIcon from '@mui/icons-material/Help';
import { ReactComponent as LendIcon } from "../../../../assets/svgs/Lending.svg";

// UserAvatar Component
const UserAvatar: React.FC<{ userData: any }> = ({ userData }) => {
  const history = useHistory();
  return (
    <ListItemAvatar sx={{ alignSelf: 'flex-start', marginBottom: "0.69rem" }} onClick={() => history.push('/')}>
      <Avatar alt="Pasbanc" src="/logo.png" />
    </ListItemAvatar>
  )
};

// NavigationItem Component
interface NavigationItemProps {
  to: string;
  exact: boolean;
  children: React.ReactNode;
  mouseEnter?: () => void;
  mouseLeave?: () => void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ to, exact, children, mouseEnter, mouseLeave }) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      component={NavLink}
      to={to}
      exact={exact}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {children}
    </ListItem>
  )
};

// ProfileItem Component
interface ProfileItemProps {
  handleDrawerToggle: () => void;
  userData: any;
}

const ProfileItem: React.FC<ProfileItemProps> = ({ handleDrawerToggle, userData }) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem} onClick={handleDrawerToggle} sx={{ display: { sm: 'none', md: 'block' } }}>
      <Avatar alt={userData?.first_name} src={userData?.profile_img} sx={{ width: '1.875rem', height: '1.875rem' }}>
        {userData?.first_name.charAt(0)}
      </Avatar>
      <Typography variant='button'>Profile</Typography>
    </ListItem>
  )
};

// LogoutItem Component
interface LogoutItemProps {
  handleSignout: () => void;
}

const LogoutItem: React.FC<LogoutItemProps> = ({ handleSignout }) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      onClick={handleSignout}
    >
      <ExitToAppOutlinedIcon fontSize="small" />
      <Typography variant='button'>Logout</Typography>
    </ListItem>
  )
};

// PermanentDrawerLeft Component
function PermanentDrawerLeft() {
  const classes = useStyles();
  const history = useHistory()
  const { signOut } = useSignOut();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const handleSignout = () => {
    signOut();
    history.push(`/auth/login/email`)
  }
  const { data: userData, } = useUser();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    if (isDesktop) {
      history.push('/profile')
    } else {
      setIsDrawerOpen(!isDrawerOpen);
    }
  };
  const [hover, setHover] = useState(false);
  const activeLendRoute = history.location.pathname.startsWith('/loans');

  return (
    <List className={classes.list}>
      <div className={classes.navList}>
        <UserAvatar userData={userData} />
        <NavigationItem to="/" exact={true}>
          <HomeSharpIcon fontSize="small" />
          <Typography variant='button'>Home</Typography>
        </NavigationItem>
       {/*  <NavigationItem to="/wallet" exact={false}>
          <SvgIcon component={WalletIcon} fontSize="small" />
          <Typography variant='button'>Wallet</Typography>
        </NavigationItem> */}
        <NavigationItem to="/goals" exact={false}>
          <SvgIcon component={SavingIcon} fontSize="small" />
          <Typography variant='button'>Savings</Typography>
        </NavigationItem>
        <NavigationItem to="/loans" exact={false} mouseEnter={() => setHover(true)} mouseLeave={() => setHover(false)}>
          <SvgIcon component={hover || activeLendRoute ? LendColoredIcon : LendIcon} fontSize="small" />
          <Typography variant='button'>Lending</Typography>
        </NavigationItem>
        <NavigationItem to="/transactions" exact={false}>
          <HistoryIcon fontSize="small" />
          <Typography variant='button'>History</Typography>
        </NavigationItem>
      </div>

      <div className={classes.navList}>
        <ProfileItem handleDrawerToggle={handleDrawerToggle} userData={userData} />
        {userData && <UserDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} userData={userData} />}
        {isDesktop && <><NavigationItem to={`/profile/settings`} exact={false}>
          <SettingsOutlinedIcon fontSize="small" />
          <Typography variant='button'>Settings</Typography>
        </NavigationItem>
          <NavigationItem to={`/profile/help`} exact={false}>
            <HelpIcon fontSize="small" />
            <Typography variant='button'>Help center & FAQ</Typography>
          </NavigationItem></>}

        <LogoutItem handleSignout={handleSignout} />
      </div>
    </List>
  );
}
export default PermanentDrawerLeft;

