import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: '16px 16px 0px 16px',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      height: 163
    },
  },
  transactionContent: {
    padding: "0px 16px",
    textAlign: 'center',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      maxWidth: "1000px",
      margin: "0 auto",
    },
  },
  transactionTabs: {
    [theme.breakpoints.up('lg')]: {
      padding: '0px',
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiTabs-scroller .MuiTabs-flexContainer': {
        justifyContent: 'space-around'
      },
      '& .MuiTabs-indicatorSpan': {
        maxWidth: 50,
      },
    }

  },
  transactionTab: {
    [theme.breakpoints.up('lg')]: {
      color: '#fff',

      '&.Mui-selected': {
        color: theme.palette.secondary.main,
        backgroundColor: '#482980'
      },
    }
  },
  statusSection: {
    marginTop: theme.spacing(1.2),
    overflowX: 'scroll',
    padding: `0px ${theme.spacing(2)}`,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 444,
      overflowX: 'visible'

    }
  },
  transactionSection: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between'

    }
  },
  transactionSearchbar: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      // placeholder text
      '& input::placeholder': {
        color: theme.palette.grey[500],
        fontWeight: 600,
      },
    },
  },

}));
