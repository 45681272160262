import React, { useState } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import Stepper from "@mui/material/Stepper";
import { useStyles } from './GoalStyles'
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Alert, Avatar, Box, IconButton, Typography } from "@mui/material";
import Button from "../../components/Reusables/Input/Button";
import { styled } from '@mui/material/styles';
import { useStyles as useButtonStyles } from '../../components/Reusables/Input/ButtonStyles'
import { useCreateGoal } from '../../hooks/goalHooks';
import { calculateEndDate, calculateTotalAmountAccrued, convertMonthsToYears, convertYearsToMonths, numberWithCommas, removeCommas, renderInterestRate, returnMinimumDeposit, stringToBoolean } from '../../utils/middleware';
import { useHistory, useParams } from 'react-router-dom';
import DurationOptionsList from '../../components/Reusables/Display/DurationOptionList';
import FormInput from '../../components/Reusables/Input/FormInput';
import { adminAccessOptions, adminOptions, currencyOptionsArray, durationOptions, privacyOptions, savingFrequencyOptions } from '../../components/Reusables/Input/select/Options';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Select from '../../components/Reusables/Input/select/Select';
import DoneIcon from '@mui/icons-material/Done';
import dayjs from 'dayjs';
import RadioButtons from '../../components/Reusables/Input/RadioGroup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoDialog from '../../components/Reusables/Feedback/InfoDialog';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import theme from '../../theme';

export interface FormValues {
  name: string;
  currency: string;
  visibility: string;
  targetAmount: string;
  file: null;
  type: string;
  admin_access: string;
  revolving: string;
  durationOption: number | string;
  customDuration: number | null;
  frequency: string;
}

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  padding: "10px",
  '& .Mui-active .MuiSvgIcon-root': {
    color: theme.palette.success.main,
    '& .MuiStepIcon-text': {
      fill: '#000'
    }
  },
  '& .Mui-disabled .MuiSvgIcon-root': {
    color: `#E4F7E8`,
    '& .MuiStepIcon-text': {
      fill: '#000'
    }
  },
  '& .Mui-completed .MuiSvgIcon-root': {
    color: theme.palette.success.main,
  }
}));

export const CreateGoal = () => {

  const { isError, error, handleCreateGoal, isLoading: creatingGoal } = useCreateGoal();

  const [activeStep, setActiveStep] = useState(0);
  const [createdGoalData, setCreatedGoalData] = useState<any>(null);
  const history = useHistory()
  const classes = useStyles();
  const buttonClasses = useButtonStyles()

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (activeStep === 0) {
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.currency) {
        errors.currency = "Required";
      }
      if (!values.visibility) {
        errors.visibility = "Required";
      }

    }
    if (activeStep === 1) {

      if (!values.durationOption) {
        errors.durationOption = "Required";
      } else {
        if (values.durationOption === "year") {
          if (!values.customDuration) {
            errors.durationOption = "Duration must be between 2 and 15 years";
          }
          else {
            const durationValue = values.customDuration;
            if (isNaN(durationValue) || durationValue > 15 || durationValue < 2) {
              errors.durationOption = "Duration must be between 2 and 15 years";
            }
          }
        }
      }
    }
    return errors;
  };


  const handleNext = async (values: FormValues) => {
    try {
      if (activeStep === 2) {
        let data = {
          ...values,
          targetAmount: values.targetAmount ? removeCommas(values.targetAmount) : undefined,
          admin_access: stringToBoolean(values.admin_access),
          revolving: stringToBoolean(values.revolving),
          duration: typeof values.durationOption === 'number' ? values.durationOption : values.durationOption === 'year' && values.customDuration ? convertYearsToMonths(values.customDuration) : values.customDuration as number,
          frequency: values.frequency,
        }


        const createdGoal = await handleCreateGoal(data);
        setCreatedGoalData(createdGoal);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);



      } else {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      console.log(error)
    }

  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Form
      onSubmit={handleNext}
      validate={validate}
      initialValues={{
        frequency: "anytime",
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className={classes.container}>
          <Box sx={{ width: '100%' }}>
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackOutlinedIcon />
            </IconButton>
            <Stepper
              activeStep={activeStep}
              orientation='horizontal'
              sx={{ mt: 2, maxWidth: '28.125rem', m: '16px auto' }}
              color='success'
            >

              {['', '', ''].map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                return (
                  <Step {...stepProps} key={index}>
                    <CustomStepLabel {...labelProps}>{label}</CustomStepLabel>
                  </Step>
                );
              })}
            </Stepper>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '28.125rem', m: '16px auto' }}>
              {activeStep === 0 && (<GoalName />)}
              {activeStep === 1 && (<GoalDuration data={values} />)}
              {activeStep === 2 && (<GoalPreview data={values} />)}
              {activeStep === 3 && (<Success />)}
              {activeStep !== 3 ? (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: "3rem",
                  justifyContent: 'center',
                  px: { xs: "1rem", md: 0 }
                }}>
                  <Button
                    variant="outlined"
                    title='Back'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, width: { sm: '150px', xs: "130px" } }}

                  />
                  <Button
                    type='submit'
                    variant="contained"
                    title={activeStep === 2 ? 'Create goal' : 'Next'}
                    sx={{ width: { sm: '150px', xs: "130px" } }}
                    disabled={creatingGoal}
                    loading={creatingGoal}
                  />
                </Box>
              ) : (
                <Button
                  title="Go to goal"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    if (createdGoalData) {
                      history.push(`/goals/goal/${createdGoalData.id}`);
                    } else {
                      history.push('/goals');
                    }
                  }}
                  className={buttonClasses.largeButton}
                  sx={{
                    mt: 5
                  }}
                />
              )}
            </Box>
            {isError && activeStep == 2 && <Alert severity="error" sx={{ textAlign: 'center' }}>{(error as Error).message}</Alert>}

          </Box>
        </form>
      )}
    />
  )
}

const GoalName = () => {
  const [openInfo, setOpenInfo] = useState(false);
  const handleInfoDialog = () => {
    setOpenInfo(!openInfo);
  };
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const imageUpload = () => {
    const fileInput = document.querySelector("#photoUpload") as HTMLInputElement;
    fileInput.click();
  };

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    input: FieldRenderProps<File | null, HTMLElement>['input']
  ) => {
    const file = e.target.files && e.target.files[0];
    input.onChange(file);
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const classes = useStyles();

  return (
    <Box className={classes.formContainer}>
      <Box
        sx={{
          width: '100%',
          height: '114px',
          border: `1px dashed #8C9196`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '4px',
          alignSelf: 'center',
        }}
        style={{ backgroundImage: `url(${imagePreview})`, backgroundSize: "cover", backgroundPosition: "center" }} >
        <Field name="file">
          {({ input }) => (
            <div>
              <input
                type="file"
                accept="image/*"
                id="photoUpload"
                onChange={(e) => handleImageChange(e, input)}
                style={{ display: 'none' }}
              />
              <label htmlFor="photoUpload">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, opacity: imagePreview ? 0.3 : 1 }}>
                  <InsertPhotoIcon />
                  <Button
                    title={"Upload Image"}
                    color="primary"
                    variant="outlined"
                    onClick={imageUpload}
                  />
                </Box>
              </label>
            </div>
          )}
        </Field>
      </Box>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: '1.25rem'
        }}>
        <Field
          label="Goal Name"
          name="name"
          type="text"
          component={FormInput}
        />
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ flex: 0.5 }}>
            <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}> Currency</Typography>
            <Field
              type="select"
              name="currency"
              component={Select}
              options={currencyOptionsArray}
            />
          </Box>
          <Box sx={{ flex: 0.5 }}>
            <Typography
              sx={{ marginBottom: '4px', fontWeight: 600 }}> Visibility</Typography>
            <Field
              type="select"
              name="visibility"
              component={Select}
              options={privacyOptions}
            />
          </Box>
        </Box>
      </Box>
      <InfoDialog
        title="Managing funds"
        content={
          <Box component='span'>
            <Typography component='span' fontWeight={600}>
              Goal owner and/or admins
              <Typography component='span' sx={{ display: 'block' }}>
                Goal owners will have the authority to withdraw or assign fund control to admins.
              </Typography>
            </Typography>
            <Typography component='span' fontWeight={600} sx={{ mt: 1 }}>
              Everybody to their own
              <Typography component='span' sx={{ display: 'block' }}>
                Individuals can be responsible for controlling their own funds within the same goal.
              </Typography>
            </Typography>
          </Box>
        }
        open={openInfo}
        handleClose={handleInfoDialog}
        width="auto"
        padding="0 0 24px 0"
      />
    </Box>
  );
}


function GoalDuration({ data }: { data: FormValues }) {
  const [openInfo, setOpenInfo] = useState(false);
  const handleInfoDialog = () => {
    setOpenInfo(!openInfo);
  };
  const { durationOption, customDuration, targetAmount, currency } = data
  const duration = typeof durationOption === 'number' ? durationOption : customDuration;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: "100%" }}>
      <Box>
        <Typography variant="body1" fontWeight={600}>Desired target amount<Typography variant='caption' component='span'>{" "}(optional)</Typography></Typography>
        <Field name="targetAmount">
          {(props: any) => <FormInput
            label=""
            type="text"
            value={numberWithCommas(props.input.value)}
            onChange={(e) => {
              props.input.onChange(removeCommas(e.target.value));

            }}
            {...props}
          />}
        </Field>
      </Box>

      <Box>
        <Typography variant='body1' fontWeight={600}
          sx={{ marginBottom: '4px' }}>For how long?</Typography>
        <Field name="durationOption">
          {({ input, meta }) => (
            <DurationOptionsList
              options={durationOptions.slice(0, -1)}
              value={input.value}
              onChange={input.onChange}
              meta={meta}
              customStyles={{
                [theme.breakpoints.up("sm")]: {
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1rem"
                },
                [theme.breakpoints.down("sm")]: {
                  display: 'flex', flexWrap: 'wrap', gap: "16px", width: "100%", justifyContent: "flex-start"
                }
              }}
            />
          )}
        </Field>
      </Box>

      <Field
        name="durationOption"
        subscription={{ dirty: true }}
        render={({ meta: { dirty } }) =>
          dirty && targetAmount && duration ? (
            <Alert severity="success"
              sx={{ padding: '0.5rem', backgroundColor: '#E4F7E8', color: '#000' }}>
              <Typography variant='body2'>
                Saving <Typography component='span' variant='body2' fontWeight={700}>
                  {numberWithCommas(
                    removeCommas(targetAmount)
                  )}</Typography> <Typography component='span' variant='overline'>{currency}</Typography> for <Typography component='span' variant='body2' fontWeight={700}>{
                    renderInterestRate(duration).rate
                  } </Typography>results into <Typography component='span' variant='body2' fontWeight={700}>{numberWithCommas(
                    calculateTotalAmountAccrued({
                      period: duration,
                      amount: parseFloat(removeCommas(targetAmount)),

                    })
                  )} </Typography> <Typography component='span' variant='overline'>{currency}</Typography> on <Typography variant='body2' fontWeight={700} component='span'>
                  {calculateEndDate({
                    duration: duration,
                  })}</Typography>
              </Typography>
              <Typography variant='body2' >
                {
                  duration >= 24
                    ? `Note when saving for this period,the minimum amount per deposit is 
                        ${currency}
                        ${numberWithCommas(
                      returnMinimumDeposit(duration, currency)
                    )}`
                    : ""
                }
              </Typography>
            </Alert>
          ) : null
        }
      />


      {durationOption === 'year' && (
        <Field name="customDuration">
          {(props: any) => <FormInput
            label="Enter duration"
            value={props.input.value > 12 ? convertMonthsToYears(props.input.value) : props.input.value}
            type="number"
            {...props}
          />}
        </Field>
      )}

      <InfoDialog
        title="Revolving funds"
        content={
          <Typography component='span'>
            Revolving funds are goals or pockets whose jointly pooled funds are distributed or rotated among the goal participants until everyone has received their share.
            <Typography component='span' sx={{ display: 'block', mt: 1 }}>
              Find more in goal settings
            </Typography>
          </Typography>
        }
        open={openInfo}
        handleClose={handleInfoDialog}
        width="auto"
        padding="0 0 24px 0"
      />
    </Box>
  );
}



function Success() {

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 3
    }}>
      <Avatar sx={{ bgcolor: '#22C55E', width: '5.6875rem', height: '5.6875rem' }}>
        <DoneIcon fontSize="large" />
      </Avatar>
      <Typography variant='h3' sx={{ mt: 3 }}>Success!</Typography>
      <Typography variant='body1' sx={{ textAlign: 'center', maxWidth: "21.625rem", width: "100%", margin: "0 auto" }}>
        {`Your new savings goal is up and running. Congratulations!`}
      </Typography>
    </Box>
  );
}



function GoalPreview({ data }: { data: FormValues }) {
  const { durationOption, customDuration, targetAmount, currency, name, frequency } = data

  const duration = typeof durationOption === 'number' ? (durationOption) : durationOption === 'year' && customDuration ? customDuration : null

  const durationText = typeof durationOption === 'number' ? 'months' : durationOption === 'year' && customDuration ? 'years' : 'days'

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography
        variant='body1'
        fontWeight={700}>Goal Preview</Typography>
      <Box className={classes.formContainer}>
        <Box className={classes.previewCard}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body1'>Goal name</Typography>
            <Typography variant='body1' sx={{ fontWeight: 700 }} textAlign={"right"}>{name}</Typography>
          </Box>
          {
            targetAmount && parseFloat(removeCommas(targetAmount)) > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='body1'>Target Amount</Typography>
                <Typography variant='body1' sx={{ fontWeight: 700 }} textAlign={"right"}>
                  {numberWithCommas(removeCommas(targetAmount))}{" "}
                  <Typography component='span' variant='overline'>{currency}</Typography>
                </Typography>
              </Box>
            )
          }

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body1'>Duration</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
              <Typography variant='body1' sx={{ fontWeight: 700 }} textAlign={"right"}>
                {`${duration} ${durationText}`}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body1'>Start Date</Typography>
            <Typography variant='body1' textAlign={"right"}>
              {`${dayjs().format('DD MMM YYYY')}`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body1'>Maturity Date</Typography>
            <Typography variant='body1' textAlign={"right"}>
              {duration && `${dayjs().add(duration, `${durationText}`).format('DD MMM YYYY')}`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body1'>Interest rate</Typography>
            <Typography variant='body1' sx={{ fontWeight: 700 }} textAlign={"right"}>
              {duration && `${renderInterestRate(duration).rate}`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body1'>Saving Frequency</Typography>
            <Typography variant='body1' sx={{ fontWeight: 700 }} textAlign={"right"}>{frequency} </Typography>
          </Box>
        </Box>

        <Box width="100%" marginTop="1rem" display="flex" flexDirection={"column"} gap="0.5rem">
          <Typography variant="body2" fontWeight={600}>Select saving frequency</Typography>
          <Field name="frequency" type="radio" width="100%">
            {(props: any) => (
              <RadioButtons
                options={savingFrequencyOptions}
                onChange={(e) => {
                  props.input.onChange(e.target.value);
                }}
                {...props}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "0.5rem"
                }}
                labelPlacement={'start'}
                labelStyles={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              />

            )}
          </Field>
        </Box>
      </Box>
    </div>
  );
}
