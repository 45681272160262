import React, { useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  useMediaQuery,Theme
} from '@mui/material';
import { useUpdateUser, useUser } from '../../hooks/userHooks';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Link, useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from '../../components/Modals/Modal';
import Button from '../../components/Reusables/Input/Button';


function SecurityPrivacy() {
  const {data:user}= useUser()
  const { updateUser } = useUpdateUser();
  const isDesktop = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));

  const [usePinForLogin, setUsePinForLogin] = useState<boolean>(user?.use_pin_for_login);

 const [usePinForTransactions, setUsePinForTransactions] = useState<boolean>(user?.use_pin_for_transactions);


  const [deleteAccount, setDeleteAccount] = useState(false);
  const history = useHistory();



  const handleDeleteConfirmation = () => {
    setDeleteAccount(false);
    // Redirect to security questions page
    history.push('/profile/security-questions');
  };

  const handleUsePinForLoginToggle = async() => {
     setUsePinForLogin(!usePinForLogin);
    try {
      await updateUser({
        use_pin_for_login: !usePinForLogin,
      });
     
    } catch (error) {
      // Handle error or show error message
    }
  };

  const handleUsePinForTransactionsToggle = async () => {
     setUsePinForTransactions(!usePinForTransactions,);
    try {
      await updateUser({
        use_pin_for_transactions: !usePinForTransactions,
      });
     
    } catch (error) {
      // Handle error or show error message
    }
  };

  

  
  

  return (
    <Box m={2}>
       <Box sx={{display:'flex',alignItems:'start',gap:1,mt:2}}>
        {!isDesktop && (
          <IconButton onClick={()=>history.push(`/profile/settings`)} sx={{p:0}}>
            <ArrowBackOutlinedIcon />
         </IconButton>
        )}
        
          <Typography variant="h6" fontWeight={600} sx={{m:'0 auto'}}>
          Security & Privacy
        </Typography>
         </Box> 
        <List sx={{maxWidth:'500px',m:'0 auto'}}>
         <ListItem
            sx={{ px: 0 }}
            component={Link}
            to={`/profile/settings/edit-pin`}
          >
            <ListItemText primary={`PIN setup`} primaryTypographyProps={{fontWeight:600}} />
            <ListItemIcon sx={{ minWidth: 'unset', color: '#000' }}>
              <ArrowForwardIosIcon fontSize="small" />
            </ListItemIcon>
          </ListItem>
          <ListItem
            sx={{ px: 0 }}
            component={Link}
            to={`/profile/settings/edit-password`}
          >
            <ListItemText primary={`Password setup`} primaryTypographyProps={{fontWeight:600}} />
            <ListItemIcon sx={{ minWidth: 'unset', color: '#000' }}>
              <ArrowForwardIosIcon fontSize="small" />
            </ListItemIcon>
          </ListItem>
          <ListItem sx={{pl:0}}>
            <ListItemText primary="Use PIN for account login" primaryTypographyProps={{fontWeight:600}}/>
            <ListItemSecondaryAction sx={{right:'-5px'}}>
              <Switch checked={usePinForLogin} onChange={handleUsePinForLoginToggle} />
            </ListItemSecondaryAction>
          </ListItem>
         
          <ListItem sx={{pl:0}} >
            <ListItemText primary="Use PIN for transactions" primaryTypographyProps={{fontWeight:600}}/>
            <ListItemSecondaryAction sx={{right:'-5px'}}>
              <Switch
                checked={usePinForTransactions}
                onChange={handleUsePinForTransactionsToggle}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem sx={{px:0}} component={Link} to={`/profile/settings/security-questions`}>
            <ListItemText primary="Security questions" primaryTypographyProps={{fontWeight:600}} />
          </ListItem>
         
          <Divider />
          <ListItem button onClick={()=>setDeleteAccount(!deleteAccount)} sx={{px:0}}>
            <ListItemText primary="Delete my account" primaryTypographyProps={{fontWeight:600}} />
            <ListItemIcon sx={{ minWidth: 'unset', color: '#000' }}>
              <ArrowForwardIosIcon fontSize="small" />
            </ListItemIcon>
          </ListItem>
        </List>
        {
            deleteAccount && (
                <Modal open={deleteAccount} handleClose={()=>setDeleteAccount(false)}>
                <Typography sx={{px:1,pt:1,textAlign:'center'}}>{`Are you sure you want to delete your Pasbanc account? `}</Typography>
                 <Typography sx={{px:1,py:1,textAlign:'center',fontWeight:600}}>{`Make sure you have no funds at all anywhere within the app.`}</Typography>
                <Box sx={{m:'8px auto',textAlign:'center'}}>
                    <Button title='Cancel' color='inherit' sx={{mr:1.5}} />
                    <Button title ='Proceed' color='error' onClick={handleDeleteConfirmation} variant='contained'/>
                </Box>
                    
                </Modal>
            )
        }
    </Box>
  );
}

export default SecurityPrivacy;
