import { Route, Switch } from "react-router-dom";
/**goal screens */
import GoalPage from "../pages/goals/GoalPage";
import EditGoal from "../pages/goals/EditGoal";
import authGuard from "../utils/auth";
import Savings from "../pages/goals/Savings";
import { CreateGoal } from "../pages/create/CreateGoal";
import GoalTransactions from "../pages/goals/GoalTransactions";
import GoalMember from "../pages/goals/GoalMember";
import GoalMemberContributions from "../pages/goals/GoalMemberContributions";
import PayoutHistory from "../pages/goals/PayoutHistory";
import ReinvestmentStepper from "../components/Modals/ReInvestFunds";

/**end of goal screens */
const GoalRoutes = () => {
  return (
    <Switch>
       <Route exact path="/goals/reinvest" component={authGuard(ReinvestmentStepper)} />
      <Route exact path="/goals/create" component={authGuard(CreateGoal)} />
      <Route  exact path="/goals/edit/:id" component={authGuard(EditGoal)} />
      <Route  exact path="/goals/goal/:id" component={authGuard(GoalPage)} />
      <Route  exact path="/goals/payouts/:id" component={authGuard(PayoutHistory)} />
      <Route  exact path="/goals/transactions/:id" component={authGuard(GoalTransactions)} />
      <Route  exact path="/goals/members/:id" component={authGuard(GoalMember)} />
      <Route  exact path="/goals/members/transactions/:id" component={authGuard(GoalMemberContributions)} />
       <Route exact path="/goals/join/:id" component={authGuard(GoalPage)} />

      <Route  exact path='/goals/create/:groupId' component={authGuard(CreateGoal)}/>
      <Route  exact path='/goals' component={authGuard(Savings)}/>

    </Switch>
  );
};

export default GoalRoutes;
