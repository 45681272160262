import { useState } from "react";
import Button from "../../components/Reusables/Input/Button";
import FormInput from "../../components/Reusables/Input/FormInput";
import { Alert, Avatar, Box, Chip, IconButton, Typography } from "@mui/material";
import { useStyles } from "./loginStyles";
import { Link, useHistory } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { useSignUp, useUserContact, useVerifyUser } from '../../hooks/authHooks';
import CustomLink from "../../components/Reusables/Input/CustomLink";
import { useStyles as useButtonStyles } from '../../components/Reusables/Input/ButtonStyles';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toast from "../../components/Reusables/Feedback/Toast";

type FormValues = {
  firstname: string;
  lastname: string;
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
  pin: string;
  confirmPIN: string;
  otp: string;
};

function EmailRegister() {
  const { signUpByEmail, loading: signUpLoading, error: signUpError } = useSignUp();
  const { verifyUserByEmail, error } = useVerifyUser();
  const history = useHistory()


  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Enter First Name', 'Set Password', 'Enter PIN'];

  const handleNext = async (values: FormValues) => {
    if (activeStep === 0) {
      try {
        await verifyUserByEmail({
          otp: values.otp,
          email: values.email,
        });
        setActiveStep(prevActiveStep => prevActiveStep + 1);

      } catch (error) {
        // Handle error
      }
    }
    else if (activeStep === 2) {
      await signUpByEmail({
        email: values.email,
        password: values.password,
        firstname: values.firstname,
        lastname: values.lastname,
        username: values.username,
        pin: values.pin
      });
      history.push('/', { fromSignup: true })
    } else if (activeStep < steps.length) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };


  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (activeStep === 0) {
      if (!values.email) {
        errors.email = "Required";
      }
      if (!values.otp) {
        errors.otp = "Required";
      }
      if (!values.firstname) {
        errors.firstname = "Required";
      }
      if (!values.lastname) {
        errors.lastname = "Required";
      }
      if (!values.username) {
        errors.username = "Required";
      }
    }

    if (activeStep === 1) {
      if (!values.password) {
        errors.password = "required";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "required";
      }
      if (values.password?.length < 8) {
        errors.password = "Password must have a mimimum of 8 chars";
      }
      if (values.confirmPassword !== values.password) {
        errors.confirmPassword = "Passwords must match";
      }
    }
    if (activeStep === 2) {
      if (!values.pin) {
        errors.pin = "required";
      }
      if (values.pin && values.pin.length !== 5) {
        errors.pin = 'PIN must be five numbers'
      }
      if (!values.confirmPIN) {
        errors.confirmPIN = "required";
      }

      if (values.confirmPIN !== values.pin) {
        errors.confirmPIN = "Pins must match";
      }
    }
    return errors;

  };



  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  return (
    <div className={classes.container}>
      <Box className={classes.logoContainer}>
        <Avatar alt="Pasbanc" src="/logo.png" />
        <Typography variant='h5'>Pasbanc</Typography>
      </Box>
      <Box className={classes.formContainer}>
        <Form
          onSubmit={handleNext}
          validate={validate}
          render={({ handleSubmit, submitting, values }) => (
            <form className={classes.customForm} onSubmit={handleSubmit}>
              {activeStep === 0 && <UserDetails email={values.email} />}
              {activeStep === 1 && <CreatePassword handleBack={handleBack} />}
              {activeStep === 2 && <CreatePIN handleBack={handleBack} />}
              {activeStep === 0 && error && <Alert severity="error">{(error as Error).message}</Alert>}
              {activeStep === 2 && signUpError && <Alert severity='error'>{(signUpError as Error).message}</Alert>}
              <Box mt={1}>
                <Button
                  title="Create account"
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={buttonClasses.largeButton}
                  disabled={submitting || signUpLoading}
                />
              </Box>

            </form>
          )}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: "0.96rem", my: "1.12rem" }}>
          <CustomLink
            title="Already have an account?"
            href="/auth/login/mobile"
            sx={{ fontWeight: 400 }}
          />
          <CustomLink
            title="Log in"
            href="/auth/login/mobile"
            sx={{ color: 'primary.main', backgroundColor: '#F0EBF8', padding: '0.625rem', borderRadius: 1, height: "2.0625rem", width: "6.125rem", display: "flex", justifyContent: "center", alignItems: "center" }}
          />
        </Box>

        <Typography
          variant="caption"
          sx={{
            textAlign: 'center'
          }}
        >
          By creating an account, you have agreed to our terms and conditions.
        </Typography>
        <Link to='/auth/signup/register-stepper'>
          <Typography sx={{ textDecoration: 'underline' }} variant='caption'>
            Become an investor
          </Typography>
        </Link>

      </Box>
    </div>
  );
}



export default EmailRegister;

const UserDetails = ({ email }: { email: string }) => {
  const classes = useStyles();

  const { getUserByEmail, error } = useUserContact();
  const [open, setOpen] = useState(false);

  const handleEmailLookup = async () => {
    try {
      await getUserByEmail({
        email,
      });
      setOpen(true)

    } catch (error) {
      // Handle error
    }
  };
  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h2">Signup</Typography>
        <Typography variant="body1">Start your Pasbanc journey here</Typography>
      </Box>
      <Box className={classes.nameContainer}>
        <Field name="firstname">
          {(props: any) => <FormInput
            label="First name"
            type="text"

            {...props}
          />}
        </Field>
        <Field name="lastname">
          {(props: any) => <FormInput
            label="Last name"
            type="text"
            {...props}
          />}
        </Field>
      </Box>

      <Box sx={{ textAlign: 'left', width: '100%' }}>
        <Field name="username">
          {(props: any) => <FormInput
            label="User name"
            placeholder="@username"
            type="text"

            {...props}
          />}
        </Field>
      </Box>

      <Box sx={{ width: '100%' }} >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography fontWeight={600}>Email</Typography>
          <Chip label={<Typography variant="button" component='span'>Use <Typography variant="body2" fontWeight={700} component='span'>phone</Typography> instead</Typography>} component={Link} to={`/auth/register/mobile`} clickable />
        </Box>
        <Field name="email">
          {(props: any) => <FormInput
            label=""
            type="email"
            name="email"

            {...props}
          />}
        </Field>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Field name='otp'>
          {(props: any) => <FormInput label=''
            placeholder='Enter code'
            InputProps={{
              endAdornment: <Typography variant="button" noWrap sx={{ width: "-webkit-fill-available", cursor: "pointer", color: '#000' }} onClick={handleEmailLookup}>Send me a code</Typography>
            }}
            {...props}

          />}
        </Field>
      </Box>
      {error && <Alert severity='error'>{(error as Error).message}</Alert>}
      <Toast open={open} type={'success'} message={'Code sent'} handleClose={() => setOpen(false)} />

    </>
  )
}



export const CreatePassword = ({ handleBack }: { handleBack: any }) => {

  const [showPassword, setPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);

  return (
    <div >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '-54px' }}>
        <IconButton onClick={handleBack}><ArrowBackIcon /></IconButton>

      </Box>
      <Typography variant='h4' sx={{ mt: 2, textAlign: "center" }}>Set your Password
        <Typography variant='body2' sx={{ mt: 1, textAlign: "center", display: 'block' }} component='span'> Enter a password you will easily remember to access your Pasbanc account</Typography>
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, textAlign: 'left' }}>
        <Field name="password">
          {(props: any) => <FormInput
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            Icon={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPassword(!showPassword)}
                size="large">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
            sx={{ width: '18.75rem' }}
            {...props}
          />}
        </Field>
        <Field name="confirmPassword">
          {(props: any) => <FormInput
            label="Confirm Password"
            name="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            position="end"
            Icon={<IconButton
              aria-label="toggle password visibility"
              onClick={() => setConfirmPassword(!showConfirmPassword)}
              size="large">
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>}
            sx={{ width: '18.75rem' }}
            {...props}
          />}
        </Field>
      </Box>
    </div>
  );
}


export const CreatePIN = ({ handleBack }: { handleBack: any }) => {

  const [showPassword, setPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);


  return (
    <div >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: 'left', marginTop: '-54px' }}>
        <IconButton onClick={handleBack}><ArrowBackIcon /></IconButton>
      </Box>

      <Typography variant='h3' sx={{ mt: 2, textAlign: "center" }}>Set your PIN
        <Typography sx={{ mt: 1, textAlign: "center", display: 'block' }} component='span'> Enter a PIN code you will use to login and transact while in your Pasbanc account(5)</Typography>
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, textAlign: 'left' }}>
        <Field name="pin">
          {(props: any) => <FormInput
            label="PIN"
            name="password"
            type={showPassword ? "text" : "password"}
            Icon={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPassword(!showPassword)}
                size="large">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
            sx={{
              width: '18.75rem'
            }}
            {...props}
          />}
        </Field>

        <Field name="confirmPIN">
          {(props: any) => <FormInput
            label="Confirm PIN"
            name="confirmPIN"
            type={showConfirmPassword ? "text" : "password"}
            position="end"
            Icon={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setConfirmPassword(!showConfirmPassword)}
                size="large">
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
            sx={{
              width: '18.75rem'
            }}
            {...props}
          />}

        </Field>
      </Box>

    </div>
  );
}