import { useQuery,useMutation, useInfiniteQuery, useQueryClient, QueryClient } from '@tanstack/react-query';
import pocketsApiClient from '../apis/apiServices/pockets';
import { ExtendedPocketMember, ExtendedRevolvingSettings } from '../types/actionTypes';
import { PastRevolvingPayout, Pocket, PocketItem, PocketMember, RevolvingSettings, TransactionData, UpcomingRevolvingPayout } from '../types/types';


export const usePockets = (walletId: number | null) => {
  if (walletId === null) {
    return useQuery<PocketItem[], Error>(['pockets',walletId], {
      placeholderData: [],
    });
  }

  return useQuery<PocketItem[], Error>(['pockets',walletId], () => pocketsApiClient.getAll(walletId), {
    placeholderData: [],
  });
};


export const useCreatePocket = () => {
   const queryClient = useQueryClient();
  const mutation = useMutation((pocketData: Partial<PocketItem>) => pocketsApiClient.create(pocketData),{
    onSuccess:()=>{
      queryClient.invalidateQueries(['pockets'])
    }
  });

  // Function to handle creating a goal
  const createPocket = async (data: Partial<PocketItem>) => {
    try {
      // Call the mutate function with the necessary data
      const response =  await mutation.mutateAsync(data);
      return response
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error creating pocket:', error);
      throw new Error('error creating pocket')
      // Handle the error state, display an error message, etc.
    }
  };

  return {
    ...mutation,
    createPocket,
  };
};


export const usePocket = (pocketId: number) => {
    return useQuery<Pocket,Error>(['pocket', pocketId], () => pocketsApiClient.getById(pocketId));
  };


export const usePocketTransactions = (filter: any, hasSearchTerm: boolean,pocketId:number) => {
    return useInfiniteQuery<TransactionData, Error>(['pocket-transactions', JSON.stringify(filter),pocketId], ({ pageParam = 0 }) => {
      const params = {
        ...filter,
        skip: pageParam
      };
      return  pocketsApiClient.getTransactions(pocketId,params);
    }, {
      refetchInterval: 2 * 60 * 1000, // 2 minutes in milliseconds
      placeholderData:{ pageParams: [undefined, 1], pages: []},
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || !lastPage.transactions || lastPage.transactions.length < 10) {
          return null;
        }
        return pages.length * 10;
      },
    });
  };

 
  
  export const useUpdatePocket = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation((params: { id: number,data:Partial<Pocket> }) =>pocketsApiClient.updateById(params.id, params.data),{
    onSuccess:(_,variables)=>{
     queryClient.invalidateQueries(['pocket',variables.id])
    }
  });

  const updatePocket = async (params: { id: number,data:Partial<Pocket> }) => {
    try {
      const response = await mutation.mutateAsync(params);
      return response.data;
    } catch (error) {
      console.error('Error updating the pocket', error);
      throw new Error('Error updating the pocket');
    }
  };

  return {
    ...mutation,
    updatePocket,
  };
}
  
  export const useUpdatePocketAdminAccess = () => {
  const mutation = useMutation<void, Error, { id: number; data: { admin_access: boolean } }>(
    ({ id, data }) => pocketsApiClient.updateAdminAccess(id, data)
  );

  const updatePocketAdminAccess = async (id: number, data: { admin_access: boolean }) => {
    try {
      // Call the mutate function with the necessary data
      await mutation.mutateAsync({ id, data });
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error updating pocket admin access:', error);
      throw new Error('Error updating pocket admin access');
    }
  };

  return {
    ...mutation,
    updatePocketAdminAccess,
  };
};


  
  export const useDeletePocket = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation((params: { pocketId: number; data: { password: string } }) => pocketsApiClient.deleteById(params.pocketId,params.data),{
    onSuccess:()=>{
      queryClient.invalidateQueries(['pockets'])
    }
  });

  const deletePocket = async (pocketId: number,password:string) => {
    try {
      // Call the mutate function with the necessary data
      await mutation.mutateAsync({pocketId:pocketId,data:{password}});
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error deleting the pocket:', error);
      throw new Error('Error deleting the pocket');
    }
  };

  return {
    ...mutation,
    deletePocket,
  };
}

  

 export const useUpdatePocketRevolvingSettings = () => {
    const queryClient = useQueryClient();
  const mutation = useMutation((params:{id:number; data:Partial<RevolvingSettings>}) => pocketsApiClient.updateRevolving(params.id, params.data),{
    onSuccess:(_,variables)=>{
      queryClient.invalidateQueries(['pocket',variables.id])
    }
  }
  );

  const updateRevolvingSettings = async (params:{id:number; data:ExtendedRevolvingSettings}) => {
    try {
      // Call the mutate function with the necessary data
      await mutation.mutateAsync(params);
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error updating revolving settings:', error);
      throw new Error('Error updating revolving settings');
    }
  };

  return {
    ...mutation,
    updateRevolvingSettings,
  };
};


  export const usePocketRevolvingSchedule = (pocketId: number) => {
    return useQuery<UpcomingRevolvingPayout[],Error>(['pockets', pocketId, 'revolving-schedule'], () => pocketsApiClient.getRevolvingSchedule(pocketId),{
      placeholderData:[],
    });
  };

  export const usePocketRevolvingHistory = (pocketId: number) => {
    return useQuery<PastRevolvingPayout[],Error>(['pockets', pocketId, 'revolving-history'], () => pocketsApiClient.getPayoutHistory(pocketId),{
      placeholderData:[],
    });
  };

  export const useRevolvingPayoutTransactions = (pocketId: number) => {
    return useInfiniteQuery(
      ['revolving-transactions', 'pocket',pocketId],
      ({ pageParam = 0 }) => {
        const params = {
          skip: pageParam
        };
        return pocketsApiClient.getRevolvingTransactions(pocketId, params); 
      },
      {
        refetchInterval: 2 * 60 * 1000, // 2 minutes in milliseconds
        placeholderData: { pages: [], pageParams: [undefined, 1] },
        getNextPageParam: (lastPage) => {
          // Adjust this based on your API response structure
          const nextPage = lastPage?.nextPage; 
          return nextPage || undefined; // Return null or undefined if there are no more pages to fetch
        },
      }
    );
  };

  export const useAddPocketMembers = () => {
    const queryClient = useQueryClient();
    const mutation = useMutation((params:{id: number, members: number[]}) => pocketsApiClient.addPocketMembers(params.id, params.members),{
      onSuccess:(_,variables)=>{
       queryClient.invalidateQueries(['pocket-members',variables.id])
      }
    });

    
  const addMembers = async (params: {id: number, members: number[]}) => {
    try {
     
      const response =  await mutation.mutateAsync(params);
      return response.data
    } catch (error) {
     
      console.error('Error adding member:', error);
      throw new Error('error adding member')
     
    }
  };
  
    return {
      ...mutation,
      addMembers
    }
  };

  export const usePocketMembers = (pocketId: number) => {
    return useQuery<PocketMember[],Error>(['pocket-members', pocketId], () => pocketsApiClient.getPocketMembers(pocketId),{
      placeholderData:[],
    })
  };

  export const usePocketMember = (memberId: number) => {
    return useQuery<PocketMember,Error>(['pocket-members','pocket', memberId], () => pocketsApiClient.getPocketMember(memberId));
  };

  
  
  export const useUpdatePocketMember = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation((params:{pocketId:number,data:Partial<PocketMember>}) =>
      pocketsApiClient.promoteMember(params.pocketId, params.data),{
        onSuccess:(_,variables)=>{
          queryClient.invalidateQueries(['pocket-members','pocket',variables.pocketId])
        }
      }
    );
  
    // Function to handle updating the goal member
    const handleUpdateMember = async (pocketId: number, data: ExtendedPocketMember) => {
      try {
        // Call the mutate function with the necessary data
        await mutation.mutateAsync({pocketId, data});
        // Mutation successful, handle success or update state as needed
      } catch (error) {
        // Mutation failed, handle error or show error message
        throw new Error(`error updating pocket member`)
      }
    };
  
    return {
      ...mutation,
      handleUpdateMember,
    };
  };

  export const useRemovePocketMembers = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation((params: { pocketId: number; members: number[] }) =>
    pocketsApiClient.deletePocketMembers(params.pocketId, params.members),{
      onSuccess:(_,variables)=>{
         queryClient.invalidateQueries(['pocket-members',variables.pocketId])
      }
    }
  );

  const removePocketMembers = async (pocketId: number, members: number[]) => {
    try {
      await mutation.mutateAsync({ pocketId, members });
    } catch (error) {
      console.error('Error removing pocket members:', error);
      throw new Error('Error removing pocket members');
    }
  };

  return {
    ...mutation,
    removePocketMembers,
  };
};

  

export const useJoinPocket = () => {
  const mutation = useMutation((params: { pocketId: number }) => pocketsApiClient.joinPocket(params.pocketId));

  const joinPocket = async (params: { pocketId: number }) => {
    try {
      // Call the mutate function with the necessary data
      const response = await mutation.mutateAsync(params);
      return response.data;
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error joining pocket:', error);
      throw new Error('Error joining pocket'); 
    }
  };

  return {
   ...mutation,
    joinPocket,
  };
}

export const useLeavePocket = () => {
  const mutation = useMutation((params: { pocketId: number }) => pocketsApiClient.leavePocket(params.pocketId));

  const leavePocket = async (params: { pocketId: number }) => {
    try {
      // Call the mutate function with the necessary data
      const response = await mutation.mutateAsync(params);
      return response.data;
    } catch (error) {
      // Mutation failed, handle error or show error message
      console.error('Error leaving the goal:', error);
      throw new Error('Error leaving the goal');
    }
  };

  return {
    ...mutation,
    leavePocket,
  };
}

