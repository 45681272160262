import api from "../api";
import { CONTACTS_ENDPOINT } from "../apiEndpoints";

const contactsApiClient = {
  // Retrieve a list of all contacts
  getAll: async () => {
    const response = await api.get(`${CONTACTS_ENDPOINT}`);
    return response.data.contacts;
  },
  create: async (data:{
    name:string,
    phonenumber:string
    username?:string,
  }) => {
    try {
      const response = await api.post(
      `${CONTACTS_ENDPOINT}`,data
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  getById: async (contactId:number) => {
    const response = await api.get(
      `${CONTACTS_ENDPOINT}/${contactId}`
    );
    return response.data;
  },
  updateById: async (id:number,data:{name:string,phonenumber:string}) => {
    try {
       const response = await api.put(`${CONTACTS_ENDPOINT}/${id}`,data);
       return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
   
  },
  deleteById: async (id:number) => {
    try {
      await api.delete(`${CONTACTS_ENDPOINT}/${id}`);
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
};

export default contactsApiClient;
