import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,Theme, Chip
} from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useStyles } from "./ProfileStyles";
import InfoIcon from '@mui/icons-material/Info';
import InfoDialog from '../../components/Reusables/Feedback/InfoDialog';

const TransactionLimits = () => {
  const history = useHistory();
  const [showInfo,setShowInfo]= useState(false)
   const isDesktop = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));



  return (
    <Box m={2}>
      <Box sx={{ display: 'flex', alignItems: 'center',justifyContent:'space-between', gap: 1, mt: 2 }}>
        {!isDesktop &&(
        <IconButton onClick={() => history.goBack()} sx={{ p: 0 }}>
          <ArrowBackOutlinedIcon color="inherit" fontSize="small" />
        </IconButton>
        )}
     
        <Typography variant="h5" fontWeight={600}>
          Transaction limits
        </Typography>
        {isDesktop ? <Chip label='Withdraw fees ≈ 2%' icon={<InfoIcon />} variant='outlined' color='primary'/> :
         <IconButton onClick={()=>setShowInfo(!showInfo)}>
      <InfoIcon  color='primary'/>
      </IconButton>}
      </Box>
       <InfoDialog
            title="Transaction fees"
            content="Withdraw fees ≈ 2%"
            open={showInfo}
            handleClose={()=>setShowInfo(false)}
            width="auto"
            padding="0 0 24px 0"
          />

      <List>
        <ListItem sx={{ px: 0 }}>
          <ListItemText
            primary="Send"
            secondary={
              <>
                <Typography>
                  _ UGX / _ UGX (Daily)
                  <br />
                  _ UGX / _ UGX (Weekly)
                  <br />
                  _ UGX / _ UGX (Monthly)
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem sx={{ px: 0 }}>
          <ListItemText
            primary="Receive"
            secondary={
              <>
                <Typography>
                  _ UGX / _ UGX (Daily)
                  <br />
                  _ UGX / _ UGX (Weekly)
                  <br />
                  _ UGX / _ UGX (Monthly)
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem sx={{ px: 0 }}>
          <ListItemText
            primary="Withdraw"
            secondary={
              <>
                <Typography>
                  _ UGX / _ UGX (Daily)
                  <br />
                  _ UGX / _ UGX (Weekly)
                  <br />
                  _ UGX / _ UGX (Monthly)
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default TransactionLimits;
