import React, { useState } from 'react';
import {
  Box,
  Typography,
  DialogContent,
  DialogTitle,
  IconButton,
  Alert,
} from '@mui/material';
import { Field, Form } from 'react-final-form';
import RadioButtons from '../Reusables/Input/RadioGroup';
import { paymentMethodOptions } from '../Reusables/Input/select/Options';
import { useBanks, useCreatePaymentMethod, useVerifyCard } from '../../hooks/paymentMethodHooks';
import Select from '../Reusables/Input/select/Select';
import FormInput from '../Reusables/Input/FormInput';
import Button from '../Reusables/Input/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import ModalWrapper from '../Modals/ModalWrapper';

interface CreatePaymentMethodStepperProps {
  open: boolean;
  onClose: () => void;
}

interface PaymentMethodFormValues {
  method_type: 'bank' | 'card';
  accountNumber?: string;
  cardNumber?: string;
  bank?: number
}




const CreatePaymentMethod: React.FC<CreatePaymentMethodStepperProps> = ({ open, onClose }) => {
  const { data: banks, } = useBanks();
  const { createPaymentMethod, error: creationError } = useCreatePaymentMethod();
  const { verifyCard, error: verificationError } = useVerifyCard();

  const history = useHistory()

  const onSubmit = async (values: PaymentMethodFormValues) => {
    if (values.cardNumber) {
      let cardDetails = {
        currency: "UGX",
        amount: 100,
        url: history.location.pathname,
      };
      try {
        await verifyCard(cardDetails);
        onClose()

      } catch (error) {
        console.error('Card verification failed:', error);
      }
    } else {
      try {
        let bank = (banks ?? []).filter((bank: any) => bank.code === values.bank)[0]
        await createPaymentMethod({
          bank_acc: bank, acc_no: values.accountNumber, method_type: "bank"
        });
        onClose()
      } catch (error) {
        console.error('Payment method creation failed:', error);
      }
    }
  }




  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography fontWeight={600} sx={{ m: '0 auto' }}>{`Add accounts & cards`}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 1.5 }}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              <Box>
                <Typography fontWeight={600}>Select payment provider</Typography>
                <Field component={RadioButtons} options={paymentMethodOptions} name="method_type" />
              </Box>
              <Box>
                <Typography fontWeight={600}>Select Bank</Typography>
                <Field name="bank" flutterwaveBanks={banks ?? []} component={Select}
                  type="select" />
              </Box>
              <Field label="Account number" name="accountNumber" component={FormInput}
                type="number" />

              <Field label="Card number" name="cardNumber" component={FormInput}
                type="number" />
              <Field
                name="cardNumber"
                subscription={{ dirty: true }}
                render={({ meta: { dirty } }) =>
                  dirty && values.cardNumber ? (
                    <Alert severity="success"
                      sx={{ padding: '0px' }}>
                      A charge of UGX 100 will be made to your card to verify its  validity.
                    </Alert>
                  ) : null
                }
              />
              {verificationError && (<Alert severity='error'>{(verificationError as Error).message}</Alert>)}
              {creationError && (<Alert severity='error'>{(creationError as Error).message}</Alert>)}

              <Box mt={2} sx={{ display: 'flex', alignItems: 'center', gap: 0.5, alignSelf: 'center' }}>
                <Button onClick={onClose} sx={{ mr: 2 }} title="Cancel" />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  title={'Save'}
                />
              </Box>
            </form>
          )}
        />

      </DialogContent>
    </ModalWrapper>
  );
};

export default CreatePaymentMethod;
