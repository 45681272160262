import { Dialog, IconButton , DialogProps,  DialogContent, Typography, Box} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { styled } from '@mui/material/styles';


interface ModalProps {
  title?: string;
  open: boolean;
  handleClose: () => void;
  icon?:boolean
};


type Props = ModalProps  & DialogProps;

const Transition = React.forwardRef(function Transition(
  props: any,
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

 const CustomModal = styled(Dialog)(({ theme }) => ({
'& .MuiDialog-container .MuiDialog-paperScrollPaper' :{
 minWidth:250,
 [theme.breakpoints.up('lg')]:{
  minWidth:560,
  minHeight:150,
 }
}
}));

function Modal({
  children,
  title,
  handleClose,
  open,
  icon,
  ...rest
  
}:Props) {

  
  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      aria-labelledby={title}
      scroll={"paper"}
      TransitionComponent={Transition}
      {...rest}
     
    >
      <Box sx={{ display:'flex',alignItems:'center',justifyContent:'space-between',px:2,textAlign:'center',mt:2}}>
        <Typography variant='body2' fontWeight={600} sx={{m:'auto'}}>{title}</Typography>{
          icon && (
         <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
          )
        }
        
      </Box>
     <DialogContent sx={{padding:'unset',px:1 ,mt:0.5}}>
      {children}
     </DialogContent>
    </CustomModal>
  );
}



export default Modal;
