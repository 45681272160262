import { Route, Switch } from "react-router-dom";
/**profile screens */
import Notifications from "../pages/profile/Notifications";
import Help from "../pages/profile/Help";
import authGuard from "../utils/auth";
import Profile from "../pages/profile/Profile";
import Contact from "../pages/profile/Contact";
import NextOfKinList from "../pages/profile/NextOfKinList";
import ContactUs from "../pages/profile/ContactUs";
import UserSettings from "../pages/profile/UserSettings";
import ExchangeRates from "../pages/profile/ExchangeRates";
import TransactionLimits from "../pages/profile/TransactionLimits";
import BalancesVisibility from "../pages/profile/BalancesVisibility";
import PaymentMethods from "../pages/profile/PaymentMethods";
import NotificationSettings from "../components/profile/NotificationSettings";
import Security from "../pages/profile/Security";
import { SecurityQuestionsStepper } from "../pages/profile/DeleteAccount";
import SecurityQuestions from "../pages/profile/SecurityQuestions";
import PINForm from "../components/profile/PINForm";
import PasswordForm from "../components/profile/PasswordForm";
import SocialMedia from "../pages/profile/SocialMedia";
import  {KYC, GovernmentalKYC, InstitutionalKyc, ProofOfAddress, Witnesses, FinancialRecords } from "../pages/profile/KYC";

const ProfileRoutes = () => {
  return (
    <Switch>
      <Route  exact path="/profile/help" component={authGuard(Help)} />
      <Route exact path="/profile/contact" component={authGuard(Contact)} />
      <Route exact path="/profile/contact-us" component={authGuard(ContactUs)} />
      <Route exact path="/profile/settings" component={authGuard(UserSettings)} />
      <Route exact path="/profile/settings/exchange-rates" component={authGuard(ExchangeRates)} />
      <Route exact path="/profile/settings/transaction-limits" component={authGuard(TransactionLimits)} />
      <Route exact path="/profile/settings/balances" component={authGuard(BalancesVisibility)} />
      <Route exact path="/profile/contact/kins" component={authGuard(NextOfKinList)} />
      <Route  exact path="/profile/settings/payment-methods" component={authGuard(PaymentMethods)} />
      <Route  exact path="/profile/settings/notification" component={authGuard(NotificationSettings)} />
      <Route  exact path="/profile/settings/security-privacy" component={authGuard(Security)} />
      <Route  exact path="/profile/settings/security-questions" component={authGuard(SecurityQuestions)} />
      <Route  exact path="/profile/settings/edit-pin" component={authGuard(PINForm)} />
      <Route  exact path="/profile/settings/edit-password" component={authGuard(PasswordForm)} />
      <Route  exact path="/profile/social-media" component={authGuard(SocialMedia)} />
      <Route  exact path="/profile/kyc" component={authGuard(KYC)} />
      <Route  exact path="/profile/kyc/governmental" component={authGuard(GovernmentalKYC)} />
      <Route  exact path="/profile/kyc/institutional" component={authGuard(InstitutionalKyc)} />
       <Route  exact path="/profile/kyc/proof-of-address" component={authGuard(ProofOfAddress)} />
      <Route  exact path="/profile/kyc/witnesses" component={authGuard(Witnesses)} />
       <Route  exact path="/profile/kyc/financial-records" component={authGuard(FinancialRecords)} />
      <Route  exact path="/profile/security-questions" component={authGuard(SecurityQuestionsStepper)} />
      <Route  exact path="/profile/notifications" component={authGuard(Notifications)} />
      <Route exact path='/profile' component={authGuard(Profile)}/>
    </Switch>
  );
};

export default ProfileRoutes;
