import React from 'react'
import AppBar from '@mui/material/AppBar';
import { GoalHeaderDetailed } from './GoalHeader';
import GoalTabs from './GoalTabs';
import { Box } from '@mui/material';
import ContentBox from '../../components/layout/ContentBox';

const GoalPage = () => {
  return (
    <Box sx={{ m: '0 auto' }}>
      <AppBar
        sx={{ background: '#fff', color: '#000', boxShadow: 'none', position: 'relative' }}>
        <GoalHeaderDetailed />
      </AppBar>
      <ContentBox contentBoxStyles={{
        mt: "1.25rem", px: { xs: "16px", sm: "16px", md: 0, lg: 0 }
      }}>
        <GoalTabs />
      </ContentBox>
    </Box>
  )
}

export default GoalPage