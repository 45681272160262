import { Alert, Autocomplete, Avatar, Box, CircularProgress, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Contact } from '../../types/types';
import { Field, Form } from 'react-final-form';
import CloseIcon from '@mui/icons-material/Close';
import FormInput from '../Reusables/Input/FormInput';
import Button from '../Reusables/Input/Button';
import { useSearchUsers } from '../../hooks/userHooks';
import { useCreateContact } from '../../hooks/contactHooks';
import { telephoneCheck } from '../../utils/middleware';
import ModalWrapper from '../Modals/ModalWrapper';

interface ContactListProps {
  contacts: Contact[];
  handleContactSelect: (contact: Contact) => void;
}


export const ContactList: React.FC<ContactListProps> = ({ contacts, handleContactSelect }) => {
  const [openContact, setOpenContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [username, setUsername] = useState('')
  const { data, isLoading } = useSearchUsers({ searchterm: username })
  const { createContact, isLoading: creatingContact, error } = useCreateContact();


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setUsername(inputValue);
  };

  const handleModalClose = (): void => {
    setOpenContact(false);
    setSelectedContact(null);
  };

  const handleContactClick = (contact: Contact): void => {
    setSelectedContact(contact);
    handleContactSelect(contact);

  };

  const validate = (values: { name: string; phonenumber: string; username: string; }) => {
    const errors: { name?: string; phonenumber?: string; username?: string; } = {};


    if (!values.phonenumber) {
      errors.phonenumber = "Required";
    }
    if (!telephoneCheck(values.phonenumber)) {
      errors.phonenumber = "Please enter a valid phonenumber";
    }
    if (!values.name) {
      errors.name = "Required";
    }
    if (!username) {
      errors.username = "Required";
    }

    return errors;
  };

  const handleSubmit = async (values: { name: string; phonenumber: string; username: string }) => {
    try {
      await createContact({
        name: values.name,
        phonenumber: values.phonenumber,
        username: username,
      });
      handleModalClose()
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.log('error creating contact')
      // Handle error (e.g., show an error message)
    }
  };



  return (
    <>
      <List dense>
        <ListItemButton onClick={() => setOpenContact(!openContact)}>
          <ListItemIcon>
            <AddCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Add Contact"
            primaryTypographyProps={{ fontSize: '1rem', fontWeight: 600 }} />
        </ListItemButton>
        {contacts.map((contact) => (
          <ListItemButton
            key={contact.id}
            onClick={() => handleContactClick(contact)}
            selected={selectedContact?.id === contact.id ?? false}
          >
            <ListItemIcon>
              <Avatar
                alt={'profile image'}
                src={contact?.users?.profile_img ?? undefined}
                sx={{ bgcolor: "primary.main", width: 30, height: 30 }}
              >{contact.name.charAt(0)}</Avatar>
            </ListItemIcon>
            <ListItemText primary={`${contact.name} (${contact.username})`} secondary={contact.phone_number} />
          </ListItemButton>
        ))}
      </List>
      <ModalWrapper open={openContact} onClose={handleModalClose}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600, m: '0 auto' }}>Add Recipient</Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 1 }}>
          <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Field name='name' component={FormInput} label='Full Name' />
                  <Field name="username">
                    {(props) => (
                      <Autocomplete
                        id="username-autocomplete"
                        sx={{ width: 300 }}
                        isOptionEqualToValue={(option, value) => option.username === value.username}
                        getOptionLabel={(option) => option.username}
                        options={data ?? []}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Avatar
                              alt={option.username}
                              src={option.profile_img}
                              sx={{ bgcolor: 'primary.main', width: 30, height: 30, marginRight: 1 }}
                            >
                              {option.username.charAt(1)}
                            </Avatar>
                            <Typography>{option.username}</Typography>
                          </li>
                        )}
                        renderInput={(params) => (
                          <FormInput
                            {...props}
                            {...params}
                            onChange={handleInputChange}
                            placeholder='Search by username'
                            label="Username"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>
                  <Field name='phonenumber' component={FormInput} label='Phone number' />
                </Box>
                {error && <Alert severity='error' sx={{ my: 1 }}>{(error as Error).message}</Alert>}
                <Button title='Add' type='submit' variant='contained'
                  loading={creatingContact}
                  disabled={creatingContact}
                  sx={{ my: 2, width: '80%', mx: 'auto' }} />
              </form>
            )}
          />
        </DialogContent>
      </ModalWrapper>
    </>

  )
}
