import { Route, Switch } from "react-router-dom";
/**User authentication */
import EmailLogin from "../pages/auth/EmailLogin";
import MobileLogin from "../pages/auth/MobileLogin";
import MobileRegister from "../pages/auth/MobileRegister";
import EmailRegister from "../pages/auth/EmailRegister";
import ResetPassword from "../pages/auth/ResetPassword";
import ResetPIN from "../pages/auth/ResetPIN";
import RegisterStepper from "../pages/auth/signup/RegisterStepper";
/**--end of user authentication */
const AuthRoutes = () => {
  return (
    <Switch>
      <Route exact path="/auth/login/email" component={EmailLogin} />
      <Route exact path="/auth/login/mobile" component={MobileLogin} />
      <Route exact path="/auth/register/email" component={EmailRegister} />
      <Route exact path="/auth/register/mobile" component={MobileRegister} />
      <Route exact path="/auth/signup/register-stepper" component={RegisterStepper} />
      <Route
        exact
        path="/auth/reset/password/:type"
        component={ResetPassword}
      />
      <Route
        exact
        path="/auth/reset/pin/:type"
        component={ResetPIN}
      />
    </Switch>
  );
};

export default AuthRoutes;
