import  { useState } from "react";
import Modal from "./Modal";
import Button from "../Reusables/Input/Button";
import { useHistory, useParams } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { Box, IconButton, Typography } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React from "react";
import { useDeletePocket } from "../../hooks/pocketHooks";
import FormInput from "../Reusables/Input/FormInput";

interface Props {
  open: boolean;
  handleClose: () => void;
  
}

function DeletePocket({ open, handleClose }:Props) {
  
  let history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [inputValue, setInputValue] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [deleteToast, setDeleteToast] = useState(false);



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const { isLoading, error, deletePocket ,isError} = useDeletePocket();

    const handleDelete = async () => {
    try {
      // Call the deleteGoal function to delete the goal
       if(inputValue){
         await deletePocket(parseFloat(id),inputValue);
         setDeleteToast(true)
        history.push('/wallet')
      }
    } catch (error) {
      console.error('Error deleting the pocket:', error);
      // Handle the error or display an error message to the user.
    }
  };

  
  
  return (
    <div>
      <Modal title="Delete Pocket" open={open} handleClose={handleClose}>
        <form>
          <Typography textAlign={'center'}>Please enter account password to continue</Typography>
          <FormInput
            value={inputValue}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:(
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )
            }}
          />
          {error && <Alert severity="error">{(error as Error).message}</Alert>}
          <Box sx={{display:'flex',alignItems:'center',gap:0.5,justifyContent:'center',mb:1}}>
            <Button
              title="Delete"
              variant="contained"
              color="error"
              onClick={handleDelete}
              disabled={isLoading}
              loading={isLoading}
               sx={{width:'120px'}}
            />
            <Button title="Cancel"  onClick={handleClose}  sx={{width:'120px',color:'inherit'}}/>
          </Box>
        </form>
      </Modal>
      
    </div>
  );
}

export default DeletePocket;
