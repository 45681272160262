import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Settings } from "@mui/icons-material";
import NotificationList from "../profile/NotificationList";
import Button from "../Reusables/Input/Button";
import { useHistory } from "react-router-dom";
import { FC } from "react";

interface NotificationsModalProps {
    open: boolean;
    onClose: () => void;
    notifications: any;
    handleUpdateNotifications: () => void;
    notificationsPageRoute?: string;
}

const NotificationsModal: FC<NotificationsModalProps> = ({
    open,
    onClose,
    notifications,
    handleUpdateNotifications,
    notificationsPageRoute='/profile/notifications'
}) => {
    const history = useHistory();
    const theme = useTheme();
    const matches = useMediaQuery(`@media (min-width: ${theme.breakpoints.values.sm}px)`);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: '0.75rem',
                    backgroundColor: '#fff',
                    color: '#000',
                    position: 'absolute',
                    top: '55px',
                    right: "12px",
                    zIndex: 1000,
                    maxHeight: "35.9375rem"
                },
            }}
            fullScreen={!matches}
            sx={{
                '& .MuiDialog-paper': {
                    margin: matches ? '0' : '48px',
                },
            }}
            hideBackdrop
        >
            <DialogTitle variant="body1" fontWeight={700}>Notifications</DialogTitle>
            <Box position="absolute" right="12px" top="18px" display="flex" alignItems={"center"} gap="0.97rem">
                {notifications.data?.length > 0 &&
                    <>
                        <Box sx={{ cursor: 'pointer' }} onClick={handleUpdateNotifications}>
                            <Typography variant="button">Mark as read</Typography>
                        </Box>
                        <Box sx={{ cursor: 'pointer' }} onClick={handleUpdateNotifications}>
                            <Typography variant="button" color="primary">Clear All</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap="0.44rem" sx={{ cursor: "pointer" }} onClick={() => history.push("/profile/settings")}>
                            <Settings />
                            <Typography variant="button">Settings</Typography>
                        </Box>
                    </>}
            </Box>
            <DialogContent>
                <NotificationList notifications={notifications} />
            </DialogContent>
            <DialogActions>
                <Button title="View more" onClick={() => history.push(notificationsPageRoute)} />
                <Button title="Close" onClick={onClose} />
            </DialogActions>
        </Dialog>
    )
}

export default NotificationsModal;