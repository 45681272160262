import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { durationFilters, transactionTypes } from '../../components/Reusables/Input/select/Options';
import { useState } from 'react';
import { Chip, IconButton, InputAdornment, Stack, useMediaQuery, Theme, CircularProgress } from '@mui/material';
import { getStartDateAndEndDate, numberWithCommas } from '../../utils/middleware';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';
import TransactionList from '../../components/home/TransactionList';
import Button from '../../components/Reusables/Input/Button';
import PayoutTransactions from './PayoutTransactions';
import { Search, StyledInputBase } from '../goals/SearchAppBar';
import CloseIcon from '@mui/icons-material/Close';
import Filter from '../../components/transactions/Filter';
import { Transaction, TransactionData, } from '../../types/types';
import { StyledTabs, StyledTab } from '../goals/GoalTabs';
import { useTransactionSelection } from '../../hooks/customHooks';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import SearchIcon from '@mui/icons-material/Search';
import FormInput from '../../components/Reusables/Input/FormInput';
import Fuse from 'fuse.js'
import { useStyles } from "./TransactionStyles"
import { SubFilters } from '../wallet/WalletTransactions';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}


export interface StyledTabProps {
    label: string;
}

const CustomChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-root': {
        fontWeight: 600,
        background: "transparent",
        '&:hover': {
            background: '#F0EBF8'
        },
        '&.MuiChip-filledPrimary': {
            color: theme.palette.primary.main,
            fontSize: "1.125rem",
            backgroundColor: '#F0EBF8',
        }
    }
}));

interface TransactionWrapperProps {
    transactions: any;
    onUpdateValues: (values: any) => void;
    member?: any;
    initialValues: any;
    members?: any[]
    revolvingPayouts?: UseInfiniteQueryResult<any, unknown>,
    currency?: string,
    transactionPageTitle?: string,
    showDurationFilters?: boolean,
    customTabPanels?: JSX.Element[],
    transactionTypes: { label: string, value: string }[],
    subTransactionTypes?: SubFilters,
    isLoading?: boolean,
    fullWidth?: boolean
}

const TransactionsWrapper: React.FC<TransactionWrapperProps> = ({
    transactions,
    member,
    onUpdateValues,
    initialValues,
    members,
    revolvingPayouts,
    currency = 'UGX',
    transactionPageTitle = "Transactions",
    showDurationFilters = true,
    transactionTypes,
    subTransactionTypes,
    customTabPanels = [],
    isLoading = false,
    fullWidth = false
}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [showSearch, setShowSearch] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [duration, setDuration] = useState('all');
    const [subTransactionType, setSubTransactionType] = useState("all");
    const [searchWord, setSearchWord] = useState("");
    const [selectMode, setSelectMode] = useState(false)
    const [searchResults, setSearchResults] = useState<UseInfiniteQueryResult<TransactionData, Error>>();
    const history = useHistory()
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const { selectedTransactions, handleTransactionSelect, selectTransactions } = useTransactionSelection();

    const totalDeposits = transactions?.data?.pages?.[0]?.totalDeposits || 0;
    const totalWithdrawals = transactions?.data?.pages[0]?.totalWithdrawals || 0;


    const handleDownload = () => {
        if (transactions.data && transactions.data.pages[0].length > 0) {
            const firstTransactions = transactions.data.pages[0]
            selectTransactions(firstTransactions.slice(0, 5))
        }
        setSelectMode(true)
    }

    const handleSelectAll = () => {
        if (transactions.data) {
            selectTransactions(transactions.data?.pages.flatMap((page: { transactions: any; }) => page.transactions))
            history.push(`/transactions/preview`)
        }
    }

    const handlePreview = () => {
        if (selectedTransactions.length > 0) {
            history.push(`/transactions/preview`)
        }
    }

    const transactionsData: readonly any[] = transactions.data ? transactions.data?.pages.flatMap((page: { transactions: any; }) => page.transactions) : [];

    const fuse = React.useMemo(
        () =>
            new Fuse(transactionsData, {
                keys: ['name', 'from', 'to', 'payment_method_name', 'currency', 'type'],
            }),
        [transactionsData]
    );

    const updateSearchResults = (): void => {
        if (searchWord) {
            const searchResults = fuse.search(searchWord);
            const pages = [{ transactions: searchResults.map((result: any) => result.item as Transaction) }] as unknown as TransactionData;

            const updatedSearchResults = {
                data: { pages },
                error: null, // Set this based on your error handling logic
                hasNextPage: false, // Set this based on whether there are more pages
            };

            setSearchResults(updatedSearchResults as unknown as UseInfiniteQueryResult<TransactionData, Error>);
        }
    };


    const handleDelete = () => {
        history.goBack()

    };

    const handleSearchIconClick = () => {
        setShowSearch((prevShowSearch) => !prevShowSearch);
    };

    const handleDuration = (duration: string) => {
        setDuration(duration)
        const { startDate, endDate } = getStartDateAndEndDate(duration);
        onUpdateValues({ ...initialValues, startDate, endDate })

    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        onUpdateValues({ ...initialValues, type: transactionTypes[newValue].value })
    };

    const handleSubTransactionTypeChange = (subTransactionType: string) => {
        setSubTransactionType(subTransactionType)
        onUpdateValues({ ...initialValues, status: subTransactionType })
    }

    return (
        <Box sx={{ width: '100%', px: { sm: fullWidth ? "16px" : 0, lg: 2 }, maxWidth: fullWidth ? "none" : '560px', m: '0 auto' }}>
            {selectMode === true ? (
                <Box sx={{ my: { lg: 2 } }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Chip icon={<CloseIcon fontSize="small" />} label={selectedTransactions.length} sx={{ backgroundColor: '#C2B0E2', fontSize: 16 }} onClick={() => setSelectMode(false)} />
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography fontWeight={600} onClick={handlePreview}>Download</Typography>
                            <Typography fontWeight={600} color='primary' onClick={handleSelectAll}>Select all</Typography>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: "1.25rem", flexDirection: { xs: 'row', lg: fullWidth ? 'row' : 'column' } }}>
                        <IconButton onClick={() => history.goBack()} sx={{ p: 0, display: { lg: fullWidth ? "block" : 'none' }, marginRight: "8px", color: "#000" }}>
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{
                            flexGrow: 1,
                            fontWeight: 600,
                            display: { sm: 'block' },
                            justifyContent: { xs: 'center', sm: 'initial' },
                            textAlign: { xs: 'center', sm: "initial", lg: 'center' }
                        }}>{transactionPageTitle}</Typography>
                        {!showSearch && !isDesktop ? (
                            <IconButton onClick={handleSearchIconClick} sx={{ p: 0 }}>
                                <SearchOutlinedIcon />
                            </IconButton>
                        ) : !isDesktop ? (
                            <Search>
                                <IconButton onClick={handleSearchIconClick} sx={{ p: 0 }}>
                                    <SearchOutlinedIcon />
                                </IconButton>
                                <StyledInputBase
                                    placeholder="Search…"
                                    value={searchWord}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <CloseIcon onClick={() => setSearchWord('')} />
                                        </InputAdornment>
                                    }
                                    onChange={(e) => {
                                        setSearchWord(e.target.value);
                                        updateSearchResults();
                                    }}
                                />
                            </Search>
                        ) : null}
                    </Box>
                    <Stack direction="row" spacing={1} sx={{ mt: 2, justifyContent: 'flex-end', mb: 1 }}>
                        <Box display="flex" justifyContent={isDesktop ? "space-between" : "flex-end"} alignItems={"center"} width="100%">{isDesktop && <FormInput className={classes.transactionSearchbar} sx={{ backgroundColor: '#F6F6F7', borderRadius: "1rem", width: '14.875rem', outline: "0", border: "none" }} Icon={<SearchIcon />} placeholder='Search transactions' value={searchWord}
                            onChange={(e) => {
                                setSearchWord(e.target.value);
                                updateSearchResults();
                            }}
                        />}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, float: 'right' }}>
                                <Chip label={<Typography variant="button">Filter</Typography>} size="medium" onClick={() => setShowFilter(true)} icon={<FilterAltIcon />} sx={{ backgroundColor: '#F0EBF8' }} />
                                <Chip label={<Typography variant="button">Download</Typography>} size="medium" onClick={handleDownload} icon={<FileDownloadIcon />} sx={{ backgroundColor: '#F0EBF8' }} />
                            </Box>
                        </Box>
                    </Stack>
                    {member?.name && (<Chip
                        label={`${member.name}`}
                        onDelete={handleDelete}
                        sx={{ backgroundColor: '#C2B0E2' }}
                        size='small'
                    />
                    )}
                </>
            )}
            {/* Tab headers */}
            <Box sx={{ borderBottom: 1, borderColor: 'transparent', display: "flex", flexDirection: "column", gap: "0.81rem", marginBottom: "0.75rem" }}>
                {transactionTypes && <StyledTabs value={value} onChange={handleChange} aria-label="goal transactions tabs">
                    {transactionTypes.map((filter, index) => (
                        <StyledTab label={filter.label} {...a11yProps(index)} key={index} />
                    ))}
                </StyledTabs>}

                {transactionTypes && subTransactionTypes && (
                    <Box sx={{ display: 'flex', overflowX: 'auto', overflowY: 'hidden' }}>
                        {subTransactionTypes[transactionTypes[value].value].map((subTxType, index) => (
                            <CustomChip
                                variant="filled"
                                color={subTxType.value === subTransactionType ? 'primary' : 'default'}
                                key={index}
                                label={subTxType.label}
                                size="small"
                                onClick={() => handleSubTransactionTypeChange(subTxType.value)}
                                sx={{
                                    '& .MuiChip-label': {
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        letterSpacing: "0.0125rem",
                                    },
                                    marginRight: '8px'
                                }}
                            />
                        ))}
                    </Box>
                )}

                {showDurationFilters && <Stack direction="row" spacing={1} justifyContent={"space-between"}>
                    {durationFilters.map((filter, index) => (
                        <CustomChip
                            variant="filled"
                            color={filter.value === duration ? 'primary' : 'default'}
                            key={index}
                            label={filter.label}
                            size="small"
                            onClick={() => handleDuration(filter.value)}
                            sx={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                letterSpacing: "0.0125rem"
                            }}
                        />
                    ))}
                </Stack>}
            </Box>
            {/* Tab panels */}
            {customTabPanels.length > 0 && customTabPanels.map((panel, index) => (
                <CustomTabPanel value={value} index={index} key={index}>
                    {panel}
                </CustomTabPanel>
            ))}
            {revolvingPayouts && <CustomTabPanel value={value} index={customTabPanels && customTabPanels.length + 1}>
                <PayoutTransactions revolvingPayouts={revolvingPayouts} />
            </CustomTabPanel>}
            {/**these tabs should be for all tabs except revolving payouts */}
            <Typography variant="body1" fontWeight={600} marginBottom={"0.62rem"} marginTop="1.56rem">Transactions</Typography>
            {
                isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    value !== (customTabPanels.length + 1) && (
                        <Box>
                            <TransactionList
                                transactions={searchResults && searchWord.length > 0 ? searchResults : transactions}
                                selectMode={selectMode}
                                selectedTransactions={selectedTransactions}
                                onSelect={handleTransactionSelect} />
                            {transactions.hasNextPage && !searchResults && searchWord.length === 0 && (
                                <Button
                                    onClick={() => transactions.fetchNextPage()}
                                    title={transactions.isFetchingNextPage ? 'Loading more...' : 'Load more'}
                                    disabled={transactions.isFetchingNextPage}
                                />)}
                        </Box>
                    )
                )
            }
            <Filter open={showFilter} handleClose={() => setShowFilter(false)} initialValues={initialValues} onUpdateValues={onUpdateValues} members={members} />
        </Box>
    )
}

export default TransactionsWrapper;