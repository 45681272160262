import React, { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';

interface ContentBoxProps {
    children: ReactNode;
    contentBoxStyles?: {};
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '717px',
        margin: '0 auto',
        width:"100%",
        padding: '8px 16px',
    },
}));

const ContentBox = ({ children, contentBoxStyles }: ContentBoxProps) => {
    const classes = useStyles();

    return (
        <div className={classes.root} style={contentBoxStyles}>
            {children}
        </div>
    );
};

export default ContentBox;

