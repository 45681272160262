import { useStyles } from "./ProfileStyles";
import { Box, CircularProgress, List, Typography } from "@mui/material";
import FlipMove from "react-flip-move";
import NotificationItem from "./NotificationItem";
import { Notification as NotificationType } from "../../types/types";
import { UseQueryResult } from "@tanstack/react-query";

function Notifications({ notifications }: { notifications: UseQueryResult<any, unknown> }) {
  const classes = useStyles();

  const { data, isLoading, isError } = notifications

  return (
    <div className={classes.notificationsContainer}>
      {isLoading ?
        (<Box display="flex" justifyContent="center" alignItems="center" height="70vh"><CircularProgress /></Box>) :
        data?.length > 0 ? (
          <FlipMove>
            <List>
              {data.map((notification: NotificationType, index: number) => (
                <NotificationItem key={index} notification={notification} />
              ))}
            </List>

          </FlipMove>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh"><Typography variant="body1" color="grey.400" textAlign="center">No transactions yet</Typography></Box>
        )}
    </div>
  );
}

export default Notifications;
