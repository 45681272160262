import React, { SelectHTMLAttributes } from "react";
import FormControl from "@mui/material/FormControl";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useStyles } from "../../Styles";
import FormHelperText from "@mui/material/FormHelperText";
import { PaymentMethod } from "../../../../types/types";

interface Option {
  value: string;
  label: string;
}

interface SelectInputProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options?: Option[];
  cards?: PaymentMethod[];
  phoneNumbers?: PaymentMethod[];
  banks?: PaymentMethod[] ;
  flutterwaveBanks?:any[]
  input: any;
  meta: {
    error?: string;
    submitError?: string;
    touched?: boolean;
  };
  variant?: "standard" | "outlined" | "filled";
}

const SelectInput = ({
  options,
  cards,
  phoneNumbers,
  banks,
  flutterwaveBanks,
  input,
  meta,
  variant="outlined",
  ...rest
}: SelectInputProps) => {
  

  const classes = useStyles();
  return (
    <div>
      <FormControl
        variant={variant}
        className={classes.formControl}
        error={!!(meta?.error || meta?.submitError) && meta?.touched}
      >
        <Select {...input} {...rest}>
          {options
            ? options.map((option: any) => <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>)
            : cards
            ? cards.map((option: PaymentMethod, index: any) => (
                <MenuItem value={option as unknown as string} key={index}>
                  {`${option.card.type}   **** **** **** ${option.card.last_4digits}`}
                </MenuItem>
              ))
            :banks
            ? banks.map((option: PaymentMethod) => <MenuItem value={option as unknown as string} key={option.id}>
             {`${option.bank_acc.name} -- ${option.acc_no}`}
          </MenuItem>)
          :flutterwaveBanks
          ? flutterwaveBanks.map((option: any) => <MenuItem value={option.code} key={option.code}>
           {`${option?.name} `}
        </MenuItem>)
            : phoneNumbers
            ? phoneNumbers.map((phone: PaymentMethod,i) => <MenuItem value={phone.phone_number} key={i}>
            {`${phone.phone_number} `}
          </MenuItem>)
            : null}
        </Select>
        <FormHelperText>
          {meta?.touched ? meta?.error || meta?.submitError : ""}
        </FormHelperText>
      </FormControl>
    </div>
  );
};
export default SelectInput;
