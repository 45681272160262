import  { useState } from "react";
import Modal from "./Modal";
import Button from "../Reusables/Input/Button";
import Toast from "../Reusables/Feedback/Toast";
import { useHistory, useParams } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { useDeleteGoal } from "../../hooks/goalHooks";
import { Box, DialogActions, IconButton, Typography } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React from "react";
import FormInput from "../Reusables/Input/FormInput";

interface Props {
  open: boolean;
  handleClose: () => void;
  
}

function DeleteGoal({ open, handleClose }:Props) {
  
  let history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [inputValue, setInputValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [deleteToast, setDeleteToast] = useState(false);



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const { isLoading, error, deleteGoal ,isError} = useDeleteGoal();

    const handleDelete = async () => {
    try {
      // Call the deleteGoal function to delete the goal
      if(inputValue){
         await deleteGoal(parseFloat(id),inputValue);
         setDeleteToast(true)
        history.push('/goals')
      }
     
    } catch (error) {
      console.error('Error deleting the goal:', error);
      // Handle the error or display an error message to the user.
    }
  };

  
  return (
   
      <Modal title="Delete Goal" open={open} handleClose={handleClose} sx={{padding:{lg:'28px 55px'}}}>
        <form >
          <Typography textAlign={'center'}>Please enter account password to continue</Typography>
          <FormInput
            value={inputValue}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:(
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )
            }}
          />
         {error && <Alert severity="error">{(error as Error).message}</Alert>}

          <DialogActions>
            <Button
              title="Delete"
              variant="contained"
              color="error"
              onClick={()=>handleDelete()}
              disabled={isLoading}
              loading={isLoading}
            />
            <Button title="Cancel"  onClick={handleClose} color='inherit'/>
          </DialogActions>
            <Toast
        open={deleteToast}
        message={"Goal deleted successfully"}
        type="success"
        handleClose={handleClose}
      />
        </form>
      </Modal>
    
    
  );
}

export default DeleteGoal;
