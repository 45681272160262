import { useQuery } from '@tanstack/react-query';
import balancesApiClient from '../apis/apiServices/balances';
import { Balance } from '../types/types';

interface WalletBalance {
  id: number;
  user:number;
  available: Balance;
  pending: Balance;
  hide_balance: boolean;
}

interface UserBalance {
  total: Balance;
  hide_balance: boolean;
}


export const useGetTotal = () => {
    return useQuery<UserBalance,Error>(['user','total'], balancesApiClient.getTotal,{
      placeholderData:{total:{ currency: '', amount: 0 },hide_balance:false},
    });
  };

  export const useGoalsTotal = () => {
    return useQuery<WalletBalance,Error>(['goals','total'], balancesApiClient.getGoalsTotal,{
      placeholderData: {
        id: 0,
        user:0,
        available: { currency: 'UGX', amount: 0 },
        pending: { currency: 'UGX', amount: 0 },
        hide_balance: false,
      },
    });
  };
 

  export const useUserWalletTotal = () => {
    return useQuery<WalletBalance,Error>(['user', 'wallet','total'], balancesApiClient.getUserWalletTotal,
    {
      placeholderData: {
        id: 0,
        user:0,
        available: { currency: 'UGX', amount: 0 },
        pending: { currency: 'UGX', amount: 0 },
        hide_balance: false,
      },
    });
  };

 

 