import { useMutation,useQueryClient } from '@tanstack/react-query'
import userApiClient from "../apis/apiServices/users";
import { LoginWithEmail, LoginWithPhone, SignupWithEmail, SignupWithPhone } from '../types/actionTypes';
import Cookies from "js-cookie";


const useSignUp = () => {
  const signUpByEmailMutation = useMutation((userData:SignupWithEmail) => userApiClient.createUserByEmail(userData),{
    onSuccess:()=>{localStorage.removeItem("email")}
    });
  const signUpByPhoneNumberMutation = useMutation((userData:SignupWithPhone) => userApiClient.createUserByPhone(userData),{
    onSuccess:()=>{
      localStorage.removeItem("phone")
      localStorage.removeItem("ref")
    }
    });

  const signUpByEmail = async (userData: SignupWithEmail) => {
    try {
      localStorage.setItem("email", userData.email);
      await signUpByEmailMutation.mutateAsync(userData);
    } catch (error) {
      console.error('Sign up by email error:', error);
      throw new Error('Sign up by email error:')
    }
  };

  const signUpByPhoneNumber = async (userData: SignupWithPhone) => {
    try {
      await signUpByPhoneNumberMutation.mutateAsync(userData);
    } catch (error) {
      console.error('Sign up by phone number error:', error);
      throw new Error('Sign up by phone number error:')
    }
  };

  return {
    signUpByEmail,
    loading: signUpByEmailMutation.isLoading || signUpByPhoneNumberMutation.isLoading ,
    signUpByPhoneNumber,
    error :signUpByEmailMutation.error || signUpByPhoneNumberMutation.error ,
  };
};

const useForgetPassword = () => {
  const forgetPasswordMutation = useMutation((data:{email?:string,phonenumber?:string }) => userApiClient.forgetPassword(data), {
        onSuccess: (data) => {
          // Perform additional actions after the mutation is successful
          localStorage.setItem("ref", data.ref);
        },
      });
 

  const forgetPassword = async (data: { email?: string; phonenumber?: string; }) => {
    try {
      await forgetPasswordMutation.mutateAsync(data);
    } catch (error) {
      console.error('Forget password error:', error);
      throw new Error('Forget password error:')
    }
  };
  return {
    forgetPassword,
    ... forgetPasswordMutation
  };
};


const useLogin = () => {
  const loginByEmailMutation = useMutation((userData:LoginWithEmail) => userApiClient.loginByEmail(userData));
  const loginByPhoneNumberMutation = useMutation((userData:LoginWithPhone) => userApiClient.loginByPhone(userData));

  const loginByEmail = async (userData:LoginWithEmail) => {
    try {
      await loginByEmailMutation.mutateAsync(userData);
    } catch (error:any) {
      console.error('Login by email error:');
      throw new Error(error)
    }
  };

  const loginByPhoneNumber = async (userData: LoginWithPhone) => {
    try {
      await loginByPhoneNumberMutation.mutateAsync(userData);
    } catch (error) {
      console.error('Login by phone number error:', error);
      throw new Error('Login by phone number error:')
    }
  };

  return {
    loginByEmail,
    error:loginByEmailMutation.error || loginByPhoneNumberMutation.error,
    loading: loginByEmailMutation.isLoading || loginByPhoneNumberMutation.isLoading,
    loginByPhoneNumber,
  };
};


const useResetPassword = () => {
    const resetPasswordByEmailMutation = useMutation((userData:{ password:string, otp:string,email:string }) => userApiClient.resetPassword(userData));
    const resetPasswordByPhoneNumberMutation = useMutation((userData:{password:string,phonenumber:string, reference:string,otp:string}) => userApiClient.resetPasswordPhone(userData));
  
    const resetPasswordByEmail = async (userData:{ password:string, otp:string ,email:string}) => {
      try {
        await resetPasswordByEmailMutation.mutateAsync(userData);
      } catch (error) {
        console.error('Reset password by email error:', error);
        throw new Error('Reset password by email error:')
      }
    };
  
    const resetPasswordByPhoneNumber = async (userData:{password:string,phonenumber:string, reference:string,otp:string}) => {
      try {
        await resetPasswordByPhoneNumberMutation.mutateAsync(userData);
      } catch (error) {
        console.error('Reset password by phone number error:', error);
        throw new Error('Reset password by phone number error:')
      }
    };
  
    return {
      resetPasswordByEmail,
      loading: resetPasswordByEmailMutation.isLoading || resetPasswordByPhoneNumberMutation.isLoading ,
      resetPasswordByPhoneNumber,
      error: resetPasswordByEmailMutation.error || resetPasswordByPhoneNumberMutation.error ,
    };
  };

  export const useResetPIN = () => {
    const resetPINByEmailMutation = useMutation((userData:{ pin:string, otp:string,email:string }) => userApiClient.resetPIN(userData));
    const resetPINByPhoneNumberMutation = useMutation((userData:{pin:string,phonenumber:string, reference:string,otp:string}) => userApiClient.resetPINPhone(userData));
  
    const resetPINByEmail = async (userData:{ pin:string, otp:string,email:string }) => {
      try {
        await resetPINByEmailMutation.mutateAsync(userData);
      } catch (error) {
        console.error('Reset PIN by email error:', error);
        throw new Error('Reset PIN by email error:')
      }
    };
  
    const resetPINByPhoneNumber = async (userData:{pin:string,phonenumber:string, reference:string,otp:string}) => {
      try {
        await resetPINByPhoneNumberMutation.mutateAsync(userData);
      } catch (error) {
        console.error('Reset pin by phone number error:', error);
        throw new Error('Reset pin by phone number error:')
      }
    };
  
    return {
      resetPINByEmail,
      loading: resetPINByEmailMutation.isLoading || resetPINByPhoneNumberMutation.isLoading ,
      resetPINByPhoneNumber,
      error: resetPINByEmailMutation.error || resetPINByPhoneNumberMutation.error ,
    };
  };


  const signOut = async () => {
    // Remove data from local storage and cookies
    localStorage.removeItem('name');
    Cookies.remove('access_token');
    Cookies.remove('refreshToken');
  };

  const useSignOut = () => {
    const queryClient = useQueryClient();
  
    const signOutMutation = useMutation(signOut, {
      onSuccess: () => {
        // Invalidate all queries and remove data from cache
        queryClient.invalidateQueries();
  
        // Optionally, you can also redirect to the login page or perform any other necessary actions
      },
    });
  
    const handleSignOut = async () => {
      try {
        await signOutMutation.mutateAsync();
      } catch (error) {
        console.error('Sign out error:', error);
      }
    };
  
    return {
      signOut: handleSignOut,
      signOutLoading: signOutMutation.isLoading,
    };
  };

  const useUserContact = () => {
    const getUserEmailMutation = useMutation((userData:{email:string}) => userApiClient.getEmail(userData));
    const getUserPhoneNumberMutation = useMutation((userData:{phonenumber:string}) => userApiClient.getPhoneNumber(userData), {
        onSuccess: (data) => {
          console.log(data)
          // Perform additional actions after the mutation is successful
          localStorage.setItem("ref", data.ref);
        },
      });
  
    const getUserByEmail = async (userData: { email: string; }) => {
      try {
        localStorage.setItem("email", userData.email);
        await getUserEmailMutation.mutateAsync(userData);
      } catch (error) {
        console.error('Reset password by email error:', error);
        throw new Error('Reset password by email error:')
      }
    };
  
    const getUserByPhoneNumber = async (userData: { phonenumber: string; }) => {
      try {
        localStorage.setItem("phone", userData.phonenumber);
        await getUserPhoneNumberMutation.mutateAsync(userData);
      } catch (error) {
        console.error('Reset password by phone number error:', error);
        throw new Error('Reset password by phone number error:')
      }
    };
  
    return {
      getUserByEmail,
      loading: getUserEmailMutation.isLoading || getUserPhoneNumberMutation.isLoading ,
      error: getUserEmailMutation.error || getUserPhoneNumberMutation.error,
      getUserByPhoneNumber,
      
    };
  }

  const useVerifyUser = () => {
    const verifyEmailMutation = useMutation((userData:{ otp:string, email:string }) => userApiClient.verifyEmail(userData));
    const verifyPhoneNumberMutation = useMutation((userData:{ reference:string, otp:string }) => userApiClient.verifyPhoneNumber(userData),{
    onSuccess:()=>{  localStorage.removeItem("ref")}
    });
  
    const verifyUserByEmail = async (userData: { otp: string; email: string; }) => {
      try {
        await verifyEmailMutation.mutateAsync(userData);
      } catch (error) {   
        console.error('Reset password by email error:', error);
        throw new Error('Reset password by email error:')
      }
    };
  
    const verifyByPhoneNumber = async (userData: { reference: string; otp: string; }) => {
      try {
        await verifyPhoneNumberMutation.mutateAsync(userData);
      } catch (error) {
        console.error('Reset password by phone number error:', error);
        throw new Error('Reset password by phone number error:')
      }
    };
  
    return {
      verifyUserByEmail,
      loading: verifyEmailMutation.isLoading || verifyPhoneNumberMutation.isLoading ,
      verifyByPhoneNumber,
      error:verifyPhoneNumberMutation.error || verifyEmailMutation.error
    };
  }

export { useSignUp, useLogin ,useResetPassword,useSignOut,useUserContact,useVerifyUser,useForgetPassword };
