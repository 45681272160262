import { useMutation,useQueryClient,useQuery ,useInfiniteQuery} from '@tanstack/react-query';
import walletsApiClient from '../apis/apiServices/wallets';
import { ExtendedWalletMember } from '../types/actionTypes';
import { PocketItem, TransactionData, Wallet, WalletMember } from '../types/types';


export const useGetUserWallet = () => {
  return useQuery<Wallet,Error>(['user', 'wallet'], () => walletsApiClient.getUserWallet());
};




export const useUpdateWalletById = () => {

  const mutation = useMutation((payload:{id: number; data: Partial<Wallet>}) => walletsApiClient.updateById(payload.id,payload.data));

  const updateWallet = async (id: number,data:Partial<Wallet>) => {
    try {
      await mutation.mutateAsync({id,data});
    } catch (error) {
      console.error('Error updating wallet:', error);
      throw new Error('Error updating wallet:');
    }
  };

  return {
    updateWallet,
    loading: mutation.isLoading,
    error: mutation.error,
  };
};




export const useEditWalletMember = () => {

  const editMutation = useMutation(
    (payload: { id: number; data: Partial<WalletMember> }) => walletsApiClient.editWalletMember(payload.id, payload.data)
  );

  const editWalletMember = async (id:number,data: ExtendedWalletMember) => {
    try {
      await editMutation.mutateAsync({id,data});
    } catch (error) {
      console.error('Error editing wallet member:', error);
      throw new Error('Error editing wallet member');
    }
  };

  return {
    editWalletMember,
    loading: editMutation.isLoading,
    error: editMutation.error,
  };
};


export const useCreateWalletMember = () => {
 const queryClient = useQueryClient()
  const createMutation = useMutation(
    (params:{id:number,members:number[]}) => walletsApiClient.createWalletMember(params.id,params.members),{
      onSuccess:(_,variables)=>{
         //queryClient.invalidateQueries(['group','wallet',variables.id])
         //queryClient.invalidateQueries(['user', 'wallet'])
      }
    }
     
  );

  const createWalletMember = async (params: {id: number, members: number[]}) => {
    try {
      const response = await createMutation.mutateAsync(params);
      return response.data
    } catch (error) {
      console.error('Error creating wallet member:', error);
      throw new Error('Error creating wallet member');
    }
  };

  return {
    createWalletMember,
    loading: createMutation.isLoading,
    error: createMutation.error,
  };
};


export const useWalletMemberById = (memberId: number) => {
    return useQuery(['wallet-members', memberId], () => walletsApiClient.getWalletMember(memberId));
  };

export const useGetUserWalletPockets = () => {
  return useQuery<PocketItem[],Error>(['pockets','user wallet'], walletsApiClient.getWalletPockets,{
    placeholderData:[],
  })
};




export const useWalletOptions = () => {
  return useQuery<Partial<Wallet[]>,Error>(['wallet-options'], walletsApiClient.getWalletOptions, {
    placeholderData:[],
  });
};


export const useWalletTransactions = (filter: any, hasSearchTerm: boolean) => {
  return useInfiniteQuery<TransactionData, Error>(['user','wallet','transactions', JSON.stringify(filter)], ({ pageParam = 0 }) => {
  
    const params = {
      ...filter,
      skip: pageParam
    };
    return walletsApiClient.getUserTransactions(params);
  }, {
     refetchInterval: 2 * 60 * 1000, // 2 minutes in milliseconds
    placeholderData:{ pageParams: [undefined, 1], pages: []},
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || !lastPage.transactions ||lastPage?.transactions.length < 10) {
        // All transactions have been fetched
        return null;
      }
      return pages.length * 10;
    },
  });
};


