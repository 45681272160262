import {
  categoryOptions,
  paymentOptions,
  statusOptions,
  transactionTypes,
} from "../Reusables/Input/select/Options";
import Button from "../Reusables/Input/Button";
import { Form, Field } from "react-final-form";
import { Box,DialogContent,DialogTitle,IconButton,Typography } from "@mui/material";
import DateInput from "../Reusables/Input/DateInput";
import Select from "../Reusables/Input/select/Select";
import { DialogContainer, Transition } from "../Reusables/Feedback/InfoDialog";
import CloseIcon from '@mui/icons-material/Close';

interface FilterProps {
  handleClose: () => void;
  open:boolean;
  initialValues: any;
  onUpdateValues: (values: any) => void;
  members?: any[];

}

export interface TransactionsFilter {
  category: string;
  paymentMethod: string;
  type: string;
  startDate: Date;
  endDate: Date;
  user:number;
  
}

function Filter({ open,handleClose,initialValues, onUpdateValues, members  }:FilterProps) {

  const getMemberOptions = () => {
  if (!members) {
    return [];
  }
  return members.map((member:any) => ({
      label: member.name,
      value: member.user,
    }));
  
};

  const onSubmit = async(values: TransactionsFilter) => {
    onUpdateValues(values)
  };

  
  return (
    <DialogContainer open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle sx={{display:'flex',alignItems:'center'}}>
        <Typography fontWeight={600} sx={{m:'0 auto'}}>Filter Options</Typography>
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{mt:2}}>
      <Form
        initialValues={{
          ...initialValues,
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form style={{display:'flex',flexDirection:'column',gap:'16px'}} onSubmit={handleSubmit}>
            <Box sx={{display:'flex',gap:1}}>
            <Box sx={{flex:0.5}}>
            <Typography variant ='h6'sx={{fontSize:'1rem',marginBottom:'4px',fontWeight:600}}> Start Date</Typography>
            <Field name="startDate" component={DateInput} label="" />

            </Box>
            <Box sx={{flex:0.5}}>
            <Typography variant ='h6' 
            sx={{fontSize:'1rem',marginBottom:'4px',fontWeight:600}}> End date</Typography>
            <Field name="endDate" component={DateInput} label="" />
            </Box>
            </Box>
            <Box sx={{display:'flex',gap:1}}>
            <Box sx={{flex:0.5}}>
            <Typography variant ='h6'sx={{fontSize:'1rem',marginBottom:'4px',fontWeight:600}}> Type</Typography>
              <Field
                type="select"
                name="type"
                component={Select}
                options={transactionTypes}
              />
            </Box>
            <Box sx={{flex:0.5}}>
            <Typography variant ='h6' 
            sx={{fontSize:'1rem',marginBottom:'4px',fontWeight:600}}>Category</Typography>
              <Field
                type="select"
                name="category"
                component={Select}
                options={categoryOptions}
              />
            </Box>
            </Box>
            <Box sx={{display:'flex',gap:1}}>
            <Box sx={{flex:0.5}}>
            <Typography variant ='h6'sx={{fontSize:'1rem',marginBottom:'4px',fontWeight:600}}>Payment method</Typography>
              <Field
                type="select"
                name="paymentMethod"
                component={Select}
                options={paymentOptions}
              />
            </Box>
            <Box sx={{flex:0.5}}>
            <Typography variant ='h6' 
            sx={{fontSize:'1rem',marginBottom:'4px',fontWeight:600}}> Status</Typography>
              <Field
                type="select"
                name="status"
                component={Select}
                options={statusOptions}
              />
            </Box>
            </Box>
            {members && (
            <Box sx={{flex:0.5}}>
            <Typography variant ='h6'sx={{fontSize:'1rem',marginBottom:'4px',fontWeight:600}}>By member</Typography>
              <Field
                type="select"
                name="user"
                component={Select}
                options={getMemberOptions()}
              />
            </Box>
              )}
            <Box sx={{alignText:'center'}}>
              <Button
                title="Set"
                color="primary"
                variant="contained"
                type="submit"
                disabled={submitting}
                onClick={handleClose}
                sx={{width:'100%',mt:2}}
              />
            </Box>
          </form>
        )}
      />
      </DialogContent>
    </DialogContainer>
  );
}

export default Filter;
