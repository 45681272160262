import { useState, useEffect } from "react";
import { numberWithCommas } from "../../utils/middleware";
import Button from "../Reusables/Input/Button";
import { useStyles } from "./homeStyles";
import { Link, useHistory } from "react-router-dom";
import { IconButton, useTheme, Typography, Box, Avatar, Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CreateDeposit from "../Modals/CreateDeposit";
import CreateWithdrawal from "../Modals/CreateWithdrawal";
import { useGetTotal } from "../../hooks/balanceHooks";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useStyles as useButtonStyles } from '../Reusables/Input/ButtonStyles';
import { useNotifications, useUpdateNotifications } from "../../hooks/notificationHooks";
import { useUser } from "../../hooks/userHooks";
import UserDrawer from "../profile/UserDrawer";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Notification as NotificationType } from "../../types/types";
import NotificationsModal from "../Modals/NotificationsModal";

const HomeHeader = () => {

  const { data, isLoading, } = useGetTotal();
  const notifications = useNotifications();
  const { data: userData, } = useUser();

  const [open, setOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false)
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [momoModalOpen, setMomoModalOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const [hideBalance, setHideBalance] = useState(data?.hide_balance || false);
  useEffect(() => {
    if (data?.hide_balance !== undefined) {
      setHideBalance(data.hide_balance);
    }
  }, [data?.hide_balance]);

  const theme = useTheme();
  const matches = useMediaQuery(`@media (min-width: ${theme.breakpoints.values.sm}px)`);


  /**get total balance and notiifications */


  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  const { updateNotifications } = useUpdateNotifications();

  const handleUpdateNotifications = () => {
    const ids = notifications.data.map((notification: NotificationType) => notification.id)
    updateNotifications(ids);
  };

  const handleDepositClick = () => {
    setOpenDeposit(true);
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div className={classes.container}>
      <div className={classes.totalHeader}>
        <Box onClick={handleDrawerToggle} sx={{ display: { sm: 'block', md: 'none' }, }}>
          <Avatar alt={userData?.first_name} src={userData?.profile_img} >
            {userData && userData?.first_name.charAt(0)}
          </Avatar>
        </Box>
        <Typography variant='body1' fontWeight={600}>
          Home
        </Typography>
        <Box position="relative">
          {matches ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton color="inherit" onClick={handleDialogOpen} sx={{ display: "flex", flexDirection: "column" }} disableRipple>
                <NotificationsActiveIcon />
                <Typography variant='caption' fontWeight={600} color="inherit">Notifications</Typography>
              </IconButton>

              <NotificationsModal
                open={openDialog}
                onClose={handleDialogClose}
                notifications={notifications}
                handleUpdateNotifications={handleUpdateNotifications}
              />
            </Box>
          ) : (
            <Box component={Link} to="/profile/notifications" sx={{ color: '#673AB7' }}>
              <NotificationsActiveIcon />
            </Box>
          )}
        </Box>

        {userData && <UserDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} userData={userData} />}
      </div>
      <div className={classes.balanceSection}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Typography variant="button">Total Balance</Typography>
          <IconButton sx={{ color: 'inherit' }} size='small' onClick={() => setHideBalance(!hideBalance)}>
            {hideBalance ? <VisibilityOffIcon sx={{ height: '18px', width: '18px' }} /> : <VisibilityIcon sx={{ height: '18px', width: '18px' }} />}
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: "4px" }}>

          {!hideBalance && data ? (
            <Typography variant="h2" >
              {data.total.amount && numberWithCommas(data.total.amount)}
            </Typography>
          ) : (
            <Typography variant="h4">••••••</Typography>
          )}

          <Typography variant="body1">
            {data?.total.currency}
          </Typography>
        </Box>
      </div>

      <div className={classes.btnContainer}>
        <Button
          title="Deposit"
          variant="contained"
          disableElevation
          className={buttonClasses.mediumButton}
          onClick={handleDepositClick}
        />

        <Button
          title="Withdraw"
          variant="contained"
          disableElevation
          className={buttonClasses.mediumButton}
          onClick={() => setOpenWithdraw(!openWithdraw)}
        />

      </div>
      <CreateDeposit open={openDeposit} onClose={() => setOpenDeposit(false)} />
      <CreateWithdrawal open={openWithdraw} onClose={() => setOpenWithdraw(!openWithdraw)} />
    </div>
  );
};

export default HomeHeader;
