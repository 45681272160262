import { createTheme, ThemeOptions,responsiveFontSizes } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#673AB7',
      light: '#764EBE',
      dark: '#5D34A5',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FAFF00',
      light: '#4B78F7',
      dark: '#341D5C',
    },
    warning: {
      main: '#EF6C00',
      light: '#FFE0B2',
      dark: '#F57C00',
      contrastText: '#ffffff',
    },
    error: {
      main: '#EF4444',
      light: '#FEE2E2',
      dark: '#D31121',
    },
    success: {
      main: '#22C55E',
      light: '#E4F7E8',
      dark: '#006703',
    },
    grey: {
      100: '#FAFAFA',
      200: '#F4F4F5',
      300: '#E4E4E7',
      400: '#A1A1AA',
      500: '#71717A',
      600: '#52525B',
      700: '#3F3F46',
      800: '#27272A',
      900: '#18181B',
    },
    info: {
      main: '#FFFFFF',
      light: '#E4F1FF',
      dark: '#1E40AF',
      contrastText: '#673AB7'
    },
  },
  typography: {
    fontFamily: 'Source Serif Pro, serif',
    fontWeightRegular: 400,
    fontWeightBold: 700,
  },
  components: {
    MuiTypography: {
      defaultProps: {
          fontFamily: 'Source Serif Pro, serif',
      },
      styleOverrides:{
        button: {
          textTransform: "inherit",
          fontWeight:600,
          fontSize: '1rem',
          letterSpacing: '0.0125rem',
          lineHeight: 'normal'
        },
        subtitle1: {
          fontSize: '1rem',
        },
        subtitle2: {
          fontSize: '0.875rem',
        },
        caption: {
          fontSize: '0.875rem',
          letterSpacing: '0.01094rem',
          lineHeight: 'normal'
        },
        body1: {
          fontSize: '1.125rem',
          fontWeight: 400,
          letterSpacing: '0.00563rem',
          lineHeight: 'normal'
        },
        body2: {
          fontSize: '1rem',
          letterSpacing: '0.0025rem',
          lineHeight: 'normal'
        },
        overline: {
          fontSize: '0.75rem',
          letterSpacing: '0.00938rem',
          lineHeight: 'normal'

        },
        h1: {
          fontSize: '3.4375rem',
          fontWeight: 600,
          lineHeight: 'normal'
        },
        h2: {
          fontSize: '2.375rem',
          fontWeight: 600,
          letterSpacing: '0.00594rem',
          lineHeight: 'normal'
        },
        h3: {
          fontSize: '1.5rem',
          fontWeight: 600,
          lineHeight: 'normal'
        },
        h4: {
          fontSize: '1.375rem',
          fontWeight: 400,
          letterSpacing: '0.00688rem',
          lineHeight: 'normal'
        },
        h5: {
          fontSize: "1.25rem",
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '0.00625rem'
        }
        
      }
     
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Serif Pro, serif',
        },
      },
    },
    
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
}

const theme = createTheme(themeOptions);
const responsiveTheme = responsiveFontSizes(theme); // Apply responsive font sizes



export default responsiveTheme;
