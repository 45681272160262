import { ReinvestMultipleGoalsData, ReinvestNewGoalData, ReinvestSameGoalsData, ReinvestSingleGoalData } from "../../types/actionTypes";
import { createIdempotenceKey } from "../../utils/middleware";
import api from "../api";
import { REINVESTS_ENDPOINT } from "../apiEndpoints";
const timeframeInMinutes = 10;

const reinvestsApiClient = {
  newGoal: async (data: ReinvestNewGoalData) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
      const config = {
        headers: {
          "x-idempotence-key": idempotenceKey,
        },
      };
      const response = await api.post(`${REINVESTS_ENDPOINT}/new-goal`, data, config);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data
      }
    }

  },
  multipleGoals: async (data: ReinvestMultipleGoalsData) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
      const config = {
        headers: {
          "x-idempotence-key": idempotenceKey,
        },
      };
      const response = await api.post(
        `${REINVESTS_ENDPOINT}/multiple-goals`, data, config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data
      }
    }

  },
  singleGoal: async (data: ReinvestSingleGoalData) => {
    try {
      const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);
      const config = {
        headers: {
          "x-idempotence-key": idempotenceKey,
        },
      };
      const response = await api.post(
        `${REINVESTS_ENDPOINT}/single-goal`, data, config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data
      }
    }

  },
  sameGoal: async (data: ReinvestSameGoalsData) => {
    try {
      const idempotenceKey = createIdempotenceKey(data.goals, timeframeInMinutes);

      const config = {
        headers: {
          "x-idempotence-key": idempotenceKey,
        },
      };
      const response = await api.post(`${REINVESTS_ENDPOINT}/same-goal`, data, config);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data
      }
    }

  },

};

export default reinvestsApiClient;
