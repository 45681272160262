import { Balance, Contact, SearchResultUser } from "../../types/types";
import { Box, Container, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Avatar, useRadioGroup } from "@mui/material";
import { Field, Form } from "react-final-form";
import { FormApi, ValidationErrors } from "final-form";
import { useState } from "react";
import PasbancUserStep from "./steps/PasbancUserStep";
import RequestDetailsStep from "./steps/RequestDetailsStep";
import { Alert } from "@mui/material";
import Button from "../Reusables/Input/Button";
import DoneIcon from '@mui/icons-material/Done';
import { numberWithCommas, removeCommas } from "../../utils/middleware";
import { useMemberSelection } from "../../hooks/customHooks";
import { useCreatePaymentRequest } from "../../hooks/paymentRequestHook";

interface RequestStepperProps {
    balance: Balance;
    onClose: () => void;
    steps: string[];
}

interface RequestStepperFormValues {
    user: SearchResultUser;
    userType: string;
    amount: string;
    description: string;
}

const RequestStepper: React.FC<RequestStepperProps> = ({ balance, onClose, steps }) => {
    const [activeStep, setActiveStep] = useState(0);
    const { selectedMembers, handleAddMember } = useMemberSelection();

    const { createPaymentRequest, loading, error } = useCreatePaymentRequest();

    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === 'pasbanc';
    }
    
    const handleReset = (form: FormApi<RequestStepperFormValues, Partial<RequestStepperFormValues>>) => {
        setActiveStep(0);
        form.reset();
        onClose()
    };

    const validate = (values: RequestStepperFormValues) => {
        const errors: ValidationErrors = {};

        if (activeStep === 1) {
            if (!values.user) {
                errors.user = "Required";
            }

            if (values.user) {
                const names = values.user.name.split(' ');
                if (names.length !== 2) {
                    errors.user = 'Please enter two names separated by a space';
                }
            }
        }

        if (activeStep === 2) {
            if (!values.amount) {
                errors.amount = "Required";
            }

            if (!values.description) {
                errors.description = "Required";
            }

        }
        return errors;
    };

    const handleNext = async (values: RequestStepperFormValues) => {

        const { amount, user, description } = values
        if (activeStep === steps.length - 2) {
            try {
                await createPaymentRequest({
                    amount: parseFloat(removeCommas(amount)),
                    currency: balance.currency,
                    reason: description,
                    recipients: [user]
                })
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

            } catch (error) {
                console.log('error')
            }


        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Container maxWidth="sm">
            <Form
                initialValues={{ userType: 'pasbanc' }}
                onSubmit={handleNext}
                validate={validate}
                render={({ handleSubmit, values, form }) => (
                    <form onSubmit={handleSubmit}>
                        {activeStep === 0 && (
                            <FormControl sx={{ mt: 2, width: "100%" }}>
                                <Field name="userType" type="radio">
                                    {({ input }) => (
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={input.value}
                                            onChange={input.onChange}
                                        >
                                            <Box sx={{ ...(checked ? { border: '2px solid #673AB7', borderRadius: "0.375rem", width: "100%", backgroundColor: "#F6F6F7" } : {}), padding: "0.1875rem 0.25rem", display: "inline-flex", alignItems: "center" }}>
                                                <FormControlLabel value="pasbanc" control={<Radio />} label="Pasbanc user" labelPlacement="start" sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    width: "100%"
                                                }} />
                                            </Box>
                                        </RadioGroup>
                                    )}
                                </Field>
                            </FormControl>
                        )}
                        {activeStep === 1 && values.userType === 'pasbanc' &&
                            <Field name="user" value={selectedMembers[0]} >
                                {({ input, meta }) => (
                                    <>
                                        <PasbancUserStep
                                            recipient={values.user}
                                            onAddMember={(selectedContact) => {
                                                input.onChange(selectedContact);
                                                handleAddMember(selectedContact);
                                                handleNext(values)
                                            }}
                                        />
                                        <Typography color='error' variant='caption'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                                    </>
                                )}
                            </Field>}

                        {activeStep === 2 && <RequestDetailsStep selectedMember={values.user as Contact} balance={balance} />}
                        {activeStep === 3 && <Box>
                            <Typography sx={{ mt: 2 }}>
                                You are requesting  <Typography component='span' fontWeight={600}> {numberWithCommas(values.amount)}</Typography> <Typography component='span' variant='overline'> {balance.currency}</Typography> from  <Typography component='span' fontWeight={600}>{values.user.name}</Typography>
                            </Typography>
                        </Box>}
                        {activeStep === 4 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: '#22C55E', mt: 2, width: 60, height: 60 }}>
                                <DoneIcon fontSize='large' />
                            </Avatar>
                            <Typography variant='h6' sx={{ mt: 1, fontWeight: 600 }}>Success!</Typography>
                            <Typography>Request has been successfully sent.User has been notified</Typography>
                        </Box>}
                        {activeStep === steps.length - 2 && error && <Alert severity='error'>{(error as Error).message}</Alert>}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 3,
                            }}
                        >
                            {activeStep === steps.length - 1 ? (
                                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                    <Button title='Okay' variant='contained' sx={{ width: '100%' }} onClick={() => handleReset(form)} />
                                </Box>
                            ) : (
                                <>
                                    {activeStep !== 0 && (
                                        <Button color="primary" onClick={handleBack} title='Back' sx={{ backgroundColor: '#F0EBF8', mr: 1.5, width: '120px' }} />
                                    )}
                                    <Button variant="contained" type="submit"
                                        title={activeStep === steps.length - 2 ? 'Send request' : 'Next'}
                                        sx={{ minWidth: '120px' }}
                                    />
                                </>
                            )}
                        </Box>
                    </form>
                )}
            />
        </Container>
    );
};

export default RequestStepper;