/** i first need to outline multiple transfer capabailities */
/**
 * 1.wallet to goal transfer
 * 2.wallet to wallet transfer
 * 3.goal to wallet transfer
 * 4.goal to goal transfer
 */

import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import {
  Alert,
  Avatar,
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import Button from '../Reusables/Input/Button';
import CloseIcon from '@mui/icons-material/Close';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FormInput from '../Reusables/Input/FormInput';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DepositOptionsStep } from './CreateDeposit';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { WithdrawOptionsStep } from './CreateWithdrawal';
import { calculateTransferFee, numberWithCommas, removeCommas } from '../../utils/middleware';
import { FormApi } from 'final-form';
import { useGoalToGoalTransfer, useWalletToWalletTransfer, useGoalWalletTransfer, usePocketGoalTransfer, useWalletPocketTransfer, usePocketToPocketTransfer } from '../../hooks/transferHooks';
import ModalWrapper from './ModalWrapper';

const steps = ['Select Transfer Type', 'Confirm', 'Success'];

interface TransferStepperProps {
  open: boolean;
  onClose: () => void;
}

interface TransferFormValues {
  type: string,
  amount: string,
  source: any,
  destination: any
}

const TransferStepper: React.FC<TransferStepperProps> = ({ open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLDivElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLDivElement>(null);
  const [withdrawFormValues, setwithdrawFormValues] = useState({ type: '', currency: '', name: '', amount: 0 });
  const [depositFormValues, setDepositFormValues] = useState({ type: '', currency: '', name: '' });

  const { goalWalletTransfer, loading: walletGoalLoading, error: walletGoalError } = useGoalWalletTransfer();
  const { walletPocketTransfer, loading: walletPocketLoading, error: walletPocketError } = useWalletPocketTransfer();
  const { pocketGoalTransfer, loading: pocketGoalLoading, error: pocketGoalError } = usePocketGoalTransfer();
  const { goalToGoal, isLoading: goalLoading, error: goalError } = useGoalToGoalTransfer();
  const { pocketToPocket, isLoading: pocketLoading, error: pocketError } = usePocketToPocketTransfer();
  const { walletToWallet, isLoading: walletLoading, error: walletError } = useWalletToWalletTransfer();

  const history = useHistory()

  const handleClick1 = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl1(event.currentTarget as HTMLDivElement);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl2(event.currentTarget as HTMLDivElement);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };



  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const popover1Id = open1 ? 'popover1' : undefined;
  const popover2Id = open2 ? 'popover2' : undefined;

  const handleNext = async (values: TransferFormValues) => {
    if (activeStep === steps.length - 2) {
      const { source, destination, amount } = values;
      const { type: sourceType, currency } = withdrawFormValues;
      const { type: destinationType } = depositFormValues;
      const fee = calculateTransferFee(currency, Number(amount))

      try {
        if ((sourceType === 'wallet' || sourceType === 'group') && destinationType === 'goal') {
          // Handle wallet/group to goal transfer
          await goalWalletTransfer({ goalId: destination, walletId: source, amount: parseFloat(removeCommas(amount)), currency, fee }, 'walletToGoal');
        } else if ((sourceType === 'wallet' || sourceType === 'group') && (destinationType === 'wallet' || destinationType === 'group')) {
          // Handle wallet/group to wallet transfer
          await walletToWallet({ senderWalletId: source, recipientWalletId: destination, amount: parseFloat(removeCommas(amount)), currency, fee });
        } else if (sourceType === 'goal' && (destinationType === 'wallet' || destinationType === 'group')) {
          // Handle goal to wallet/group transfer
          await goalWalletTransfer({ goalId: source, walletId: destination, amount: parseFloat(removeCommas(amount)), currency, fee }, 'goalToWallet');
        } else if (sourceType === 'goal' && destinationType === 'goal') {
          // Handle goal to goal transfer
          await goalToGoal({ senderGoalId: source, recipientGoalId: destination, amount: parseFloat(removeCommas(amount)), currency, fee });
        } else if (sourceType === 'pocket' && destinationType === 'goal') {
          // Handle pocket to goal transfer
          await pocketGoalTransfer({ pocketId: source as number, goalId: destination as number, amount: parseFloat(removeCommas(values.amount)), currency, fee }, 'pocketToGoal');
        } else if (sourceType === 'goal' && destinationType === 'pocket') {
          // Handle goal to pocket transfer
          await pocketGoalTransfer({ pocketId: destination as number, goalId: source as number, amount: parseFloat(removeCommas(values.amount)), currency, fee }, 'goalToPocket');
        } else if ((sourceType === 'wallet' || sourceType === 'group') && destinationType === 'pocket') {
          // Handle wallet/group to pocket transfer
          await walletPocketTransfer({ pocketId: destination as number, walletId: source, amount: parseFloat(removeCommas(values.amount)), currency, fee }, 'walletToPocket');
        } else if (sourceType === 'pocket' && (destinationType === 'wallet' || destinationType === 'group')) {
          // Handle pocket to wallet/group transfer
          await walletPocketTransfer({ pocketId: source as number, walletId: destination as number, amount: parseFloat(removeCommas(values.amount)), currency, fee }, 'pocketToWallet');
        } else if (sourceType === 'pocket' && destinationType === 'pocket') {
          // Handle pocket to pocket transfer
          await pocketToPocket({ senderPocketId: source as number, recipientPocketId: destination as number, amount: parseFloat(removeCommas(values.amount)), currency, fee });
        }

        // Proceed to the next step
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error('Transfer error:', error);
        // Handle error here if needed
      }
    } else {
      // Proceed to the next step
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = (form: FormApi<TransferFormValues, Partial<TransferFormValues>>) => {
    setActiveStep(0);
    form.reset();
    onClose()
    setwithdrawFormValues({ type: '', currency: '', name: '', amount: 0 });
    setDepositFormValues({ type: '', currency: '', name: '' });
  };

  const validate = (values: TransferFormValues) => {
    const errors: Partial<TransferFormValues> = {};
    if (activeStep === 0) {
      if (!values.source) {
        errors.source = "required";
      }
      if (!values.destination) {
        errors.source = "required";
      }
      if (!values.amount) {
        errors.amount = "required"
      }
      if (values.source && values.destination) {
        if ((values.source === values.destination) && (depositFormValues.type === withdrawFormValues.type)) {
          errors.destination = 'Cant transfer to the same account'
        }
      }
    }

    return errors;
  };



  return (
    <ModalWrapper open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
        <Typography variant='h6' fontWeight={600} sx={{ m: '0 auto' }}>Transfer</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ px: 1, padding: { lg: '16px 32px' } }}>
        <Form
          onSubmit={handleNext}
          validate={validate}
          initialValues={{
            amount: 0
          }}
          render={({ handleSubmit, form, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              {activeStep === 0 && <>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleClick1}>
                      <Typography fontWeight={600} sx={{ width: '51px' }}>From</Typography>
                      <Field
                        name='source'
                        component={(props) => (
                          <FormInput
                            value={withdrawFormValues.name}
                            InputProps={{
                              readOnly: true,
                              endAdornment: <ArrowDropDownIcon />
                            }} {...props} />
                        )}
                        sx={{ width: { lg: '400px' } }}
                      />

                    </Box>
                    <Popover
                      id={popover1Id}
                      open={open1}
                      anchorEl={anchorEl1}
                      onClose={handleClose1}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Box sx={{ p: 1 }}>
                        <WithdrawOptionsStep
                          formValues={withdrawFormValues}
                          setFormValues={setwithdrawFormValues}
                          name='source'
                          handleNext={handleClose1}
                        />
                      </Box>
                    </Popover>

                    <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleClick2}>
                      <Typography fontWeight={600} sx={{ width: '51px' }}>To</Typography>
                      <Field
                        name='destination'
                        component={(props) => (
                          <FormInput
                            value={depositFormValues.name}
                            InputProps={{
                              readOnly: true,
                              endAdornment: <ArrowDropDownIcon />
                            }} {...props}
                            sx={{ width: { lg: '400px' } }} />
                        )}
                      />

                    </Box>
                    <Popover
                      id={popover2Id}
                      open={open2}
                      anchorEl={anchorEl2}
                      onClose={handleClose2}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Box sx={{ p: 1 }}>
                        <DepositOptionsStep formValues={depositFormValues} setFormValues={setDepositFormValues} name='destination' handleNext={handleClose2} />
                      </Box>
                    </Popover>
                  </Box>
                  <SwapVertIcon color='primary' fontSize='large' />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Field name="amount" data-testid="amount">
                    {(props: any) => <FormInput
                      label="Amount"
                      type="text"
                      name="amount"
                      value={numberWithCommas(props.input.value)}
                      onChange={(e) => {
                        props.input.onChange(removeCommas(e.target.value));

                      }}
                      InputProps={{
                        endAdornment: <Typography sx={{ color: '#006703', fontWeight: 600 }}
                          onClick={() => {
                            props.input.onChange(withdrawFormValues.amount);
                          }}
                        >Max</Typography>
                      }}
                      {...props}
                    />}
                  </Field>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='caption' sx={{ color: '#6F6F6F' }}>Max Amount</Typography>
                    <Typography variant='caption' sx={{ color: '#6F6F6F' }}>{`${numberWithCommas(withdrawFormValues.amount)} ${withdrawFormValues.currency}`}</Typography>
                  </Box>
                </Box>
              </>}
              {activeStep === 1 && (<Box> <Typography sx={{ mt: 1, textAlign: 'center' }}>
                You are transferring{' '}{numberWithCommas(values.amount)} {' '}
                <Typography component="span" fontWeight={600}>
                  {withdrawFormValues.currency} from {withdrawFormValues.name}
                </Typography>{' '}
                to{' '}
                <Typography component="span" fontWeight={600}>
                  {depositFormValues.name}
                </Typography>
              </Typography>
              </Box>)}
              {activeStep === 2 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#22C55E', width: 40, height: 40 }}>
                  <SyncAltIcon sx={{ color: '#000' }} />
                </Avatar>
                <Typography variant='body1' mt={1}>You successfully sent </Typography>
                <Typography sx={{ fontWeight: 600 }}>{`${numberWithCommas(values.amount)} ${withdrawFormValues.currency}`}</Typography>
                <Box sx={{ mt: 1 }}>
                  <Typography variant='subtitle2' fontWeight={600}>Transaction details</Typography>
                  <Typography>
                    From <Typography component="span" fontWeight={600}>{withdrawFormValues.name}</Typography> to <Typography fontWeight={600} component="span">{depositFormValues.name}</Typography>
                  </Typography>
                  <Typography gutterBottom>
                    {`At ${dayjs().format('h:mm A')}. ${dayjs().format('ddd D MMM YY')}`}
                  </Typography>
                </Box>
              </Box>}
              {activeStep === steps.length - 2 && walletGoalError && <Alert severity="error">{(walletGoalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletPocketError && <Alert severity="error">{(walletPocketError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && pocketGoalError && <Alert severity="error">{(pocketGoalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && goalError && <Alert severity="error">{(goalError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && pocketError && <Alert severity="error">{(pocketError as Error).message}</Alert>}
              {activeStep === steps.length - 2 && walletError && <Alert severity="error">{(walletError as Error).message}</Alert>}
              {activeStep === steps.length - 1 ? (
                <Box sx={{ mt: 2 }}>
                  <Button title='Great' variant='contained' sx={{ width: '100%' }} onClick={() => handleReset(form)} />
                  <Button title='View transaction history' sx={{ backgroundColor: '#F0EBF8', width: '100%', mt: 1 }} onClick={() => {
                    handleReset(form)
                    history.push('/transactions')
                  }} />
                </Box>
              ) : (
                <Box sx={{ my: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {activeStep !== 0 && (
                    <Button color="primary" onClick={handleBack} title='Back' sx={{ backgroundColor: '#F0EBF8', mr: 1.5, width: '120px' }} />
                  )}
                  <Button variant="contained" type="submit"
                    title={activeStep === steps.length - 2 ? 'Transfer' : 'Next'}
                    loading={submitting || walletGoalLoading || goalLoading || walletPocketLoading || walletLoading || pocketGoalLoading || pocketLoading}
                    sx={{ width: '120px' }} />
                </Box>
              )}
            </form>
          )}
        />
      </DialogContent>
    </ModalWrapper>
  );
};

export default TransferStepper;
