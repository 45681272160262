import React, { useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Box,
  IconButton,
  useMediaQuery,Theme
} from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';

function NotificationSettings() {
  const [emailNotification, setEmailNotification] = useState(true);
  const [smsNotification, setSmsNotification] = useState(true);
  const [pushNotification, setPushNotification] = useState(true);
   const isDesktop = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));
  

  const handleEmailToggle = () => {
    setEmailNotification((prev) => !prev);
  };

  const handleSmsToggle = () => {
    setSmsNotification((prev) => !prev);
  };

  const handlePushToggle = () => {
    setPushNotification((prev) => !prev);
  };
   const history =useHistory()

  return (
    <div>
        <Box sx={{display:'flex',alignItems:'start',gap:1,mt:2}}>
          {!isDesktop && (
           <IconButton onClick={()=>history.goBack()} sx={{p:0}}>
            <ArrowBackOutlinedIcon />
         </IconButton>
          )}
        
          <Typography variant="h6" fontWeight={600} sx={{m:'0 auto'}}>
         Notifications Settings
        </Typography>
         </Box>
        <List sx={{maxWidth:'600px',m:'0 auto'}}>
          <ListItem>
            <ListItemText primary="Email notifications" />
            <ListItemSecondaryAction>
              <Switch checked={emailNotification} onChange={handleEmailToggle} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="SMS notifications" />
            <ListItemSecondaryAction>
              <Switch checked={smsNotification} onChange={handleSmsToggle} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Push notifications" />
            <ListItemSecondaryAction>
              <Switch checked={pushNotification} onChange={handlePushToggle} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
    </div>
  );
}

export default NotificationSettings;
