import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme:Theme) => ({
  header:{
    padding:'0px 16px',
     width:'100%',
    [theme.breakpoints.up('lg')]:{
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between',
      backgroundColor:theme.palette.primary.main,
      color:theme.palette.primary.contrastText,
      padding:'0px 24px',
      height:150
    }
  },
  balanceSection: {
    display: "flex",
    flexDirection:'column',
    alignItems:'center',
    marginTop:40
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    alignSelf: "center",
  },

  imageContainer: {
    height: "200px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#3F4A99",
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      height: "280px",
    },
  }, 
  editHeader: {
    display: "flex",
    alignItems: "center",
    gap: "56px",
  },
  editImage: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "16px",
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      flexDirection: "row",
    },
  },
  editContainer: {
    margin: "16px 16px",
    display: "flex",
    flexDirection: "column",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "24px",
  },
  exploreContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "16px",
    gap: "16px",
  },
  goalTabs:{
   [theme.breakpoints.up('lg')]:{
     padding:'0px', 
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 50,
    },
    }
  },
  goalTab:{
     [theme.breakpoints.up('lg')]:{
      color:'#fff',
      maxWidth:'180px',
      '&.Mui-selected': {
        color:theme.palette.secondary.main,
        backgroundColor:'#482980'
     },
    }
  }
   
}));
