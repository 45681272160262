import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  Chip,
  Box,
  Typography,
  Slide,
  Popover,
  capitalize,
  Alert,
} from '@mui/material';
import { Form, Field } from 'react-final-form';
import Select from '../../components/Reusables/Input/select/Select';
import Button from '../../components/Reusables/Input/Button';
import FormInput from '../../components/Reusables/Input/FormInput';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { beneficiaryOptions, countries, genderOptions, relationShipOptions } from '../../components/Reusables/Input/select/Options';
import { telephoneCheck } from '../../utils/middleware';
import { useCreateNextOfKin } from '../../hooks/userHooks';
import ModalWrapper from '../../components/Modals/ModalWrapper';

interface NextOfKinFormValues {
  username: string;
  first_name: string;
  last_name: string;
  relationship: string;
  email: string;
  phone_number: string;
  gender: string;
  beneficiary_role: string;
  country: string;
  company_address: string;
  mailing_address: string;
  allocation_percentage: number;
  secondary_beneficiary: string;
  secondary_beneficiary_role: string
}

interface NextOfKinDialogProps {
  open: boolean;
  onClose: () => void;
}

const renderForm = (selectedForm: string) => {
  if (selectedForm === 'individual') {
    return (
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Field name="first_name" component={FormInput} label="First name" fullWidth />
          <Field name="last_name" component={FormInput} label="Last name" fullWidth />
          <Field name="email" component={FormInput} label="Email address" fullWidth />
          <Box >
            <Typography fontWeight={600}>Relationship</Typography>
            <Field name="relationship" component={Select} options={relationShipOptions} />
          </Box>
          <Box >
            <Typography fontWeight={600}>Gender</Typography>
            <Field name="gender" component={Select} options={genderOptions} />
          </Box>
          <Field name="username" component={FormInput} label="Pasbanc username" placeholder='@jane' fullWidth />
        </Box>
      </Slide>
    );
  } else if (selectedForm === 'corporation') {
    return (
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Field name="first_name" component={FormInput} label="What is the registered name of the corporation?" fullWidth />
          <Box >
            <Typography fontWeight={600}>Select the Country of Work</Typography>
            <Field name="country" component={Select} options={countries} />
          </Box>
          <Field name="company_address" component={FormInput} label="Add office location or business address" fullWidth />
          <Field name="phone_number" component={FormInput} label="Their phone number" fullWidth />
          <Field name="email" component={FormInput} label="Email Address" fullWidth type='email' />
          <Field name="mailing_address" component={FormInput} label="P.O. Box mailing address (optional)" fullWidth />
        </Box>
      </Slide>
    );
  }
}

const NextOfKin: React.FC<NextOfKinDialogProps> = ({ open, onClose }) => {
  const { createNextOfKin, isLoading, error } = useCreateNextOfKin();

  const [selectedForm, setSelectedForm] = useState<'individual' | 'corporation'>('individual');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);


  const handleChipChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (formType: 'individual' | 'corporation') => {
    setSelectedForm(formType)
    setAnchorEl(null);

  }

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'popover' : undefined;

  const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState(false);

  const handleAdvancedOptionsToggle = () => {
    setAdvancedOptionsOpen(!advancedOptionsOpen);
  };
  const validate = (values: NextOfKinFormValues) => {
    const errors: Partial<NextOfKinFormValues> = {};

    if (selectedForm === 'individual') {
      if (!values.first_name) {
        errors.first_name = 'First name is required';
      }
      if (!values.last_name) {
        errors.last_name = 'Last name is required';
      }
      if (!values.relationship) {
        errors.relationship = 'Relationship is required';
      }
      if (!values.gender) {
        errors.gender = 'Gender is required';
      }
    }

    if (selectedForm === 'corporation') {
      if (!values.first_name) {
        errors.first_name = 'Corporation name is required';
      }
      if (!values.country) {
        errors.country = 'Country is required';
      }
      if (!values.company_address) {
        errors.company_address = 'Office location is required';
      }
      if (!values.phone_number) {
        errors.phone_number = 'Phone-number is required';
      }
      if (values.phone_number && !telephoneCheck(values.phone_number)) {
        errors.phone_number = "Invalid phone number";
      }
      if (!values.email) {
        errors.email = 'Email address is required';
      }

    }

    return errors;
  };


  const handleSubmit = async (values: NextOfKinFormValues) => {
    try {
      let data = {
        ...values,
        is_corporation: selectedForm === 'corporation' ? true : false
      }
      await createNextOfKin(data);
      onClose(); // Close the dialog on successful submission
    } catch (error) {
      // Handle error or show an error message
    }
  };

  return (
    <Box sx={{ px: 2, mt: 2 }}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Chip label={`Add ${selectedForm} as next of kin`} aria-describedby={popoverId}
          onClick={(event) => handleChipChange(event)} sx={{ m: '0 auto', backgroundColor: '#F0EBF8' }} deleteIcon={<KeyboardArrowDownOutlinedIcon />} />
        <Popover
          id={popoverId}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClick={() => handleChange(selectedForm === 'individual' ? 'corporation' : 'individual')}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ py: 1, px: 1 }}>{`${capitalize(selectedForm === 'individual' ? 'corporation' : 'individual')} Next of kin`}</Typography>
        </Popover>
        <Form onSubmit={handleSubmit} validate={validate}>
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              {/* Basic Next of Kin Form */}
              {renderForm(selectedForm)}
              <Chip label='Advanced Options'
                sx={{ color: '#021AEE', backgroundColor: '#F0F2FB', mt: 1, fontWeight: 600 }} onClick={handleAdvancedOptionsToggle} />
              <ModalWrapper open={advancedOptionsOpen} onClose={handleAdvancedOptionsToggle}>
                <DialogTitle>
                  <Typography fontWeight={600} sx={{ textAlign: 'center' }}>Advanced Options</Typography>
                </DialogTitle>
                <DialogContent sx={{ px: 1, display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                  <Box >
                    <Typography fontWeight={600}>What role does this person play in case of serious emergency or demise?</Typography>
                    <Field name="beneficiary_role" component={Select} options={beneficiaryOptions} />
                  </Box>
                  {
                    values.beneficiary_role === 'optional' ?
                      (
                        <Box >
                          <Typography fontWeight={600}>What role does this person play in case of serious emergency or demise?</Typography>
                          <Field name="secondary_beneficiary_role" component={Select} options={beneficiaryOptions} />
                        </Box>
                      )
                      :
                      (<>
                        <Field name="allocation_percentage">
                          {(props: any) => <FormInput
                            label="What percentage of your Pasbanc funds in such a case should be allocated to this person?"
                            type="number"
                            placeholder='e.g 10'
                            {...props}
                            InputProps={{
                              endAdornment: '%'
                            }}
                          />}
                        </Field>
                        <Field name="secondary_beneficiary" component={FormInput} label="Who is the secondary beneficiary of the above mentioned person?" fullWidth />
                      </>
                      )}
                  <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Button title='Cancel' sx={{ mr: 2, backgroundColor: "#FOEBFE", width: '120px' }} onClick={handleAdvancedOptionsToggle} />
                    <Button variant='contained' title='Save' onClick={handleAdvancedOptionsToggle} sx={{ width: '120px' }} />
                  </Box>
                </DialogContent>
              </ModalWrapper>
              {error && <Alert severity='error'>{(error as Error).message}</Alert>}
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Button title='Cancel' sx={{ mr: 2, width: '120px', backgroundColor: "#FOEBFE" }} onClick={onClose} />
                <Button type="submit" variant='contained' title='Save' sx={{ width: '120px' }} />

              </Box>
            </form>
          )}
        </Form>
      </DialogContent>
    </Box>
  );
};

export default NextOfKin;
