import React from "react";
import FlipMove from "react-flip-move";
import TransactionItem from "./TransactionItem";
import { useStyles } from "./transactionStyles";
import { Transaction, TransactionData } from "../../types/types";
import TransactionsSkeleton from "../Reusables/Feedback/skeletons/TransactionsSkeleton";
import { Box, Typography } from "@mui/material";
import { UseInfiniteQueryResult } from '@tanstack/react-query';

interface TransactionListProps {
  transactions: UseInfiniteQueryResult<TransactionData, Error>,
  selectedTransactions?: Transaction[]
  onSelect?: (transaction: Transaction) => void;
  selectMode?: boolean;
  boxHeight?: string;
}

const TransactionList: React.FC<TransactionListProps> = ({ transactions, selectedTransactions, onSelect, selectMode, boxHeight = "40vh" }) => {
  const classes = useStyles();

  if (transactions.isLoading) {
    return <TransactionsSkeleton />;
  }

  if (transactions.isError) {
    return <Box display="flex" justifyContent="center" alignItems="center" height={boxHeight}><Typography variant="body1" color="grey.400" textAlign="center">Error fetching transactions</Typography></Box>;
  }

  
  return (
    <div className={classes.transactionList}>
      <FlipMove>
        {transactions.data.pages.map((page, pageIndex) => (
          <React.Fragment key={pageIndex}>
            {page.transactions.length === 0 ? (
              <Box display="flex" justifyContent="center" alignItems="center" height={boxHeight}><Typography variant="body1" color="grey.400" textAlign="center">No transactions yet</Typography></Box>
            ) : page.transactions.map((transaction: Transaction) => (
              <TransactionItem
                key={transaction.id}
                transaction={transaction as Transaction}
                selectMode={selectMode}
                selected={selectedTransactions?.some((t) => t.id === transaction.id)}
                onSelect={onSelect}
              />
            ))}
          </React.Fragment>
        ))}

      </FlipMove>
    </div>
  );
};



export default TransactionList;
