import authReducer from "./auth";
import goalReducer from "./goalReducer";
import {  combineReducers } from "@reduxjs/toolkit";
import transactionReducer from "./transactionReducer";

//reducers and combine them to form one
export default combineReducers({
  auth: authReducer,
  goals: goalReducer,
  transactions: transactionReducer,
});
