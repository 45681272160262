import { Key, useState } from 'react';
import { Avatar, AvatarGroup, Badge, Box, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, List, ListItem, ListItemAvatar, ListItemText, Radio, RadioGroup, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { StyledTabProps, StyledTabsProps } from '../transactions/TransactionListTabs';
import { styled } from '@mui/material/styles';
import Button from '../../components/Reusables/Input/Button';
import { capitalizeWords, numberWithCommas } from '../../utils/middleware';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { useMemberSelection } from '../../hooks/customHooks';
import { useGoalById, useGoalMembers, useGoalRevolvingHistory, useGoalRevolvingSchedule, useUpdateGoalRevolvingSettings } from '../../hooks/goalHooks';
import { useHistory, useParams } from 'react-router-dom';
import Fuse from 'fuse.js';
import SearchIcon from "@mui/icons-material/Search";
import FormInput from '../../components/Reusables/Input/FormInput';
import { GoalMember } from '../../types/types';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ModalWrapper from '../../components/Modals/ModalWrapper';

export const LightStyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    centered
    variant="fullWidth"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  minHeight: 'unset',
  padding: '4px 4px',
  backgroundColor: '#F6F6F7',
  borderRadius: '8px',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 0,

  },
});

export const LightStyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(16),
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  height: theme.typography.pxToRem(32),
  borderRadius: theme.spacing(0.5),
  minHeight: 'unset',
  minWidth: 'unset',
  color: theme.palette.grey[600],
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: '#fff'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));


const PayoutHistory = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const history = useHistory()


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ px: 2, width: { lg: '450px' } }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => history.goBack()}>
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography sx={{ flex: 1, textAlign: 'center', fontWeight: 600 }}>
          Payout History
        </Typography>

      </Box>
      <LightStyledTabs value={activeTab} onChange={handleTabChange}>
        <LightStyledTab label="Upcoming" />
        <LightStyledTab label="Past" />
      </LightStyledTabs>
      <Button title='Select for payout' sx={{ width: '100%', my: 1 }} variant='contained' onClick={() => setOpen(!open)} />
      {
        open && <AddRecipient
          open={open}
          onClose={() => setOpen(false)}
        />
      }

      {activeTab === 0 && <UpcomingTab />}
      {activeTab === 1 && <PastTab />}
    </Box>
  );
};

export default PayoutHistory;




const UpcomingTab = () => {
  const { id } = useParams<{ id: string }>();
  const { data: upcomingPayouts, isLoading, isError } = useGoalRevolvingSchedule(parseFloat(id));
  return (
    <div>
      {isLoading || isError ?
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
        : upcomingPayouts.length === 0 ?
          <Typography color='textSecondary' sx={{ py: 1 }}>No upcoming payouts. Switch on revolving to get started</Typography>
          : upcomingPayouts.map((payout, index) => (
            <Box key={index} >
              <Typography variant="body2" fontWeight={600} sx={{ py: 1 }}>
                <Typography component='span' color='textSecondary' variant='subtitle2'>Next payout:</Typography>{" "}
                {dayjs(payout.date).format('dddd D MMM YY')}
              </Typography>
              <List>
                {payout.members.map((member, memberIndex) => (
                  <ListItem
                    key={memberIndex}
                    disableGutters
                  >
                    <ListItemAvatar>
                      <Avatar alt={member.name} src={member.image} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }} component='div'>
                        <Typography variant="subtitle1">
                          {capitalizeWords(member.name)}
                        </Typography>
                        <Typography variant="subtitle2">
                          ({member.username})
                        </Typography>
                      </Box>
                      }
                      secondary={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} component='div'>
                          <Typography variant="caption" color="textSecondary">
                            {member.timesReceived}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {`${numberWithCommas(member.amount)} ${member.currency}`}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}

    </div>
  );
};



const PastTab = () => {
  const { id } = useParams<{ id: string }>();

  const { data: pastPayouts, isLoading, isError } = useGoalRevolvingHistory(parseFloat(id));

  return (
    <div>
      {/* Loop through the past payouts and display them in a list */}
      {isLoading || isError ?
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
        : pastPayouts.length === 0 ?
          <Typography color='textSecondary' sx={{ py: 1 }}>No payout history. Switch on revolving to get started</Typography>
          : pastPayouts.map((payout, index) => (
            <Box key={index} p={2} borderBottom="1px solid #ccc">
              <Typography variant="h6">
                {dayjs(payout.date).format('dddd D MMM YY')}
              </Typography>
              <Typography variant="subtitle1">
                {payout.name} {payout.username}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {payout.timesReceived}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {numberWithCommas(payout.amount)}
              </Typography>
            </Box>
          ))}
    </div>
  );
};

interface PayoutMemberSelectionProps {
  open: boolean;
  onClose: () => void;
}

const AddRecipient: React.FC<PayoutMemberSelectionProps> = ({ open, onClose }) => {
  const { id } = useParams<{ id: string }>();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [value, setValue] = useState('');
  const { updateRevolvingSettings, error } = useUpdateGoalRevolvingSettings();
  const { data: goal } = useGoalById(parseFloat(id));



  const { selectedMembers, handleAddMember, handleRemoveMember } = useMemberSelection();
  const { data: goalMembers } = useGoalMembers(parseFloat(id));

  const fuseOptions = {
    keys: ['name'],
    threshold: 0.3,
  };
  const fuse = new Fuse(goalMembers, fuseOptions);

  // Filter group members based on the search query
  const filteredMembers = searchTerm ? fuse.search(searchTerm).map((result) => result.item) : goalMembers;



  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);

  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleAddSelectedMembers = async () => {
    const currentRevolvingOrder = goal.revolving_settings.revolving_order as GoalMember[] || [];

    //this method is selecting goal members to their ids and adding them to the revolving order
    const selectedUserIds = selectedMembers.map((member) => member.id);

    // Filter out duplicates manually
    const updatedRevolvingOrder = currentRevolvingOrder.map((member) => member.id).concat(
      selectedUserIds.filter((userId) => !currentRevolvingOrder.some((member) => member.id === userId))
    );

    await updateRevolvingSettings({ id: parseFloat(id), data: { ...goal.revolving_settings, revolving_order: updatedRevolvingOrder } });
    // Reset the modal state and close the modal
    setSearchTerm('');
    onClose();

  };


  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 0.5 }}>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Select members for payout`}</Typography>
      </DialogTitle>
      <DialogContent>
        {currentStep === 0 && (
          <>
            <FormInput
              placeholder="Find members"
              type="text"
              Icon={<SearchIcon />}
              onChange={handleSearch}
            />
            {selectedMembers.length > 0 && (
              <Box sx={{ mt: 1 }}>
                <Typography>Selected Members</Typography>
                <AvatarGroup max={10} sx={{ flexDirection: 'row' }}>
                  {selectedMembers.map((member: any, index) => (
                    <Badge
                      key={index}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={<IconButton onClick={() => handleRemoveMember(member.id)}>  <CancelOutlinedIcon fontSize="small" /> </IconButton>} >
                      <Avatar alt={member.name} src={member.users.profile_img ?? undefined}
                        sx={{ bgcolor: "primary.main", width: 30, height: 30 }}
                      />
                    </Badge>
                  ))}
                </AvatarGroup>
              </Box>
            )
            }


            <List>
              {filteredMembers.map((member: any, index: Key) => (
                <ListItem
                  key={index}
                  disableGutters
                  onClick={() => handleAddMember(member)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={member.name}
                      src={member.image}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
                      <Typography variant="subtitle1">
                        {capitalizeWords(member.name)}
                      </Typography>
                      <Typography variant="subtitle2">
                        ({member.users.username})
                      </Typography>
                    </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
        {currentStep === 1 && (
          <>
            <Typography>When do you want the selected members to receive their payout?</Typography>
            <RadioGroup value={value}
              onChange={handleChange}>
              <FormControlLabel value="current" control={<Radio />} label="Upcoming payout" />
              <FormControlLabel value="next" control={<Radio />} label="Next upcoming payout" />
            </RadioGroup>
          </>
        )}
        <DialogActions>
          {currentStep === 0 && (
            <>
              <Button onClick={onClose} title='Cancel' />
              <Button onClick={handleNextStep} variant="contained" color="primary" title='Next' />
            </>
          )}
          {currentStep === 1 && (
            <>
              <Button onClick={handlePrevStep} title='Back' />
              <Button onClick={handleAddSelectedMembers} variant="contained" color="primary" title='Submit' />
            </>
          )}
        </DialogActions>
      </DialogContent>
    </ModalWrapper>
  )
}