import paymentrequestsApiClient from '../apis/apiServices/paymentrequests';
import { useMutation,useQueryClient,useQuery } from '@tanstack/react-query';
import { PaymentRequestResponse } from '../types/types';
import { PaymentRequestData } from '../types/actionTypes';






export const useCreatePaymentRequest = () => {
  const queryClient = useQueryClient();

  const createPaymentRequestMutation = useMutation(
    paymentrequestsApiClient.create,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['paymentRequests']);
      },
    }
  );

  const createPaymentRequest = async (requestData:PaymentRequestData) => {
    try {
      await createPaymentRequestMutation.mutateAsync(requestData);
    } catch (error) {
      console.error('Error creating payment request:', error);
      throw new Error('Error creating payment request:');
    }
  };

  return {
    createPaymentRequest,
    loading: createPaymentRequestMutation.isLoading,
    error: createPaymentRequestMutation.error,
  };
};


// Custom hook to get a payment request by ID
export const useGetPaymentRequestById = (paymentRequestId: number) => {
  return useQuery<PaymentRequestResponse,Error>(['paymentRequests', paymentRequestId], () =>
    paymentrequestsApiClient.getById(paymentRequestId)
  );
};
