import { useState } from "react";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useStyles } from "./ProfileStyles";
import { Box, IconButton, Typography, InputAdornment } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { Search, StyledInputBase } from "../goals/SearchAppBar";
import CloseIcon from '@mui/icons-material/Close';

const customBoxStyle = {
  display: 'flex',
  width: `100%`,
  padding: `12px 16px`,
  flexDirection: 'column',
  justifyContent: `center`,
  alignItems: `flex-start`,
  gap: `4px`,
  borderRadius: `16px`,
  background: `#F5F5F5`,
  marginTop: `16px`,
 
  }

function Help() {
  const classes = useStyles();
  const [searchWord, setSearchWord] = useState("");
  const history = useHistory()
  const [showSearch, setShowSearch] = useState(false);

   const handleSearchIconClick = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch);
  };

  const handleShare = async () => {
  const shareData = {
    title: 'Join the best way to create ad manage wealth!',
    text: 'Experience the future of finance. Get started Pasbanc today!',
    url: 'https://my.pasbanc.com/', 
  };

  try {
    // Use the Web Share API to trigger the native share dialog
    await navigator.share(shareData);
    console.log('Shared successfully');
  } catch (error) {
    console.error('Error sharing:', error);
  }
};


  return (
    <Box m={2}>
      <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',mt:2}}>
         <IconButton onClick={()=>history.goBack()} sx={{p:0}}>
            <ArrowBackOutlinedIcon />
         </IconButton>
         <Typography variant="h6" sx={{ 
                flexGrow: 1, 
                fontWeight:600,
                display: { sm: 'block' },
                justifyContent: { xs: 'center', sm: 'initial' },
                textAlign:{xs:'center',sm:"initial"}
             }}>Help center & FAQ</Typography>
         {!showSearch ? (
            <IconButton onClick={handleSearchIconClick} sx={{p:0}}>
              <SearchOutlinedIcon />
            </IconButton>
          ):(
              <Search>
              <IconButton onClick={handleSearchIconClick} sx={{p:0}}>
              <SearchOutlinedIcon />
            </IconButton>
              <StyledInputBase
                placeholder="Search…"
                value={searchWord}
                endAdornment={
                    <InputAdornment position="end">
                      <CloseIcon  onClick={()=>setSearchWord('')}/>
                    </InputAdornment>
                  }
                onChange={(e)=>setSearchWord(e.target.value)}
              />
            </Search>
            )}
        </Box>
        <Box>
          <Box sx={customBoxStyle}>
            <Typography variant ='body2'fontWeight={600} onClick={handleShare}>Share</Typography>
            <Typography>{`Invite your friends, relatives and colleagues`}</Typography>
          </Box>
          <Box component={Link} to={`/profile/contact-us`} sx={customBoxStyle}>
            <Typography variant ='body2'fontWeight={600}>Contact us</Typography>
            <Typography>{`For more inquiries, you can reach out to us here`}</Typography>
          </Box>
        </Box>
     
   </Box>
  );
}

export default Help;
