/**AUTH */
export const SIGN_UP = "SIGN_UP";
export const AUTH_ERROR = "AUTH_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

/**profile */
export const USER_LOADED = "USER_LOADED";
export const KIN_LOADED = "KIN_LOADED";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_BANKS = "GET_BANKS";
export const USER_ERROR = "USER_ERROR";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const LOADING = "LOADING";
export const ADD_NUMBER = "ADD_NUMBER";
export const REMOVE_NUMBER = "REMOVE_NUMBER";
export const ADD_CARD = "ADD_CARD";
export const REMOVE_CARD = "REMOVE_CARD";
export const ADD_BANK_ACCT = "ADD_BANK_ACCT";
export const REMOVE_BANK_ACCT = "REMOVE_BANK_ACCT";
export const GET_METHODS = "GET_METHODS";

/**Goals */
export const GET_TOTAL = "GET_TOTAL";
export const GET_DASHBOARD_GOALS = "GET_DASHBOARD_GOALS";
export const GET_ALLGOALS = "GET_ALLGOALS";
export const EDIT_GOAL = "EDIT_GOAL";
export const CREATE_GOAL = "CREATE_GOAL";
export const GET_GOAL = "GET_GOAL";
export const DELETE_GOAL = "DELETE_GOAL";
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const DELETE_GOAL_SUBSCRIPTION = "DELETE_GOAL_SUBSCRIPTION";
export const GET_GOAL_NAMES = "GET_GOAL_NAMES";
export const GOAL_ERROR = "GOAL_ERROR";
export const UPLOAD_GOAL_IMAGE = "UPLOAD_GOAL_IMAGE";
export const CLEAR_GOAL_ERRORS = "CLEAR_GOAL_ERRORS";
export const UPDATE_GOAL_SUBSCRIPTION_STATUS =
  "UPDATE_GOAL_SUBSCRIPTION_STATUS";
export const UPDATE_GOAL_SUBSCRIPTION = "UPDATE_GOAL_SUBSCRIPTION";
export const SORT_GOALS = "SORT_GOALS";
export const PIN_GOAL = "PIN_GOAL";
export const ADD_GOAL_SUBSCRIPTION = "ADD_GOAL_SUBSCRIPTION";

/**groups */
export const GROUP_ERROR = "GROUP_ERROR";
export const CREATE_GROUP = "CREATE_GROUP";
export const GET_PUBLIC_GROUPS = "GET_PUBLIC_GROUPS";
export const GET_MY_GROUPS = "GET_MY_GROUPS";
export const GET_PUBLIC_GROUP = "GET_PUBLIC_GROUP";
export const UPLOAD_GROUP_IMAGE = "UPLOAD_GROUP_IMAGE";
export const PIN_GROUP = "PIN_GROUP";
export const ADD_GROUP_SUBSCRIPTION = "ADD_GROUP_SUBSCRIPTION";
export const GET_GROUP = "GET_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const GET_MEMBERS = "GET_MEMBERS";
export const SEARCH_USERS = "SEARCH_USERS";
export const EDIT_GROUP = "EDIT_GROUP";
export const JOIN_GROUP = "JOIN_GROUP";
export const LEAVE_GROUP = "LEAVE_GROUP";
export const SORT_GROUPS = "SORT_GROUPS";
export const ADD_MEMBERS = "ADD_MEMBERS";
export const REMOVE_MEMBERS = "REMOVE_MEMBERS";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const UPDATE_GROUP_STATUS = "UPDATE_GROUP_STATUS";
export const UPDATE_PERMISSION_STATUS = "UPDATE_PERMISSION_STATUS";
export const UPDATE_GROUP_SUBSCRIPTION_STATUS =
  "UPDATE_GROUP_SUBSCRIPTION_STATUS";
export const UPDATE_GROUP_SUBSCRIPTION = "UPDATE_GROUP_SUBSCRIPTION";
export const DELETE_GROUP_SUBSCRIPTION = "DELETE_GROUP_SUBSCRIPTION";

/**payments */
export const MAKE_PAYMENT = "MAKE_PAYMENT";
export const GET_PAYMENT_GOAL = "GET_PAYMENT_GOAL";
export const ACTIVATE_SUBSCRIPTION = "ACTIVATE_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const CONVERT_CURRENCY = "CONVERT_CURRENCY";
export const PROCESSING_PAYMENT = "PROCESSING_PAYMENT";
export const PAYMENT_FAILURE = "PAYMENT_FAILURE";
export const ROLLOVER_SAVINGS = "ROLLOVER_SAVINGS";

/**transactions */
export const GET_DASHBOARD_TRANSACTIONS = "GET_DASHBOARD_TRANSACTIONS";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const SEARCH_TRANSACTIONS = "SEARCH_TRANSACTIONS";
export const GET_TRANSACTION = "GET_TRANSACTION";
export const TRANSACTION_ERROR = "TRANSACTION_ERROR";

/**withdrawals */
export const TRANSFERS_LOADING = "TRANSFERS_LOADING";
export const MOBILE_WITHDRAW = "MOBILE_WITHDRAW";
export const BANK_WITHDRAW = "BANK_WITHDRAW";
export const GOAL_WITHDRAW = "GOAL_WITHDRAW";
