import React, { useState } from "react";
import Button from "../../components/Reusables/Input/Button";
import FormInput from "../../components/Reusables/Input/FormInput";
import { useStyles } from "./loginStyles";
import { Form, Field, } from "react-final-form";
import {  useHistory } from "react-router";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, Typography ,Box, Avatar} from "@mui/material";
import Toast from "../../components/Reusables/Feedback/Toast";
import Alert from '@mui/material/Alert';
import { useResetPIN } from '../../hooks/authHooks';
import { ForgetPassword } from "./ResetPassword";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface ResetPINFormValues {
  email:string;
  otp:string;
  phoneNumber:string;
  pin: string;
  confirmPIN: string;
}


function ResetPIN() {
  const { resetPINByEmail,resetPINByPhoneNumber,loading } = useResetPIN();
  const steps = ['Forget PIN','Reset PIN'];
  const history = useHistory()

  const [activeStep, setActiveStep] = useState(0);

  const [open, setOpen] = useState(false);

 
  const validate = (values: ResetPINFormValues) => {
   
    const errors: Partial<ResetPINFormValues> = {};
     if(activeStep === 0){
      
      if(!values.otp){
        errors.otp ='Required'
      }
    }


    if(activeStep === 1){
    if (!values.pin) {
      errors.pin = "required";
    }
    if (!values.confirmPIN) {
      errors.confirmPIN= "required";
    }
   
    if (values.confirmPIN !== values.pin) {
      errors.confirmPIN = "Pins must match";
    }
  }
    return errors;
  };
 
  const handleNext = async(values:ResetPINFormValues) => {
     if(activeStep === 1){
     try {
      const ref = localStorage.getItem('ref')
      if(values.email && !ref){
        await resetPINByEmail({pin:values.pin,otp:values.otp,email:values.email})
         setOpen(true)
          history.push('/auth/login/email')
      }else if(values.phoneNumber && ref){
        await resetPINByPhoneNumber({
          pin:values.pin,
          phonenumber:values.phoneNumber,
          reference:ref,
          otp:values.otp
        })
         setOpen(true)
         history.push('/auth/login/mobile')
      }
      
     } catch (error) {
      
     }
     
    }else if(activeStep < steps.length ){
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box className={classes.logoContainer}>
      <Avatar alt="Pasbanc" src="/logo.png" />
      <Typography variant='h5'>Pasbanc</Typography>
      </Box>
     
      <Form
        onSubmit={handleNext}
        validate={validate}
        render={({ handleSubmit, submitting,values }) => (
          <form className={classes.resetContainer} onSubmit={handleSubmit}>
          {activeStep === 0 && <ForgetPassword  values={values} />}
           {activeStep === 1 && <Reset handleBack={handleBack}/>}
            <Box mt={3} textAlign={'center'}>
            <Button
              title="Next"
              color="primary"
              variant="contained"
              type="submit"
              disabled={submitting || loading}
              sx={{width:'200px'}}
            />
            </Box>
            
          </form>
        )}
      />

      <Toast
        type="success"
        message="PIN reset Successfully"
        open={open}
        handleClose={() => setOpen(!open)}
      />
    </div>
  );
}



export default ResetPIN;


const Reset=({handleBack}:{handleBack:any})=>{
  const [showPassword, setPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);

      const classes = useStyles();

  return (
    <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',textAlign:'left',gap:'20px'}}>
     <Box sx={{alignSelf:'baseline'}}>
       <IconButton onClick={handleBack}><ArrowBackIcon /></IconButton>
      </Box>
      <Box sx={{textAlign:'center'}}>
  <Typography variant="h3">Set your PIN</Typography>
      <Typography variant='body1' sx={{maxWidth:'414px'}}>Enter a PIN code you will use to login and transact while in your Pasbanc account</Typography>
      </Box>
    
      <Box className={classes.customForm}>
      <Field name="pin">
       {(props: any) => <FormInput
        label="PIN"
        type={showPassword ? "text" : "password"}
         Icon={
         <IconButton
           aria-label="toggle password visibility"
           onClick={() => setPassword(!showPassword)}
           size="large">
          {showPassword ? <Visibility /> : <VisibilityOff />}
           </IconButton>
             }
             
                {...props}
              />}
            </Field>

            <Field name="confirmPIN">
              {(props: any) => <FormInput
                label="Confirm PIN"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                position="end"
                Icon={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setConfirmPassword(!showConfirmPassword)}
                    size="large">
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
                {...props}
              />}
              
            </Field>
            </Box>
    </Box>
  )
}