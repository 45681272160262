import { Box, Typography, useMediaQuery, Theme, IconButton, List, ListItemText, ListItemSecondaryAction, ListItem, Chip, MenuItem, Select as MuiSelect, Drawer, DialogContent, DialogTitle, ImageListItem, Alert, DialogActions, InputAdornment, ImageListItemBar, ImageList, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/Reusables/Input/Button';
import ApplyBusinessLoan from './ApplyBusinessLoan';
import ApplySalaryLoan, { DocumentFormValues, salaryDocumentTypes } from './ApplySalaryLoan';
import { FormApi } from 'final-form';
import { Form, Field } from 'react-final-form';
import Toast from '../../components/Reusables/Feedback/Toast';
import FormInput from '../../components/Reusables/Input/FormInput';
import { businessCategoryOptions, businessDurationOptions, businessLoanTypeOptions, countries, document_types, employmentStatusOptions } from '../../components/Reusables/Input/select/Options';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Select from '../../components/Reusables/Input/select/Select';
import { Document, Page, pdfjs } from 'react-pdf';
import { useBusinessInfo, useCreateLegalOwner, useCreateRevenue, useDeleteLegalOwner, useEmployeeInfo, useLoanApply } from '../../hooks/loanHooks';
import dayjs from 'dayjs';
import { LightStyledTabs, LightStyledTab } from '../goals/PayoutHistory';
import { numberWithCommas, removeCommas, telephoneCheck } from '../../utils/middleware';
import { useCreateKycDocument, useDeleteDocumentById, useUserDocuments } from '../../hooks/userHooks';
import { Business, KYCDocument } from '../../types/types';
import Modal from '../../components/Modals/Modal';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RadioButtons from '../../components/Reusables/Input/RadioGroup';
import AddIcon from '@mui/icons-material/Add';
import ModalWrapper from '../../components/Modals/ModalWrapper';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const LoanProfile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { data: businesses, isLoading } = useBusinessInfo();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ px: 2, width: { lg: '450px' } }}>
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, mt: 2 }}>
        {!isDesktop && (
          <IconButton onClick={() => history.push(`/loans`)} sx={{ p: 0 }}>
            <ArrowBackOutlinedIcon />
          </IconButton>
        )}

        <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
          Loan Profiles
        </Typography>
      </Box>
      <LightStyledTabs value={activeTab} onChange={handleTabChange} sx={{ mt: 3 }}>
        <LightStyledTab label="Businesses" />
        <LightStyledTab label="Salaried employee" />
      </LightStyledTabs>
      {activeTab === 0 && businesses ? (
        <BusinessProfile businesses={businesses} />
      ) : isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><CircularProgress /></Box>
      ) : null}
      {activeTab === 1 && <SalaryProfile />}
    </Box>
  );
};

const BusinessProfile: React.FC<{ businesses: Business[] }> = ({ businesses }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const history = useHistory()
  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const [addDocument, setAddDocument] = useState(false)
  const [openView, setOpenView] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openAddRevenue, setOpenRevenue] = useState(false)
  const [addOwner, setAddOwner] = useState(false)
  const { deleteLegalOwner } = useDeleteLegalOwner();

  const [selectedDocument, setSelectedDocument] = useState<KYCDocument>()
  // Sample data for the profile


  const [selectedBusiness, setSelectedBusiness] = useState((businesses ?? [])[0]);

  const handleBusinessSelect = (event: any) => {
    const selectedBusinessName = event.target.value as string;
    const selectedBusiness = (businesses ?? []).find((business) => business.business_name === selectedBusinessName);
    if (selectedBusiness) {
      setSelectedBusiness(selectedBusiness);
    }
  };

  const handleViewFile = (document: KYCDocument) => {
    setSelectedDocument(document)
    setOpenView(!openView)
  }

  const handleDelete = (document: KYCDocument) => {
    setSelectedDocument(document)
    setOpenDelete(!openDelete)
  }



  const handleDeleteOwner = async (owner: { name: string }) => {
    try {
      await deleteLegalOwner({
        businessId: selectedBusiness.id,
        name: owner.name
      })
    } catch (error) {
      console.log(error)
    }
  }




  return (
    <Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Typography sx={{ fontWeigh: 600 }}>Verified businesses</Typography>
        <Button title='Add new business' onClick={() => isDesktop ? setIsBusinessOpen(true) : history.push(`/loans/apply/business`)} sx={{ fontSize: 'small' }} />
      </Box>


      <Box sx={{ mt: 2 }}>
        <Typography>Selected business:</Typography>
        <MuiSelect
          value={selectedBusiness.business_name}
          onChange={handleBusinessSelect}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-input": {
              padding: "8px",
            },
          }}
        >
          {businesses.map((business) => (
            <MenuItem key={business.business_name} value={business.business_name}>
              {business.business_name}
            </MenuItem>
          ))}
        </MuiSelect>

      </Box>


      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Typography fontWeight={600}>Legal business owners</Typography>
        <Button title='Add new' onClick={() => setAddOwner(!addOwner)} />
      </Box>
      <List dense>
        {selectedBusiness.data.legalBusinessOwners.map((owner, index) => (
          <ListItem key={index} disableGutters disablePadding>
            <ListItemText primary={owner.name} secondary={owner.phoneNumber} />
            <ListItemSecondaryAction sx={{ display: 'flex', gap: 2 }} onClick={() => handleDeleteOwner(owner)}>
              <Typography variant='body2'>{`${owner.ownership}%  ownership`}</Typography>
              <CloseIcon fontSize='small' />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>


      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Typography fontWeight={600} mt={2}>Business revenue</Typography>
        <Button title='Add new' onClick={() => setOpenRevenue(!openAddRevenue)} />
      </Box>
      <List dense>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>{`Month of ${dayjs(selectedBusiness.data.businessRevenue.month).format('MMM')}`}</Typography>
          <Typography>{`${numberWithCommas(selectedBusiness.data.businessRevenue.revenue)} UGX`}</Typography>
        </Box>
      </List>




      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Typography fontWeight={600}>Financial records</Typography>
        <Button title='Add new' onClick={() => setAddDocument(!addDocument)} />
      </Box>


      <List sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        {selectedBusiness.data.documents.map((record, index) => (
          <Box key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 600 }}>{record.document_name}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant='body2' sx={{ textDecoration: 'underline' }} onClick={() => handleViewFile(record)}>View file</Typography>
                <IconButton onClick={() => handleDelete(record)}>
                  <CloseIcon fontSize='medium' color='error' />
                </IconButton>
                <DocumentView open={openView} onClose={() => setOpenView(!openView)} document={selectedDocument} />
                <DocumentView open={openView} onClose={() => setOpenView(!openView)} document={selectedDocument} />
                <DeleteDocument open={openDelete} onClose={() => setOpenDelete(!openDelete)} document={selectedDocument} />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Category</Typography>
              {record.additional_details && record.additional_details.map((detail, index) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={index}>
                  <Typography variant='body2'>{detail.value}</Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Last Modified</Typography>
              <Typography variant='body2'>{dayjs(record.created_at).format('DD MMM YYYY')}</Typography>
              <Chip label={record.verification_status}
                sx={{
                  backgroundColor:
                    record.verification_status === 'valid' ? '#E4F7E8' :
                      record.verification_status === 'expired' ? '#FFEAED' :
                        record.verification_status === 'rejected' ? '#FFF3E0' :
                          '', // Default background color when none of the conditions match
                  color:
                    record.verification_status === 'valid' ? '#00703C' :
                      record.verification_status === 'expired' ? '#B7000C' :
                        record.verification_status === 'rejected' ? '#EF6C00' :
                          '', // Default text color when none of the conditions match

                }}
              />
            </Box>
          </Box>
        ))}
      </List>

      <Typography sx={{ fontWeight: 600, mt: 2 }}>Business location</Typography>
      <Typography>{selectedBusiness.data.businessLocation}</Typography>
      <Drawer anchor="right" open={isBusinessOpen && isDesktop} onClose={() => setIsBusinessOpen(false)} sx={{ '& .MuiPaper-root.MuiDrawer-paper': { width: '400px' } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ApplyBusinessLoan />
        </Box>
      </Drawer>
      <AddFinancialDocuments open={addDocument} onClose={() => setAddDocument(!addDocument)} businessId={selectedBusiness.id} />
      <ModalWrapper open={addOwner} onClose={() => setAddOwner(!addOwner)}>
        <DialogContent>
          <LegalOwners business={selectedBusiness} onClose={() => setAddOwner(!addOwner)} />
        </DialogContent>
      </ModalWrapper>
      <ModalWrapper open={openAddRevenue} onClose={() => setOpenRevenue(!openAddRevenue)}>
        <DialogContent>
          <AddRevenue business={selectedBusiness} onClose={() => setOpenRevenue(!openAddRevenue)} />
        </DialogContent>
      </ModalWrapper>
    </Box>
  );
};

const SalaryProfile = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const history = useHistory()
  const [isSalaryOpen, setIsSalaryOpen] = useState(false);
  const [addDocument, setAddDocument] = useState(false)
  const [openView, setOpenView] = useState(false)
  const { data, isError, isLoading } = useEmployeeInfo();
  const [selectedDocument, setSelectedDocument] = useState<KYCDocument>()
  const [openDelete, setOpenDelete] = useState(false)


  const handleViewFile = (document: KYCDocument) => {
    setSelectedDocument(document)
    setOpenView(!openView)
  }
  const handleDelete = (document: KYCDocument) => {
    setSelectedDocument(document)
    setOpenDelete(!openDelete)
  }


  // Sample data for the profile


  if (isLoading || isError) {
    // Display a loading message or spinner while data is being fetched
    return <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><CircularProgress /></Box>;
  }



  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Typography sx={{ fontWeigh: 600 }}>Verified employers</Typography>
        <Button title='Replace employer' onClick={() => isDesktop ? setIsSalaryOpen(true) : history.push(`/loans/new-employer`)} sx={{ fontSize: 'small' }} />
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography>Employer name:</Typography>
        <Typography sx={{ background: '#F0EBF8', textTransform: 'uppercase', p: 1, color: '#482980', fontWeight: 600, width: 'fit-content', borderRadius: 1 }}>{data.employer.name}</Typography>
      </Box>
      {data.employer.location && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Typography >Business Location</Typography>
        <Typography>{data.employer.location}</Typography>
      </Box>}
      {data.employer.contact && <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mt: 2 }}>
        <Typography >Business Contact</Typography>
        <Box>
          <Typography>{data.employer.contact}</Typography>
          <Typography>{data.employer.email}</Typography>
          <Typography>{data.employer.mailing_address}</Typography>
        </Box>
      </Box>}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Typography fontWeight={600}>Financial records</Typography>
        <Button title='Add new' onClick={() => setAddDocument(!addDocument)} />
      </Box>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        {data.documents.map((record, index) => (
          <Box key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 600 }}>{record.document_name}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant='body2' sx={{ textDecoration: 'underline' }} onClick={() => handleViewFile(record)}>View file</Typography>
                <IconButton onClick={() => handleDelete(record)}>
                  <CloseIcon fontSize='medium' color='error' />
                </IconButton>

                <DocumentView open={openView} onClose={() => setOpenView(!openView)} document={selectedDocument} />
                <DeleteDocument open={openDelete} onClose={() => setOpenDelete(!openDelete)} document={selectedDocument} />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Category</Typography>
              {record.additional_details && record.additional_details.map((detail, index) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={index}>
                  <Typography variant='body2'>{detail.value}</Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Last Modified</Typography>
              <Typography variant='body2'>{dayjs(record.created_at).format('ddd DD MMM, YYYY')}</Typography>
              <Chip label={record.verification_status}
                sx={{
                  backgroundColor:
                    record.verification_status === 'valid' ? '#E4F7E8' :
                      record.verification_status === 'expired' ? '#FFEAED' :
                        record.verification_status === 'rejected' ? '#FFF3E0' :
                          '', // Default background color when none of the conditions match
                  color:
                    record.verification_status === 'valid' ? '#00703C' :
                      record.verification_status === 'expired' ? '#B7000C' :
                        record.verification_status === 'rejected' ? '#EF6C00' :
                          '', // Default text color when none of the conditions match

                }}
              />
            </Box>
          </Box>
        ))}
      </List>





      <Drawer anchor="right" open={isSalaryOpen && isDesktop} onClose={() =>
        setIsSalaryOpen(false)} sx={{ '& .MuiPaper-root.MuiDrawer-paper': { width: '400px' } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ApplySalaryLoan />
        </Box>
      </Drawer>
      <AddSalaryDocuments open={addDocument} onClose={() => setAddDocument(!addDocument)} />
    </Box>
  );
};

export default LoanProfile;


const AddFinancialDocuments: React.FC<{ open: boolean; onClose: any; businessId: number; }> = ({ open, onClose, businessId }) => {
  const { createKyc, error, isLoading } = useCreateKycDocument();
  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };





  const [uploadToast, setUploadToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "upload") {
      setUploadToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const validate = (values: DocumentFormValues) => {
    const errors: Partial<DocumentFormValues> = {};
    if (!values.document_name) {
      errors.document_name = 'Document name is required';
    }
    if (!values.frontImage) {
      errors.frontImage = 'Front Image is required';
    }


    return errors;
  };

  const onSubmit = async (values: DocumentFormValues, form: FormApi<DocumentFormValues, Partial<DocumentFormValues>>) => {
    try {

      const formData = new FormData();
      formData.append('name', values.document_name);
      formData.append('type', document_types[6]);
      formData.append('documents', values.frontImage);
      formData.append('business', businessId.toString());

      const additionalDetails = [
        { field: 'name', value: values.type },
      ];
      const additionalDetailsString = JSON.stringify(additionalDetails);
      formData.append('additional_details', additionalDetailsString);
      await createKyc(formData);

      form.reset()
      onClose()
      // Document created successfully, you can perform any further actions here
    } catch (error) {

    }

  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Add new financial record`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <Form onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Which financial record do you have?</Typography>
                  <Field name="document_name" component={Select} fullWidth options={businessLoanTypeOptions} />
                </Box>
                <Field
                  name="type"
                  label="Type the name of the document"
                  fullWidth
                  component={FormInput}
                />
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset', mt: 1 }}>Take a photo of the selected document.Use clear photos</Typography>
                  <Field name="frontImage">
                    {({ input, meta }) => (
                      <>
                        <Box>
                          <input
                            type="file"
                            accept="image/*,.pdf" // Allow both images and PDFs
                            id="frontPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);

                            }}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="frontPhotoUpload">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("front")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                          {values.frontImage && (

                            <ImageListItem
                              sx={{ borderRadius: 8, objectFit: 'cover', border: '2px dashed #F6F6F7' }}
                            >
                              {(values.frontImage instanceof File && values.frontImage.type.includes('pdf')) || (typeof values.frontImage === 'string' && values.frontImage.endsWith('.pdf')) ? (
                                <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                                  <Document
                                    file={values.frontImage instanceof File ? URL.createObjectURL(values.frontImage) : values.frontImage}

                                  >
                                    <Page pageNumber={1} width={200} height={1} />
                                  </Document>
                                </div>
                              ) : (
                                // Render image
                                <img
                                  src={values.frontImage instanceof File ? URL.createObjectURL(values.frontImage) : values.frontImage}
                                  alt="Profile Image"
                                  style={{ objectFit: 'contain', width: '200px' }}
                                />
                              )}
                            </ImageListItem>
                          )}
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>


                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Button color="primary" title='Cancel' sx={{ mr: 2, width: '120px' }} onClick={onClose} />
                  <Button variant="contained" color="primary" type="submit" title='Save' loading={submitting || isLoading} sx={{ width: '120px' }} disabled={isLoading} />
                </Box>
              </Box>
            </form>
          )}
        </Form>
        <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
      </DialogContent>
    </ModalWrapper>
  );
}

const DocumentView: React.FC<{ document?: KYCDocument; open: boolean; onClose: any; }> = ({ document, open, onClose, }) => {
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 600 }}>Document View
        <CloseIcon sx={{ float: 'right' }} onClick={onClose} /></DialogTitle>
      <DialogContent>
        <ImageListItem
          sx={{ borderRadius: 8, objectFit: 'cover', border: '2px dashed #F6F6F7', mt: 1 }}
        >
          {(document && typeof document.document_url[0].url === 'string' && document.document_url[0].url.endsWith('.pdf')) ? (
            <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
              <Document
                file={document.document_url[0].url}
              >
                <Page pageNumber={1} width={200} height={1} />
              </Document>
            </div>
          ) : document ? (
            // Render image
            <img
              src={document.document_url[0].url}
              alt="Profile Image"
              style={{ objectFit: 'contain', width: '100%', overflow: 'hidden' }}
            />
          ) : <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><CircularProgress /></Box>}
        </ImageListItem>
      </DialogContent>
    </ModalWrapper>
  )
}

const AddSalaryDocuments: React.FC<{ open: boolean; onClose: any; }> = ({ open, onClose, }) => {
  const { createKyc, error, isLoading } = useCreateKycDocument();
  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };





  const [uploadToast, setUploadToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "upload") {
      setUploadToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const validate = (values: DocumentFormValues) => {
    const errors: Partial<DocumentFormValues> = {};
    if (!values.document_name) {
      errors.document_name = 'Document name is required';
    }
    if (!values.frontImage) {
      errors.frontImage = 'Front Image is required';
    }


    return errors;
  };

  const onSubmit = async (values: DocumentFormValues, form: FormApi<DocumentFormValues, Partial<DocumentFormValues>>) => {
    try {

      const formData = new FormData();
      formData.append('name', values.document_name);
      formData.append('type', document_types[6]);
      formData.append('documents', values.frontImage);

      const additionalDetails = [
        { field: 'name', value: values.type },
      ];
      const additionalDetailsString = JSON.stringify(additionalDetails);
      formData.append('additional_details', additionalDetailsString);
      await createKyc(formData);



      form.reset()
      onClose()
      // Document created successfully, you can perform any further actions here
    } catch (error) {

    }

  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Add new financial record`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <Form onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Which financial record do you have?</Typography>
                  <Field name="document_name" component={Select} fullWidth options={salaryDocumentTypes} />
                </Box>
                <Field
                  name="type"
                  label="Type the name of the document"
                  fullWidth
                  component={FormInput}
                />
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset', mt: 1 }}>Take a photo of the selected document.Use clear photos</Typography>
                  <Field name="frontImage">
                    {({ input, meta }) => (
                      <>
                        <Box>
                          <input
                            type="file"
                            accept="image/*,.pdf" // Allow both images and PDFs
                            id="frontPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);

                            }}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="frontPhotoUpload">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("front")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                          {values.frontImage && (

                            <ImageListItem
                              sx={{ borderRadius: 8, objectFit: 'cover', border: '2px dashed #F6F6F7' }}
                            >
                              {(values.frontImage instanceof File && values.frontImage.type.includes('pdf')) || (typeof values.frontImage === 'string' && values.frontImage.endsWith('.pdf')) ? (
                                <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                                  <Document
                                    file={values.frontImage instanceof File ? URL.createObjectURL(values.frontImage) : values.frontImage}

                                  >
                                    <Page pageNumber={1} width={200} height={1} />
                                  </Document>
                                </div>
                              ) : (
                                // Render image
                                <img
                                  src={values.frontImage instanceof File ? URL.createObjectURL(values.frontImage) : values.frontImage}
                                  alt="Profile Image"
                                  style={{ objectFit: 'contain', width: '200px' }}
                                />
                              )}
                            </ImageListItem>
                          )}
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>


                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Button color="primary" title='Cancel' sx={{ mr: 2, width: '120px' }} onClick={onClose} />
                  <Button variant="contained" color="primary" type="submit" title='Save' loading={submitting || isLoading} sx={{ width: '120px' }} disabled={isLoading} />
                </Box>
              </Box>
            </form>
          )}
        </Form>
        <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
      </DialogContent>
    </ModalWrapper>
  );
}

export const DeleteDocument: React.FC<{ document?: KYCDocument; open: boolean; onClose: any; }> = ({ document, open, onClose, }) => {
  const { deleteDocument, error, isLoading } = useDeleteDocumentById();

  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };

  const handleDeleteDocument = async () => {
    try {
      if (document) {
        // Call the deleteDocument function from the hook
        await deleteDocument(document.id);
        setUpdateToast({
          open: true,
          message: "Document deleted",
          type: "success",
        });
      }
    } catch (error: any) {
      setUpdateToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  return (

    <Modal title="Delete Document" open={open} handleClose={onClose} sx={{ padding: { lg: '28px 55px' } }}>
      <form >
        <Typography textAlign={'center'}>Are you sure you want to delete this document</Typography>

        {error && <Alert severity="error">{(error as Error).message}</Alert>}

        <DialogActions>
          <Button
            title="Delete"
            variant="contained"
            color="error"
            onClick={handleDeleteDocument}
            disabled={isLoading}
            loading={isLoading}
          />
          <Button title="Cancel" onClick={onClose} color='inherit' />
        </DialogActions>

      </form>
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />
    </Modal>


  );
}

interface EmployeeFormValues {
  business_type: string;
  employer_name: string;
  country: string;
  employer_location: string;
  employer_contact: string;
  employer_email: string;
  employment_status: string;
  current_job: string;
  monthly_revenue: string;
  job_duration: string;
  mailing_address: string;
}


export const ReplaceEmployer = () => {
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0);
  const { data: documents, refetch } = useUserDocuments({ document_type: document_types[5] })
  const { deleteDocument } = useDeleteDocumentById();


  const [openDocument, setOpenDocument] = useState(false)
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };
  const handleDeleteDocument = async (documentId: number) => {
    try {
      // Call the deleteDocument function from the hook
      await deleteDocument(documentId);
      setUpdateToast({
        open: true,
        message: "Document deleted",
        type: "success",
      });
    } catch (error: any) {
      setUpdateToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  const { loanApply, loading, error } = useLoanApply();




  const validate = (values: EmployeeFormValues) => {
    const errors: Partial<EmployeeFormValues> = {};

    if (activeStep === 0) {
      if (!values.business_type) {
        errors.business_type = 'required'
      }
      if (!values.employer_name) {
        errors.employer_name = 'required'
      }
      if (!values.employer_location) {
        errors.employer_location = 'required'
      }
      if (!values.employer_contact) {
        errors.employer_contact = 'required'
      }
      if (!values.employment_status) {
        errors.employment_status = 'required'
      }
      if (!values.current_job) {
        errors.current_job = 'required'
      }
      if (!values.current_job) {
        errors.current_job = 'required'
      }

      if (!values.monthly_revenue) {
        errors.monthly_revenue = 'required'
      }
      if (!values.job_duration) {
        errors.job_duration = 'required'
      }

    }

    return errors;
  };

  const data = localStorage.getItem('salaryValues') ? JSON.parse(localStorage.getItem('salaryValues') as string) : undefined;

  const handleNext = async (values: EmployeeFormValues, form: any) => {
    localStorage.setItem('salaryValues', JSON.stringify(values));
    if (activeStep === 1) {
      try {
        await loanApply({
          business_type: values.business_type,
          employer: {
            name: values.employer_name,
            country: values.country,
            location: values.employer_location,
            contact: values.employer_contact,
            mailing_address: values.mailing_address,
            email: values.employer_email
          },
          employment_status: values.employment_status,
          current_job: values.current_job,
          monthly_revenue: parseFloat(values.monthly_revenue),
          job_duration: values.job_duration,
        }, 'salary');


        form.restart();
        localStorage.removeItem('salaryValues')
        history.push(`/loans/profile`)

      } catch (error) {
        console.log(error)
      }

    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ px: 2 }}>
      <Form
        onSubmit={handleNext}
        validate={validate}
        initialValues={{
          business_type: data?.business_type,
          employer_name: data?.employer_name,
          employment_status: data?.employment_status,
          current_job: data?.current_job,
          monthly_revenue: data?.monthly_revenue,
          job_duration: data?.job_duration,
          country: data?.country,
          mailing_address: data?.mailing_address,
          employer_email: data?.employer_email,
          employer_location: data?.employer_location,
          employer_contact: data?.employer_contact

        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} >

            {activeStep === 0 &&
              <>
                <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, my: 2, }}>

                  <IconButton onClick={() => history.push(`/loans/profile`)} sx={{ p: 0 }}>
                    <ArrowBackOutlinedIcon />
                  </IconButton>
                  <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
                    Add new employer
                  </Typography>
                </Box>
                <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
                  <Box>
                    <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}>What is your industry?</Typography>
                    <Field
                      type="select"
                      name="business_type"
                      component={Select}
                      options={businessCategoryOptions}
                    />
                  </Box>
                  <Field
                    label="What is registered name of your employer?"
                    name="employer_name"
                    type="text"
                    component={FormInput}
                  />
                  <Box>
                    <Typography fontWeight={600}>Select the country of work?</Typography>
                    <Field name="country" component={Select} fullWidth options={countries} />
                  </Box>
                  <Field
                    label="Add office location or business address?"
                    name="employer_location"
                    type="text"
                    component={FormInput}
                  />
                  <Field
                    label="Their phone number"
                    name="employer_contact"
                    type="text"
                    component={FormInput}
                  />
                  <Field
                    label="Employer email address"
                    name="employer_email"
                    type="text"
                    component={FormInput}
                  />
                  <Field
                    label="P.O Box mailing address"
                    name="mailing_address"
                    type="text"
                    component={FormInput}
                  />
                  <Box>
                    <Typography fontWeight={600} >What is your employment status?</Typography>
                    <Field
                      type="select"
                      name="employment_status"
                      component={Select}
                      options={employmentStatusOptions}
                    />
                  </Box>

                  <Field
                    label="What is your current job title?"
                    name="current_job"
                    type="text"
                    component={FormInput}
                  />

                  <Field name="monthly_revenue">
                    {(props: any) => <FormInput
                      label="How much do you earn every month?"
                      name="monthly_revenue"
                      type='text'
                      value={numberWithCommas(props.input.value)}
                      onChange={(e) => {
                        props.input.onChange(removeCommas(e.target.value));
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">UGX</InputAdornment>,
                      }}
                      {...props}
                    />}
                  </Field>
                  <Box>
                    <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}> For how long have you worked for your current employer? </Typography>
                    <Field
                      name="job_duration"
                      row={false}
                      component={RadioButtons}
                      options={businessDurationOptions}
                    />
                  </Box>
                </Box>
              </>}



            {activeStep === 1 && (<Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
              <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, my: 2, }}>

                <IconButton onClick={handleBack} sx={{ p: 0 }}>
                  <ArrowBackOutlinedIcon />
                </IconButton>
                <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
                  Add financial record
                </Typography>
              </Box>

              <Typography>Kindly upload all the required legal documents(3 documents i.e current employer contract, current bank statement, recent utility bill)</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight={600}>Upload document</Typography>
                <Button title='Upload' variant='outlined' startIcon={<AddIcon />} onClick={() => setOpenDocument(!openDocument)} />
              </Box>
              {documents && (
                <ImageList sx={{ width: 500, height: 'auto' }} cols={4} gap={0}>
                  {documents.map((document: KYCDocument, i: React.Key) => (
                    <ImageListItem key={i} sx={{ width: 91, height: 80, borderRadius: 8, objectFit: 'cover', border: `0.5px solid  #F6F6F7` }}>
                      <ImageListItemBar
                        sx={{ background: 'transparent' }}
                        position="top"
                        actionIcon={
                          <IconButton
                            sx={{ padding: '0px', display: 'block', color: 'red' }}
                            aria-label={`close ${document.document_name}`}
                            onClick={() => handleDeleteDocument(document.id)}
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                        actionPosition="left"
                      />
                      <img
                        srcSet={`${document.document_url[0].url}`}
                        src={`${document.document_url[0].url}`}
                        alt={`transaction attached document`}
                        style={{ objectFit: 'cover' }}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}

                </ImageList>
              )}

              {documents && documents?.length < 3 && <Typography variant='caption' color='error'>Please upload all documents</Typography>}
              <Box sx={{ mt: 1.5 }}>
                <Typography variant='body2' sx={{ fontWeight: 600, textDecoration: 'underline' }}>Uploaded documents</Typography>
                <Box>
                  {documents?.map((document) => document.document_name).join(', ')}
                </Box>
              </Box>

              <AddSalaryDocuments open={openDocument} onClose={() => {
                setOpenDocument(!openDocument);
                refetch()
              }} />
              <Toast {...updateToast} handleClose={() => handleCloseToast()} />

            </Box>)}
            {error && <Alert severity="error">{(error as Error).message}</Alert>}
            <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Button title={activeStep === 1 ? 'SAVE' : 'NEXT'} type='submit' variant='contained' endIcon={<ArrowForwardIcon />} disabled={loading} loading={loading} />
            </Box>

          </form>
        )}
      />
    </Box>
  )
}

export const LegalOwners: React.FC<{ business: Business, onClose: any }> = ({ business, onClose }) => {

  const { createLegalOwner, isLoading: loading, error } = useCreateLegalOwner();

  // Use the deleteLegalOwner hook
  const { deleteLegalOwner } = useDeleteLegalOwner();

  const handleDeleteOwner = async (owner: { name: string }) => {
    try {
      await deleteLegalOwner({
        businessId: business.id,
        name: owner.name
      })
    } catch (error) {
      console.log(error)
    }
  }

  const history = useHistory()

  const validate = (values: { name: string; phoneNumber: string; ownership: string; }) => {
    const errors: { name?: string; phoneNumber?: string; ownership?: string; } = {};


    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    }
    if (!telephoneCheck(values.phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phonenumber";
    }
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.ownership) {
      errors.ownership = "Required";
    }

    return errors;
  };

  const handleSubmit = async (values: { name: string; phoneNumber: string; ownership: string }) => {
    try {
      await createLegalOwner({
        name: values.name,
        phoneNumber: values.phoneNumber,
        ownership: values.ownership,
        businessId: business.id
      });
      onClose()

      // Handle success (e.g., show a success message)
    } catch (error) {
      console.log('error creating legal owner')
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}

      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} >
          <Box sx={{ display: 'flex', alignItems: 'start', gap: 2, my: 2, }}>
            <IconButton onClick={() => history.push(`/loans/profile`)} sx={{ p: 0 }}>
              <ArrowBackOutlinedIcon />
            </IconButton>
            <Typography variant="body1" fontWeight={600} sx={{ m: '0 auto' }}>
              Add the other legal business owner details
            </Typography>
          </Box>
          <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>

            <Field
              label="Full name"
              name="name"
              type="text"
              component={FormInput}
            />

            <Field
              label="Percentage of ownership"
              name="ownership"
              type="number"
              component={FormInput}
            />


            <Field
              label="Contact"
              name="phoneNumber"
              type="text"
              component={FormInput}
            />
            <Box>
              <Typography fontWeight={600}>Business owners info</Typography>
              <List dense>
                {business.data.legalBusinessOwners.map((owner, index) => (
                  <ListItem key={index} disableGutters disablePadding>
                    <ListItemText primary={owner.name} secondary={owner.phoneNumber} />
                    <ListItemSecondaryAction sx={{ display: 'flex', gap: 2 }} onClick={() => handleDeleteOwner(owner)}>
                      <Typography variant='body2'>{`${owner.ownership}% ownership`}</Typography>
                      <CloseIcon fontSize='small' />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>

            </Box>
          </Box>


          {error && <Alert severity="error">{(error as Error).message}</Alert>}
          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title={'SAVE'} type='submit' variant='contained' endIcon={<ArrowForwardIcon />} disabled={loading} loading={loading} />
          </Box>

        </form>
      )}
    />
  )
}


export const AddRevenue: React.FC<{ business: Business, onClose: any }> = ({ business, onClose }) => {
  const { createRevenue, isLoading: loading, error } = useCreateRevenue();
  const history = useHistory()

  const validate = (values: {
    this_week_revenue: string;
    last_week_revenue: string; last_month_revenue: string;
  }) => {
    const errors: { this_week_revenue?: string; last_week_revenue?: string; last_month_revenue?: string; } = {};


    if (!values.this_week_revenue) {
      errors.this_week_revenue = "Required";
    }

    if (!values.last_week_revenue) {
      errors.last_week_revenue = "Required";
    }
    if (!values.last_month_revenue) {
      errors.last_month_revenue = "Required";
    }

    return errors;
  };

  const handleSubmit = async (values: {
    this_week_revenue: string;
    last_week_revenue: string; last_month_revenue: string;
  }) => {
    try {
      await createRevenue({
        this_week_revenue: parseFloat(values.this_week_revenue),
        last_week_revenue: parseFloat(values.last_week_revenue),
        last_month_revenue: parseFloat(values.last_month_revenue),
        businessId: business.id
      });
      onClose()

      // Handle success (e.g., show a success message)
    } catch (error) {
      console.log('error creating legal owner')
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}

      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} >

          <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, my: 2, }}>

            <IconButton onClick={() => history.push(`/loans/profile`)} sx={{ p: 0 }}>
              <ArrowBackOutlinedIcon />
            </IconButton>
            <Typography variant="h6" fontWeight={600} sx={{ m: '0 auto' }}>
              Add the other legal business owner details
            </Typography>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
            <Typography fontWeight={600} textAlign={'center'}>Great! How about your revenue?</Typography>
            <Field name="this_week_revenue">
              {(props: any) => <FormInput
                label="How much in sales/revenue did you make this week?"
                name="this_week_revenue"
                type="text"
                value={numberWithCommas(props.input.value)}
                onChange={(e) => {
                  props.input.onChange(removeCommas(e.target.value));
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">UGX</InputAdornment>,
                }}
                {...props}
              />}
            </Field>
            <Field name="last_week_revenue">
              {(props: any) => <FormInput
                label="How much in sales/revenue did you make last week?"
                name="last_week_revenue"
                type="text"
                value={numberWithCommas(props.input.value)}
                onChange={(e) => {
                  props.input.onChange(removeCommas(e.target.value));
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">UGX</InputAdornment>,
                }}
                {...props}
              />}
            </Field>

            <Field name="last_month_revenue">
              {(props: any) => <FormInput
                label="How much in sales/revenue did you make last month?"
                name="last_month_revenue"
                type="text"
                value={numberWithCommas(props.input.value)}
                onChange={(e) => {
                  props.input.onChange(removeCommas(e.target.value));
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">UGX</InputAdornment>,
                }}
                {...props}
              />}
            </Field>


          </Box>


          {error && <Alert severity="error">{(error as Error).message}</Alert>}
          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title={'SAVE'} type='submit' variant='contained' endIcon={<ArrowForwardIcon />} disabled={loading} loading={loading} />
          </Box>

        </form>
      )}
    />
  )
}