import { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { Box, useMediaQuery } from "@mui/material";
import ErrorBoundary from "../../components/Reusables/ErrorBoundary";
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import { useHistory } from "react-router-dom";
import { Theme, styled } from '@mui/material/styles';
import { SavingFilter } from "../../components/goals/SavingFilter";
import SearchAppBar from "./SearchAppBar";
import { savingsFilters } from "../../components/Reusables/Input/select/Options";
import Goals from "../../components/goals/Goals";
import Explore from "../../components/groups/Explore";
import { CreateGoal } from "../create/CreateGoal";
import { CreatePocket } from "../create/CreatePocket";

export interface Action {
  icon: React.ReactNode;
  name: string;
  link: string;
  component?: React.ReactNode;
}

const actions: Action[] = [
  {
    icon: <ElectricBoltOutlinedIcon />,
    name: "Create a pocket",
    link: "/pockets/create",
    component: <CreatePocket />,
  },
  {
    icon: <LockOutlinedIcon />,
    name: "Create a goal",
    link: "/goals/create",
    component: <CreateGoal />,
  },

];

export const CustomSpeedDialAction = styled(SpeedDialAction)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiSvgIcon-root': {
    fill: 'primary.main'
  }
}));

export const CustomSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(8),
  right: theme.spacing(2),
  //above the backdrop
  zIndex: theme.zIndex.drawer + 2,
  '& .MuiSpeedDial-actions .MuiSpeedDialAction-staticTooltip .MuiSpeedDialAction-staticTooltipLabel': {
    width: 'max-content',
    color: '#fff',
    background: 'transparent',
    boxShadow: 'none',
    fontSize: theme.typography.pxToRem(18),
  }
}));


const Savings = () => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const [searchWord, setSearchWord] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");


  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
  };

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleClick = (link: string) => {
    setOpen(false);
    history.push(link)
  };

  /**when the user wants to join an existing group we take them directly to explore tab of value 2,we use the term in the url which is existing */
  const [tab, setTab] = useState(0);
  /**as the client changes the tab set the search term to an empty string */
  const handleValue = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    setSearchWord("");
  };
  /**helps to notify us if user is typing */
  const handleSearch = useCallback((e) => {
    setIsTyping(true);
    setSearchWord(e.target.value);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTyping(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchWord]);



  return (
    <motion.div
      initial={{ opacity: 0.8 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <SearchAppBar
        handleChange={handleSearch}
        searchValue={searchWord}
        clearSearch={() => setSearchWord("")}
        handleTabChange={handleValue}
        activeTab={tab}
      />
      <Box mt={3} >
        <ErrorBoundary>
          <ErrorBoundary>
            {tab === 0 && (
              <>
                <Box sx={{ m: `8px auto`, }}>
                  <SavingFilter filters={savingsFilters} handleFilterChange={handleFilterChange} selected={selectedFilter} />
                </Box>
                <Goals
                  isTyping={isTyping}
                  searchTerm={searchWord}
                  searchFilter={selectedFilter}
                />
              </>
            )}
          </ErrorBoundary>

          <ErrorBoundary>
            {tab === 1 && (
              <Explore
                isTyping={isTyping}
                searchTerm={searchWord}
              />
            )}
          </ErrorBoundary>


        </ErrorBoundary>
      </Box>

      <Backdrop
        open={open}
        sx={{
          background: `rgba(0, 0, 0, 0.89)`,
          zIndex: (theme) => theme.zIndex.drawer + 1
        }} />
      <CustomSpeedDial
        ariaLabel="create account speedial"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
      >
        {actions.map((action) => (
          <CustomSpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action.link)}
            tooltipOpen
          />
        ))}
      </CustomSpeedDial>

    </motion.div>
  );
};


export default Savings;
