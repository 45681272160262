import { VerifyCardData, addCardData,PaymentMethodData } from "../../types/actionTypes";
import { createIdempotenceKey } from "../../utils/middleware";
import api from "../api";
import { PAYMENT_METHODS_ENDPOINT } from "../apiEndpoints";
const timeframeInMinutes = 10; // Set the timeframe to an hour

const paymentMethodsApiClient = {
  // Retrieve a list of all blog posts
  getAll: async () => {
    const response = await api.get(`${PAYMENT_METHODS_ENDPOINT}`);
    return response.data.methods;
  },
  getBanks: async () => {
    const response = await api.get(`${PAYMENT_METHODS_ENDPOINT}/banks`);
    return response.data.banks;
  },
  // Create a new payment nethod
  create: async (data:PaymentMethodData) => {
    try {
    const response = await api.post(`${PAYMENT_METHODS_ENDPOINT}/add`, data);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },


  updateById: async (id:number) => {
    try {
    const response = await api.put(`${PAYMENT_METHODS_ENDPOINT}/${id}`);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
   
  },

  // Delete an existing blog post by ID
  deleteById: async (id:number) => {
    try {
       await api.delete(`${PAYMENT_METHODS_ENDPOINT}/${id}`);
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
   
  },
  verifyCard: async (data:VerifyCardData) => {
    try {
     const idempotenceKey = createIdempotenceKey(data, timeframeInMinutes);

    const config = {
      headers: {
        "x-idempotence-key": idempotenceKey,
      },
    };
    const response = await api.post(
      `${PAYMENT_METHODS_ENDPOINT}/verify-card`,
      data,
      config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  addCard: async (data:addCardData) => {
    try {
    const response = await api.post(`${PAYMENT_METHODS_ENDPOINT}/add-card`,data);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
    throw error.response.data
      }
    }
    
  },
};

export default paymentMethodsApiClient;
