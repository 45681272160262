import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "0px 16px",
    paddingBottom: "16px",
    display: "flex",
    justifyContent: "center",
  },
  goalsContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  myGoals: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    justifyContent: "center",
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      width: "400px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      flexDirection: "row",
      flexWrap: "wrap",
      width: "unset",
    },
    [`@media (max-width: ${theme.breakpoints.values.xs}px)`]: {
      px: "16px"
    },
  },
  groupContainer: {
    background: "#fff",
    width: "100%",
    maxWidth: "23.375rem",
    border: "1px solid #E2E8F0",
    boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
    padding: "12px",
    marginTop: "16px",
    cursor: "pointer",
    "& :hover": {
      opacity: 0.95,
    },
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      /**leave some room for spacing so we dont go to 50% */
      flexBasis: "45%",
      minWidth: "300px",
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
      /**leave some room for spacing so we dont go to 50% */
      flexBasis: "45%",
    },
    [`@media (max-width: ${theme.breakpoints.values.xs}px)`]: {
      minWidth: "none",
      width: "100%",
    },
  },
}));
