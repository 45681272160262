import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: `#F1F2F3`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: `#5CD07C`,
  },
}));


interface ProgressProps {
  value: number;
  maxValue: number;
}


const Progress:React.FC<ProgressProps> = ({ value,  maxValue }) => {
  const MIN = 0; // Minimum expected value (0% progress)
  const MAX = maxValue; // Maximum expected value (100% progress)
  const normalise = (value: number) => {
    const normalizedValue = Math.min(Math.max(value, MIN), MAX); // Ensure value is within MIN and MAX range
    return ((normalizedValue - MIN) * 100) / (MAX - MIN);
  };

  const progress = normalise(value);


  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

export default Progress;
