import axios from "axios";
import { createNextOfKINData, documentData, editUserData, LoginWithEmail, LoginWithPhone, SignupWithEmail, SignupWithPhone, updatePasswordData, updatePINData, witnessData } from "../../types/actionTypes";
import { KYCDocument, SecurityQuestion } from "../../types/types";
import api from "../api";
import { USERS_ENDPOINT } from "../apiEndpoints";

const userApiClient = {
  getEmail: async (data: { email: string }) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/getEmail`, data);
      console.log(response)
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }
  },

  verifyEmail: async (data: { otp: string, email: string }) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/verifyEmail`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }
  },

  getPhoneNumber: async (data: { phonenumber: string }) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/getPhoneNumber`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response?.data;
      }
    }
  },
  verifyPhoneNumber: async (data: { reference: string, otp: string }) => {
    try {
      const response = await api.post(
        `${USERS_ENDPOINT}/verifyPhoneNumber`,
        data
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }
  },
  // Retrieve a specific user by ID
  getUser: async () => {
    try {
      const response = await api.get(`${USERS_ENDPOINT}/user`);
      return response.data.user;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }
  },
  getSocialConnections: async () => {
    try {
      const response = await api.get(`${USERS_ENDPOINT}/social`);
      return response.data.socialConnections;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }
  },
  getUserDocuments: async (data: Partial<KYCDocument>) => {
    try {
      const response = await api.put(`${USERS_ENDPOINT}/documents`, data);
      return response.data.documents;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }
  },
  getQuestions: async () => {
    try {
      const response = await api.get(`${USERS_ENDPOINT}/questions`);
      return response.data.questions;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }
  },

  loginByEmail: async (user: LoginWithEmail) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/signin/email`, user);
      return response.data;
    } catch (error: any) {
      // Handle the error here
      if (error.response && error.response.data) {
        throw error.response?.data;
      }
    }
  },
  loginByPhone: async (user: LoginWithPhone) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/signin/phone`, user);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }
  },

  // Create a user by email
  createUserByEmail: async (data: SignupWithEmail) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/signup/email`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }
  },

  // Create a user by phone Number
  createUserByPhone: async (data: SignupWithPhone) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/signup/phone`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }
  },

  updateUser: async (data: editUserData) => {
    try {
      const response = await api.put(`${USERS_ENDPOINT}/updateUser`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  uploadProfileImg: async (data: FormData) => {
    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const response = await api.post(
        `${USERS_ENDPOINT}/uploadImage`,
        data,
        config
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  forgetPassword: async (data: { email?: string, phonenumber?: string }) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/forgetPassword`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }

    }

  },
  resetPassword: async (data: { password: string, otp: string, email: string }) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/resetPassword`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }

    }

  },
  resetPIN: async (data: { pin: string, otp: string, email: string }) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/resetPin`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  resetPasswordPhone: async (data: { password: string, phonenumber: string, reference: string, otp: string }) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/resetPassword/phone`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  resetPINPhone: async (data: { pin: string, phonenumber: string, reference: string, otp: string }) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/resetPin/phone`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  updatePassword: async (data: updatePasswordData) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/updatePassword`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }

    }

  },
  updatePin: async (data: updatePINData) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/updatePin`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }

    }

  },
  searchUsers: async (data: { searchterm: string }) => {
    const response = await api.post(`${USERS_ENDPOINT}/search`, data);
    return response.data.users;
  },
  getKins: async () => {
    const response = await api.get(`${USERS_ENDPOINT}/kins`);
    return response.data.kins;
  },
  getWitnesses: async () => {
    const response = await api.get(`${USERS_ENDPOINT}/witness`);
    return response.data.witnesses;
  },
  createNextOfKin: async (data: createNextOfKINData) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/kin`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }

    }

  },
  createKycDocument: async (data: FormData, onProgress: any) => {

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (progressEvent: any) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          onProgress(progress);
        },
      };
      const response = await api.post(`${USERS_ENDPOINT}/documents`, data, config);
      console.log('response: ' + response.data)
      return response.data?.document;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response?.data;
      } else {
        throw error
      }
    }

  },
  uploadDocument: async (data: FormData, onUploadProgress: (progressEvent: ProgressEvent) => void) => {
    console.log('calling endpoint upload')

    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/upload`,
        data,
        {

          headers: {
            'Content-Type': 'multipart/form-data',


          },

          onUploadProgress, // Pass the progress callback
          withCredentials: false
        },
      );
      console.log(response)
      if (response && response.data) {
        const image = response.data;

        return image;
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  },
  createWitness: async (data: witnessData) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/witness`, data);
      return response.data.witness;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  deleteWitness: async (id: number) => {
    try {
      await api.delete(`${USERS_ENDPOINT}/witness/${id}`);
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  deleteNextOfKin: async (id: number) => {
    try {
      await api.delete(`${USERS_ENDPOINT}/kin/${id}`);
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  deleteDocument: async (id: number) => {
    try {
      await api.delete(`${USERS_ENDPOINT}/documents/${id}`);
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  deleteUser: async (data: { pin: string, password: string }) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/delete`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  deleteSocialAccount: async (id: number) => {
    try {
      const response = await api.delete(`${USERS_ENDPOINT}/social/${id}`);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  updateSecurityQuestions: async (data: SecurityQuestion[]) => {
    try {
      const response = await api.put(`${USERS_ENDPOINT}/questions`, { security_questions: data });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
  addSocialConnection: async (data: { platform: string, profileName: string }) => {
    try {
      const response = await api.post(`${USERS_ENDPOINT}/social`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
    }

  },
};

export default userApiClient;
