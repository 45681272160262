import { Balance, Contact } from "../../../types/types";
import { Box, Typography, Avatar } from "@mui/material";
import { numberWithCommas, removeCommas } from "../../../utils/middleware";
import FormInput from "../../Reusables/Input/FormInput";
import { Field } from "react-final-form";

export interface PaymentDetailsStepProps {
    selectedMember: Contact;
    balance: Balance;

}

const PaymentDetailsStep: React.FC<PaymentDetailsStepProps> = ({ selectedMember, balance }) => {
    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                {selectedMember && <Avatar alt={selectedMember.username} src={selectedMember.users?.profile_img} sx={{ bgcolor: 'primary.main', width: 60, height: 60, marginRight: 1 }}>{selectedMember.name.charAt(0)}</Avatar>}

                <Typography fontWeight={600}>{selectedMember.name}</Typography>
                <Typography variant='body2' sx={{ color: 'grey.600' }}>{(selectedMember.username ? selectedMember.username : selectedMember.users?.username)}</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Field name="amount" data-testid="amount">
                    {(props: any) => <FormInput
                        label="Amount"
                        type="text"
                        name="amount"
                        variant='standard' InputProps={{
                            endAdornment: `${balance.currency}`
                        }}
                        value={numberWithCommas(props.input.value)}
                        onChange={(e) => {
                            props.input.onChange(removeCommas(e.target.value));

                        }}
                        {...props}
                    />}
                </Field>

                <Typography sx={{ textAlign: 'center', mt: 0.5 }}>Available:
                    <Typography component='span' fontWeight={600}>{numberWithCommas(balance.amount)}</Typography>
                    <Typography variant="overline" component='span'>{` ${balance.currency}`}</Typography>
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography fontWeight={600}>Reason
                    <Typography component='span' sx={{ color: 'grey.600' }}>{`(optional)`}</Typography>
                </Typography>
                <Field name='description' component={FormInput} />
            </Box>
        </Box>
    )
}

export default PaymentDetailsStep