import { Typography, Box, IconButton } from '@mui/material';
import { Form, Field } from 'react-final-form';
import FormInput from '../../components/Reusables/Input/FormInput';
import { useSecurityQuestions, useUpdateSecurityQuestions } from '../../hooks/userHooks';
import { SecurityQuestion } from '../../types/types';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Reusables/Input/Button';

function SecurityQuestions() {
  const { data: securityQuestions, isLoading, isError } = useSecurityQuestions();
  const { updateSecurityQuestions, isLoading: isUpdating } = useUpdateSecurityQuestions();
  const history = useHistory();

  


  const handleFormSubmit = async (values:any) => {
    const formattedQuestions = [];
    const keys = Object.keys(values);
    let currentQuestionId = 1; 


    for (let i = 0; i < keys.length; i += 2) {
      const question = values[keys[i]];
      const currentAnswer = values[keys[i + 1]];

      const answer = currentAnswer !== '' ? currentAnswer :  keys[i+1];


      formattedQuestions.push({ question, answer ,id:currentQuestionId});
      currentQuestionId++;
    }
    console.log(formattedQuestions)
   
    try {
      await updateSecurityQuestions(formattedQuestions);
    } catch (error) {
      // Handle error, e.g., show an error message
    }
  };

    const placeholderQuestionCount = Math.max(3 - (securityQuestions?.length || 0), 0);

  

  return (
    <Box p={2} sx={{maxWidth:'600px',m:'0 auto'}}>
      <Box sx={{display:'flex',alignItems:'start',gap:1,mt:2}}>
         <IconButton onClick={()=>history.goBack()} sx={{p:0}}>
            <ArrowBackOutlinedIcon />
         </IconButton>
          <Typography variant="h6" fontWeight={600} sx={{m:'0 auto'}}>
          Security Questions
        </Typography>
         </Box> 
      {isLoading ? (
        <Typography>Loading security questions...</Typography>
      ) : isError ? (
        <Typography>Error loading security questions.</Typography>
      ) : (
        <Form
          initialValues={{
          // Transform the array of security questions into an object
          ...securityQuestions.reduce((acc: { [x: string]: any; }, question: {  id: number; question: string ; answer: string ; }, index: number) => {
            acc[question.question] = question.question;
            acc[question.answer] = '';
            return acc;
          }, {})
        }}
          onSubmit={handleFormSubmit}
          render={({ handleSubmit,values }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{display:'flex',flexDirection:'column',gap:4,mt:2}}>
                {securityQuestions.map((question:SecurityQuestion, index:number) => (
                  <Box  key={index} >
                      <Field
                        name={`${question.question}`}
                        component={FormInput}
                        label={`Question ${index + 1}`}
                        fullWidth
                        variant="standard"
                        sx={{mb:1.2}}
                      />
                      <Field
                        name={`${question.answer}`}
                        component={FormInput}
                        label="Answer"
                        fullWidth
                      />
                  </Box>
                ))}
                {/* Render placeholder questions */}
                {Array.from({ length: placeholderQuestionCount }).map((_, index) => (
                  <Box key={`placeholder-${index}`}>
                   <Field
                    name={`Placeholder Question ${index + 1}`}
                    component={FormInput}
                    label="Placeholder Question"
                    placeholder='What is your favorite book?'
                    fullWidth
                     sx={{mb:1.2}}
                  />
                    <Field
                      name={`Placeholder Answer ${index + 1}`}
                      component={FormInput}
                      label="Placeholder Answer"
                      placeholder='Harry Potter'
                      fullWidth
                    />
                  </Box>
                ))}
              
              <Button
                variant="contained"
                color="primary"
                type="submit"
                loading={isUpdating}
                disabled={isUpdating}
                title= {isUpdating ? 'Saving...' : 'Save Changes'}
                sx={{mt:3}}
              />
              </Box>
            </form>
            
          )}
        />
      )}
    </Box>
  );
}

export default SecurityQuestions;
