import { Alert, Avatar, Box, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useStyles } from "../loginStyles";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import FormInput from "../../../components/Reusables/Input/FormInput";
import Button from "../../../components/Reusables/Input/Button";
import { Field, Form } from 'react-final-form';
import CloseIcon from '@mui/icons-material/Close';
import { telephoneCheck } from "../../../utils/middleware";

const BusinessOwners: React.FC<{ handleBack: any, input: any, meta: any; loading: boolean; error: any; }> = ({ handleBack, input }) => {
    const classes = useStyles();
    const [owners, setOwners] = useState<{ name: string; phoneNumber: string; ownership: number }[]>(
        Array.isArray(input.value) ? input.value : []
    );

    const handleDeleteOwner = async (owner: { name: string }) => {
        const newOwners = owners.filter((item) => item.name !== owner.name);
        setOwners(newOwners);
        input.onChange(newOwners);
    }

    const handleAddOwner = async (values: { name: string; phoneNumber: string; ownership: string }, form: any) => {
        const newOwner = { name: values.name, phoneNumber: values.phoneNumber, ownership: parseInt(values.ownership) }
        // Check if the owner is already selected based on their ID

        const isAlreadySelected = owners.some((selectedMember) => selectedMember.name === newOwner.name);

        if (!isAlreadySelected) {
            setOwners((prevSelectedMembers) => [
                ...prevSelectedMembers,
                newOwner
            ]);

            input.onChange([...input.value, newOwner]);
            form.restart()

        }
    }

    const validate = (values: { name: string; phoneNumber: string; ownership: string; }) => {
        const errors: { name?: string; phoneNumber?: string; ownership?: string; } = {};


        if (!values.phoneNumber) {
            errors.phoneNumber = "Required";
        }
        if (!telephoneCheck(values.phoneNumber)) {
            errors.phoneNumber = "Please enter a valid phonenumber";
        }
        if (!values.name) {
            errors.name = "Required";
        }
        if (!values.ownership) {
            errors.ownership = "Required";
        }

        return errors;
    };
    return (
        <Box className={classes.formContainer}>
            <Box maxWidth={"450px"} width="100%">
                <Typography variant="h4">Add the other legal business owners details</Typography>
            </Box>
            <Form
                onSubmit={handleAddOwner}
                validate={validate}
                render={({ handleSubmit, values, form }) => (
                    <form onSubmit={handleSubmit} className={classes.customForm}>
                        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>

                            <Box sx={{ textAlign: 'left', width: '100%' }}>
                                <Field
                                    label="Full name"
                                    name="name"
                                    type="text"
                                    component={FormInput}
                                />
                            </Box>

                            <Box sx={{ textAlign: 'left', width: '100%' }}>
                                <Field
                                    label="Percentage of ownership"
                                    name="ownership"
                                    type="number"
                                    component={FormInput}
                                />
                            </Box>

                            <Box sx={{ textAlign: 'left', width: '100%' }}>
                                <Field
                                    label="Contact"
                                    name="phoneNumber"
                                    type="text"
                                    component={FormInput}
                                />
                            </Box>

                            <Button variant="outlined" title="Add owner" style={{ width: "50%", margin: "0 auto" }} onClick={handleSubmit} />

                            {owners && owners.length > 0 && <Box>
                                <Typography fontWeight={600} sx={{ textAlign: 'left' }}>Business owners info</Typography>
                                <List dense>
                                    {owners.map((owner, index) => (
                                        <ListItem key={index} disableGutters disablePadding>
                                            <ListItemText primary={owner.name} secondary={owner.phoneNumber} />
                                            <ListItemSecondaryAction sx={{ display: 'flex', gap: 2, cursor: "pointer" }} onClick={() => handleDeleteOwner(owner)}>
                                                <Typography variant='body2'>{`${owner.ownership}% ownership`}</Typography>
                                                <CloseIcon fontSize='small' />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>

                            </Box>}
                        </Box>


                    </form>
                )}
            />
            <Box mt={3} className={classes.formBtnContainer}>
                <Button title='Back' variant='outlined' style={{ width: "49%" }} onClick={handleBack} />
                <Button title='Continue' type='submit' variant='contained' style={{ width: "49%" }} />
            </Box>
        </Box>

    );
};

export default BusinessOwners;