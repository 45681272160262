import { Box, Checkbox, Typography } from "@mui/material";
import { useStyles } from "../loginStyles";
import FormInput from "../../../components/Reusables/Input/FormInput";
import Button from "../../../components/Reusables/Input/Button";
import { Field, } from 'react-final-form';
import RadioButtons from "../../../components/Reusables/Input/RadioGroup";
import { numberWithCommas, removeCommas } from "../../../utils/middleware";
import DurationOptionsList from "../../../components/Reusables/Display/DurationOptionList";
import { durationOptions, savingFrequencyOptions } from "../../../components/Reusables/Input/select/Options";
import theme from '../../../theme';
import { useState } from "react";

const InvestmentPlan: React.FC<{ handleBack: any; loading: boolean }> = ({ handleBack, loading }) => {
    const classes = useStyles();
    const [agreed, setAgreed] = useState(false)

    return (
        <div className={classes.container}>
            <Box className={classes.formContainer}>
                <Box maxWidth={"450px"} width="100%">
                    <Typography variant="h4">Lastly, tell us about your investment plan</Typography>
                </Box>

                <Box sx={{ textAlign: 'left', width: '100%' }}>
                    <Typography variant="body1" fontWeight={600}>How much do you plan to invest?</Typography>
                    <Field name="targetAmount">
                        {(props: any) => <FormInput
                            label=""
                            type="text"
                            value={numberWithCommas(props.input.value)}
                            onChange={(e) => {
                                props.input.onChange(removeCommas(e.target.value));

                            }}
                            {...props}
                        />}
                    </Field>
                </Box>

                <Box sx={{ textAlign: 'left', width: '100%' }}>
                    <Typography variant='body1' fontWeight={600}
                        sx={{ marginBottom: '4px' }}>For how long do you want to lock up the funds?</Typography>
                    <Field name="durationOption">
                        {({ input, meta }) => (
                            <DurationOptionsList
                                options={durationOptions.slice(0, -1)}
                                value={input.value}
                                onChange={input.onChange}
                                meta={meta}
                                customStyles={{
                                    [theme.breakpoints.up("sm")]: {
                                        display: "grid",
                                        gridTemplateColumns: "1fr 1fr 1fr",
                                        gap: "1rem"
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        display: 'flex', flexWrap: 'wrap', gap: "16px", width: "100%", justifyContent: "flex-start"
                                    }
                                }}
                            />
                        )}
                    </Field>
                </Box>

                <Box width="100%" marginTop="1rem" display="flex" flexDirection={"column"} gap="0.5rem" textAlign={"left"}>
                    <Typography variant="body1" fontWeight={600} sx={{ marginBottom: "4px" }}>Select saving frequency</Typography>
                    <Field name="frequency" type="radio" initialValue={'onetime'}>
                        {(props: any) => (
                            <RadioButtons
                                options={[savingFrequencyOptions[1]]}
                                onChange={(e) => {
                                    props.input.onChange(e.target.value);
                                }}
                                {...props}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    gap: "0.5rem"
                                }}
                                labelPlacement={'start'}
                                labelStyles={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%"
                                }}
                            />

                        )}
                    </Field>
                </Box>



                <Box sx={{ textAlign: 'left', width: '100%' }}>
                    <Checkbox
                        checked={agreed}
                        onChange={() => setAgreed(!agreed)}
                        size="small" sx={{ p: 0 }}
                    />
                    <Typography
                        variant="caption"
                        sx={{
                            textAlign: 'center', marginLeft: "4px"
                        }}
                    >
                        By clicking submit, you agree to Pasbanc terms and conditions
                    </Typography>
                </Box>

                <Box mt={3} className={classes.formBtnContainer}>
                    <Button title='Back' variant='outlined' style={{ width: "49%" }} onClick={handleBack} />
                    <Button title='Submit' type='submit' variant='contained' style={{ width: "49%" }} disabled={!agreed || loading} loading={loading} />
                </Box>

            </Box>
        </div>
    )
}

export default InvestmentPlan;