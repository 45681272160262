import { Avatar, Box, Step, Stepper, Typography, Slide, InputAdornment, DialogContent, List, ListItem, IconButton, ListItemText, Alert, DialogTitle, FormHelperText, ImageList, ImageListItem, ImageListItemBar, useMediaQuery, Theme, Drawer, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form';
import Button from '../../components/Reusables/Input/Button';
import FormInput from '../../components/Reusables/Input/FormInput';
import RadioButtons from '../../components/Reusables/Input/RadioGroup';
import { businessCategoryOptions, businessDurationOptions, businessLoanTypeOptions, document_types, loanTypeOptions, relationShipOptions, yesOrNoOptions } from '../../components/Reusables/Input/select/Options';
import Select from '../../components/Reusables/Input/select/Select';
import { QontoConnector } from '../profile/DeleteAccount';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CheckboxList from '../../components/Reusables/Input/CheckboxList';
import { useHistory } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VerifiedIcon from '@mui/icons-material/Verified';
import { KYCDocument, Witness, } from '../../types/types';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useCreateKycDocument, useCreateWitness, useDeleteDocumentById, useFetchWitnesses, useUserDocuments } from '../../hooks/userHooks';
import Modal from '../../components/Modals/Modal';
import { numberWithCommas, removeCommas, telephoneCheck } from '../../utils/middleware';
import AddIcon from '@mui/icons-material/Add';
import { FormApi } from 'final-form';
import Toast from '../../components/Reusables/Feedback/Toast';
import ApplySalaryLoan, { DocumentFormValues } from './ApplySalaryLoan';
import CloseIcon from '@mui/icons-material/Close';
import { useLoanApply } from '../../hooks/loanHooks';
import { Document, Page, pdfjs } from 'react-pdf';
import { WitnessFormValues } from '../profile/KYC';
import ModalWrapper from '../../components/Modals/ModalWrapper';



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



//Step 1:about business/Business info
//Step 2: revenure
//Step 3:verify legal details
//Step 4:loan witnesses
interface BusinessFormValues {
  type: string;
  business_type: string;
  trade_type: string;
  business_duration: string;
  business_name: string;
  location: string;
  this_week_revenue: string;
  last_week_revenue: string;
  last_month_revenue: string;
  have_national_id: boolean | string;
  have_phonenumber: boolean | string;
  phoneNumber: string;
  business_documents: string[] | string;
  other_document: string;
  nationalFront: File | string;
  nationalBack: File | string;
  profileImage: File | string;
  TIN: string;
  witnesses: number[] | string;
  loan_amount: string;
  documents: KYCDocument[];

}

const steps = ['', 'Business info', 'Revenue', 'Legal details', 'Loan witnesses'];
const ApplyBusinessLoan = () => {
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0);
  const [openApply, setOpenApply] = useState(false)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [isSalaryOpen, setIsSalaryOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const { data: governmentalID } = useUserDocuments({ document_name: 'National ID', document_type: 'government' })
  const { data: profileID } = useUserDocuments({ document_name: 'Facial Profile' })




  const { loanApply, loading, error } = useLoanApply();

  const validate = (values: BusinessFormValues) => {
    const errors: Partial<BusinessFormValues> = {};
    if (activeStep === 0) {
      if (!values.type) {
        errors.type = "Required";
      }
    }
    if (activeStep === 1) {
      if (!values.business_type) {
        errors.business_type = 'required'
      }
      if (values.business_type === 'trading' && !values.trade_type) {
        errors.trade_type = 'required'
      }
      if (!values.business_duration) {
        errors.business_duration = 'required'
      }
      if (!values.location) {
        errors.location = 'required'
      }
      if (!values.business_name) {
        errors.business_name = 'required'
      }
    }

    if (activeStep == 2) {
      if (!values.this_week_revenue) {
        errors.this_week_revenue = 'required'
      }
      if (!values.last_week_revenue) {
        errors.last_week_revenue = 'required'
      }
      if (!values.last_month_revenue) {
        errors.last_month_revenue = 'required'
      }

    }

    if (activeStep === 3) {
      if (!values.have_national_id) {
        errors.have_national_id = 'required'
      }
      if (values.have_national_id === 'yes' && !values.have_phonenumber) {
        errors.have_phonenumber = 'required'
      }
      if (values.have_phonenumber === 'yes' && !values.phoneNumber) {
        errors.phoneNumber = 'required'
      }
      if (values.have_national_id === 'yes' && !values.nationalFront) {
        errors.nationalFront = 'required'
      }
      if (values.have_national_id === 'yes' && !values.nationalBack) {
        errors.nationalBack = 'required'
      }
      if ((values.nationalFront && typeof values.nationalFront) !== (typeof values.nationalBack) && values.nationalBack) {
        errors.nationalBack = 'Please reupload both images'
      }
      if (!values.profileImage) {
        errors.profileImage = 'required'
      }
      if (!values.business_documents || values.business_documents.length === 0) {
        errors.business_documents = 'required'
      }
      if (values.business_documents && values.business_documents.includes('other') && !values.other_document) {
        errors.other_document = 'required'
      }
      if (values.business_documents && values.business_documents.includes('Tax Identification Number') && !values.TIN) {
        errors.TIN = 'required'
      }
    }

    if (activeStep === 4) {
      if (!values.witnesses || values.witnesses.length < 2) {
        errors.witnesses = 'Minimum of 2 people'
      }

    }


    return errors;
  };
  const data = localStorage.getItem('formValues') ? JSON.parse(localStorage.getItem('formValues') as string) : undefined;

  const handleNext = async (values: BusinessFormValues, form: any) => {

    localStorage.setItem('formValues', JSON.stringify(values));
    if (activeStep === 4) {
      try {
        if (openApply === true) {
          await loanApply({
            business_type: values.business_type,
            trade_type: values.trade_type,
            business_name: values.business_name,
            business_duration: values.business_duration,
            location: values.location,
            this_week_revenue: parseFloat(values.this_week_revenue),
            last_week_revenue: parseFloat(values.last_week_revenue),
            last_month_revenue: parseFloat(values.last_month_revenue),
            phone_number: values.phoneNumber,
            business_documents: values.business_documents as string[],
            other_document: values.other_document,
            TIN: values.TIN,
            witnesses: values.witnesses as number[],
            loan_amount: parseFloat(values.loan_amount),
            documents: values.documents,
            owners: []
          }, 'business');

          setOpenSuccess(true)
          form.restart()
          localStorage.removeItem('formValues')
        } else {
          setOpenApply(true)
        }

      } catch (error) {
        console.log(error)
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  };
  const handleBack = () => {
    if (openApply) {
      setOpenApply(false)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };






  return (
    <Box sx={{ px: 2 }}>
      <Form
        onSubmit={handleNext}
        validate={validate}
        initialValues={{
          nationalFront: governmentalID && governmentalID[0] ? governmentalID[0].document_url[0].url : undefined,
          nationalBack: governmentalID && governmentalID[0] && governmentalID[0].document_url[1] ? governmentalID[0].document_url[1].url : undefined,
          profileImage: profileID && profileID[0] && profileID[0].document_url[0].url ? profileID[0].document_url[0].url : undefined,
          type: data?.type,
          business_type: data?.business_type,
          trade_type: data?.trade_type,
          business_name: data?.business_name,
          business_duration: data?.business_duration,
          location: data?.location,
          this_week_revenue: data?.this_week_revenue,
          last_week_revenue: data?.last_week_revenue,
          last_month_revenue: data?.last_month_revenue,
          phone_number: data?.phoneNumber,
          business_documents: data?.business_documents ?? [],
          other_document: data?.other_document,
          TIN: data?.TIN,
          witnesses: data?.witnesses ?? [],
          loan_amount: data?.loan_amount,
          have_national_id: data?.have_national_id,
          have_phonenumber: data?.have_phonenumber,
          documents: data?.documents ?? []
        }}
        render={({ handleSubmit, values, errors, form }) => (
          <form onSubmit={handleSubmit} >
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<QontoConnector />}
              sx={{ minHeight: '32px' }}
            >
              {steps.map((label) => (
                <Step key={label}>
                </Step>
              ))}

            </Stepper>
            {activeStep === 0 && (
              <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
                <Box sx={{ width: '100%', height: '373px', backgroundColor: '#D9D9D9;', display: 'none' }}>
                </Box>
                <Box>
                  <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}>Are you a business owner/salary contract employee? </Typography>
                  <Field name="type">
                    {(props: any) => <Select
                      onChange={(e: any) => {
                        props.input.onChange(e.target.value)
                        if (e.target.value === 'salary') {
                          isDesktop ? setIsSalaryOpen(true) : history.push(`/loans/apply/salary`)
                        }
                      }}
                      options={loanTypeOptions}
                      {...props}
                    />}
                  </Field>

                </Box>
                <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Button title='Continue' type='submit' variant='contained' endIcon={<ArrowForwardIcon />} />
                  <Button title='Back' onClick={() => history.goBack()} sx={{ textDecoration: 'underline' }} />
                </Box>

              </Box>
            )}
            {activeStep === 1 && (<BusinessInfo handleBack={handleBack} values={values} />)}
            {activeStep === 2 && (<BusinessRevenue handleBack={handleBack} />)}
            {activeStep === 3 && (<BusinessLegal values={values} goBack={handleBack} errors={errors} handleSubmit={handleSubmit} />)}
            {activeStep === 4 && (<Field name="witnesses">
              {(props) => (<LoanWitnesses   {...props} handleBack={handleBack} loading={loading} error={error} />)}
            </Field>)}
            <ApplyLoan open={openApply} onClose={() => setOpenApply(!openApply)} values={values} handleBack={handleBack} loading={loading} error={error} handleNext={handleNext} form={form} />

          </form>
        )}
      />
      <LoanSuccess open={openSuccess} onClose={() => setOpenSuccess(!openSuccess)} />

      <Drawer anchor="right" open={isSalaryOpen && isDesktop} onClose={() => setIsSalaryOpen(false)} sx={{
        '& .MuiPaper-root.MuiDrawer-paper': {
          width: '400px'
        }
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ApplySalaryLoan />
        </Box>
      </Drawer>
    </Box>
  )
}

export default ApplyBusinessLoan


const BusinessInfo: React.FC<{ handleBack: any; values: BusinessFormValues }> = ({ handleBack, values }) => {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
      <Typography fontWeight={600} textAlign={'center'}>Tell us a bit about your business</Typography>
      <Box>
        <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}>What type of business do you run?</Typography>
        <Field
          type="select"
          name="business_type"
          component={Select}
          options={businessCategoryOptions}
        />
      </Box>

      <Field
        label="What is the registered name of your business?"
        name="business_name"
        type="text"
        component={FormInput}
      />
      {values.business_type === 'trading' && (
        <Field
          label="What do you trade?"
          name="trade_type"
          type="text"
          component={FormInput}
        />

      )}
      <Box>
        <Typography sx={{ marginBottom: '4px', fontWeight: 600 }}> How long has the business been in operation? </Typography>
        <Field
          name="business_duration"
          row={false}
          component={RadioButtons}
          options={businessDurationOptions}
        />
      </Box>

      <Field
        label="Where is your business located?"
        name="location"
        type="text"
        component={FormInput}
      />
      <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Button title='Continue' type='submit' variant='contained' endIcon={<ArrowForwardIcon />} />
        <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
      </Box>
    </Box>
  );
}

const BusinessRevenue: React.FC<{ handleBack: any }> = ({ handleBack }) => {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
      <Typography fontWeight={600} textAlign={'center'}>Great! How about your revenue?</Typography>
      <Field name="this_week_revenue">
        {(props: any) => <FormInput
          label="How much in sales/revenue did you make this week?"
          name="this_week_revenue"
          type="text"
          value={numberWithCommas(props.input.value)}
          onChange={(e) => {
            props.input.onChange(removeCommas(e.target.value));
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">UGX</InputAdornment>,
          }}
          {...props}
        />}
      </Field>
      <Field name="last_week_revenue">
        {(props: any) => <FormInput
          label="How much in sales/revenue did you make last week?"
          name="last_week_revenue"
          type="text"
          value={numberWithCommas(props.input.value)}
          onChange={(e) => {
            props.input.onChange(removeCommas(e.target.value));
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">UGX</InputAdornment>,
          }}
          {...props}
        />}
      </Field>

      <Field name="last_month_revenue">
        {(props: any) => <FormInput
          label="How much in sales/revenue did you make last month?"
          name="last_month_revenue"
          type="text"
          value={numberWithCommas(props.input.value)}
          onChange={(e) => {
            props.input.onChange(removeCommas(e.target.value));
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">UGX</InputAdornment>,
          }}
          {...props}
        />}
      </Field>

      <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Button title='Continue' type='submit' variant='contained' endIcon={<ArrowForwardIcon />} />
        <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
      </Box>
    </Box>
  );
}

const BusinessLegal: React.FC<{ goBack: any; values: BusinessFormValues, errors: any; handleSubmit: any }> = ({ goBack, values, errors, handleSubmit }) => {

  const { createKyc, isLoading } = useCreateKycDocument();
  const { deleteDocument } = useDeleteDocumentById();

  const [activeStep, setActiveStep] = useState(0);
  const [addDocument, setAddDocument] = useState(false)
  const [updateToast, setUpdateToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = () => {
    setUpdateToast((prevToast) => ({
      ...prevToast,
      open: false,
    }));

  };
  const handleDeleteDocument = async (documentId: number) => {
    try {
      // Call the deleteDocument function from the hook
      await deleteDocument(documentId);
      setUpdateToast({
        open: true,
        message: "Document deleted",
        type: "success",
      });
    } catch (error: any) {
      setUpdateToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };
  const handleNext = async () => {
    if (activeStep === 0) {
      if (errors.have_national_id || errors.have_phonenumber || errors.phoneNumber) {
        handleSubmit()
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (errors.nationalFront || errors.nationalBack) {
        handleSubmit()
      } else {
        /**if the user already has their national id  */
        if (typeof values.nationalFront === 'string' && typeof values.nationalBack === 'string') {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (values.nationalFront && values.nationalBack) {
          /**if they dont then upload it */
          const formData = new FormData();
          formData.append('name', 'National ID');
          formData.append('type', document_types[0]);
          formData.append('documents', values.nationalFront);
          formData.append('documents', values.nationalBack);
          try {
            await createKyc(formData);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } catch (error) {
            console.log(error)
          }
        }
      }
    } else if (activeStep === 2) {
      if (errors.profileImage) {
        handleSubmit()
      } else {
        if (typeof values.profileImage === 'string') {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (values.profileImage) {
          /**if they dont then upload it */
          const formData = new FormData();
          formData.append('name', 'Facial Profile');
          formData.append('type', document_types[7]);
          formData.append('documents', values.profileImage);
          try {
            await createKyc(formData);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
    else if (activeStep === 3) {
      if (errors.business_documents || errors.other_document) {
        handleSubmit()
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 4) {
      if (errors.TIN) {
        handleSubmit()
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }


  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };

  console.log(values)
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
      <Typography fontWeight={600} textAlign={'center'}>We need to verify your legal details</Typography>
      {activeStep === 0 &&
        <>
          <Box>
            <Typography>Do you have a National ID?</Typography>
            <Field
              type="select"
              name="have_national_id"
              component={Select}
              options={yesOrNoOptions}
            />
          </Box>
          {values.have_national_id === 'yes' && (
            <Box>
              <Typography>Do you have a phone number registered in those names?</Typography>
              <Field
                type="select"
                name="have_phonenumber"
                component={Select}
                options={yesOrNoOptions}
              />
            </Box>
          )}
          {values.have_phonenumber === 'yes' && (
            <Field
              label="Please enter correct phone number"
              name="phoneNumber"
              type="text"
              component={FormInput}
            />
          )}
          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title='Continue' onClick={handleNext} variant='contained' endIcon={<ArrowForwardIcon />} />
            <Button title='Back' onClick={goBack} sx={{ textDecoration: 'underline' }} />
          </Box>
        </>}
      {activeStep === 1 &&
        <>

          <Box>
            <Typography fontWeight={600} >Take a photo of the front of your National ID.</Typography>
            <Field name="nationalFront">
              {({ input, meta }) => (
                <>
                  <Box>
                    <input
                      type="file"
                      accept="image/*,.pdf" // Allow both images and PDFs
                      id="frontPhotoUpload"
                      onChange={(e) => {
                        input.onChange(e.target.files && e.target.files[0]);

                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="frontPhotoUpload" >
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>

                        <Button
                          title={"Capture Image"}
                          onClick={() => imageUpload("front")}
                          sx={{ padding: '4px 32px', background: '#F0EBF8' }}
                        />
                      </Box>
                    </label>
                  </Box>
                  <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                </>
              )}
            </Field>
          </Box>
          <Box>
            <Typography fontWeight={600} >Take a photo of the back of your National ID.</Typography>
            <Field name="nationalBack">
              {({ input, meta }) => (
                <>
                  <Box>
                    <input
                      type="file"
                      accept="image/*,.pdf" // Allow both images and PDFs
                      id="backPhotoUpload"
                      onChange={(e) => {
                        input.onChange(e.target.files && e.target.files[0]);
                      }}

                      style={{ display: 'none' }}
                    />
                    <label htmlFor="backPhotoUpload">
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, }}>

                        <Button
                          title={"Capture Image"}
                          onClick={() => imageUpload("back")}
                          sx={{ padding: '4px 32px', background: '#F0EBF8' }}
                        />
                      </Box>
                    </label>
                  </Box>
                  <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                </>
              )}
            </Field>
          </Box>
          <ImageList sx={{ width: '100%', alignItems: 'start', overFlowY: 'hidden' }} cols={2}>

            {values.nationalFront && (
              <ImageListItem
                sx={{ borderRadius: 8, objectFit: 'cover', border: '1px solid #F6F6F7' }}
              >
                {(values.nationalFront instanceof File && values.nationalFront.type.includes('pdf') || (typeof values.nationalFront === 'string' && values.nationalFront.endsWith('.pdf'))) ? (
                  // Render PDF preview using iframe
                  <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                    <Document
                      file={values.nationalFront instanceof File ? URL.createObjectURL(values.nationalFront) : values.nationalFront}

                    >
                      <Page pageNumber={1} width={200} height={1} />
                    </Document>
                  </div>
                ) : (
                  // Render image
                  <img
                    src={values.nationalFront instanceof File ? URL.createObjectURL(values.nationalFront) : values.nationalFront}
                    alt="National ID Front"
                    style={{ objectFit: 'contain' }}
                  />
                )}
              </ImageListItem>
            )}
            {values.nationalBack && (
              <ImageListItem
                sx={{ borderRadius: 8, border: '1px solid #F6F6F7' }}
              >
                {(values.nationalBack instanceof File && values.nationalBack.type.includes('pdf')) || (typeof values.nationalBack === 'string' && values.nationalBack.endsWith('.pdf')) ? (
                  <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                    <Document
                      file={values.nationalBack instanceof File ? URL.createObjectURL(values.nationalBack) : values.nationalBack}

                    >
                      <Page pageNumber={1} width={200} height={1} />
                    </Document>
                  </div>
                ) : (
                  // Render image
                  <img
                    src={values.nationalBack instanceof File ? URL.createObjectURL(values.nationalBack) : values.nationalBack}
                    alt="National ID Back"
                    style={{ objectFit: 'contain' }}
                  />
                )}
              </ImageListItem>
            )}
          </ImageList>
          {isLoading && (<CircularProgress color="success" sx={{ m: '0 auto' }} />)}

          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title='Continue' onClick={handleNext} disabled={isLoading} variant='contained' endIcon={<ArrowForwardIcon />} />
            <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
          </Box>
        </>}
      {activeStep === 2 && (
        <>
          <Box>
            <Typography fontWeight={600} >Take a clear photo of your face(a selfie).</Typography>
            <Box>
              <Field name="profileImage">
                {({ input, meta }) => (
                  <div>
                    <input
                      type="file"
                      accept="image/*,.pdf" // Allow both images and PDFs
                      id="profilePhotoUpload"
                      onChange={(e) => {
                        input.onChange(e.target.files && e.target.files[0]);

                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="profilePhotoUpload">
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, mt: 2 }}>

                        <Button
                          title={"Upload"}
                          sx={{ padding: '4px 32px', background: '#F0EBF8' }}
                          onClick={() => imageUpload("profile")}
                        />
                      </Box>
                    </label>
                    <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                  </div>
                )}
              </Field>

              {values.profileImage && (
                <ImageListItem
                  sx={{ borderRadius: 8, objectFit: 'cover', border: '2px dashed #F6F6F7', mt: 1 }}
                >
                  {(values.profileImage instanceof File && values.profileImage.type.includes('pdf')) || (typeof values.profileImage === 'string' && values.profileImage.endsWith('.pdf')) ? (
                    <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                      <Document
                        file={values.profileImage instanceof File ? URL.createObjectURL(values.profileImage) : values.profileImage}
                      >
                        <Page pageNumber={1} width={200} height={1} />
                      </Document>
                    </div>
                  ) : (
                    // Render image
                    <img
                      src={values.profileImage instanceof File ? URL.createObjectURL(values.profileImage) : values.profileImage}
                      alt="Profile Image"
                      style={{ objectFit: 'contain' }}
                    />
                  )}
                </ImageListItem>
              )}
              {isLoading && (<CircularProgress color="success" sx={{ m: '0 auto' }} />)}
            </Box>
          </Box>
          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title='Continue' onClick={handleNext} variant='contained' endIcon={<ArrowForwardIcon />} disabled={isLoading} />
            <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
          </Box>
        </>
      )}
      {activeStep === 3 && (
        <>
          <Box>
            <Typography variant='h6' sx={{ fontSize: '1rem', marginBottom: '4px', fontWeight: 600 }}>Which of these legal business documents do you have? You can choose more than one.</Typography>
            <Field name="business_documents" component={CheckboxList} options={businessLoanTypeOptions} />
          </Box>
          {values.business_documents && values.business_documents.includes('other') && (
            <Field
              label="Type the name of document you have below."
              name="other_document"
              type="text"
              component={FormInput}
            />
          )}
          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title='Continue' onClick={handleNext} variant='contained' endIcon={<ArrowForwardIcon />} />
            <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
          </Box>
        </>
      )}

      {activeStep === 4 && (
        <>
          <Box>
            <Typography fontWeight={600} >{`Take a photo of the ${values.business_documents}`}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography fontWeight={600}>Upload documents</Typography>
              <Button title='Upload' variant='outlined' startIcon={<AddIcon />} onClick={() => setAddDocument(!addDocument)} />
            </Box>

            {values.documents && (
              <ImageList sx={{ width: 500, height: 'auto', mt: 2, overflowX: 'scroll' }} cols={4} gap={0}>
                {values.documents.map((document: KYCDocument, i: React.Key) => (
                  <ImageListItem key={i} sx={{ width: 91, height: 80, borderRadius: 8, objectFit: 'cover', border: `0.5px solid  #F6F6F7` }}>
                    <ImageListItemBar
                      sx={{ background: 'transparent' }}
                      position="top"
                      actionIcon={
                        <IconButton
                          sx={{ padding: '0px', display: 'block', color: 'red' }}
                          aria-label={`close ${document?.document_name}`}
                          onClick={() => handleDeleteDocument(document.id)}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                      actionPosition="left"
                    />
                    {(document.document_url && document.document_url[0].url.endsWith('pdf')) ? (
                      <div style={{ maxHeight: '92px', overflow: 'hidden' }}>
                        <Document
                          file={document.document_url[0].url} >
                          <Page pageNumber={1} width={90} height={0.5} />
                        </Document>
                      </div>
                    ) : document.document_url[0] ? (
                      <img
                        srcSet={document.document_url[0].url}
                        src={document.document_url[0].url}
                        alt={`business attached document`}
                        style={{ objectFit: 'cover' }}
                      />
                    ) : null}
                  </ImageListItem>
                ))}
              </ImageList>
            )}

            {values.documents && values.documents?.length < values.business_documents.length && <Typography variant='caption' color='error'>Please upload all documents</Typography>}
            <Box sx={{ mt: 1.5 }}>
              <Typography variant='body2' sx={{ fontWeight: 600, textDecoration: 'underline' }}>Uploaded documents</Typography>
              <Box>
                {values.documents?.map((document) => document.document_name).join(', ')}
              </Box>
            </Box>
          </Box>
          <Field
            label="Please enter your TIN number"
            name="TIN"
            type="text"
            component={FormInput}
          />

          <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button title='Continue' type='submit' variant='contained' disabled={values.documents && values.documents?.length < values.business_documents.length} endIcon={<ArrowForwardIcon />} />
            <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
          </Box>
        </>
      )}
      <Toast {...updateToast} handleClose={() => handleCloseToast()} />
      <Field name="documents">
        {(props) => (<AddBusinessDocuments {...props} documents={values.business_documents ? values.business_documents as string[] : []} open={addDocument} onClose={() => setAddDocument(!addDocument)} />)}
      </Field>

    </Box>
  );
}

export const LoanWitnesses: React.FC<{ handleBack: any, input: any, meta: any; loading: boolean; error: any; }> = ({ handleBack, input, meta, loading, error: finalerror, }) => {
  const { data: witnesses } = useFetchWitnesses();
  const initialSelectedWitnesses = (witnesses ?? []).filter((witness) => input.value.includes(witness.id));

  const { createWitness, error, isLoading: creatingWitness } = useCreateWitness();
  const [selectedWitnesses, setSelectedWitnesses] = useState<any[]>(initialSelectedWitnesses);
  const onSubmit = async (values: WitnessFormValues, form: FormApi<WitnessFormValues, Partial<WitnessFormValues>>) => {
    try {
      const witness = await createWitness({
        name: values.name,
        phonenumber: values.phoneNumber,
        relationship: values.relationship
      });

      handleSelect(witness)
      // Clear validation errors
      form.restart()

    } catch (error) {
      // Handle error
    }

  };

  const validate = (values: WitnessFormValues) => {
    const errors: Partial<WitnessFormValues> = {};
    if (!values.name) {
      errors.name = 'required';
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'required';
    }

    if (!telephoneCheck(values.phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phonenumber";
    }

    if (!values.relationship) {
      errors.relationship = 'required';
    }


    return errors;
  };


  const handleSelect = (member: Witness) => {

    let newMember: { id: number, name: string, relationship: string, phone_number: string } = {
      id: 0,
      name: "",
      relationship: "",
      phone_number: ""
    }

    newMember = { id: member.id as number, relationship: member.relationship, name: member.name, phone_number: member.phone_number }


    // Check if the member is already selected based on their ID
    const isAlreadySelected = selectedWitnesses.some((selectedMember) => selectedMember.id === newMember.id);


    if (!isAlreadySelected && newMember.id !== null) {

      setSelectedWitnesses((prevSelectedMembers) => [
        ...prevSelectedMembers,
        newMember
      ]);

      input.onChange([...input.value, newMember.id]);
      localStorage.setItem('witnesses', JSON.stringify([...input.value, newMember.id]))
    }


  };

  const handleRemoveMember = (memberId: number) => {
    setSelectedWitnesses((prevSelectedMembers) => prevSelectedMembers.filter((member) => member.id !== memberId));
    input.onChange(input.value.filter((member: number) => member !== memberId));
  };

  return (
    <>
      <Typography fontWeight={600} textAlign={'center'}>Add loan witnesses</Typography>
      <Box>
        <Typography my={1}>Who else knows about the loan application (witnesses/bailsmen)?<Typography variant='body2' component='span'>  (Minimum of 2 people)</Typography> </Typography>
        <Form onSubmit={onSubmit} validate={validate}>
          {({ values, form }) => (
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Field
                  name="name"
                  label="Full legal name"
                  fullWidth
                  component={FormInput}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box>
                    <Typography fontWeight={600}>Relationship</Typography>
                    <Field name="relationship" component={Select} fullWidth options={relationShipOptions} />
                  </Box>
                  <Field
                    name="phoneNumber"
                    label="Phone number"
                    fullWidth
                    component={FormInput}
                  />
                </Box>
                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Button title='Add' variant='outlined' onClick={() => onSubmit(values, form)} loading={creatingWitness} disabled={creatingWitness} />
              </Box>
            </Box>
          )}
        </Form>
        {selectedWitnesses.length > 0 && (
          <Box mt={2}>
            <Typography fontWeight={600}>Selected Witnesses</Typography>
            <List>
              {selectedWitnesses.map((member, i) => (
                <ListItem key={i}
                  secondaryAction={<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>{member.relationship}</Typography>
                    <IconButton onClick={() => handleRemoveMember(member.id)}>  <CancelOutlinedIcon fontSize="small" /> </IconButton> </Box>} >      <ListItemText primary={member.name}
                      secondary={member.phone_number} />
                </ListItem>))}
            </List>
          </Box>
        )}
        <FormHelperText sx={{ color: 'red' }}>
          {meta?.touched ? meta?.error || meta?.submitError : ""}
        </FormHelperText>
        {finalerror && <Alert severity='error'>{(finalerror as Error).message}</Alert>}


        <Box mt={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Button title='Continue' type='submit' variant='contained' endIcon={<ArrowForwardIcon />} disabled={loading} loading={loading} />
          <Button title='Back' onClick={handleBack} sx={{ textDecoration: 'underline' }} />
        </Box>
      </Box>

    </>

  );
}

export const LoanSuccess: React.FC<{ open: boolean; onClose: any }> = ({ open, onClose }) => {
  const history = useHistory()
  return (
    <Modal open={open} handleClose={onClose}
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
        <VerifiedIcon color='success' sx={{ height: '80px', width: '80px' }} />
        <Typography variant='body1' sx={{ textAlign: 'center' }}>
          {`We have successfully received your application. Please be patient as we review it.`}
        </Typography>
        <Button title='Okay' onClick={() => history.push('/loans')} />
      </DialogContent>
    </Modal>
  );
}



const AddBusinessDocuments: React.FC<{ open: boolean; onClose: any, documents: string[]; input: any }> = ({ open, onClose, documents, input }) => {
  const imageUpload = (inputName: string) => {
    const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
    fileInput.click();
  };

  const { createKyc, error, isLoading } = useCreateKycDocument();

  const filteredOptions = businessLoanTypeOptions.filter(option => documents.includes(option.value));


  const [uploadToast, setUploadToast] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleCloseToast = (toastType: string) => {
    if (toastType === "upload") {
      setUploadToast((prevToast) => ({
        ...prevToast,
        open: false,
      }));
    }
  };

  const validate = (values: DocumentFormValues) => {
    const errors: Partial<DocumentFormValues> = {};
    if (!values.document_name) {
      errors.document_name = 'Document name is required';
    }
    if (!values.frontImage) {
      errors.frontImage = 'Front Image is required';
    }


    return errors;
  };

  const onSubmit = async (values: DocumentFormValues, form: FormApi<DocumentFormValues, Partial<DocumentFormValues>>) => {

    try {

      const formData = new FormData();
      formData.append('name', values.document_name);
      formData.append('type', document_types[6]);
      formData.append('documents', values.frontImage);

      const additionalDetails = [
        { field: 'name', value: values.type },
      ];
      const additionalDetailsString = JSON.stringify(additionalDetails);
      formData.append('additional_details', additionalDetailsString);
      const document = await createKyc(formData);

      if (document) {

        input.onChange([...input.value, document]);


        form.reset()
        onClose()
      }
      // Document created successfully, you can perform any further actions here
    } catch (error) {

    }

  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{`Add documents`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <Form onSubmit={onSubmit} validate={validate}>
          {({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Select document?</Typography>
                  <Field initialValue={filteredOptions[0].value} name="document_name" component={Select} fullWidth options={filteredOptions} />
                </Box>
                <Field
                  name="type"
                  label="Type the name of the document"
                  fullWidth
                  component={FormInput}
                />
                <Box>
                  <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach a pdf or clear scanned photo of the document</Typography>
                  <Field name="frontImage">
                    {({ input, meta }) => (
                      <>
                        <Box>
                          <input
                            type="file"
                            accept="image/*,.pdf" // Allow both images and PDFs
                            id="frontPhotoUpload"
                            onChange={(e) => {
                              input.onChange(e.target.files && e.target.files[0]);

                            }}
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="frontPhotoUpload">
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, }}>
                              <ArrowCircleUpIcon />
                              <Button
                                title={"Upload"}
                                color="inherit"
                                variant="outlined"
                                onClick={() => imageUpload("front")}
                                sx={{ padding: '3px 10px' }}
                              />
                            </Box>
                          </label>
                          {values.frontImage && (

                            <ImageListItem
                              sx={{ borderRadius: 8, objectFit: 'cover', border: '2px dashed #F6F6F7' }}
                            >
                              {(values.frontImage instanceof File && values.frontImage.type.includes('pdf')) || (typeof values.frontImage === 'string' && values.frontImage.endsWith('.pdf')) ? (
                                <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                                  <Document
                                    file={values.frontImage instanceof File ? URL.createObjectURL(values.frontImage) : values.frontImage}

                                  >
                                    <Page pageNumber={1} width={200} height={1} />
                                  </Document>
                                </div>
                              ) : (
                                // Render image
                                <img
                                  src={values.frontImage instanceof File ? URL.createObjectURL(values.frontImage) : values.frontImage}
                                  alt="Profile Image"
                                  style={{ objectFit: 'contain' }}
                                />
                              )}
                            </ImageListItem>
                          )}
                        </Box>
                        <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                      </>
                    )}
                  </Field>
                </Box>


                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Button color="primary" title='Cancel' sx={{ mr: 2, width: '120px' }} onClick={onClose} />
                  <Button variant="contained" color="primary" type="submit" title='Save' loading={submitting || isLoading} sx={{ width: '120px' }} disabled={isLoading} />
                </Box>
              </Box>
            </form>
          )}
        </Form>
        <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
      </DialogContent>
    </ModalWrapper>
  );
}

export const ApplyLoan: React.FC<{ open: boolean; onClose: any; values: BusinessFormValues; handleBack: any; loading: boolean; error: any; handleNext: any; form: any }> = ({ open, onClose, values, handleBack, loading, error, handleNext, form }) => {

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography sx={{ fontWeight: 600, }}>{`You are applying for a business loan as an owner of ${values.business_name}.`}</Typography>
      </DialogTitle>
      <DialogContent >
        <Box sx={{ width: '100%' }}>
          <Field name="loan_amount" >
            {(props: any) => <FormInput
              label="Desired initial loan amount"
              type="text"
              name="amount"
              value={numberWithCommas(props.input.value)}
              onChange={(e) => { props.input.onChange(removeCommas(e.target.value)); }}
              {...props}
            />}
          </Field>
        </Box>
        {error && <Alert severity='error'>{(error as Error).message}</Alert>}
        <Box mt={3} sx={{ textAlign: 'center', display: 'flex', gap: 1 }}>

          <Button title='Back' onClick={handleBack} sx={{ flex: 0.4 }} color='primary' />
          <Button title='Submit' onClick={
            () => handleNext(values, form)
          } variant='contained' disabled={loading} loading={loading} sx={{ flex: 0.5 }} />

        </Box>
        <Button title='Skip and Submit' onClick={onClose} sx={{ color: '#673AB7', textAlign: 'center', m: '16px auto', width: '100%' }} />
      </DialogContent>
    </ModalWrapper>
  );
}

