import { Typography, Box, Avatar, Divider, Menu, MenuItem, MenuProps, Chip, DialogTitle, DialogContent, List, ListItem, IconButton, useMediaQuery, Theme, Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAddGoalMembers, useGoalMemberById, useGoalMembers, useGoalTransactions, useRevolvingPayoutTransactions, useUpdateGoalMember } from '../../hooks/goalHooks';
import { GoalMember, KYCDocument } from '../../types/types';
import VerifiedIcon from '@mui/icons-material/Verified';
import { styled, alpha } from '@mui/material/styles';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { capitalizeWords, numberWithCommas } from '../../utils/middleware';
import PromoteAdmin from '../../components/members/PromoteAdmin';

import FilterListIcon from '@mui/icons-material/FilterList';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import FlipMove from 'react-flip-move';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CloseIcon from '@mui/icons-material/Close';
import AddMembersStepper from '../../components/members/AddMembers';
import Toast from '../../components/Reusables/Feedback/Toast';
import MemberProfile from '../../components/members/MemberProfile';
import ModalWrapper from '../../components/Modals/ModalWrapper';
import TransactionsWrapper from '../transactions/TransactionsWrapper';
import { DepositTabContent, WithdrawTabContent } from '../../components/transactions/TransactionSummaryDetails';
import { transactionTypes } from '../../components/Reusables/Input/select/Options';

interface HomeTabContentProps {
  depositTotal: number;
  withdrawTotal: number;
  currency: string;
}

const HomeTabContent: React.FC<HomeTabContentProps> = ({ depositTotal, withdrawTotal, currency }) => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return <Box display={"flex"} flexDirection={"column"} gap="0.62rem">
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Total deposits</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`+ ${numberWithCommas(depositTotal)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Total withdraws</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`- ${numberWithCommas(withdrawTotal)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>
  </Box>
};


const KYCContent = ({ member }: { member: GoalMember }) => {
  const { name, users, role, } = member;
  return <Box>
    <Box sx={{ display: 'flex', alignItems: 'stretch', gap: 1.5 }}>
      <Avatar src={member.image ?? undefined}
        sx={{ bgcolor: "primary.main", width: 35, height: 35 }}
        alt={member.name} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1">
            {name}
          </Typography>
          <Typography variant='caption'>{`(${users?.username})`}</Typography>
        </Box>
        <Typography variant="body2">{users?.phone_number} </Typography>
      </Box>

      <Typography>{role && capitalizeWords(role)}</Typography>
    </Box>
    <Box mt={2} sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
      {member.kyc_documents?.map((document: KYCDocument, index: number) => (
        <Chip key={index} label={document.document_name} />
      ))}
    </Box>
  </Box>;
};

const AdminContent = ({ member }: { member: GoalMember }) => {
  const { name, role, custom_title, add_members_privilege, remove_members_privilege, promote_demote_admins_privilege, edit_goal_info_privilege, withdraw_privilege, distribute_goal_funds_privilege, max_withdraw_limit, is_withdraw_limit_percentage } = member;

  return <Box>
    <List>
      <ListItem>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="body1" fontWeight={600}>Admin Name</Typography>
          <Typography variant="body2" align="right">
            {name}
          </Typography>
        </Box>
      </ListItem>
      <ListItem>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="body1" fontWeight={600}>Admin Role</Typography>
          <Typography variant="body2" align="right">
            {role}
          </Typography>
        </Box>
      </ListItem>
      <ListItem>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="body1" fontWeight={600}>Custom Title</Typography>
          <Typography variant="body2" align="right">
            {custom_title ?? 'Not set'}
          </Typography>
        </Box>
      </ListItem>
      <ListItem>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="body1" fontWeight={600}>Non-Financial Rights</Typography>
          <Typography variant="body2" align="right">
            {add_members_privilege && 'Add Members'},
            {remove_members_privilege && ' Remove Members'},
            {promote_demote_admins_privilege && ' Promote/Demote admins'},
            {edit_goal_info_privilege && ' Edit goal info'}
          </Typography>
        </Box>
      </ListItem>
      <ListItem>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="body1" fontWeight={600}>Financial Rights</Typography>
          <Typography variant="body2" align="right">
            {withdraw_privilege && 'Withdraw Funds'},
            {distribute_goal_funds_privilege && ' Distribute goal funds'}
          </Typography>
        </Box>
      </ListItem>
      <ListItem>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="body1" fontWeight={600}>Max Withdraw Limit</Typography>
          <Typography variant="body2" align="right">
            {max_withdraw_limit != null ? (
              is_withdraw_limit_percentage ? (
                `${max_withdraw_limit}%`
              ) : (
                numberWithCommas(max_withdraw_limit)
              )
            ) : (
              'N/A'
            )}
          </Typography>
        </Box>
      </ListItem>
      <ListItem>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="body1" fontWeight={600}>Per transaction withdraw limit</Typography>
          <Typography variant="body2" align="right">
            {max_withdraw_limit ? numberWithCommas(max_withdraw_limit) : 'N/A'}
          </Typography>
        </Box>
      </ListItem>
    </List>

  </Box>;
};


export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}

  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      minHeight: 0,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


const GoalMembers = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory()
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);
  const [selectedMember, setSelectedMember] = useState<GoalMember | null>(null);
  const [sortingOption, setSortingOption] = useState<string>('');
  const { addMembers, error } = useAddGoalMembers();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [open, setOpen] = useState(false);
  const [isMemberOpen, setIsMemberOpen] = useState(false);
  const [isTransactionsOpen, setIsTransactionsOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const currency = 'UGX';

  const [filterValues, setFilterValues] = useState({
    startDate: '',
    endDate: '',
    type: 'all',
    category: 'all',
    paymentMethod: 'all',
    status: 'all',
    user: null,
  });

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };


  const { data: goalMembers, isLoading, isError } = useGoalMembers(parseFloat(id));
  const { data: member, isLoading: memberLoading, isError: memberError } = useGoalMemberById(selectedMember?.id || 0);
  const memberTransactions = useGoalTransactions({ ...filterValues, user: selectedMember?.user }, false, parseFloat(id));

  const revolvingTransactions = useRevolvingPayoutTransactions(parseFloat(id))
  const { handleUpdateMember, error: updateError } = useUpdateGoalMember();

  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);

  const totalDeposits = memberTransactions?.data?.pages?.[0]?.totalDeposits || 0;
  const totalWithdrawals = memberTransactions?.data?.pages[0]?.totalWithdrawals || 0;

  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    if (memberTransactions?.data?.pages[0]?.transactions?.length === 0) return;
    setIsLoading(true);
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);


  const handleSelectMember = (event: React.MouseEvent<HTMLElement>, member: GoalMember) => {
    setSelectedMember(member);
    setAnchorEl1(event.currentTarget);
  };

  const handleSort = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleSortOptionClick = (option: string) => {
    setSortingOption(option);
    setAnchorEl2(null);
  };

  const handleClose = () => {
    setAnchorEl1(null);
    setAnchorEl2(null);

  };

  const handleOpenModal = (menuItem: string) => {
    setSelectedMenuItem(menuItem);
    handleClose();
  };

  const handleOpenAdmin = () => {
    setIsAdminOpen(true)
    handleClose()
  }

  const handleClickViewProfile = () => {
    if (selectedMember) {
      if (isDesktop) {
        setIsMemberOpen(true)
      } else {
        // Redirect to the new page for Contribution details
        history.push(`/goals/members/${selectedMember.id}`);
      }
      handleClose();
    }
  };

  const handleClickViewContributions = () => {
    if (selectedMember) {
      if (isDesktop) {
        setIsTransactionsOpen(true)
      } else {
        // Redirect to the new page for Contribution details
        history.push(`/goals/members/transactions/${selectedMember.id}`);
      }
      handleClose();
    }
  };

  const getMenuContent = () => {
    switch (selectedMenuItem) {
      case 'Add Administrator':
        return selectedMember && <PromoteAdmin member={selectedMember} promoteAdmin={handleUpdateMember} context='goal' />;
      case 'Member KYC':
        return selectedMember && <KYCContent member={selectedMember} />;
      case 'View admin rights':
        return selectedMember && <AdminContent member={selectedMember} />;
      default:
        return null;
    }
  };

  const sortGoalMembers = (option: string, goalMembers: GoalMember[]) => {
    if (option === 'Deposits') {
      return goalMembers.sort((a, b) => b.deposits - a.deposits);
    } else if (option === 'Withdraws') {
      return goalMembers.sort((a, b) => b.withdraws - a.withdraws);
    } else if (option === 'Revolving payouts') {
      return goalMembers.sort((a, b) => b.revolvingPayouts - a.revolvingPayouts);
    } else if (option === 'Recent Activity') {
      return goalMembers.sort((a, b) => new Date(b.recentActivity).getTime() - new Date(a.recentActivity).getTime());
    } else {
      // Default sorting (no sorting)
      return goalMembers;
    }
  }

  const sortedGoalMembers = sortGoalMembers(sortingOption, goalMembers);

  let members = sortingOption.length > 0 ? sortedGoalMembers : goalMembers

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography>Error occurred while fetching the goal members.</Typography>;
  }


  return (
    <Box sx={{ mt: 0.5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
        <Box
          aria-controls="sort-menu" aria-haspopup="true"
          onClick={(event) => handleSort(event)}
          sx={{ display: 'flex', alignItems: 'center', gap: 0.4 }}>
          <FilterListIcon />
          <Typography variant='body2'>Sort By</Typography>
        </Box>
        <Menu
          id="sort-menu"
          aria-labelledby="sort-button"
          anchorEl={anchorEl2}
          open={Boolean(anchorEl2)}
          onClose={handleClose}
          MenuListProps={{
            dense: true,
          }}
        >
          <MenuItem onClick={() => handleSortOptionClick('Deposits')} >Deposits</MenuItem>
          <MenuItem onClick={() => handleSortOptionClick('Withdraws')}>Withdraws</MenuItem>
          <MenuItem onClick={() => handleSortOptionClick('Revolving payouts')}>Revolving payouts</MenuItem>
          <MenuItem onClick={() => handleSortOptionClick('Recent Activity')}>Recent Activity</MenuItem>
        </Menu>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.4 }} onClick={handleOpenDialog}>
          <AddCircleOutlinedIcon color='primary' />
          <Typography variant='body2'>Add member</Typography>
        </Box>
        {open && (<AddMembersStepper open={open} onClose={handleCloseDialog} onAddMembers={addMembers} id={parseFloat(id)} name={''} type={'goal'} error={error} />)}
      </Box>
      <FlipMove>
        {members.map((member: GoalMember) => (
          <React.Fragment key={member.id} >
            <Box
              aria-controls="menu1" aria-haspopup="true"
              sx={{
                borderBottom: `0.5px solid  #F1F2F3`,
                py: 1
              }}
              onClick={(event) => handleSelectMember(event, member)}

            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.4 }}>
                  <Avatar src={member.image ?? undefined}
                    sx={{ bgcolor: "primary.main", width: 35, height: 35 }} alt={member.name}
                  />
                  <Box>
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                      <Typography variant='body1' fontWeight={600}>{capitalizeWords(member.name)}</Typography>
                      {member.role === 'owner' && (
                        <VerifiedIcon fontSize='small' sx={{ color: "#C2B0E2" }} />
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', color: 'grey.600', gap: 0.3, alignItems: 'baseline' }}>
                      <Typography variant='subtitle2'>{`Gave`}</Typography>
                      <Typography variant="h6" fontWeight={600}>
                        {typeof member.contribution === 'number'
                          ? numberWithCommas(member.contribution)
                          : `${member.contribution}`}
                      </Typography>
                      <Typography variant='caption'>
                        {typeof member.contribution === 'number'
                          ? member.currency
                          : `the min amount`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography variant='body2'>{member && capitalizeWords(member.custom_title ?? member.role)}</Typography>
              </Box>
            </Box>

          </React.Fragment>
        ))}
      </FlipMove>
      <>
        <StyledMenu
          id="menu1"
          anchorEl={anchorEl1}
          open={Boolean(anchorEl1)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClickViewProfile} disableRipple>
            <Person2OutlinedIcon />
            View Profile
          </MenuItem>
          {selectedMember?.role !== `member` && [
            <MenuItem key="member-kyc" onClick={() => handleOpenModal('View admin rights')} disableRipple>
              <AdminPanelSettingsIcon />
              View Admin
            </MenuItem>,
            <MenuItem key="demote-admin" onClick={isDesktop ? handleOpenAdmin : () => handleOpenModal('Add Administrator')} disableRipple>
              <ShieldOutlinedIcon />
              Demote  admin
            </MenuItem>,
          ]}
          <MenuItem onClick={isDesktop ? handleOpenAdmin : () => handleOpenModal('Add Administrator')} disableRipple>
            <ShieldOutlinedIcon />
            Promote to admin*
          </MenuItem>
          <MenuItem onClick={() => handleOpenModal('Member KYC')} disableRipple>
            <HowToRegIcon />
            KYC
          </MenuItem>
          <MenuItem onClick={handleClickViewContributions} disableRipple>
            <MonetizationOnIcon />
            Contribution details
          </MenuItem>
        </StyledMenu>
      </>
      {selectedMenuItem && (
        <ModalWrapper
          open={Boolean(selectedMenuItem)}
          onClose={() => setSelectedMenuItem(null)}
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body1' fontWeight={600} sx={{ m: '0 auto' }}>{selectedMenuItem}</Typography>
            <IconButton color="inherit" onClick={() => setSelectedMenuItem(null)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ px: 1 }}>
            {getMenuContent()}
          </DialogContent>
        </ModalWrapper>
      )}

      <Toast
        open={updateError !== null}
        type='severity'
        handleClose={() => console.log('message')}
        message={updateError ? (updateError as Error).message : ''}
      />
      {selectedMember && (
        <>
          <ModalWrapper open={isMemberOpen} onClose={() => setIsMemberOpen((prevState) => !prevState)}>
            <MemberProfile memberData={member} isLoading={memberLoading} isError={memberError} memberType='goal' />
          </ModalWrapper>
          <ModalWrapper open={isTransactionsOpen} onClose={() => setIsTransactionsOpen((prevState) => !prevState)}>
            <TransactionsWrapper
              transactions={memberTransactions}
              onUpdateValues={setFilterValues}
              member={member}
              initialValues={filterValues}
              revolvingPayouts={revolvingTransactions}
              transactionPageTitle="Contribution detail"
              transactionTypes={transactionTypes}
              customTabPanels={[
                <HomeTabContent depositTotal={totalDeposits} withdrawTotal={totalWithdrawals} currency={currency} />
                ,
                <DepositTabContent total={totalDeposits} currency={currency} />
                ,
                <WithdrawTabContent total={totalWithdrawals} currency={currency} />
              ]}
              isLoading={loading} />
          </ModalWrapper>
          <ModalWrapper open={isAdminOpen} onClose={() => setIsAdminOpen((prevState) => !prevState)}>
            <PromoteAdmin member={selectedMember} promoteAdmin={handleUpdateMember} context='goal' />
          </ModalWrapper>
        </>
      )}
    </Box>
  );
};

export default GoalMembers;
