import { useState } from "react";
import { SearchResultUser } from "../../../types/types";
import { useSearchUsers } from "../../../hooks/userHooks";
import {
    Box,
    Typography,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    List,
} from "@mui/material";
import MemberSkeleton from "../../Reusables/Feedback/skeletons/MemberSkeleton";
import { SearchOutlined } from "@mui/icons-material";
import FormInput from "../../Reusables/Input/FormInput";

interface PasbancUserStepProps {
    onAddMember: (data: any) => void;
    recipient: SearchResultUser
}

export const PasbancUserStep: React.FC<PasbancUserStepProps> = ({ onAddMember }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const { data: users, isLoading: usersLoading, isError: usersError } = useSearchUsers({ searchterm: searchTerm });

    return (
        <Box>
            <FormInput name='search recipients' placeholder="Name,username"
                InputProps={{ startAdornment: <SearchOutlined /> }} onChange={(e) => {
                    setSearchTerm(e.target.value);
                }} sx={{ mt: 1.5 }} />

            <Box sx={{ mt: 2 }}>
                <Typography fontWeight={600}>Pasbanc users</Typography>
                {usersLoading ? (
                    <div >
                        {Array.from(new Array(1)).map((item, index) => (<MemberSkeleton key={index} />
                        ))} </div>
                ) : usersError ? (
                    <div>
                        <Typography variant="caption">Error occurred while fetching contacts.</Typography> </div>
                ) : users && users.length === 0 ? (
                    <div><Typography variant="caption">No users found.</Typography> </div>
                ) : (
                    <List>
                        {users.map((user) => (
                            <ListItem key={user.id} button onClick={() => onAddMember(user)}>
                                <ListItemAvatar>
                                    <Avatar src={user.profile_img} alt={user.username} />
                                </ListItemAvatar>
                                <ListItemText primary={user.name} secondary={user.username} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
        </Box>
    )
}

export default PasbancUserStep;