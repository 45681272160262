import { useMutation, useQueryClient } from '@tanstack/react-query';
import waitlistApiClient from "../apis/apiServices/waitlist";
import { InvestmentWaitlistData } from '../types/actionTypes';

export const useCreateWaitlist = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
        (data: InvestmentWaitlistData) => waitlistApiClient.create(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['waitlist']);
            },
        }
    );

    const createWaitlist = async (data: InvestmentWaitlistData) => {
        try {
            await mutation.mutateAsync(data);

        } catch (error) {
            console.error('Error creating waitlist entry:', error);
            throw new Error('Error creating waitlist entry');
        }
    };

    return {
        createWaitlist,
        ...mutation
    };
};


export const useCreateWaitlistDocument = () => {

    const mutation = useMutation(
        (newDocument: FormData) => waitlistApiClient.createDocument(newDocument)
    );

    const createDocument = async (newDocument: FormData) => {
        try {
            const document = await mutation.mutateAsync(newDocument);
            console.log("mutation result: " + document)
            return document;
        } catch (error) {
            console.error('Error creating KYC document:', error);
            throw new Error('Error creating KYC document');
        }
    };

    return {
        createDocument,
        ...mutation,
    };
};
