import React, { ReactNode } from "react";
import { Grid, Divider as MuiDivider } from "@mui/material";

interface DividerProps {
  children?: ReactNode;
}
const Divider = ({ children, ...props }:DividerProps) => (
  <Grid container alignItems="center" spacing={3} {...props}>
    <Grid item xs>
      <MuiDivider />
    </Grid>
    <Grid item>{children}</Grid>
    <Grid item xs>
      <MuiDivider />
    </Grid>
  </Grid>
);

export default Divider;
