import React, { useEffect, useState } from 'react'
import { Alert, Avatar, Box, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { FormApi, ValidationErrors } from 'final-form';
import { Field, Form } from 'react-final-form';
import Select from '../Reusables/Input/select/Select';
import CustomLink from '../Reusables/Input/CustomLink';
import { calculateLoanInterest, numberWithCommas, removeCommas } from '../../utils/middleware';
import FormInput from '../Reusables/Input/FormInput';
import RadioButtons from '../Reusables/Input/RadioGroup';
import { loanOptions } from '../Reusables/Input/select/Options';
import { PaymentMethod } from '../../types/types';
import { usePaymentMethods } from '../../hooks/paymentMethodHooks';
import DoneIcon from '@mui/icons-material/Done';
import Button from '../Reusables/Input/Button';
import dayjs from 'dayjs';
import { useCreateLoan, useLoanDetails } from '../../hooks/loanHooks';

interface LoanStepperProps {
  open: boolean;
  onClose: () => void;
}

interface FormValues {
  type: string;
  currency: string;
  name: string;
  amount: number;
}

interface LoanFormValues {
  type: string,
  amount: string,
  method: string,
  phoneNumber: string,
  bank: PaymentMethod

}

const steps = ['Apply for instant saver loan', 'Loan details', 'Confirm', 'Success!'];

const CreateLoan: React.FC<LoanStepperProps> = ({ open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { createLoan, isLoading, error } = useCreateLoan();
  const { data } = useLoanDetails();
  const [formValues, setFormValues] = useState({ type: '', currency: 'UGX', name: '', amount: 0 });

  const validate = (values: LoanFormValues) => {
    const errors: ValidationErrors = {};
    if (activeStep === 0) {
      if (!values.amount) {
        errors.amount = "required"
      }

      if (values.amount && parseFloat((values.amount)) < 1000) {
        errors.amount = 'Please a value greater than 1000'
      }

      if (values.amount && parseFloat((values.amount)) > 500000) {
        errors.amount = `Max. amount is ${numberWithCommas(500000)}`
      }

      if (values.amount && parseFloat((values.amount)) > formValues.amount) {
        errors.amount = `Max. amount is ${numberWithCommas(formValues.amount)}`
      }

      if (!values.method) {
        errors.method = "required";
      }

      if (values.method === 'mobile') {
        if (!values.phoneNumber) {
          errors.phoneNumber = 'required'
        }
      }
    }
    return errors;
  };

  useEffect(() => {
    if (data?.amountLeftToBorrow !== undefined) {
      setFormValues({ ...formValues, amount: data.amountLeftToBorrow });
    }
  }, [data?.amountLeftToBorrow]);

  const handleReset = (form: FormApi<LoanFormValues, Partial<LoanFormValues>>) => {
    setActiveStep(0);
    form.reset();
    onClose()
    setFormValues({ type: '', currency: '', name: '', amount: 0 });
  };

  const handleNext = async (values: LoanFormValues) => {
    if (activeStep === steps.length - 2) {
      try {
        const totalDue = calculateLoanInterest(parseFloat((values.amount)), 30) + parseFloat((values.amount))
        if (values.method === 'bank') {
          await createLoan({
            duration: 1,
            amount: parseFloat(values.amount),
            loan_amount: totalDue,
            fee: 0,
            type: 'saver',
            rate: 10,
            method: values.method,
            currency: 'UGX',
            bankAccount: {
              id: values.bank.id,
              code: values.bank.bank_acc.code,
              accountNumber: values.bank.acc_no as string,
            }
          });
        } else {
          await createLoan({
            duration: 1,
            amount: parseFloat(values.amount),
            loan_amount: totalDue,
            type: 'saver',
            rate: 10,
            method: values.method,
            currency: 'UGX',
            phoneNumber: values.phoneNumber,
            fee: 0
          });
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.log(`error`)
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box>
      <Box>
        <DialogTitle sx={{ display: 'flex' }}>
          <Typography sx={{ m: '0 auto', fontWeight: 600 }}>{steps[activeStep]}</Typography>
          <CloseIcon onClick={onClose} />
        </DialogTitle>
        <DialogContent sx={{ padding: { lg: '28px 55px' } }}>
          <Form
            onSubmit={handleNext}
            validate={validate}
            initialValues={{
              method: 'mobile',
              amount: 0
            }}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit}>
                {activeStep === 0 && <LoanDetailsStep values={values} formValues={formValues} />}
                {activeStep === 1 && <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography>Loan amount</Typography>
                    <Typography component='span'>{numberWithCommas((values.amount))} <Typography component='span' variant='overline'>{formValues.currency}</Typography></Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography>Interest</Typography>
                    <Typography component='span' >{numberWithCommas(calculateLoanInterest(parseFloat((values.amount)), 30))} <Typography component='span' variant='overline'>{formValues.currency}</Typography></Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='h6'>Total due</Typography>
                    <Typography component='span' fontWeight={600} variant='h5'>{numberWithCommas(calculateLoanInterest(parseFloat((values.amount)), 30) + parseFloat((values.amount)))} <Typography component='span' variant='overline'>{formValues.currency}</Typography></Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                    <Typography>Due in</Typography>
                    <Typography> 30 days <Typography component='span' variant='subtitle2' sx={{ display: 'flex' }}>{dayjs().add(30, 'day').format('DD MMM, YYYY')}</Typography></Typography>
                  </Box>
                </Box>}
                {activeStep === 2 && <Box>
                  <Typography>
                    You are about to receive {" "}
                    <Typography component='span' fontWeight={600}>{numberWithCommas((values.amount))}</Typography> <Typography component='span' variant='overline'>{formValues.currency}</Typography> on <Typography component={'span'} fontWeight={600}>
                      {values.method === 'mobile' ? values.phoneNumber : values.method === 'bank' ? `${values.bank.bank_acc.name} -- ${values.bank.acc_no}` : `wallet`}.</Typography> Can we proceed ?
                  </Typography>
                </Box>}

                {activeStep === 3 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: '#22C55E', mt: 2, width: 60, height: 60 }}>
                    <DoneIcon fontSize='large' />
                  </Avatar>
                  <Typography sx={{ mt: 1, textAlign: 'center' }}>{`You have successfully withdrawn a loan of ${numberWithCommas(parseFloat(values.amount))} ${formValues.currency}`}</Typography>
                  <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography variant='subtitle2' fontWeight={600}>Transaction details</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography>Loan amount</Typography>
                      <Typography component='span'>{numberWithCommas(values.amount)} <Typography component='span' variant='overline'>{formValues.currency}</Typography></Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography>Loan Interest</Typography>
                      <Typography component='span' >{numberWithCommas(calculateLoanInterest(parseFloat((values.amount)), 30))} <Typography component='span' variant='overline'>{formValues.currency}</Typography></Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography >Total due</Typography>
                      <Typography component='span' >{numberWithCommas(calculateLoanInterest(parseFloat((values.amount)), 30) + parseFloat((values.amount)))} <Typography component='span' variant='overline'>{formValues.currency}</Typography></Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                      <Typography>Due </Typography>
                      <Typography> 30 days <Typography component='span'>({dayjs().add(30, 'day').format('DD MMM, YYYY')})</Typography></Typography>
                    </Box>
                  </Box>

                </Box>}
                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 3,
                  }}
                >
                  {activeStep === steps.length - 1 ? (
                    <Button title='Great' variant='contained' sx={{ width: '100%' }} onClick={() => handleReset(form)} />
                  ) : (
                    <>
                      {activeStep !== 0 && (
                        <Button color="primary" onClick={handleBack} title='Back' sx={{ backgroundColor: '#F0EBF8', mr: 1.5, width: '120px' }} />
                      )}
                      <Button variant="contained" type="submit"
                        title={activeStep === steps.length - 2 ? 'Proceed' : 'Next'} loading={isLoading}
                        disabled={isLoading}
                        sx={{ width: '120px' }}
                      />
                    </>
                  )}
                </Box>
              </form>
            )}
          />
        </DialogContent>
      </Box>
    </Box>
  )
}

export default CreateLoan

interface LoanDetailsFormValues {
  values: Partial<LoanFormValues>,
  formValues: FormValues
}

const LoanDetailsStep: React.FC<LoanDetailsFormValues> = ({ values, formValues }) => {
  const { data: paymentMethodsData, } = usePaymentMethods();
  const phoneMethods: PaymentMethod[] = paymentMethodsData?.phone || []
  const bankMethods: PaymentMethod[] = paymentMethodsData?.bank || []

  const [displayInput, setDisplayInput] = useState(phoneMethods.length === 0)

  useEffect(() => {
    if (phoneMethods.length !== 0) {
      setDisplayInput(!displayInput);
    }
  }, [paymentMethodsData]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.6 }}>
      <Box>
        <Box sx={{ mt: 2 }}>
          <Field name="amount" >
            {(props: any) => <FormInput
              label="Amount"
              type="text"
              name="amount"
              value={numberWithCommas(props.input.value)}
              onChange={(e) => {
                props.input.onChange(removeCommas(e.target.value));

              }}
              InputProps={{
                endAdornment: <Typography sx={{ color: '#006703', fontWeight: 600 }}
                  onClick={() => {
                    props.input.onChange(formValues.amount.toString());
                  }}
                >Max</Typography>,
              }}
              {...props}
            />}
          </Field>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: "0.25rem" }}>
            <Typography variant='caption' sx={{ color: '#6F6F6F' }}>Max. loan amount</Typography>
            <Typography variant='caption' sx={{ color: '#6F6F6F' }}>{`${numberWithCommas(formValues.amount)} ${formValues.currency}`}</Typography>
          </Box>
        </Box>

      </Box>

      <Box>
        <Typography fontWeight={600} marginBottom={"0.5rem"}>Payment method</Typography>
        <Field name="method" >
          {(props: any) => <RadioButtons
            options={loanOptions}
            onChange={(e) => {
              props.input.onChange(e.target.value);
            }}
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridAutoColumns: "max-content",
              gap: "1rem",
            }}
            {...props}
          />}
        </Field>
      </Box>

      {values.method === "mobile" ? (
        <div >
          {displayInput ? (
            <Field
              label="Enter phone number"
              name="phoneNumber"
              component={FormInput}
              type="string"
            />
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight={600}>Choose PhoneNumber</Typography>
                <Typography fontWeight={600} onClick={() => setDisplayInput(!displayInput)} sx={{ color: '#006703' }}>Add </Typography>
              </Box>
              <Field
                phoneNumbers={phoneMethods}
                name="phoneNumber"
                type="select"
                component={Select}
              />
            </>
          )}
        </div>
      ) : values.method === "bank" ? (
        <div >
          <Typography fontWeight={600}>Choose Account</Typography>
          {bankMethods.length === 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography fontWeight={600}>Add bank account to continue transaction</Typography>
              <CustomLink href={`/profile/settings/payment-methods`} title='Add card' />
            </Box>
          ) : (
            <Field
              banks={bankMethods}
              name="bank"
              type="select"
              component={Select}
            />
          )}
        </div>
      ) : null}
    </Box>
  );
};