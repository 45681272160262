import { Alert, Box, Typography } from "@mui/material";
import { useStyles } from "../loginStyles";
import { useState } from "react";
import Button from "../../../components/Reusables/Input/Button";
import { Field, Form } from 'react-final-form';
import Select from "../../../components/Reusables/Input/select/Select";
import { governmentTypeOptions } from "../../../components/Reusables/Input/select/Options";
import { useCreateKycDocument } from "../../../hooks/userHooks";
import { FormApi } from "final-form";
import { extractTextFromImage } from "../../../utils/middleware";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Toast from "../../../components/Reusables/Feedback/Toast";
import { useCreateWaitlistDocument } from "../../../hooks/waitlistHooks";

interface GovernmentFormValues {
    country: string;
    document_name: string;
    frontImage: File | string;
    backImage: File | string;
}

const GovernmentKYC: React.FC<{ handleBack: any; input: any }> = ({ handleBack, input }) => {
    const classes = useStyles();
    const { createDocument, error, isLoading } = useCreateWaitlistDocument();


    const imageUpload = (inputName: string) => {
        const fileInput = document.querySelector(`#${inputName}PhotoUpload`) as HTMLInputElement;
        fileInput.click();
    };

    const [uploadToast, setUploadToast] = useState({
        open: false,
        message: "",
        type: "success",
    });

    const handleCloseToast = (toastType: string) => {
        if (toastType === "upload") {
            setUploadToast((prevToast) => ({
                ...prevToast,
                open: false,
            }));
        }
    };

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const imageFile: File | null = event.target.files ? event.target.files[0] : null;
            if (imageFile) {

                // Extract text from the image using Tesseract
                const extractedText = await extractTextFromImage(imageFile);

                const ninRegex = /([A-Z]+\d+)/;
                const cardNumberRegex = /([A-Z]+\d+)/;
                const ninMatch = extractedText.match(ninRegex);
                const cardNumberMatch = extractedText.match(cardNumberRegex);

                const extractedNIN = ninMatch ? ninMatch[1] : 'N/A';
                const extractedCardNumber = cardNumberMatch ? cardNumberMatch[1] : 'N/A';
                const fullNameRegex = /(\w+<<\w+)/i;

                const fullNameMatch = extractedText.match(fullNameRegex);

                const extractedFullName = fullNameMatch ? fullNameMatch[1] : 'N/A';

                console.log('NIN Number:', extractedNIN);
                console.log('Card Number:', extractedCardNumber);
                console.log('Legal Full Name:', extractedFullName);
            }
        } catch (error) {
            console.log('error uploading image')
        }
    };

    const validate = (values: GovernmentFormValues) => {
        const errors: Partial<GovernmentFormValues> = {};

        if (!values.document_name) {
            errors.document_name = 'Document name is required';
        }
        if (!values.frontImage) {
            errors.frontImage = 'Front Image is required';
        }
        if (!values.backImage) {
            errors.backImage = 'Back Image is required';
        }


        return errors;
    };

    const onSubmit = async (values: GovernmentFormValues, form: FormApi<GovernmentFormValues, Partial<GovernmentFormValues>>) => {

        try {

            const formData = new FormData();
            formData.append('name', values.document_name);
            formData.append('documents', values.frontImage);
            formData.append('documents', values.backImage);
            formData.append('type', 'government')



            const document = await createDocument(formData);
            input.onChange(document);
            setUploadToast({
                open: true,
                message: "Document uploaded successfully",
                type: "success",
            });


            // Document created successfully, you can perform any further actions here
        } catch (error) {
            // Handle error
            setUploadToast({
                open: true,
                message: "Failed to upload document",
                type: "error",
            });
        }

    };

    return (
        <div className={classes.container}>
            <Box className={classes.formContainer}>
                <Box maxWidth={"450px"} width="100%">
                    <Typography variant="h4">Add governmental KYC</Typography>
                </Box>
                <Form onSubmit={onSubmit} validate={validate}>
                    {({ handleSubmit, values, form }) => (
                        <form >
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                                <Box sx={{ textAlign: 'left', width: '100%' }}>
                                    <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Select the government IDs you have</Typography>
                                    <Field name="document_name" component={Select} fullWidth options={governmentTypeOptions} />
                                </Box>

                                <Box sx={{ textAlign: 'left', width: '100%' }}>
                                    <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach a clear photo of the front of the selected ID</Typography>
                                    <Field name="frontImage">
                                        {({ input, meta }) => (
                                            <>
                                                <Box sx={{ borderRadius: 1.5, border: `1px solid  #BABFC3`, py: 2 }} style={{
                                                    backgroundImage: `url(${values.frontImage ? URL.createObjectURL(values.frontImage as File) : null})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: "center"
                                                }}>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        id="frontPhotoUpload"
                                                        onChange={(e) => {
                                                            input.onChange(e.target.files && e.target.files[0]);
                                                            handleImageUpload(e);
                                                        }}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <label htmlFor="frontPhotoUpload">
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, opacity: values.frontImage ? 0.3 : 1 }}>
                                                            <ArrowCircleUpIcon />
                                                            <Button
                                                                title={"Upload"}
                                                                color="inherit"
                                                                variant="outlined"
                                                                onClick={() => imageUpload("front")}
                                                                sx={{ padding: '3px 10px' }}
                                                            />
                                                        </Box>
                                                    </label>
                                                </Box>
                                                <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                                            </>
                                        )}
                                    </Field>
                                </Box>

                                <Box sx={{ textAlign: 'left', width: '100%' }}>
                                    <Typography fontWeight={600} sx={{ lineHeight: 'unset' }}>Attach a clear photo of the back of the selected ID</Typography>
                                    <Field name="backImage">
                                        {({ input, meta }) => (
                                            <>
                                                <Box sx={{ borderRadius: 1.5, border: `1px solid  #BABFC3`, py: 2 }} style={{
                                                    backgroundImage: `url(${values.backImage ? URL.createObjectURL(values.backImage as File) : null})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: "center"
                                                }}>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        id="backPhotoUpload"
                                                        onChange={(e) => {
                                                            input.onChange(e.target.files && e.target.files[0]);
                                                            handleImageUpload(e);
                                                        }}

                                                        style={{ display: 'none' }}
                                                    />
                                                    <label htmlFor="backPhotoUpload">
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, opacity: values.backImage ? 0.3 : 1 }}>
                                                            <ArrowCircleUpIcon />
                                                            <Button
                                                                title={"Upload"}
                                                                color="inherit"
                                                                variant="outlined"
                                                                onClick={() => imageUpload("back")}
                                                                sx={{ padding: '3px 10px' }}
                                                            />
                                                        </Box>
                                                    </label>
                                                </Box>
                                                <Typography variant='caption' color='error'>{meta?.touched ? meta?.error || meta?.submitError : ""}</Typography>
                                            </>
                                        )}
                                    </Field>
                                </Box>
                                {error && <Alert severity='error'>{(error as Error).message}</Alert>}
                                <Button variant="outlined" title="Add document" style={{ width: "80%", margin: "0 auto" }} onClick={() => onSubmit(values, form)} disabled={isLoading} loading={isLoading} />
                            </Box>
                        </form>
                    )}
                </Form>
                <Box mt={3} className={classes.formBtnContainer}>
                    <Button title='Back' variant='outlined' style={{ width: "49%" }} onClick={handleBack} />
                    <Button title='Continue' type='submit' variant='contained' style={{ width: "49%" }} />
                </Box>
            </Box>
            <Toast {...uploadToast} handleClose={() => handleCloseToast("upload")} />
        </div>
    )
}

export default GovernmentKYC;