import {editUserGoalData, ExtendedGoalMember } from "../../types/actionTypes";
import { RevolvingSettings } from "../../types/types";
import api from "../api";
import { GOALS_ENDPOINT } from "../apiEndpoints";

const goalsApiClient = {
  // Retrieve a list of all user goals
  getAll: async () => {
    const response = await api.get(`${GOALS_ENDPOINT}`);
    return response.data.goals;
  },
  getMatured: async () => {
    const response = await api.get(`${GOALS_ENDPOINT}/matured`);
    return response.data.goals;
  },
 
  getTransactions: async (id:number,params:any) => {
    const response = await api.post(`${GOALS_ENDPOINT}/transactions/${id}`,params);
    return response.data;
  },
  getRevolvingTransactions: async (id:number,params:any) => {
    const response = await api.post(`${GOALS_ENDPOINT}/transactions/revolving/${id}`,params);
    return response.data;
  },
  getNotifications: async (id:number) => {
    const response = await api.get(`${GOALS_ENDPOINT}/notifications/${id}`);
    return response.data.notifications;
  },
  getById: async (id:number) => {
    const response = await api.get(`${GOALS_ENDPOINT}/goal/${id}`);
    return response.data.goal;
  },
  uploadImage: async (id:number, data:FormData) => {
    try {
      const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const response = await api.put(
      `${GOALS_ENDPOINT}/upload/${id}`,
      data,
      config
    );
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  updateAdminAccess: async (id:number, data:{ admin_access:boolean}) => {
    try {
      const response = await api.put(`${GOALS_ENDPOINT}/admin-access/${id}`, data);
      return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
    create: async (data:any) => {
      try {
      const formData = new FormData();

    // Append each field from the 'data' object to the form data
      for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

      const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const response = await api.post(`${GOALS_ENDPOINT}/create`, formData, config);
    return response.data.goal;
    } catch (error:any) {
      if (error.response && error.response.data) {
     throw error.response.data
      }
   }
   
  },
  // Update an existing goal  by ID
  updateById: async (id:number, data:editUserGoalData) => {
    try {
     const response = await api.put(`${GOALS_ENDPOINT}/${id}`, data);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
  },
  // Delete an existing goal by ID
  deleteById: async (id:number,data:{password:string}) => {
    try {
      await api.post(`${GOALS_ENDPOINT}/delete/${id}`,data);
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  updateRevolving: async (goalId:number, data:Partial<RevolvingSettings>) => {
    try {
    const response = await api.put(`${GOALS_ENDPOINT}/revolving-settings/${goalId}`, data);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  getRevolvingSchedule: async (goalId:number) => {
    const response = await api.get(`${GOALS_ENDPOINT}/revolving-schedule/${goalId}`);
    return response.data.schedule;
  },
  getPayoutHistory: async (goalId:number) => {
    const response = await api.get(`${GOALS_ENDPOINT}/payout-history/${goalId}`);
    return response.data.history;
  },
  addGoalMembers: async (id:number,members:number[]) => {
    try {
    const response = await api.post(`${GOALS_ENDPOINT}/add-members/${id}`,{members});
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
  },
  getGoalMembers: async (id:number) => {
    const response = await api.get(`${GOALS_ENDPOINT}/members/${id}`);
    return response.data.members;
  },
  getGoalMember: async (memberId:number) => {
    const response = await api.get(`${GOALS_ENDPOINT}/members/member/${memberId}`);
    return response.data.member;
  },
  deleteGoalMembers: async (goalId:number,members:number[]) => {
    try {
       await api.post(`${GOALS_ENDPOINT}/remove-members/${goalId}`,{members});
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
   
  },
  promoteMember: async (goalId:number, data:ExtendedGoalMember) => {
    try {
    const response = await api.put(`${GOALS_ENDPOINT}/members/${goalId}`, data);
    return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
    
  },
  joinGoalById: async (goalId:number) => {
    try {
       const response = await api.post(`${GOALS_ENDPOINT}/join/${goalId}`);
       return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
   },
  leaveGoalById: async (id:number) => {
    try {
       const response = await api.delete(`${GOALS_ENDPOINT}/leave/${id}`);
       return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
 
  },
  transferGoalOwnership:async(id:number,data:{memberId:number})=>{
    try {
       const response = await api.post(`${GOALS_ENDPOINT}/transfer-ownership/${id}`,data);
     return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
      throw error.response.data
      }
    }
   
  },fetchAccountsInCommon:async(userId:number)=>{
    try {
       const response = await api.get(`${GOALS_ENDPOINT}/goals-in-common/${userId}`);
       return response.data.goals;
    } catch (error:any) {
      console.log(error)
    }
  },
   getAllPublic: async () => {
    const response = await api.get(`${GOALS_ENDPOINT}/public`);
    return response.data.goals;
  },
   getPublicByMember: async (userId?:number) => {
    const response = await api.get(`${GOALS_ENDPOINT}/public-goals/${userId}`);
    return response.data.goals;
  },

};

export default goalsApiClient;
