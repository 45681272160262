import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Notification as NotificationType } from "../../types/types";
import { useUpdateNotifications } from "../../hooks/notificationHooks";
import dayjs from "dayjs";
interface NotificationProps {
  notification: NotificationType
}

const NotificationItem: React.FC<NotificationProps> = ({ notification }) => {

  const { type, body, title, account_type, account_id, id, created_at } = notification;
  let history = useHistory();

  const handleMaturityClick = (accountType: string, accountId: number) => {
    if (accountType === 'goal') {
      history.push(`/goals/goal/${accountId}`);
    }
  };

  const handleJoinedGroupClick = (accountType: string, accountId: number) => {
    if (accountType === 'group') {
      history.push(`/groups/${accountId}`);
    }
  };

  const handleWithdrawFundsClick = () => {
    history.push('/transactions');
  };

  const handleFailedTransactionClick = () => {
    history.push('/transactions');
  };

  const handleNotificationClick = () => {
    switch (type) {
      case 'maturity':
        handleMaturityClick(account_type, account_id as number);
        break;
      case 'joinedGroup':
        handleJoinedGroupClick(account_type, account_id as number);
        break;
      case 'withdrawFunds':
        handleWithdrawFundsClick();
        break;
      case 'failedTransaction':
        handleFailedTransactionClick();
        break;
      // Add cases for other notification types if needed
      default:
        // Handle default behavior
        break;
    }
  }

  return (
    <Box sx={{ borderBottom: "1px solid rgba(196, 196, 196, 0.30)", marginBottom: "0.5rem", cursor: "pointer" }}>
      <Typography variant="overline" textTransform="initial" sx={{ display: 'block', }}>{dayjs(created_at).format('MMM D, YYYY - h:mmA')}</Typography>
      <ListItem sx={{ pl: 0, pt: 0 }} onClick={handleNotificationClick}>
        <ListItemText primary={body} secondary={title} primaryTypographyProps={{ variant: 'body1' }} secondaryTypographyProps={{ variant: 'caption' }} sx={{ gap: "0.25rem", my: "0.5rem" }} />
      </ListItem>
    </Box>
  );
}

export default NotificationItem;
