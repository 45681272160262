import React from "react";
import Modal from "./Modal";
import Button from "../Reusables/Input/Button";
import { capitalizeWords, convertMonthsToYears, numberWithCommas } from "../../utils/middleware";
import { useHistory } from "react-router-dom";
import {  GoalItem, MaturedGoal} from "../../types/types";
import { Avatar, Box, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';


interface MaturedGoalsPopupProps {
  maturedGoal: GoalItem;
  onClose: () => void;
  open: boolean;

}

const MaturedGoalsPopup: React.FC<MaturedGoalsPopupProps>=({ maturedGoal, open,onClose }) =>{
  let history = useHistory();
  const {duration,name,id}=maturedGoal
  const handleGoal=()=>{
    history.push(`/goals/goal/${id}`)
  }

  const reInvest=()=>{
   history.push(`/goals/reinvest`)
  }

  return (
    <>
      <Modal open={open} handleClose={onClose} >
        <Box sx={{display:'flex',alignItems:'center',flexDirection:'column',gap: 1.5}}>
        <Avatar sx={{ background: `linear-gradient(90deg, #FDFC47 0%, #24FE41 100%)`,width:90,height:90 }}>
          <DoneIcon/>
        </Avatar>
        <Typography variant="h6" fontWeight={600}>Congratulations!</Typography>
        <Typography sx={{textAlign:'center'}} variant='body2'>
          {`Your ${duration > 12 ? convertMonthsToYears(duration) : `${duration} months`} ${name} has matured.Kindly act on your available funds within the next 3 days.`}
        </Typography>
        <Box sx={{display:'flex',alignItems:'center',gap:0.5,mb:2}}>
          <Button title='See goal' sx={{backgroundColor:'#F0EBF8'}} onClick={handleGoal}/>
          <Button title='Reinvest' variant="contained" onClick={reInvest}/>
        </Box>
       </Box>
      </Modal>
    </>
  );
}

export default MaturedGoalsPopup;
