import { Alert, Avatar, Box, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Radio, Typography } from '@mui/material';
import React, { Key, useState } from 'react';
import { useAddGoalMembers, useGoals, useGoalsInCommon } from '../../hooks/goalHooks';
import {GoalItem, GoalMember, PocketMember, WalletMember } from '../../types/types';
import Button from '../Reusables/Input/Button';
import DoneIcon from '@mui/icons-material/Done';
import ModalWrapper from '../Modals/ModalWrapper';

interface AddToGoalStepperProps {
  open: boolean;
  onClose: () => void;
  member: GoalMember | WalletMember | PocketMember 
}



const AddToGoalStepper: React.FC<AddToGoalStepperProps> = ({ open, onClose, member }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);

  const { isLoading, isError, data } = useGoals();
  const { data: commonAccounts } = useGoalsInCommon(member.user as number);

  const { addMembers, error } = useAddGoalMembers();

  const handleAddMembers = async (goalId: number) => {
    try {
      if (member.user) {
        await addMembers({ id: goalId, members: [member.user] });
        handleNextStep();
      }
    } catch (error) {
      console.error('Error adding members:', error);
    }
  };


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(Number(event.target.value));
  };


  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography>Error fetching goals data</Typography>;
  }
  //this enures we dont have the option to add a member to goal we already share with them
  // Filter out goals where isAdmin is false
  // Filter out goals that are not in commonGoals or do not have isAdmin set to true
  const filteredGoals = data.filter((goal) => {
    // Check if the goal is not in commonGoals
    const isNotCommonGoal = !commonAccounts.some((commonGoal: { id: number; isGroup: boolean; }) => commonGoal.id === goal.id && commonGoal.isGroup === false);

    // Check if isAdmin is true
    const isAdminGoal = goal.isAdmin === true;


    // Return true if all conditions are met
    return isNotCommonGoal && isAdminGoal;
  });



  const selectedGoal = data.find((goal: GoalItem) => goal.id === selectedValue);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };


  const titles = [`Choose goal to add ${member.users?.username}`, 'Add new goal member', 'Success!'];

  return (
    <ModalWrapper open={open} onClose={onClose} >
      <DialogTitle sx={{ pb: 0.5 }}>
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>{titles[currentStep]}</Typography>
      </DialogTitle>
      <DialogContent>
        {currentStep === 0 && (
          <Box p={1}>
            <List dense>
              {filteredGoals.length === 0 ? <Typography>No goals listed</Typography> : filteredGoals.map((goal: GoalItem, index: Key) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Radio
                      onChange={handleChange}
                      checked={selectedValue === goal.id}
                      value={goal.id}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar alt={'goal image'} src={goal.image_url ?? undefined} sx={{ bgcolor: "primary.main", width: 30, height: 30 }}>{goal.name.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText id={`${goal.id}`} primary={`${goal.name}`} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Box sx={{ float: 'right' }}>
              <Button title='Close' onClick={onClose} sx={{ width: '120px', mr: 0.5 }} />
              <Button color='primary' title='Next' variant="contained" onClick={handleNextStep} sx={{ width: '120px' }} disabled={!selectedGoal} />
            </Box>
          </Box>
        )}
        {currentStep === 1 && (
          <Box >
            <Typography variant='body1' sx={{ mt: 2 }}>{`Are u sure you want to add ${member.users?.username} to ${selectedGoal?.name} goal ?`}</Typography>
            {error && <Alert severity="error" sx={{ px: 0 }}>{(error as Error).message}</Alert>}
            <Box sx={{ float: 'right', marginTop: 1 }}>
              <Button onClick={handlePrevStep} title='Back' sx={{ mr: 0.4, width: '120px' }} />
              <Button variant="contained" onClick={() => handleAddMembers(selectedValue as number)} title='Add' sx={{ width: '120px' }} />
            </Box>
          </Box>
        )}

        {currentStep === 2 && (
          <Box sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 3
          }}>
            <Avatar sx={{ bgcolor: 'primary.main', width: '80px', height: '80px' }}>
              <DoneIcon />
            </Avatar>
            <Typography variant='body2' sx={{ textAlign: 'center', color: 'grey.700', mt: 2 }}>
              {`You have successfully added ${member.users?.username} to your ${selectedGoal?.name} goal.`}
            </Typography>
            <Button
              title="Okay"
              color="primary"
              variant="contained"
              onClick={onClose}
              disableElevation
              sx={{ mt: 5, width: '100%' }} />
          </Box>
        )}
      </DialogContent>
    </ModalWrapper>
  );
};

export default AddToGoalStepper;
