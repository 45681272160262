export const USERS_ENDPOINT = "/users";
export const GOALS_ENDPOINT = "/goals";
export const DEPOSITS_ENDPOINT = "/deposits";
export const PAYOUTS_ENDPOINT = "/payouts";
export const NOTIFICATIONS_ENDPOINT = "/notifications";
export const TRANSACTIONS_ENDPOINT = "/transactions";
export const PAYMENT_METHODS_ENDPOINT = "/paymentmethods";
export const PAYMENT_REQUESTS_ENDPOINT = "/paymentrequests";
export const MEMBERSHIP_REQUESTS_ENDPOINT = "/membershiprequests";
export const BALANCES_ENDPOINT = "/balances";
export const CONTACTS_ENDPOINT = "/contacts";
export const TRANSFERS_ENDPOINT = "/transfers";
export const SUBSCRIPTIONS_ENDPOINT = "/subscriptions";
export const REINVESTS_ENDPOINT = "/reinvest";
export const WALLETS_ENDPOINT = "/wallets";
export const POCKETS_ENDPOINT = "/pockets";
export const LOANS_ENDPOINT = "/loans";
export const WAITLIST_ENDPOINT = "/investment-waitlist";






