import React, { useState } from 'react';
import { Typography, Box, IconButton, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Alert, Skeleton } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from 'react-router-dom';
import { useCreateSocialConnection, useDeleteSocialAccount, useSocialConnections } from '../../hooks/userHooks';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import { SocialConnection } from '../../types/types';
import Button from '../../components/Reusables/Input/Button';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../components/Modals/Modal';
import Select from '../../components/Reusables/Input/select/Select';
import { Form, Field } from 'react-final-form';
import FormInput from '../../components/Reusables/Input/FormInput';
import { socialPlatformOptions } from '../../components/Reusables/Input/select/Options';

interface SocialConnectionFormValues {
  platform: string;
  username: string;
}

const SocialMedia = () => {
    const history = useHistory()
  
  return (
    <Box p={2}>
          <Box sx={{display:'flex',alignItems:'start',gap:1,mt:2}}>
         <IconButton onClick={()=>history.push(`/profile`)} sx={{p:0}}>
            <ArrowBackOutlinedIcon />
         </IconButton>
          <Typography variant="h6" fontWeight={600} sx={{m:'0 auto'}}>
          Social media accounts
        </Typography>
         </Box> 
   <SocialDetails />
    
</Box>
  );
};

export default SocialMedia;

export const SocialDetails=()=>{
  const { createSocialConnection, isLoading: isCreating ,error} = useCreateSocialConnection();
  const {deleteAccount} = useDeleteSocialAccount();
  const [isDialogOpen, setDialogOpen] = useState(false);

   const { data: socialConnections,isLoading,isError } = useSocialConnections();
    

 
    
  const groupedConnections: { [platform: string]: SocialConnection[] } = {};
   socialPlatformOptions.forEach((platformOption) => {
  groupedConnections[platformOption.value] = [];
});

socialConnections?.forEach((account: SocialConnection) => {
  if (!groupedConnections[account.platform]) {
    groupedConnections[account.platform] = [];
  }
  groupedConnections[account.platform].push(account);
})


  const handleDeleteAccount = async (accountId: number) => {
    try {
      await deleteAccount(accountId);
    } catch (error) {
      // Handle error, e.g., show an error message
    }
  };

   const validate = (values:SocialConnectionFormValues) => {
    const errors:Partial<SocialConnectionFormValues> = {};
    if (!values.platform) {
      errors.platform = "Required";
    }
    if (!values.username) {
      errors.username = "Required";
    }
   
    return errors;
  };

  const onSubmit = async (values: SocialConnectionFormValues) => {
    try {
        let data ={
            platform:values.platform,
            profileName:values.username
        }
      // Call your API function to add a new social connection
      await createSocialConnection(data);
      setDialogOpen(false)
    } catch (error) {
      console.error('Error adding social connection:', error);
    }
  };

  return (
    <Box sx={{flex:{lg:0.7}}}>
    <List sx={{ alignItems: 'start',maxWidth:'500px',margin:'16px auto' }}>
      {isLoading || isError ? 
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      : Object.entries(groupedConnections).map(([platform, accounts]) => (
        <div key={platform}>
        <ListItem sx={{alignItems:'start',pl:0}}>
         <ListItemIcon sx={{ minWidth: '40px',color:'text.900' }}>
          {platform === 'Instagram' && <InstagramIcon />}
          {platform === 'Facebook' && <FacebookIcon />}
          {platform === 'Twitter' && <TwitterIcon />}
          {platform === 'Email' && <EmailIcon  color='inherit'/>}
          </ListItemIcon>
          <ListItemText primary={platform} primaryTypographyProps={{fontWeight:600,mt:0}} />     
          {
            accounts.length === 0 ? (
                 <ListItemSecondaryAction sx={{ right: '-6px' }}>
                    <Button title="Connect" color="inherit" onClick={()=>setDialogOpen(!isDialogOpen)} />
                  </ListItemSecondaryAction>
            ):(
                <ListItemSecondaryAction sx={{ right: '-6px' }}>
                    <Button title="Add new"  onClick={()=>setDialogOpen(!isDialogOpen)} />
                  </ListItemSecondaryAction>
            )
          }
        </ListItem>
        
          {accounts
            .sort((a, b) => (b.is_default ? 1 : -1))
            .map((account) => (
              <ListItem key={account.id} sx={{ mt: 0 ,pl:0}}>
                <ListItemText primary={<Box sx={{display:'flex'}}>
                <Typography >{account.profile_name}</Typography>
                {account.is_default && <Typography color='#71717A' component='span'>&nbsp;(primary)</Typography>}
                </Box>} />
                  <ListItemSecondaryAction sx={{ right: '0px' }}>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteAccount(account.id)}>
                      <CloseIcon  fontSize='small'/>
                    </IconButton>
                  </ListItemSecondaryAction>
              </ListItem>
            ))}
        </div>
      ))}
    </List>  
    <Modal open={isDialogOpen} handleClose={()=>setDialogOpen(false)} title ="Add new social" fullWidth={true}>
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
        <Box sx={{px:2}}>
        <Typography fontWeight={600} sx={{mt:2}}>Select platform</Typography>
          <Field
            name="platform"
            component={Select}
            options={socialPlatformOptions}
            label="Select Platform"
            variant="outlined"
            fullWidth
            required
          />
           <Box sx={{mt:1.5}}></Box>
          <Field
            name="username"
            component={FormInput}
            label="Username"
            variant="outlined"
            fullWidth
            required
            
          />
          </Box>
           {error && <Alert severity='error'>{(error as Error).message}</Alert>}
            <Box sx={{my:2,textAlign:'center'}}>
                <Button onClick={()=>setDialogOpen(false)} color="inherit" title="Cancel" sx={{mr:2}}/>
                <Button type="submit" variant="contained" title ="Add" disabled={isCreating} loading={isCreating}/>
            </Box>
        </form>
      )}
    />
   
</Modal>
    </Box>
  )
}
