import { useStyles } from "./ProfileStyles";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useUpdateUser, useUser } from "../../hooks/userHooks";
import { usePaymentMethods } from "../../hooks/paymentMethodHooks";
import { Alert, Box, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography,useMediaQuery,Theme } from "@mui/material";
import { PaymentMethod } from "../../types/types";
import Button from "../../components/Reusables/Input/Button";
import dayjs from "dayjs";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import { Form, Field } from "react-final-form";
import FormInput from "../../components/Reusables/Input/FormInput";
import DateInput from "../../components/Reusables/Input/DateInput";
import { useState } from "react";


interface FormValues {
  email: string;
  date_of_birth: Date;
  address:string;
}

function Contact() {
  const history = useHistory();
   const isDesktop = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));
    const [openKin, setOpenKin] = useState(false);

   const handleKin=()=>{
    if(isDesktop){
      setOpenKin(!openKin)
    }else{
     history.push("/profile/contact/kins")
    }
   }
  
  return (
    <Box m={2}>
      <Box sx={{display:'flex',alignItems:'center',mt:2}}>
        <IconButton onClick={()=>history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
          <Typography fontWeight={600} sx={{margin:'0 auto'}}>Contact Information</Typography>
      </Box>
      <ContactDetails handleClick={handleKin} />
   </Box>
  );
}

export default Contact;

export const ContactDetails=({handleClick}:{handleClick:any})=>{
   const { data: user, isLoading,isError } = useUser();
  const { updateUser, isLoading: isUpdating,error } = useUpdateUser();

  const { data: paymentMethodsData, isLoading: isPaymentMethodsLoading ,isError:isPaymentMethodsError } = usePaymentMethods();

  

   const validate = (values:FormValues) => {
    const errors:Partial<FormValues> = {};
    if (!values.email) {
      errors.email = "Required";
    }
    return errors;
  };

   const onSubmit = async (values: FormValues) => {
    try {
      updateUser(values)
    } catch (error) {
      console.error('Error editing user', error);
    }
  };

   if(isLoading || isError){
    <Box>
      <Skeleton variant="text" width={210} height={20} />
       <Skeleton variant="text" width={210} height={20} />
    </Box>
   }
  return (
   <List sx={{flex:{lg:0.7}}}>
      {/* Phone Numbers */}
        <ListItem disablePadding>
          <ListItemText primary="Phone Numbers" primaryTypographyProps={{ variant: 'body1', fontWeight: 600 }} />
           <Box sx={{ flexGrow: 1 }} /> 
          <Button title='Add new'/>
        </ListItem>
        {
        isPaymentMethodsLoading || isPaymentMethodsError ? (
       <>
       <Skeleton variant="text" width={210} height={20} />
       <Skeleton variant="text" width={210} height={20} />
       </>
      ) : (
        <>
          {paymentMethodsData.phone.map((phoneNumber: PaymentMethod) => (
            <ListItem key={phoneNumber.id} disablePadding>
            <ListItemText primary={phoneNumber.phone_number} />
            <Box sx={{ flexGrow: 1 }} /> 
            <CloseIcon fontSize='small'/>
            </ListItem>
          ))}
        </>
      )}
       <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{
        email:user?.email,
        address:user?.address,
        date_of_birth:dayjs(user?.date_of_birth),
      }}
      render={({ handleSubmit,pristine }) => (
        <form onSubmit={handleSubmit}>
        <Box sx={{display:'flex',flexDirection:'column',gap:2,mt:2,}}>
          <Field
            name="email"
            component={FormInput}
            label="Email"
            variant="outlined"
            fullWidth
            required
          />
          <Field
            name="address"
            component={FormInput}
            label="Current address"
            variant="outlined"
            placeholder='Street name, location'
            fullWidth
            required
          />
          <Box sx={{flex:0.5}}>
            <Typography variant ='h6' 
            sx={{fontSize:'1rem',marginBottom:'4px',fontWeight:600}}>Birthday</Typography>
            <Field name="date_of_birth" component={DateInput} label="" />
            </Box>
           
          </Box>
           {error && <Alert severity='error'>{(error as Error).message}</Alert>}
           {!pristine && (
            <Button title='Save' type='submit' variant="contained" sx={{width:'160px' ,textAlign:'center',mt:2}}/>
           )}
           
        </form>
      )}
    />       
        <Divider sx={{mt:2}}/>
        <ListItem
           sx={{ flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
            <ListItemIcon sx={{ minWidth: 'unset' }}>
            <ArrowForwardIosIcon  fontSize='small'/>
          </ListItemIcon>
          <ListItemText primary={`Next of kins`} onClick={handleClick}/>
          </ListItem>
        
        </List>
  )
}