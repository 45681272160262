import { useQuery,useMutation,useQueryClient } from '@tanstack/react-query';
import notificationsApiClient from "../apis/apiServices/notifications";




export const useNotifications = () => {
    return useQuery(['notifications'], notificationsApiClient.getAll,{
      placeholderData:[],
    });
  };
  
export const useUpdateNotifications = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (params: { data: { ids: number[] } }) => notificationsApiClient.updateById(params.data),
    {
      // Optional: onSuccess function to invalidate the notifications query
      onSuccess: () => {
        queryClient.invalidateQueries(['notifications']);
      },
    }
  );

  return {
    updateNotifications: (ids: number[]) => {
      mutation.mutate({ data: { ids } });
    },
    ...mutation,
  };
};
