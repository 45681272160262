import { useMutation } from '@tanstack/react-query';
import reinvestsApiClient from '../apis/apiServices/reinvests';
import { ReinvestNewGoalData, ReinvestSingleGoalData, ReinvestMultipleGoalsData, ReinvestSameGoalsData } from '../types/actionTypes';

export const useReinvestNewGoal = () => {
  const mutation = useMutation((data: ReinvestNewGoalData) => reinvestsApiClient.newGoal(data));

  const reinvestNewGoal = async (data: ReinvestNewGoalData) => {
    try {
      const response = await mutation.mutateAsync(data);
      return response.data;
    } catch (error) {
      console.error('Error reinvesting new goal:', error);
      throw new Error('Error reinvesting new goal');
    }
  };

  return {
    ...mutation,
    reinvestNewGoal,
  };
};


export const useReinvestSingleGoal = () => {
  const mutation = useMutation((data: ReinvestSingleGoalData) => reinvestsApiClient.singleGoal(data));

  const reinvestSingleGoal = async (data: ReinvestSingleGoalData) => {
    try {
      const response = await mutation.mutateAsync(data);
      return response.data;
    } catch (error) {
      console.error('Error reinvesting single goal:', error);
      throw new Error('Error reinvesting single goal');
    }
  };

  return {
    ...mutation,
    reinvestSingleGoal,
  };
};


export const useReinvestMultipleGoals = () => {
  const mutation = useMutation((data: ReinvestMultipleGoalsData) => reinvestsApiClient.multipleGoals(data));

  const reinvestMultipleGoals = async (data: ReinvestMultipleGoalsData) => {
    try {
      const response = await mutation.mutateAsync(data);
      return response.data;
    } catch (error) {
      console.error('Error reinvesting multiple goals:', error);
      throw new Error('Error reinvesting multiple goals');
    }
  };

  return {
    ...mutation,
    reinvestMultipleGoals,
  };
};


export const useReinvestSameGoals = () => {
  const mutation = useMutation((data: ReinvestSameGoalsData) => reinvestsApiClient.sameGoal(data));

  const reinvestSameGoals = async (data: ReinvestSameGoalsData) => {
    try {
      const response = await mutation.mutateAsync(data);
      return response.data;
    } catch (error) {
      console.error('Error reinvesting same goals:', error);
      throw new Error('Error reinvesting same goals');
    }
  };

  return {
    ...mutation,
    reinvestSameGoals,
  };
};
