import { InvestmentWaitlistData } from "../../types/actionTypes";
import api from "../api";
import { WAITLIST_ENDPOINT } from "../apiEndpoints";

const waitlistApiClient = {
    create: async (data: InvestmentWaitlistData) => {
        try {
            const response = await api.post(
                `${WAITLIST_ENDPOINT}`, data
            );
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.data) {
                throw error.response.data
            }
        }

    },

    createDocument: async (data: FormData) => {
        const config = {
            headers: { "content-type": "multipart/form-data" },

        };

        try {
            const response = await api.post(
                `${WAITLIST_ENDPOINT}/documents`, data, config
            );
            console.log(response)
            return response.data.document;
        } catch (error: any) {
            if (error.response && error.response.data) {
                throw error.response.data
            } else {
                throw error
            }
        }

    },

    deleteById: async (id: number) => {
        try {
            await api.delete(`${WAITLIST_ENDPOINT}/${id}`);
        } catch (error: any) {
            if (error.response && error.response.data) {
                throw error.response.data
            }
        }

    },
};

export default waitlistApiClient;
