import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme:Theme) => ({
  search: {
    backgroundColor: "white",
    borderRadius: "4px",
    "& .MuiOutlinedInput-adornedStart": {
      width: "100%",
    },
  },
  formControl: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "8px",
    },
  },
  
  bottomNavigationAction: {
    "& .MuiBottomNavigationAction-label": {
      fontSize: "14px",
      fontWeight: 600,
    },
    color: theme.palette.grey[500],
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      display: "none",
    },
    "&.active": {
      color: theme.palette.primary.main,
    },
  },
  tabsPanel: {
    boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.12)",
    border: `1px solid ${theme.palette.grey[300]}`,
    fontFamily:"Source Serif Pro",
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
    },
    "& .MuiTabs-fixed": {
      backgroundColor: "#fff",
      fontFamily:"Source Serif Pro",

    },
  }, 
  tabs: {
    position: "relative",
  },
  sideTabs: {
    display: "flex",
    "& .MuiTabs-vertical": {
      minHeight: "100vh",
    },
  },
  tab: {
    "& .MuiTab-wrapper": {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "16px",
      fontFamily:"Source Serif Pro",

    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      fontFamily:"Source Serif Pro",

    },
    "&.MuiTab-labelIcon": {
      minHeight: "56px",
      padding: "8px",
    },
  },
  sidebarContent: {
    flex: 1,
  }
}));
