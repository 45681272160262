import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGoalMembers, useGoalTransactions, useRevolvingPayoutTransactions } from '../../hooks/goalHooks';
import TransactionsWrapper from '../transactions/TransactionsWrapper';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { numberWithCommas } from '../../utils/middleware';
import { transactionTypes } from '../../components/Reusables/Input/select/Options';
import theme from '../../theme';
import { DepositTabContent, WithdrawTabContent } from '../../components/transactions/TransactionSummaryDetails';

interface HomeTabContentProps {
  depositTotal: number;
  withdrawTotal: number;
  currency: string;
}

const HomeTabContent: React.FC<HomeTabContentProps> = ({ depositTotal, withdrawTotal, currency }) => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return <Box display={"flex"} flexDirection={"column"} gap="0.62rem">
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Total deposits</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`+ ${numberWithCommas(depositTotal)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant='body1'>Total withdraws</Typography>
      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.4 }}>
        <Typography variant='body1' fontWeight={700}>{`- ${numberWithCommas(withdrawTotal)}`}</Typography>
        <Typography variant='overline'>{currency}</Typography>
      </Box>
    </Box>
  </Box>
};

const GoalTransactionsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: goalMembers } = useGoalMembers(parseFloat(id));
  

  const [filterValues, setFilterValues] = useState({
    startDate: '',
    endDate: '',
    type: 'all',
    category: 'all',
    paymentMethod: 'all',
    status: 'all',
    user: null,
  });

  const transactions = useGoalTransactions({ ...filterValues }, false, parseFloat(id));

  const revolvingTransactions = useRevolvingPayoutTransactions(parseFloat(id));
  const totalDeposits = transactions?.data?.pages?.[0]?.totalDeposits || 0;
  const totalWithdrawals = transactions?.data?.pages[0]?.totalWithdrawals || 0;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (transactions?.data?.pages[0]?.transactions?.length === 0) return;
    setIsLoading(true);
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

 const currency = transactions?.data?.pages[0]?.transactions?.[0]?.currency ?? 'UGX';


  return (
    <Box sx={{
      [theme.breakpoints.down('sm')]: {
        padding: "16px",
      },
    }}>
      <TransactionsWrapper
        transactions={transactions}
        currency={currency}
        transactionTypes={transactionTypes}
        onUpdateValues={setFilterValues}
        initialValues={filterValues}
        members={goalMembers}
        revolvingPayouts={revolvingTransactions}
        transactionPageTitle="Goal Transactions"
        customTabPanels={[
          <HomeTabContent depositTotal={totalDeposits} withdrawTotal={totalWithdrawals} currency={currency} />
          ,
          <DepositTabContent total={totalDeposits} currency={currency} />
          ,
          <WithdrawTabContent total={totalWithdrawals} currency={currency} />

        ]}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default GoalTransactionsPage;
